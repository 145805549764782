import { Injectable } from '@angular/core';
import * as Msal from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


@Injectable()
export   class AuthService {

  msalConfig = {
    auth: {
      clientId: environment.environment.MicrosoftCredentials.clientId,
      authority: environment.environment.MicrosoftCredentials.authority,
   
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };
  public app:any
  public tokenResponse;
  public accountObj;

  private interactionInProgress = false;
  constructor() {

    this. app = new Msal.PublicClientApplication(this.msalConfig)

  }
  
  async handleRedirect(){
    if (this.interactionInProgress) {
      return; // Return if interaction is already in progress
    }
    try {

     this.interactionInProgress=true;

       this.tokenResponse =  await this.app.handleRedirectPromise();

       this.accountObj;
      if (this.tokenResponse) {
        this.accountObj = this.tokenResponse.account;
      } else {
        this.accountObj = await this.app.getAllAccounts()[0];
      }

      if (this.accountObj && this.tokenResponse) {
        console.log("[AuthService.init] Got valid accountObj and tokenResponse")
      } else if (this.accountObj) {
        console.log("[AuthService.init] User has logged in, but no tokens.");
        try {
          this.tokenResponse = await this.app.acquireTokenSilent({
            account: this.app.getAllAccounts()[0],
            scopes: ["user.read"]
          })
        } catch(err) {
           this.app.acquireTokenRedirect({scopes: ["user.read"]});
        }
        
      } 
      
      
    } catch (error) {
      this.interactionInProgress=true;
      console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
    }
    finally{ this.interactionInProgress=false;
    }

  }

  public async  login(){
    if (this.interactionInProgress) {
      return; // Return if interaction is already in progress
    }
    try{
      this.interactionInProgress=true
    await this.app.loginRedirect({scopes: ["user.read"]});
    }catch(error){
      this.interactionInProgress=true
    }
    finally{this.interactionInProgress=false}
  }

  public async logout() {
    var key=JSON.parse(window.localStorage.getItem('msal.token.keys.'+environment.environment.MicrosoftCredentials.clientId))
   
    var  val:any =window.localStorage.getItem(key.idToken[0])
    const authData = JSON.parse(val);
    console.log(authData)
    var account =this.app.getAccountByHomeId(authData.homeAccountId)
   var acc=await this.app.getAllAccounts()[0]
    if (this.interactionInProgress) {
      return; // Return if interaction is already in progress
    }
      try{
        this.interactionInProgress=true
       this.app.logout({
        account: account
        
      });
    }
    catch(error){
      this.interactionInProgress=true
    }
    finally{

      this.interactionInProgress=false
    }
   
  }
 
}