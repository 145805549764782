
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  DoCheck,
  AfterViewChecked,
  NgZone,
  QueryList,
} from "@angular/core";
import {
  PrimaryDetails,
  AssetDetails,
  AssetAdminListingEntity,
  LocationEntity,
  AssetTypeFaultListModel,
  AssetTypeFixListModel,
  AssetTypeEntity,
  AssetImageData,
  SubAssetEntity,
  SubAssetList,
  ReplacementCostBugget,
  UOMData,
  RepairAndMaintenanceCostBugget,
} from "./add-asset.model";
import { MapsAPILoader } from "@agm/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreDataService } from "../../Service/core-data.service";
import { SharedDataService } from "../../Service/CommonUtility.service";


import {
  FormControl,
  FormGroup,
  FormsModule,
  FormArray,
  Validators,
  FormBuilder,
  NgForm,
  FormControlDirective,
  FormControlName,
} from "@angular/forms";
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
import {
  FileRestrictions,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
} from "@progress/kendo-angular-upload";
import { Http } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { ContextMenuComponent } from "@progress/kendo-angular-menu";
import { SnotifyService } from "ng-snotify";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import { AssetValidationComponent } from "../add-asset/AssetValidators";


import { Ng2ImgMaxService } from "ng2-img-max";
import { ImageCompressor, getImageSize } from "compressor-img";
import { ViewChildren } from "@angular/core";
declare var google: any;
declare var googlemap: any;
import { AdminEntity } from "../../Common/shared-model";

import { DocumentsUploadEntity } from "../../Common/documents-upload/documents-upload.model";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import {
  process,
  State,
  SortDescriptor,
  orderBy,
  filterBy,
  FilterDescriptor,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { AssetTypeRMModel } from "../create-asset-type/create-asset-type.model";
import { ToastrService } from "ngx-toastr";
import { isFunction } from "rxjs/internal-compatibility";
import { runInThisContext } from "vm";
declare const BMap: any;

@Component({
  selector: "app-add-asset",
  templateUrl: "./add-asset.component.html",
  styleUrls: ["./add-asset.component.scss"],
})
export class AddAssetComponent
  implements OnInit, AfterViewInit, AfterViewChecked {
  //#region Declaration Starts
  @ViewChild("img", { static: false }) imgEl: ElementRef<HTMLImageElement>;
  public reccWdrawl: Date = null;

  public HiddenErrorMessage = true;
  public filter: string;
  LanguageConfig: any;
  NewFieldConfig: any;
  GridConfig: any;
  public CheckDisable = true;
  public ShowError = false;
  public OldAssetTypeID: number;
  public OldLocationID: number;
  @ViewChild("tabstrip", { static: false }) tabstrip;
  public latitude: number = 25.2744;
  public longitude: number = 133.775131;
  // public latitude: number =  null ;
  // public longitude: number =  null;
  public searchControl: FormControl;
  public zoom: number = 4;
  public index=0
  public index1=0
  public replacementCost: ReplacementCostBugget = new ReplacementCostBugget();
  public AssetTypeRmList: Array<AssetTypeRMModel> = [];
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  IsUserCustomer: string;
  ShowHideSubAssets = false;
  public gridData: GridDataResult;
  public gridBookData: GridDataResult;
  public repairAndMaintenanceCostBudget: RepairAndMaintenanceCostBugget = new RepairAndMaintenanceCostBugget();
  public CompanyDropdown: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [
      { codeValue: "Small", codeID: 1, weightage: 0 },
      { codeValue: "Medium", codeID: 2, weightage: 0 },
      { codeValue: "Large", codeID: 3, weightage: 0 },
    ];
  public expandMode: number = PanelBarExpandMode.Multiple;
  public ddlDefaultItem: { Name: string; ID: number } = {
    Name: "Select",
    ID: null,
  };
  public ddlCodeDataDefaultItem: {
    codeValue: string;
    codeID: number;
    weightage: number;
  } = { codeValue: "Select", codeID: null, weightage: 0 };
  public ddlModelDataDefaultItem: {
    codeValue: string;
    codeID: number;
    weightage: number;
  } = { codeValue: "Other", codeID: null, weightage: 0 };
  // public ddlModelDataDefaultItem: { codeValue: string, codeID: number} = { codeValue: 'Other', codeID: 0};

  public max: Date = new Date();
  public ddlConditionRatingCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlMfgCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlStatusCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlIndoorOutdoorCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlDampersFittedCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlFilterTypeCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlFireDoorFRLRatingCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlFireModeFnCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlMaintenanceFreqCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlOwnershipCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlRefrigerantCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlEnvironmentCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlAssetRunTimeCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlCriticalityCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlEssentialServiceCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlMaintenanceStdCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlPriorityCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlRecommendedActionCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlyesnonaCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlmaintenancetypecd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlAssetSizeCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlPermitsCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];

  // --NEW FIELD
  public ddlFuelTypeCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];

  public ddlUOMTest: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];
  public ddlModelCd: Array<{
    codeValue: string;
    codeID: number;
    weightage: number;
  }> = [];

  isCancelclicked: boolean = false;
  IsCancelClickedImage: boolean = false;
  ClickedImage: boolean = false;
  ClickedImageSub: boolean = false;
  IsCancelClickedAssetType: boolean = false;
  IsCancelClickedSwitchDialogBox: boolean = false;

  IsCancelClickedLocation: boolean = false;
  IsCancelClickedClone: boolean = false;
  IsNFactorPresent: boolean;
  IsQuantityPresent: boolean;
  IsCancelClickedSubImage: boolean = false;
  IsEditClickedImage: boolean = false;
  ImageID: number;
  SubAssetImageID: number;
  public ddlUOMCd: Array<any> = new Array<any>();

  subAssetModel: SubAssetEntity = new SubAssetEntity();

  TempSubAssetImages: any = [];
  submitted = false;

  IsValidationDisabled: boolean = false;

  AssetAdminListingEntity: AssetAdminListingEntity =
    new AssetAdminListingEntity();

  AssetTypeFaultListModel: any = [];
  AssetTypeFixListModel: Array<AssetTypeFixListModel> =
    new Array<AssetTypeFixListModel>();

  lengthArray: any = [];
  formGroup: FormGroup;

  public data: number;
  public dataValid: boolean = true;

  public datawidthBreadth: number;
  public datawidthBreadthValid: boolean = true;
  public datathicknessDepth: number;
  public datathicknessDepthValid: boolean = true;
  GridCurrencyChange: any;
  currencySymbol: any;
  RMCost: number;
  // Image
  public onBlur() {
    this.dataValid = true;
  }
  public onBlurWidthBreadth() {
    this.datawidthBreadthValid = true;
  }
  public onBlurThicknessDepth() {
    this.datathicknessDepthValid = true;
  }

  public imagePreviews: any = [];
  public imagePreviewsViewer: string[] = [];
  public imageIndexOne = 0;
  public imageIndexOneSub = 0;
  public imagePreviewsSub: any = [];
  public imagePreviewsSubViewer: string[] = [];
  currDate: Date;
  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };
  private _map: any;
  MapOptions = [{ key: "Google", displayName: "Google" }, { key: "Baidu", displayName: "Baidu" }];
 
  SelectedMap:string ='Google'
  BaiduAutocomplete: any;
  GoogleAutocomplete:any;

  changeIndex(i, image) {
    this.imageIndexOne = i;
    if (image != undefined) {
      this.ImageID = image.uid;
      this.ClickedImage = true;
    }

    document
      .getElementsByTagName("ngx-image-viewer")[0]
      .getElementsByClassName("img-container")[0]
      .getElementsByTagName("img")[0]["style"]["height"] =
      "-webkit-fill-available";
  }

  changeIndexSub(i, subImage) {
    this.imageIndexOneSub = i;
    if (subImage != undefined) {
      this.SubAssetImageID = subImage.uid;
      this.ClickedImageSub = true;
    }

    document
      .getElementsByTagName("ngx-image-viewer")[0]
      .getElementsByClassName("img-container")[0]
      .getElementsByTagName("img")[0]["style"]["height"] =
      "-webkit-fill-available";
  }

  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png",".JPEG"],
  };

  public uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  public uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  public clearEventHandler(e: ClearEvent): void {
    this.imagePreviews = [];
  }

  public completeEventHandler() { }

  public removeEventHandler(e: RemoveEvent): void {
    const index = this.imagePreviews.findIndex(
      (item) => item.uid === e.files[0].uid
    );

    if (index >= 0) {
      this.imagePreviews.splice(index, 1);
    }
  }

  originalImage: any = null;
  sizeOriganleImage: any = null;
  compressedImage: any = null;
  sizeCompressedImage: any = null;
  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    console.log("File", e);
    e.files.forEach((file: any) => {
      if (file.size < 8388608 && this.uploadRestrictions.allowedExtensions.includes( file.extension)) {
        const reader = new FileReader();
        if (file.size > 300000) {
          reader.onload = () => {
            let imageCompressor = new ImageCompressor({
              onSuccess: (response) => {
                (this.originalImage = response.original),
                  (this.sizeOriganleImage = getImageSize(response.original)),
                  (this.compressedImage = response.compressed),
                  (this.sizeCompressedImage = getImageSize(
                    response.compressed
                  ));

                //GENERATING Unique object ID for image & also for deletion

                if (that.imagePreviewsViewer[0] !== this.compressedImage) {
                  let image = {
                    src: this.compressedImage,
                    uid: file.uid,
                    date: Date.now(),
                    extension: file.extension,
                    IsUpdated: true,
                  };

                  //GENERATING Unique object ID for image
                  that.imagePreviews.unshift(image);
                  that.imagePreviewsViewer.unshift(this.compressedImage);
                  this.validateAssetImages();
                }
                var k = document.getElementsByClassName("k-upload-files");
                if (k.length > 0) {
                  k[0].remove();
                }
              },
              scale: 30,
              quality: 100,
              holdCompress: false,
              originalImage: reader.result,
            });
            imageCompressor.startCompress();
          };
        } else {
          reader.onload = (ev) => {
            let image = {
              src: ev.target["result"],
              uid: file.uid,
              extension: file.extension,
              date: Date.now(),
              IsUpdated: true,
              description: "",
            };

            that.imagePreviews.unshift(image);
            that.imagePreviewsViewer.unshift(ev.target["result"].toString());
            this.validateAssetImages();
            var k = document.getElementsByClassName("k-upload-files");
            if (k.length > 0) {
              k[0].remove();
            }
          };
        }

        reader.readAsDataURL(file.rawFile);
      } else if(file.size > 8388608) {
        this.notify.error("File Size More Than 8 MB.");
        var k = document.getElementsByClassName("k-upload-files");
        if (k.length > 0) {
          k[0].remove();
        }
      }
      else{
        this.notify.error("File Extenstion should be '.JPG','.PNG','.JPEG' ");
        var k = document.getElementsByClassName("k-upload-files");
        if (k.length > 0) {
          k[0].remove();
        } 
      }
    });
  }

  validateAssetImages(): boolean {
    let IsValid = false;
    if (this.assetDetailsModel.assetID >= 0) {
      if (this.imagePreviews.length <= 10 && this.imagePreviews.length >= 2) {
        document.getElementById("ImageDisplay").hidden = true;
        //Commented by sunil:
        // this.assetDetailsModel.isIncomplete = true;
        IsValid = true;
      } else {
        document.getElementById("ImageDisplay").hidden = false;
        IsValid = false;
      }
    }
    if (this.assetDetailsModel.isIncomplete == true) {
      IsValid = true;
    }
    return IsValid;
  }

  public selectEventHandlerSub(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = (ev) => {
          //GENERATING Unique object ID for image & also for deletion
          let imageSub = {
            src: ev.target["result"],
            uid: file.uid,
            extension: file.extension,
            IsUpdated: true,
            name: file.name,
          };
          //GENERATING Unique object ID for image

          that.subAssetModel.SubAssetImages.unshift(imageSub);
          that.imagePreviewsSubViewer.unshift(ev.target["result"].toString());

          var k = document.getElementsByClassName("k-upload-files");
          if (k.length > 0) {
            k[0].remove();
          }
        };
        reader.readAsDataURL(file.rawFile);
      }
    });
  }
  //
  userAction: string = "Add Asset";

  // subAssetModel:SubAssetEntity;
  assetDetailsModel: AssetDetails = new AssetDetails();
  primaryDetailModel: PrimaryDetails = new PrimaryDetails();
  locationData: Array<LocationEntity> = [];
  AdminEntity: AdminEntity = new AdminEntity();

  AssetData: Array<AssetTypeEntity> = [];
  ShowSubAssets: boolean = false;
  IsEdit: boolean = false;
  ArraySubAssets: Array<SubAssetEntity> = new Array<SubAssetEntity>();

  DocumentData: Array<DocumentsUploadEntity> =
    new Array<DocumentsUploadEntity>();
  SubAssetResp: number;
  isSelectedOther: boolean = false;
  public allowCustom: boolean = true;
  public value: Date = new Date();
  public panelExpand: boolean;
  subAssetArray: Array<SubAssetEntity> = new Array<SubAssetEntity>();
  AssetDataLabel: any = [];
  TempImagArray: any = [];
  showCloneButton: boolean = false;
  showJobHistoryButton: boolean = false;
  //#region Declaration Ends
  IsCustomer: boolean;
  IsAdmin: string;
  accounts: any = [];
  errorMsg: any;
  altitude: any;
  Dateformat: any;
  ShowhidegeneralCommentsValue: any = true;
  ShowhideoHSCommentsValue: any = true;
  generalCommentsOld: string = undefined;
  oHSCommentsOld: string = undefined;
  CommentType: string;
  CommentHistoryTitle: string;
  DocType: string;
  DateTimeformat: any;
  public opened = false;
  constructor(
    private ng2ImgMax: Ng2ImgMaxService,
    public http: Http,
    private DataService: CoreDataService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private sharedDataService: SharedDataService,
    private titleService: Title,
    private notify: SnotifyService,
    private elementRef: ElementRef,
    private coreDataService: CoreDataService,
    private toastrService: ToastrService
  ) { }
  AssetValidationComponent: AssetValidationComponent =
    new AssetValidationComponent();
  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get("id") != null) {
      let Id: number = parseInt(
        this.activatedRoute.snapshot.paramMap.get("id")
      );
      this.assetDetailsModel.assetID = Id;
      this.DocType = "AST";
    } else {
      this.assetDetailsModel.quantity = 1;
    }

    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        console.log(res);
        if (res != undefined) {
          this.LanguageConfig = res[9];
          this.NewFieldConfig = res[30];
          console.log("NewField config", this.NewFieldConfig);
          this.errorMsg = res[28];
          this.GridConfig = res[2];
        }
      });
      // this.sharedDataService.nfactor = this.assetDetailsModel.nFactor;
    }

    this.SelectedMap=this.sharedDataService.GetMap();

    this.GetAssetFieldsLabel();
    this.formGroup = this.AssetValidationComponent.ValidateMeathod();
    // this.Dateformat = this.sharedDataService.dateFormat();
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res["DateFormat"] + " " + res["TimeFormat"];
        this.Dateformat = res["DateFormat"];
        this.GridCurrencyChange = res.Currency;
        this.currencySymbol = res.CurrencySymbol;
      }
    });

    this.IsUserCustomer = this.sharedDataService.IsCustomer();
    this.IsAdmin = this.sharedDataService.IsAdmin();
    //Dynamic Set Title
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
    this.userAction = pageTite;

    if (this.activatedRoute.snapshot.paramMap.get("id") == null) {
      //   set current position
      this.setCurrentPosition();
    }
    //End -- Set Title

    this.bindCodeData();
    this.getGeolocation();
    if (this.activatedRoute.snapshot.paramMap.get("id") != null) {
      let Id: number = parseInt(
        this.activatedRoute.snapshot.paramMap.get("id")
      );
      this.assetDetailsModel.assetID = Id;

      this.AdminEntity.Table = "Asset";
      this.AdminEntity.AssociatedID = Id;
      this.showCloneButton = true;
      this.HideShowJobHistory();
      this.showJobHistoryButton = true;
      this.updateAssetModel(Id);

      // this.getSubAssetByID(Id)
    }
    this.dataValid = true;
    this.datathicknessDepthValid = true;
    this.datawidthBreadthValid = true;
  }

  Audit: Array<any> = new Array<any>();
  BookAudit: Array<any> = new Array<any>();
  public onTabSelect(e) {
    if (this.activatedRoute.snapshot.paramMap.get("id") != null) {
      let Id: number = parseInt(
        this.activatedRoute.snapshot.paramMap.get("id")
      );
      if (e.index == 1 || e.index == 2 ) {
        this.AdminEntity.Table = "Asset";
        this.AdminEntity.AssociatedID = Id;
      } else if (e.index == 4) {
        this.DataService.GetAuditLog(Id).subscribe((res) => {
          this.Audit = res;
          this.Audit = this.Audit.filter((ele) => {
            return ele.type != "BA";
          });
          this.gridData = process(this.Audit, this.state);
        });
        this.DataService.GetBookAuditLog(Id).subscribe((res) => {
          this.BookAudit = res;
          this.BookAudit = this.BookAudit.filter((ele) => {
            return ele.type == "BA";
          });
          this.gridBookData = process(this.BookAudit, this.state);
          console.log(this.gridBookData);
        });
      }
    }
    this.onReady(this._map);
  }

  // GetAssetFieldLabelByLang(Lang: string) {
  //   this.DataService.GetAssetFieldLabelByLang(Lang).subscribe((res) => {
  //     if (res != null) {
  //       this.AssetDataLabel = res
  //     }
  //   })
  // }

  GetAssetFieldsLabel() {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        console.log(res);
        if (res != undefined) {
          this.AssetDataLabel = res[30];
          console.log(this.AssetDataLabel);
        }
      });
    }
  }

  FieldNameChange(FieldName: string) {
    var FieldLabel = this.AssetDataLabel.filter((data) => {
      return (
        FieldName.toLowerCase().toString() ===
        data.FieldName.toLowerCase().toString()
      );
    });
   
    return FieldLabel[0].DisplayName;
  }

  // GroupFieldNameChange(FieldName: string) {

  //   // let Name = FieldName.replace(/\s+/g, "");
  //   let Name = FieldName;

  //   if (FieldName != '') {
  //     return Name
  //   }
  //   else {
  //     return Name
  //   }

  // }
  assetFieldGroup: any;
  GroupFieldNameChange(GroupName: string) {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined && GroupName != "") {
          this.assetFieldGroup = res[41];
        }
      });
    }

    if (GroupName != "" && GroupName != null) {
      let Name = GroupName.replace(/\s+/g, "");
      var Groupname = this.assetFieldGroup.filter((data) => {
        return Name.toString() === data.GroupName.toString();
      });
      return Groupname[0].DisplayName;
    }
  }

  ngAfterViewInit() {
    this.validateAssetImages();
  }

  ngAfterViewChecked() {
    if (this.ddlUOMCd.length > 0) {
      this.ddlUOMTest = this.ddlUOMCd;
    }
  }

  ShowCommentHistory(commentsValue) {
    if (commentsValue != undefined) {
      this.CommentType = commentsValue;
      if (this.CommentType.toLowerCase() == "genc") {
        this.CommentHistoryTitle = "General Comments History";
      } else if (this.CommentType.toLowerCase() == "ohsc") {
        this.CommentHistoryTitle = "OHS Comments History";
      }
    }

    this.opened = true;
  }

  public closeComment() {
    this.opened = false;
  }

  public width = "100%";
  public height = "250px";
  public widthSub = "100%";
  public heightSub = "50px";

  ImageError: any = false;
  childData: any;

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.gridData = process(this.Audit, this.state);
  }

  protected dataBookingStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.gridBookData = process(this.BookAudit, this.state);
  }

  toggelSubAsset() {
    if (this.ShowHideSubAssets) {
      this.ShowHideSubAssets = false;
    } else {
      this.subAssetModel = new SubAssetEntity();
      this.ShowHideSubAssets = true;
    }
  }

  SubAssetsData(event: SubAssetEntity[]) {
    this.ArraySubAssets = event;
  }

  DocumentsData(event: DocumentsUploadEntity[]) {
    this.DocumentData = event;
  }

  checkSubAssetsValue() {
    if (
      document.getElementById("conditionRating")["value"] != null &&
      document.getElementById("conditionRating")["value"] != undefined &&
      document.getElementById("assetTypeSub")["value"] != null &&
      document.getElementById("assetTypeSub")["value"] != undefined &&
      document.getElementById("userAssetid")["value"] != null &&
      document.getElementById("userAssetid")["value"] != undefined &&
      document.getElementById("areaLocatedSub")["value"] != null &&
      document.getElementById("areaLocatedSub")["value"] != undefined
    ) {
      document.getElementById("AddSubAsset").setAttribute("disabled", "true");
    } else {
      document.getElementById("AddSubAsset").setAttribute("disabled", "false");
    }
  }

  AddSubAsset() {
    this.toggelSubAsset();
    this.validateAssetImages();

    if (Object.keys(this.subAssetModel).length > 0) {
      if (
        this.subAssetModel.index != undefined &&
        this.subAssetModel.index != null
      ) {
        this.ArraySubAssets.forEach((element) => {
          if (element.index == this.subAssetModel.index) {
            element.areaLocated = this.subAssetModel.areaLocated;
            element.assetID = this.subAssetModel.assetID;
            element.assetType = this.subAssetModel.assetType;
            element.conditionRating = this.subAssetModel.conditionRating;
            element.heightLength = this.subAssetModel.heightLength;
            element.makeCd = this.subAssetModel.makeCd;
            element.model = this.subAssetModel.model;
            element.serialNo = this.subAssetModel.serialNo;
            element.subAssetID = this.subAssetModel.subAssetID;
            element.thicknessDepth = this.subAssetModel.thicknessDepth;
            element.widthBreadth = this.subAssetModel.widthBreadth;
            element.index = this.subAssetModel.index;
            element.userAssetID = this.subAssetModel.userAssetID;
          }
        });

        this.subAssetModel = new SubAssetEntity();
      } else {
        if (this.ArraySubAssets == null) {
          this.ArraySubAssets = new Array<SubAssetEntity>();
          this.subAssetModel.index = this.ArraySubAssets.length;
          this.ArraySubAssets.push(this.subAssetModel);
        } else {
          this.subAssetModel.index = this.ArraySubAssets.length;
          this.ArraySubAssets.push(this.subAssetModel);
        }

        this.subAssetModel = new SubAssetEntity();
      }
    } else {
      this.notify.error("The fields cannot be empty");
    }
  }

  onValueChangeAssetType(event) {
    console.log("cliked..",event);

    this.AssetTypeRmList = [];

    if (this.OldAssetTypeID != undefined && this.OldAssetTypeID != undefined) {
      this.IsCancelClickedAssetType = true;
    }
    this.GetReplacementCostBugget();
  }

  onValueChangeLocation(event) {
    if (this.OldLocationID != undefined && this.OldLocationID != undefined) {
      this.IsCancelClickedLocation = true;
    }
  }

  EnableClone() {
    this.IsCancelClickedClone = true;
    this.showJobHistoryButton = false;
  }
  HideShowJobHistory() {
    var User = this.sharedDataService.GetUserToken();
    if (User.ShowJobHistory.toLowerCase() == "true") {
      return true;
    } else {
      return false;
    }
  }

  JobHistory() {
    if (this.assetDetailsModel.assetID > 0) {
      // document.getElementById('job')['href'] = window.location.origin + '/#/ViewAssetsJobs/' + this.assetDetailsModel.assetID;
      document.getElementById("job")["href"] =
        window.location.origin +
        "/ViewAssetsJobs/" +
        this.assetDetailsModel.assetID;
    }
  }

  computeCalculation(fieldName: string) {
    if (
      fieldName.toLowerCase() == "assetsizecd" ||
      fieldName.toLowerCase() == "uomcd" ||
      fieldName.toLowerCase() == "conditionratingcd" ||
      fieldName.toLowerCase() == "maintenancestdcd" ||
      fieldName.toLowerCase() == "essentialservicecd" ||
      fieldName.toLowerCase() == "assetruntimecd" ||
      fieldName.toLowerCase() == "criticalitycd" ||
      fieldName.toLowerCase() == "refrigerantcd" ||
      fieldName.toLowerCase() == "environmentcd"
    ) {
      this.CalculateActualAge(undefined, undefined);
    }
  }
  getData(fieldName, fieldType) {
    if (fieldName.toLowerCase() == "conditionratingcd") {
      this.ddlConditionRatingCd.sort(function (obj1, obj2) {
        // Ascending: first age less than the previous
        return obj1.codeID - obj2.codeID;
      });
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }

      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "mfgcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlMfgCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlMfgCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlMfgCd;
    } else if (fieldName.toLowerCase() == "statuscd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlStatusCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlStatusCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlStatusCd;
    } else if (fieldName.toLowerCase() == "indooroutdoorcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlIndoorOutdoorCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlIndoorOutdoorCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlIndoorOutdoorCd;
    } else if (fieldName.toLowerCase() == "dampersfittedcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlDampersFittedCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlDampersFittedCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlDampersFittedCd;
    } else if (fieldName.toLowerCase() == "filtertypecd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlFilterTypeCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlFilterTypeCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlFilterTypeCd;
    } else if (fieldName.toLowerCase() == "firedoorfrlratingcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlFireDoorFRLRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlFireDoorFRLRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlFireDoorFRLRatingCd;
    } else if (fieldName.toLowerCase() == "firemodefncd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlFireModeFnCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlFireModeFnCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlFireModeFnCd;
    } else if (fieldName.toLowerCase() == "maintenancecompletedcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlyesnonaCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlyesnonaCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlyesnonaCd;
    } else if (fieldName.toLowerCase() == "ownershipcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlOwnershipCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlOwnershipCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlOwnershipCd;
    } else if (fieldName.toLowerCase() == "refrigerantcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlRefrigerantCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlRefrigerantCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlRefrigerantCd;
    } else if (fieldName.toLowerCase() == "environmentcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlEnvironmentCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlEnvironmentCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlEnvironmentCd;
    } else if (fieldName.toLowerCase() == "assetruntimecd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlAssetRunTimeCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlAssetRunTimeCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlAssetRunTimeCd;
    } else if (fieldName.toLowerCase() == "criticalitycd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlCriticalityCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlCriticalityCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlCriticalityCd;
    } else if (fieldName.toLowerCase() == "essentialservicecd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlEssentialServiceCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlEssentialServiceCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlEssentialServiceCd;
    } else if (fieldName.toLowerCase() == "maintenancestdcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlMaintenanceStdCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlMaintenanceStdCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlMaintenanceStdCd;
    } else if (fieldName.toLowerCase() == "prioritycd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlPriorityCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlPriorityCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlPriorityCd;
    } else if (fieldName.toLowerCase() == "recommendedactioncd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlRecommendedActionCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlRecommendedActionCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlRecommendedActionCd;
    } else if (fieldName.toLowerCase() == "auditconditionrating") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "coolingcoilcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "ductworkandinsulation") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "economydamperscondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "electricalwiringcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "heatingcoilcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "indoorcoilcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "outdoorcoilcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "refrigpipeworkcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "supplyairfanmotorcondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "valvesandactuatorscondition") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlConditionRatingCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlConditionRatingCd;
    } else if (fieldName.toLowerCase() == "ownershipcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlOwnershipCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlOwnershipCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlOwnershipCd;
    } else if (fieldName.toLowerCase() == "maintenancefreqcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlMaintenanceFreqCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlMaintenanceFreqCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlMaintenanceFreqCd;
    } else if (fieldName.toLowerCase() == "yesnonacd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlyesnonaCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlyesnonaCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlyesnonaCd;
    } else if (fieldName.toLowerCase() == "assetsizecd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlAssetSizeCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlAssetSizeCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlAssetSizeCd;
    } else if (fieldName.toLowerCase() == "permits") {
      if (
        fieldType.toLowerCase() == "MultiDropdown" &&
        !this.ddlPermitsCd.includes(this.ddlModelDataDefaultItem)
      ) {
      }
      return this.ddlPermitsCd;
    }
    //new field
    else if (fieldName.toLowerCase() == "fueltype") {
      return this.ddlFuelTypeCd;
    }
    //new field
    else if (fieldName.toLowerCase() == "uomcd") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlUOMCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlUOMCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlUOMCd;
    } else if (fieldName.toLowerCase() == "modelnbr") {
      if (
        fieldType.toLowerCase() == "combobox" &&
        !this.ddlModelCd.includes(this.ddlModelDataDefaultItem)
      ) {
        this.ddlModelCd.push(this.ddlModelDataDefaultItem);
      }
      return this.ddlModelCd;
    }
  }

  renderTemplate() {
    if (this.activatedRoute.snapshot.paramMap.get("id") != null) {
      this.panelExpand = true;
    } else {
      this.panelExpand = false;
    }
    // if(this.IsValidationDisabled==true){
    //   this.panelExpand = false;
    // }
    // else{
    //   this.panelExpand = true;
    // }
    this.GetUserLocationDropdown("");
    this.GetAssetTypeByNameDropDownUrl("");

    if (
      this.assetDetailsModel.assetTypeID != undefined &&
      this.assetDetailsModel.assetTypeID != null &&
      this.assetDetailsModel.assetLocationID != undefined &&
      this.assetDetailsModel.assetLocationID != null
    ) {
      this.GetAssetFieldConfigurationTemplate();

      this.GetAssetypeFaultByID(this.assetDetailsModel.assetTypeID);
      this.GetAssetypeFixByID(this.assetDetailsModel.assetTypeID);
      if (
        this.assetDetailsModel.assetTypeID != undefined &&
        this.assetDetailsModel.assetLocationID != undefined
      ) {
        // this.CalculateActualAge(undefined, undefined);
      }
    } else {
      this.notify.error("Please select Location and Asset type");
    }
  }

  GetAssetypeFaultByID(assetTypeID) {
    this.DataService.GetAssetypeFaultByID(assetTypeID).subscribe((elem) => {
      this.AssetTypeFaultListModel = elem;
      let aRR = [];
      this.AssetTypeFaultListModel.forEach((element) => {
        aRR.push({
          faultID: element.faultID.toString(),
          faultName: element.faultName,
          groupName: element.groupName,
        });
      });
      this.AssetTypeFaultListModel = aRR;
    });
  }

  GetAssetypeFixByID(assetTypeID) {
    this.DataService.GetassetTypeFixByID(assetTypeID).subscribe((e) => {
      this.AssetTypeFixListModel = e;
      let aRR = [];
      this.AssetTypeFixListModel.forEach((element) => {
        aRR.push({ fixID: element.fixID.toString(), fixName: element.fixName });
      });
      this.AssetTypeFixListModel = aRR;
    });
  }

  updateAssetModel(Id) {
    this.DataService.GetAssetByID(Id).subscribe(
      (res) => {
       

        if (res != null) {
          if (
            res.assetEntity.quantity == null ||
            res.assetEntity.quantity == undefined
          ) {
            res.assetEntity.quantity = 1;
          }
          this.IsEdit = true;
          this.assetDetailsModel = res.assetEntity;
          this.assetDetailsModel.isIncomplete = res.assetEntity.isIncomplete;
          this.IsValidationDisabled = res.assetEntity.isIncomplete;
          this.assetDetailsModel.replacementCostBudget =
            this.assetDetailsModel.replacementCostBudget == null
              ? 0
              : Number.parseFloat(
                this.assetDetailsModel.replacementCostBudget.toFixed(2)
              );
              console.log("cost-r : ",this.assetDetailsModel.replacementCostBudget);
          this.assetDetailsModel.customerBudgetPrice =
            res.assetEntity.customerBudgetPrice;

          this.assetDetailsModel.assetTypeID = res.assetEntity.assetTypeID;

          let arrFault = [];
          if (
            res.assetEntity.faultCodes == "" ||
            res.assetEntity.faultCodes == null
          ) {
            this.assetDetailsModel.faultCodes = null;
          } else {
            res.assetEntity.faultCodes.split(",").forEach((element) => {
              arrFault.push(element.toString());
            });
            this.assetDetailsModel.faultCodes = arrFault;
          }

          let arrFix = [];
          if (
            res.assetEntity.fixCodes == "" ||
            res.assetEntity.fixCodes == null
          ) {
            this.assetDetailsModel.fixCodes = null;
          } else {
            res.assetEntity.fixCodes.split(",").forEach((element) => {
              arrFix.push(element.toString());
            });
            this.assetDetailsModel.fixCodes = arrFix;
          }

          this.assetDetailsModel.assetLocationID =
            res.assetEntity.assetLocationID;
          this.assetDetailsModel.installDate =
            res.assetEntity.installDate == null
              ? null
              : new Date(res.assetEntity.installDate);

          //new business fields
          this.assetDetailsModel.purchaseDate =
            res.assetEntity.purchaseDate == null
              ? null
              : new Date(res.assetEntity.purchaseDate);
          this.assetDetailsModel.registrationExpiryDate =
            res.assetEntity.registrationExpiryDate == null
              ? null
              : new Date(res.assetEntity.registrationExpiryDate);
          this.assetDetailsModel.nextService =
            res.assetEntity.nextService == null
              ? null
              : new Date(res.assetEntity.nextService);
          this.assetDetailsModel.nextTestandTag =
            res.assetEntity.nextTestandTag == null
              ? null
              : new Date(res.assetEntity.nextTestandTag);
          this.assetDetailsModel.lastTestandTag =
            res.assetEntity.lastTestandTag == null
              ? null
              : new Date(res.assetEntity.lastTestandTag);
          //new business fields

          this.assetDetailsModel.lastAnnual =
            res.assetEntity.lastAnnual == null
              ? null
              : new Date(res.assetEntity.lastAnnual);

          this.assetDetailsModel.lastService =
            res.assetEntity.lastService == null
              ? null
              : new Date(res.assetEntity.lastService);
          this.assetDetailsModel.reccWithdrawalDate =
            res.assetEntity.reccWithdrawalDate == null
              ? null
              : new Date(res.assetEntity.reccWithdrawalDate);
          //this.assetDetailsModel.auditduration = res.assetEntity.auditduration == null ? null : new Date(res.assetEntity.auditduration)
          this.assetDetailsModel.dLPEndDate =
            res.assetEntity.dlpEndDate == null
              ? null
              : new Date(res.assetEntity.dlpEndDate);
          // this.assetDetailsModel.assetCaptureDate = res.assetEntity.assetCaptureDate == null ? null : new Date(res.assetEntity.assetCaptureDate)
          this.assetDetailsModel.assetCaptureDate =
            res.assetEntity.assetCaptureDate;

          this.assetDetailsModel.uOMCd = parseInt(res.assetEntity.uomCd);
          this.assetDetailsModel.firstauditduration =
            res.assetEntity.firstAuditduration;
          this.assetDetailsModel.lastauditduration =
            res.assetEntity.lastAuditduration;
          this.assetDetailsModel.oHSComments = res.assetEntity.ohsComments;
          this.assetDetailsModel.rMBudgetYear1 = res.assetEntity.rmBudgetYear1;
          this.assetDetailsModel.rMBudgetYear2 = res.assetEntity.rmBudgetYear2;
          this.assetDetailsModel.rMBudgetYear3 = res.assetEntity.rmBudgetYear3;
          this.assetDetailsModel.rMBudgetYear4 = res.assetEntity.rmBudgetYear4;
          this.assetDetailsModel.rMBudgetYear5 = res.assetEntity.rmBudgetYear5;
          this.assetDetailsModel.rMBudgetYear6 = res.assetEntity.rmBudgetYear6;
          this.assetDetailsModel.rMBudgetYear7 = res.assetEntity.rmBudgetYear7;
          this.assetDetailsModel.rMBudgetYear8 = res.assetEntity.rmBudgetYear8;
          this.assetDetailsModel.rMBudgetYear9 = res.assetEntity.rmBudgetYear9;
          this.assetDetailsModel.rMBudgetYear10 =
            res.assetEntity.rmBudgetYear10;
          this.assetDetailsModel.rMBudgetYear11 =
            res.assetEntity.rmBudgetYear11;
          this.assetDetailsModel.rMBudgetYear12 =
            res.assetEntity.rmBudgetYear12;
          this.assetDetailsModel.rMBudgetYear13 =
            res.assetEntity.rmBudgetYear13;
          this.assetDetailsModel.rMBudgetYear14 =
            res.assetEntity.rmBudgetYear14;
          this.assetDetailsModel.rMBudgetYear15 =
            res.assetEntity.rmBudgetYear15;
          this.assetDetailsModel.rMBudgetYear16 =
            res.assetEntity.rmBudgetYear16;
          this.assetDetailsModel.rMBudgetYear17 =
            res.assetEntity.rmBudgetYear17;
          this.assetDetailsModel.rMBudgetYear18 =
            res.assetEntity.rmBudgetYear18;
          this.assetDetailsModel.rMBudgetYear19 =
            res.assetEntity.rmBudgetYear19;
          this.assetDetailsModel.rMBudgetYear20 =
            res.assetEntity.rmBudgetYear20;
          this.assetDetailsModel.hYDRStorz = res.assetEntity.hydrStorz;

          this.assetDetailsModel.replacementCostBudget =
            res.assetEntity.replacementCostBudget;

          this.assetDetailsModel.rMBudgetYear1 =
            res.assetEntity.rmBudgetYear1 == 0
              ? null
              : res.assetEntity.rmBudgetYear1;
          this.assetDetailsModel.rMBudgetYear2 =
            res.assetEntity.rmBudgetYear2 == 0
              ? null
              : res.assetEntity.rmBudgetYear2;
          this.assetDetailsModel.rMBudgetYear3 =
            res.assetEntity.rmBudgetYear3 == 0
              ? null
              : res.assetEntity.rmBudgetYear3;
          this.assetDetailsModel.rMBudgetYear4 =
            res.assetEntity.rmBudgetYear4 == 0
              ? null
              : res.assetEntity.rmBudgetYear4;
          this.assetDetailsModel.rMBudgetYear5 =
            res.assetEntity.rmBudgetYear5 == 0
              ? null
              : res.assetEntity.rmBudgetYear5;
          this.assetDetailsModel.rMBudgetYear6 =
            res.assetEntity.rmBudgetYear6 == 0
              ? null
              : res.assetEntity.rmBudgetYear6;
          this.assetDetailsModel.rMBudgetYear7 =
            res.assetEntity.rmBudgetYear7 == 0
              ? null
              : res.assetEntity.rmBudgetYear7;
          this.assetDetailsModel.rMBudgetYear8 =
            res.assetEntity.rmBudgetYear8 == 0
              ? null
              : res.assetEntity.rmBudgetYear8;
          this.assetDetailsModel.rMBudgetYear9 =
            res.assetEntity.rmBudgetYear9 == 0
              ? null
              : res.assetEntity.rmBudgetYear9;
          this.assetDetailsModel.rMBudgetYear10 =
            res.assetEntity.rmBudgetYear10 == 0
              ? null
              : res.assetEntity.rmBudgetYear10;
          this.assetDetailsModel.rMBudgetYear11 =
            res.assetEntity.rmBudgetYear11 == 0
              ? null
              : res.assetEntity.rmBudgetYear11;
          this.assetDetailsModel.rMBudgetYear12 =
            res.assetEntity.rmBudgetYear12 == 0
              ? null
              : res.assetEntity.rmBudgetYear12;
          this.assetDetailsModel.rMBudgetYear13 =
            res.assetEntity.rmBudgetYear13 == 0
              ? null
              : res.assetEntity.rmBudgetYear13;
          this.assetDetailsModel.rMBudgetYear14 =
            res.assetEntity.rmBudgetYear14 == 0
              ? null
              : res.assetEntity.rmBudgetYear14;
          this.assetDetailsModel.rMBudgetYear15 =
            res.assetEntity.rmBudgetYear15 == 0
              ? null
              : res.assetEntity.rmBudgetYear15;
          this.assetDetailsModel.rMBudgetYear16 =
            res.assetEntity.rmBudgetYear16 == 0
              ? null
              : res.assetEntity.rmBudgetYear16;
          this.assetDetailsModel.rMBudgetYear17 =
            res.assetEntity.rmBudgetYear17 == 0
              ? null
              : res.assetEntity.rmBudgetYear17;
          this.assetDetailsModel.rMBudgetYear18 =
            res.assetEntity.rmBudgetYear18 == 0
              ? null
              : res.assetEntity.rmBudgetYear18;
          this.assetDetailsModel.rMBudgetYear19 =
            res.assetEntity.rmBudgetYear19 == 0
              ? null
              : res.assetEntity.rmBudgetYear19;
          this.assetDetailsModel.rMBudgetYear20 =
            res.assetEntity.rmBudgetYear20 == 0
              ? null
              : res.assetEntity.rmBudgetYear20;
          this.assetDetailsModel.jobCount =
            res.assetEntity.jobCount == 0 ? null : res.assetEntity.jobCount;
          this.assetDetailsModel.totalSpend =
            res.assetEntity.totalSpend == 0 ? null : res.assetEntity.totalSpend;

          if (
            this.assetDetailsModel.reccWithdrawalDate != null ||
            this.assetDetailsModel.reccWithdrawalDate != undefined
          ) {
            let CurrDate = new Date();
            // var diff = Math.abs(this.assetDetailsModel.reccWithdrawalDate.getTime() - CurrDate.getTime());
            // this.plannedReplacementDate = Math.round(diff / (1000 * 3600 * 24 * 365));
            // //customer defined calculated replacement year
            // this.assetDetailsModel.calculatedReplacementYear = this.plannedReplacementDate + CurrDate.getFullYear();

            var diff =
              1 +
              Math.abs(
                this.assetDetailsModel.reccWithdrawalDate.getFullYear() -
                CurrDate.getFullYear()
              );
            this.plannedReplacementDate = diff;
            //customer defined calculated replacement year
            //  this.assetDetailsModel.calculatedReplacementYear = this.plannedReplacementDate + CurrDate.getFullYear();
          //  this.assetDetailsModel.calculatedReplacementYear =
            //  this.assetDetailsModel.reccWithdrawalDate.getFullYear();
          }
          if (res.imageData != undefined && res.imageData != null) {
            res.imageData.forEach((element) => {
              this.imagePreviewsViewer.push(
                "data:image/png;base64," + element.imagePath
              );

              this.imagePreviews.push({
                src: "data:image/png;base64," + element.imagePath,
                uid: element.imageDataID,
                extension: element.extension,
                IsUpdated: false,
                date: element.creationDate,
                description: element.description,
              });
            });
          }

          this.validateAssetImages();

          let arr: any = [];

          // this.permitValue = ['Hemant']

          // this.assetDetailsModel.permits = res.assetEntity.permits.toString();
          // if (res.assetEntity.permits != undefined && res.assetEntity.permits != null) {
          //   let arr: any = [];
          //   res.assetEntity.permits.split(',').forEach(element => {
          //     arr.push(element);
          //   });
          //   this.assetDetailsModel.permits = [];
          //   this.assetDetailsModel.permits.push(parseInt(arr));
          // }

          if (
            res.assetEntity.permits != "" &&
            res.assetEntity.permits != null
          ) {
            res.assetEntity.permits.split(",").forEach((element) => {
              arr.push(parseInt(element));

              // this.ddlPermitsCd.push({ codeValue: '', codeID: parseInt(element), weightage: 0 })
              this.ddlModelDataDefaultItem.codeID = arr;
              this.assetDetailsModel.permits =
                this.ddlModelDataDefaultItem.codeID;

              // this.ddlModelCd.push({ codeValue: element.model, codeID: 0, weightage: 0 });
              // this.ModelArray.push({ "mfgCd": element.mfgCd, "model": element.model, "uomCd": element.uomCd, "electricalInputKW": element.electricalInputKW, "capacity": element.capacity })
            });
            // this.assetDetailsModel.permits = [];
            // this.assetDetailsModel.permits.push(parseInt(arr));
          } else if (
            res.assetEntity.permits == "" &&
            res.assetEntity.permits == null
          ) {
            this.assetDetailsModel.permits = "";
          }

          if (
            this.assetDetailsModel.geoLocation != undefined &&
            this.assetDetailsModel.geoLocation != "" &&
            this.assetDetailsModel.geoLocation.split(",") != undefined &&
            this.assetDetailsModel.geoLocation.split(",")[0] != "NaN"
          ) {
            this.latitude = parseFloat(
              this.assetDetailsModel.geoLocation.split(",")[0]
            );
            this.longitude = parseFloat(
              this.assetDetailsModel.geoLocation.split(",")[1]
            );

            if (
              this.assetDetailsModel.geoLocation.split(",")[2] != undefined &&
              this.assetDetailsModel.geoLocation.split(",")[2] != "null"
            ) {
              this.altitude =
                "," +
                parseFloat(this.assetDetailsModel.geoLocation.split(",")[2]);
            } else {
              this.altitude = "";
            }
            this.assetDetailsModel.geoLocation =
              this.latitude + "," + this.longitude + this.altitude;
              this.onReady(this._map)
          }

          this.generalCommentsOld =
            this.assetDetailsModel.generalComments == null
              ? ""
              : this.assetDetailsModel.generalComments;
          this.oHSCommentsOld =
            this.assetDetailsModel.oHSComments == null
              ? ""
              : this.assetDetailsModel.oHSComments;

          this.renderTemplate();

          //BIND MODEL DATA
          // this.onSelectmanufacture(this.assetDetailsModel);
          //BIND MODEL DATA
        }
      },
      (error) => {
        this.notify.error("Some error occured");
      }
    );
  }

  RotateImageForward(srcBase64, uid, Orientation, Rotation, index,imageOriginal) {
    var img = new Image();
    let srcOrientation = null;
    if (Orientation != undefined) {
      srcOrientation = Orientation;
    } else {
      srcOrientation = 1;
    }

    if (Rotation == "F") {
      //Setting Forward orientation
      if (srcOrientation == 1) srcOrientation = 6;
      else if (srcOrientation == 8) srcOrientation = 6;
    } else if (Rotation == "B") {
      if (srcOrientation == 1) srcOrientation = 8;
      else if (srcOrientation == 6) srcOrientation = 8;
    }

    // Orientation = srcOrientation
    img.onload = () => {
      var width = img.width,
        height = img.height ,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

  

      // draw image
      ctx.drawImage(img, 0, 0,width,height);
      
     var quality=1;
     var image ='';

     if( canvas.toDataURL("image/jpeg",quality).length >1000000){
      while(imageOriginal.src.length<(canvas.toDataURL("image/jpeg",quality).length)){
        quality=quality-0.1
       image =this.ImageCompressor(canvas,quality)
       
      }
     }
     else {
      image =canvas.toDataURL("image/jpeg",1)

     }
      this.test(image, uid, srcOrientation, index,imageOriginal);
      // export base64
 
      document.getElementById(uid)["src"] = image;
    };

    this.imagePreviews.forEach((element) => {
      if (element.uid === uid) {
        element.Orientation = srcOrientation;
      }
    });
    img.src = srcBase64;
  }
  ImageCompressor(canvas,quality){
    return canvas.toDataURL("image/jpeg",quality)
  }
  test(data, uid, srcOrientation, index,imageOriginal) {
    this.imagePreviews.forEach((element) => {
      if (element.uid === uid) {
       
        element.src = data;
        element.Orientation = srcOrientation;
        element.IsUpdated = true;
      }
    });
    this.imagePreviewsViewer[index] = data;
  }

  RotateImageForwardSub(srcBase64, uid, Orientation, Rotation, index,imageOriginal) {
    var img = new Image();
    let srcOrientation;
    if (Orientation != undefined) {
      srcOrientation = Orientation;
    } else {
      srcOrientation = 1;
    }

    if (Rotation == "F") {
      //Setting Forward orientation
      if (srcOrientation == 1) srcOrientation = 6;
      else if (srcOrientation == 8) srcOrientation = 6;
    } else if (Rotation == "B") {
      if (srcOrientation == 1) srcOrientation = 8;
      else if (srcOrientation == 6) srcOrientation = 8;
    }

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0,width,height);
      var quality=1;
      var image ='';
      if( canvas.toDataURL("image/jpeg",quality).length >1000000){
       while(imageOriginal.src.length<(canvas.toDataURL("image/jpeg",quality).length)){
         quality=quality-0.1
        image =this.ImageCompressor(canvas,quality)
        
       }
      }
      else{
        image =canvas.toDataURL("image/jpeg",1)
      }
     
      this.testSub(image, uid, srcOrientation, index);
      // export base64

      document.getElementById(uid)["src"] = image
    };

    this.subAssetModel.SubAssetImages.forEach((element) => {
      if (element.uid === uid) {
        element.Orientation = srcOrientation;
      }
    });
    img.src = srcBase64;
  }

  testSub(data, uid, srcOrientation, index) {
    this.subAssetModel.SubAssetImages.forEach((element) => {
      if (element.uid === uid) {
        element.src = data;
        element.Orientation = srcOrientation;  
        element.IsUpdated = true;
      }
    }); 
    this.imagePreviewsSubViewer[index] = data;
  }

  getGeolocation() {
    if(this.SelectedMap=='Google'){
    this.assetDetailsModel.geoLocation = this.latitude + "," + this.longitude;
    //create search FormControl
    this.searchControl = new FormControl();
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"],
        }
      );

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: any = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.assetDetailsModel.geoLocation =
            this.latitude + "," + this.longitude;
        });
      });
    });
  }
  }

  handleEvent(value) {
    if (value == "click") {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }
  }

  //swapping assetImages

  swapImages: any = [];
  swapAssetImage(assetImages) {
    // this.tempArray.push(event)
    this.swapImages = assetImages;

    console.log(this.swapImages);
    // console.log(this.imagePreviews)
    // console.log(array)
  }

  //imageText

  textlengthBoolean: boolean = false;
  getImagetext(text) {
    console.log(text.length);
    if (text.length == 500) {
      this.textlengthBoolean = true;
      setTimeout(() => {
        this.textlengthBoolean = false;
      }, 4000);
    }
  }

  isTextEdit: boolean = true;
  isCancelbtn: boolean = false;
  isSavebtn: boolean = false;
  isEditbtn: boolean = true;
  assetImgDesc(value, index) {
    console.log(index);
    if (value == "edit") {
      this.isTextEdit = false;
      this.isCancelbtn = true;
      this.isSavebtn = true;
      this.isEditbtn = false;
      this.positionCursor();
    }
    if (value == "cancel") {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }

    if (value == "save") {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }
  }

  // focus to the description box
  positionCursor() {
    document.getElementById("description").focus();
    document.execCommand("selectAll", false, null);
    document.getSelection().collapseToEnd();
  }

  // cancel Image PopUP

  cancelledClickImage(item) {
    if (item != undefined) {
      this.ImageID = item.uid;
      this.IsCancelClickedImage = true;
    }
  }

  ClickImage(item) {
    if (item != undefined) {
      this.ImageID = item.uid;
      this.ClickedImage = true;
    }
  }

  EditClickImage(item) {
    if (item != undefined) {
      this.ImageID = item.uid;
      this.IsEditClickedImage = true;
    }
  }

  closeEditImage(val) {
    this.IsEditClickedImage = false;
  }

  cancelledClickSubImage(item) {
    if (item != undefined) {
      this.SubAssetImageID = item.uid;
      this.IsCancelClickedSubImage = true;
    }
  }

  closePreviewImage(value) {
    this.ClickedImage = value == "false" ? false : true;
    this.handleEvent("click");
  }

  closePreviewImageSub(value) {
    this.ClickedImageSub = value == "false" ? false : true;
  }

  closeImage(val) {
    if (val != undefined && val == "true") {
      const index = this.imagePreviews.findIndex(
        (item) => item.uid === this.ImageID
      );
      if (index >= 0) {
        if (typeof this.ImageID == "string") {
          this.imagePreviews.splice(index, 1);
          this.imagePreviewsViewer.splice(index, 1);
          this.IsCancelClickedImage = false;
        } else if (typeof this.ImageID == "number") {
          this.DataService.DeleteAssetImage(this.ImageID).subscribe((res) => {
            if (res != null && res != undefined) {
              // alert('deleted')
              this.imagePreviews.splice(index, 1);
              this.imagePreviewsViewer.splice(index, 1);
              this.IsCancelClickedImage = false;
            }
          });
        }
      }
    } else {
      this.IsCancelClickedImage = false;
    }
  }
  closeDialogAssetType() {
    this.IsCancelClickedAssetType = false;
  }
  closeDialogLocation() {
    this.IsCancelClickedLocation = false;
  }
  closeDialogClone() {
    this.IsCancelClickedClone = false;
  }

  closeSubImage(val) {
    if (val != undefined && val == "true") {
      const index = this.subAssetModel.SubAssetImages.findIndex(
        (item) => item.uid === this.SubAssetImageID
      );

      if (index >= 0) {
        this.TempImagArray = this.subAssetModel.SubAssetImages;

        this.DataService.DeleteSubAssetImage(this.SubAssetImageID).subscribe(
          (res) => {
            if (res != null && res != undefined) {
              this.subAssetModel.SubAssetImages.splice(index, 1);
              this.imagePreviewsSubViewer.splice(index, 1);
              this.IsCancelClickedSubImage = false;
            }
          }
        );
      }
    } else {
      this.IsCancelClickedSubImage = false;
    }
  }

  //Canclec PopUp
  cancelledClick() {
    this.isCancelclicked = true;
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event["coords"]["lat"];
    this.longitude = $event["coords"]["lng"];
    if (this.altitude == undefined) {
      this.altitude = "";
    }
    this.assetDetailsModel.geoLocation =
      this.latitude + "," + this.longitude + this.altitude;
  }

  close(val, id, spliceid) {
    if (val != undefined && val == "true") {
      if (id == undefined) {
        this.ArraySubAssets.splice(spliceid, 1);
        this.isCancelclicked = false;
      } else {
        this.DataService.DeleteAssetByID(id).subscribe((res) => {
          this.ArraySubAssets.splice(spliceid, 1);
          this.isCancelclicked = false;
        });
      }
    } else {
      this.isCancelclicked = false;
    }
  }

  //not in use
  // SaveAssetDetails(form: FormControl) {

  //   Object.entries(form['controls']).forEach((ele) => {

  //     var test = ele[0];
  //     if (document.getElementById(ele[0]) != null) {

  //       ele[1]['touched'] = true;
  //       document.getElementById(ele[0]).hidden = ele[1]['valid'];
  //     }
  //   });

  //   if (form.valid && this.validateAssetImages()) {
  //     this.submitted = true;
  //     Object.entries(this.assetDetailsModel).forEach((ele) => {
  //       if (ele[1] == '0001-01-01T00:00:00') {
  //         ele[1] = null;
  //         this.assetDetailsModel[ele[0]] = ele[1];
  //       }
  //     });

  //     if (this.assetDetailsModel.permits != null && this.assetDetailsModel.permits != NaN && this.assetDetailsModel.permits != undefined) {
  //       this.assetDetailsModel.permits = this.assetDetailsModel.permits.toString();
  //     }
  //     else {
  //       this.assetDetailsModel.permits = null;
  //     }

  //     // if (this.assetDetailsModel.permits != null && this.assetDetailsModel.permits != NaN) {
  //     // this.assetDetailsModel.permits = this.assetDetailsModel.permits.toString();
  //     // this.assetDetailsModel.permits = this.assetDetailsModel.permits.toString() : null

  //     // this.assetDetailsModel.permits = (this.assetDetailsModel.permits != null && this.assetDetailsModel.permits != NaN) ? this.assetDetailsModel.permits = this.assetDetailsModel.permits.toString() : null

  //     if (this.assetDetailsModel.installDate != null) {
  //       this.assetDetailsModel.installDate = typeof this.assetDetailsModel.installDate == 'string' ? new Date(this.assetDetailsModel.installDate) : this.assetDetailsModel.installDate.getFullYear() + '-' + (this.assetDetailsModel.installDate.getMonth() + 1) + '-' + this.assetDetailsModel.installDate.getDate();
  //     }
  //     else {
  //       this.assetDetailsModel.installDate = null
  //     }

  //     if (this.assetDetailsModel.lastAnnual != null) {
  //       this.assetDetailsModel.lastAnnual = typeof this.assetDetailsModel.lastAnnual == 'string' ? new Date(this.assetDetailsModel.lastAnnual) : this.assetDetailsModel.lastAnnual.getFullYear() + '-' + (this.assetDetailsModel.lastAnnual.getMonth() + 1) + '-' + this.assetDetailsModel.lastAnnual.getDate();
  //     }
  //     else {
  //       this.assetDetailsModel.lastAnnual = null
  //     }

  //     if (this.assetDetailsModel.lastService != null) {
  //       this.assetDetailsModel.lastService = typeof this.assetDetailsModel.lastService == 'string' ? new Date(this.assetDetailsModel.lastService) : this.assetDetailsModel.lastService.getFullYear() + '-' + (this.assetDetailsModel.lastService.getMonth() + 1) + '-' + this.assetDetailsModel.lastService.getDate();
  //     }
  //     else {
  //       this.assetDetailsModel.lastService = null
  //     }

  //     if (this.assetDetailsModel.reccWithdrawalDate != null) {
  //       this.assetDetailsModel.reccWithdrawalDate = typeof this.assetDetailsModel.reccWithdrawalDate == 'string' ? new Date(this.assetDetailsModel.reccWithdrawalDate) : this.assetDetailsModel.reccWithdrawalDate.getFullYear() + '-' + (this.assetDetailsModel.reccWithdrawalDate.getMonth() + 1) + '-' + this.assetDetailsModel.reccWithdrawalDate.getDate();
  //     }
  //     else {
  //       this.assetDetailsModel.reccWithdrawalDate = null
  //     }

  //     if (this.assetDetailsModel.dLPEndDate != null) {
  //       this.assetDetailsModel.dLPEndDate = typeof this.assetDetailsModel.dLPEndDate == 'string' ? new Date(this.assetDetailsModel.dLPEndDate) : this.assetDetailsModel.dLPEndDate.getFullYear() + '-' + (this.assetDetailsModel.dLPEndDate.getMonth() + 1) + '-' + this.assetDetailsModel.dLPEndDate.getDate();
  //     }
  //     else {
  //       this.assetDetailsModel.dLPEndDate = null
  //     }

  //     // if (this.assetDetailsModel.assetCaptureDate != null) {
  //     //   this.assetDetailsModel.assetCaptureDate = typeof this.assetDetailsModel.assetCaptureDate == 'string' ? new Date(this.assetDetailsModel.assetCaptureDate) : this.assetDetailsModel.assetCaptureDate.getFullYear() + '-' + (this.assetDetailsModel.assetCaptureDate.getMonth() + 1) + '-' + this.assetDetailsModel.assetCaptureDate.getDate();
  //     // }
  //     // else {
  //     //   this.assetDetailsModel.assetCaptureDate = null
  //     // }

  //     this.assetDetailsModel.calculatedReplacementYear =  this.plannedReplacementDate = null?this.assetDetailsModel.calculatedReplacementYear:this.assetDetailsModel.calculatedReplacementYear ;
  //     this.assetDetailsModel.installDate = this.assetDetailsModel.installDate == null ? null : typeof this.assetDetailsModel.installDate == 'string' ? this.assetDetailsModel.installDate : this.assetDetailsModel.installDate.getFullYear() + '-' + (this.assetDetailsModel.installDate.getMonth() + 1) + '-' + this.assetDetailsModel.installDate.getDate();
  //     this.assetDetailsModel.lastAnnual = this.assetDetailsModel.lastAnnual == null ? null : typeof this.assetDetailsModel.lastAnnual == 'string' ? this.assetDetailsModel.lastAnnual : this.assetDetailsModel.lastAnnual.getFullYear() + '-' + (this.assetDetailsModel.lastAnnual.getMonth() + 1) + '-' + this.assetDetailsModel.lastAnnual.getDate();
  //     this.assetDetailsModel.lastService = this.assetDetailsModel.lastService == null ? null : typeof this.assetDetailsModel.lastService == 'string' ? this.assetDetailsModel.lastService : this.assetDetailsModel.lastService.getFullYear() + '-' + (this.assetDetailsModel.lastService.getMonth() + 1) + '-' + this.assetDetailsModel.lastService.getDate();
  //     this.assetDetailsModel.reccWithdrawalDate = this.assetDetailsModel.reccWithdrawalDate == null ? null : typeof this.assetDetailsModel.reccWithdrawalDate == 'string' ? this.assetDetailsModel.reccWithdrawalDate : this.assetDetailsModel.reccWithdrawalDate.getFullYear() + '-' + (this.assetDetailsModel.reccWithdrawalDate.getMonth() + 1) + '-' + this.assetDetailsModel.reccWithdrawalDate.getDate();
  //     this.assetDetailsModel.dLPEndDate = this.assetDetailsModel.dLPEndDate == null ? null : typeof this.assetDetailsModel.dLPEndDate == 'string' ? this.assetDetailsModel.dLPEndDate : this.assetDetailsModel.dLPEndDate.getFullYear() + '-' + (this.assetDetailsModel.dLPEndDate.getMonth() + 1) + '-' + this.assetDetailsModel.dLPEndDate.getDate();
  //     // this.assetDetailsModel.assetCaptureDate = this.assetDetailsModel.assetCaptureDate == null ? null : typeof this.assetDetailsModel.assetCaptureDate == 'string' ? this.assetDetailsModel.assetCaptureDate : this.assetDetailsModel.assetCaptureDate.getFullYear() + '-' + (this.assetDetailsModel.assetCaptureDate.getMonth() + 1) + '-' + this.assetDetailsModel.assetCaptureDate.getDate();
  //     // this.assetDetailsModel.assetCaptureDate = this.assetDetailsModel.assetCaptureDate == null ? null : this.assetDetailsModel.assetCaptureDate;

  //     if (this.assetDetailsModel.faultCodes != null) {
  //       if (this.assetDetailsModel.faultCodes != "") {
  //         this.assetDetailsModel.faultCodes = this.assetDetailsModel.faultCodes.toString()

  //       }
  //       else {
  //         this.assetDetailsModel.faultCodes = ""
  //       }
  //     }
  //     if (this.assetDetailsModel.fixCodes != null) {
  //       if (this.assetDetailsModel.fixCodes != "") {
  //         this.assetDetailsModel.fixCodes = this.assetDetailsModel.fixCodes.toString()
  //       }
  //       else {
  //         this.assetDetailsModel.fixCodes = ""
  //       }
  //     }

  //     this.DataService.AddUpdateAsset(this.assetDetailsModel).subscribe((res) => {

  //       // Set Image
  //       if (res != null) {
  //         var prom;

  //         let image: AssetImageData = new AssetImageData();

  //         if (this.imagePreviews.length > 0) {
  //           this.imagePreviews.forEach((element) => {

  //             if (element.IsUpdated == true) {
  //               image.associatedID = res.assetID;
  //               image.description = "";
  //               image.imageDataID = typeof (element.uid) == "string" ? 0 : element.uid;
  //               image.imageType = "AST";
  //               image.extension = element['extension'].toLowerCase();
  //               image.imageData = this.convertDataURIToBinary(element['src']);

  //               this.DataService.UploadImageAsset(image).subscribe((rp) => {

  //                 image = new AssetImageData();
  //               });
  //             }
  //           });
  //         }

  //         if (this.ArraySubAssets != undefined && this.ArraySubAssets != null && this.ArraySubAssets.length > 0) {
  //           var promise;
  //           this.ArraySubAssets.forEach((element) => {
  //             element.assetID = res.assetID;
  //             element.assetTypeID = element.assetType == undefined ? null : element.assetType.assetTypeID;
  //             element.conditionRatingCd = element.conditionRating == undefined ? null : element.conditionRating.codeID;
  //             element.make = element.makeCd == undefined ? null : parseInt(element.makeCd.codeID);
  //             element["ImageData"] = new Array()
  //             // with Promise
  //             promise = new Promise((resolve, reject) => {

  //               this.DataService.addUpdateSubAssets(element).toPromise().then((resSub) => {
  //                 let SubAssetimage: AssetImageData = new AssetImageData();

  //                 if (element.SubAssetImages != undefined && element.SubAssetImages.length > 0) {
  //                   element.SubAssetImages.forEach((elem) => {

  //                     if (elem.IsUpdated == true) {
  //                       SubAssetimage.associatedID = resSub.subAssetID;
  //                       SubAssetimage.description = "";
  //                       SubAssetimage.imageDataID = elem.uid;
  //                       SubAssetimage.imageType = "SAST";
  //                       SubAssetimage.extension = elem.extension.toLowerCase();
  //                       SubAssetimage.imageData = this.convertDataURIToBinary(elem.src);

  //                       this.DataService.UploadImageAsset(SubAssetimage).subscribe((rps) => {

  //                         SubAssetimage = new AssetImageData();
  //                       });
  //                     }
  //                   });
  //                 }

  //                 resolve('success');
  //               }).catch(() => {
  //                 reject();
  //               })
  //             })

  //           })

  //           promise.then(() => {
  //             this.notify.success('Asset saved successfully.')
  //             this.router.navigate(['/assets-listing']);
  //           })
  //         } else {
  //           this.notify.success('Asset saved successfully.')
  //           this.router.navigate(['/assets-listing']);

  //         }
  //       }

  //     })
  //   }
  // }

  //not in use

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
        this.assetDetailsModel.geoLocation =
          this.latitude + "," + this.longitude;
      });
    }
  }

  bindCodeData() {
    // this.ddlConditionRatingCd.push(this.ddlModelDataDefaultItem);
    this.DataService.getCodeData().subscribe((res) => {
      if (res != undefined && res != null) {
        res.forEach((element) => {
          if (element.codeName.toLowerCase() == "conditionratingcd") {
            this.ddlConditionRatingCd.push(element);
          } else if (element.codeName.toLowerCase() == "mfgcd") {
            this.ddlMfgCd.push(element);
          } else if (element.codeName.toLowerCase() == "assetstatuscd") {
            this.ddlStatusCd.push(element);
          } else if (element.codeName.toLowerCase() == "indooroutdoorcd") {
            this.ddlIndoorOutdoorCd.push(element);
          } else if (element.codeName.toLowerCase() == "dampersfittedcd") {
            this.ddlDampersFittedCd.push(element);
          } else if (element.codeName.toLowerCase() == "filtertypecd") {
            this.ddlFilterTypeCd.push(element);
          } else if (element.codeName.toLowerCase() == "firedoorfrlratingcd") {
            this.ddlFireDoorFRLRatingCd.push(element);
          } else if (element.codeName.toLowerCase() == "firemodefncd") {
            this.ddlFireModeFnCd.push(element);
          } else if (element.codeName.toLowerCase() == "yesnonacd") {
            this.ddlyesnonaCd.push(element);
          } else if (element.codeName.toLowerCase() == "maintenancefreqcd") {
            this.ddlMaintenanceFreqCd.push(element);
          } else if (element.codeName.toLowerCase() == "assetownershipcd") {
            this.ddlOwnershipCd.push(element);
          } else if (element.codeName.toLowerCase() == "refrigerantcd") {
            this.ddlRefrigerantCd.push(element);
          } else if (element.codeName.toLowerCase() == "environmentcd") {
            this.ddlEnvironmentCd.push(element);
          } else if (element.codeName.toLowerCase() == "assetruntimecd") {
            this.ddlAssetRunTimeCd.push(element);
          } else if (element.codeName.toLowerCase() == "criticalitycd") {
            this.ddlCriticalityCd.push(element);
          } else if (element.codeName.toLowerCase() == "essentialservicecd") {
            this.ddlEssentialServiceCd.push(element);
          } else if (element.codeName.toLowerCase() == "maintenancestdcd") {
            this.ddlMaintenanceStdCd.push(element);
          } else if (element.codeName.toLowerCase() == "prioritycd") {
            this.ddlPriorityCd.push(element);
          } else if (element.codeName.toLowerCase() == "recommendedactioncd") {
            this.ddlRecommendedActionCd.push(element);
          } else if (element.codeName.toLowerCase() == "assetsizecd") {
            this.ddlAssetSizeCd.push(element);
          } else if (element.codeName.toLowerCase() == "permitscd") {
            this.ddlPermitsCd.push(element);
          } else if (element.codeName.toLowerCase() == "fueltypecd") {
            this.ddlFuelTypeCd.push(element);
          } else if (element.codeName.toLowerCase() == "uomcd") {
          } else if (element.codeName.toLowerCase() == "modelnbr") {
            // this.ddlModelCd.push(this.ddlModelDataDefaultItem);
          }
        });
      }
    });
  }
  getLength(lengthObj) {
    if (lengthObj.length != undefined && lengthObj.length != null) {
      let arr = [];
      arr = lengthObj.length.split(",");
      let TotLength;
      if (arr.length > 0) {
        let aggLength = arr[0] - arr[1];
        TotLength = 1000 * aggLength - 1;
      } else {
        TotLength = 1000 * lengthObj.length - 1;
      }
      return TotLength;
    }
  }

  //--VOLATGE VALIDTAION CODE
  public openedVoltage: boolean = false;

  VoltageDialogPop(e) {
    if (e == 240 || e == 440) {
      this.openedVoltage = true;
      this.assetDetailsModel.electricalInputKW = e;
    } else {
      this.assetDetailsModel.electricalInputKW =
        this.assetDetailsModel.electricalInputKW;
    }
  }

  public closeVoltage(status) {
    // this.openedVoltage = false;
    if (status == "yes") {
      this.assetDetailsModel.electricalInputKW =
        this.assetDetailsModel.electricalInputKW;
    } else {
      this.assetDetailsModel.electricalInputKW = null;
    }
    this.openedVoltage = false;
  }
  //--VOLATGE VALIDTAION CODE

  GetAssetFieldConfigurationTemplate(): any {
    this.DataService.getAssetFieldConfigurationTemplate(
      this.assetDetailsModel.assetLocationID,
      this.assetDetailsModel.assetTypeID
    ).subscribe((res) => {
      let arr = [];

      res.forEach((element) => {
        let arrEle = [];
        console.log(res);
        element.forEach((valelm) => {
          valelm.fieldName =
            valelm.fieldName.charAt(0).toLowerCase() +
            valelm.fieldName.slice(1);

          arrEle.push(valelm);
        });
        arr.push(arrEle);
      });

      this.primaryDetailModel.AssetFieldTemplate = arr;
      let result: any = [];

      this.primaryDetailModel.AssetFieldTemplate.forEach((res) => {
        result = res;
        result.forEach((res1) => {
          if (res1.displayName == "NFactor") {
            this.IsNFactorPresent = true;
          }
          if (res1.displayName.toLowerCase() == "quantity") {
            this.IsQuantityPresent = true;
          }
        });
      });
      if (this.IsQuantityPresent != true) {
        this.assetDetailsModel.quantity = 1;
      }
      if (this.IsNFactorPresent != true) {
        this.assetDetailsModel.nFactor = 0;
      }

      console.log("NFactor", this.IsNFactorPresent);
      console.log("Quantity", this.IsQuantityPresent);
      arr.forEach((ele) => {
        ele.forEach((element) => {
          var finalValidatorArray = [];
          var MaxLimit = Number.parseInt(element.length.split(",")[0]);
          var limit = "";

          for (var i = 0; i < MaxLimit; i++) {
            limit = limit + "9";
          }
          if (
            element.dataType.toLowerCase() == "string" &&
            element.fieldType.toLowerCase() == "textfield"
          ) {
            var maxLengthValidator = Validators.maxLength(element.length);
            finalValidatorArray.push(maxLengthValidator);
          } else if (
            element.dataType.toLowerCase() == "string" &&
            element.fieldType.toLowerCase() == "textarea"
          ) {
            var maxLengthValidator = Validators.maxLength(element.length);
            finalValidatorArray.push(maxLengthValidator);
          } else if (
            element.dataType.toLowerCase() == "integer" &&
            element.fieldType.toLowerCase() == "textfield"
          ) {
            var maxLengthValidator = Validators.max(Number.parseInt(limit));
            finalValidatorArray.push(maxLengthValidator);
          } else if (
            element.dataType.toLowerCase() == "numeric" &&
            element.fieldType.toLowerCase() == "textfield" &&
            element.fieldName.toLowerCase() != "nfactor"
          ) {
            if (element.length != "" && element.length != undefined) {
              let ELelength = parseInt(element.length.split(",")[0]);
              let ELePre = parseInt(element.length.split(",")[1]);
              let MainLength = ELelength - ELePre;
              var precValidator = Validators.pattern(
                new RegExp(
                  "^[\\d]{1," + MainLength + "}(\\.[\\d]{1," + ELePre + "})?$"
                )
              );
            }

            finalValidatorArray.push(precValidator);
          } else if (
            element.dataType.toLowerCase() == "money" &&
            element.fieldType.toLowerCase() == "textfield"
          ) {
            let ELePre = 2;
            let MainLength = 13;

            var precValidator = Validators.pattern(
              new RegExp(
                "^[\\d]{1," + MainLength + "}(\\.[\\d]{1," + ELePre + "})?$"
              )
            );
            finalValidatorArray.push(precValidator);
          } else if (
            element.dataType.toLowerCase() == "string" &&
            element.fieldType.toLowerCase() == "multidropdown"
          ) {
            var maxLengthValidator = Validators.maxLength(element.length);
            finalValidatorArray.push(maxLengthValidator);
          } else if (
            element.dataType.toLowerCase() == "string" &&
            element.fieldType.toLowerCase() == "barcode"
          ) {
            var maxLengthValidator = Validators.maxLength(element.length);
            finalValidatorArray.push(maxLengthValidator);
          } else if (
            element.dataType.toLowerCase() == "string" &&
            element.fieldType.toLowerCase() == "location"
          ) {
            var maxLengthValidator = Validators.maxLength(element.length);
            finalValidatorArray.push(maxLengthValidator);
          }

          if (element.defaultBehaviour == "1") {
            var requiredFieldValidator = Validators.required;
            finalValidatorArray.push(requiredFieldValidator);
          }
          //Done some changes for Validation.
          if (
            this.IsValidationDisabled == true &&
            this.assetDetailsModel.isIncomplete == true
          ) {
            console.log("remove validation");
            // this.formGroup.controls[element.fieldName].clearValidators();
            for (const key in this.formGroup.controls) {
              // console.log(key)
              this.formGroup.get(key).clearValidators();
              this.formGroup.get(key).updateValueAndValidity();
            }
          } else if (
            this.IsValidationDisabled == false &&
            this.assetDetailsModel.isIncomplete == false
          ) {
          
            this.formGroup.controls[element.fieldName].setValidators(
              finalValidatorArray
            );
            this.formGroup.controls[element.fieldName].updateValueAndValidity();
          }
          // else
          // {

          // }

          //  this.formGroup.controls[element.fieldName].setValidators(finalValidatorArray);
          //  this.formGroup.controls[element.fieldName].updateValueAndValidity();
        });
      });
    });
  }

  onSubmit() {
    console.log(this.assetDetailsModel);

    this.panelExpand = true;
    for (
      var i = 0;
      i < this.primaryDetailModel.AssetFieldTemplate.length;
      i++
    ) {
      for (
        var j = 0;
        j < this.primaryDetailModel.AssetFieldTemplate[i].length;
        j++
      ) {
        var obj = this.primaryDetailModel.AssetFieldTemplate[i][j];
        if (obj.fieldType.toLowerCase() == "dropdown") {
          const control = this.formGroup.get(obj.fieldName);
          if (control.value == 0) control.setValue("");
        }
      }
    }

    if (this.ArraySubAssets != null && this.ArraySubAssets.length > 0) {
      for (var data of this.ArraySubAssets) {
        if (
          data.conditionRatingCd == null ||
          data.areaLocated == null ||
          data.conditionRatingCd == 0 ||
          data.areaLocated == ""
        ) {
          this.subAssetModel.IsValidSubAsset = false;
          break;
        } else {
          this.subAssetModel.IsValidSubAsset = true;
        }
      }
    } else {
      this.subAssetModel.IsValidSubAsset = true;
    }

    console.log(this.formGroup.value);
    console.log(this.formGroup.controls);

    if (
      this.validateAssetImages() &&
      this.formGroup.valid &&
      this.subAssetModel.IsValidSubAsset
    ) {
      let assetCaptureDate: any = this.assetDetailsModel.assetCaptureDate;
      let lastAuditduration: any = this.assetDetailsModel.lastauditduration;
      let firstAuditduration: any = this.assetDetailsModel.firstauditduration;
      this.formGroup.value.customerBudgetPrice =
        this.assetDetailsModel.customerBudgetPrice;
      this.assetDetailsModel = this.formGroup.value;
      this.assetDetailsModel.reccWithdrawalDate = this.reccWdrawl;
      this.assetDetailsModel.assetCaptureDate = assetCaptureDate;
      this.assetDetailsModel.firstauditduration = firstAuditduration;
      this.assetDetailsModel.lastauditduration = lastAuditduration;
      this.assetDetailsModel.isIncomplete = this.IsValidationDisabled;

      if (
        this.assetDetailsModel.assetID != undefined &&
        this.assetDetailsModel.assetID != null &&
        this.assetDetailsModel.assetID > 0
      ) {
        //Checking coment history and setting the data.

        if (this.assetDetailsModel.generalComments != null) {
          if (
            this.assetDetailsModel.generalComments.trim().toLowerCase() !=
            this.generalCommentsOld.trim().toLowerCase()
          ) {
            this.assetDetailsModel.generalCommentsUpdate =
              this.assetDetailsModel.generalComments;
            this.assetDetailsModel.generalComments = this.generalCommentsOld;
          } else {
            this.assetDetailsModel.generalCommentsUpdate = undefined;
          }
        } else {
          this.assetDetailsModel.generalCommentsUpdate = undefined;
        }

        if (this.assetDetailsModel.oHSComments != null) {
          if (
            this.assetDetailsModel.oHSComments.trim().toLowerCase() !=
            this.oHSCommentsOld.trim().toLowerCase()
          ) {
            this.assetDetailsModel.oHSCommentsUpdate =
              this.assetDetailsModel.oHSComments;
            this.assetDetailsModel.oHSComments = this.oHSCommentsOld;
          } else {
            this.assetDetailsModel.oHSCommentsUpdate = undefined;
          }
        } else {
          this.assetDetailsModel.oHSCommentsUpdate = undefined;
        }
      }

      Object.entries(this.assetDetailsModel).forEach((ele) => {
        if (ele[1] == "0001-01-01T00:00:00") {
          ele[1] = null;
          this.assetDetailsModel[ele[0]] = ele[1];
        }
      });

      if (
        this.assetDetailsModel.permits != null &&
        this.assetDetailsModel.permits != NaN &&
        this.assetDetailsModel.permits != undefined
      ) {
        this.assetDetailsModel.permits =
          this.assetDetailsModel.permits.toString();
      } else {
        this.assetDetailsModel.permits = null;
      }

      if (this.assetDetailsModel.installDate != null) {
        this.assetDetailsModel.installDate =
          typeof this.assetDetailsModel.installDate == "string"
            ? new Date(this.assetDetailsModel.installDate)
            : this.assetDetailsModel.installDate.getFullYear() +
            "-" +
            (this.assetDetailsModel.installDate.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.installDate.getDate();
      } else {
        this.assetDetailsModel.installDate = null;
      }

      if (this.assetDetailsModel.lastAnnual != null) {
        this.assetDetailsModel.lastAnnual =
          typeof this.assetDetailsModel.lastAnnual == "string"
            ? new Date(this.assetDetailsModel.lastAnnual)
            : this.assetDetailsModel.lastAnnual.getFullYear() +
            "-" +
            (this.assetDetailsModel.lastAnnual.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.lastAnnual.getDate();
      } else {
        this.assetDetailsModel.lastAnnual = null;
      }

      if (this.assetDetailsModel.lastService != null) {
        this.assetDetailsModel.lastService =
          typeof this.assetDetailsModel.lastService == "string"
            ? new Date(this.assetDetailsModel.lastService)
            : this.assetDetailsModel.lastService.getFullYear() +
            "-" +
            (this.assetDetailsModel.lastService.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.lastService.getDate();
      } else {
        this.assetDetailsModel.lastService = null;
      }

      if (
        this.assetDetailsModel.reccWithdrawalDate != null ||
        this.assetDetailsModel.reccWithdrawalDate != undefined
      ) {
        this.assetDetailsModel.reccWithdrawalDate =
          typeof this.assetDetailsModel.reccWithdrawalDate == "string"
            ? new Date(this.assetDetailsModel.reccWithdrawalDate)
            : this.assetDetailsModel.reccWithdrawalDate.getFullYear() +
            "-" +
            (this.assetDetailsModel.reccWithdrawalDate.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.reccWithdrawalDate.getDate();
      } else {
        this.assetDetailsModel.reccWithdrawalDate = null;
      }

      if (this.assetDetailsModel.dLPEndDate != null) {
        this.assetDetailsModel.dLPEndDate =
          typeof this.assetDetailsModel.dLPEndDate == "string"
            ? new Date(this.assetDetailsModel.dLPEndDate)
            : this.assetDetailsModel.dLPEndDate.getFullYear() +
            "-" +
            (this.assetDetailsModel.dLPEndDate.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.dLPEndDate.getDate();
      } else {
        this.assetDetailsModel.dLPEndDate = null;
      }

      // if (this.assetDetailsModel.assetCaptureDate != null) {
      //   this.assetDetailsModel.assetCaptureDate = typeof this.assetDetailsModel.assetCaptureDate == 'string' ? new Date(this.assetDetailsModel.assetCaptureDate) : this.assetDetailsModel.assetCaptureDate.getFullYear() + '-' + (this.assetDetailsModel.assetCaptureDate.getMonth() + 1) + '-' + this.assetDetailsModel.assetCaptureDate.getDate();
      // }
      // else {
      //   this.assetDetailsModel.assetCaptureDate = null
      // }

      this.assetDetailsModel.installDate =
        this.assetDetailsModel.installDate == null
          ? null
          : typeof this.assetDetailsModel.installDate == "string"
            ? this.assetDetailsModel.installDate
            : this.assetDetailsModel.installDate.getFullYear() +
            "-" +
            (this.assetDetailsModel.installDate.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.installDate.getDate();
      this.assetDetailsModel.lastAnnual =
        this.assetDetailsModel.lastAnnual == null
          ? null
          : typeof this.assetDetailsModel.lastAnnual == "string"
            ? this.assetDetailsModel.lastAnnual
            : this.assetDetailsModel.lastAnnual.getFullYear() +
            "-" +
            (this.assetDetailsModel.lastAnnual.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.lastAnnual.getDate();
      this.assetDetailsModel.lastService =
        this.assetDetailsModel.lastService == null
          ? null
          : typeof this.assetDetailsModel.lastService == "string"
            ? this.assetDetailsModel.lastService
            : this.assetDetailsModel.lastService.getFullYear() +
            "-" +
            (this.assetDetailsModel.lastService.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.lastService.getDate();
      // this.assetDetailsModel.reccWithdrawalDate = this.assetDetailsModel.reccWithdrawalDate == null ? null : typeof this.assetDetailsModel.reccWithdrawalDate == 'string' ? this.assetDetailsModel.reccWithdrawalDate : this.assetDetailsModel.reccWithdrawalDate.getFullYear() + '-' + (this.assetDetailsModel.reccWithdrawalDate.getMonth() + 1) + '-' + this.assetDetailsModel.reccWithdrawalDate.getDate();
      this.assetDetailsModel.dLPEndDate =
        this.assetDetailsModel.dLPEndDate == null
          ? null
          : typeof this.assetDetailsModel.dLPEndDate == "string"
            ? this.assetDetailsModel.dLPEndDate
            : this.assetDetailsModel.dLPEndDate.getFullYear() +
            "-" +
            (this.assetDetailsModel.dLPEndDate.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.dLPEndDate.getDate();
        //----------------------------------------------------
        this.assetDetailsModel.nextService =
        this.assetDetailsModel.nextService == null
          ? null
          : typeof this.assetDetailsModel.nextService == "string"
            ? this.assetDetailsModel.nextService
            : this.assetDetailsModel.nextService.getFullYear() +
            "-" +
            (this.assetDetailsModel.nextService.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.nextService.getDate();
        //------------------------------------------------------
        this.assetDetailsModel.nextTestandTag =
        this.assetDetailsModel.nextTestandTag == null
          ? null
          : typeof this.assetDetailsModel.nextTestandTag == "string"
            ? this.assetDetailsModel.nextTestandTag
            : this.assetDetailsModel.nextTestandTag.getFullYear() +
            "-" +
            (this.assetDetailsModel.nextTestandTag.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.nextTestandTag.getDate();
        //--------------------------------------------------------- 
        this.assetDetailsModel.purchaseDate =
        this.assetDetailsModel.purchaseDate == null
          ? null
          : typeof this.assetDetailsModel.purchaseDate == "string"
            ? this.assetDetailsModel.purchaseDate
            : this.assetDetailsModel.purchaseDate.getFullYear() +
            "-" +
            (this.assetDetailsModel.purchaseDate.getMonth() + 1) +
            "-" +
            this.assetDetailsModel.purchaseDate.getDate();
      //-------------------------------------------------------------
      
      //  this.assetDetailsModel.assetCaptureDate = this.assetDetailsModel.assetCaptureDate == null ? null : typeof this.assetDetailsModel.assetCaptureDate == 'string' ? this.assetDetailsModel.assetCaptureDate : this.assetDetailsModel.assetCaptureDate.getFullYear() + '-' + (this.assetDetailsModel.assetCaptureDate.getMonth() + 1) + '-' + this.assetDetailsModel.assetCaptureDate.getDate();
      // this.assetDetailsModel.assetCaptureDate = this.assetDetailsModel.assetCaptureDate == null ? null : typeof this.assetDetailsModel.assetCaptureDate == 'string' ? new Date(this.assetDetailsModel.assetCaptureDate) : this.assetDetailsModel.assetCaptureDate.getFullYear() + '-' + (this.assetDetailsModel.assetCaptureDate.getMonth() + 1) + '-' + this.assetDetailsModel.assetCaptureDate.getDate();

      if (this.assetDetailsModel.calculatedReplacementYear != null) {
        this.assetDetailsModel.calculatedReplacementYear =
          this.assetDetailsModel.calculatedReplacementYear.toString();
      }

      if (this.assetDetailsModel.faultCodes != null) {
        if (this.assetDetailsModel.faultCodes != "") {
          this.assetDetailsModel.faultCodes =
            this.assetDetailsModel.faultCodes.toString();
        } else {
          this.assetDetailsModel.faultCodes = "";
        }
      }
      if (this.assetDetailsModel.fixCodes != null) {
        if (this.assetDetailsModel.fixCodes != "") {
          this.assetDetailsModel.fixCodes =
            this.assetDetailsModel.fixCodes.toString();
        } else {
          this.assetDetailsModel.fixCodes = "";
        }
      }

      console.log("Before save1", this.assetDetailsModel);
      if (this.IsQuantityPresent != true) {
        this.assetDetailsModel.quantity = 1;
      }

      if (this.IsNFactorPresent != true) {
        this.assetDetailsModel.nFactor = 0;
      }

      console.log("Before save2", this.assetDetailsModel);
      this.DataService.AddUpdateAsset(this.assetDetailsModel).subscribe(
        (res) => {
          // Set Image
          if (res != null) {
            var prom;

            let image: AssetImageData = new AssetImageData();

            if (this.imagePreviews.length > 0) {
              this.imagePreviews.forEach((element, index) => {
                // if (element.IsUpdated == true) {
                image.associatedID = res.assetID;
                image.imageDataID =
                  typeof element.uid == "string" ? 0 : element.uid;
                image.imageType = "AST";
                image.extension = element["extension"].toLowerCase();
                image.imageData = this.convertDataURIToBinary(element["src"]);
                image.sequence = index;
                image.description = element.description;
                // image.CapturedDate = element.date;
                this.DataService.UploadImageAsset(image).subscribe((rp) => {
                  image = new AssetImageData();
                });
                // }
              });
            }

            //DOCUMENTS UPLOADS
            let DocumentsObj: DocumentsUploadEntity =
              new DocumentsUploadEntity();
            if (this.DocumentData.length > 0) {
              this.DocumentData.forEach((elem) => {
                if (elem.IsUpdated == true) {
                  DocumentsObj.associateID = res.assetID;
                  DocumentsObj.documentID =
                    elem.documentID == undefined ? 0 : elem.documentID;
                  DocumentsObj.description = elem.description;
                  DocumentsObj.docType = "AST";
                  DocumentsObj.documentData = elem.documentData;
                  DocumentsObj.extension = elem.extension;
                  DocumentsObj.fileName =
                    elem.fileName.split(/\.(?=[^\.]+$)/)[0];
                  DocumentsObj.title = elem.title;
                  this.DataService.SaveDocuments(DocumentsObj).subscribe(
                    (rp) => {
                      DocumentsObj = new DocumentsUploadEntity();
                    }
                  );
                }
              });
            }

            //DOCUMENTS UPLOADS

            if (
              this.ArraySubAssets != undefined &&
              this.ArraySubAssets != null &&
              this.ArraySubAssets.length > 0
            ) {
              var promise;
              this.ArraySubAssets.forEach((element) => {
                element.assetID = res.assetID;
                element.assetTypeID =
                  element.assetType == undefined
                    ? null
                    : element.assetType.assetTypeID;
                element.conditionRatingCd =
                  element.conditionRating == undefined
                    ? null
                    : element.conditionRating.codeID;
                element.make =
                  element.makeCd == undefined
                    ? null
                    : parseInt(element.makeCd.codeID);
                element.filterType =
                  element.filterType == undefined
                    ? null
                    : element.filterType.codeID;
                element["ImageData"] = new Array();
                // with Promise
                promise = new Promise((resolve, reject) => {
                  this.DataService.addUpdateSubAssets(element)
                    .toPromise()
                    .then((resSub) => {
                      let SubAssetimage: AssetImageData = new AssetImageData();

                      if (
                        element.SubAssetImages != undefined &&
                        element.SubAssetImages.length > 0
                      ) {
                        element.SubAssetImages.forEach((elem, index) => {
                          // if (elem.IsUpdated == true) {
                          SubAssetimage.associatedID = resSub.subAssetID;
                          SubAssetimage.description = elem.description;
                          SubAssetimage.imageDataID = elem.uid;
                          SubAssetimage.imageType = "SAST";
                          // SubAssetimage.CreationDate = elem.date;
                          SubAssetimage.extension =
                            elem.extension.toLowerCase();
                          SubAssetimage.imageData = this.convertDataURIToBinary(
                            elem.src
                          );
                          SubAssetimage.sequence = index;

                          this.DataService.UploadImageAsset(
                            SubAssetimage
                          ).subscribe((rps) => {
                            SubAssetimage = new AssetImageData();
                          });
                          // }
                        });
                      }

                      resolve("success");
                    })
                    .catch(() => {
                      reject();
                    });
                });
              });
              promise.then(() => {
                this.notify.success("Asset saved successfully.");
                this.router.navigate(["/assets-listing"]);
              });
            } else {
              this.notify.success("Asset saved successfully.");
              this.router.navigate(["/assets-listing"]);
            }
          }
        }
      );
    } else {
      // this.panelExpand = true;

      if (!this.formGroup.valid) {
        Object.keys(this.formGroup.controls).forEach((field) => {
          // {1}
          const control = this.formGroup.get(field); // {2}
          // control.setValue('')
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true }); // {3}
        });
        if (document.getElementsByClassName("ng-invalid")[1].id != null) {
          if (
            document.getElementById(
              document.getElementsByClassName("ng-invalid")[1].id
            ) != null
          ) {
            if (
              document
                .getElementById(
                  document.getElementsByClassName("ng-invalid")[1].id
                )
                .getElementsByTagName("input").length > 0
            ) {
              document
                .getElementById(
                  document.getElementsByClassName("ng-invalid")[1].id
                )
                .getElementsByTagName("input")[0]
                .focus();
            } else {
              document
                .getElementById(
                  document.getElementsByClassName("ng-invalid")[1].id
                )
                .focus();
            }
            document
              .getElementById(
                document.getElementsByClassName("ng-invalid")[1].id
              )
              .scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
          }
        }
      } else {
        this.notify.error(
          "Please enter all mandatory/incorrect fields in Sub Assets"
        );
      }
    }
  }

  ValidateBarcode(fieldName) {
    if (
      fieldName.toLowerCase() == "barcode" &&
      this.assetDetailsModel.barcode.length >= 1
    ) {
      this.DataService.ValidateAssetBarcode(
        this.assetDetailsModel.assetID,
        this.assetDetailsModel.assetLocationID,
        this.assetDetailsModel.barcode
      ).subscribe((res) => {
        if (res != undefined && res != null && res.length > 0) {
          this.assetDetailsModel.barcode = null;
          this.notify.warning("Barcode already exists, Please enter again.");
        }
      });
    }
  }

  onReset() {
    // this.formGroup.reset();
  }

  isGeolocationEnabled = function () {
    navigator.geolocation.getCurrentPosition(
      function () {
        return true;
      },
      function (error) {
        if (error.code == error.PERMISSION_DENIED) {
          return false;
        } else {
          return true;
        }
      }
    );
  };

  GetUserLocationDropdown(data) {
    this.DataService.getUserLocationDropdown(data).subscribe((res) => {
      this.locationData = res;
      if (
        this.assetDetailsModel.assetLocationID != undefined &&
        this.assetDetailsModel.assetLocationID != null
      ) {
        this.locationData.forEach((element) => {
          if (element.locationID == this.assetDetailsModel.assetLocationID) {
            //--NEW CONDITITION

            if (
              this.assetDetailsModel.assetID == null ||
              this.assetDetailsModel.assetID == 0 ||
              this.assetDetailsModel.assetID == undefined
            ) {
              if (this.assetDetailsModel.geoLocation === "25.2744,133.775131") {
                this.assetDetailsModel.geoLocation = element.geoLocation;
              }
            }

            // let id = navigator.geolocation.watchPosition((position) => {
            //   // console.log("i'm tracking you!");
            //   // this.assetDetailsModel.geoLocation = element.geoLocation
            //   navigator.geolocation.clearWatch(id);
            // },
            //   (error) => {
            //     if (error.code == error.PERMISSION_DENIED) {
            //       // console.log("you denied me :-(");
            //       this.assetDetailsModel.geoLocation = element.geoLocation
            //       navigator.geolocation.clearWatch(id);
            //     }

            //   }
            // );
            //--NEW CONDITITION
            this.OldLocationID = element.locationID;
            if (
              this.assetDetailsModel.maintenanceStdCd == undefined ||
              this.assetDetailsModel.maintenanceStdCd == null
            ) {
              this.assetDetailsModel.maintenanceStdCd =
                element.maintenanceStandardCd;
            }
          }
        });
      }
    });
  }
  locationDialogYes() {
    this.locationData.forEach((element) => {
      if (element.locationID == this.assetDetailsModel.assetLocationID) {
        if (element.geoLocation != null) {
          this.latitude = parseFloat(element.geoLocation.split(",")[0]);
          this.longitude = parseFloat(element.geoLocation.split(",")[1]);
        }
        this.assetDetailsModel.geoLocation = element.geoLocation;
      }
    });
    this.renderTemplate();
    this.IsCancelClickedLocation = false;
  }

  locationDialogNo() {
    this.assetDetailsModel.assetLocationID = this.OldLocationID;
    this.GetUserLocationDropdown("");
    this.closeDialogLocation();
  }

  CloneDialogYes() {
    //this.ArraySubAssets = null
    this.assetDetailsModel.assetID = null;
    this.assetDetailsModel.geoLocation = null;

    this.assetDetailsModel.userAssetID = null;
    this.assetDetailsModel.barcode = null;
    this.assetDetailsModel.conditionRatingCd = null;
    this.assetDetailsModel.serialNo = null;
    this.assetDetailsModel.decommissionedAssetID = null;

    this.assetDetailsModel.customerAssetID = null;
    this.assetDetailsModel.refrigerantQty = null;
    this.assetDetailsModel.generalComments = null;
    this.assetDetailsModel.oHSComments = null;
    this.assetDetailsModel.faultCodes = null;
    this.assetDetailsModel.fixCodes = null;
    this.imagePreviews.length = 0;
    this.imagePreviewsViewer.length = 0;
    this.assetDetailsModel.assetCaptureDate = new Date();
    this.sharedDataService.SubAssetClone$.next("true");

    this.IsCancelClickedClone = false;
  }

  CloneDialogNo() {
    this.IsCancelClickedClone = false;
  }

  LocationfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.GetUserLocationDropdown(filterData);
    }
  }

  filterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.GetAssetTypeByNameDropDownUrl(filterData);
    }
  }

  assetTypeDialogYes() {
    this.renderTemplate();
    this.IsCancelClickedAssetType = false;
  }
  assetTypeDialogNo() {
    this.assetDetailsModel.assetTypeID = this.OldAssetTypeID;
    this.GetAssetTypeByNameDropDownUrl("");
    this.closeDialogAssetType();
  }

  filterChangeSubAssets(filterData: any): void {
    if (filterData.length >= 3) {
      this.GetSubAssetTypeByNameDropDownUrl(filterData);
    }
  }

  GetSubAssetTypeByNameDropDownUrl(filterData) {
    this.DataService.getAssetTypeByNameDropDownUrl(filterData).subscribe(
      (res) => {
        this.AssetData = res;
      }
    );
  }

  GetAssetTypeByNameDropDownUrl(filterData) {
    
    this.DataService.getAssetTypeByNameDropDownUrl(filterData).subscribe(
      (res) => {
        this.AssetData = res;
        this.ShowSubAssets = false;
        if (
          this.assetDetailsModel.assetTypeID != undefined &&
          this.assetDetailsModel.assetTypeID != null
          ) {
          let UOMTypeID: number;
          this.AssetData.forEach((element) => {
            if (
              parseInt(element.assetTypeID) ==
              this.assetDetailsModel.assetTypeID
            ) {
              this.assetDetailsModel.expectedLife = element.expectedLife;
              UOMTypeID = element.uomTypeID;

              this.OldAssetTypeID = parseInt(element.assetTypeID);
              if (element.hasSubAssets) {
                this.ShowSubAssets = true;
              } else {
                this.ShowSubAssets = false;
              }
            }
          });
          if (UOMTypeID != undefined && UOMTypeID != null) {
            this.ddlUOMCd = new Array();
            this.DataService.GetUOMDataURL(UOMTypeID).subscribe((res) => {
              res.forEach((element) => {
                this.ddlUOMCd.push({
                  codeID: element.uomid,
                  codeValue: element.unitName,
                });
              });
            });
          }
        }
      }
    );
  }

  ModelArray: Array<any>;
  onSelectmanufacture(data) {
    let tempArray: any = [];
    this.assetDetailsModel.assetTypeID = data.assetTypeID;
    this.assetDetailsModel.mfgCd = data.mfgCd;
    this.DataService.getmanufactureeModelList(
      this.assetDetailsModel.assetTypeID,
      this.assetDetailsModel.mfgCd
    ).subscribe((res) => {
      tempArray = res;
      this.ModelArray = new Array<any>();
      this.ddlModelCd = [];
      this.assetDetailsModel.modelNbr = null;
      // this.assetDetailsModel.uOMCd = null
      // this.assetDetailsModel.capacity = null
      // this.assetDetailsModel.electricalInputKW = null
      this.ddlModelCd.push(this.ddlModelDataDefaultItem);
      tempArray.forEach((element) => {
        this.ddlModelCd.push({
          codeValue: element.model,
          codeID: 0,
          weightage: 0,
        });
        this.ModelArray.push({
          mfgCd: element.mfgCd,
          model: element.model,
          uomCd: element.uomCd,
          electricalInputKW: element.electricalInputKW,
          capacity: element.capacity,
        });
      });
      // this.ddlModelCd.push({codeValue:"Other"});
    });
  }

  IsOtherSelected: boolean = false;
  onselectModel(modelData, fieldName) {
    if (fieldName.toLowerCase() == "modelnbr") {
      if (modelData == "Other") {
        this.IsOtherSelected = true;
        this.isSelectedOther = true;
        this.allowCustom = true;
      } else {
        let TempData: any;
        if (
          modelData != undefined &&
          modelData != undefined &&
          this.ModelArray != undefined
        ) {
          TempData = this.ModelArray.find((ele) => {
            return ele.model.toLowerCase() === modelData.toLowerCase();
          });
          if (TempData != undefined) {
            this.assetDetailsModel.uOMCd = TempData.uomCd;
            this.assetDetailsModel.capacity = TempData.capacity;
            this.assetDetailsModel.electricalInputKW =
              TempData.electricalInputKW;
          }
          // else if (TempData == undefined) {
          //   this.assetDetailsModel.uOMCd = null;
          //   this.assetDetailsModel.capacity = null;
          //   this.assetDetailsModel.electricalInputKW = null;
          // }
        }
        this.allowCustom = false;
        this.IsOtherSelected = false;
      }
    } else {
      if (modelData == "Other") {
        this.IsOtherSelected = true;
        this.isSelectedOther = true;
        this.allowCustom = true;
      } else {
        this.allowCustom = false;
        this.IsOtherSelected = false;
      }
    }
  }

  blur() {
    if (this.IsOtherSelected) {
      this.allowCustom = true;
    } else {
      this.allowCustom = false;
    }
  }

  plannedReplacementDate: any;
  capXYearCalculate(event) {
    this.assetDetailsModel.reccWithdrawalDate = event;
    var CurrDate = new Date();
    if (this.assetDetailsModel.reccWithdrawalDate == null) {
      this.plannedReplacementDate = null;

      let replacementYear: any =
        1 +
        Math.round(
          this.assetDetailsModel.nFactor + this.assetDetailsModel.remainingLife
          // this.sharedDataService.nfactor + this.assetDetailsModel.remainingLife
        );
      this.assetDetailsModel.calculatedReplacementYear =
        replacementYear + CurrDate.getFullYear();
      // this.assetDetailsModel.calculatedReplacementYear = replacementYear
    } else if (this.assetDetailsModel.reccWithdrawalDate != null) {
      // var diff = Math.abs(this.assetDetailsModel.reccWithdrawalDate.getTime() - CurrDate.getTime());
      var diff =
        1 +
        Math.abs(
          this.assetDetailsModel.reccWithdrawalDate.getFullYear() -
          CurrDate.getFullYear()
        );
      this.plannedReplacementDate = diff;
      //customer defined calculated replacement year
      //  this.assetDetailsModel.calculatedReplacementYear = this.plannedReplacementDate + CurrDate.getFullYear();
   //  this.assetDetailsModel.calculatedReplacementYear =
     //   this.assetDetailsModel.reccWithdrawalDate.getFullYear();
      //customer defined calculated replacement year
    }
    this.customerBudgetPrice(this.assetDetailsModel.customerBudgetPrice);
  }

  customerbudgtPrice: number;
  customerBudgetPrice(price) {
    this.customerbudgtPrice = price;

    this.assetDetailsModel.customerBudgetPrice = this.customerbudgtPrice;

    if (
      this.assetDetailsModel.customerBudgetPrice != null 
      &&
      this.assetDetailsModel.reccWithdrawalDate != null
    ) {
      this.assetDetailsModel.capExBudgetYear1 = null;
      this.assetDetailsModel.capExBudgetYear2 = null;
      this.assetDetailsModel.capExBudgetYear3 = null;
      this.assetDetailsModel.capExBudgetYear4 = null;
      this.assetDetailsModel.capExBudgetYear5 = null;
      this.assetDetailsModel.capExBudgetYear6 = null;
      this.assetDetailsModel.capExBudgetYear7 = null;
      this.assetDetailsModel.capExBudgetYear8 = null;
      this.assetDetailsModel.capExBudgetYear9 = null;
      this.assetDetailsModel.capExBudgetYear10 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear12 = null;
      this.assetDetailsModel.capExBudgetYear13 = null;
      this.assetDetailsModel.capExBudgetYear14 = null;
      this.assetDetailsModel.capExBudgetYear15 = null;
      this.assetDetailsModel.capExBudgetYear16 = null;
      this.assetDetailsModel.capExBudgetYear17 = null;
      this.assetDetailsModel.capExBudgetYear18 = null;
      this.assetDetailsModel.capExBudgetYear19 = null;
      this.assetDetailsModel.capExBudgetYear20 = null;

      if (this.plannedReplacementDate == 0) {
        this.assetDetailsModel.capExBudgetYear1 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 1) {
        this.assetDetailsModel.capExBudgetYear1 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 2) {
        this.assetDetailsModel.capExBudgetYear2 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 3) {
        this.assetDetailsModel.capExBudgetYear3 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 4) {
        this.assetDetailsModel.capExBudgetYear4 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 5) {
        this.assetDetailsModel.capExBudgetYear5 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 6) {
        this.assetDetailsModel.capExBudgetYear6 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 7) {
        this.assetDetailsModel.capExBudgetYear7 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 8) {
        this.assetDetailsModel.capExBudgetYear8 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 9) {
        this.assetDetailsModel.capExBudgetYear9 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 10) {
        this.assetDetailsModel.capExBudgetYear10 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 11) {
        this.assetDetailsModel.capExBudgetYear11 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 12) {
        this.assetDetailsModel.capExBudgetYear12 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 13) {
        this.assetDetailsModel.capExBudgetYear13 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 14) {
        this.assetDetailsModel.capExBudgetYear14 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 15) {
        this.assetDetailsModel.capExBudgetYear15 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 16) {
        this.assetDetailsModel.capExBudgetYear16 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 17) {
        this.assetDetailsModel.capExBudgetYear17 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 18) {
        this.assetDetailsModel.capExBudgetYear18 = this.customerbudgtPrice;
      } else if (this.plannedReplacementDate == 19) {
        this.assetDetailsModel.capExBudgetYear19 = this.customerbudgtPrice;
      } else {
        this.assetDetailsModel.capExBudgetYear20 = this.customerbudgtPrice;
      }
      this.assetDetailsModel.replacementCostBudget =
        this.assetDetailsModel.customerBudgetPrice;
    }

    if (
      this.assetDetailsModel.customerBudgetPrice != null  &&
      this.assetDetailsModel.reccWithdrawalDate == null
    ) {
      // this.assetDetailsModel.replacementCostBudget = price;

      this.assetDetailsModel.capExBudgetYear1 = null;
      this.assetDetailsModel.capExBudgetYear2 = null;
      this.assetDetailsModel.capExBudgetYear3 = null;
      this.assetDetailsModel.capExBudgetYear4 = null;
      this.assetDetailsModel.capExBudgetYear5 = null;
      this.assetDetailsModel.capExBudgetYear6 = null;
      this.assetDetailsModel.capExBudgetYear7 = null;
      this.assetDetailsModel.capExBudgetYear8 = null;
      this.assetDetailsModel.capExBudgetYear9 = null;
      this.assetDetailsModel.capExBudgetYear10 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear12 = null;
      this.assetDetailsModel.capExBudgetYear13 = null;
      this.assetDetailsModel.capExBudgetYear14 = null;
      this.assetDetailsModel.capExBudgetYear15 = null;
      this.assetDetailsModel.capExBudgetYear16 = null;
      this.assetDetailsModel.capExBudgetYear17 = null;
      this.assetDetailsModel.capExBudgetYear18 = null;
      this.assetDetailsModel.capExBudgetYear19 = null;
      this.assetDetailsModel.capExBudgetYear20 = null;

      // new changes
      this.assetDetailsModel.replacementCostBudget =
        this.assetDetailsModel.customerBudgetPrice;

      let replacementYear =
        1 +
        Math.round(
          this.assetDetailsModel.nFactor + this.assetDetailsModel.remainingLife
          //this.sharedDataService.nfactor + this.assetDetailsModel.remainingLife
        );

      //new changes

      if (replacementYear == 0 || replacementYear < 0) {
        this.assetDetailsModel.capExBudgetYear1 = this.customerbudgtPrice;
      } else if (replacementYear == 1) {
        this.assetDetailsModel.capExBudgetYear1 = this.customerbudgtPrice;
      } else if (replacementYear == 2) {
        this.assetDetailsModel.capExBudgetYear2 = this.customerbudgtPrice;
      } else if (replacementYear == 3) {
        this.assetDetailsModel.capExBudgetYear3 = this.customerbudgtPrice;
      } else if (replacementYear == 4) {
        this.assetDetailsModel.capExBudgetYear4 = this.customerbudgtPrice;
      } else if (replacementYear == 5) {
        this.assetDetailsModel.capExBudgetYear5 = this.customerbudgtPrice;
      } else if (replacementYear == 6) {
        this.assetDetailsModel.capExBudgetYear6 = this.customerbudgtPrice;
      } else if (replacementYear == 7) {
        this.assetDetailsModel.capExBudgetYear7 = this.customerbudgtPrice;
      } else if (replacementYear == 8) {
        this.assetDetailsModel.capExBudgetYear8 = this.customerbudgtPrice;
      } else if (replacementYear == 9) {
        this.assetDetailsModel.capExBudgetYear9 = this.customerbudgtPrice;
      } else if (replacementYear == 10) {
        this.assetDetailsModel.capExBudgetYear10 = this.customerbudgtPrice;
      } else if (replacementYear == 11) {
        this.assetDetailsModel.capExBudgetYear11 = this.customerbudgtPrice;
      } else if (replacementYear == 12) {
        this.assetDetailsModel.capExBudgetYear12 = this.customerbudgtPrice;
      } else if (replacementYear == 13) {
        this.assetDetailsModel.capExBudgetYear13 = this.customerbudgtPrice;
      } else if (replacementYear == 14) {
        this.assetDetailsModel.capExBudgetYear14 = this.customerbudgtPrice;
      } else if (replacementYear == 15) {
        this.assetDetailsModel.capExBudgetYear15 = this.customerbudgtPrice;
      } else if (replacementYear == 16) {
        this.assetDetailsModel.capExBudgetYear16 = this.customerbudgtPrice;
      } else if (replacementYear == 17) {
        this.assetDetailsModel.capExBudgetYear17 = this.customerbudgtPrice;
      } else if (replacementYear == 18) {
        this.assetDetailsModel.capExBudgetYear18 = this.customerbudgtPrice;
      } else if (replacementYear == 19) {
        this.assetDetailsModel.capExBudgetYear19 = this.customerbudgtPrice;
      } else {
        this.assetDetailsModel.capExBudgetYear20 = this.customerbudgtPrice;
      }
    } else if (
      (this.assetDetailsModel.customerBudgetPrice == null ) &&
      this.assetDetailsModel.reccWithdrawalDate != null
    ) {
      this.assetDetailsModel.capExBudgetYear1 = null;
      this.assetDetailsModel.capExBudgetYear2 = null;
      this.assetDetailsModel.capExBudgetYear3 = null;
      this.assetDetailsModel.capExBudgetYear4 = null;
      this.assetDetailsModel.capExBudgetYear5 = null;
      this.assetDetailsModel.capExBudgetYear6 = null;
      this.assetDetailsModel.capExBudgetYear7 = null;
      this.assetDetailsModel.capExBudgetYear8 = null;
      this.assetDetailsModel.capExBudgetYear9 = null;
      this.assetDetailsModel.capExBudgetYear10 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear12 = null;
      this.assetDetailsModel.capExBudgetYear13 = null;
      this.assetDetailsModel.capExBudgetYear14 = null;
      this.assetDetailsModel.capExBudgetYear15 = null;
      this.assetDetailsModel.capExBudgetYear16 = null;
      this.assetDetailsModel.capExBudgetYear17 = null;
      this.assetDetailsModel.capExBudgetYear18 = null;
      this.assetDetailsModel.capExBudgetYear19 = null;
      this.assetDetailsModel.capExBudgetYear20 = null;

      if (this.plannedReplacementDate == 0) {
        this.assetDetailsModel.capExBudgetYear1 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 1) {
        this.assetDetailsModel.capExBudgetYear1 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 2) {
        this.assetDetailsModel.capExBudgetYear2 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 3) {
        this.assetDetailsModel.capExBudgetYear3 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 4) {
        this.assetDetailsModel.capExBudgetYear4 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 5) {
        this.assetDetailsModel.capExBudgetYear5 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 6) {
        this.assetDetailsModel.capExBudgetYear6 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 7) {
        this.assetDetailsModel.capExBudgetYear7 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 8) {
        this.assetDetailsModel.capExBudgetYear8 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 9) {
        this.assetDetailsModel.capExBudgetYear9 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 10) {
        this.assetDetailsModel.capExBudgetYear10 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 11) {
        this.assetDetailsModel.capExBudgetYear11 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 12) {
        this.assetDetailsModel.capExBudgetYear12 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 13) {
        this.assetDetailsModel.capExBudgetYear13 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 14) {
        this.assetDetailsModel.capExBudgetYear14 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 15) {
        this.assetDetailsModel.capExBudgetYear15 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 16) {
        this.assetDetailsModel.capExBudgetYear16 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 17) {
        this.assetDetailsModel.capExBudgetYear17 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 18) {
        this.assetDetailsModel.capExBudgetYear18 =
          this.assetDetailsModel.replacementCostBudget;
      } else if (this.plannedReplacementDate == 19) {
        this.assetDetailsModel.capExBudgetYear19 =
          this.assetDetailsModel.replacementCostBudget;
      } else {
        this.assetDetailsModel.capExBudgetYear20 =
          this.assetDetailsModel.replacementCostBudget;
      }
    } else if (
      (this.assetDetailsModel.customerBudgetPrice == null ) &&
      this.assetDetailsModel.reccWithdrawalDate == null
    ) {
      this.assetDetailsModel.capExBudgetYear1 = null;
      this.assetDetailsModel.capExBudgetYear2 = null;
      this.assetDetailsModel.capExBudgetYear3 = null;
      this.assetDetailsModel.capExBudgetYear4 = null;
      this.assetDetailsModel.capExBudgetYear5 = null;
      this.assetDetailsModel.capExBudgetYear6 = null;
      this.assetDetailsModel.capExBudgetYear7 = null;
      this.assetDetailsModel.capExBudgetYear8 = null;
      this.assetDetailsModel.capExBudgetYear9 = null;
      this.assetDetailsModel.capExBudgetYear10 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear11 = null;
      this.assetDetailsModel.capExBudgetYear12 = null;
      this.assetDetailsModel.capExBudgetYear13 = null;
      this.assetDetailsModel.capExBudgetYear14 = null;
      this.assetDetailsModel.capExBudgetYear15 = null;
      this.assetDetailsModel.capExBudgetYear16 = null;
      this.assetDetailsModel.capExBudgetYear17 = null;
      this.assetDetailsModel.capExBudgetYear18 = null;
      this.assetDetailsModel.capExBudgetYear19 = null;
      this.assetDetailsModel.capExBudgetYear20 = null;

      let ReplacementCostBuggetValue =
        this.assetDetailsModel.replacementCostBudget;

      let replacementYear =
        1 +
        Math.round(
          this.assetDetailsModel.nFactor + this.assetDetailsModel.remainingLife
          //this.sharedDataService.nfactor + this.assetDetailsModel.remainingLife
        );

      //new changes
      this.GetReplacementCostBugget();

      if ((replacementYear <= 0 || replacementYear < 0)) {
        this.assetDetailsModel.capExBudgetYear1 = ReplacementCostBuggetValue;
      } else if (replacementYear == 1) {
        this.assetDetailsModel.capExBudgetYear1 = ReplacementCostBuggetValue;
      } else if (replacementYear == 2) {
        this.assetDetailsModel.capExBudgetYear2 = ReplacementCostBuggetValue;
      } else if (replacementYear == 3) {
        this.assetDetailsModel.capExBudgetYear3 = ReplacementCostBuggetValue;
      } else if (replacementYear == 4) {
        this.assetDetailsModel.capExBudgetYear4 = ReplacementCostBuggetValue;
      } else if (replacementYear == 5) {
        this.assetDetailsModel.capExBudgetYear5 = ReplacementCostBuggetValue;
      } else if (replacementYear == 6) {
        this.assetDetailsModel.capExBudgetYear6 = ReplacementCostBuggetValue;
      } else if (replacementYear == 7) {
        this.assetDetailsModel.capExBudgetYear7 = ReplacementCostBuggetValue;
      } else if (replacementYear == 8) {
        this.assetDetailsModel.capExBudgetYear8 = ReplacementCostBuggetValue;
      } else if (replacementYear == 9) {
        this.assetDetailsModel.capExBudgetYear9 = ReplacementCostBuggetValue;
      } else if (replacementYear == 10) {
        this.assetDetailsModel.capExBudgetYear10 = ReplacementCostBuggetValue;
      } else if (replacementYear == 11) {
        this.assetDetailsModel.capExBudgetYear11 = ReplacementCostBuggetValue;
      } else if (replacementYear == 12) {
        this.assetDetailsModel.capExBudgetYear12 = ReplacementCostBuggetValue;
      } else if (replacementYear == 13) {
        this.assetDetailsModel.capExBudgetYear13 = ReplacementCostBuggetValue;
      } else if (replacementYear == 14) {
        this.assetDetailsModel.capExBudgetYear14 = ReplacementCostBuggetValue;
      } else if (replacementYear == 15) {
        this.assetDetailsModel.capExBudgetYear15 = ReplacementCostBuggetValue;
      } else if (replacementYear == 16) {
        this.assetDetailsModel.capExBudgetYear16 = ReplacementCostBuggetValue;
      } else if (replacementYear == 17) {
        this.assetDetailsModel.capExBudgetYear17 = ReplacementCostBuggetValue;
      } else if (replacementYear == 18) {
        this.assetDetailsModel.capExBudgetYear18 = ReplacementCostBuggetValue;
      } else if (replacementYear == 19) {
        this.assetDetailsModel.capExBudgetYear19 = ReplacementCostBuggetValue;
      } else {
        this.assetDetailsModel.capExBudgetYear20 = ReplacementCostBuggetValue;
      }
    }
  }

  CalculateActualAge(event, fieldName) {
    if (event != undefined && event != null && fieldName == undefined) {
      this.assetDetailsModel.expectedLife = event;
    }

    if (
      this.assetDetailsModel.installDate != undefined &&
      this.assetDetailsModel.installDate != null
    ) {
      var CurrDate = new Date();
      var diff = Math.abs(
        this.assetDetailsModel.installDate.getTime() - CurrDate.getTime()
      );
      var diffDays = Math.round(diff / (1000 * 3600 * 24 * 365));
      this.assetDetailsModel.actualAge = diffDays;

      //Calculating age group
      if (this.assetDetailsModel.actualAge < 1) {
        this.assetDetailsModel.ageGroup = "<1";
      } else if (
        this.assetDetailsModel.actualAge >= 1 &&
        this.assetDetailsModel.actualAge <= 5
      ) {
        this.assetDetailsModel.ageGroup = "1-5";
      } else if (
        this.assetDetailsModel.actualAge >= 6 &&
        this.assetDetailsModel.actualAge <= 10
      ) {
        this.assetDetailsModel.ageGroup = "6-10";
      } else if (
        this.assetDetailsModel.actualAge >= 11 &&
        this.assetDetailsModel.actualAge <= 15
      ) {
        this.assetDetailsModel.ageGroup = "11-15";
      } else if (
        this.assetDetailsModel.actualAge >= 16 &&
        this.assetDetailsModel.actualAge <= 20
      ) {
        this.assetDetailsModel.ageGroup = "16-20";
      } else if (
        this.assetDetailsModel.actualAge >= 21 &&
        this.assetDetailsModel.actualAge <= 25
      ) {
        this.assetDetailsModel.ageGroup = "21-25";
      } else if (this.assetDetailsModel.actualAge > 25) {
        this.assetDetailsModel.ageGroup = "25+";
      }
      if (
        this.assetDetailsModel.expectedLife != undefined &&
        this.assetDetailsModel.expectedLife != null
      ) {
        let remainingLife = this.assetDetailsModel.expectedLife - diffDays;
        if (remainingLife > 0) {
          this.assetDetailsModel.remainingLife = remainingLife;
        } else {
          this.assetDetailsModel.remainingLife = 0;
        }
      } else {
        this.assetDetailsModel.remainingLife = 0;
      }
      if (
        this.assetDetailsModel.assetTypeID != undefined &&
        this.assetDetailsModel.assetLocationID != undefined
      ) {
        this.GetReplacementCostBugget();
      }
    }
  }
  AgeGroupOnRefactor() {
    if (
      this.assetDetailsModel.installDate != undefined &&
      this.assetDetailsModel.installDate != null
    ) {
      var CurrDate = new Date();
      var diff = Math.abs(
        this.assetDetailsModel.installDate.getTime() - CurrDate.getTime()
      );
      var diffDays = Math.round(diff / (1000 * 3600 * 24 * 365));
      this.assetDetailsModel.actualAge = diffDays;

      //Calculating age group
      if (this.assetDetailsModel.actualAge < 1) {
        this.assetDetailsModel.ageGroup = "<1";
      } else if (
        this.assetDetailsModel.actualAge >= 1 &&
        this.assetDetailsModel.actualAge <= 5
      ) {
        this.assetDetailsModel.ageGroup = "1-5";
      } else if (
        this.assetDetailsModel.actualAge >= 6 &&
        this.assetDetailsModel.actualAge <= 10
      ) {
        this.assetDetailsModel.ageGroup = "6-10";
      } else if (
        this.assetDetailsModel.actualAge >= 11 &&
        this.assetDetailsModel.actualAge <= 15
      ) {
        this.assetDetailsModel.ageGroup = "11-15";
      } else if (
        this.assetDetailsModel.actualAge >= 16 &&
        this.assetDetailsModel.actualAge <= 20
      ) {
        this.assetDetailsModel.ageGroup = "16-20";
      } else if (
        this.assetDetailsModel.actualAge >= 21 &&
        this.assetDetailsModel.actualAge <= 25
      ) {
        this.assetDetailsModel.ageGroup = "21-25";
      } else if (this.assetDetailsModel.actualAge > 25) {
        this.assetDetailsModel.ageGroup = "25+";
      }
      if (
        this.assetDetailsModel.expectedLife != undefined &&
        this.assetDetailsModel.expectedLife != null
      ) {
        let remainingLife = this.assetDetailsModel.expectedLife - diffDays;
        if (remainingLife > 0) {
          this.assetDetailsModel.remainingLife = remainingLife;
        } else {
          this.assetDetailsModel.remainingLife = 0;
        }
      } else {
        this.assetDetailsModel.remainingLife = 0;
      }

    }
  }
 async GetReplacementCostBugget() {
  this.AssetTypeRmList=[]
  await  this.coreDataService.CheckAssetsRMBudgetExists(this.assetDetailsModel.assetID).subscribe(async (response) => {
      
      if (response == 1) {
        this.assetDetailsModel.rMBudgetYear1 = 0;
        this.assetDetailsModel.rMBudgetYear2 = 0;
        this.assetDetailsModel.rMBudgetYear3 = 0;
        this.assetDetailsModel.rMBudgetYear4 = 0;
        this.assetDetailsModel.rMBudgetYear5 = 0;
        this.assetDetailsModel.rMBudgetYear6 = 0;
        this.assetDetailsModel.rMBudgetYear7 = 0;
        this.assetDetailsModel.rMBudgetYear8 = 0;
        this.assetDetailsModel.rMBudgetYear9 = 0;
        this.assetDetailsModel.rMBudgetYear10 = 0;
        this.assetDetailsModel.rMBudgetYear11 = 0;
        this.assetDetailsModel.rMBudgetYear12 = 0;
        this.assetDetailsModel.rMBudgetYear13 = 0;
        this.assetDetailsModel.rMBudgetYear14 = 0;
        this.assetDetailsModel.rMBudgetYear15 = 0;
        this.assetDetailsModel.rMBudgetYear16 = 0;
        this.assetDetailsModel.rMBudgetYear17 = 0;
        this.assetDetailsModel.rMBudgetYear18 = 0;
        this.assetDetailsModel.rMBudgetYear19 = 0;
        this.assetDetailsModel.rMBudgetYear20 = 0;
   await   this.coreDataService
          .GetAssetTypeRM(this.assetDetailsModel.assetTypeID)
          .subscribe(async (res) => {
            res.forEach(
              (row) => {
                this.AssetTypeRmList.push(row);
              },
              (catchError) => {
                if (catchError) {
                  this.toastrService.error(
                    "error" + catchError.status + " " + catchError["message"]
                  );
                }
              }
            );
            this.currDate = new Date(this.assetDetailsModel.installDate);
            console.log("CurrDate0", this.currDate);
            let Years =
              new Date().getFullYear() -
              this.assetDetailsModel.installDate.getFullYear();
            console.log("years", Years);
         await   this.AssetTypeRmList.forEach(async(res, index) => {

         this.index=index;
              this.repairAndMaintenanceCostBudget.AssetTypeID = this.assetDetailsModel.assetTypeID;
              this.repairAndMaintenanceCostBudget.AssetTypeRMID = res.assetTypeRMID;
              this.repairAndMaintenanceCostBudget.Capacity =
                this.assetDetailsModel.capacity != undefined
                  ? this.assetDetailsModel.capacity
                  : 0;
              this.repairAndMaintenanceCostBudget.Size =
                this.assetDetailsModel.assetSizeCd != undefined
                  ? this.assetDetailsModel.assetSizeCd
                  : 0;
              this.repairAndMaintenanceCostBudget.UOMCd = this.assetDetailsModel.uOMCd;
             this.repairAndMaintenanceCostBudget.LocationID = this.assetDetailsModel.assetLocationID;
              console.log(
                "this.assetDetailsModel.quantity",
                this.assetDetailsModel.quantity
              );
              this.repairAndMaintenanceCostBudget.Quantity = this.assetDetailsModel.quantity;
    
           this.RMCost=await this.DataService.getRepairAndMaintenanceCostBudget(this.repairAndMaintenanceCostBudget)
           //.subscribe((val:number)=>{

            //his.RMCost=val
          
           this.index1+=1;    
               
            
                    if (res.frequency == 1) {
                      let totalYear = Years + 20;

                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);
                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 2) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);
                      let seq = 1;
                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 3) {
                      let totalYear = Years + 20;

                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);
                      let seq = 1;
                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 4) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);
                      let seq = 1;
                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 5) {
                      let totalYear = Years + 20;
                      let diff = 0;

                      diff = Math.floor(totalYear / res.frequency);
                      let seq = 1;
                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 6) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);
                      let seq = 1;
                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 7) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 8) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 9) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 10) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 11) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 12) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 13) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 14) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 15) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 16) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 17) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 18) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency == 19) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);
                    } else if (res.frequency >= 20) {
                      let totalYear = Years + 20;
                      let diff = 0;
                      diff = Math.floor(totalYear / res.frequency);

                      let seq = 1;

                      for (let i = 0; i < diff; i++) {
                        let y =
                          this.currDate.getFullYear() +
                          res.frequency -
                          new Date().getFullYear();
                        console.log("Y", y);
                        if (y == 1) {
                          this.assetDetailsModel.rMBudgetYear1 =
                            this.assetDetailsModel.rMBudgetYear1 + this.RMCost;
                        } else if (y == 2) {
                          this.assetDetailsModel.rMBudgetYear2 =
                            this.assetDetailsModel.rMBudgetYear2 + this.RMCost;
                        } else if (y == 3) {
                          this.assetDetailsModel.rMBudgetYear3 =
                            this.assetDetailsModel.rMBudgetYear3 + this.RMCost;
                        } else if (y == 4) {
                          this.assetDetailsModel.rMBudgetYear4 =
                            this.assetDetailsModel.rMBudgetYear4 + this.RMCost;
                        } else if (y == 5) {
                          this.assetDetailsModel.rMBudgetYear5 =
                            this.assetDetailsModel.rMBudgetYear5 + this.RMCost;
                        } else if (y == 6) {
                          this.assetDetailsModel.rMBudgetYear6 =
                            this.assetDetailsModel.rMBudgetYear6 + this.RMCost;
                        } else if (y == 7) {
                          this.assetDetailsModel.rMBudgetYear7 =
                            this.assetDetailsModel.rMBudgetYear7 + this.RMCost;
                        } else if (y == 8) {
                          this.assetDetailsModel.rMBudgetYear8 =
                            this.assetDetailsModel.rMBudgetYear8 + this.RMCost;
                        } else if (y == 9) {
                          this.assetDetailsModel.rMBudgetYear9 =
                            this.assetDetailsModel.rMBudgetYear9 + this.RMCost;
                        } else if (y == 10) {
                          this.assetDetailsModel.rMBudgetYear10 =
                            this.assetDetailsModel.rMBudgetYear10 + this.RMCost;
                        } else if (y == 11) {
                          this.assetDetailsModel.rMBudgetYear11 =
                            this.assetDetailsModel.rMBudgetYear11 + this.RMCost;
                        } else if (y == 12) {
                          this.assetDetailsModel.rMBudgetYear12 =
                            this.assetDetailsModel.rMBudgetYear12 + this.RMCost;
                        } else if (y == 13) {
                          this.assetDetailsModel.rMBudgetYear13 =
                            this.assetDetailsModel.rMBudgetYear13 + this.RMCost;
                        } else if (y == 14) {
                          this.assetDetailsModel.rMBudgetYear14 =
                            this.assetDetailsModel.rMBudgetYear14 + this.RMCost;
                        } else if (y == 15) {
                          this.assetDetailsModel.rMBudgetYear15 =
                            this.assetDetailsModel.rMBudgetYear15 + this.RMCost;
                        } else if (y == 16) {
                          this.assetDetailsModel.rMBudgetYear16 =
                            this.assetDetailsModel.rMBudgetYear16 + this.RMCost;
                        } else if (y == 17) {
                          this.assetDetailsModel.rMBudgetYear17 =
                            this.assetDetailsModel.rMBudgetYear17 + this.RMCost;
                        } else if (y == 18) {
                          this.assetDetailsModel.rMBudgetYear18 =
                            this.assetDetailsModel.rMBudgetYear18 + this.RMCost;
                        } else if (y == 19) {
                          this.assetDetailsModel.rMBudgetYear19 =
                            this.assetDetailsModel.rMBudgetYear19 + this.RMCost;
                        } else if (y == 20) {
                          this.assetDetailsModel.rMBudgetYear20 =
                            this.assetDetailsModel.rMBudgetYear20 + this.RMCost;
                        }

                        this.currDate.setFullYear(
                          this.currDate.getFullYear() + res.frequency
                        );
                      }
                      this.currDate = new Date(this.assetDetailsModel.installDate);

                    }
                  
                    if(this.index==(this.index1-1)){
                      this.index1=0;
                      this.AssetTypeRmList = [];
                      if (this.assetDetailsModel.rMBudgetYear1 == 0) {
                        this.assetDetailsModel.rMBudgetYear1 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear2 == 0) {
                        this.assetDetailsModel.rMBudgetYear2 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear3 == 0) {
                        this.assetDetailsModel.rMBudgetYear3 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear4 == 0) {
                        this.assetDetailsModel.rMBudgetYear4 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear5 == 0) {
                        this.assetDetailsModel.rMBudgetYear5 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear6 == 0) {
                        this.assetDetailsModel.rMBudgetYear6 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear7 == 0) {
                        this.assetDetailsModel.rMBudgetYear7 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear8 == 0) {
                        this.assetDetailsModel.rMBudgetYear8 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear9 == 0) {
                        this.assetDetailsModel.rMBudgetYear9 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear10 == 0) {
                        this.assetDetailsModel.rMBudgetYear10 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear11 == 0) {
                        this.assetDetailsModel.rMBudgetYear11 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear12 == 0) {
                        this.assetDetailsModel.rMBudgetYear12 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear13 == 0) {
                        this.assetDetailsModel.rMBudgetYear13 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear14 == 0) {
                        this.assetDetailsModel.rMBudgetYear14 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear15 == 0) {
                        this.assetDetailsModel.rMBudgetYear15 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear16 == 0) {
                        this.assetDetailsModel.rMBudgetYear16 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear17 == 0) {
                        this.assetDetailsModel.rMBudgetYear17 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear18 == 0) {
                        this.assetDetailsModel.rMBudgetYear18 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear19 == 0) {
                        this.assetDetailsModel.rMBudgetYear19 = undefined;
                      }
                      if (this.assetDetailsModel.rMBudgetYear20 == 0) {
                        this.assetDetailsModel.rMBudgetYear20 = undefined;
                      }
                    }
                  
              // end of API Call
           // }) 

            }); //end of loop
         
  
    
          }); //end of API call
          
      }//end of if condition
     
    })
   

    this.replacementCost.AssetTypeID = this.assetDetailsModel.assetTypeID;
    this.replacementCost.Capacity =
      this.assetDetailsModel.capacity != undefined
        ? this.assetDetailsModel.capacity
        : 0;
    this.replacementCost.Size =
      this.assetDetailsModel.assetSizeCd != undefined
        ? this.assetDetailsModel.assetSizeCd
        : 0;
    this.replacementCost.UOMCd = this.assetDetailsModel.uOMCd;
    this.replacementCost.LocationID = this.assetDetailsModel.assetLocationID;
    console.log(
      "this.assetDetailsModel.quantity",
      this.assetDetailsModel.quantity
    );
    this.replacementCost.Quantity = this.assetDetailsModel.quantity;
    if (this.replacementCost != undefined && this.replacementCost != null) {
      this.DataService.getReplacemnetCostBudget(this.replacementCost).subscribe(
        (res) => {
          if (res != undefined && res != null) {
            this.assetDetailsModel.replacementCostBudget = parseFloat(
              res.toString()
            );

            //Calculating BreakEven point
            let AssetSpent = 0; // temp value , change it once the api gets ready
            let breakEvenPoint = 0;
            let ReplacementCostBuggetValue;
            if (this.assetDetailsModel.customerBudgetPrice == null ) {
              ReplacementCostBuggetValue =
                this.assetDetailsModel.replacementCostBudget;
            } else {
              ReplacementCostBuggetValue =
                this.assetDetailsModel.customerBudgetPrice;
            }

            let AssetStatus = this.ddlStatusCd.find((obj) => {
              return obj.codeID == this.assetDetailsModel.statusCd;
            });

            if (
              AssetStatus != undefined &&
              AssetStatus.codeValue.toLowerCase().includes("decommision")
            ) {
              ReplacementCostBuggetValue = 0;
            }
            if (
              ReplacementCostBuggetValue != undefined &&
              ReplacementCostBuggetValue > 0
            ) {
              breakEvenPoint = AssetSpent / ReplacementCostBuggetValue;
            } else {
              breakEvenPoint = 0;
            }
            this.assetDetailsModel.replacementCostBudget =
              ReplacementCostBuggetValue;

            let conditionRatingObj = this.ddlConditionRatingCd.find((obj) => {
              return obj.codeID == this.assetDetailsModel.conditionRatingCd;
            });
            let essentialServiceObj = this.ddlEssentialServiceCd.find((obj) => {
              return obj.codeID == this.assetDetailsModel.essentialServiceCd;
            });
            let criticalityObj = this.ddlCriticalityCd.find((obj) => {
              return obj.codeID == this.assetDetailsModel.criticalityCd;
            });
            let maintenanceStdObj = this.ddlMaintenanceStdCd.find((obj) => {
              return obj.codeID == this.assetDetailsModel.maintenanceStdCd;
            });
            let assetRunTimeObj = this.ddlAssetRunTimeCd.find((obj) => {
              return obj.codeID == this.assetDetailsModel.assetRunTimeCd;
            });
            let refrigerantWeight;
            if (
              this.assetDetailsModel.refrigerantCd != undefined &&
              this.assetDetailsModel.refrigerantCd != null &&
              this.assetDetailsModel.refrigerantCd != 0
            ) {
              let refrigerantobj = this.ddlRefrigerantCd.find((obj) => {
                return obj.codeID == this.assetDetailsModel.refrigerantCd;
              });
              if (refrigerantobj != undefined) {
                refrigerantWeight = refrigerantobj.weightage / 100;
              } else {
                refrigerantWeight = 1;
              }
            } else {
              refrigerantWeight = 1;
            }
            let environmentWeight;
            if (
              this.assetDetailsModel.environmentCd != undefined &&
              this.assetDetailsModel.environmentCd != null &&
              this.assetDetailsModel.environmentCd != 0
            ) {
              let environmentobj = this.ddlEnvironmentCd.find((obj) => {
                return obj.codeID == this.assetDetailsModel.environmentCd;
              });
              if (environmentobj != undefined) {
                environmentWeight = environmentobj.weightage / 100;
              } else {
                environmentWeight = 1;
              }
            } else {
              environmentWeight = 1;
            }

            if (
              this.assetDetailsModel.expectedLife != undefined &&
              this.assetDetailsModel.remainingLife != undefined &&
              conditionRatingObj != undefined &&
              essentialServiceObj != undefined &&
              criticalityObj != undefined &&
              maintenanceStdObj != undefined &&
              assetRunTimeObj != undefined
            ) {
              var replacementYear;
              let conditionRatingWeight = conditionRatingObj.weightage / 100;
              let essentialServiceWeight = essentialServiceObj.weightage / 100;
              let criticalityWeight = criticalityObj.weightage / 100;
              let maintenanceStdWeight = maintenanceStdObj.weightage / 100;
              let assetRunTimeWeight = assetRunTimeObj.weightage / 100;
              if (this.IsNFactorPresent == true) {
                this.assetDetailsModel.nFactor =
                  parseFloat((conditionRatingWeight *
                    essentialServiceWeight *
                    criticalityWeight *
                    maintenanceStdWeight *
                    assetRunTimeWeight *
                    environmentWeight *
                    refrigerantWeight *
                    (1 +
                      this.assetDetailsModel.remainingLife /
                      this.assetDetailsModel.expectedLife) *
                    (1 - breakEvenPoint)).toFixed(2));
              }
              this.AgeGroupOnRefactor()
              replacementYear =
                1 +
                Math.round(
                  this.assetDetailsModel.nFactor +
                  this.assetDetailsModel.remainingLife
                );

              //CUSTOMER BUDGET PRICE IMPLEMENTATION
              if (
                (this.assetDetailsModel.customerBudgetPrice == null ||
                 
                  this.assetDetailsModel.customerBudgetPrice == undefined) &&
                this.assetDetailsModel.reccWithdrawalDate == null
              ) {
                this.assetDetailsModel.capExBudgetYear1 = null;
                this.assetDetailsModel.capExBudgetYear2 = null;
                this.assetDetailsModel.capExBudgetYear3 = null;
                this.assetDetailsModel.capExBudgetYear4 = null;
                this.assetDetailsModel.capExBudgetYear5 = null;
                this.assetDetailsModel.capExBudgetYear6 = null;
                this.assetDetailsModel.capExBudgetYear7 = null;
                this.assetDetailsModel.capExBudgetYear8 = null;
                this.assetDetailsModel.capExBudgetYear9 = null;
                this.assetDetailsModel.capExBudgetYear10 = null;
                this.assetDetailsModel.capExBudgetYear11 = null;
                this.assetDetailsModel.capExBudgetYear11 = null;
                this.assetDetailsModel.capExBudgetYear12 = null;
                this.assetDetailsModel.capExBudgetYear13 = null;
                this.assetDetailsModel.capExBudgetYear14 = null;
                this.assetDetailsModel.capExBudgetYear15 = null;
                this.assetDetailsModel.capExBudgetYear16 = null;
                this.assetDetailsModel.capExBudgetYear17 = null;
                this.assetDetailsModel.capExBudgetYear18 = null;
                this.assetDetailsModel.capExBudgetYear19 = null;
                this.assetDetailsModel.capExBudgetYear20 = null;
              } else if (
                this.assetDetailsModel.reccWithdrawalDate == null &&
                this.assetDetailsModel.customerBudgetPrice == null
              ) {
                var CurrDate = new Date();
                var diff = Math.abs(
                  this.assetDetailsModel.reccWithdrawalDate.getTime() -
                  CurrDate.getTime()
                );
                this.plannedReplacementDate = Math.round(
                  diff / (1000 * 3600 * 24 * 365)
                );
                this.assetDetailsModel.capExBudgetYear1 = null;
                this.assetDetailsModel.capExBudgetYear2 = null;
                this.assetDetailsModel.capExBudgetYear3 = null;
                this.assetDetailsModel.capExBudgetYear4 = null;
                this.assetDetailsModel.capExBudgetYear5 = null;
                this.assetDetailsModel.capExBudgetYear6 = null;
                this.assetDetailsModel.capExBudgetYear7 = null;
                this.assetDetailsModel.capExBudgetYear8 = null;
                this.assetDetailsModel.capExBudgetYear9 = null;
                this.assetDetailsModel.capExBudgetYear10 = null;
                this.assetDetailsModel.capExBudgetYear11 = null;
                this.assetDetailsModel.capExBudgetYear12 = null;
                this.assetDetailsModel.capExBudgetYear13 = null;
                this.assetDetailsModel.capExBudgetYear14 = null;
                this.assetDetailsModel.capExBudgetYear15 = null;
                this.assetDetailsModel.capExBudgetYear16 = null;
                this.assetDetailsModel.capExBudgetYear17 = null;
                this.assetDetailsModel.capExBudgetYear18 = null;
                this.assetDetailsModel.capExBudgetYear19 = null;
                this.assetDetailsModel.capExBudgetYear20 = null;
                if (this.plannedReplacementDate == 0) {
                  this.assetDetailsModel.capExBudgetYear1 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 1) {
                  this.assetDetailsModel.capExBudgetYear1 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 2) {
                  this.assetDetailsModel.capExBudgetYear2 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 3) {
                  this.assetDetailsModel.capExBudgetYear3 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 4) {
                  this.assetDetailsModel.capExBudgetYear4 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 5) {
                  this.assetDetailsModel.capExBudgetYear5 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 6) {
                  this.assetDetailsModel.capExBudgetYear6 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 7) {
                  this.assetDetailsModel.capExBudgetYear7 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 8) {
                  this.assetDetailsModel.capExBudgetYear8 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 9) {
                  this.assetDetailsModel.capExBudgetYear9 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 10) {
                  this.assetDetailsModel.capExBudgetYear10 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 11) {
                  this.assetDetailsModel.capExBudgetYear11 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 12) {
                  this.assetDetailsModel.capExBudgetYear12 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 13) {
                  this.assetDetailsModel.capExBudgetYear13 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 14) {
                  this.assetDetailsModel.capExBudgetYear14 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 15) {
                  this.assetDetailsModel.capExBudgetYear15 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 16) {
                  this.assetDetailsModel.capExBudgetYear16 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 17) {
                  this.assetDetailsModel.capExBudgetYear17 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 18) {
                  this.assetDetailsModel.capExBudgetYear18 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else if (this.plannedReplacementDate == 19) {
                  this.assetDetailsModel.capExBudgetYear19 =
                    this.assetDetailsModel.customerBudgetPrice;
                } else {
                  this.assetDetailsModel.capExBudgetYear20 =
                    this.assetDetailsModel.customerBudgetPrice;
                }
              }
              //CUSTOMER BUDGET PRICE IMPLEMENTATION

              //NEW FIELD CALCULATED REPLACEMENT YEAR

              var d = new Date();
              var year = d.getFullYear();
              var month = d.getMonth();
              var day = d.getDate();
              var c = new Date(year + replacementYear, month, day);
              // this.assetDetailsModel.calculatedReplacementYear = c.getFullYear().toFixed(0).toString();
              if (
                replacementYear > 0 &&
                (this.assetDetailsModel.reccWithdrawalDate == null ||
                  this.assetDetailsModel.reccWithdrawalDate == undefined)
              ) {
                var c = new Date(year + replacementYear, month, day);
                this.assetDetailsModel.calculatedReplacementYear = c
                  .getFullYear()
                  .toFixed(0)
                  .toString();
              } else if (
                replacementYear <= 0 &&
                (this.assetDetailsModel.reccWithdrawalDate == null ||
                  this.assetDetailsModel.reccWithdrawalDate == undefined)
              ) {
                var c = new Date(year + 0, month, day);
                this.assetDetailsModel.calculatedReplacementYear = c
                  .getFullYear()
                  .toFixed(0)
                  .toString();
              }

              //NEW FIELD CALCULATED REPLACEMENT YEAR

              if (
                !AssetStatus.codeValue.toLowerCase().includes("decommision")
              ) {
                if (
                  (this.assetDetailsModel.customerBudgetPrice == null ||
                    
                    this.assetDetailsModel.customerBudgetPrice == undefined) &&
                  this.assetDetailsModel.reccWithdrawalDate == null
                ) {
                  if (replacementYear <= 0) {
                    this.assetDetailsModel.capExBudgetYear1 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 1) {
                    this.assetDetailsModel.capExBudgetYear1 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 2) {
                    this.assetDetailsModel.capExBudgetYear2 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 3) {
                    this.assetDetailsModel.capExBudgetYear3 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 4) {
                    this.assetDetailsModel.capExBudgetYear4 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 5) {
                    this.assetDetailsModel.capExBudgetYear5 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 6) {
                    this.assetDetailsModel.capExBudgetYear6 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 7) {
                    this.assetDetailsModel.capExBudgetYear7 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 8) {
                    this.assetDetailsModel.capExBudgetYear8 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 9) {
                    this.assetDetailsModel.capExBudgetYear9 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 10) {
                    this.assetDetailsModel.capExBudgetYear10 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 11) {
                    this.assetDetailsModel.capExBudgetYear11 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 12) {
                    this.assetDetailsModel.capExBudgetYear12 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 13) {
                    this.assetDetailsModel.capExBudgetYear13 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 14) {
                    this.assetDetailsModel.capExBudgetYear14 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 15) {
                    this.assetDetailsModel.capExBudgetYear15 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 16) {
                    this.assetDetailsModel.capExBudgetYear16 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 17) {
                    this.assetDetailsModel.capExBudgetYear17 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 18) {
                    this.assetDetailsModel.capExBudgetYear18 =
                      ReplacementCostBuggetValue;
                  } else if (replacementYear == 19) {
                    this.assetDetailsModel.capExBudgetYear19 =
                      ReplacementCostBuggetValue;
                  } else {
                    this.assetDetailsModel.capExBudgetYear20 =
                      ReplacementCostBuggetValue;
                  }
                }
              }
            }
          }
        }
      );
    }
  }

  closeModel(res) {
    if (res == "false") {
      this.isSelectedOther = false;
      this.IsOtherSelected = false;
    } else if (res == "true") {
      this.isSelectedOther = false;
      this.IsOtherSelected = true;
    }
  }

  convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  // public addNew(): void {
  //   this.ddlModelCd.push({
  //     codeValue: this.filter,
  //   });
  //   this.handleFilter(this.filter);
  // }

  // public handleFilter(value) {
  //   this.filter = value;
  //   this.ddlModelCd = this.ddlModelCd.filter((s) => s.codeValue.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  // }

  // Added a method for validation relaxation
  ToggleValidation(event) {
    console.log(event);

    if (event == true) {
      //disble validation on lower panel
      this.IsValidationDisabled = true;
      this.assetDetailsModel.isIncomplete = true;
      this.IsCancelClickedSwitchDialogBox = true;
      this.GetAssetTypeByNameDropDownUrl("");

      if (this.activatedRoute.snapshot.paramMap.get("id") != null) {
        this.IsCancelClickedSwitchDialogBox = true;
        // this.GetAssetFieldConfigurationTemplate();
      }
    } else {
      //disble validation on lower panel
      this.IsValidationDisabled = false;
      this.assetDetailsModel.isIncomplete = false;
      if (this.activatedRoute.snapshot.paramMap.get("id") != null) {
        //  this.IsCancelClickedSwitchDialogBox=true;
        this.GetAssetFieldConfigurationTemplate();
      }
    }
  }
  closeSwitchDialogBox() {
    this.IsCancelClickedSwitchDialogBox = false;
    this.IsValidationDisabled = false;
    this.assetDetailsModel.isIncomplete = false;
  }
  sufficientDetails() {
    this.IsCancelClickedSwitchDialogBox = false;
    this.IsValidationDisabled = false;
    this.assetDetailsModel.isIncomplete = false;
    this.GetAssetFieldConfigurationTemplate();
  }
  inSufficientDetails() {
    this.IsCancelClickedSwitchDialogBox = false;
    this.IsValidationDisabled = true;
    this.assetDetailsModel.isIncomplete = true;
    this.GetAssetFieldConfigurationTemplate();
  }
  Refactor() {
    this.GetReplacementCostBugget();
    //this.assetDetailsModel.nFactor = this.sharedDataService.nfactor;
    this.sharedDataService.showSuccess(
      "Asset Refactored. Please save the asset to save the changes"
    );
  }

  onReady(map: any): void {
    if(this.SelectedMap=='Baidu')
    {
    this._map = map;
    if(this._map!=undefined){
    this._map.clearOverlays();
    var point = new BMap.Point(this.longitude,this.latitude);
    this._map.centerAndZoom(point, 11);
    this._map.addControl(new BMap.MapTypeControl());
    this._map.enableScrollWheelZoom(true);

    var marker = new BMap.Marker(point);
    marker.enableDragging();
    this._map.addOverlay(marker);
    this._map.enableDragging();
   

    const navigationControl = new BMap.NavigationControl({
      anchor: 'BMAP_ANCHOR_BOTTOM_RIGHT',
      type: 'BMAP_NAVIGATION_CONTROL_ZOOM'
    });
    this._map.addControl(navigationControl);
   
    marker.addEventListener('dragend', (e) => {
      this.latitude = e.point.lat;
      this.longitude = e.point.lng;
      this.assetDetailsModel.geoLocation =
      this.latitude + "," + this.longitude;
      console.log(this.latitude, this.longitude);
    });

    this._map.addEventListener('click', (e) => {
      this._map.clearOverlays();
      marker = new BMap.Marker(e.point);
      marker.enableDragging();
      this._map.addOverlay(marker);
      marker.addEventListener('dragend', (e) => {
        this.latitude = e.point.lat;
        this.longitude = e.point.lng;
        this.assetDetailsModel.geoLocation =
        this.latitude + "," + this.longitude;
        console.log(this.latitude, this.longitude);
      });
    });
  }
  }
}

//   searchaddress(event) {
//     if(this.SelectedMap=='Baidu'){
//     if (event.key === 'Enter') {
//       var local = new BMap.LocalSearch(this._map, {
//         renderOptions: { map: this._map, autoViewport: true },
//       });
//       local.search((event.target as HTMLInputElement).value);
//       local.setSearchCompleteCallback((results) => {
//         console.log(results);
//         var point = results.getPoi(0).point;
//         this._map.setCenter(new BMap.Point(point.lat, point.lng));
//         var marker = new BMap.Marker(new BMap.Point(point.lat, point.lng));
//         this._map.addOverlay(marker);

//         this.latitude = point.lat;
//         this.longitude = point.lng;
//         this.assetDetailsModel.geoLocation =
//         this.latitude + "," + this.longitude;

//         console.log(this.latitude, this.longitude);
//       });
//     }
//   }
// }

onValueChangeMap(){
  if(this.SelectedMap=='Google'){
    this.BaiduAutocomplete;
  
  }
  else if(this.SelectedMap=='Baidu'){
    google.maps.event.clearInstanceListeners(this.searchElementRef.nativeElement);
    
  }
}


}
