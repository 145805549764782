import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { EvaluatePortalUrl } from "../Services/shared/shared";
import { AuthenticationService } from "../Service/authentication.service";
import { SharedDataService } from "../Service/CommonUtility.service";
import { SnotifyService } from 'ng-snotify';
import { CoreDataService } from '../Service/core-data.service'
import { from } from 'rxjs';
import { ConfigObject } from './login.model';
import * as CryptoJS from 'crypto-js';
declare var window: any;
import { LocaleHelper } from '../locale.helper';
import { OrgSettingEntity } from '../SystemAdmin/organisation-setting/organisation-setting.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from "../../environments/environment";
import { AuthService } from '../Service/auth.service';

// declare var window: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public arrLanguage: Array<{ text: string, value: string }> = [
    { text: "English", value: 'en' },
    { text: "Japanese", value: 'jp' }
  ];
  selectedLanguageValue: string;

  public arrMap: Array<{ text: string, value: string }> = [
    { text: "Google", value: 'Google' },
    { text: "Baidu", value: 'Baidu' }
  ];
  selectedMapValue: string;

  LoginForm: FormGroup;
  submitted = false;
  UserData: any;
  orgSettingEntity: OrgSettingEntity = new OrgSettingEntity();
  LanguageConfig: any;
  ErrMessagesData: any;
  // evaluate:EvaluatePortalUrl=new EvaluatePortalUrl();
  constructor(private activatedRoute: ActivatedRoute, private DataService: CoreDataService, private titleService: Title, private formBuilder: FormBuilder, private sharedDataService: SharedDataService, private authService: AuthenticationService, private router: Router,
    private notify: SnotifyService, private cookieService: CookieService,private authService1 : AuthService

  ) {
    this.titleService.setTitle("Login - Evaluate Next");
    if(environment.environment.MicrosoftAuthentication && !this.sharedDataService.isLoggedOut)
    this.microsoftUserLogin()
  }


  ngOnInit() {

    this.sharedDataService.languageConfig$.subscribe(res => {
      if (res != undefined) {
        this.LanguageConfig = res[0];
        this.ErrMessagesData = res[28];
      }
    })

    localStorage.clear();
    sessionStorage.clear()
    let LanguageInitial = this.sharedDataService.GetLanguageLiteral()
    // this.ChangeLanguageData()

    this.selectedLanguageValue = LanguageInitial;

    let MapInitial = this.sharedDataService.GetMap()
    // this.ChangeLanguageData()

    this.selectedMapValue = MapInitial;
    var token = window.localStorage.getItem('token');
     let tokenData = this.activatedRoute.snapshot.paramMap.get('p')
    //  console.log(this.activatedRoute.snapshot.fragment.includes('/login/'))
     if(this.activatedRoute.snapshot.fragment != null){
      console.log(this.activatedRoute.snapshot.fragment.includes('/login/'))

      if(this.activatedRoute.snapshot.fragment.includes('/ResponsePasswordReset/')){

        let d = this.activatedRoute.snapshot.fragment.replace('/ResponsePasswordReset/', '')
        // let d1 = d.replace('p=', '')
        this.router.navigate(['/ResponsePasswordReset',d]);        
      }
      else if(this.activatedRoute.snapshot.fragment.includes('/RequestPasswordReset')){
        this.router.navigate(['/RequestPasswordReset']);        
      }
     
      else if(this.activatedRoute.snapshot.fragment.includes('/WhatsNew')){
        this.router.navigate(['/WhatsNew']);        
      }
      else {
        tokenData = this.activatedRoute.snapshot.fragment.replace('/login/', '')
      }      
     }
    // let tokenData = this.activatedRoute.snapshot.fragment.replace('/login/', '')

    // console.log()

    // let encodedURL = window.location.href;
    // encodedURL = encodedURL.split('/')[1];
    

    if (tokenData != undefined && tokenData != "" && tokenData.length > 25 && !tokenData.includes('code=') ) {
      if (token != undefined && token != null) {
        window.localStorage.removeItem('token');

      }
      
      this.DataService.ValidateExternalLogin(tokenData).subscribe(res => {
        if (res != undefined && res != null)
          if (res.message == "TokenExpired") {
            this.notify.error(this.ErrMessagesData.URLExpried);
          } else if (res.message == "InvalidEmailID") {
            this.notify.error(this.ErrMessagesData.UserNotExist);
          }
          else if (res.message == "Success") {
            this.authService.getExternalLogin(res.data.emailID, "", res.data.origin).subscribe((data: any) => {
              this.routeAfterLogin(data.access_token);
            })
          }
      })
    }


    if (token != null && token != undefined && token != '') {


      this.UserData = this.sharedDataService.parseJwt(token);
      let role: string
      role = this.UserData.RoleType;
      // LIVE AND UAt
      if (role.toLowerCase() == 'customer') { //Customer
        this.router.navigate(['/dashboard']);
      } else if (role.toLowerCase() == 'auditor') {//Auditor
        this.router.navigate(['/dashboard']);
      }
      else if (role.toLowerCase() == 'reviewer') {//Reviewer
        this.router.navigate(['/MyTeamDashboard']);

      }
      else if (role.toLowerCase() == 'sys admin') {//Sys Admin
        this.router.navigate(['/AdminDashboard']);
      }
      else if (role.toLowerCase() == 'admin') {//Sys Admin
        this.router.navigate(['/AdminDashboard']);
      }

    }
    this.LoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });

  }
  ChangeLanguageData() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      let langLiteral = this.sharedDataService.GetLanguageLiteral();

      Promise.all([
        this.DataService.GetLoginConfig(this.sharedDataService.GetLanguageLiteral(), "login.json"),
        this.DataService.GetFooterConfig(this.sharedDataService.GetLanguageLiteral(), "footer.json"),
        this.DataService.GetGridDataConfig(this.sharedDataService.GetLanguageLiteral(), "grid.json"),
        this.DataService.GetHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "header.json"),
       // this.DataService.GetBusinessHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "BusinessHeader.json"),
        this.DataService.GetLocationConfig(this.sharedDataService.GetLanguageLiteral(), "location.json"),
        this.DataService.GetAssetListingConfig(this.sharedDataService.GetLanguageLiteral(), "asset_listing.json"),
        this.DataService.GetCustomerManagementConfig(this.sharedDataService.GetLanguageLiteral(), "customerManagementConfig.json"),
        this.DataService.getCustomerConfig(this.sharedDataService.GetLanguageLiteral(), "addCustomer.json"),
        this.DataService.addLocationConfig(this.sharedDataService.GetLanguageLiteral(), "addLocation.json"),
        this.DataService.addAssetConfig(this.sharedDataService.GetLanguageLiteral(), "addAsset.json"),
        this.DataService.getCompanyManagementConfig(this.sharedDataService.GetLanguageLiteral(), "companyManagement.json"),
        this.DataService.getCompanyConfig(this.sharedDataService.GetLanguageLiteral(), "createCompany.json"),
        this.DataService.getUserManagementConfig(this.sharedDataService.GetLanguageLiteral(), "userManagement.json"),
        this.DataService.getUserUpdateConfig(this.sharedDataService.GetLanguageLiteral(), "addUser.json"),
        this.DataService.getAssetGroupConfig(this.sharedDataService.GetLanguageLiteral(), "assetGroup.json"),
        this.DataService.createAssetGroupConfig(this.sharedDataService.GetLanguageLiteral(), "createAssetGroup.json"),
        this.DataService.getAssetTypeManagementConfig(this.sharedDataService.GetLanguageLiteral(), "assetTypeManagement.json"),
        this.DataService.creatAssetTypeConfig(this.sharedDataService.GetLanguageLiteral(), "createAssetType.json"),
        this.DataService.getEmailConfig(this.sharedDataService.GetLanguageLiteral(), "emailConfiguration.json"),
        this.DataService.updateEmailConfig(this.sharedDataService.GetLanguageLiteral(), "emailConfig.json"),
        this.DataService.getAssetRefactoring(this.sharedDataService.GetLanguageLiteral(), "assetrefactoring.json"),
        this.DataService.getOrganisationSettingConfig(this.sharedDataService.GetLanguageLiteral(), "organisationSetting.json"),
        this.DataService.getUnitOfMesurListing(this.sharedDataService.GetLanguageLiteral(), "unitOfMeasListing.json"),
        this.DataService.updateUnitOfMeasurement(this.sharedDataService.GetLanguageLiteral(), "addUnitOfMeasurment.json"),
        this.DataService.getcodeMangementConfig(this.sharedDataService.GetLanguageLiteral(), "codeManagement.json"),
        this.DataService.getFaultFixConfig(this.sharedDataService.GetLanguageLiteral(), "faultfixmanagement.json"),
        this.DataService.getDocumentUploadConfig(this.sharedDataService.GetLanguageLiteral(), "documentUpload.json"),
        this.DataService.getReportConfig(this.sharedDataService.GetLanguageLiteral(), "report.json"),
        this.DataService.getEroorMessageConfig(this.sharedDataService.GetLanguageLiteral(), "ErrorMessagesConfig.json"),
        this.DataService.getFilterConfig(this.sharedDataService.GetLanguageLiteral(), "filter.json"),
        this.DataService.getAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "AssetFields.json"),
        this.DataService.getResetPassword(this.sharedDataService.GetLanguageLiteral(), "resetpassword.json"),
        this.DataService.getDashboardConfig(this.sharedDataService.GetLanguageLiteral(), "dashboard.json"),
        this.DataService.getAdminDashboard(this.sharedDataService.GetLanguageLiteral(), "adminDashboard.json"),
        this.DataService.getTeamDashboard(this.sharedDataService.GetLanguageLiteral(), "teamDashboard.json"),
        this.DataService.getNotificationMessage(this.sharedDataService.GetLanguageLiteral(), "popupMsg.json"),
        this.DataService.getFieldConfig(this.sharedDataService.GetLanguageLiteral(), "assetFieldConfig.json"),
        this.DataService.getChangepasswordConfig(this.sharedDataService.GetLanguageLiteral(), "changepassword.json"),
        this.DataService.getSubAssetConfig(this.sharedDataService.GetLanguageLiteral(), "subAsset.json"),
        this.DataService.getMyAccountConfig(this.sharedDataService.GetLanguageLiteral(), "myAccount.json"),
        this.DataService.getDataImportConfig(this.sharedDataService.GetLanguageLiteral(), "dataImport.json"),
        this.DataService.getAssetFieldGroup(this.sharedDataService.GetLanguageLiteral(), "AssetFieldGroup.json"),
        this.DataService.getAdminConfig(this.sharedDataService.GetLanguageLiteral(), "adminConfig.json"),
        this.DataService.getContentMangementConfig(this.sharedDataService.GetLanguageLiteral(), "contentManagement.json"),
        this.DataService.getUserActivityReportConfig(this.sharedDataService.GetLanguageLiteral(), "activityReport.json"),
        this.DataService.getCustomerFieldConfig(this.sharedDataService.GetLanguageLiteral(), "customerFieldStatusConfig.json"),
        // this.DataService.getAgeandCostConfig(this.sharedDataService.GetLanguageLiteral(), "age_Cost.json"),
        // this.DataService.getOtherFilterConfig(this.sharedDataService.GetLanguageLiteral(), "othersFilter.json"),
        this.DataService.getddlAgeGroup(this.sharedDataService.GetLanguageLiteral(), "ddlAgeGroup.json"),
        this.DataService.getddlRemainingLifeGroup(this.sharedDataService.GetLanguageLiteral(), "ddlRemainingLife.json"),
        this.DataService.getddlReplacementCost(this.sharedDataService.GetLanguageLiteral(), "ddlReplacementCost.json"),
        this.DataService.getddlReplacementyear(this.sharedDataService.GetLanguageLiteral(), "ddlReplacementYear.json"),
        this.DataService.getServiceCall(this.sharedDataService.GetLanguageLiteral(), "otherServiceCall.json"),
        this.DataService.getSortBy(this.sharedDataService.GetLanguageLiteral(), "otherSortBy.json"),
        this.DataService.getOhsComment(this.sharedDataService.GetLanguageLiteral(), "otherOhsComment.json"),
        this.DataService.getSubAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "SubAssetFields.json"),
        this.DataService.getSubAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "GlobalSearch.json"),
        this.DataService.getbusinessunitManagement(this.sharedDataService.GetLanguageLiteral(), "businessunitManagementConfig.json"),
        this.DataService.getaddBusiness(this.sharedDataService.GetLanguageLiteral(), "addBusiness.json"),
        this.DataService.getDepartment(this.sharedDataService.GetLanguageLiteral(), "Department.json"),
        this.DataService.getaddDepartment(this.sharedDataService.GetLanguageLiteral(), "addDepartment.json"),
        this.DataService.getbusinessAssetListing(this.sharedDataService.GetLanguageLiteral(), "BusinessAsset_listing.json"),
        this.DataService.getReserveAsset(this.sharedDataService.GetLanguageLiteral(), "reserve_asset.json"),
        this.DataService.GetBusinessHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "BusinessHeader.json"),
        this.DataService.GetBusinessErrorConfig(this.sharedDataService.GetLanguageLiteral(), "BusinessErrorConfig.json"),
        this.DataService.getddlTotalSpend(this.sharedDataService.GetLanguageLiteral(), "ddlTotalSpend.json"),
        this.DataService.GetAssetJobListingConfig(this.sharedDataService.GetLanguageLiteral(), "asset_job_listing.json"),
        this.DataService.GetRepairAndMaintainanceConfig(this.sharedDataService.GetLanguageLiteral(), "repair_and_maintainance_listing.json"),
        this.DataService.getBusinessDashboard(this.sharedDataService.GetLanguageLiteral(), "business_dashboard.json"),
        this.DataService.getddlIMStatus(this.sharedDataService.GetLanguageLiteral(), "ddlIMStatus.json"),
        this.DataService.getExpenseDashboard(this.sharedDataService.GetLanguageLiteral(), "expenseDashboard.json")

        

      ]).then(res => {
        this.sharedDataService.languageConfig = res;
        this.sharedDataService.languageConfig$.next(this.sharedDataService.languageConfig);
      });
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
      // this.titleService.setTitle(this.LanguageConfig.PageTitle);
      // this.ErrMessagesData = ErrMessagesData[langLiteral];
    }
  }

  // convenience getter for easy access to form fields
  get LoginControlValidator() { return this.LoginForm.controls; }


  routeAfterLogin(access_token) {
    if (access_token != null && access_token != undefined) {
      var data={access_token :access_token}
      this.authService._setSession(data);
      this.UserData = this.sharedDataService.parseJwt(access_token);
      this.DataService.OrganSettings().subscribe(res => {
        if (res != undefined) {

          var passwordExpiryInterval = this.sharedDataService.search(
            "PasswordResetInterval",
            res
          );
          console.log(passwordExpiryInterval);
          var hash = CryptoJS.AES.encrypt(
            JSON.stringify(this.sharedDataService.convertListToModel(res)),
            this.UserData.EmailID
          ).toString();
          localStorage.setItem("OrgSetting", hash);

          this.orgSettingEntity =
            this.sharedDataService.convertListToModel(res);

          window.OrgSetting = this.orgSettingEntity;



        }
      })


      let role: string
      role = this.UserData.RoleType;
      // LIVE AND UAt
      if (role.toLowerCase() == 'customer') { //Customer
        this.router.navigate(['/dashboard']);
      } else if (role.toLowerCase() == 'auditor') {//Auditor
        this.router.navigate(['/dashboard']);
      }
      else if (role.toLowerCase() == 'reviewer') {//Reviewer
        this.router.navigate(['/AdminDashboard']);

      }
      else if (role.toLowerCase() == 'sys admin') {//Sys Admin
        this.router.navigate(['/AdminDashboard']);
      }
      else if (role.toLowerCase() == 'admin') {//Sys Admin
        this.router.navigate(['/AdminDashboard']);
      }
      // if (this.UserData.RoleTypeCd == "83") { //Customer
      //   this.router.navigate(['/dashboard']);
      // } else if (this.UserData.RoleTypeCd == "84") {//Auditor
      //   this.router.navigate(['/dashboard']);
      // }
      // else if (this.UserData.RoleTypeCd == "85") {//Reviewer
      //   this.router.navigate(['/MyTeamDashboard']);

      // }
      // else if (this.UserData.RoleTypeCd == "86") {//Sys Admin
      //   this.router.navigate(['/AdminDashboard']);
      // }
      // else if (this.UserData.RoleTypeCd == "1227") {//Sys Admin
      //   this.router.navigate(['/AdminDashboard']);
      // }
      this.notify.success(this.ErrMessagesData.SuccessfullLogin);
    }

  }




  login(form) {
    
    this.submitted = true;
    //
    this.sharedDataService.password = form.password;
    if (this.LoginForm.valid) {
      this.authService.getLogin(form).subscribe(
        (data: any) => {
          if (data.access_token != null && data.access_token != undefined) {
            this.authService._setSession(data);
            this.routeAfterLogin(data.access_token);
          }


        },
        catchError => {
          if (catchError.error.error=='Invalid Password!') {
          
            this.notify.error(this.ErrMessagesData.InvalidPassword)

          }
          else if(catchError.error.error=='Invalid Username!')
          {
            this.notify.error(this.ErrMessagesData.InvalidUsername)
          }
          else if(catchError.error.error=='Account Disabled!')
          {
            this.notify.error(this.ErrMessagesData.AccountInactive)
          }
          else{
            this.notify.error(this.ErrMessagesData.InternalServerError)
          }
        }
      );
    }
    else if (this.LoginForm.invalid) {
      return;
    }
  }
  ChangeLanguage() {
    if (this.cookieService.check('EvalLangPref') != null && this.cookieService.check('EvalLangPref')) {
      this.cookieService.set('EvalLangPref', this.selectedLanguageValue)
    }
    else {
      this.cookieService.set('EvalLangPref', environment.environment.language)
    }
    this.ChangeLanguageData();
  }

  ChangeMap(){
    if (this.cookieService.check('EvalMapPref') != null && this.cookieService.check('EvalMapPref')) {
      this.cookieService.set('EvalMapPref', this.selectedMapValue)
    }
    else {
      this.cookieService.set('EvalMapPref', 'Google')
    }

  }


  openRequestResetPassword() {
    this.router.navigateByUrl('/RequestPasswordReset');
  }
  mstest() {
    this.router.navigateByUrl('MobileResetPassword');
  }
  async microsoftUserLogin(){

    await  this.authService1.handleRedirect();
    var key=JSON.parse(window.localStorage.getItem('msal.token.keys.'+environment.environment.MicrosoftCredentials.clientId))
   
    var  val:any =window.localStorage.getItem(key.idToken[0])
    const authData = JSON.parse(val);

    var validationResponse= await this.DataService.ValidateMicrosoftUser(authData.secret)
    if(validationResponse.message=='Valid Request')
    {
    this.routeAfterLogin(validationResponse.token)
    }
    else if(validationResponse.message=='Invalid Username!')
    {
      this.notify.error('User with this email is not registered in Evaluate')
    }
    else if(validationResponse.message=='Account Disabled!')
    {
      this.notify.error(this.ErrMessagesData.AccountInactive)
    }
    else{
      this.notify.error(this.ErrMessagesData.InternalServerError)
    }
  }

  LoginWithMicrosoft(){
    this.authService1.login();
  }

  

}
