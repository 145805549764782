import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CoreDataService } from '../../Service/core-data.service';
import { ToastrService } from 'ngx-toastr';
import { process, State } from '@progress/kendo-data-query';
import { ActivatedRoute } from "@angular/router";
import { SharedDataService } from "../../Service/CommonUtility.service";

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  userAction: string = "Company Management";

  id: number;
  UserData: any;
  editItemData;
  sampledata = [];
  public view: boolean;
  list: any = [];
  temp: any = {};
  count = 0;
  userId: number;
  value: string;
  LanguageConfig: any;
  GridConfig: any
  gridData: GridDataResult;
  key;
  filteredQuotelist: any = [];
  public state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public opened: boolean = true;

  public close(status) {
    this.opened = false;
  }
  public open() {
    this.opened = true;
  }
  constructor(private sharedService: SharedDataService, private coredataservice: CoreDataService, private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute, private router: Router, private titleService: Title) {

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);


  }
  isRowSelected(e: any): void {
    this.temp = e;

  }
  MenuOnSelect(event) {
    // var baseUrl = window.location.origin + '/#/edit-company/' + event.dataItem.companyID;
    var baseUrl = window.location.origin + '/edit-company/' + event.dataItem.companyID;

    if (event.item == "Open") {

      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }
  ngOnInit() {

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[10]
        }
      })
    }
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      let langLiteral = this.sharedService.GetLanguageLiteral();
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.GridConfig = res[2];
        }
      })
      // this.GridConfig = GridData[langLiteral];
    }
    this.loadData();
    var token = window.localStorage.getItem('token');

    this.UserData = this.sharedService.parseJwt(token);
  }
  editHandler(dataItem) {
    this.editItemData = dataItem;

  }
  editDetails(event) {
    this.id = event.companyID;
    this.router.navigate(["/edit-company", event.companyID]);
  }

  removeDetail(event) {
    var index: number;
    this.sampledata.forEach((element) => {
      if (event.companyID === element.companyID) {
        index = this.sampledata.indexOf(element)
        return this.sampledata.indexOf(element);
      }
    });

    this.coredataservice.deleteCompany(event.companyID).subscribe(
      data => {
        if (data) {

          this.toastrService.success("Record has been deleted");
          this.sampledata.splice(index, 1);
          this.gridData = process(this.sampledata, this.state);

        }
      },
      catchError => {
        if (catchError) {
          this.sharedService.ErrorHandler(catchError)
        }
      });

  }
  loadData() {

    this.coredataservice.getCompanyList().first().subscribe(response => {
      if (response != null && response != undefined) {
        this.list = response;
        this.list.map((element) => {
          this.sampledata.push({
            'companyID': element.companyID,
            'address': element.address,
            'active': element.active,
            'createdBy': element.createdBy,
            'creationDate': this.GetFormattedDate(element.creationDate),
            'email': element.email,
            'evaluateChampion': element.evaluateChampion,
            'name': element.name,
            'updatedBy': element.updatedBy,
            'updationDate': this.GetFormattedDate(element.updationDate),
          }
          );
        });
        this.gridData = process(this.sampledata, this.state);

        this.count++;
      }

    },
      catchError => {
        if (catchError) {
          this.sharedService.ErrorHandler(catchError);
        }
      });

  }
  public dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.sampledata, this.state);

  }
  GetFormattedDate(todayTime) {
    var dt = new Date(todayTime);
    var month = dt.getMonth();
    var day = dt.getDate();
    var year = dt.getFullYear();
    return moment(new Date(year, month, day)).toDate();
  }
}
