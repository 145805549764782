import { Component, OnInit, ElementRef, NgZone, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";

import { Address, LocationRMModel } from "../add-location/add-location.model";
import { LocationEntity, LocationImageData, LocationAdminListingEntity } from '../../AccountManagement/add-location/add-location.model'
import { AssetTypeKeyValue } from '../../AccountManagement/add-location/add-location.model'
import { CoreDataService } from '../../Service/core-data.service';
import { ActivatedRoute } from '@angular/router';
import { numberSymbols } from '@telerik/kendo-intl';
import { Router } from "@angular/router";
import { MapsAPILoader } from '@agm/core';
import { SharedDataService } from '../../Service/CommonUtility.service';

import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { ImageCompressor, getImageSize } from "compressor-img";
import { Title } from '@angular/platform-browser';
import { AdminEntity } from '../../Common/shared-model';
import { CompositeFilterDescriptor, filterBy, State, process } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

declare var google: any;
declare var googlemap: any;
declare const BMap: any;




const formGroupRM = (dataItem) =>
  new FormGroup({

    locationRMID: new FormControl(dataItem.locationRMID),
    locationID: new FormControl(dataItem.locationID),
    plannedSpend: new FormControl(dataItem.plannedSpend,[
      Validators.min(0),
      Validators.required,
      Validators.pattern(new RegExp("[0-9]*"))
    ]),
    priorityCD: new FormControl(dataItem.priorityCD),
    assetClass: new FormControl(dataItem.assetClass),
    description: new FormControl(dataItem.description),
    qty: new FormControl(dataItem.qty,[
      Validators.min(0),
      Validators.required,
      Validators.pattern(new RegExp("[0-9]*"))
    ]),
    plannedDateWork: new FormControl(new Date(dataItem.plannedDateWork)),
    statusCD: new FormControl(dataItem.statusCD),
    comments: new FormControl(dataItem.comments),
    createdBy: new FormControl(dataItem.createdBy),
    creationDate: new FormControl(dataItem.creationDate),
    updatedBy: new FormControl(dataItem.updatedBy),
    updationDate: new FormControl(dataItem.updationDate),

  });

@Component({
  selector: 'app-add-new-customer',
  templateUrl: 'add-location.component.html',
  styleUrls: ['add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  CodeData: any;

  formGroup: FormGroup;
  LanguageConfig: any;
  BusinessLanguageConfig: any;
  GridConfig: any
  @ViewChild('search',{static:false}) public  searchElementRef: ElementRef;
  @ViewChild('LocationForm',{static:false}) locationForm: NgForm;
  public state: State = {
    skip: 0,
    take: 10,
  sort: [{ field: "locationRMID" }],
  }
 
  public editedRowIndex: number;
  public isRowOpen:boolean=false;
  public formGroupRM: FormGroup = formGroupRM(new LocationRMModel());

  public LocationImages: any = [];
  public filter: CompositeFilterDescriptor = null;
  public LocationImagesViewer: string[] = [];
LocationRMModel:LocationRMModel=new LocationRMModel();
  public LocationRmList: Array<LocationRMModel> = [];

  public gridfilter: any[] = filterBy(this.LocationRmList, this.filter);
  public gridData: GridDataResult = process(this.LocationRmList, this.state);
  public selectableSettings = { enabled: true, checkboxOnly: false, mode: "multiple" };
  public mySelection: number[] = [];
  filteredList: Array<LocationRMModel> = [];
  objLocation: LocationEntity = new LocationEntity();
  LocationAdminListingEntity: LocationAdminListingEntity = new LocationAdminListingEntity();

  public ddlDefaultItem: { Name: string, ID: number } = { Name: "Select", ID: null };
  public ddlAccountManagerDefaultItem: { accountManagerName: string, accountManagerID: number } = { accountManagerName: "Select", accountManagerID: null };
  public ddlCountryDefaultItem: { codeValue: string, codeID: number } = { codeValue: "Select", codeID: null };
  public ddlCustomerDefaultItem: { customerName: string, customerID: number } = { customerName: "Select", customerID: null };
  public ddlStateDefaultItem: { codeValue: string, codeID: number } = { codeValue: "Select", codeID: null };
  latitude = -25.274399;
  longitude = 133.775131;
  zoom = 4;
  LocationImageID;
  IsCancelClickedLocationImage: boolean = false;
  AssetTypeMaster: AssetTypeKeyValue[] = [];
  public assetClass: Array<{ className: string, assetClassID: number }> = [];
  public CountryDropdown: Array<{ codeValue: string, codeID: number }> = [];
  public StatusDropdown: Array<{ codeValue: string, codeID: number }> = [];
  public StateDropdown: Array<{ codeValue: string, codeID: number }> = [];
  public StateDropdownBind: Array<{ codeValue: string, codeID: number }> = [];
  public PriorityDropdown: Array<{ codeValue: string, codeID: number }> = [];
  public CountryDropdownBind: Array<{ codeValue: string, codeID: number }> = [];
  public AuditStatusDropdown: Array<{ codeValue: string, codeID: number }> = [];
  public AuditStatusDropdownBind: Array<{ codeValue: string, codeID: number }> = [];
  public CustomerDropdown: Array<{ customerName: string, customerID: number,code:string }> = [];
  public AccountMangerDropdown: Array<{ accountManagerID: number, accountManagerName: string }> = [];
  public AccountMangerDropdownBind: Array<{ accountManagerID: number, accountManagerName: string }> = [];
  public IsAssetTypeBaselineCalled: boolean = false;

  public ddlUOMTest: Array<{ codeValue: string, codeID: number, weightage: number }> = [];
  public ddlAuditStatusDropdown: Array<{ codeValue: string, codeID: number }> = [];
  ClickedImage = false;
  // LocationForm: FormGroup;
  submitted = false;
  IsUserCustomer: string;
  IsAuditorReviewer: boolean;
  IsAdmin: string;
  ErrMessagesData: any;
  userAction: string = "Add Location";
  AdminEntity: AdminEntity = new AdminEntity();
  IsAddDepartment: boolean
  IsAddLocation: boolean
  IsUpdateDepartment: boolean
  IsUpdateLocation: boolean
  IsRowInEdit: boolean = false;
  public PlannedDateWork: Date;
  PreviewCkDescription: any;
  PreviewDescriptionDialog: boolean;
  PreviewCkComments: any;
  PreviewCommentsDialog: boolean;
  Dateformat: any;
  DateTimeformat: string;
  GridCurrencyChange: any;
  currencySymbol: any;
  UserData: any;
  isReviewer: boolean=false;
  isCustomer: boolean=false;
  //ckDescription: any;
  //ckComments: any;

  public userSearchString:string;
  public userList: Array<{ userID: number, userName: string }>=[];
  public filteredUserList: Array<{ userID: number, userName: string }>=[];
  public UserID =[]
  options: any
  markerPosition: any
  private _map: any;
  MapOptions = [{ key: "Google", displayName: "Google" }, { key: "Baidu", displayName: "Baidu" }];
 
  SelectedMap:string ='Google'
  BaiduAutocomplete: any;
  GoogleAutocomplete:any;

  constructor(
    private notify: SnotifyService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private DataService: CoreDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService

  ) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
    this.userAction = pageTite
    this.getAssetDetails("");
  
  
    

  


    this.getCodeData();
    this.classList();
  }

  public imageIndexOne = 0;
  public onTabchange(e) {
    if (e.index == 1 && this.activatedRoute.snapshot.paramMap.get('id') != null) {

      let Id: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));

      this.getAssetTypeBaslelinebyID(Id);

    }
    else if (e.index == 2 && this.activatedRoute.snapshot.paramMap.get('id') != null) {

      this.AdminEntity.Table = 'Location'
      this.AdminEntity.AssociatedID = this.objLocation.locationID
    }

  }
  LocationId: number
  ngOnInit() {
   
   
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
    }

    this.sharedDataService.languageConfig$.subscribe(res => {
      if (res != undefined) {
        this.LanguageConfig = res[8];
        this.BusinessLanguageConfig = res[58];
        this.ErrMessagesData = res[28];
      }
    })

    this.SelectedMap=this.sharedDataService.GetMap();
    
    this.formGroup = new FormGroup({
      locationName: new FormControl('', [
        Validators.required,

        Validators.maxLength(100)
      ]),
      locationCode: new FormControl('', [

        Validators.maxLength(15)
      ]),
      debtorCode: new FormControl('', [
        Validators.maxLength(100)
      ]),

      customerID: new FormControl('', [
        Validators.required,

      ]),
      addressLine1: new FormControl('', [
        Validators.required,

        Validators.maxLength(150)
      ]),
      addressLine2: new FormControl('', [

        Validators.maxLength(50)
      ]),
      suburb: new FormControl('', [
        Validators.required,

        Validators.maxLength(100)
      ]),
      stateCD: new FormControl('', [
        Validators.required

     
      ]),
      countryCd: new FormControl('', [
        Validators.required

      ]),
      postcode: new FormControl('', [
        Validators.required,

        Validators.maxLength(11)
      ]),
      accountManagerID: new FormControl('', [
        Validators.required
      ]),
      locationAuditStatusCd: new FormControl('', [
        Validators.required

      ]),
      geoLocation: new FormControl('', [

        Validators.maxLength(70)
      ]),
      comments: new FormControl('', [

        Validators.maxLength(500)
      ]),
      locationID: new FormControl('', [

      ]),
      active: new FormControl('', [

      ]),
      userID: new FormControl('', [

      ]),
      emailRecipients: new FormControl('', [
        Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/)
      ])
    });


    this.IsAuditorReviewer = this.sharedDataService.IsAuditorOrReviewer();
    this.IsUserCustomer = this.sharedDataService.IsCustomer();
   
    this.IsAdmin = this.sharedDataService.IsAdmin();
    this.GetAccountManagerDropdown();
    this.getApiData();


    //Call to bind Asset Details.
    this.BindAssetTypeList();
    if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
      let Id: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
      this.AdminEntity.Table = 'Location'
      this.AdminEntity.AssociatedID = Id
      this.getLocationByID(Id);
     
    }

    //DEPARTMENT UNIT CODE

    this.LocationId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    if (isNaN(this.LocationId)) {
      this.LocationId = 0
      if (this.activatedRoute.snapshot.data['IsAddDepartment']) {
        this.IsAddDepartment = this.activatedRoute.snapshot.data['IsAddDepartment']
        
      }
      else {
        this.IsAddLocation = this.activatedRoute.snapshot.data['IsAddLocation']
      }
    }
    else {
      if (this.activatedRoute.snapshot.data['IsUpdateDepartment']) {
        this.IsUpdateDepartment = this.activatedRoute.snapshot.data['IsUpdateDepartment']
      }
      else {
        this.IsUpdateLocation = this.activatedRoute.snapshot.data['IsUpdateLocation']
      }
    }

    var token = window.localStorage.getItem('token');
    this.UserData = this.sharedDataService.parseJwt(token);
    if(this.UserData.RoleType=="REVIEWER" &&(this.IsAddLocation || this.IsUpdateLocation)){
      this.isReviewer=true
    }
    else if(this.UserData.RoleType=="CUSTOMER" &&(this.IsAddLocation || this.IsUpdateLocation)){
      this.isCustomer=true
    }

    //DEPARTMENT UNIT CODE

    this.LocationRM();
 
  

    this.onReady(this._map)
  }

  ngAfterViewChecked() {
    if (this.CountryDropdown.length > 0) {
      this.CountryDropdownBind = this.CountryDropdown
    }

    if (this.StateDropdown.length > 0) {
      this.StateDropdownBind = this.StateDropdown
    }
    if (this.AuditStatusDropdown.length > 0) {
      this.AuditStatusDropdownBind = this.AuditStatusDropdown
    }
    if (this.AccountMangerDropdown.length > 0) {
      this.AccountMangerDropdownBind = this.AccountMangerDropdown
    }
  
  }

  onSubmit() {

    if (this.formGroup.valid) {
      this.objLocation = this.formGroup.value

    }
  }

  onReset() {
    this.formGroup.reset();
  }

  changeIndex(i, item) {
    this.imageIndexOne = i
    if (item != undefined) {
      this.LocationImageID = item.uid;
      this.ClickedImage = true;
    }

    document.getElementsByTagName('ngx-image-viewer')[0].getElementsByClassName('img-container')[0].getElementsByTagName('img')[0]['style']['height'] = '-webkit-fill-available';

  }
  closePreviewImage(value) {
    this.ClickedImage = value == "false" ? false : true;
  }
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png']
  };

  cancelledClickLocationImage(item) {
    if (item != undefined) {
      this.LocationImageID = item.uid;
      this.IsCancelClickedLocationImage = true;
    }
  }
  originalImage: any = null;
  sizeOriganleImage: any = null;
  compressedImage: any = null;
  sizeCompressedImage: any = null;
  public selectEventHandlerSub(e: SelectEvent): void {
    const that = this;


    e.files.forEach((file) => {


      if (file.size < 8388608) {
        let reader = new FileReader();

        if (file.size > 300000) {
          reader.onload = () => {


            let imageCompressor = new ImageCompressor({
              onSuccess: response => {
                (this.originalImage = response.original),
                  (this.sizeOriganleImage = getImageSize(response.original)),
                  (this.compressedImage = response.compressed),

                  (this.sizeCompressedImage = getImageSize(response.compressed));

                //GENERATING Unique object ID for image & also for deletion

                if (that.LocationImagesViewer[0] !== this.compressedImage) {

                  let imageSub = {
                    src: this.compressedImage,
                    uid: file.uid,
                    extension: file.extension,
                    IsUpdated: true,
                    name: file.name
                  };

                  //GENERATING Unique object ID for image
                  that.LocationImages.unshift(imageSub);
                  that.LocationImagesViewer.unshift(this.compressedImage)
                }
                var k = document.getElementsByClassName('k-upload-files');
                if (k.length > 0) {
                  k[0].remove()
                }
              },
              scale: 30,
              quality: 100,
              holdCompress: false,
              originalImage: reader.result
            });
            imageCompressor.startCompress();

          }
        }
        else {
          reader.onload = (ev) => {

            let imageSub = {
              src: ev.target['result'],
              uid: file.uid,
              extension: file.extension,
              IsUpdated: true,
              name: file.name
            };
            that.LocationImages.unshift(imageSub);
            that.LocationImagesViewer.unshift(ev.target['result'].toString())
            var k = document.getElementsByClassName('k-upload-files');
            if (k.length > 0) {
              k[0].remove()
            }
          }
        }
        reader.readAsDataURL(file.rawFile);
      }
      else {
        this.notify.error(this.ErrMessagesData.exceedFileSize)
        var k = document.getElementsByClassName('k-upload-files');
        if (k.length > 0) {
          k[0].remove()
        }
      }
    });
  }
  closeLocationImage(val) {

    if (val != undefined && val == 'true') {
      const index = this.LocationImages.findIndex(item => item.uid === this.LocationImageID);
      if (index >= 0) {
        this.LocationImages.splice(index, 1);
        this.LocationImagesViewer.splice(index, 1);
        this.IsCancelClickedLocationImage = false;
        this.DataService.DeleteSubAssetImage(this.LocationImageID).subscribe((res) => {
          if (res != null && res != undefined) {
          }
        })
      }

    } else {
      this.IsCancelClickedLocationImage = false;
    }
  }

  RotateImageForward(srcBase64, uid, Orientation, Rotation, index) {
    var img = new Image();
    let srcOrientation = null;
    if (Orientation != undefined) {
      srcOrientation = Orientation;
    } else {
      srcOrientation = 1;
    }

    if (Rotation == 'F') {
      //Setting Forward orientation
      if (srcOrientation == 1)
        srcOrientation = 6;
      else if (srcOrientation == 8)
        srcOrientation = 6


    } else if (Rotation == 'B') {
      if (srcOrientation == 1)
        srcOrientation = 8;
      else if (srcOrientation == 6)
        srcOrientation = 8
    }


    // Orientation = srcOrientation
    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);
      this.test(canvas.toDataURL(), uid, srcOrientation, index)
      // export base64

      document.getElementById(uid)['src'] = canvas.toDataURL();

    };

    this.LocationImages.forEach(element => {
      if (element.uid === uid) {
        element.Orientation = srcOrientation;
      }
    });
    img.src = srcBase64;

  }
  test(data, uid, srcOrientation, index) {
    this.LocationImages.forEach(element => {
      if (element.uid === uid) {
        element.src = data
        element.Orientation = srcOrientation;
        element.IsUpdated = true;
      }
    });
    this.LocationImagesViewer[index] = data;
  }



  SaveLocation() {

    if(this.isRowOpen==true){
      this.notify.error(this.ErrMessagesData.openRowError);
      return
    }

    this.submitted = true;
    if (this.formGroup.valid) {

      if (this.objLocation != undefined && this.objLocation != null) {




       
        this.objLocation = this.formGroup.value
        this.objLocation.userID=this.UserID;
     
        console.log(this.objLocation);
        this.objLocation.isDepartment = this.activatedRoute.snapshot.data['IsDepartment']

        // console.log(this.activatedRoute.snapshot.data['IsDepartment'])

        this.DataService.AddUpdateLocation(this.objLocation).subscribe((res) => {
          if (res != null && res != undefined && typeof res == "number") {
            let LocationID = res;
            let AssetBaseLineData = [];

            let image: LocationImageData = new LocationImageData();

            if (this.LocationImages.length > 0) {
              this.LocationImages.forEach((element) => {

                if (element.IsUpdated == true) {
                  image.associatedID = LocationID;
                  image.description = "";
                  image.imageDataID = typeof element.uid == "string" ? 0 : element.uid;;
                  image.imageType = "LOC";
                  image.extension = element['extension'].toLowerCase();
                  image.imageData = this.convertDataURIToBinary(element['src']);

                  this.DataService.UploadImageAsset(image).subscribe((rp) => {

                    image = new LocationImageData();
                  });
                }
              });
            }
            this.LocationRmList.forEach(row=>{
              row.locationID=LocationID;
              this.PriorityDropdown.forEach(Prow => { if (row.priorityCD == Prow.codeValue) { row.priorityCD = Prow.codeID+"" } })
              this.assetClass.forEach(arow => { if (row.assetClass == arow.className) { row.assetClass = arow.assetClassID+"" } })
              this.StatusDropdown.forEach(srow => { if (row.statusCD == srow.codeValue) { row.statusCD = srow.codeID+"" } })
              this.saveLocationRM(row)
            })
           // this.notify.success(this.ErrMessagesData.rMSavedSuccessfully);

            this.AssetTypeMaster.forEach(element => {
              AssetBaseLineData.push({ assetTypeID: element.assetTypeID, baselineValue: element.value, subAssetBaselineValue: element.subAssetValue })
            });
            if (this.IsAssetTypeBaselineCalled) {
              this.SaveAssetBaseLine({ locationID: LocationID, assetTypeBaseline: AssetBaseLineData });
            }
            this.IsAssetTypeBaselineCalled = false;


            if (this.activatedRoute.snapshot.data['IsUpdateDepartment'] || this.activatedRoute.snapshot.data['IsAddDepartment']) {
              this.router.navigate(['department-management']);
              this.notify.success('Department saved successfully');
            }
            else {
              this.router.navigate(['location-management']);
              this.notify.success(this.ErrMessagesData.locationSavedSuccessfully);
            }

          } else {
            this.notify.error(this.ErrMessagesData.SomethingWentWrong);
          }
        });
      }
    }
    else {

      Object.keys(this.formGroup.controls).forEach(field => { // {1}
        const control = this.formGroup.get(field); // {2}

        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });
      this.notify.error(this.ErrMessagesData.mandatoryField);
    }

  }
  convertDataURIToBinary(dataURI) {

    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
  markerDragEnd($event: MouseEvent) {

    this.latitude = $event['coords']['lat']
    this.longitude = $event['coords']['lng']
    this.objLocation.geoLocation = this.latitude + ',' + this.longitude;
  }
  getLocationByID(id): any {
    this.DataService.getLocationByID(id).subscribe((res) => {
      if (res != null && res != undefined) {
        this.getCustomerbyName('');
        this.GetAccountManagerDropdown();
        
        this.objLocation = res;
        this.bindSelectedLinkUser();
        this.getUsersList();
        
        this.objLocation.locationAuditStatusCd = res.locationAuditStatusCd;
       

        this.DataService.GetLocationImageData('LOC', this.objLocation.locationID).subscribe((resImg) => {

          let arr = [];
          resImg.forEach(ImgData => {
            arr.push({
              src: 'data:image/png;base64,' + ImgData.imagePath,
              uid: ImgData.imageDataID,
              extension: ImgData.extension,
              IsUpdated: false
            })
            this.LocationImages = arr;
            this.LocationImagesViewer.push('data:image/png;base64,' + ImgData.imagePath)

          });
        })

        if (this.objLocation.geoLocation != undefined && this.objLocation.geoLocation != null && this.objLocation.geoLocation != "") {
          this.latitude = parseFloat(this.objLocation.geoLocation.split(',')[0]);
          this.longitude = parseFloat(this.objLocation.geoLocation.split(',')[1]);
          this.zoom = 4;
          this.onReady(this._map)
        }
      } 
    });
  }
  CustomerValueChange(event) {
    this.checkLocationExists(this.objLocation.locationID, event, this.objLocation.locationName);
    if(this.objLocation.debtorCode=='' ||  this.objLocation.debtorCode==null)
    {
    var customer=this.CustomerDropdown.find((obj) => obj.customerID ==this.objLocation.customerID)
    this.objLocation.debtorCode=customer.code
    }
    this.getUsersList();

  }
  OnLocationBlur(event) {
    this.checkLocationExists(this.objLocation.locationID, this.objLocation.customerID, event.target.value);
  }
  checkLocationExists(LocationID, CustomerID, LocationName) {
    if (CustomerID != undefined && CustomerID != 0 && LocationName != "") {

      this.DataService.ValidateLocationName(LocationID, CustomerID, LocationName).subscribe(res => {
        if (res != null && res != undefined && res.length > 0) {
          this.objLocation.locationName = null
          this.notify.error(this.ErrMessagesData.locationAlreadyExisit);
        }
        else {
          this.objLocation.locationName = this.objLocation.locationName;
        }
      })
    }

  }
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  getCodeData() {
    this.DataService.getCodeData().first().subscribe((res) => {
      if (res != undefined && res != null) {

        res.forEach(element => {
          if (element.codeName == "CountryCd") {
            this.CountryDropdown.push(element);
            
          } 
          else if (element.codeName == 'StateCd') {
            this.StateDropdown.push(element)

          }
          else if (element.codeName == 'LocationAuditStatusCd') {
            this.AuditStatusDropdown.push(element);

          }
          else if (element.codeName == 'StatusRMCd') {
            this.StatusDropdown.push(element)

          }
         
          else if (element.codeName == 'PriorityRMCd') {
            this.PriorityDropdown.push(element)

          }
        });
      }
    });
 
  }
 
  getApiData() {
    if(this.SelectedMap=='Google'){
    this.mapsAPILoader.load().then(() => {
      this.GoogleAutocomplete= new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      this.GoogleAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = this.GoogleAutocomplete.getPlace();
          place.address_components.forEach((element) => {
            element.types.forEach((eleme) => {
              if (eleme === "route") {

              }
              if (eleme === "locality") {

              }
              if (eleme === "administrative_area_level_1") {
              

              }
              if (eleme === "country") {


              }
              if (eleme === "postal_code") {
                this.objLocation.postcode = element.long_name;


              }
              if (eleme === "sublocality") {
                this.objLocation.suburb = element.long_name;
              }
            });
          });
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.objLocation.geoLocation = this.latitude + ',' + this.longitude;
          this.objLocation.addressLine1 = this.searchElementRef.nativeElement.value;
        });
      });
    });
  }
  }
  GetAccountManagerDropdown() {
    this.DataService.getUsersByRole([]).subscribe((res) => {
      if (res != undefined && res != null) {
        let ArrayData = [];
        res.forEach(ele => {
          this.AccountMangerDropdown.push({ accountManagerID: parseInt(ele.userID), accountManagerName: ele.name })
        })

      }

    });
  }
  BindAssetTypeList() {
    this.DataService.getAssetTypeByNameDropDownUrl('').subscribe((res) => {
      if (res != undefined && res != null) {
        res.forEach(val => {
          let objAssetTypeMaster = {} as AssetTypeKeyValue;
          objAssetTypeMaster.assetTypeID = val.assetTypeID;
          objAssetTypeMaster.value = 0;
          objAssetTypeMaster.subAssetValue = 0;
          objAssetTypeMaster.assetTypeName = val.assetTypeName;
          this.AssetTypeMaster.push(objAssetTypeMaster);
     
        });
      }
    })
  }

  filterChangeCustomer(filterData: any): void {

    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
    }
  }

  getCustomerbyName(searchtext) {
    this.DataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {
        this.CustomerDropdown = res;
      }
    })
  }
  SaveAssetBaseLine(Data) {
    this.DataService.addUpdateLocationAssetTypeBaseline(Data).subscribe((res) => {
      if (res != undefined && res != null) {
        if (res > 0) {
          this.notify.success(this.ErrMessagesData.assetBaseline);
        }
      }
    })
  }

  // This code is used to Handle the Paste Event.

  onPaste(event: ClipboardEvent) {
    console.log(event);
    event.preventDefault();
  }

  getAssetTypeBaslelinebyID(id) {
    this.DataService.getLocationAssetTypeBaseline(id).subscribe((res) => {
      if (res != undefined && res != null) {
        this.AssetTypeMaster.forEach(element => {
          res.forEach(elv => {
            if (element.assetTypeID == elv.assetTypeID) {
              element.value = elv.baselineValue;
              element.subAssetValue = elv.subAssetBaselineValue;
            }
          });
        });
      }
      this.IsAssetTypeBaselineCalled = true;
    })
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.LocationRmList, this.state);
  }
  onSelect($event) { }
  addHandler({ sender }) {
    this.isRowOpen=true;
    sender.addRow(this.formGroupRM);
  }
  editHandler({ sender, rowIndex, dataItem }) {
    this.isRowOpen=true;
    this.PriorityDropdown.forEach(Prow => { if (dataItem.priorityCD === Prow.codeValue) { dataItem.priorityCD = Prow.codeID } })
   this.assetClass.forEach(arow => { if (dataItem.assetClass == arow.className) { dataItem.assetClass = arow.assetClassID } })
   this.StatusDropdown.forEach(srow => { if (dataItem.statusCD === srow.codeValue) { dataItem.statusCD = srow.codeID } })
  
    this.PlannedDateWork = new Date(dataItem.plannedDateWork)
    this.formGroupRM = formGroupRM(dataItem);
    sender.editRow(rowIndex, this.formGroupRM);
  }
  cancelHandler({ sender, rowIndex }) {
   
    this.LocationRmList.forEach(row => {
      this.PriorityDropdown.forEach(Prow => { if (Number(row.priorityCD) == Prow.codeID) { row.priorityCD = Prow.codeValue } })
      this.assetClass.forEach(arow => { if (Number(row.assetClass) == arow.assetClassID) { row.assetClass = arow.className } })
      this.StatusDropdown.forEach(srow => { if (Number(row.statusCD)== srow.codeID) { row.statusCD = srow.codeValue } })
      this.isRowOpen=false;
    });
    this.gridData = process(this.LocationRmList, this.state);
    this.closeEditor(sender, rowIndex);
    
   }
  saveHandler({ sender, rowIndex, dataItem, formGroup, isNew }) {
    var num:Number=0
    formGroup.value .locationID = this.LocationId;
    formGroup.value.plannedDateWork = this.PlannedDateWork;
  
   this.LocationRMModel.locationID=formGroup.value.locationID;
   this.LocationRMModel.locationRMID=formGroup.value.locationRMID;
   this.LocationRMModel.plannedDateWork=formGroup.value.plannedDateWork;
   this.LocationRMModel.priorityCD=formGroup.value.priorityCD;
   this.LocationRMModel.qty=formGroup.value.qty;
   this.LocationRMModel.plannedSpend=formGroup.value.plannedSpend;
   this.LocationRMModel.statusCD=formGroup.value.statusCD;
   this.LocationRMModel.updatedBy=formGroup.value.updatedBy;
   this.LocationRMModel.updationDate=formGroup.value.updationDate;
   
   this.LocationRMModel.description=formGroup.value.description;
   this.LocationRMModel.creationDate=formGroup.value.creationDate;
   this.LocationRMModel.createdBy=formGroup.value.createdBy;
   this.LocationRMModel.comments=formGroup.value.comments
   this.LocationRMModel.assetClass=formGroup.value.assetClass
   
  
   this.PriorityDropdown.forEach(Prow => { if (Number(this.LocationRMModel.priorityCD) === Prow.codeID) { this.LocationRMModel.priorityCD = Prow.codeValue } })
   this.assetClass.forEach(arow => { if (Number(this.LocationRMModel.assetClass )== Number(arow.assetClassID)) { this.LocationRMModel.assetClass = arow.className } })
   this.StatusDropdown.forEach(srow => { if (Number(this.LocationRMModel.statusCD) === srow.codeID) { this.LocationRMModel.statusCD = srow.codeValue } })
   

   if(formGroup.status=="VALID"){
     //this.saveLocationRM(formGroup.value)
   
    sender.closeRow(rowIndex);
   
   
    if (isNew) {
      this.LocationRmList.push(this.LocationRMModel);
      this.gridData = process(this.LocationRmList.reverse(), this.state);
    
    } 
    else{
      this.LocationRmList = this.LocationRmList.map((t,index) =>
     index!== rowIndex ? t : this.LocationRMModel
      );
      this.gridData = process(this.LocationRmList, this.state);
    }
  
    this.LocationRMModel=new LocationRMModel();
    this.formGroupRM=formGroupRM(new LocationRMModel());
    this.PlannedDateWork=new Date();

    
     this.isRowOpen=false;
    
   this.PlannedDateWork=undefined;
  }else(
     
    this.notify.error(this.ErrMessagesData.rmError)
   //this.notify.error("error")
    
    )
  }
  RemoveHandler(dataItem) {
    
    if (dataItem.dataItem.locationRMID !=null) {
      
    this.DeleteLocationRM(dataItem.dataItem.locationRMID);
     
    }
   
    this.LocationRmList.forEach((element, index) => {
      if (index == dataItem.rowIndex) this.LocationRmList.splice(index, 1);
    });
 
    this.gridData = process(this.LocationRmList, this.state);
    this.formGroupRM=formGroupRM(new LocationRMModel());
    this.isRowOpen=false
  }

    closeEditor(grid, rowIndex ) {
      grid.closeRow(rowIndex);
      this.isRowOpen=false;
      this.formGroupRM =  formGroupRM(new LocationRMModel);

    }
   

 
 




    LocationRM() {
    if((this.IsAddDepartment || this.IsUpdateDepartment)){
      return
    }
      this.DataService
        .GetLocationRM(this.LocationId)

        .subscribe(
          response => {
            response.forEach(row => {       
              this.LocationRmList.push(row) 
            });
            this.gridData = process(this.LocationRmList.reverse(), this.state);

            // this.FilterGridByCharacter("All")
          },
          (catchError) => {
            if (catchError) {
              this.toastrService.error(
                "error" + catchError.status + " " + catchError["message"]
              );
            }
          }
        );
    }
    FilterGridByCharacter() {
     
       
        this.gridData = process(this.LocationRmList, this.state);
    
      
    
     
      //this.sharedDataService.selectedUserGridCharacter = characters;
      this.dataStateChange({ filter: this.state.filter, group: this.state.group, skip: 0, sort: this.state.sort, take: this.state.take });
    }
    classList() {
      this.DataService
        .getAssetClassList()
        .first()
        .subscribe(
          (response) => {
            for (let key in response) {
              this.assetClass.push({
                assetClassID: response[key]["assetClassID"],
                className: response[key]["className"],
              });
            }
          },
          (catchError) => {
            if (catchError) {
              this.toastrService.error(
                "error" + catchError.status + " " + catchError["message"]
              );
            }
          }
        );
    }

     saveLocationRM(data) {


      if (this.isRowOpen == false) {
      
         this.DataService.SaveLocationRM(data).subscribe((res) => {
          if (res != null && res != undefined && typeof res == "number") {
           
           
           this.LocationRMModel.locationRMID=res;
            this.LocationRmList = this.LocationRmList.map((t) =>
            t.locationRMID !==data.locationRMID ? t : data
          );
          this.gridData = process(this.LocationRmList, this.state);
   
          }
        })}
      else {
          this.notify.warning("Row is Open");
       
        }
      }

      DeleteLocationRM(id) {
        this.DataService.DeleteLocationRMbyID(id).subscribe((res) => {  this.notify.success(this.ErrMessagesData.deletedSuccessfully);} ,(catchError)=>{  this.notify.warning(this.ErrMessagesData.SomethingWentWrong)})
      }
      
    
      
      parseDate(dateString: string): Date {
        if (dateString) {
            return new Date(dateString);
        }
        return null;
    }
    getAssetDetails(data) {
      this.sharedDataService.GetOrgSettings().then((res) => {
        if (res != null) {
          this.DateTimeformat = res["DateFormat"] ;
          this.Dateformat = res["DateFormat"];
          this.GridCurrencyChange = res.Currency;
          this.currencySymbol = res.CurrencySymbol;
        }
      });
    }
  
    get plannedDateWork(): any {
      return this.formGroupRM.get('plannedDateWork');
    }

    getUsersList() {
      this.DataService.getUserbyCustomerID(this.objLocation.customerID).first().subscribe((res) => {
        if (res != undefined && res != null) {
          this.userList=res   
          this.filteredUserList=res  
          this.objLocation.user.forEach(row=>{
          
            const delIndex: number = this.filteredUserList.indexOf(this.filteredUserList.find((obj) => obj.userID == row.userID) );
            if (delIndex != -1) {
              this.filteredUserList.splice(delIndex, 1);
            }
            const delIndex1: number = this.userList.indexOf(this.userList.find((obj) => obj.userID == row.userID) );
            if (delIndex1 != -1) {
              this.userList.splice(delIndex, 1);
            }
          }
          )
        
        this.bindLinkUser();
        }
      });  
    }

    onUserFilterChange(){
      this.filteredUserList=[];
      this.filteredUserList = this.userList.filter(s => s.userName.toUpperCase().includes(this.userSearchString.toUpperCase()));
      this.bindLinkUser();
    }

    bindLinkUser(){
     
      var oSelect = document.getElementById('linkUser');
      oSelect.innerHTML = "";
     
      if (this.filteredUserList != undefined && this.filteredUserList.length > 0) {
        for (let i = 0; i < this.filteredUserList.length; i++) {
          var oOption = document.createElement('option');
          oOption.value = (this.filteredUserList[i].userID).toString();
          oOption.innerHTML = this.filteredUserList[i].userName.toUpperCase();
         
          oSelect.appendChild(oOption)          
          
        }
      }
    }


    bindSelectedLinkUser(){
     
      var oSelect = document.getElementById('selectedlinkUser');
      oSelect.innerHTML = "";
      this.UserID=[];
      if (this.objLocation.user != undefined && this.objLocation.user.length > 0) {
      
        for (let i = 0; i < this.objLocation.user.length; i++) {
         this.UserID.push(this.objLocation.user[i].userID)
          var oOption = document.createElement('option');
          oOption.value = (this.objLocation.user[i].userID).toString();
          oOption.innerHTML = this.objLocation.user[i].userName.toUpperCase();
         
          oSelect.appendChild(oOption)          
          
        }
      }
    }

    addSelectedUser(){

      for (var i = 0; i < document.getElementById("linkUser").childNodes.length; i++) {
        if (document.getElementById("linkUser")[i].selected == true) {
          if (this.filteredUserList.find((obj) => obj.userID == document.getElementById("linkUser")[i].value)) {
            this.objLocation.user.push(this.filteredUserList.find((obj) => obj.userID == document.getElementById("linkUser")[i].value));
            const delIndex: number = this.filteredUserList.indexOf(this.filteredUserList.find((obj) => obj.userID == document.getElementById("linkUser")[i].value));
            if (delIndex != -1) {
              this.filteredUserList.splice(delIndex, 1);
            }
          }
        }
      }
      this.bindLinkUser();
      this.bindSelectedLinkUser();
      
    }

    removeSelectedUser(){

      for (var i = 0; i < document.getElementById("selectedlinkUser").childNodes.length; i++) {
        if (document.getElementById("selectedlinkUser")[i].selected == true) {
          if (this.objLocation.user.find((obj) => obj.userID == document.getElementById("selectedlinkUser")[i].value)) {
           this.filteredUserList.push(this.objLocation.user.find((obj) => obj.userID == document.getElementById("selectedlinkUser")[i].value));
            const delIndex: number =  this.objLocation.user.indexOf(this.objLocation.user.find((obj) => obj.userID == document.getElementById("selectedlinkUser")[i].value));
            if (delIndex != -1) {
              this.objLocation.user.splice(delIndex, 1);
            }
          }
        }
      }
      
      this.bindSelectedLinkUser();
      this.bindLinkUser();
      
    }

    
  onReady(map: any): void {
    if(this.SelectedMap=='Baidu')
    {
    this._map = map;
    this._map.clearOverlays();
    var point = new BMap.Point(this.longitude,this.latitude);
    this._map.centerAndZoom(point, 11);
    this._map.addControl(new BMap.MapTypeControl());
    this._map.enableScrollWheelZoom(true);

    var marker = new BMap.Marker(point);
    marker.enableDragging();
    this._map.addOverlay(marker);
    this._map.enableDragging();
   

   
    this._map.enableScrollWheelZoom(true);
    const navigationControl = new BMap.NavigationControl({
      anchor: 'BMAP_ANCHOR_BOTTOM_RIGHT',
      type: 'BMAP_NAVIGATION_CONTROL_ZOOM'
    });
    this._map.addControl(navigationControl);

    // First, create a Baidu Maps object with your API key

   
    var inputElementId = 'address';

    var input = document.getElementById(inputElementId);

// Add an event listener to the text box element
//input.addEventListener('focus', (e)=> {
 
    this.BaiduAutocomplete = new BMap.Autocomplete({
      input: inputElementId,
      location: this._map,
    });

    this.BaiduAutocomplete.addEventListener('onconfirm', (event: any) => {
      const keyword =
        event.item.value.city +
        ' ' +
        event.item.value.district +
        ' ' +
        event.item.value.business;
      console.log(event.item.value);
      var local = new BMap.LocalSearch(this._map, {
        renderOptions: { map: this._map, autoViewport: true },
      });
      local.search(keyword); //search for India, and the callback function will be triggered with results

      local.setSearchCompleteCallback((results) => {
        console.log(results);
        var point = results.getPoi(0).point;
        this._map.setCenter(new BMap.Point(point.lat, point.lng));
        var marker = new BMap.Marker(new BMap.Point(point.lat, point.lng));
        this._map.addOverlay(marker);

        this.latitude = point.lat;
        this.longitude = point.lng;
        this.zoom = 12;
        this.objLocation.geoLocation = this.latitude + ',' + this.longitude;
       this.objLocation.addressLine1 = this.searchElementRef.nativeElement.value;
        console.log(this.latitude, this.longitude);
      });
    });
  //})

    marker.addEventListener('dragend', (e) => {
      this.latitude = e.point.lat;
      this.longitude = e.point.lng;
      this.objLocation.geoLocation = this.latitude + ',' + this.longitude;
      console.log(this.latitude, this.longitude);
    });

    this._map.addEventListener('click', (e) => {
      this._map.clearOverlays();
      marker = new BMap.Marker(e.point);
      marker.enableDragging();
      this._map.addOverlay(marker);
      marker.addEventListener('dragend', (e) => {
        this.latitude = e.point.lat;
        this.longitude = e.point.lng;
        this.objLocation.geoLocation = this.latitude + ',' + this.longitude;
        console.log(this.latitude, this.longitude);
      });
    });
  
    this._map.addEventListener("tilesloaded", () => {
      console.log("Map tiles loaded");
      this.searchElementRef.nativeElement.value = this.objLocation.addressLine1;
    });
  }
}

  searchaddress(event) {
    if(this.SelectedMap=='Baidu'){
    if (event.key === 'Enter') {
      var local = new BMap.LocalSearch(this._map, {
        renderOptions: { map: this._map, autoViewport: true },
      });
      local.search((event.target as HTMLInputElement).value);
      local.setSearchCompleteCallback((results) => {
        console.log(results);
        var point = results.getPoi(0).point;
        this._map.setCenter(new BMap.Point(point.lat, point.lng));
        var marker = new BMap.Marker(new BMap.Point(point.lat, point.lng));
        this._map.addOverlay(marker);

        this.latitude = point.lat;
        this.longitude = point.lng;
        this.objLocation.geoLocation = this.latitude + ',' + this.longitude;
          this.objLocation.addressLine1 = this.searchElementRef.nativeElement.value;
        console.log(this.latitude, this.longitude);
      });
    }
  }
}

onValueChangeMap(){
  if(this.SelectedMap=='Google'){
    this.BaiduAutocomplete.hide();
    this.BaiduAutocomplete.dispose();
  
  }
  else if(this.SelectedMap=='Baidu'){
    google.maps.event.clearInstanceListeners(this.searchElementRef.nativeElement);
   
 
  }
}

}
