///<reference types="@types/googlemaps" />
import { SnotifyService } from 'ng-snotify';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService } from '../../Service/CommonUtility.service'
import { Router, ActivatedRoute } from '@angular/router';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor, GroupDescriptor, AggregateDescriptor, AggregateResult, aggregateBy } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, CellClickEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Title } from '@angular/platform-browser';
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { Keys } from "@progress/kendo-angular-common";
import { AssetsRMListEntity, AssetsRMModel } from './AssetsRMList-management.model';
import { TooltipDirective } from "@progress/kendo-angular-tooltip";
import { ToastrService } from 'ngx-toastr';
import { ExcelExportData, Workbook } from '@progress/kendo-angular-excel-export';
import { ExcelExportEvent } from '@progress/kendo-angular-grid/dist/es2015/excel/excel-export-event';
import { saveAs } from "@progress/kendo-file-saver";
import { Observable, zip } from "rxjs";







@Component({
  selector: 'app-RM-management',
  templateUrl: 'AssetsRMList-management.component.html',
  styleUrls: ['AssetsRMList-management.component.scss']
})
export class AssetsRMList implements OnInit {
  RMList: Array<AssetsRMListEntity> = [];
  private state: State = {
    skip: 0,
    take: 1000,
    filter: {
      logic: 'and',
      filters: []
    },
    
  };
  @ViewChild(TooltipDirective, {static:false}) public tooltipDir: TooltipDirective;
  public groups: GroupDescriptor[] = [{ field: "rmItem"}];

  public aggregates: AggregateDescriptor[] = [
    { field: "rmBudgetYear1", aggregate: "sum" },
    { field: "rmBudgetYear2", aggregate: "sum" },
    { field: "rmBudgetYear3", aggregate: "sum" },
    { field: "rmBudgetYear4", aggregate: "sum" },
    { field: "rmBudgetYear5", aggregate: "sum" },
    { field: "rmBudgetYear6", aggregate: "sum" },
    { field: "rmBudgetYear7", aggregate: "sum" },
    { field: "rmBudgetYear8", aggregate: "sum" },
    { field: "rmBudgetYear9", aggregate: "sum" },
    { field: "rmBudgetYear10", aggregate: "sum" },
    { field: "rmBudgetYear11", aggregate: "sum" },
    { field: "rmBudgetYear12", aggregate: "sum" },
    { field: "rmBudgetYear13", aggregate: "sum" },
    { field: "rmBudgetYear14", aggregate: "sum" },
    { field: "rmBudgetYear15", aggregate: "sum" },
    { field: "rmBudgetYear16", aggregate: "sum" },
    { field: "rmBudgetYear17", aggregate: "sum" },
    { field: "rmBudgetYear18", aggregate: "sum" },
    { field: "rmBudgetYear19", aggregate: "sum" },
    { field: "rmBudgetYear20", aggregate: "sum" },

  ];
  public gridData1:GridDataResult = process(this.RMList, this.state);
  public gridData: GridDataResult = process(this.RMList, this.state);

  public total: AggregateResult = aggregateBy(
    this.gridData1.data,
    this.aggregates
  );
  assetRMModel:AssetsRMModel = new AssetsRMModel();
  
 public Comment:string;
  locationListingTitle: any;
  
  LanguageConfig: any;
  BusinessLanguageConfig:any;
  public LocationRmList: Array<any> = [];
  GridConfig: any
  public Index:number;
  public Field:string;
  IsDepartmentManagement : boolean
  IsLocationManagement : boolean
  TooltipValue: string;
  showCommentDialog: boolean=false;
  args: CellClickEvent;
  ErrorMsgData: any;
  locationRMGridData: any;
  LocationRMLanguageConfig: any;
  loading: boolean;
  constructor( private toastrService: ToastrService,private titleService: Title,private activatedRoute: ActivatedRoute, private notify: SnotifyService, private DataService: CoreDataService, private router: Router, private route: ActivatedRoute, private sharedDataService: SharedDataService,private formBuilder: FormBuilder) {
      let pageTite = this.activatedRoute.snapshot.data['title'];
      this.titleService.setTitle(pageTite);
     

  }
  GetRMListOnRMItem(): any {
  
    this.DataService.GetRMListOnRMItem(parseInt(this.route.snapshot.paramMap.get("locationID"))).subscribe((res) => {
      if (res != undefined && res != null) {
        this.RMList = [] 
        this.RMList=res;
        this.RMList.forEach(row=>{
          if(row.rmItem!= null || row.rmItem!=undefined){
          if(row.rmItem!='Projects & Major Refurbishments'){
          row.rmItem=' '+row.rmItem
         
          }
          else{
            row.rmItem=this.LanguageConfig.ProjectsMajorRefurbishments;
          }
          row.rmItem=row.rmItem
        }
        })
        this.gridData = process(this.RMList, this.state );
        this.total = aggregateBy(this.gridData.data, this.aggregates);
        this.gridData = process(this.RMList, {group:this.groups} );  
        this.allData = this.allData.bind(this); 
      }
    })
  }

  ngOnInit() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[65];
          this.ErrorMsgData = res[28];
          this.LocationRMLanguageConfig = res[8];
         
          this.GridConfig = res[2]
        }
        if(res!=""){
          this.GetRMListOnRMItem();
          this.LocationRM();
        }
      })

      var token = window.localStorage.getItem('token');

     
 
  }

  }
 

  onTabchange(event) {
    if (event.index == 1) { 
    }
  }
  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.RMList, this.state);
    this.allData = this.allData.bind(this);
  }
  Open(id,type){
    if(type==this.LanguageConfig.ProjectsMajorRefurbishments)
    var baseUrl = window.location.origin + '/AddLocation/' + id;
    window.open(baseUrl);
    if(type!=this.LanguageConfig.ProjectsMajorRefurbishments)
    var baseUrl = window.location.origin + '/AddAssets/' + id;
    window.open(baseUrl);
  }
  
  public cellClickHandler(args: CellClickEvent): void {
    this.args=args
    if (!args.isEdited && args.type=='click') {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        this.createFormGroup(args.dataItem)
      );
    }
  }
  
   public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({  
       assetID :dataItem.assetID,
       rmBudetID:dataItem.rmBudetID,
       userAssetID:dataItem.userAssetID,
       barcode:dataItem.barcode,
       assetLocationID :dataItem.assetLocationID,
       assetClass:dataItem.assetClass,
       assetStatus:dataItem.assetStatus,
       rMItem :dataItem.rMItem,
       locationName:dataItem.locationName,
      rmBudgetYear1 :[dataItem.rmBudgetYear1,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear2 :[dataItem.rmBudgetYear2,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear3 :[dataItem.rmBudgetYear3,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear4 :[dataItem.rmBudgetYear4,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear5 :[dataItem.rmBudgetYear5,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear6 :[dataItem.rmBudgetYear6,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear7 :[dataItem.rmBudgetYear7,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear8 :[dataItem.rmBudgetYear8,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear9 :[dataItem.rmBudgetYear9,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear10 :[dataItem.rmBudgetYear10,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear11 :[dataItem.rmBudgetYear11,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear12 :[dataItem.rmBudgetYear12,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear13 :[dataItem.rmBudgetYear13,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear14 :[dataItem.rmBudgetYear14,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear15 :[dataItem.rmBudgetYear15,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear16 :[dataItem.rmBudgetYear16,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear17 :[dataItem.rmBudgetYear17,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear18 :[dataItem.rmBudgetYear18,Validators.compose([Validators.required, Validators.min(0) ])],  
      rmBudgetYear19 :[dataItem.rmBudgetYear19,Validators.compose([Validators.required, Validators.min(0) ])],
      rmBudgetYear20 :[dataItem.rmBudgetYear20,Validators.compose([Validators.required, Validators.min(0) ])],  
      

     });
  }
  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      this.notify.error("Please enter valid value");
      args.preventDefault();
    } else if (formGroup.dirty) {  
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }
      dataItem.rmBudgetYear1=formGroup.value.rmBudgetYear1
      dataItem.rmBudgetYear2=formGroup.value.rmBudgetYear2
      dataItem.rmBudgetYear3=formGroup.value.rmBudgetYear3
      dataItem.rmBudgetYear4=formGroup.value.rmBudgetYear4
      dataItem.rmBudgetYear5=formGroup.value.rmBudgetYear5
      dataItem.rmBudgetYear6=formGroup.value.rmBudgetYear6
      dataItem.rmBudgetYear7=formGroup.value.rmBudgetYear7
      dataItem.rmBudgetYear8=formGroup.value.rmBudgetYear8
      dataItem.rmBudgetYear9=formGroup.value.rmBudgetYear9
      dataItem.rmBudgetYear10=formGroup.value.rmBudgetYear10
      dataItem.rmBudgetYear11=formGroup.value.rmBudgetYear11
      dataItem.rmBudgetYear12=formGroup.value.rmBudgetYear12
      dataItem.rmBudgetYear13=formGroup.value.rmBudgetYear13
      dataItem.rmBudgetYear14=formGroup.value.rmBudgetYear14
      dataItem.rmBudgetYear15=formGroup.value.rmBudgetYear15
      dataItem.rmBudgetYear16=formGroup.value.rmBudgetYear16
      dataItem.rmBudgetYear17=formGroup.value.rmBudgetYear17
      dataItem.rmBudgetYear18=formGroup.value.rmBudgetYear18
      dataItem.rmBudgetYear19=formGroup.value.rmBudgetYear19
      dataItem.rmBudgetYear20=formGroup.value.rmBudgetYear20
      if(args.column.field=='rmBudgetYear1'){
        dataItem.rmBudgetYear1Comment=''
      }
      else if(args.column.field=='rmBudgetYear2'){
        dataItem.rmBudgetYear2Comment=''
      }
      else if(args.column.field=='rmBudgetYear3'){
        dataItem.rmBudgetYear3Comment=''
      }
      else if(args.column.field=='rmBudgetYear4'){
        dataItem.rmBudgetYear4Comment=''
      }
      else if(args.column.field=='rmBudgetYear5'){
        dataItem.rmBudgetYear5Comment=''
      }
      else if(args.column.field=='rmBudgetYear6'){
        dataItem.rmBudgetYear6Comment=''
      }
      else if(args.column.field=='rmBudgetYear7'){
        dataItem.rmBudgetYear7Comment=''
      }
      else if(args.column.field=='rmBudgetYear8'){
        dataItem.rmBudgetYear8Comment=''
      }
      else if(args.column.field=='rmBudgetYear9'){
        dataItem.rmBudgetYear9Comment=''
      }
      else if(args.column.field=='rmBudgetYear10'){
        dataItem.rmBudgetYear10Comment=''
      }
      else if(args.column.field=='rmBudgetYear11'){
        dataItem.rmBudgetYear11Comment=''
      }
      else if(args.column.field=='rmBudgetYear12'){
        dataItem.rmBudgetYear12Comment=''
      }
      else if(args.column.field=='rmBudgetYear13'){
        dataItem.rmBudgetYear13Comment=''
      }
      else if(args.column.field=='rmBudgetYear14'){
        dataItem.rmBudgetYear14Comment=''
      }
      else if(args.column.field=='rmBudgetYear15'){
        dataItem.rmBudgetYear15Comment=''
      }
      else if(args.column.field=='rmBudgetYear16'){
        dataItem.rmBudgetYear16Comment=''
      }
      else if(args.column.field=='rmBudgetYear17'){
        dataItem.rmBudgetYear17Comment=''
      }
      else if(args.column.field=='rmBudgetYear18'){
        dataItem.rmBudgetYear18Comment=''
      }
      else if(args.column.field=='rmBudgetYear19'){
        dataItem.rmBudgetYear19Comment=''
      }
      else if(args.column.field=='rmBudgetYear20'){
        dataItem.rmBudgetYear20Comment=''
      }
    

      this.RMList.forEach((ele,index)=>{
        if(ele.rmItem == dataItem.rmItem && ele.assetID == dataItem.assetID){
          this.Index=index;
          this.Field=args.column.field;
          this.showCommentDialog=true;
         this.RMList[index]=dataItem;
      this.gridData = process(this.RMList, this.state );
      this.total = aggregateBy(this.gridData.data, this.aggregates);
      this.gridData = process(this.RMList, {group:this.groups} );  
      this.allData = this.allData.bind(this);   
    }
      })  
    }
  }

  saveChanges(){
   
    this.assetRMModel.addUpdateRMBudgetModel=this.RMList;
    this.assetRMModel.locationID=parseInt(this.route.snapshot.paramMap.get("locationID"))
    this.DataService.SaveRMBudget( this.assetRMModel).subscribe(res=>{

      this.toastrService.success(
        this.ErrorMsgData.RepairAndMaintananceUpdatedSuccessfully
      
      );
    })
  }
  cancelChanges(){
 this.GetRMListOnRMItem()
  }

  close(){

    this.showCommentDialog=false
  }
  AddComment(){
if(this.Field=="rmBudgetYear1"){
this.RMList[this.Index].rmBudgetYear1Comment=this.Comment==null?'':this.Comment
}
if(this.Field=="rmBudgetYear2"){
  this.RMList[this.Index].rmBudgetYear2Comment=this.Comment==null?'':this.Comment
  }
if(this.Field=="rmBudgetYear3"){
    this.RMList[this.Index].rmBudgetYear3Comment=this.Comment==null?'':this.Comment
   }
if(this.Field=="rmBudgetYear4"){
      this.RMList[this.Index].rmBudgetYear4Comment=this.Comment==null?'':this.Comment
 }
 if(this.Field=="rmBudgetYear5"){
  this.RMList[this.Index].rmBudgetYear5Comment=this.Comment==null?'':this.Comment
  }
  if(this.Field=="rmBudgetYear6"){
    this.RMList[this.Index].rmBudgetYear6Comment=this.Comment==null?'':this.Comment
    }
  if(this.Field=="rmBudgetYear7"){
      this.RMList[this.Index].rmBudgetYear7Comment=this.Comment==null?'':this.Comment
     }
  if(this.Field=="rmBudgetYear8"){
        this.RMList[this.Index].rmBudgetYear8Comment=this.Comment==null?'':this.Comment
   }
   if(this.Field=="rmBudgetYear9"){
    this.RMList[this.Index].rmBudgetYear9Comment=this.Comment==null?'':this.Comment
    }
    if(this.Field=="rmBudgetYear10"){
      this.RMList[this.Index].rmBudgetYear10Comment=this.Comment==null?'':this.Comment
      }
    if(this.Field=="rmBudgetYear11"){
        this.RMList[this.Index].rmBudgetYear11Comment=this.Comment==null?'':this.Comment
       }
    if(this.Field=="rmBudgetYear12"){
          this.RMList[this.Index].rmBudgetYear12Comment=this.Comment==null?'':this.Comment
     }
     if(this.Field=="rmBudgetYear13"){
      this.RMList[this.Index].rmBudgetYear13Comment=this.Comment==null?'':this.Comment
      }
      if(this.Field=="rmBudgetYear14"){
        this.RMList[this.Index].rmBudgetYear14Comment=this.Comment==null?'':this.Comment
        }
      if(this.Field=="rmBudgetYear15"){
          this.RMList[this.Index].rmBudgetYear15Comment=this.Comment==null?'':this.Comment
         }
      if(this.Field=="rmBudgetYear16"){
            this.RMList[this.Index].rmBudgetYear16Comment=this.Comment==null?'':this.Comment
       }
       if(this.Field=="rmBudgetYear17"){
        this.RMList[this.Index].rmBudgetYear17Comment=this.Comment==null?'':this.Comment
        }
        if(this.Field=="rmBudgetYear18"){
          this.RMList[this.Index].rmBudgetYear18Comment=this.Comment==null?'':this.Comment
          }
        if(this.Field=="rmBudgetYear19"){
            this.RMList[this.Index].rmBudgetYear19Comment=this.Comment==null?'':this.Comment
           }
        if(this.Field=="rmBudgetYear20"){
              this.RMList[this.Index].rmBudgetYear20Comment=this.Comment==null?'':this.Comment
         }
         this.showCommentDialog=false
         this.Field=null
         this.Index=null
         this.Comment=null


  }
  public rowCallback = (context: RowClassArgs) => {
  
      
        return { gold: true };
     
    
  };
  public MenuOnSelect($event){
    if($event.item=='Edit Value'){
      if (!this.args.isEdited ) {
        this.args.sender.editCell(
          this.args.rowIndex,
          this.args.columnIndex,
          this.createFormGroup(this.args.dataItem)
        );
      }
    }
    else if('Edit Comment'){
      this.RMList.forEach((ele,index)=>{
        if(ele.rmItem == $event.dataItem.rmItem && ele.assetID == $event.dataItem.assetID){
          this.Index=index;
          this.Field=this.args.column.field;
          this.showCommentDialog=true;
      
        }
      })  
    }


  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.RMList, {
      
        sort: [{ field: "active", dir: "desc" }],
      }).data,
      
    };
  
    return result;
  }
  LocationRM() {
   
      this.DataService
        .GetLocationRM(parseInt(this.route.snapshot.paramMap.get("locationID")))

        .subscribe(
          response => {
            response.forEach(row => {       
              this.LocationRmList.push(row) 
            });
            this.locationRMGridData = process(this.LocationRmList.reverse(), this.state);

            // this.FilterGridByCharacter("All")
          },
          (catchError) => {
            if (catchError) {
              this.toastrService.error(
                "error" + catchError.status + " " + catchError["message"]
              );
            }
          }
        );
    }

    public showOnlyBeveragesDetails(dataItem): boolean {
      if(dataItem.barcode==null && dataItem.barcode== null && dataItem.assetID==0){
      return true
      }
      else{
        return false
      }
    }


    public onExcelExport(args: ExcelExportEvent): void {
      // Prevent automatically saving the file. We will save it manually after we fetch and add the details
      args.preventDefault();
  
      this.loading = true;
  
      const observables = [];
      const workbook = args.workbook;
      const rows = workbook.sheets[0].rows;
      
      // Get the default header styles.
      // Aternatively set custom styles for the details
      // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/WorkbookSheetRowCell/
      const headerOptions = rows[0].cells[0];

     
      
      for (
        let Idx = this.LocationRmList.length - 1;
        Idx >= 0;
        Idx--
      ) {
          rows.splice(this.RMList.length+1, 0, {
            cells: [
              {},
              { value: this.LocationRmList[Idx].priorityCD},
              { value: this.LocationRmList[Idx].assetClass },
              { value: this.LocationRmList[Idx].description},
              { value: this.LocationRmList[Idx].qty },
              { value: this.LocationRmList[Idx].plannedDateWork},
              { value: this.LocationRmList[Idx].plannedSpend },
              { value: this.LocationRmList[Idx].statusCD},
              { value: this.LocationRmList[Idx].comments },
            ],
          });
        }
        

        // add the detail header
        rows.splice(this.RMList.length+1, 0, {
          cells: [
            {},
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.Priority }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.AssetClass }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.Description }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.Quantity }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.PlannedDateofWorks }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.plannedSpend }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.Status }),
            Object.assign({}, headerOptions, { value: this.LocationRMLanguageConfig.Comment }),
          ],
        });
  
        // create a Workbook and save the generated data URL
        // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/Workbook/
        new Workbook(workbook).toDataURL().then((dataUrl: string) => {
          // https://www.telerik.com/kendo-angular-ui/components/filesaver/
          saveAs(dataUrl, "Assets-RM.xlsx");
          this.loading = false;
        });
      
      }
 
}
