import { SnotifyService } from "ng-snotify";
import {
  AssetListingEntity,
  AssetGeoLocationUpdate,
} from "./asset-listing.model";
///<reference types="@types/googlemaps" />
import * as MarkerClusterer from "@google/markerclusterer";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreDataService } from "../../Service/core-data.service";
import {
  SharedDataService,
  ReportFilter,
} from "../../Service/CommonUtility.service";
import { Router } from "@angular/router";
import {
  GridDataResult,
  DataStateChangeEvent,
  SelectAllCheckboxState,
} from "@progress/kendo-angular-grid";
import {
  process,
  State,
  SortDescriptor,
  orderBy,
  filterBy,
  FilterDescriptor,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { google } from "@google/maps";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { Title } from "@angular/platform-browser";
import { BehaviorSubject, Subscription } from "rxjs";

import { PortfolioReportEntity } from "../Reports/reports.model";
import { environment } from "../../../environments/environment";
import { ExcelServiceExport } from "../../Service/ExcelExport.service";
declare var google: any;

const flatten = (filter) => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce(
      (acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]),
      []
    );
  }
  return [];
};

@Component({
  selector: "app-assets-listing",
  templateUrl: "assets-listing.component.html",
  styleUrls: ["assets-listing.component.scss"],
})
export class AssetsListingComponent implements OnInit, OnDestroy {
  //--Start : Map MetaData
  map: google.maps.Map;

  markerClusters: Array<MarkerClusterer>;
  mapData: Array<AssetListingEntity> = []; //The locations Lat-Long to Draw on Map

  fields: string[];

  imageUrl =
    "https://chart.apis.google.com/chart?cht=mm&chs=24x32&chco=FFFFFF,008CFF,000000&ext=.png";
  oms: OverlappingMarkerSpiderfier;
  //--End : Map MetaData
  DownloadAssetCount: number;
  IsDashboardAsset: boolean;
  GridCurrencyChange: any;
  ExpenseDashboardOpened: boolean=false;
  public AssetID:number
  constructor(
    private cd: ChangeDetectorRef,
    private excelService: ExcelServiceExport,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private notify: SnotifyService,
    private route: ActivatedRoute,
    private DataService: CoreDataService,
    private router: Router,
    private sharedDataService: SharedDataService
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);

    if (this.activatedRoute.snapshot.data["IsDashboardAsset"]) {
      this.IsDashboardAsset =
        this.activatedRoute.snapshot.data["IsDashboardAsset"];
    }
  }

  assetList: any[] = [];
  assetListExport: any[] = [];

  AssetGeoLocationUpdate: AssetGeoLocationUpdate = new AssetGeoLocationUpdate();
  DateTimeformat: any;
  Dateformat: any;
  IsCustomer: string;
  value: string;
  private state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };
  private state1: State = {
    skip: 0,
    sort: [{ field: "userAssetID", dir: "asc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public clear_state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  private stateMap: State = {
    skip: 0,
    sort: [{ field: "active", dir: "desc" }],
  };
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[];
  private gridData: GridDataResult;
  CustomerIDModel: number;
  LocationIDModel: number;
  portfolioReportEntity: PortfolioReportEntity = new PortfolioReportEntity();
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.assetList, sort), this.state);
  }

  public mySelection: number[] = [];
  public selectAllState: SelectAllCheckboxState = "unchecked";

  sub: any;
  lat: any = 0;
  long: any = 0;
  ErrorMsgData: any;

  data: Array<any> = [
    {
      text: "Request PDF report",
    },
    { text: "Request DOC report" },
  ];
  LanguageLiteral:string;
  LanguageConfig: any;
  GridConfig: any;
  ReportFilter: ReportFilter = new ReportFilter();
  message: any;
  subscription: Subscription;
  AssetDataLabel: any;
  UserData: any;

  ngOnInit() {
   this.LanguageLiteral= this.sharedDataService.GetLanguageLiteral()
    var token = window.localStorage.getItem("token");
    if (token != null && token != undefined && token != "") {
      this.UserData = this.sharedDataService.parseJwt(token);
    }
    this.gridfilter = filterBy(this.assetList, this.filter);

    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[5];
          this.GridConfig = res[2];
          this.ErrorMsgData = res[28];
          this.AssetDataLabel = res[30];
        }
      });
    }
    this.DownloadAssetCount = environment.environment.DownloadAssetCount;
    this.value = this.route.snapshot.paramMap.get("name");
    if (this.value != undefined && this.value != null && this.value != "") {
      this.state.filter.filters = [
        {
          field: "locationName",operator: "contains",
          value: this.value.toString(),
        },
      ];
      this.sharedDataService.ReportFilterSharedData["customerID"] =
        this.route.snapshot.paramMap.get("customerID") == null
          ? undefined
          : Number.parseInt(this.route.snapshot.paramMap.get("customerID"));
          this.sharedDataService.ReportFilterSharedData["locationID"] =
        this.route.snapshot.paramMap.get("locationID") == null
          ? undefined
          : Number.parseInt(this.route.snapshot.paramMap.get("locationID"));
      this.sharedDataService.ReportFilterBeh$.next(this.ReportFilter);
    }
    this.IsCustomer = this.sharedDataService.IsCustomer();

    // NEW CODE WHILE TRAVERSING FROM REPORTS
    this.sharedDataService.ReportFilterBeh$.subscribe(
      (object: ReportFilter) => {
        let cust = this.sharedDataService.ReportFilterSharedData["customerID"];
        let loc = this.sharedDataService.ReportFilterSharedData["locationID"];
        let objReport: ReportFilter = new ReportFilter();
        let objReportObj: ReportFilter = object;
        if (JSON.stringify(objReportObj) === JSON.stringify(objReport)) {
          this.ReportFilter = new ReportFilter();
          this.ReportFilter.customerID = cust;
          this.ReportFilter.locationID = loc;
        }
      }
    );
    // NEW CODE WHILE TRAVERSING FROM REPORTS

    this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
    if (this.sharedDataService.ReportFilterSharedData != undefined) {
      this.ReportFilter.state =
        this.sharedDataService.ReportFilterSharedData.state;
      this.ReportFilter.sortBy =
        this.ReportFilter.sortBy == undefined ? "1" : this.ReportFilter.sortBy;
      this.ReportFilter.OHSComments =
        this.ReportFilter.OHSComments == undefined
          ? 2
          : this.ReportFilter.OHSComments;
      this.ReportFilter.conditionRating =
        this.ReportFilter.conditionRating == undefined
          ? ""
          : this.ReportFilter.conditionRating;
      this.ReportFilter.criticality =
        this.ReportFilter.criticality == undefined
          ? ""
          : this.ReportFilter.criticality;

      this.ReportFilter.filtersFitted =
        this.ReportFilter.filtersFitted == undefined
          ? ""
          : this.ReportFilter.filtersFitted;
      this.ReportFilter.refrigerant =
        this.ReportFilter.refrigerant == undefined
          ? ""
          : this.ReportFilter.refrigerant;
      this.ReportFilter.remainingLife =
        this.ReportFilter.remainingLife == undefined
          ? ""
          : this.ReportFilter.remainingLife;
      this.ReportFilter.replacementCost =
        this.ReportFilter.replacementCost == undefined
          ? ""
          : this.ReportFilter.replacementCost;
          this.ReportFilter.totalSpend =
          this.ReportFilter.totalSpend == undefined
            ? ""
            : this.ReportFilter.totalSpend;
            
      this.ReportFilter.replacementYear =
        this.ReportFilter.replacementYear == undefined
          ? ""
          : this.ReportFilter.replacementYear;
      this.ReportFilter.serviceCalls =
        this.ReportFilter.serviceCalls == undefined
          ? ""
          : this.ReportFilter.serviceCalls;
      this.ReportFilter.sortBy =
        this.ReportFilter.sortBy == undefined ? "" : this.ReportFilter.sortBy;
      this.ReportFilter.status =
        this.ReportFilter.status == undefined ? "" : this.ReportFilter.status;
      this.ReportFilter.ageGroup =
        this.ReportFilter.ageGroup == undefined
          ? ""
          : this.ReportFilter.ageGroup;
      this.ReportFilter.assetOwnership =
        this.ReportFilter.assetOwnership == undefined
          ? ""
          : this.ReportFilter.assetOwnership;
      this.ReportFilter.assetTypeID =
        this.ReportFilter.assetTypeID == undefined
          ? ""
          : this.ReportFilter.assetTypeID;
      this.ReportFilter.assetGroup =
        this.ReportFilter.assetGroup == undefined
          ? ""
          : this.ReportFilter.assetGroup;

      this.ReportFilter.capacityStart =
        this.ReportFilter.capacityStart == undefined
          ? undefined
          : this.ReportFilter.capacityStart;
      this.ReportFilter.capacityEnd =
        this.ReportFilter.capacityEnd == undefined
          ? undefined
          : this.ReportFilter.capacityEnd;

      this.ReportFilter.installStartDate =
        this.ReportFilter.installStartDate == undefined
          ? ""
          : this.ReportFilter.installStartDate;
      this.ReportFilter.installEndDate =
        this.ReportFilter.installEndDate == undefined
          ? ""
          : this.ReportFilter.installEndDate;

      this.ReportFilter.customerID =
        this.ReportFilter.customerID == undefined
          ? null
          : this.ReportFilter.customerID;
      this.ReportFilter.locationID =
        this.ReportFilter.locationID == undefined
          ? null
          : this.ReportFilter.locationID;
      if (this.activatedRoute.snapshot.data["title"] == "Asset Management") {
        this.getAssetDetails(this.ReportFilter);
      }
    }

    this.subscription = this.sharedDataService
      .getMessage()
      .subscribe((message) => {
        this.message = message;
        if (this.message.title == "filter") {
          // this.sub = this.sharedDataService.ReportFilterBeh$.subscribe(
          //   (res) => {
          //     if (res != undefined) {
                this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
                this.getAssetDetails(this.ReportFilter);
          //     }
          //   }
          // );
        } else if (this.message.title == "reset") {
        //  this.sub = this.sharedDataService.ReportFilterBeh$.subscribe(
          //  (res) => {
            //  if (res != undefined) {
                this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
                this.getAssetDetails(this.ReportFilter);
             // }
            //}
          ///);
        }
        this.message.title = "defined";
      });
  }

  public onSelectedKeysChange(e) {
    const len = this.mySelection.length;

    if (len <= 10000) {
      if (len === 0) {
        this.selectAllState = "unchecked";
      } else if (len > 0 && len < this.assetList.length) {
        this.selectAllState = "indeterminate";
      } else {
        this.selectAllState = "checked";
      }
      this.gridData = process(this.assetList, this.state);
    } else {
      this.mySelection = [];
      this.notify.warning(this.ErrorMsgData.maxLimitRecords);
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === "checked") {
      this.mySelection = this.assetList.map((item) => item.assetID);
      this.selectAllState = "checked";
    } else {
      // this.mySelection = [];
      this.selectAllState = "unchecked";
    }
    this.gridData = process(this.assetList, this.state);
  }

  FieldNameChange(FieldName: string) {
    if(this.AssetDataLabel !=undefined){
    var FieldLabel = this.AssetDataLabel.filter((data) => {
      return (
        FieldName.toLowerCase().toString() ===
        data.FieldName.toLowerCase().toString()
      );
    });
    var Field=FieldLabel[0]==undefined ?undefined : FieldLabel[0].DisplayName
    if(Field==undefined ){
   if(FieldName=='userAssetID')
   Field=this.LanguageConfig['UserAssetID']
   else  if(FieldName=='locationName')
   Field=this.LanguageConfig['Location']
   else  if(FieldName=='assetTypeName')
   Field=this.LanguageConfig['AssetType']
   else  if(FieldName=='conditionRatingName')
   Field=this.LanguageConfig['conditionRating']
   else  if(FieldName=='attachmentCount')
   Field=this.LanguageConfig['attachment']
   else  if(FieldName=='replacementCostBudget')
   Field=this.LanguageConfig['replacementprice']
   else  if(FieldName=='statusName')
   Field=this.LanguageConfig['Status']
   else  if(FieldName=='updationDate')
   Field=this.LanguageConfig['UpdationDate']
   else  if(FieldName=='createdBy')
   Field=this.LanguageConfig['CreatedBy']
   else  if(FieldName=='creationDate')
   Field=this.LanguageConfig['CreationDate']
   else  if(FieldName=='assetCaptureDate')
   Field=this.LanguageConfig['AssetCaptureDate']
   else
   Field=this.LanguageConfig[FieldName]
    }
  }
    return Field == undefined ? FieldName : Field;
  }

  excelReportEntity: any;
  IsPreventedExport: boolean = true;
  public onExcelExport(e: any): void {
    e.prevented = this.IsPreventedExport;
    this.IsReportOptionsEnabled =
      this.excelReportEntity != undefined &&
      this.excelReportEntity.IsOpened == false
        ? false
        : true;

    this.excelReportEntity = e;
    this.excelReportEntity.DataLength = this.DataLength;

    //this.DownloadAssetCount
    if (this.DataLength > this.DownloadAssetCount) {
      e.prevented = true;
      this.notify.warning(this.ErrorMsgData.manyRecords);
    }
  }

  SelectedAssetIDs: any = [];
  ExcelElement: any;
  ExcelReportEntityEvent(event) {
    console.log(event);
    this.IsPreventedExport = false;
    this.IsReportOptionsEnabled = false;
//Just test to download excell sheet
  this.ReportFilter=this.sharedDataService.ReportFilterSharedData;
  this.ReportFilter.isIncludeAllFields = event.isIncludeAllAssetFields;
  this.GetAllFieldsAssetData(this.ReportFilter);
    // this.sharedDataService.ReportFilterBeh$.subscribe((res) => {
    //   if (res != undefined) {
    //     this.ReportFilter = res;
    //     //this.ReportFilter = this.sharedDataService.ReportFilterSharedData
    //     this.ReportFilter.isIncludeAllFields = event.isIncludeAllAssetFields;
    //     this.GetAllFieldsAssetData(this.ReportFilter);
    //   }
    // });
  }

  ExportAssetExcel(element) {
    console.log(element);
    this.ExcelElement = element;
    this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
    console.log(this.DataLength);
  }

  GetAllFieldsAssetData(data) {
    this.DataService.GetAssetReport(data).subscribe((res) => {
      this.assetList = res;
      if (this.assetList != null) {
        this.assetList.forEach((ele) => {
          ele["assetCaptureDate"] =
            ele["assetCaptureDate"] == null
              ? null
              : new Date(ele["assetCaptureDate"]);
          ele["updationDate"] =
            ele["updationDate"] == "0001-01-01T00:00:00+00:00"
              ? new Date("0001-01-01T00:00:00+00:00")
              : new Date(ele["updationDate"]);
          ele["creationDate"] =
            ele["creationDate"] == "0001-01-01T00:00:00+00:00"
              ? new Date("0001-01-01T00:00:00+00:00")
              : new Date(ele["creationDate"]);
          ele["updationDateYear"] = new Date(ele["updationDate"]).getFullYear();
          ele["creationDateYear"] = new Date(ele["creationDate"]).getFullYear();
          ele["assetCaptureDateYear"] = (new Date(
            ele["assetCaptureDate"]
          ).getFullYear())==1970?null:(new Date(
            ele["assetCaptureDate"]
          ).getFullYear());
        });

        this.fields = Object.keys(this.assetList[0]);
        this.assetList = this.assetList;
        this.gridData = process(this.assetList, this.state);

        this.mapData = this.assetList;
        this.allData = this.allData.bind(this);
        this.assetListExport = this.allData().data;

        this.cd.detectChanges();

        //NEW CODE
        this.ExcelElement.save();
        // this.ExcelElement.save()
        this.ReportFilter = new ReportFilter();
        //RESET VALUES BLOCK
        this.IsPreventedExport = true;
      }
      this.getAssetDetails(this.sharedDataService.ReportFilterSharedData);
    });
  }
  ReportType: string;

  GenerateReport(extension, ReportType) {
    this.ReportType = ReportType;
    let FileExtension;
    if (extension == "Request PDF report") {
      FileExtension = "PDF";
    } else if (extension == "Request DOC report") {
      FileExtension = "DOC";
    }
    this.GeneratePortfolioReport(
      FileExtension.toString(),
      this.mySelection.toString()
    );
  }

  getAssetDetails(data) {
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res["DateFormat"] + " " + res["TimeFormat"];
        this.Dateformat = res["DateFormat"];
        this.GridCurrencyChange = res['Currency'];
      }
    });

    this.DataService.GetAssetReport(data).subscribe((res) => {
      this.assetList = res;
      this.assetList.forEach((ele) => {
        ele["assetCaptureDate"] =
          ele["assetCaptureDate"] == "0001-01-01T00:00:00"
            ? new Date("0001-01-01T00:00:00+00:00")
            : new Date(ele["assetCaptureDate"]);
        ele["updationDate"] =
          ele["updationDate"] == "0001-01-01T00:00:00+00:00"
            ? new Date("0001-01-01T00:00:00+00:00")
            : new Date(ele["updationDate"]);
        ele["creationDate"] =
          ele["creationDate"] == "0001-01-01T00:00:00+00:00"
            ? new Date("0001-01-01T00:00:00+00:00")
            : new Date(ele["creationDate"]);
        ele["updationDateYear"] = new Date(ele["updationDate"]).getFullYear();
        ele["creationDateYear"] = new Date(ele["creationDate"]).getFullYear();
        ele["assetCaptureDateYear"] = new Date(
          ele["assetCaptureDate"]
        ).getFullYear();
      });

      this.assetList = this.assetList;
      this.gridData = process(this.assetList, this.state);

      this.mapData = this.assetList;
      this.allData = this.allData.bind(this);

      // console.log(this.gridData)
      //FOR TESTING
      this.fields = Object.keys(this.assetList[0]);
      this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
    });
  }

  IsReportOptionsEnabled: boolean = false;
  IsImageOptionsDisabled: boolean = false;
  IsAssetFieldOptionsDisabled: boolean = true;
  IsEmailRecipientDisabled: boolean = false;

  GeneratePortfolioReport(extension, assetID) {
    this.portfolioReportEntity = new PortfolioReportEntity();
   this.portfolioReportEntity.language=this.LanguageLiteral;
    this.portfolioReportEntity.reportType = this.ReportType;
    this.portfolioReportEntity.format = extension;
    this.portfolioReportEntity.assetID = assetID;
    this.portfolioReportEntity.status = "Queued";
    if (this.ReportFilter.customerID != null) {
      this.portfolioReportEntity.customerID = this.ReportFilter.customerID;
    }else{
      this.portfolioReportEntity.customerID = 0;
    }
    if (this.ReportFilter.customerID != null&&this.ReportFilter.customerID != undefined) {
      this.portfolioReportEntity.locationID = this.ReportFilter.locationID;
    }else{
      this.portfolioReportEntity.locationID = 0;
    }
    this.portfolioReportEntity.state = this.ReportFilter.state;
    this.portfolioReportEntity.conditionRating =
      this.ReportFilter.conditionRating;
    this.portfolioReportEntity.assetStatus = this.ReportFilter.status;
    this.portfolioReportEntity.assetCategory = this.ReportFilter.assetGroup;
    this.portfolioReportEntity.criticality = this.ReportFilter.criticality;
    this.portfolioReportEntity.ownership = this.ReportFilter.assetOwnership;
    this.portfolioReportEntity.ageGroup = this.ReportFilter.ageGroup;
    this.portfolioReportEntity.filtersFitted = this.ReportFilter.filtersFitted;
    this.portfolioReportEntity.replacementCost =
      this.ReportFilter.replacementCost;
    this.portfolioReportEntity.remainingLife = this.ReportFilter.remainingLife;
    this.portfolioReportEntity.refrigerant = this.ReportFilter.refrigerant;
    this.portfolioReportEntity.replacementYear =
      this.ReportFilter.replacementYear;
    this.portfolioReportEntity.assetType = this.ReportFilter.assetTypeID;
    this.portfolioReportEntity.ohsComments = this.ReportFilter.OHSComments;
    this.portfolioReportEntity.assetSortBy = this.ReportFilter.sortBy;
    this.portfolioReportEntity.assetFields = "";
    if (
      this.ReportFilter != undefined &&
      this.ReportFilter.capacityStart != null &&
      this.ReportFilter.capacityStart != undefined
    ) {
      this.portfolioReportEntity.capacity =
        this.ReportFilter.capacityStart + "," + this.ReportFilter.capacityEnd;
    } else {
      this.portfolioReportEntity.capacity = undefined;
    }

    if (
      this.ReportFilter != undefined &&
      this.ReportFilter.startDate != null &&
      this.ReportFilter.startDate != undefined
    ) {
      this.portfolioReportEntity.creationDateRange =
        this.ReportFilter.startDate + "," + this.ReportFilter.endDate;
    } else {
      this.portfolioReportEntity.creationDateRange = undefined;
    }

    if (this.ReportFilter != undefined &&
      this.ReportFilter.installStartDate != null &&
      this.ReportFilter.installStartDate != undefined &&
      this.ReportFilter.installStartDate != ''
    ) {
      this.portfolioReportEntity.installDateRange =
        this.ReportFilter.installStartDate +
        "," +
        this.ReportFilter.installEndDate;
    } else {
      this.portfolioReportEntity.installDateRange = undefined;
    }
    //NEW CHANGES NEEDS TO BE ADDED
    //DAILOG POP-UP GeneratePortfolioReport
    this.IsReportOptionsEnabled = true;
    return;
  }

  portfolioReportEntityEvent(event) {
    console.log(event);

    this.portfolioReportEntity = event;
    this.IsReportOptionsEnabled = false;
    this.DataService.SavePortolioReport(this.portfolioReportEntity).subscribe(
      (response) => {
        if (response != undefined && response != null) {
          this.notify.success(this.ErrorMsgData.reportGenerate);
        } else {
          this.notify.warning(this.ErrorMsgData.noDataAvailable);
        }
      }
    );
  }

  RefreshData() {
    this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
    this.getAssetDetails(this.ReportFilter);
    // })
  }
  DataLength: any;
  public allData(): ExcelExportData {
    if (this.state.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.assetList, {
          sort: [{ field: "userAssetID", dir: "asc" }],
        }).data,
      };
      // console.log(result.data)
      this.DataLength = result.data.length;
      this.assetListExport = result.data;
      return result;
    } else {
      this.state1.filter.filters = this.state.filter.filters;

      const result: ExcelExportData = {
        data: process(this.assetList, this.state1).data,
      };
      // console.log(result.data)
      this.assetListExport = result.data;
      this.DataLength = result.data.length;
      return result;
    }
  }

  onTabchange(event) {
    if (event.index == 1) {
      this.initialization();
    }
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.gridData = process(this.assetList, this.state);
    console.log(this.assetList);

    if (this.gridData.data.length > 0) {
      this.stateMap.filter = state.filter;
      this.stateMap.group = state.group;
      this.stateMap.skip = state.skip;
      this.stateMap.sort = state.sort;
      // delete this.stateMap.take;
      var newMapData = process(this.assetList, this.stateMap);
      this.mapData = newMapData.data;
    } else {
      this.mapData = [];
    }
  }

  clearfilter() {
    this.state = this.clear_state;
    this.gridData = process(this.assetList, this.clear_state);
  }

  MenuOnSelect(event) {
    var baseUrl =
      window.location.origin + "/AddAssets/" + event.dataItem.assetID;

    if (event.item == "Open") {
      window.location.href = baseUrl;
    } else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    } else if (event.item == "Open in new window") {
      window.open(
        baseUrl,
        "_blank",
        "location=yes,height=600,width=1200,scrollbars=yes,status=yes"
      );
    } else {
    }
  }

  //--- Start : Map Code Block---
  //Initializing the Google Map for 1st Use
  initialization() {
    //Getting the Properties of Map
    if (this.mapData.length > 0) {
      var center = new google.maps.LatLng(
        this.mapData[0].geoLocation.split(",")[0],
        this.mapData[0].geoLocation.split(",")[1]
      );

      this.map = new google.maps.Map(document.getElementById("mapView"), {
        zoom: 3,
        center: center,
        mapTypeId: google.maps.MapTypeId.HYBRID,
      });
      this.refreshMap();
    } else {
      var center = new google.maps.LatLng("25.2744", "133.7751");

      this.map = new google.maps.Map(document.getElementById("mapView"), {
        zoom: 13,
        center: center,
        mapTypeId: google.maps.MapTypeId.HYBRID,
      });
    }
  }
  // refresh Map Code
  refreshMap() {
    var bounds = new google.maps.LatLngBounds();
    var markerImage = new google.maps.MarkerImage(
      this.imageUrl,
      new google.maps.Size(24, 32)
    );
    var infowindow = new google.maps.InfoWindow();
    this.markerClusters = new Array<MarkerClusterer>();

    //Spider Code
    this.oms = new OverlappingMarkerSpiderfier(this.map, {
      markersWontMove: true,
      markersWontHide: false,
      keepSpiderfied: true,
    });
    var spider = "images/pointer.png";
    var spidermarker = "images/mark.png";
    var iconWithColor = function (color) {
      return "http://chart.googleapis.com/chart?chst=d_map_pin_letter_withshadow&chld=C|FF0000|0000FF";
    };
    var shadow = new google.maps.MarkerImage(
      "https://www.google.com/intl/en_ALL/mapfiles/shadow50.png",
      new google.maps.Size(37, 34), // size   - for sprite clipping
      new google.maps.Point(0, 0), // origin - ditto
      new google.maps.Point(10, 34) // anchor - where to meet map location
    );
    let that = this;

    for (var i = 0; i < this.mapData.length; i++) {
      if (
        this.mapData[i].geoLocation != undefined &&
        this.mapData[i].geoLocation != null &&
        this.mapData[i].geoLocation != ""
      ) {
        let latt = this.mapData[i].geoLocation.split(",")[0];
        let longg = this.mapData[i].geoLocation.split(",")[1];

        if (
          latt != undefined &&
          latt != null &&
          latt != "" &&
          longg != undefined &&
          longg != null &&
          longg != ""
        ) {
          var latLng = new google.maps.LatLng(latt, longg);
          var newmarker = new google.maps.Marker({
            position: latLng,
            draggable: true,
            animation: google.maps.Animation.DROP,
            imagePath:
              "http://chart.googleapis.com/chart?chst=d_map_pin_letter_withshadow&chld=C|FF0000|0000FF",
            metaData: this.mapData[i],

            map: this.map,
            index: i,
          });

          google.maps.event.addListener(
            newmarker,
            "dragstart",
            (function (clickedmarker, i) {
              return function () {
                var oldLat = this.getPosition().lat().toFixed(5);
                var oldLng = this.getPosition().lng().toFixed(5);
                this.lat = oldLat;
                this.long = oldLng;
              };
            })(newmarker, i)
          );

          google.maps.event.addListener(
            newmarker,
            "dragend",
            (function (clickedmarker1, i) {
              return function () {
                if (confirm("Do you want to change the location?")) {
                  let newLat = this.getPosition().lat().toFixed(5);
                  let newLng = this.getPosition().lng().toFixed(5);
                  that.AssetGeoLocationUpdate.AssetID =
                    clickedmarker1.metaData.assetID;
                  that.AssetGeoLocationUpdate.GeoLocation =
                    newLat + "," + newLng;

                  that.DataService.UpdateGeoLocationByAsset(
                    that.AssetGeoLocationUpdate
                  ).subscribe((res) => {
                    if (res != undefined) {
                      that.mapData.forEach((ele) => {
                        if (ele.assetID == clickedmarker1.metaData.assetID) {
                          ele.geoLocation = newLat + "," + newLng;
                        }
                      });
                    }
                  });
                } else {
                  clickedmarker1.setPosition(
                    new google.maps.LatLng(this.lat, this.long)
                  );
                }
              };
            })(newmarker, i)
          );

          //listener event when Click on a marker
          google.maps.event.addListener(
            newmarker,
            "click",
            (function (clickedmarker, i) {
              return function () {
                var AssetID = "",
                  CustomerID = "",
                  customerAssetID;
                if (clickedmarker.metaData.assetID)
                  AssetID =
                    clickedmarker.metaData.assetID == undefined ||
                    clickedmarker.metaData.assetID == null ||
                    clickedmarker.metaData.assetID == ""
                      ? "NA"
                      : clickedmarker.metaData.assetID;

                customerAssetID =
                  clickedmarker.metaData["customerAssetID"] == null ||
                  clickedmarker.metaData["customerAssetID"] == undefined
                    ? ""
                    : clickedmarker.metaData["customerAssetID"];
                let currOrigin = window.location.origin;
                // infowindow.setContent('<p><b>Asset Details</b></p> User Asset ID : <a href="' + currOrigin + '/#/AddAssets/' + clickedmarker.metaData.assetID + '" target="_blank" style="color:#007bff !important">' + clickedmarker.metaData.userAssetID + '</a> <br> Customer Asset ID  : ' + customerAssetID);
                infowindow.setContent(
                  '<p><b>Asset Details</b></p> User Asset ID : <a href="' +
                    currOrigin +
                    "/AddAssets/" +
                    clickedmarker.metaData.assetID +
                    '" target="_blank" style="color:#007bff !important">' +
                    clickedmarker.metaData.userAssetID +
                    "</a> <br> Customer Asset ID  : " +
                    customerAssetID
                );
                infowindow.open(this.map, clickedmarker);
              };
            })(newmarker, i)
          );
          bounds.extend(newmarker.getPosition());
          this.map.fitBounds(bounds);
          this.markerClusters.push(newmarker);
          // return newmarker
        }
      }
    }

    var clusterStyles = [
      {
        height: 42,
        width: 42,
      },
    ];
    var markerClusterer = new MarkerClusterer(this.map, this.markerClusters, {
      ac: clusterStyles,
      gridSize: 50,
      maxZoom: 17,
      ignoreHidden: true,
      imagePath: "../../../assets/m",
    });

    //Showing Spider
    this.oms.addListener("spiderfy", function (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setIcon(spidermarker);
        markers[i].setShadow(shadow);
      }
    });

    //Hide Spider
    this.oms.addListener("unspiderfy", function (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setIcon(spider);
        markers[i].setShadow(shadow);
      }
    });

    this.oms = new OverlappingMarkerSpiderfier(this.map);
    this.markerClusters = new MarkerClusterer(this.map, this.markerClusters, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
  }
  moveMarker(map, marker, lat, long) {
    //delayed so you can see it move
    setTimeout(function () {
      marker.setPosition(
        new google.maps.LatLng(parseFloat(lat), parseFloat(long))
      );
      map.panTo(new google.maps.LatLng(parseFloat(lat), parseFloat(long)));
    }, 100);
  }

  labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  labelIndex = 0;

  //Setting location
  setLocation(locationData) {
    //api is not available yet
  }
  //--- End : Map Code Block

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
    this.subscription.unsubscribe();
    this.ReportFilter = new ReportFilter();
    let filter$ = new ReportFilter();
    filter$.customerID =
      this.sharedDataService.ReportFilterSharedData["customerID"];
    filter$.locationID =
      this.sharedDataService.ReportFilterSharedData["locationID"];
    // this.sharedDataService.ReportFilterBeh$ = new BehaviorSubject<ReportFilter>(
    //   this.ReportFilter
    // );
    this.sharedDataService.ReportFilterSharedData = filter$;
  }
  closeExpenseDashboard(){
    this.ExpenseDashboardOpened=false
  }

  openExpenseDashboard(AssetID){
    this.AssetID=AssetID
    console.log(AssetID)
    this.ExpenseDashboardOpened=true
  }
}
