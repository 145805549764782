export class UserEntity{
  public userID?: number;
  public emailID: string;
  public firstName: string;
  public lastName: string;
  public companyID: string;
  public roleTypeCd: number;
  public employee: boolean;
  public password: string;
  public showJobHistory: boolean;
  public allowAssetDownload: boolean;
  public active: boolean;
  public customer: string;
  public multipleConcurrentSession: number;
  public locationsID: Array<number>=[];
  public assetGroupID:Array<number>= [];
  public managerID:number;
  public passEncriptCode:string;
  public inventoryManagement :boolean;
  public rmManagement :boolean;
  public allowReportAccess :boolean;
  public stateCD:string;
  
}
export class LinkLocation {
public locationID: number;
public locationName: string;
public customerID: number;
public customerName: string;
}

export class UserAdminListingEntity{
public createdByName: string;
public updatedByName: string;
public creationDate: Date;
public updationDate: Date;
}
export class ManagerEntity {
userID: number;
name: string;
}
export class LinkLocationRequest {
public userID: number;
public locationIDs: string;
}
export class CompanyEntity{
public name: string;
public companyID: string;
}
export class SendEmailEntity {
public Data: any;           //Email Template 3 type Name, Key-Value Pair
public EmailType: string;   //"Welcome"
public EmailTo: string;     //User Create/update
}
//Example :
// {
//   "data":{
//       "FullName":"Manish Sharma",
//       "EmailID":"ermanisharma7@gmail.com",
//       "Password":"1234567"
//   },
//   "EmailType":"Welcome",
//   "EmailTo":"ermanisharma7@gmail.com"
//   }


export class AssetGroupDDLModel{
public assetGroupID :number;
public groupName : string;
public assetClassID :number;
}
