import { SnotifyService } from 'ng-snotify';
import { Component, OnInit, ViewChild } from '@angular/core';
import { emailConfiguration, EmailAdminListingEntity } from '../email-configuration/email-configuration.model';
import { CoreDataService } from '../../Service/core-data.service';
import { SharedDataService } from '../../Service/CommonUtility.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AdminEntity } from '../../Common/shared-model';
import { EditorComponent } from '@progress/kendo-angular-editor';
@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent implements OnInit {
  LanguageConfig: any;
  isCancelclicked: boolean = false;
  ckeConfig: any;
  ErrorDataMsg: any;
  mycontent: string;
  log: string = '';
  dataFieldsData: Array<string>;
  valid: boolean = true;
  @ViewChild('editor',{ static: false }) editor: EditorComponent;
  emailConfigModel: emailConfiguration = new emailConfiguration();
  EmailAdminListingEntity: EmailAdminListingEntity = new EmailAdminListingEntity();
  public ddlEmailConfigDefaultItem: string = "Select";
  emailConfiguration: Array<any> = [];
  IsAdmin: string;
  AdminEntity: AdminEntity = new AdminEntity();
  constructor(private titleService: Title, private notify: SnotifyService, private activatedRoute: ActivatedRoute, private router: Router, private coreDataService: CoreDataService, private sharedDataService: SharedDataService) {

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);

  }
  userAction: string = "Add Email Configuration";
  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[19];
          this.ErrorDataMsg = res[28];

        }
      })
    }
    this.ckeConfig = {
      allowedContent: true,
      attributes: true,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      specialChars: ['&lt;', '&gt;']
    };
    this.IsAdmin = this.sharedDataService.IsAdmin();


    if (this.activatedRoute.snapshot.paramMap.get('id') != null) {

      let EmailTemplateID: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
      if (EmailTemplateID != null && EmailTemplateID != undefined) {
        this.AdminEntity.Table = 'EmailTemplate'
        this.AdminEntity.AssociatedID = EmailTemplateID
        this.editEmailConfigDetailsByID(EmailTemplateID);
      }
    }

  }
  public onTabchange(e) {

    if (e.index == 1 && this.activatedRoute.snapshot.paramMap.get('emailTemplateID') != null) {
      this.AdminEntity.Table = 'EmailTemplate'
      this.AdminEntity.AssociatedID = this.activatedRoute.snapshot.paramMap.get('emailTemplateID')
    }

  }

  saveEmailConfig(value: any, valid: boolean) {

    if (valid == true) {
      let body = this.emailConfigModel.body;

      body = body.replace(new RegExp('&gt;', 'g'), '>');
      body = body.replace(new RegExp('&lt;', 'g'), '<');

      this.emailConfigModel.body = body;
      this.coreDataService.AddUpdateEmailConfiguration(this.emailConfigModel).subscribe((res) => {

        if (res != undefined && res != null) {

          if (res != undefined && res != null) {

            this.notify.success(this.ErrorDataMsg.emailTypeSaved);
            this.router.navigate(['/email-configuration-listing']);
          }
        }

      })
    }

  }
  DataFieldChange(value) {
    if (value != undefined && value != null && value !== "Select") {
      this.editor.exec('insertText', { text: `{{${value}}}` });
    this.editor.value = this.editor.value.replace('#CURSOR#', value);
    }

  }
  editEmailConfigDetailsByID(EmailTemplateID) {

    this.coreDataService.GetEmailConfigurationDetailsByID(EmailTemplateID).subscribe((data) => {
      if (data != undefined && data != null) {

        this.emailConfigModel.emailTemplateID = data.emailTemplateID;
        this.emailConfigModel.body = data.body;
        this.emailConfigModel.subject = data.subject;
        this.emailConfigModel.type = data.type;
        this.dataFieldsData = data.dataFields.split(';');
      }
    })

  }

  back() {
    this.router.navigate(['/email-configuration-listing']);
  }
  // cancel PopUp
  cancelledClick() {
    this.isCancelclicked = true;

  }
  close() {
    this.isCancelclicked = false;
  }

}
