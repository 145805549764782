import { Component, Input, OnInit } from '@angular/core';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { ExpenseDashboard, InetData } from './expense-dashboard.model';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import * as FileSaver from "file-saver";
import htmlToImage from "html-to-image";
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';




@Component({
  selector: 'app-expense-dashboard',
  templateUrl: './expense-dashboard.component.html',
  styleUrls: ['./expense-dashboard.component.scss']
})
export class ExpenseDashboardComponent implements OnInit {

  @Input()  AssetID: number;
  public expenseDashboard: ExpenseDashboard =new ExpenseDashboard() ;
  ExpenseDashboardChartData :InetData[]=[]

  public Data: Array<{ text: string, value: number }> = [];
  LanguageConfig: any;
  ErrorMsgData: string;
  DateTimeformat: string;
  Currency: string;
  CurrencySymbol: string;
  public legendLabelTemplate = " #= label #";

  constructor(private DataService:CoreDataService, private sharedDataService: SharedDataService ) {
    
   
  }



  public labelContent(e: any): string {
    if(e.category!=""){
    return e.category+":" +e.value+" ("  +(e.percentage*100).toFixed(2) +'%)';
    }
    else{
      return ''
    }
  }

  public chartLegend = {
    labels: {
      template: (args: LegendLabelsContentArgs) => {
        return  args.dataItem.label; // Customize the legend value here
      }
    }
  };

  ngOnInit() {
    

    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] 
        this.Currency = res['Currency'];
        this.CurrencySymbol=res['CurrencySymbol']
      }
    })
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined && res!='') {

          this.LanguageConfig = res[68];
          this.ErrorMsgData = res[28];
          this.getExpenseDashboardData(this.AssetID)
        }
      })
     
    }
  
  }

  getExpenseDashboardData(AssetID) {
    this.expenseDashboard.installDate
    this.DataService.getExpenseDashboardData(
      AssetID
    ).subscribe((res) => {
      this.expenseDashboard = res;
     this.expenseDashboard.replacementYear= this.expenseDashboard.plannedReplacementYear.toString()=='0001-01-01T00:00:00' ?this.expenseDashboard.calculatedReplacementYear:new Date(this.expenseDashboard.plannedReplacementYear).getFullYear()
     this.expenseDashboard.plannedRepacement =this.expenseDashboard.plannedReplacementYear.toString()=='0001-01-01T00:00:00' ?undefined:new Date(this.expenseDashboard.plannedReplacementYear).getFullYear()
      this.ExpenseDashboardChartData.push({name:"",data:[{category : this.LanguageConfig ==undefined ?'Expended':this.LanguageConfig.Expended,value :this.expenseDashboard.totalSpend,color:'#00b0f0' ,label:this.LanguageConfig ==undefined ?'Total Spend (Expended)': this.LanguageConfig.TotalSpendExpendedLabel},
      {category : this.LanguageConfig ==undefined ?'Planned R&M expense':this.LanguageConfig.PlannedRMExpense,value :this.expenseDashboard.rmExpense,color:'yellow' ,label:this.LanguageConfig ==undefined ?'RM Budget from Year 1 to Replacement year': this.LanguageConfig.RMBudgetfromYear1toReplacementyearLabel},
      {category : "",value :(this.expenseDashboard.rmExpense+this.expenseDashboard.rmBudgetCost+this.expenseDashboard.replacementCost-this.expenseDashboard.totalSpend),color:'#9a9a9a' ,label:''}
    ]})
     this.ExpenseDashboardChartData.push({name:"",data:[{category : this.LanguageConfig ==undefined ?'RM Total Budget':  this.LanguageConfig.RMTotalBudget,value :(this.expenseDashboard.rmBudgetCost+this.expenseDashboard.rmExpense),color:'#92d050',label:this.LanguageConfig ==undefined ?'RM Budget from installation year to Replacement year': this.LanguageConfig.RMBudgetfromInstallationYearToReplacementYearLabel },
     {category :this.LanguageConfig ==undefined ?'Capex': this.LanguageConfig.Capex,value :this.expenseDashboard.replacementCost,color:'#ffc000',label:this.LanguageConfig ==undefined ?'Replacement Cost (Cap ex)': this.LanguageConfig.ReplacementCostCapExLabel }]})

    });
  }

  exportAsImage(type){
    var node = document.getElementById(type);
    htmlToImage
        .toJpeg(node,{backgroundColor:'white'})
        .then(function (dataUrl) {
          FileSaver.saveAs(dataUrl, type + ".jpeg");
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
  }

}
