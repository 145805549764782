import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

   
  //help page authentication on mobile 
    if(route.routeConfig.path == 'help' && route.fragment !== null){
      if((route.fragment.includes('/p=') != undefined || route.fragment.includes != null)&& route.fragment.includes('/p=') == true ){
        let tokenData:any = route.fragment.replace('/p=', '')  
        this.authService._setSession({'access_token':tokenData});
        setTimeout(() => {
         this.router.navigateByUrl('/help');
        }, 800);
      }
      //

    }

    if (this.authService.authenticated) {
      return true;
    } else {
      // this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      this.router.navigateByUrl('');
      return false;
    }
  }
}
