import { SnotifyService } from 'ng-snotify';
import { Component, Input, OnInit } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService } from '../../Service/CommonUtility.service'
import { Router, ActivatedRoute } from "@angular/router";
import { AssetJobListingEntity, AssetJobDetailsListingEntity } from './asset-jobs.model';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-asset-jobs',
  templateUrl: './asset-jobs.component.html',
  styleUrls: ['./asset-jobs.component.scss']
})
export class AssetJobsComponent implements OnInit {
  LanguageConfig: any;
  ErrMessagesData: any;

  @Input()  AssetID: number;
 
  GridConfig: any;
  IsCustomer: string;
  constructor(private DataService: CoreDataService,
    private notify: SnotifyService,
    private activatedRoute: ActivatedRoute, private router: Router, private sharedDataService: SharedDataService) { }
  AssetJobListingEntity: AssetJobListingEntity[] = [];
  AssetJobListingEntityObject: AssetJobListingEntity = new AssetJobListingEntity();
  AssetJobDetailsListingEntity: AssetJobDetailsListingEntity = new AssetJobDetailsListingEntity();
  dialogOpened:boolean;
  Dateformat: any;
  DateTimeformat: any;
  public formGroup: FormGroup = new FormGroup({
    assetJobID: new FormControl(),
    jobNumber: new FormControl(),
    workDescription: new FormControl(),
    state: new FormControl(),
    technician: new FormControl(),
    problemType: new FormControl(),
    resolution: new FormControl(),
    equipmentID: new FormControl(),
    completionDate: new FormControl(),
    callType: new FormControl(),
    status: new FormControl(),
    addressCode: new FormControl(),
    gpCustomerID: new FormControl(),
    priority: new FormControl(0, [Validators.min(0), Validators.max(100)]),
    creationDate: new FormControl(),
    purchaseorder: new FormControl(),
    requestedBy: new FormControl(),
    spend_ExGST: new FormControl(),
    barcode: new FormControl(),
    customerName: new FormControl(),
  });
  public DeleteAssetJobID:number=0;
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    
  };
  public stateMap: State = {
    skip: 0,
    sort: [{ field: "active", dir: "desc" }],
  };
  
  public gridData: GridDataResult;

  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.AssetJobListingEntity, sort), this.state);
  }
  public opened: boolean = false;
  public isEdit :boolean=false;
  public IsJobHistory:boolean=true;

  public close(status) {
    this.opened = false;
  }

  public open(data) {

    this.AssetJobListingEntityObject = data
    this.opened = true;
  }
  GridCurrencyChange: string;
  ngOnInit() {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[64];
          this.GridConfig = res[2];
          this.ErrMessagesData = res[28];
         
         
        }
      });
    }

    // this.sharedService.languageConfig$.subscribe(res => {
    //   if (res != undefined) {
    //     this.LanguageConfig = res[7];
    //     this.ErrMessagesData = res[28];
    //   }
    // })
    this.IsCustomer = this.sharedDataService.IsCustomer();
   this.getAssetJobs();
  }
  getAssetJobs(){
    if (this.AssetID != null) {

      
      this.DataService.getAssetJob(this.AssetID).subscribe(res => {
       
        this.AssetJobListingEntity = res
        this.AssetJobListingEntityObject = res;
        let NumArr: Array<number> = new Array<number>();
        res.forEach(element => {
          element['completionDate']=new Date( element['completionDate'])
          NumArr.push(element['spend_ExGST'])
        });
        this.AssetJobListingEntity.forEach(ele => {
          ele['creationDate'] =  new Date(ele['creationDate'])
        })

        this.AssetJobListingEntityObject.spend_ExGST = NumArr.reduce((a, b) => a + b, 0)


        // this.DateTimeformat = this.sharedDataService.dateTimeFormat();
        // this.Dateformat = this.sharedDataService.dateFormat();
        this.sharedDataService.GetOrgSettings().then((res) => {
          if (res != null) {
            this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
            this.Dateformat = res['DateFormat'];
            this.GridCurrencyChange = res['Currency'];
            console.log(this.GridCurrencyChange)
          }
        })



        this.gridData = process(this.AssetJobListingEntity, this.state);
        this.allData = this.allData.bind(this);
        this.getJobDetails(this.AssetID);

      })
    }
    else{
     this.IsJobHistory=false;
      this.DataService.getAssetJobs().subscribe(res => {
        this.AssetJobListingEntity = res
        this.AssetJobListingEntity.forEach(ele => {
          ele['creationDate'] =  new Date(ele['creationDate'])
        })
        this.AssetJobListingEntityObject = res;
        this.AssetJobListingEntity.forEach(ele => {
          ele['creationDate'] =  new Date(ele['creationDate'])
        })
        let NumArr: Array<number> = new Array<number>();
        res.forEach(element => {
          element['completionDate']=new Date( element['completionDate'])
          NumArr.push(element['spend_ExGST'])
        });

        this.AssetJobListingEntityObject.spend_ExGST = NumArr.reduce((a, b) => a + b, 0)


        // this.DateTimeformat = this.sharedDataService.dateTimeFormat();
        // this.Dateformat = this.sharedDataService.dateFormat();
        this.sharedDataService.GetOrgSettings().then((res) => {
          if (res != null) {
            this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
            this.Dateformat = res['DateFormat'];
            this.GridCurrencyChange = res['Currency'];
            console.log(this.GridCurrencyChange)
          }
        })


        this.allData = this.allData.bind(this);
        this.gridData = process(this.AssetJobListingEntity, this.state);
  
       

      })
    }
  }

  getJobDetails(Id) {
    this.DataService.getAssetJobDetails(Id).subscribe(resp => {
      this.AssetJobDetailsListingEntity = resp
      this.AssetJobDetailsListingEntity.noOfServiceJobs = this.AssetJobListingEntity.length;
      this.AssetJobDetailsListingEntity.totalSpend =this.AssetJobListingEntityObject.spend_ExGST;
    })
  }
  public dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.AssetJobListingEntity, this.state);

  }
  
  public delete(data){
    this.dialogOpened=true;
    this.AssetJobListingEntityObject = data
    this.DeleteAssetJobID=this.AssetJobListingEntityObject.assetJobID;
   
  }
  edit(data){
    this.AssetJobListingEntityObject = data
    this.formGroup.patchValue(this.AssetJobListingEntityObject)
    //this.isEdit=true
    
    
        // document.getElementById(LocationID)['href'] = window.location.origin + '/#/add-department/' + LocationID;
        document.getElementById(this.AssetJobListingEntityObject.assetJobID.toString())['href'] = window.location.origin + '/add-asset-job/' + this.AssetJobListingEntityObject.assetJobID;
  
      
    
  }
  closeEdit(){
    this.isEdit=false;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.AssetJobListingEntity, {
      
        sort: [{ field: "active", dir: "desc" }],
      }).data,
      
    };

    return result;
  }
 
  AddAssetJob(){
   
   this.formGroup= new FormGroup({
    assetJobID: new FormControl(0),
    jobNumber: new FormControl(),
    workDescription: new FormControl(),
    state: new FormControl(),
    technician: new FormControl(),
    problemType: new FormControl(),
    resolution: new FormControl(),
    equipmentID: new FormControl(),
    completionDate: new FormControl(),
   
    callType: new FormControl(),
    status: new FormControl(),
    addressCode: new FormControl(),
    gpCustomerID: new FormControl(),
    priority: new FormControl(0, [Validators.min(0), Validators.max(100)]),
    creationDate: new FormControl(new Date()),
    purchaseorder: new FormControl(),
    requestedBy: new FormControl(),
    spend_ExGST: new FormControl(),
    barcode: new FormControl(),
    customerName: new FormControl(),
  });
    this.isEdit=true

  }
  closeDeleteDialog(){
    this.dialogOpened=false
  }
  deleteAssetJob(){

    this.DataService.DeleteAssetJobs( this.DeleteAssetJobID).subscribe(
      (res) => {
        this.getAssetJobs();
        this.DeleteAssetJobID=0;
        this.dialogOpened=false
        this.notify.success(this.ErrMessagesData.deletedSuccessfully);
      },
      (catchError) => {
        this.notify.warning(this.ErrMessagesData.SomethingWentWrong);
      }
    );

  }

}
