import { Component } from '@angular/core';
@Component({
  selector: "app-response-password-reset",
  template: `

<div class="messageBox">
  <div class="messageBoxHeader bg-01"><span class="icon fa fa-check"></span></div>
  <h1 style="color:#17adcc;text-align:center"> Success </h1>
  <p>Your password updated successfully. Open Evaluate App for login.</p>
</div>
  `,
  styles: [`
  body {
    background: #F1F1F1;
    font-family: 'Roboto', sans-serif;
    cursor: default;
  }

  h1 {
    color: #666;
    font-weight: 300;
    text-align:center;
  }


  .messageBox {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    display: block;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
    width: 300px;
    background-color: #fff;
    padding-bottom: 20px;
  }

  .messageBox .messageBoxHeader {
    background: #3498db;
    width: 100%;
    height: 100px;
  }

  .messageBox .messageBoxHeader .icon {
    color: #fff;
    line-height: 100px;
    font-size: 48px;
    text-align:center;
    width: 100%;
  }

  .messageBox .btn {
    float: right;
    margin: 15px;
    color: #2980b9;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .messageBox .disabled {
    color: #666;
  }

  .messageBox .btn:hover {
    color: #3498db;
  }

  .messageBox .disabled:hover {
    color: #7f8c8d;
  }

  .messageBox h1 {
    margin: 15px !important;
    font-size: 24px;
    font-weight: normal;
    color: #333;
    text-align:left;
  }

  .messageBox p {
    margin: 15px;
    color: #888;
  }

  /* Backgrounds */
  .bg-01 {
    background-image: url("http://cdn.bgr.com/2014/12/ultimate-material-lollipop-collection-470.jpg?w=450") !important;
  }
  `]
})
export class ResponsePasswordResetMobile {
  constructor() {}
}
