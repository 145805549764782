import { Component, OnInit, Input, Output, OnChanges, SimpleChange, EventEmitter, SimpleChanges } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { SharedDataService, ReportFilter } from 'src/app/Service/CommonUtility.service';

import { CoreDataService } from '../../Service/core-data.service'
import { ActivatedRoute } from '@angular/router';
import { reportFilterTemplate } from './report-options.model';
import { Subscription } from 'rxjs';
import { projectProgressReport, CommonFilterEntity, PortfolioReportEntity, AssetReportFieldTemplateModel, QueueReportEntity, SchedulerReportEntity, ScheduleList } from '../../AccountManagement/Reports/reports.model';
import { BusinessManagerModel } from '../../AccountManagement/add-customer/add-customer.model';
import { environment } from "../../../environments/environment";
@Component({
  selector: 'app-report-options',
  templateUrl: './report-options.component.html',
  styleUrls: ['./report-options.component.css']
})
export class ReportOptionsComponent implements OnInit, OnChanges {

  @Input() portfolioReportEntity: PortfolioReportEntity;
  @Input() excelReportEntity: any;

  @Input() opened: boolean;
  

  @Input() IsImageOptionsDisabled: boolean;
  @Input() IsAssetFieldOptionsDisabled: boolean;
  @Input() IsEmailRecipientDisabled: boolean;

  @Output() portfolioReportEntityEvent = new EventEmitter<PortfolioReportEntity>();
  @Output() excelReportEntityEvent = new EventEmitter<any>();

  businessdevlopmentList: BusinessManagerModel[] = [];
  accountManagerID: any = null;
  isIncludeAllPhotos: boolean = false;
  isIncludeAllAssetFields: boolean = false

  LanguageConfig : any
  ErrorMsgData : any
  ViewSelectedAssetCount : any
  isPortfolio: boolean=true;

  constructor(private coredataservice: CoreDataService,private sharedDataService: SharedDataService) { }

  ngOnInit() {


    this.ViewSelectedAssetCount = environment.environment.SelectedAssetCount
    // console.log(environment.environment)
    this.opened = false
    var payload = { Roles: ['customer'] };
    // this.coredataservice.getUsersByRole(payload).first()
    //   .subscribe(response => {
    //     for (let key in response) {

    //       this.businessdevlopmentList.push({ userID: parseInt(response[key].userID), name: response[key].name })
    //     }
    //   });
      if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
        this.sharedDataService.languageConfig$.subscribe(res => {
          if (res != undefined) {
            this.LanguageConfig = res[5];
            this.ErrorMsgData = res[28];
          }
        })
      }
  }

  IsSelectedForReports: boolean = false
  IsSelectedForExportExcel: boolean = false
  ngOnChanges(changes: SimpleChanges) {

    //Add-ons if check
    const change = changes['portfolioReportEntity']
    if (change != undefined) {
      this.portfolioReportEntity = change.currentValue
      console.log(this.portfolioReportEntity)
      this.IsSelectedForReports = true
      this.IsSelectedForExportExcel = false
      this.IsImageOptionsDisabled = false
      this.IsAssetFieldOptionsDisabled = true
      this.IsEmailRecipientDisabled = false
      //selected asset count
      if(this.portfolioReportEntity.assetID != undefined || this.portfolioReportEntity.assetID != null){
        this.SelectedAssetCount = this.portfolioReportEntity.assetID.split(',').length
      }
      //selected asset count
    }
    else {
      this.IsImageOptionsDisabled = true
      this.IsAssetFieldOptionsDisabled = false
      this.IsEmailRecipientDisabled = true
      this.IsSelectedForReports = false
      this.IsSelectedForExportExcel = true
    }
    //Add-ons if check

    if (changes["opened"] == undefined || changes["opened"].previousValue != undefined) {
      if (changes["opened"] == undefined) {
        this.opened = true;
      }
      else if (changes["opened"] != undefined) {
        this.opened = (changes["opened"].previousValue == true) ? false : true;
      }
      if (changes["excelReportEntity"] != undefined) {
        let DataLength = changes["excelReportEntity"]["currentValue"]["DataLength"]
        //environment.environment.DownloadAssetCount
        if (DataLength > environment.environment.DownloadAssetCount) {
          this.opened = false
        }
      }
      this.accountManagerID = null
      this.isIncludeAllPhotos = false
      this.isIncludeAllAssetFields = false
    }

  }

  IsValidEmailRecipients : boolean = true;
  SelectedAssetCount : any; 

  DisableValidation(){
    this.IsValidEmailRecipients = true
  }

  public close(status,obj) {
    if(obj!=null){
      this.portfolioReportEntity =obj
     
      this.isPortfolio=false
    }
    console.log(`Dialog result: ${status}`);
    if (status == 'yes') {
      console.log(this.accountManagerID)

      //Add-ons if check
      if (!this.IsImageOptionsDisabled && !this.IsEmailRecipientDisabled) {
        if (this.accountManagerID != undefined || this.accountManagerID != null) {
          this.portfolioReportEntity.emailRecipients = this.accountManagerID.toString()
        }        
        this.portfolioReportEntity.isIncludeAllPhotos = this.isIncludeAllPhotos
        this.opened = false;
        console.log(document.getElementById('accountID'))
        let k = document.getElementById('accountID')
        //Extra fields validations
        if (this.accountManagerID != null && this.accountManagerID != "") {
          let value = document.getElementById('accountID')['value']
          if (!(/^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}(?:,[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}){0,11}$/.test(value))) {
            // alert("Please enter a valid price");
            document.getElementById('accountID').focus();
            this.IsValidEmailRecipients = false
            this.opened = true;
            return ;
          }
        }
        if(this.SelectedAssetCount > environment.environment.SelectedAssetCount){
          this.opened = true;
          return;
        }

        //Extra fields validations
        this.portfolioReportEntityEvent.emit(this.portfolioReportEntity)
      }
      else {
        console.log(this.excelReportEntity)
        this.excelReportEntity.IsOpened = false;
        this.excelReportEntity.isIncludeAllAssetFields = this.isIncludeAllAssetFields;
        this.excelReportEntity = this.excelReportEntity
        this.excelReportEntityEvent.emit(this.excelReportEntity)
      }

      //Add-ons if check
    }
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }
  public onTabSelect(e) {
    console.log(e);

    // console.log(this.portfolioReportEntity)
  }

}
