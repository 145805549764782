import { Injectable } from '@angular/core';;
import { Observable, from } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
declare var window: any;

@Injectable()
export class AppInitService {
  pathname: any = window.location.origin;
  appConfig: AppConfig = new AppConfig()
  webConfig:any;


  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
 public  async  init() {

  this.webConfig =await from(
      fetch('/assets/Web-Config.json').then(function (response) {
        return response.json();
      })
    ).toPromise();

    if (this.pathname != 'http://localhost:4200') {
      this.appConfig.Url=this.pathname
      this.appConfig.apiUrl = this.pathname + '/api/api/';
      this.appConfig.authUrl = this.pathname + '/api/';
      this.appConfig.reportBasePath = this.pathname + '/reports/';
      this.appConfig.prodApiUrl = this.pathname + '/api/';
      this.appConfig.attachmentPath = this.pathname + '/api/ImageRoot/Attachments/';
      this.appConfig.language = 'en';
      this.appConfig.DownloadAssetCount = '30000';
      this.appConfig.SelectedAssetCount = '10';
      this.appConfig.MicrosoftAuthentication=this.webConfig.MicrosoftAuthentication=="true"?true:false;
      this.appConfig.MicrosoftCredentials=this.webConfig.MicrosoftCredentials;
      return window.config = this.appConfig;
   }
    else {
      return from(
        fetch('/assets/app-config.json').then(function (response) {
          return response.json();
        })
      ).pipe(
        map((config) => {
          config.MicrosoftAuthentication=this.webConfig.MicrosoftAuthentication=="true"?true:false;
          config.MicrosoftCredentials=this.webConfig.MicrosoftCredentials;
          window.config = config;     
          return
        })).toPromise();
    }
  }
}

class AppConfig {
  public Url:any
  public apiUrl: any
  public authUrl: any
  public reportBasePath: any
  public prodApiUrl: any
  public attachmentPath: any
  public language: any
  public DownloadAssetCount: any
  public SelectedAssetCount: any
  public MicrosoftCredentials: MicrosoftCredentials;
  public MicrosoftAuthentication:boolean;

  
}
 class  MicrosoftCredentials { 
public clientId:string;
public authority:string;
}
