import { Component, OnInit, Input, SimpleChange, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { BookingEntity } from '../reserve-asset/reserve-asset.model';
import { SignaturePad } from 'angular2-signaturepad';
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService } from "../../Service/CommonUtility.service";
import { FormControl, FormGroup, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";
import { SnotifyService } from 'ng-snotify';
import { GridDataResult, DataStateChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
@Component({
  selector: 'app-booking-asset',
  templateUrl: './booking-asset.component.html',
  styleUrls: ['./booking-asset.component.css']
})
export class BookingAssetComponent implements OnInit {

  @Input() opened: boolean;
  @Input() assetID: number
  // @Input() bookingID : number
  @Input() bookingStatus: string
  bookingEntityModel: BookingEntity = new BookingEntity()
  reservedByList = []
  UserData: any
  UserDataList: any = []
  BookingStatus: any[] = []
  date: Date = null;
  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy HH:mm',
    defaultOpen: false
  }
  public format = 'dd-MM-yyyy';
  // public value: Date = new Date(2019, 5, 1, 22);
  // public format = 'MM/dd/yyyy HH:mm';
  @ViewChild(SignaturePad,{static:false}) signaturePad: SignaturePad;
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 2,
    'canvasWidth': 300,
    'canvasHeight': 200
  };
  private state: State = {
    skip: 0,
    take: 5,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  // public gridData: any[] = [];
  private gridData: GridDataResult;
  DateTimeformat: any;
  Dateformat: any;
  IsRefreshNeeded: boolean = false


  @Output() closeBookingEvent = new EventEmitter<any>();
  // constructor() { }
  constructor(private coredataservice: CoreDataService, private sharedDataService: SharedDataService, private notify: SnotifyService) { }

  ngOnInit() {
    // this.opened = false    
    this.UserData = this.sharedDataService.parseJwt(window.localStorage.getItem('token'))

    

    var payload = { Roles: ['customer'] };
    this.coredataservice.getUsersByRole(payload).first()
      .subscribe(response => {
        for (let key in response) {

          this.reservedByList.push({ userID: parseInt(response[key].userID), name: response[key].name })
        }
        this.UserDataList = this.reservedByList.filter((ele) => { return ele.userID == this.UserData.UserID })
        this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList
        this.bookingEntityModel.checkedOutBySelectedEmail = this.UserData.EmailID

      });

    this.getCodeData()
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
        this.Dateformat = res['DateFormat'];
        //CASE FOR DATE TIME FORMAT SET ON DATE/TIME FIELDS
        this.settings.format = this.DateTimeformat
        this.format = this.Dateformat
        //CASE FOR DATE TIME FORMAT SET ON DATE/TIME FIELDS
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {

    this.ResetModel()
    // Add Ons Check for open/close
    if (changes["opened"] == undefined || changes["opened"].previousValue != undefined) {
      if (changes["opened"] == undefined) {
        this.opened = true;
      }
      else if (changes["opened"] != undefined) {
        this.opened = (changes["opened"].previousValue == true) ? false : true;
        if (changes["assetID"] != undefined) {
          this.assetID = changes["assetID"].currentValue
          this.getBookingDetailsByAssetID(this.assetID)
        }
      }

    }

  }
  public close(status) {

    if (status == 'yes') {

      if (this.gridData != undefined && this.gridData.data.length > 0 && this.bookingEntityModel.bookingID == undefined) {
        let PreviousEndDatetimeValue = this.gridData.data[this.gridData.data.length - 1]['endDateTime']
        if (new Date(this.bookingEntityModel.startDateTime).getTime() > new Date(PreviousEndDatetimeValue).getTime() && new Date(this.bookingEntityModel.endDateTime).getTime() > new Date(PreviousEndDatetimeValue).getTime()) {
          console.log(PreviousEndDatetimeValue)
          console.log(this.bookingEntityModel)
          this.BookAsset(this.bookingEntityModel)
        }
        else {
          //Please select the future date to reserve the asset
          if (new Date(this.bookingEntityModel.startDateTime).getTime() > new Date(PreviousEndDatetimeValue).getTime()) {
            this.ReservedStartTime = true
          }
          if (new Date(this.bookingEntityModel.endDateTime).getTime() > new Date(PreviousEndDatetimeValue).getTime()) {
            this.ReservedEndTime = true
          }
          return;
        }
      }
      else {
        console.log(this.bookingEntityModel)
        //UDPATE WITH EXISTING DATA and new available data
        this.BookAsset(this.bookingEntityModel)
      }
    }

    if (status == 'cancel' || status == 'no') {
      this.opened = false
      this.closeBookingEvent.emit({ "opened": this.opened, "IsRefresh": this.IsRefreshNeeded })
    }
    // this.opened = false

  }

  public open() {
    this.opened = true;
  }
  getCodeData() {
    this.coredataservice.getCodeData().subscribe((res) => {
      if (res != undefined && res != null) {

        res.forEach(element => {
          if (element.codeName.toLowerCase() == "bookingstatuscd") {
            this.BookingStatus.push(element);
          }
        });
        console.log(this.BookingStatus)
      }
    });
  }

  getBookingDetailsByAssetID(AssetID) {
    if (AssetID != undefined || AssetID > 0) {
      this.coredataservice.GetBookingDetailsByAssetID(AssetID).subscribe((data: any) => {
        // console.log(data)
        this.gridData = null
        this.gridData = process(data, this.state);
        //CHECK FOR BOOKING STATUS      
         this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out'}) != undefined ?  'Checked Out' : 'Available' 
        //CHECK FOR BOOKING STATUS
      })
    }
  }

  bookingStatusName : any
  EditBookingDetails(bookingID) {
    console.log(this.gridData.data.find((ele) => { return ele.bookingID == bookingID }))
    let data: any = this.gridData.data.find((ele) => { return ele.bookingID == bookingID })
    if (data != undefined) {
      this.bookingEntityModel = new BookingEntity();
      this.bookingStatusName =  null
      this.bookingEntityModel.assetID = data.assetID
      this.bookingEntityModel.bookingID = data.bookingID
      this.bookingEntityModel.endDateTime = data.endDateTime
      this.bookingEntityModel.startDateTime = data.startDateTime
      this.bookingEntityModel.reservationNotes = data.reservationNotes
      this.bookingEntityModel.status = data.status
      this.bookingEntityModel.reservedBy = data.reservedBy
      this.UserDataList = this.reservedByList.filter((ele) => { return ele.userID == this.UserData.UserID })
      this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList
      this.bookingEntityModel.checkedOutBySelectedEmail = this.UserData.EmailID

      //CHECK FOR BOOKING STATUS   
      this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out'}) != undefined ?  'Checked Out' : 'Available'    
      this.bookingStatusName = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == data.bookingID}) != undefined ?  'Checked Out' : 'Available' 
      //CHECK FOR BOOKING STATUS
    }
  }



  ResetModel() {
    this.UserData = this.sharedDataService.parseJwt(window.localStorage.getItem('token'))
    this.bookingEntityModel = new BookingEntity();
    this.UserDataList = this.reservedByList.filter((ele) => { return ele.userID == this.UserData.UserID })
    this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList
    this.bookingEntityModel.checkedOutBySelectedEmail = this.UserData.EmailID

    this.IsRefreshNeeded = false
    console.log("BookingStatus", this.bookingStatus)
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    if (this.signaturePad != undefined) {
      this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
      // this.signaturePad.fromDataURL('')
    }

    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
  }
  onTabchange(event) {

  }
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event    
    console.log(this.signaturePad.toDataURL());
    this.bookingEntityModel.signature = this.signaturePad.toDataURL()
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }
  valueChange(value: any): void {

    // console.log(this.bookingEntityModel.reservedBy)
    this.ReservedByErrorEnable = this.bookingEntityModel.reservedBy == null ? true : false
  }


  preventStartDateTime(event) {
    if (this.bookingEntityModel.bookingID > 0) {

      //CHECK FOR UPDATED BOOKING STATUS      
      //this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == this.bookingEntityModel.bookingID }) == undefined ? 'Available' : 'Checked Out'
      this.bookingStatusName = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == this.bookingEntityModel.bookingID }) != undefined ?  'Checked Out' : 'Available'    
      //CHECK FOR UPDATED BOOKING STATUS      

      if (this.bookingStatusName == 'Checked Out') {
        document.getElementsByClassName('wc-date-popover banner-true')[0].setAttribute('hidden', 'true')
      }
      else {
        if (document.getElementsByClassName('wc-date-popover banner-true')[0].getAttribute('hidden')) {
          document.getElementsByClassName('wc-date-popover banner-true')[0].removeAttribute('hidden')
        }
      }
    }
  }

  preventEndDateTime(event) {
    if (this.bookingEntityModel.bookingID > 0) {
      
      //CHECK FOR UPDATED BOOKING STATUS      
      this.bookingStatusName = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == this.bookingEntityModel.bookingID }) == undefined ? 'Available' : 'Checked Out'
      //CHECK FOR UPDATED BOOKING STATUS      
      if (this.bookingStatusName == 'Checked Out') {
        document.getElementsByClassName('wc-date-popover banner-true')[1].setAttribute('hidden', 'true')
      }
      else {
        if (document.getElementsByClassName('wc-date-popover banner-true')[1].getAttribute('hidden')) {
          document.getElementsByClassName('wc-date-popover banner-true')[1].removeAttribute('hidden')
        }
      }
    }
  }

  TempBookingID: number = 0
  DeleteBooking(bookingID) {
    this.IsOpenDialog = true
    this.TempBookingID = bookingID

  }
  IsOpenDialog: boolean = false;
  closeDeleteAssetBookingDialog(status) {
    // this.IsOpenDialog = true;

    if (status == 'true') {
      console.log(this.TempBookingID)
      if (this.TempBookingID > 0) {
        this.coredataservice.DeleteAssetBookingDetailsByBookingID(this.TempBookingID).subscribe(res => {
          const index = this.gridData.data.findIndex(item => item.bookingID === this.TempBookingID);
          if (index >= 0) {
            this.gridData.data.splice(index, 1);
            this.gridData = process(this.gridData.data, this.state);
            this.ResetModel()

            //CHECK FOR UPDATED BOOKING STATUS      
            this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out'}) != undefined ?  'Checked Out' : 'Available'    
            //this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == this.TempBookingID }) == undefined ? 'Available' : 'Checked Out'
            //CHECK FOR UPDATED BOOKING STATUS      
            this.IsRefreshNeeded = true
          }
        })
      }
    }
    // else if(status =='false'){

    // }
    this.IsOpenDialog = false;
  }


  ReservedByErrorEnable: boolean = false
  ReservedStartTime: boolean = false
  ReservedEndTime: boolean = false

  BookAsset(bookingEntityModel: BookingEntity) {
    //Add validation
    if (this.IsValidForReserve(bookingEntityModel)) {
      if (bookingEntityModel.checkOutDate != null && bookingEntityModel.checkOutDate != undefined) {
        bookingEntityModel.status = this.BookingStatus.filter((ele) => { return ele.codeValue.toLowerCase() == 'checked out' })[0]['codeID']
      }
      else {
        bookingEntityModel.status = this.BookingStatus.filter((ele) => { return ele.codeValue.toLowerCase() == 'reserved' })[0]['codeID']
      }

      bookingEntityModel.assetID = this.assetID
      bookingEntityModel.startDateTime = new Date(bookingEntityModel.startDateTime)
      bookingEntityModel.endDateTime = new Date(bookingEntityModel.endDateTime)
     


      //CHECK FOR CHECK OUT IF FILLED BASED ON STATUS
      // if(bookingEntityModel.checkOutDate != null && bookingEntityModel.checkOutDate != undefined){
      //   if(!this.IsValid(bookingEntityModel)){
      //     this.opened = true;
      //     this.notify.error("Please fill all the mandatory fields");
      //    return
      //   }  
      // }      
      //CHECK FOR CHECK OUT IF FILLED BASED ON STATUS

      this.coredataservice.bookAssets(bookingEntityModel).subscribe(res => {
        this.bookingEntityModel = new BookingEntity()

        if (this.signaturePad != undefined) {
          this.signaturePad.clear();
        }
        this.notify.success("Asset booked successfully");
        this.opened = false;
        let AssetID = this.assetID
        this.assetID = 0
        this.IsRefreshNeeded = true
        this.closeBookingEvent.emit({ "opened": this.opened, "assetID": this.assetID, "IsRefresh": this.IsRefreshNeeded })
        //this.router.navigate(['/business-assets-listing']); CLOSE POP UP
      })
      //  this.opened = false;
      //  this.closeBookingEvent.emit({"opened" : this.opened,"assetID" :this.assetID})
    }
    else {
      this.opened = true;
      this.ReservedByErrorEnable = this.bookingEntityModel.reservedBy == null ? true : false
      // this.notify.error("Please fill all the mandatory fields");
    }
  }


  IsValid(bookingEntityModel: BookingEntity): boolean {

    if (bookingEntityModel.startDateTime != undefined && bookingEntityModel.endDateTime != undefined && bookingEntityModel.checkedOutBy != undefined && bookingEntityModel.checkedOutBy != null
      && bookingEntityModel.checkOutDate != undefined && bookingEntityModel.checkOutDate != null
      && bookingEntityModel.checkOutNotes != undefined && bookingEntityModel.checkOutNotes != null
      && bookingEntityModel.signature != undefined && bookingEntityModel.signature != null
    ) {
      return true
    }
    else {
      return false
    }

  }

  IsValidForReserve(bookingEntityModel: BookingEntity): boolean {

    if (bookingEntityModel.startDateTime != undefined && bookingEntityModel.endDateTime != undefined
      && bookingEntityModel.reservedBy != undefined && bookingEntityModel.reservedBy != null
    ) {
      return true

    }
    else {
      return false
    }

  }

}
