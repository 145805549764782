import { Component, Input, OnInit } from '@angular/core';
import { State, process } from '@progress/kendo-data-query';
import { AssetDetails, AssetImageData, LocationDetails } from './image-import.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { ClearEvent, FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { ImageCompressor, getImageSize } from "compressor-img";
import { SnotifyService } from 'ng-snotify';


@Component({
  selector: 'app-image-import',
  templateUrl: 'image-import.component.html',
  styleUrls: ['image-import.component.css']
})
export class ImageImportComponent implements OnInit {
 
  @Input('AssetData') AssetData: Array<AssetDetails>;
  @Input('Selectedtable') Selectedtable: number;
  @Input('LocationData') LocationData: Array<LocationDetails>;
  isTextEdit: boolean;
  
  isEditbtn: boolean;
  isCancelbtn: boolean;
  isSavebtn: boolean;
  IsCancelClickedImage: boolean;
  showEditButton: boolean=true;
  isRowOpen: boolean;
  LocationLanguageConfig: string;
;
  public pageSize=5
  public state: State = {
    skip: 0,
    take: 5,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public selectableSettings = {
    enabled: true,
    checkboxOnly: false,
    mode: "multiple",
  };

  public imageIndexOne = 0;

  //public AssetData:Array<AssetDetails>=[]

  public HideShowImageViewer=false

  LanguageConfig: any;
  ErrorMsgData: any;
  public gridData: GridDataResult ;
  public imagePreviews: any = [];
  public imagePreviewsViewer: string[] = [];
  ClickedImage: boolean = false;
  ImageID: number;

  constructor( private sharedDataService: SharedDataService,private DataService:CoreDataService, private notify: SnotifyService) { }

  ngOnInit() {
    if( this.Selectedtable==1)
    this.gridData= process(  this.AssetData, this.state );
    else if(this.Selectedtable==2)
    this.gridData= process(  this.LocationData, this.state );

    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[9];
          this.LocationLanguageConfig=res[8];
          this.ErrorMsgData = res[28];
        }
      });
    }
  }


 


   dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if( this.Selectedtable==1)
    this.gridData= process(  this.AssetData, this.state );
    else if(this.Selectedtable==2)
    this.gridData= process(  this.LocationData, this.state );
   

  }

  swapImages: any = [];
  swapAssetImage(assetImages) {
    
    this.swapImages = assetImages;

    console.log(this.swapImages);
  
  }

  changeIndex(i, image) {
    this.imageIndexOne = i;
    if (image != undefined) {
      this.ImageID = image.uid;
      this.ClickedImage = true;
    }

    document
      .getElementsByTagName("ngx-image-viewer")[0]
      .getElementsByClassName("img-container")[0]
      .getElementsByTagName("img")[0]["style"]["height"] =
      "-webkit-fill-available";
  }

  handleEvent(value) {
    if (value == "click") {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }
  }
  closePreviewImage(value) {
    this.ClickedImage = value == "false" ? false : true;
    this.handleEvent("click");
  }
  positionCursor() {
    document.getElementById("description").focus();
    document.execCommand("selectAll", false, null);
    document.getSelection().collapseToEnd();
  }
  assetImgDesc(value, index) {
    console.log(index);
    if (value == "edit") {
      this.isTextEdit = false;
      this.isCancelbtn = true;
      this.isSavebtn = true;
      this.isEditbtn = false;
      this.positionCursor();
    }
    if (value == "cancel") {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }

    if (value == "save") {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }
  }

  cancelledClickImage(item) {
    if (item != undefined) {
      this.ImageID = item.uid;
      this.IsCancelClickedImage = true;
    }
  }

  closeImage(val) {
    if (val != undefined && val == "true") {
      const index = this.imagePreviews.findIndex(
        (item) => item.uid === this.ImageID
      );
      if (index >= 0) {
        if (typeof this.ImageID == "string") {
          this.imagePreviews.splice(index, 1);
          this.imagePreviewsViewer.splice(index, 1);
          this.IsCancelClickedImage = false;
        } else if (typeof this.ImageID == "number") {
          this.DataService.DeleteAssetImage(this.ImageID).subscribe((res) => {
            if (res != null && res != undefined) {
              // alert('deleted')
              this.imagePreviews.splice(index, 1);
              this.imagePreviewsViewer.splice(index, 1);
              this.IsCancelClickedImage = false;
            }
          });
        }
      }
    } else {
      this.IsCancelClickedImage = false;
    }
  }

  getImages(ID){
    var type=''
    if( this.Selectedtable==1)
   type='AST'
    else if(this.Selectedtable==2)
    type='LOC'

    this.DataService.GetLocationImageData(type, ID).subscribe((resImg) => {

      let arr = [];
      resImg.forEach(ImgData => {
        arr.push({
          src: 'data:image/png;base64,' + ImgData.imagePath,
          uid: ImgData.imageDataID,
          extension: ImgData.extension,
          IsUpdated: false, 
          date: ImgData.creationDate,
          description: ImgData.description,
        })
        this.imagePreviews = arr;
        this.imagePreviewsViewer.push('data:image/png;base64,' + ImgData.imagePath)

      });
    })

  }

  
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png"],
    maxFileSize: 8388608, 
   
  };

  

  public uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  public uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  public clearEventHandler(e: ClearEvent): void {
    this.imagePreviews = [];
  }

  public completeEventHandler() { }

  public removeEventHandler(e: RemoveEvent): void {
    const index = this.imagePreviews.findIndex(
      (item) => item.uid === e.files[0].uid
    );

    if (index >= 0) {
      this.imagePreviews.splice(index, 1);
    }
  }

  originalImage: any = null;
  sizeOriganleImage: any = null;
  compressedImage: any = null;
  sizeCompressedImage: any = null;
  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    console.log("File", e);
    e.files.forEach((file: any) => {
      if (file.size < 8388608 && this.uploadRestrictions.allowedExtensions.includes( file.extension)) {
        const reader = new FileReader();
        if (file.size > 300000) {
          reader.onload = () => {
            let imageCompressor = new ImageCompressor({
              onSuccess: (response) => {
                (this.originalImage = response.original),
                  (this.sizeOriganleImage = getImageSize(response.original)),
                  (this.compressedImage = response.compressed),
                  (this.sizeCompressedImage = getImageSize(
                    response.compressed
                  ));

                //GENERATING Unique object ID for image & also for deletion

                if (that.imagePreviewsViewer[0] !== this.compressedImage) {
                  let image = {
                    src: this.compressedImage,
                    uid: file.uid,
                    date: Date.now(),
                    extension: file.extension,
                    IsUpdated: true,
                  };

                  //GENERATING Unique object ID for image
                  that.imagePreviews.unshift(image);
                  that.imagePreviewsViewer.unshift(this.compressedImage);
                  this.validateAssetImages();
                }
                var k = document.getElementsByClassName("k-upload-files");
                if (k.length > 0) {
                  k[0].remove();
                }
              },
              scale: 30,
              quality: 100,
              holdCompress: false,
              originalImage: reader.result,
            });
            imageCompressor.startCompress();
          };
        } else {
          reader.onload = (ev) => {
            let image = {
              src: ev.target["result"],
              uid: file.uid,
              extension: file.extension,
              date: Date.now(),
              IsUpdated: true,
              description: "",
            };

            that.imagePreviews.unshift(image);
            that.imagePreviewsViewer.unshift(ev.target["result"].toString());
            this.validateAssetImages();
            var k = document.getElementsByClassName("k-upload-files");
            if (k.length > 0) {
              k[0].remove();
            }
          };
        }

        reader.readAsDataURL(file.rawFile);
      } else if(file.size > 8388608) {
        this.notify.error("File Size More Than 8 MB.");
        var k = document.getElementsByClassName("k-upload-files");
        if (k.length > 0) {
          k[0].remove();
        }
      }
      else{
        this.notify.error("File Extenstion should be '.JPG','.PNG','.JPEG' ");
        var k = document.getElementsByClassName("k-upload-files");
        if (k.length > 0) {
          k[0].remove();
        } 
      }
    });
  }

  validateAssetImages(): boolean {
    let IsValid = false;
   
      if (this.imagePreviews.length <= 10 && this.imagePreviews.length >= 2) {
       
      
        IsValid = true;
      } else {
      
        IsValid = false;
      }
    return IsValid;
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.imagePreviews=[]
    this.imagePreviewsViewer=[]
    this.HideShowImageViewer=true
    var ID=0;
    if( this.Selectedtable==1)
    ID=dataItem.assetID
    else if(this.Selectedtable==2)
    ID=dataItem.locationID
    this.getImages(ID)
   this.isRowOpen=true;
  
    sender.editRow(rowIndex);
  }
  cancelHandler({ sender, rowIndex }) {
    this.HideShowImageViewer=false
    this.closeEditor(sender, rowIndex);
    this.imagePreviews=[]
    this.imagePreviewsViewer=[]
  }
  saveHandler({ sender, rowIndex, dataItem, formGroup, isNew }) {
    let image: AssetImageData = new AssetImageData();
    var imgID=null
    if (this.imagePreviews.length > 0) {
      this.imagePreviews.forEach((element, index) => {
        var ID=0;
        var type=''
        if( this.Selectedtable==1){
        ID=dataItem.assetID
        type='AST'
        }
        else if(this.Selectedtable==2){
        ID=dataItem.locationID
        type='LOC'
        }
        
        image.associatedID =ID ;
        image.imageDataID =
          typeof element.uid == "string" ? 0 : element.uid;
          imgID =
          typeof element.uid == "string" ? 0 : element.uid;
          
         
        image.imageType = type;
        image.extension = element["extension"].toLowerCase();
        image.imageData = this.convertDataURIToBinary(element["src"]);
        image.sequence = index;
        image.description = element.description;
        // image.CapturedDate = element.date;
        this.DataService.UploadImageAsset(image).subscribe((rp) => {
          image = new AssetImageData();
          sender.closeRow(rowIndex);
          this.isRowOpen=false
          this.HideShowImageViewer=false
          this.imagePreviews=[]
          this.imagePreviewsViewer=[]
        
          
        });
        // }
      });
      if(imgID==0)
      {
      this.notify.success('Image Added Successfully!');
      }
      else{
        this.notify.success('Image Updated Successfully!');
      }
    }
  
     

      
  }
  closeEditor(grid, rowIndex) {
    grid.closeRow(rowIndex);
    this.isRowOpen = false;
 
  }

  convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  RotateImageForward(srcBase64, uid, Orientation, Rotation, index) {
    var img = new Image();
    let srcOrientation = null;
    if (Orientation != undefined) {
      srcOrientation = Orientation;
    } else {
      srcOrientation = 1;
    }

    if (Rotation == "F") {
      //Setting Forward orientation
      if (srcOrientation == 1) srcOrientation = 6;
      else if (srcOrientation == 8) srcOrientation = 6;
    } else if (Rotation == "B") {
      if (srcOrientation == 1) srcOrientation = 8;
      else if (srcOrientation == 6) srcOrientation = 8;
    }

    // Orientation = srcOrientation
    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);
      this.test(canvas.toDataURL(), uid, srcOrientation, index);
      // export base64

      document.getElementById(uid)["src"] = canvas.toDataURL();
    };

    this.imagePreviews.forEach((element) => {
      if (element.uid === uid) {
        element.Orientation = srcOrientation;
      }
    });
    img.src = srcBase64;
  }
  test(data, uid, srcOrientation, index) {
    this.imagePreviews.forEach((element) => {
      if (element.uid === uid) {
        element.src = data;
        element.Orientation = srcOrientation;
        element.IsUpdated = true;
      }
    });
    this.imagePreviewsViewer[index] = data;
  }

}
