import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CoreDataService } from '../../Service/core-data.service'
import { SnotifyService } from 'ng-snotify';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';


@Component({
  selector: 'app-asset-refactoring',
  templateUrl: './asset-refactoring.component.html',
  styleUrls: ['./asset-refactoring.component.scss']
})
export class AssetRefactoringComponent implements OnInit {
  userAction: string = "Asset Refactoring";
  public CustomerDropdown: Array<{ customerName: string, customerID: number }> = [];
  public FilteredLocationDropdown: Array<{ name: string, locationID: number }> = [];
  public LocationDropdown: Array<{ name: string, locationID: number }> = [];
  CustomerID: number;
  LocationID: number;
  LanguageConfig: any;
  ErrorMsgData: any;

  constructor(private activatedRoute: ActivatedRoute, private notify: SnotifyService, private titleService: Title, private DataService: CoreDataService, private sharedDataService: SharedDataService) {

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
    this.userAction = pageTite
  }

  ngOnInit() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[20];
          this.ErrorMsgData = res[28];
        }
      })
    }

  }
  CustomerfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
    }
  }
  SelectedCustomer(val) {
    this.LocationID = null;
    this.LocationDropdown = [];
    this.FilteredLocationDropdown = [];
    this.getLocationbyName(this.CustomerID);

  }
  locationFilterChange(value) {
    this.FilteredLocationDropdown = [];
    if (value.length >= 3) {
      this.FilteredLocationDropdown = this.LocationDropdown.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.FilteredLocationDropdown = this.LocationDropdown;
    }
  }
  getLocationbyName(customerID) {
    this.DataService.getUserLocationByCustomerDropdown('', customerID).subscribe((res) => {
      if (res != undefined && res != null) {
        this.LocationDropdown = res;
        this.FilteredLocationDropdown = this.LocationDropdown
      }
    });
  }
  getCustomerbyName(searchtext) {
    this.DataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {
        this.CustomerDropdown = res;
      }

    })
  }
  RefactorAsset() {
    if (this.CustomerID != undefined && this.CustomerID != null && this.CustomerID != 0 &&
      this.LocationID != undefined && this.LocationID != null && this.LocationID != 0) {
      this.DataService.AssetRefactor(this.CustomerID, this.LocationID).subscribe((res) => {
        if (res != undefined && res != null) {
          this.notify.success(res.recordsAffected + this.ErrorMsgData.rowsAffected);
        }

      })
    } else {
      this.notify.warning(this.ErrorMsgData.selectCustomerNdLocation);

    }
  }
}
