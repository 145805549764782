export class FaultModelClass{
    public faultGroupID:number ;
   public  groupName :string;
   public faultID:number;
   public faultName:string;
}


export class GroupModel{
    groupName:string
}
// export class FaultClass{
//     public FaultID:number;
//     public FaultGroupID:number;
//     public FaultName:string;
// }

export class FixClass{
    public  fixID :number;
    public fixName :string;
}

// export class EditFaultClass{
//     public faultId: number
//     public faultGroupID:number
//     public faultName:string
//     public faultGroupName:string
// }