import { Injectable } from '@angular/core';

@Injectable()
export class CommonFunctionService {
  // public fieldItems: Array<any> = ["Mandatory", "Optional", "Not Applicable"];
  public fieldItems: Array<any> = [{
    displayName: "Mandatory", displayID: '1'
  },
  {
    displayName: "Optional", displayID: '0'
  },
  {
    displayName: "Not Applicable", displayID: '-1'
  }];
  temp = " ";
  currentdata = {};
  constructor() { }

  filterObject(keysArray, obj) {

    let newObj = {};

    for (let key in obj) {

      if (keysArray.indexOf(key) > -1) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  iterateArray(array, key) {
      if (key === "1") {
      this.temp = this.fieldItems[0];
    }
   else if (key === "0") {
      this.temp = this.fieldItems[1];
    }

    else
    {
      this.temp = this.fieldItems[2];

    }


     return this.temp
    // return   ,arr,key)

  }
  filterObjectValue(array: Array<any>, key: String, actualValue: string) {
    var newObject = { FieldName: actualValue, Field: [] };

    array.map(element => {

      if (element.codeName === key) {
        element["updatedValue"] = element.codeValue;

        newObject.Field.push(element);
      }
    });
     return newObject;

  }
  filterCodeId(grid: Array<any>, key: number, response) {
    grid[key].Field.map((elementgrid, index) => {

      response.map((element) => {

        if (element.codeID === elementgrid.codeID) {
          elementgrid["updatedValue"] = element["customerCodeValue"];

        }
      })

    });
     return grid;
//  array.map((elementout,index)=>{
     //   oldObject.map((element,index)=>{

    //     this.currentdata=element.Field.filter((element)=>{
    //          return element.codeID===8;
    //  })

    //   })
    // })



  }

}
