export class DocumentsUploadEntity {
    public documentID : number;
    public docType : string;
    public associateID  :number; 
    public title  : string;
    public description   : string;
    // public UpdatedBy  : number;
    public extension  : string;
    public fileName  : string;
    public documentData   : Array<number>;
    
    public docPath?  : string;
    public IsUpdated? : boolean ;
    
    
}