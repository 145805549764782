export class PrimaryDetails {
    // public AssetType: Array<{ assetTypeName: string, assetTypeID: string,assetGroupID :number,groupName : string}>;
    public Geolocation: string;
    public AssetImages: string;
    public AssetFieldTemplate: Array<{ assetFieldID: number, defaultBehaviour: string, dataSource: string, dataType: string, description: string, displayName: string, fieldName: string, fieldType: string, groupName: string, length: number, mobile: boolean, regExpression: string, valueFields: string, web: boolean, currval: any }>;
}
export class LocationEntity {
    public locationID: number;
    public name: string;
    public geoLocation: string;
    public maintenanceStandardCd: number;
}
export class AssetTypeEntity {
    public assetTypeName: string;
    public assetTypeID: string;
    public assetGroupID: number;
    public groupName: string;
    public hasSubAssets: boolean;
    public expectedLife: number;
    public uomTypeID: number;
}

export class UOMData {

    public uomTypeID: number;
    public typeName: string;
    public uomID: number;
    public unitName: string;

}

export class SubAssetEntity {
    public index: number;
    public subAssetID: number;
    public assetID: number
    public heightLength: number;
    public areaLocated: string;
    public make: number;

    public model: string;
    public serialNo: string;
    public widthBreadth: number;
    public thicknessDepth: number;
    public conditionRating: any;
    public makeCd: any;
    public assetType: any;
    public assetTypeID: number;
    public conditionRatingCd: number;
    public userAssetID: string;
    public SubAssetImages: any = [];
    public IsValidSubAsset: any;

    // new fields
    public barcode: string;
    public filterQty: string;
    public filterType: any;
    public filterTypeCd: number;
    public generalComments: string;
    public filterDesc: string;

    public IsChecked: boolean = false
    //

}
export class AssetImageData {
  public imageDataID: number;
  public imageType: string;
  public associatedID: number;
  public description: string;
  public imageData: Array<number>;
  public uid?: string;
  public extension: string;
  public sequence: number = null;
  public CreationDate: Date;
}
export class SubAssetList {
  public subAssetID: number = null;
  public assetID: number;
  public heightLength: number;
  public areaLocated: string;
  public make: string;
  public model: string;
  public serialNo: string;
  public widthBreadth: number;
  public thicknessDepth: number;
  public ddlConditionRatingCd?: {
    codeValue: string;
    codeID: number;
    weightage: number;
  };
  public conditionRatingCd: number;
  public conditionRating: string;
  // public ddlassetType: { assetGroupID: number,assetTypeID: number,assetTypeName: string,groupName: string,hasSubAssets: boolean};
  public assetType: any;
  public assetTypeID: number;
}

export class AssetAdminListingEntity {
  public createdByName: string;
  public updatedByName: string;
  public creationDate: Date;
  public updationDate: Date;
  public firstauditduration?: string;
  public lastauditduration?: string;
}

export class ReplacementCostBugget {
  public AssetTypeID: number;
  public Capacity: number;
  public Size: number;
  public UOMCd: number;
  public LocationID: number;
  public Quantity: number;
}
export class RepairAndMaintenanceCostBugget {
  public AssetTypeID: number;
  public AssetTypeRMID: number;
  public Capacity: number;
  public Size: number;
  public UOMCd: number;
  public LocationID: number;
  public Quantity: number;
}

export class AssetTypeFixListModel {
  public fixID: number;
  public fixName: string;
}

export class AssetTypeFaultListModel {
  public faultID: number;
  public faultName: string;
  public groupName: string;
}

export class AssetDetails {
  public systemID: string;
  public assetID?: number = 0;
  public auditCompleted: boolean = true;
  public isIncomplete: boolean = false;
  public userAssetID: string;
  public barcode: string;
  public assetLocationID: any;
  public customerAssetID: string;
  public ownershipCd: number;
  public conditionRatingCd: number;
  public statusCd: number;
  public assetTypeID: number;
  public indoorOutdoorCd: number;
  public geoLocation: string;
  public areaServed: string;
  public areaLocated: string;
  public levelLocated: string;
  public roomNo: string;
  public buildingNo: string;
  public calculatedReplacementYear: any;

  public mfgCd: number;
  public modelNbr: string;
  public serialNo: number;
  public installDate?: any;
  public fireModeFnCd: number;
  public essentialServiceCd: number;
  public internalComments: string;
  public oHSComments: string;
  public generalComments: string;
  public criticalityCd: number;
  public replacementCostBudget: number;
  public expectedLife: number;
  public remainingLife: number;
  public actualAge: number;
  public efficiencyAge: number;
  public equivalentAge: number;
  public maintenanceStdCd: number;
  public assetRunTimeCd: number;
  public dampersFittedCd: number;
  public filterDesc: string;
  public fanBeltPartNbr: string;
  public fanBeltQty: number;
  public refrigerantCd: number;
  public refrigerantQty: number;
  public nFactor: number;
  public ageGroup: string;
  public lastAnnual?: any;
  public lastService?: any;
  public rMBudgetYear1: number;
  public rMBudgetYear2: number;
  public rMBudgetYear3: number;
  public rMBudgetYear4: number;
  public rMBudgetYear5: number;
  public capExBudgetYear1: number;
  public capExBudgetYear2: number;
  public capExBudgetYear3: number;
  public capExBudgetYear4: number;
  public capExBudgetYear5: number;
  public AuditCompleted: boolean;
  public customerCommentary: string;
  public reccWithdrawalDate?: any;
  public capacity: number;
  public quantity: number;
  public firstauditduration?: string;
  public lastauditduration?: string;
  public capExBudgetYear6: number;
  public capExBudgetYear7: number;
  public capExBudgetYear8: number;
  public capExBudgetYear9: number;
  public capExBudgetYear10: number;
  public capExBudgetYear11: number;
  public capExBudgetYear12: number;
  public capExBudgetYear13: number;
  public capExBudgetYear14: number;
  public capExBudgetYear15: number;
  public capExBudgetYear16: number;
  public capExBudgetYear17: number;
  public capExBudgetYear18: number;
  public capExBudgetYear19: number;
  public capExBudgetYear20: number;
  public customerBudgetPrice: number;

  public dLPEndDate?: any;
  public maintenanceCompletedCd: number;
  public faultCodes: any;
  public fixCodes: any;
  public priorityCd: number;
  public permits: any;
  public environmentCd: number;
  public replacementYear: string;
  public pumpDuty: string;
  public batteries: string;
  public hYDRStorz: string;
  public layFlatHoses: string;
  public maxPeople: number;
  public d3NetAddress: string;
  public airnetAddress: string;
  public appliedFieldCodeSetting: string;
  public thicknessDepth: number;
  public widthBreadth: number;
  public heightLength: number;

  public ductworkandInsulation: number;
  public specialComments: string;
  public assetType: number;
  public economyDampersCondition: number;
  public electricalWiringCondition: number;
  public inactiveLeafWidth: number;
  public refrigPipeworkCondition: number;
  public maintenanceFreqCd: number;
  public decommissionedAssetID: string;
  public noOfPoles: number;
  public rMBudgetYear6: number;
  public rMBudgetYear7: number;
  public rMBudgetYear8: number;
  public rMBudgetYear9: number;
  public rMBudgetYear10: number;
  public rMBudgetYear11: number;
  public rMBudgetYear12: number;
  public rMBudgetYear13: number;
  public rMBudgetYear14: number;
  public rMBudgetYear15: number;
  public rMBudgetYear16: number;
  public rMBudgetYear17: number;
  public rMBudgetYear18: number;
  public rMBudgetYear19: number;
  public rMBudgetYear20: number;
  public auditConditionRating: number;
  public electricalInputKW: number;
  public coolingCoilCondition: number;
  public assetSizeCd: number;
  public valvesandActuatorsCondition: number;
  public indoorCoilCondition: number;
  public filterTypeCd: number;
  public outdoorCoilCondition: number;
  // public SystemID: string;
  public fireDoorFRLRatingCd: number;
  public heatingCoilCondition: number;
  public supplyAirFanMotorCondition: number;
  public filterQty: string;
  public recommendedActionCd: number;
  public uOMCd: number;
  public totalSpend: number;
  public jobCount: number;
  public photos: string = "";
  public assetCaptureDate?: any;
  public quoteReqd: string;
  public generalCommentsUpdate: string;
  public oHSCommentsUpdate: string;

  //NEW BUSINESS FIELDS
  public purchaseDate?: any;
  public registrationExpiryDate?: any;
  public nextService?: any;
  public nextTestandTag?: any;
  public lastTestandTag?: any;
  public fuelType: number;
  public version: string;
  public registrationNumber: string;
  public purchaseOrderReference:string;
}


 // {
 //     "assetTypeName": "EEL - COMMANDER/AXIOM",
 //     "assetTypeID": 2,
 //     "assetGroupID": 1,
 //     "groupName": "latestone"
 // },
