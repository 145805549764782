import { Component, OnInit, Input } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service';
import { SharedDataService } from '../../Service/CommonUtility.service';
import { CommentHistoryEntity } from '../../Common/comment-history/comment-history.model'

@Component({
  selector: 'app-comment-history',
  templateUrl: './comment-history.component.html',
  styleUrls: ['./comment-history.component.css']
})

export class CommentHistoryComponent implements OnInit {
  @Input('AssociateID') AssociateID: number;
  @Input('CommentType') CommentType: string;
  DateTimeformat:string;
  Dateformat:string;
  CommentHistoryList: Array<CommentHistoryEntity> = new Array<CommentHistoryEntity>();
  constructor(private coreDataService: CoreDataService, private sharedData: SharedDataService) { }

  ngOnInit() {
    this.sharedData.GetOrgSettings().then((res)=>{
      if(res != null){
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
        this.Dateformat = res['DateFormat'];
      }
    })
    this.bindHistory(this.CommentType, this.AssociateID);
  }


  bindHistory(CommentType, AssociateID) {
    this.coreDataService.CommentHistoryGet(CommentType, AssociateID).subscribe(res => {
      if (res != undefined && res != null) {
        this.CommentHistoryList = res;
      }
    })
  }
}
