export class unitofMeasurmentList{
  public uomType : string;
  public description : string;
  public unitName : string;
  public conversionFactors: number;
  public isDefault : boolean
}
export class uomTypeModel{
  public uomTypeID : number;
  public typeName : string;
  public description : string;
}


export class UOMList{
  public uomID :number;
  public unitName: string;
  public description : string;
  public conversionFactor : number;
  public isDefault : boolean;
 // public uomSubModel :any = [];
}