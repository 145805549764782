import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms'
import { ViewChild } from '@angular/core';

import { ChartComponent } from '@progress/kendo-angular-charts';
import { saveAs } from '@progress/kendo-file-saver';
import { exportImage, geometry } from '@progress/kendo-drawing';
import { CoreDataService } from '../Service/core-data.service'
import { DashboardKPIListingEntity, ReviewerActiveTech, AgeVsConditionEntity, AssetGroupKwhListingEntity, RemainingLifeListingEntity, ConditionRatingListingEntity, BudgetListingEntity, RefrigerantListingEntity } from '../admin-dashboard/admin-dashboard.model';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from "@angular/router";
import { PlotBand } from '@progress/kendo-angular-charts';
import { SharedDataService, ReportFilter } from 'src/app/Service/CommonUtility.service';

import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ReviewerActiveProject, ReviewerTechAuditAnalysis, ReviewerProjectDuration, AdminDashboardKPI } from '../admin-dashboard/admin-dashboard.model';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  reportFilter: ReportFilter = new ReportFilter();
  Dateformat: any;
  LanguageConfig: any;
  BudgetChartLabel: string;
  public FilteredLocationDropdown: Array<{ name: string, locationID: number }> = [];
  public LocationDropdown: Array<{ name: string, locationID: number }> = [];
  public CustomerDropdown: Array<{ customerName: string, customerID: number,isBusinessUnit : boolean }> = [];
  myActiveProjectsData: any[] = [];
  techAuditData: any[] = [];
  techAuditDataPop: any[] = [];
  projectDuration: any[] = [];

  CustomerID: number;
  LocationID: any;
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };


  private state1: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };


  private state3: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  private state2: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.myActiveProjectsData, this.filter);

  constructor(private sharedData: SharedDataService, private DataService: CoreDataService, private titleService: Title, private activatedRoute: ActivatedRoute, private sharedDataService: SharedDataService, private router: Router) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }


  private gridDataActiveProjects: GridDataResult;
  private gridDataTechAudit: GridDataResult;
  private gridDataTechAuditPop: GridDataResult;
  private gridDataProjectDuration: GridDataResult;

  ReviewerActiveProject: Array<ReviewerActiveProject> = new Array<ReviewerActiveProject>()
  ReviewerTechAuditAnalysis: Array<ReviewerTechAuditAnalysis> = new Array<ReviewerTechAuditAnalysis>()
  ReviewerTechAuditAnalysisPop: Array<ReviewerActiveTech> = new Array<ReviewerActiveTech>()
  ReviewerProjectDuration: Array<ReviewerProjectDuration> = new Array<ReviewerProjectDuration>()

  AdminDashboardKPI: AdminDashboardKPI = new AdminDashboardKPI();


  DashboardKPIListing: DashboardKPIListingEntity = new DashboardKPIListingEntity();

  IsInUseAssets: boolean;
  IsBudgetYear: boolean;
  IsR22: boolean;

  public ddlCodeData: Array<any> = new Array<any>();
  public ConditionRatingData: Array<any> = new Array<any>();
  UserData: any;
  Math: any;

  public range = { start: null, end: null };
  public ConvertedRange = { start: null, end: null };
  GridConfig: any
  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[33];
          console.log(this.LanguageConfig)
          this.GridConfig = res[2];
        }
      })

    }


    var token = window.localStorage.getItem('token');
    if (token != null && token != undefined && token != '') {

      this.UserData = this.sharedData.parseJwt(token);
      var StartDate = new Date();
      StartDate.setDate(StartDate.getDate() - 1);
      var EndDate = new Date();
      EndDate.setDate(EndDate.getDate() - 1);
      this.range.start = StartDate
      this.range.end = EndDate

      // this.Dateformat = this.sharedDataService.dateFormat();
      this.sharedDataService.GetOrgSettings().then((res) => {
        if (res != null) {
          this.Dateformat = res['DateFormat'];
        }
      })
      // this.ConvertedRange.start = new Date(StartDate)
      // this.ConvertedRange.end = new Date(EndDate)
      this.ConvertedRange.start = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
      this.ConvertedRange.end = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    }
    this.Math = Math;
    this.reviewerActiveProject(this.ConvertedRange.start, this.ConvertedRange.end, undefined, undefined)
    this.getAdminDashboardKPI(this.ConvertedRange.start, this.ConvertedRange.end, undefined, undefined);
    this.state.filter.filters = [{ field: 'locationAuditStatus', operator: 'contains', value: 'INCOMPLETE' }];
    this.state1.filter.filters = [{ field: 'locationAuditStatus', operator: 'contains', value: 'INCOMPLETE' }];
    this.state2.filter.filters = [{ field: 'locationAuditStatus', operator: 'contains', value: 'INCOMPLETE' }];
    this.bindCodeData();
    this.getCustomerbyName('');
  }



  getAdminDashboardKPI(start, end, customerID, locationID) {
    this.DataService.getAdminDashboardKPI(start, end, customerID, locationID).subscribe(res => {
      this.AdminDashboardKPI = res
    })
  }

  public onTabSelect(e) {
    if (e.index == 1) {
      this.reviewerTechAuditAnalysis(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID)
    }
    else if (e.index == 2) {
      this.reviewerProjectDuration(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID)
    }
  }




  bindCodeData() {
    this.DataService.getCodeData().subscribe((res) => {
      if (res != undefined && res != null) {
        this.ddlCodeData.push(res);
      }
    })
  }



  getLocationbyName(customerID) {
    this.DataService.getUserLocationByCustomerDropdown('', customerID).subscribe((res) => {
      if (res != undefined && res != null) {
        this.LocationDropdown = res;
        this.FilteredLocationDropdown = this.LocationDropdown
      }
    });
  }
  CustomerfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
    }
  }
  SelectedCustomer(val) {
    this.LocationID = null;
    this.LocationDropdown = [];
    this.FilteredLocationDropdown = [];
    this.getLocationbyName(this.CustomerID);

  }
  locationFilterChange(value) {
    this.FilteredLocationDropdown = [];
    if (value.length >= 3) {
      this.FilteredLocationDropdown = this.LocationDropdown.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      this.FilteredLocationDropdown = this.LocationDropdown;
    }
  }
  getCustomerbyName(searchtext) {
    let userData = this.sharedData.parseJwt(localStorage.getItem('token'))
    this.DataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {

        if (userData.InventoryManagement =='False') {
          this.CustomerDropdown = res;
          this.CustomerDropdown = this.CustomerDropdown.filter(e=>{ return e.isBusinessUnit ==false})
        }
        else{
          this.CustomerDropdown = res;
        }        
        
      }

    })
  }
  reviewerProjectDuration(start, end, customerID, locationID) {
    this.DataService.getReviewerProjectDuration(start, end, customerID, locationID).subscribe(res => {
      this.ReviewerProjectDuration = res
      this.ReviewerProjectDuration.forEach(ele => {
        ele['firstCaptureDate'] = new Date(ele['firstCaptureDate'])
        ele['lastCaptureDate'] = new Date(ele['lastCaptureDate'])
      })
      this.ReviewerProjectDuration = this.ReviewerProjectDuration
      this.projectDuration = this.ReviewerProjectDuration

      this.gridDataProjectDuration = process(this.projectDuration, this.state2);
    })

  }

  reviewerTechAuditAnalysis(start, end, customerID, locationID) {
    this.DataService.getReviewerTechAuditAnalysis(start, end, customerID, locationID).subscribe(res => {

      this.ReviewerTechAuditAnalysis = res
      this.techAuditData = this.ReviewerTechAuditAnalysis

      this.gridDataTechAudit = process(this.techAuditData, this.state1);

    })
  }

  reviewerActiveTech(start, end, customerID, locationID) {
    this.DataService.getReviewerActiveTech(start, end, customerID, locationID).subscribe(res => {

      this.ReviewerTechAuditAnalysisPop = res
      this.techAuditDataPop = this.ReviewerTechAuditAnalysisPop

      this.gridDataTechAuditPop = process(this.techAuditDataPop, this.state3);
      this.opened = true;
    })
  }




  Selected(event) {

    this.ApplyFilterReviewer(event.selectedRows[0].dataItem.customerID, event.selectedRows[0].dataItem.locationID)
  }


  reviewerActiveProject(start, end, customerID, locationID) {
    this.DataService.getReviewerActiveProjects(start, end, customerID, locationID).subscribe(res => {
      this.ReviewerActiveProject = res;
      this.myActiveProjectsData = this.ReviewerActiveProject

      this.gridDataActiveProjects = process(this.myActiveProjectsData, this.state);
    })
  }


  CapacityZero() {

    this.reportFilter.capacityStart = 0
    this.reportFilter.capacityEnd = 0
    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)
  }
  public opened: any;


  ActiveTech() {

    this.reviewerActiveTech(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID)


  }

  close(status) {
    this.opened = false;
  }

  AssetsAdded() {
    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)
  }

  ReplacementCostBudgetZeroOrVeryLess() {
    this.reportFilter.replacementCost = '<50'
    this.reportFilter.capacityStart = undefined
    this.reportFilter.capacityEnd = undefined
    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)

  }

  InstallDateYesterday() {
    var InstallDate = new Date();
    InstallDate.setDate(InstallDate.getDate() - 1);

    this.reportFilter.installStartDate = InstallDate.getFullYear() + '-' + (InstallDate.getMonth() + 1) + '-' + InstallDate.getDate()
    this.reportFilter.installEndDate = InstallDate.getFullYear() + '-' + (InstallDate.getMonth() + 1) + '-' + InstallDate.getDate()

    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)
  }


  RepYr1AndNewAsset() {

    this.ddlCodeData[0].forEach(ele => {
      if (ele.codeName.toLowerCase() == 'conditionratingcd' && ele.codeValue.toLowerCase() == 'new or as good a new unit') {
        this.reportFilter.conditionRating = ele.codeID.toString()
      }
    })
    this.reportFilter.replacementYear = 'year 1'
    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)
  }

  PoorConditionAgeLessThnFive() {

    this.ddlCodeData[0].forEach(ele => {
      if (ele.codeName.toLowerCase() == 'conditionratingcd' && ele.codeValue.toLowerCase() == 'needs component upgrade/repair') {
        this.reportFilter.conditionRating = ele.codeID.toString()
      }
    })
    this.reportFilter.ageGroup = '<1,1-5'
    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)
  }

  ExceConditionRemLifeLessThnOne() {

    this.ddlCodeData[0].forEach(ele => {
      if (ele.codeName.toLowerCase() == 'conditionratingcd' && ele.codeValue.toLowerCase() == 'new or as good a new unit') {
        this.reportFilter.conditionRating = ele.codeID.toString()
      }
    })
    this.reportFilter.remainingLife = '<1'
    this.ApplyFilterReviewer(this.CustomerID, this.LocationID)
  }


  RefreshData() {

    //NEW CODE
    if (this.range.start != null) {
      this.ConvertedRange.start = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
      this.ConvertedRange.end = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    }
    else if (this.range.start == null) {
      this.ConvertedRange.start = null;
      this.ConvertedRange.end = null;
    }
    // this.ConvertedRange.start = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
    // this.ConvertedRange.end = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    //NEW CODE
    this.getAdminDashboardKPI(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID);
    this.reviewerActiveProject(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID)
    this.reviewerTechAuditAnalysis(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID)
    this.reviewerProjectDuration(this.ConvertedRange.start, this.ConvertedRange.end, this.CustomerID, this.LocationID)

  }

  ApplyFilterReviewer(CustomerID, LocationID) {

    this.reportFilter.customerID = CustomerID;
    this.reportFilter.locationID = LocationID;

    if (this.range.start != null) {
      this.reportFilter.startDate = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
      this.reportFilter.endDate = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    }
    else if (this.range.start == null) {
      this.ConvertedRange.start = null;
      this.ConvertedRange.end = null;
    }

    this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);
    this.sharedDataService.ReportFilterSharedData = this.reportFilter;
    this.router.navigate(["/assets-listing"]);
  }



  ApplyFilter() {
    this.reportFilter.customerID = this.CustomerID;
    this.reportFilter.locationID = this.LocationID;





    this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);
    this.sharedDataService.ReportFilterSharedData = this.reportFilter;
    this.router.navigate(["/assets-listing"]);
  }


  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridDataActiveProjects = process(this.myActiveProjectsData, this.state);
  }

  protected dataStateChangeAudit(state: DataStateChangeEvent): void {
    this.state1 = state;
    this.gridDataTechAudit = process(this.techAuditData, this.state1);
  }

  protected dataStateChangeAuditPop(state: DataStateChangeEvent): void {
    this.state3 = state;
    this.gridDataTechAuditPop = process(this.techAuditDataPop, this.state3);
  }

  protected dataStateChangeProject(state: DataStateChangeEvent): void {
    this.state2 = state;
    this.gridDataProjectDuration = process(this.projectDuration, this.state2);
  }
  DecommissionedAssets(){
    this.ddlCodeData[0].forEach(ele => {
      if (ele.codeName.toLowerCase() == 'assetstatuscd' && ele.codeValue.toLowerCase() == 'decommissioned') {
        this.reportFilter.status = ele.codeID.toString()
      }
    })

    this.reportFilter.customerID = this.CustomerID;
    this.reportFilter.locationID = this.LocationID;

    if (this.range.start != null) {
      this.reportFilter.UpdationStartDate = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
      this.reportFilter.UpdationEndDate = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    }
    this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);
    this.sharedDataService.ReportFilterSharedData = this.reportFilter;
    this.router.navigate(["/assets-listing"]);
   
    //this.ApplyFilterReviewer(this.CustomerID, this.LocationID)

  }

  AssetsUpdated() {
    this.reportFilter.customerID = this.CustomerID;
    this.reportFilter.locationID = this.LocationID;

    if (this.range.start != null) {
      this.reportFilter.UpdationStartDate = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
      this.reportFilter.UpdationEndDate = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    }
    this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);
    this.sharedDataService.ReportFilterSharedData = this.reportFilter;
    this.router.navigate(["/assets-listing"]);
  }
}
