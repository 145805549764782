


export class Address {
    public country: string;
    public locality: string;
    public postal_code: string;
    public state: string;
    public sublocality: string;
}
export class LocationImageData {

    public imageDataID: number
    public imageType: string
    public associatedID: number
    public description: string
    public imageData: Array<number>;
    public uid?:string;
    public extension : string;
   
}

export class LocationAdminListingEntity{
    public createdByName: string;
    public updatedByName: string;
    public creationDate: Date;
    public updationDate: Date;
}

export class LocationEntity {
    public locationID?: number
    public locationCode: string
    public debtorCode:string
    public locationName: string
    public customerID: number
    public addressLine1: string
    public addressLine2: string
    public geoLocation: string
    public suburb: string
    public stateCD: number
    public postcode: string
    public countryCd: number
    public accountManagerID: number
    public accountManager: any
    public comments: string
    public locationAuditStatusCd: number
    public  locationAuditStatus:any
    public  active:boolean = true
    public  isDepartment:boolean = false
    public userID=[]
    public user=[]
    public emailRecipients:string;

}
export class LocationRMModel{
public  locationRMID: number;
public  locationID : number;
public  plannedSpend: number;
public  priorityCD : any;
public  assetClass : any;
public  description:string;
public  qty : number;
public  plannedDateWork :Date;
public  statusCD : any;
public  comments: number;
public  createdBy : number;
public  creationDate:Date;
public  updatedBy : number;
public  updationDate:Date;
}

export class AssetTypeKeyValue{
    public assetTypeID:number
    public assetTypeName:string
    public value:number
    public subAssetValue:number
}