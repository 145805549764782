import { SnotifyService } from 'ng-snotify';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementRef, ViewChild, Component } from '@angular/core';
import { UserEntity } from './../add-user/add-user.model';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CoreDataService } from './../../Service/core-data.service';
import { SharedDataService } from '../../Service/CommonUtility.service';
import { UserListEntity } from './user-list.model';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  LanguageConfig: any;
  GridConfig: any
  public checked: boolean = false;
  userData: Array<UserEntity> = [];
  customerData: any;
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.userData, this.filter);
  DateTimeformat: any;
  Dateformat: any;
  public gridData: GridDataResult

  constructor(private notify: SnotifyService, private titleService: Title, private sharedService: SharedDataService, private coreDataService: CoreDataService, private route: ActivatedRoute, private router: Router) {
    this.titleService.setTitle("User Management");
    this.getUserList();
    this.customerData = this.sharedService.parseJwt(localStorage.getItem('token'));
    console.log(this.customerData);
  }
  @ViewChild('userGrid', { read: ElementRef ,static:false}) grid;

  getUserList() {

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[12]
          this.GridConfig = res[2];
        }
      })
    }
    // if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
    //   let langLiteral = this.sharedService.GetLanguageLiteral();
    //   this.GridConfig = GridData[langLiteral];
    // }
    this.coreDataService.getUserList().first()
      .subscribe((data) => {
        if (data != undefined && data !== null) {
          if (this.customerData.RoleType == "ADMIN") {
            this.userData = data.filter(ele => ele.userRole != 'SYS ADMIN');
          }
          else if (this.customerData.RoleType != "ADMIN") {
            this.userData = data;
          }
          this.sharedService.GetOrgSettings().then((res) => {
            if (res != null) {
              this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
              this.Dateformat = res['DateFormat'];
            }
          })
          this.userData.forEach(ele => {

            ele['lastWebloginDate'] = ele['lastWebloginDate'] == '0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['lastWebloginDate'])
            ele['lastDeviceLoginDate'] = ele['lastDeviceLoginDate'] == '0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['lastDeviceLoginDate'])
            ele['lastSyncDate'] = ele['lastSyncDate'] == '0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['lastSyncDate'])
            ele['lastWebloginDateYear'] = new Date(ele['lastWebloginDate']).getFullYear();
            ele['lastDeviceLoginDateYear'] = new Date(ele['lastDeviceLoginDate']).getFullYear();
            ele['lastSyncDateYear'] = new Date(ele['lastSyncDate']).getFullYear();
          })
          this.userData = this.userData
          this.gridData = process(this.userData, this.state);
          this.allData = this.allData.bind(this);

        }
      },
        error => {
          this.notify.error("An Error Occured, error: " + error);
        }
      );
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.userData, this.state);
  }
  MenuOnSelect(event) {

    // var baseUrl = window.location.origin + '/#/AddUser/' + event.dataItem.userID;
    var baseUrl = window.location.origin + '/AddUser/' + event.dataItem.userID;

    if (event.item == "Open") {

      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }
  private state1: State = {
    skip: 0,
    sort: [{ field: 'emailID', dir: 'asc' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public allData(): ExcelExportData {

    if (this.state.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.userData, { sort: [{ field: 'emailID', dir: 'asc' }] }).data,

      };
      return result;
    }
    else {
      this.state1.filter.filters = this.state.filter.filters;

      const result: ExcelExportData = {

        data: process(this.userData, this.state1).data
      };
      return result;
    }

  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: "Active", dir: "desc" }]
    };
    this.gridfilter = filterBy(this.userData, filter);
    this.gridData = process(this.gridfilter, this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.userData, sort), this.state);
  }
  public switchChange(checked: boolean): void {
    const root = this.filter || { logic: "and", filters: [] };
    const [filter] = flatten(root).filter(x => x.field === "active");
    if (!filter) {
      root.filters.push({
        field: "active",
        operator: "eq",
        value: checked
      });
    }
    else {
      filter.value = checked;
    }
    this.checked = checked;
    this.filterChange(root);
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: "active", dir: "desc" }]
    };
  }
  //Add New User or Redirect to Edit
  public AddNewUser() {
    this.sharedService.user = new UserEntity();
    this.sharedService.locationData = [];
    this.router.navigate(["AddUser"]);
  }

  //Edit User
  editUser(val) {
    let userId = val.userID;
    this.router.navigate(['/AddUser', userId]);

  }
  ResetActiveFilter() {
    this.checked = false;
    this.filterChange(undefined);
  }
}
