import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../Service/authentication.service';
import { SnotifyService } from 'ng-snotify';
import { environment } from 'src/environments/environment.prod';
import { from } from 'rxjs';
import { CoreDataService } from '../../Service/core-data.service';

import * as CryptoJS from 'crypto-js';
import { AuthService } from 'src/app/Service/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']

})
export class HeaderComponent implements OnInit {
  toggle: boolean = false;
  UserData: any;
  inputValue: string = "";
  ErrMessagesData: any;
  // DashboardRouting: any;
  LanguageConfig: any;
  BusinessLanguageConfig:any;
  GridConfig: any

  HideShowDashboard: boolean = false;
  HideShowAdminDashboard: boolean = false;
  HideShowReviewerDashboard: boolean = false;
  constructor(private sharedService: SharedDataService, private router: Router, private authservice: AuthenticationService, private coreDataService: CoreDataService,
    private notify: SnotifyService,private authService1: AuthService
  ) {

  }

  logout(value) {
    {

      this.coreDataService.UpdateApplicationLogout().subscribe(res => {
        console.log(res)
        if (res != undefined && res != null && res.message.toLowerCase() == "success") {
          this.authService1.logout();
          localStorage.clear();
          console.log(document.cookie)
          this.authservice.loggedIn$.next(null);
          sessionStorage.clear()
          this.sharedService.ShowChangePass$.next('false');
          this.sharedService.isLoggedOut=true;
          this.notify.info(this.ErrMessagesData.LoggedOut);
         
          this.router.navigate([""]);
        } else {
          // localStorage.clear();
          // this.authservice.loggedIn$.next(null);
          // sessionStorage.clear()
          // if(localStorage.getItem('token') == null || localStorage.getItem('token')== ""){
          //   this.router.navigate([""]);
          // }
          // else{
          if (res == null) {
            this.authService1.logout();
            localStorage.clear();
            console.log(document.cookie)
            this.authservice.loggedIn$.next(null);
            sessionStorage.clear()
            this.sharedService.ShowChangePass$.next('false');
            this.sharedService.isLoggedOut=true;
            this.notify.info(this.ErrMessagesData.LoggedOut);
           
            this.router.navigate([""]);
          }
          else {
            this.notify.error(this.ErrMessagesData.SomethingWentWrong);
          }

          //}

        }
      })

    }
  }

  showpop: boolean;
  ngOnInit() {


    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[3];
          this.BusinessLanguageConfig=res[61];
          this.ErrMessagesData = res[28];
        }
      })
    }

    var token = window.localStorage.getItem('token');
    if (token != null && token != undefined && token != '') {
      this.UserData = this.sharedService.parseJwt(token);

      this.sharedService.ShowChangePass$.subscribe(res => {

        this.showpop = res === "true" ? true : false;
      })

      this.HideShowDashboards();
    }
    else {
      this.router.navigate(['']);
    }
  }

  ChangePassword() {
    this.sharedService.ShowChangePass$.next('false')
    this.router.navigate(['/ChangePassword']);
  }


  onGlobalSearchClick() {
    let globalSearchText = (<HTMLInputElement>document.getElementById('navbar-search-input')).value;
    if (globalSearchText.length >= 3) {
      this.sharedService.globalSearchText$.next(globalSearchText);
      this.router.navigate(['/global-search', globalSearchText]);
    } else {
      this.notify.warning(this.ErrMessagesData.GlobalSearchCharLimit);
    }
  }

  HideShowDashboards() {

    // LIVE AND UAT
    let role: string
    role = this.UserData.RoleType;

    if (role.toLowerCase() == 'customer') { //Customer
      this.HideShowDashboard = true;
    } else if (role.toLowerCase() == 'auditor') {//Auditor
      this.HideShowDashboard = true;
    }
    else if (role.toLowerCase() == 'reviewer') {//Reviewer
      this.HideShowDashboard = true;
      this.HideShowReviewerDashboard = true;
      this.HideShowAdminDashboard = true;

    }
    else if (role.toLowerCase() == 'sys admin') {//Sys Admin
      this.HideShowReviewerDashboard = true;
      this.HideShowAdminDashboard = true;
      this.HideShowDashboard = true;
    }
    else if (role.toLowerCase() == 'admin') {//Sys Admin
      this.HideShowReviewerDashboard = true;
      this.HideShowAdminDashboard = true;
      this.HideShowDashboard = true;
    }
  
  }

  navigate(){
    this.router.navigateByUrl('/help')
  }
}
