import { Observable } from 'rxjs/Rx';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
@Injectable()
export class RequestPasswordAuthGuardService implements CanActivate {
  userStatus: boolean;
  validityStatus: boolean;
  constructor(
    private authService: AuthenticationService,
    private router: Router)
     {
    this.userStatus = this.authService.loggedIn;
  }
  canActivate(snapshot: ActivatedRouteSnapshot, status: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    {
      if (!this.userStatus) {
            return true;
        }
      else{
           //Redirect to Login Page
           this.router.navigateByUrl('');
      }
    }
  }
}
