import { UserEntity, LinkLocation } from '../SystemAdmin/add-user/add-user.model';
import { Router } from '@angular/router';
import { Injectable, ViewChild, TemplateRef } from '@angular/core';
import { StatusCodes } from '../Common/shared-model';
import { CoreDataService } from './core-data.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '@progress/kendo-angular-notification';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { SnotifyService } from 'ng-snotify';
import * as CryptoJS from 'crypto-js';
import { OrgSettingEntity } from '../SystemAdmin/organisation-setting/organisation-setting.model';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { retry } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
// import { ContractEntity, GroupEntity, ContractTaskEntity, EquipmentEntity } from './../../ACMS/Model/ContractEntity';
// import { ContractEntity, GroupEntity, ContractTaskEntity, EquipmentEntity } from './../../ACMS/Model/ContractEntity';

@Injectable()
export class SharedDataService {
  GridSaveFieldGroup: Array<any> = [];
  jsoinEditedFormat = {};
  griddata = [];
  defaultFieldTemplate: Array<any>;
  defaultGridData: Array<any> = [];
  AuditorReviewer: boolean = false;
  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private notify: SnotifyService,
    private notificationService: NotificationService,
    private coreDataService: CoreDataService,
    private router: Router,
    private toastSrvc: ToastrService
  ) {}
  //Shared Meta Data
  public user: UserEntity = new UserEntity();
  public password: string;
  public locationData: Array<LinkLocation> = [];
  @ViewChild("template", { read: TemplateRef ,static:false})
  public notificationTemplate: TemplateRef<any>;

  //For GlobalSearch
  public globalSearchText: string = "";
  globalSearchText$ = new BehaviorSubject<string>(this.globalSearchText);

  public AssetGroups: Array<{ groupName: string; assetGroupID: number }> = [];

  // For repot
  public ReportFilterBeh: ReportFilter = new ReportFilter();
  ReportFilterBeh$ = new BehaviorSubject<ReportFilter>(this.ReportFilterBeh);
  ReportFilterSharedData: ReportFilter = new ReportFilter();
  //EncodedLink for Update Password
  public encodedLink: string = "";
  encodedLink$ = new BehaviorSubject<string>(this.encodedLink);

  //Clone for subAsset
  public SubAssetClone: string;
  SubAssetClone$ = new BehaviorSubject<string>("false");

  public ShowChangePass: string;
  ShowChangePass$ = new BehaviorSubject<string>("false");

  public languageConfig: any = "";
  languageConfig$ = new BehaviorSubject<string>(this.languageConfig);

  public ContentConfig: any = "";
  ContentConfig$ = new BehaviorSubject<string>(this.ContentConfig);

  public ShowDefaultFields: string;
  ShowDefaultFields$ = new BehaviorSubject<string>("false");

  public SharedUserActive: boolean = false;

  public nfactor:number;
  public RMCustomer:string;
  public RMLocationID:number
  public isLoggedOut:boolean=false



  GetLanguageLiteral() {
    if (
      this.cookieService.check("EvalLangPref") != null &&
      this.cookieService.check("EvalLangPref")
    ) {
      return this.cookieService.get("EvalLangPref");
    } else {
      this.cookieService.set("EvalLangPref", environment.environment.language);
      return environment.environment.language;
    }
  }

  GetMap() {
    if (
      this.cookieService.check("EvalMapPref") != null &&
      this.cookieService.check("EvalMapPref")
    ) {
      return this.cookieService.get("EvalMapPref");
    } else {
      this.cookieService.set("EvalMapPref",  'Google');
      return  'Google';
    }
  }

  search(nameKey, myArray) {
    // for (var i = 0; i < myArray.length; i++) {
    //   if (myArray[i].orgKey === nameKey) {
    //     return myArray[i].orgValue;
    //   }
    // }
    var value = myArray.find(function (res) {
      return res.orgKey == nameKey;
    });
    return value.orgValue;
  }
  convertModelToList(orgSettingEntity) {
    let arr = [];
    Object.entries(orgSettingEntity).map((ob) => {
      arr.push({ orgKey: ob[0], orgValue: ob[1] });
    });
    return arr;
  }

  async GetOrgSettings() {
    let OrgToken = window.localStorage.getItem("OrgSetting");

    if (OrgToken != null && OrgToken != undefined) {
      let UserData = this.GetUserToken();
      let Decript = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("OrgSetting").trim(),
          UserData.EmailID
        ).toString(CryptoJS.enc.Utf8)
      );
      return Decript;
    } else {
      // let res=  await this.http.get<OrgSettingEntity>(environment.environment.apiUrl + "OrganisationSettings").toPromise();
      let res = await this.coreDataService.GetOrgsSettings();

      let UserData = this.GetUserToken();
      let unEncryptedToken = this.convertListToModel(res);
      var hash = CryptoJS.AES.encrypt(
        JSON.stringify(unEncryptedToken),
        UserData.EmailID
      ).toString();
      localStorage.setItem("OrgSetting", hash);
      return unEncryptedToken;
    }
  }

  GetUserToken() {
    let token = window.localStorage.getItem("token");
    if (token != undefined && token != "") {
      return this.parseJwt(token);
    } else {
      return undefined;
    }
  }

  convertListToModel(model: any) {
    return model.reduce((obj, item) => {
      obj[item.orgKey] = item.orgValue;
      return obj;
    }, {});
  }

  //Kendo Notifications
  public ErrorHandler(Response: any) {
    switch (Response.status) {
      case StatusCodes.Continue: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.SwitchingProtocols: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.Created: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.NonAuthoritativeInformation: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.ResetContent: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.PartialContent: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.MultipleChoices: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.Ambiguous: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.MovedPermanently: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.Moved: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.Found: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.Redirect: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.SeeOther: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.RedirectMethod: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.NotModified: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.UseProxy: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.Unused: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.TemporaryRedirect: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.RedirectKeepVerb: {
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue"
        );
        break;
      }
      case StatusCodes.BadRequest: {
        this.showError("Bad Request. Please Contact Support. ");
        break;
      }
      case StatusCodes.Unauthorized: {
        localStorage.clear();
        this.router.navigate(["/login"]);
        this.showError(
          "Unauthorized Requet, Please Login or refresh to continue."
        );
        break;
      }
      //case StatusCodes.PaymentRequired: {
      //    this.showError("Unauthorized Requet, Please Login or refresh to continue");
      //    break;
      //}
      case StatusCodes.Forbidden: {
        this.showError("Requet Forbidden, Please Contact Support.");
        break;
      }
      case StatusCodes.NotFound: {
        this.showError("Requet not found, Please Contact Support.");
        break;
      }
      case StatusCodes.MethodNotAllowed: {
        this.showError("Request not allowed, Please Contact Support.");
        break;
      }
      case StatusCodes.NotAcceptable: {
        this.showError("Request Not accepted, Please Contact Support.");
        break;
      }
      case StatusCodes.ProxyAuthenticationRequired: {
        this.showError(
          "Proxy Authentication Required, Please Contact Support."
        );
        break;
      }
      case StatusCodes.RequestTimeout: {
        this.showError("Requet timeout, Please Contact Support.");
        break;
      }
      case StatusCodes.Conflict: {
        this.showError("Request Conflict, Please Contact Support.");
        break;
      }
      case StatusCodes.Gone: {
        this.showError(
          "Requested Resource is no longer available, Please Contact Support."
        );
        break;
      }
      case StatusCodes.LengthRequired: {
        this.showError(
          "Required header length missing, Please Contact Support."
        );
        break;
      }
      //case StatusCodes.PreconditionFailed: {
      //    this.showError("Unauthorized Requet, Please Contact Support");
      //    break;
      //}
      case StatusCodes.RequestEntityTooLarge: {
        this.showError("Request too long, Please Contact Support.");
        break;
      }
      case StatusCodes.RequestUriTooLong: {
        this.showError("Request URI too long, Please Contact Support.");
        break;
      }
      case StatusCodes.UnsupportedMediaType: {
        this.showError("Request type not supported, Please Contact Support.");
        break;
      }
      case StatusCodes.RequestedRangeNotSatisfiable: {
        this.showError(
          "Requested Range Not Satisfiable, Please Contact Support."
        );
        break;
      }
      case StatusCodes.ExpectationFailed: {
        this.showError("Expect header failed, Please Contact Support.");
        break;
      }
      case StatusCodes.UpgradeRequired: {
        this.showError(
          "Network Protocol needs to be changed, Please Contact Support."
        );
        break;
      }
      case StatusCodes.InternalServerError: {
        this.showError("Internal Server Error, Please Contact Support");
        break;
      }
      case StatusCodes.NotImplemented: {
        this.showError(
          "Server does not support the requested function, Please Contact Support."
        );
        break;
      }
      case StatusCodes.BadGateway: {
        this.showError("Bad Gateway, Please Contact Support.");
        break;
      }
      case StatusCodes.ServiceUnavailable: {
        this.showError("Service/Server unavailable, Please Contact Support.");
        break;
      }
      case StatusCodes.GatewayTimeout: {
        this.showError("Server Timeout, Please Contact Support.");
        break;
      }
      case StatusCodes.HttpVersionNotSupported: {
        this.showError("Incorrect HTTP Version, Please Contact Support.");
        break;
      }
      default: {
        this.showError("error ! ");
        break;
      }
    }
  }
  showSuccess(message, toastOptions?) {
    this.notificationService.show({
      content: message,
      hideAfter: 2000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "success", icon: true },
    });
  }
  showError(message, toastOptions?, ActionObject?) {
    this.notificationService.show({
      content: message,
      hideAfter: 2000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "error", icon: true },
    });
  }
  showWarning(message, toastOptions?) {
    this.notificationService.show({
      content: message,
      hideAfter: 2000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "warning", icon: true },
    });
  }
  showInfo(message, toastOptions?) {
    this.notificationService.show({
      content: message,
      hideAfter: 2000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "info", icon: true },
    });
  }

  checkEmailValidity(email) {
    var emails = email.split(",");
    var validEmails;
    validEmails = emails.filter((obj) => {
      return obj.match(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    }); ///^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (validEmails.length != emails.length) {
      return false;
    } else {
      return true;
    }
  }
  RouteToRoleSpecificDashboard() {
    var token = window.localStorage.getItem("token");
    let UserData = this.parseJwt(token);
    let role: string;
    role = UserData.RoleType;
    // LIVE AND UAt
    if (role.toLowerCase() == "customer") {
      //Customer
      this.router.navigate(["/dashboard"]);
    } else if (role.toLowerCase() == "auditor") {
      //Auditor
      this.router.navigate(["/dashboard"]);
    } else if (role.toLowerCase() == "reviewer") {
      //Reviewer
      this.router.navigate(["/MyTeamDashboard"]);
    } else if (role.toLowerCase() == "sys admin") {
      //Sys Admin
      this.router.navigate(["/AdminDashboard"]);
    } else if (role.toLowerCase() == "admin") {
      //Sys Admin
      this.router.navigate(["/AdminDashboard"]);
    }
  }
  IsCustomer(): string {
    var token = window.localStorage.getItem("token");

    if (token != null && token != undefined && token != "") {
      // var full = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+'/';
      let data = this.parseJwt(token);
      return data.IsCustomer;
    } else {
      return "True";
    }
  }

  IsAdmin(): string {
    var token = window.localStorage.getItem("token");

    if (token != null && token != undefined && token != "") {
      // var full = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+'/';
      let data = this.parseJwt(token);
      return data.IsAdmin;
    } else {
      return "True";
    }
  }

  IsAuditorOrReviewer(): boolean {
    var token = window.localStorage.getItem("token");
    if (token != null && token != undefined && token != "") {
      // var full = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+'/';
      let data = this.parseJwt(token);
      if (data.IsCustomer == "False" && data.IsAdmin == "False") {
        this.AuditorReviewer = true;
      }
      return this.AuditorReviewer;
    }
  }

  IsSystemAdmin(): boolean {
    var token = window.localStorage.getItem("token");
    if (token != null && token != undefined && token != "") {
      // var full = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+'/';
      let data = this.parseJwt(token);
      if (data.RoleType.toLowerCase().trim() == "sys admin") {
        return true;
      } else {
        return false;
      }
    }
  }

  parseJwt(token) {
    // var base64Url = token.split('.')[1];
    // var base64 = base64Url.replace('-', '+').replace('_', '/');
    // return JSON.parse(window.atob(base64));
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  //Sorting array by attribute => array: arrayData/List, key: attribute Name like LocationName','CustomerName'
  sortArrayByAttribute(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  //Sorting an array with Multiple Attributes : using predicate
  // Example ;  data.sort(predicate({
  //           name: 'AGE',
  //           reverse: false
  //           }, 'USERNAME', 'LOCATIONNAME'));

  predicate({ name, reverse }, attrb1: string, attrb2: string) {
    var fields = [],
      n_fields = arguments.length,
      field,
      name,
      reverse,
      cmp;

    var default_cmp = function (a, b) {
        if (a === b) return 0;
        return a < b ? -1 : 1;
      },
      getCmpFunc = function (primer, reverse) {
        var dfc = default_cmp,
          // closer in scope
          cmp = default_cmp;
        if (primer) {
          cmp = function (a, b) {
            return dfc(primer(a), primer(b));
          };
        }
        if (reverse) {
          return function (a, b) {
            return -1 * cmp(a, b);
          };
        }
        return cmp;
      };

    // preprocess sorting options
    for (var i = 0; i < n_fields; i++) {
      field = arguments[i];
      if (typeof field === "string") {
        name = field;
        cmp = default_cmp;
      } else {
        name = field.name;
        cmp = getCmpFunc(field.primer, field.reverse);
      }
      fields.push({
        name: name,
        cmp: cmp,
      });
    }

    // final comparison function
    return function (A, B) {
      var a, b, name, result;
      for (var i = 0; i < n_fields; i++) {
        result = 0;
        field = fields[i];
        name = field.name;

        result = field.cmp(A[name], B[name]);
        if (result !== 0) break;
      }
      return result;
    };
  }

  private subject = new Subject<any>();

  sendMessage(message: string) {
    this.subject.next({ title: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  setConfig(message: string) {
    this.subject.next({ title: message });
  }

  getConfig(): Observable<any> {
    return this.subject.asObservable();
  }
}

export class ReportFilter {
  public customerID: any;
  public assetTypeID: string;
  public locationID?: any;
  public conditionRating: string;
  public status: string;
  public criticality: string;
  public assetOwnership: string;
  public filtersFitted: string;
  public refrigerant: string;
  public assetGroup: string;
  public ageGroup: string
  public remainingLife: string;
  public sortBy: string;
  public OHSComments?: number;
  public serviceCalls: string;
  public replacementYear: string;
  public replacementCost: string;
  public totalSpend: string;
  // public capRange?: any;
  public startDate?: any;
  public endDate?: any;
  public capacityStart?: any
  public capacityEnd?: any
  public installStartDate?: any
  public installEndDate?: any
  public state?: string
  //NEW FILTER TYPE
  public isIncludeAllFields?: boolean = false
  public iMStatus?:any
  public checkInStartDate?:any;
  public checkInEndDate?:any;
  public AssetID?:string;
  public UpdationStartDate?:any
  public UpdationEndDate?:any

}
