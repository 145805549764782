import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { AssetListingEntity, AssetGeoLocationUpdate } from './business-assets.component.model';
///<reference types="@types/googlemaps" />
import * as MarkerClusterer from "@google/markerclusterer";

import { ActivatedRoute } from "@angular/router";
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService, ReportFilter } from '../../Service/CommonUtility.service'
import { Router } from "@angular/router";
import { GridDataResult, DataStateChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { google } from '@google/maps';
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PortfolioReportEntity } from '../Reports/reports.model';
import { environment } from "../../../environments/environment";
import { ExcelServiceExport } from "../../Service/ExcelExport.service";
import { ChangeDetectorRef } from '@angular/core';
declare var google: any;

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-business-assets',
  templateUrl: './business-assets.component.html',
  styleUrls: ['./business-assets.component.scss']
})
export class BusinessAssetsComponent implements OnInit {

  map: google.maps.Map;

  markerClusters: Array<MarkerClusterer>;
  mapData: Array<AssetListingEntity> = [];   //The locations Lat-Long to Draw on Map

  // assetListData : AssetListingEntity =  new AssetListingEntity();
  fields: string[] ;

  imageUrl = 'https://chart.apis.google.com/chart?cht=mm&chs=24x32&chco=FFFFFF,008CFF,000000&ext=.png';
  oms: OverlappingMarkerSpiderfier;
  //--End : Map MetaData
  DownloadAssetCount: number;
  constructor(private cd: ChangeDetectorRef,private excelService: ExcelServiceExport, private titleService: Title, private activatedRoute: ActivatedRoute, private notify: SnotifyService, private route: ActivatedRoute, private DataService: CoreDataService, private router: Router, private sharedDataService: SharedDataService) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  assetList: any[] = [];
  assetListExport: any[] = [];

  AssetGeoLocationUpdate: AssetGeoLocationUpdate = new AssetGeoLocationUpdate();
  DateTimeformat: any;
  Dateformat: any;
  IsCustomer: string;
  value: string;
  private state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  private state1: State = {
    skip: 0,
    sort: [{ field: 'userAssetID', dir: 'asc' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public clear_state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: "and",
      filters: []
    }
  }


  private stateMap: State = {
    skip: 0,
    sort: [{ field: "active", dir: "desc" }],
  };
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.assetList, this.filter);
  private gridData: GridDataResult;
  CustomerIDModel: number;
  LocationIDModel: number;
  portfolioReportEntity: PortfolioReportEntity = new PortfolioReportEntity();
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.assetList, sort), this.state);
  }

  public mySelection: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  sub: any;
  lat: any = 0;
  long: any = 0;
  ErrorMsgData: any;

  data: Array<any> = [{
    text: 'Request PDF report',
  },
  { text: 'Request DOC report', }];

  LanguageConfig: any;
  BusinessLanguageConfig: any;
  
  GridConfig: any

  ReportFilter: ReportFilter = new ReportFilter();
  message: any;
  subscription: Subscription;
  AssetDataLabel : any
  expiredBooking:string;
  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];


      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[5];
          this.BusinessLanguageConfig = res[59];
          this.GridConfig = res[2];
          this.ErrorMsgData = res[28];
          this.AssetDataLabel = res[30];
        }
      })
    }
    this.DownloadAssetCount = environment.environment.DownloadAssetCount;
    this.value = this.route.snapshot.paramMap.get('name');
    if (this.value != undefined && this.value != null && this.value != '') {
      this.state.filter.filters = [{ field: 'assetLocationID', operator: 'contains', value: this.value.toString() }];
      this.ReportFilter.customerID = this.route.snapshot.paramMap.get('customerID') == null ? undefined : Number.parseInt(this.route.snapshot.paramMap.get('customerID'));
      // this.ReportFilter.locationID = this.route.snapshot.paramMap.get('locationID') == null ? undefined : this.route.snapshot.paramMap.get('locationID');
      this.ReportFilter.locationID = this.route.snapshot.paramMap.get('locationID') == null ? undefined : Number.parseInt(this.route.snapshot.paramMap.get('locationID'));
      this.sharedDataService.ReportFilterBeh$.next(this.ReportFilter);
    }
    this.IsCustomer = this.sharedDataService.IsCustomer();
    //})
    // this.sharedDataService.ReportFilterSharedData

    // NEW CODE WHILE TRAVERSING FROM REPORTS
    this.sharedDataService.ReportFilterBeh$.subscribe((object: ReportFilter) => {
      let cust = this.sharedDataService.ReportFilterSharedData['customerID']
      let loc = this.sharedDataService.ReportFilterSharedData['locationID']
      let objReport: ReportFilter = new ReportFilter()
      let objReportObj: ReportFilter = object
      if (JSON.stringify(objReportObj) === JSON.stringify(objReport)) {
        //  obj = new ReportFilter()
        //  obj = object
        // obj.customerID = cust
        // obj.locationID = loc
        this.ReportFilter = new ReportFilter()
        this.ReportFilter.customerID = cust
        this.ReportFilter.locationID = loc
        if (cust != undefined && loc != undefined) {
          // this.ReportFilter.isIncludeAllFields = false
        //  this.getAssetDetails(this.ReportFilter)
        }
        return
      }
    })
    // NEW CODE WHILE TRAVERSING FROM REPORTS




    this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
    if (this.sharedDataService.ReportFilterSharedData != undefined) {
      this.ReportFilter.state = this.sharedDataService.ReportFilterSharedData.state;
      this.ReportFilter.sortBy = this.ReportFilter.sortBy == undefined ? "1" : this.ReportFilter.sortBy
      this.ReportFilter.OHSComments = this.ReportFilter.OHSComments == undefined ? 2 : this.ReportFilter.OHSComments
      this.ReportFilter.conditionRating = this.ReportFilter.conditionRating == undefined ? "" : this.ReportFilter.conditionRating
      this.ReportFilter.criticality = this.ReportFilter.criticality == undefined ? "" : this.ReportFilter.criticality

      this.ReportFilter.filtersFitted = this.ReportFilter.filtersFitted == undefined ? "" : this.ReportFilter.filtersFitted
      this.ReportFilter.refrigerant = this.ReportFilter.refrigerant == undefined ? "" : this.ReportFilter.refrigerant
      this.ReportFilter.remainingLife = this.ReportFilter.remainingLife == undefined ? "" : this.ReportFilter.remainingLife
      this.ReportFilter.replacementCost = this.ReportFilter.replacementCost == undefined ? "" : this.ReportFilter.replacementCost
      this.ReportFilter.replacementYear = this.ReportFilter.replacementYear == undefined ? "" : this.ReportFilter.replacementYear
      this.ReportFilter.serviceCalls = this.ReportFilter.serviceCalls == undefined ? "" : this.ReportFilter.serviceCalls
      this.ReportFilter.totalSpend = this.ReportFilter.totalSpend == undefined ? "" : this.ReportFilter.totalSpend
      this.ReportFilter.sortBy = this.ReportFilter.sortBy == undefined ? "" : this.ReportFilter.sortBy
      this.ReportFilter.status = this.ReportFilter.status == undefined ? "" : this.ReportFilter.status
      this.ReportFilter.ageGroup = this.ReportFilter.ageGroup == undefined ? "" : this.ReportFilter.ageGroup
      this.ReportFilter.assetOwnership = this.ReportFilter.assetOwnership == undefined ? "" : this.ReportFilter.assetOwnership
      this.ReportFilter.assetTypeID = this.ReportFilter.assetTypeID == undefined ? "" : this.ReportFilter.assetTypeID
      this.ReportFilter.assetGroup = this.ReportFilter.assetGroup == undefined ? "" : this.ReportFilter.assetGroup

      this.ReportFilter.capacityStart = this.ReportFilter.capacityStart == undefined ? undefined : this.ReportFilter.capacityStart
      this.ReportFilter.capacityEnd = this.ReportFilter.capacityEnd == undefined ? undefined : this.ReportFilter.capacityEnd

      this.ReportFilter.installStartDate = this.ReportFilter.installStartDate == undefined ? "" : this.ReportFilter.installStartDate
      this.ReportFilter.installEndDate = this.ReportFilter.installEndDate == undefined ? "" : this.ReportFilter.installEndDate

      this.getAssetDetails(this.ReportFilter)
    }
    //  console.log(this.ReportFilter)

    // this.ReportFilter = new ReportFilter();

    this.subscription = this.sharedDataService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message.title = 'filter') {
       // this.sub = this.sharedDataService.ReportFilterBeh$.subscribe(res => {
         // this.ReportFilter = res;
         this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
          this.getAssetDetails(this.ReportFilter);
        //});
      }
    })
  }



  public onSelectedKeysChange(e) {
    const len = this.mySelection.length;

    if (len <= 10000) {
      if (len === 0) {
        this.selectAllState = 'unchecked';
      } else if (len > 0 && len < this.assetList.length) {
        this.selectAllState = 'indeterminate';
      } else {
        this.selectAllState = 'checked';

      }
      this.gridData = process(this.assetList, this.state);
    }
    else {
      this.mySelection = [];
      this.notify.warning(this.ErrorMsgData.maxLimitRecords);
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.mySelection = this.assetList.map((item) => item.assetID);
      this.selectAllState = 'checked';
    } else {
      // this.mySelection = [];
      this.selectAllState = 'unchecked';
    }
    this.gridData = process(this.assetList, this.state);

  }

  FieldNameChange(FieldName: string) {
    if(this.AssetDataLabel != undefined){
      var FieldLabel = this.AssetDataLabel.filter((data) => { return FieldName.toLowerCase().toString() === data.FieldName.toLowerCase().toString() })
      return FieldLabel[0] == undefined ? FieldName : FieldLabel[0].DisplayName ;
    }
  }


  excelReportEntity: any
  IsPreventedExport: boolean = true
  public onExcelExport(e: any): void {
    e.prevented = this.IsPreventedExport
    this.IsReportOptionsEnabled = (this.excelReportEntity != undefined && this.excelReportEntity.IsOpened == false) ? false : true

    this.excelReportEntity = e
    this.excelReportEntity.DataLength = this.DataLength

    //this.DownloadAssetCount
    if (this.DataLength > this.DownloadAssetCount) {

      e.prevented = true;
      this.notify.warning(this.ErrorMsgData.manyRecords);
    }

  }

  SelectedAssetIDs: any = []
  ExcelElement : any;
  ExcelReportEntityEvent(event) {
    console.log(event)
    this.IsPreventedExport = false
    this.IsReportOptionsEnabled = false    
    this.ReportFilter = this.sharedDataService.ReportFilterSharedData
    this.ReportFilter.isIncludeAllFields = event.isIncludeAllAssetFields
    
    this.GetAllFieldsAssetData(this.ReportFilter)
    // console.log(document.getElementById('excelID').click())
    // //RESET VALUES BLOCK
    // this.IsPreventedExport = true
  }


  ExportAssetExcel(element){
    console.log(element)    
    this.ExcelElement = element    
    console.log(this.DataLength)
        
    //COLUMN'S RENDERING
    // this.fields = Object.keys(this.assetList[0]);
    //COLUMN'S RENDERING    
  }

  GetAllFieldsAssetData(data) {
    this.DataService.GetAssetReport(data).subscribe((res) => {
      this.assetList = res
      this.assetList.forEach(ele => {

        // ele['creationDate'] = new Date(ele['creationDate'])
        // ele['updationDate'] = ele['updationDate']
        ele['assetCaptureDate'] = ele['assetCaptureDate'] == '0001-01-01T00:00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['assetCaptureDate'])
        ele['updationDate'] = ele['updationDate'] == '0001-01-01T00:00:00+00:00' ? new Date("0001-01-01T00:00:00+00:00") : new Date(ele['updationDate'])
        ele['creationDate'] = ele['creationDate'] == '0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['creationDate'])
        ele['updationDateYear'] = new Date(ele['updationDate']).getFullYear();
        ele['creationDateYear'] = new Date(ele['creationDate']).getFullYear();
        ele['assetCaptureDateYear'] = new Date(ele['assetCaptureDate']).getFullYear();
      })

       this.fields =  Object.keys(this.assetList[0]);

      // this.fields = []  
      // Object.keys(this.assetList[0]).forEach((elem) =>{        
      //   this.fields.push(this.FieldNameChange(elem))
      // })

      // this.assetList = this.assetList;
      // this.gridData = process(this.assetList, this.state);
      
      // this.mapData = this.assetList;
      // this.allData = this.allData.bind(this);
      // this.assetListExport = this.allData().data

      // this.cd.detectChanges();

      //NEW CODE      
      //this.ExcelElement.save()
      //this.ReportFilter = new ReportFilter();
      // console.log(document.getElementById('excelID').click())
      // console.log(document.getElementById('excelexport').click())
      
      //RESET VALUES BLOCK
      //this.IsPreventedExport = true

    })
  }

  // exportAsXLSX():void {
  //   this.excelService.exportAsExcelFile(this.data, 'sample');
  // }

  GenerateReport(extension) {

    let FileExtension;
    if (extension == 'Request PDF report') {
      FileExtension = 'PDF'
    }
    else if (extension == 'Request DOC report') {
      FileExtension = 'DOC'
    }
    this.GeneratePortfolioReport(FileExtension.toString(), this.mySelection.toString())
  }

  getAssetDetails(data) {
    //  this.DateTimeformat = this.sharedDataService.dateTimeFormat()

    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
        this.Dateformat = res['DateFormat'];
      }
    })
    // this.Dateformat = this.sharedDataService.dateFormat();
    //this.Dateformat =  "yyyy/MM/dd";


    this.DataService.GetBusinessAssetListing(data).subscribe((res) => {
      // this.assetList=res.map(function(r){
      //   var t= new AssetListingEntity();
      //   t.creationDate=r.creationDate;
      //   return t;
      // })
      this.assetList = res
      console.log(res)

      var nowDate = new Date(); 
      let todayDate:any = nowDate.getFullYear()+'-'+(nowDate.getMonth()+1)+'-'+nowDate.getDate(); 

      let today:any = new Date();
      let yesterdayDate:any = new Date();
      yesterdayDate.setDate(today.getDate() - 1); 
      //yesterdayDate = yesterdayDate.getFullYear()+'-'+"0"+(yesterdayDate.getMonth()+1)+'-'+"0"+yesterdayDate.getDate();

      //console.log(yesterdayDate)
      this.assetList.forEach(ele => {

        // ele['creationDate'] = new Date(ele['creationDate'])
        // ele['updationDate'] = ele['updationDate']
        let returnByDate = new Date(ele['returnBYDate']);
        if(returnByDate != null){
        
          console.log(returnByDate)
          if(yesterdayDate .toISOString()> returnByDate.toISOString() &&new Date(0).toISOString()!=returnByDate.toISOString()&& ele['bookingStatus'] == 'Checked Out') {
         
            this.expiredBooking = 'expired';
        }
        }
      
        

        
        // ele['assetCaptureDateYear'] = new Date(ele['assetCaptureDate']).getFullYear();
      })

      this.assetList = this.assetList;
      this.gridData = process(this.assetList, this.state);
      this.assetList.forEach(ele => {

        // ele['creationDate'] = new Date(ele['creationDate'])
        // ele['updationDate'] = ele['updationDate']
        ele['assetCaptureDate'] = ele['assetCaptureDate'] == '0001-01-01T00:00:00' ? '' : new Date(ele['assetCaptureDate'])
        ele['updationDate'] = ele['updationDate'] == '0001-01-01T00:00:00+00:00' ? '' : new Date(ele['updationDate'])
        ele['creationDate'] = ele['creationDate'] == '0001-01-01T00:00:00+00:00' ? '' : new Date(ele['creationDate'])
        ele['updationDateYear'] = new Date(ele['updationDate']).getFullYear();
        ele['creationDateYear'] = new Date(ele['creationDate']).getFullYear();
        ele['assetCaptureDateYear'] = new Date(ele['assetCaptureDate']).getFullYear();
      })
      this.mapData = this.assetList;
      this.allData = this.allData.bind(this);

      // console.log(this.gridData)
      //FOR TESTING
      // this.fields = Object.keys(this.assetList[0]);
      // this.assetListExport = this.assetList
      // this.fields = Object.keys(this.gridData.data[0]);
      this.ReportFilter = new ReportFilter();

    })
  }

//getdate only without time
convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

  IsReportOptionsEnabled: boolean = false
  IsImageOptionsDisabled: boolean = false
  IsAssetFieldOptionsDisabled: boolean = true
  IsEmailRecipientDisabled: boolean = false

  GeneratePortfolioReport(extension, assetID) {
    this.portfolioReportEntity = new PortfolioReportEntity();

    this.portfolioReportEntity.format = extension;
    this.portfolioReportEntity.assetID = assetID;
    this.portfolioReportEntity.status = "Queued";

    this.portfolioReportEntity.customerID = this.ReportFilter.customerID;
    // this.portfolioReportEntity.locationID = this.ReportFilter.locationID.toString();
    this.portfolioReportEntity.locationID = this.ReportFilter.locationID;
    this.portfolioReportEntity.state = this.ReportFilter.state;
    this.portfolioReportEntity.conditionRating = this.ReportFilter.conditionRating;
    this.portfolioReportEntity.assetStatus = this.ReportFilter.status;
    this.portfolioReportEntity.assetCategory = this.ReportFilter.assetGroup;
    this.portfolioReportEntity.criticality = this.ReportFilter.criticality;
    this.portfolioReportEntity.ownership = this.ReportFilter.assetOwnership;
    this.portfolioReportEntity.ageGroup = this.ReportFilter.ageGroup;
    this.portfolioReportEntity.filtersFitted = this.ReportFilter.filtersFitted;
    this.portfolioReportEntity.replacementCost = this.ReportFilter.replacementCost;
    this.portfolioReportEntity.totalSpend = this.ReportFilter.totalSpend;
    this.portfolioReportEntity.remainingLife = this.ReportFilter.remainingLife;
    this.portfolioReportEntity.refrigerant = this.ReportFilter.refrigerant;
    this.portfolioReportEntity.replacementYear = this.ReportFilter.replacementYear;
    this.portfolioReportEntity.assetType = this.ReportFilter.assetTypeID;
    this.portfolioReportEntity.ohsComments = this.ReportFilter.OHSComments;
    this.portfolioReportEntity.assetSortBy = this.ReportFilter.sortBy;
    this.portfolioReportEntity.assetFields = "";
    if (this.ReportFilter != undefined && this.ReportFilter.capacityStart != null && this.ReportFilter.capacityStart != undefined) {
      this.portfolioReportEntity.capacity = this.ReportFilter.capacityStart + ',' + this.ReportFilter.capacityEnd;
    } else {
      this.portfolioReportEntity.capacity = undefined;
    }

    if (this.ReportFilter != undefined && this.ReportFilter.startDate != null && this.ReportFilter.startDate != undefined) {
      this.portfolioReportEntity.creationDateRange = this.ReportFilter.startDate + ',' + this.ReportFilter.endDate;
    } else {
      this.portfolioReportEntity.creationDateRange = undefined;
    }

    if (this.ReportFilter != undefined && this.ReportFilter.installStartDate != null && this.ReportFilter.installStartDate != undefined) {
      this.portfolioReportEntity.installDateRange = this.ReportFilter.installStartDate + ',' + this.ReportFilter.installEndDate;
    } else {
      this.portfolioReportEntity.installDateRange = undefined;
    }
    //NEW CHANGES NEEDS TO BE ADDED
    //DAILOG POP-UP GeneratePortfolioReport               
    this.IsReportOptionsEnabled = true
    return
  }

  portfolioReportEntityEvent(event) {
    console.log(event)

    this.portfolioReportEntity = event
    this.IsReportOptionsEnabled = false
    this.DataService.SavePortolioReport(this.portfolioReportEntity).subscribe((response) => {
      if (response != undefined && response != null) {
        this.notify.success(this.ErrorMsgData.reportSchedule);
      } else {
        this.notify.warning(this.ErrorMsgData.noDataAvailable);
      }
    })

  }


  IsOpenedBooking : boolean = false
  assetID : number = 0
  bookingID : number = 0
  bookingStatus : string = null
  OpenBooking(assetID,bookingStatus){
    
    // this.IsOpenedBooking =  null
    // this.assetID = null

    this.IsOpenedBooking =  true
    this.assetID = assetID
    // this.bookingID = bookingID     
    this.bookingStatus = bookingStatus == null ? 'Available' : bookingStatus  
    //  console.log(this.bookingStatus)
    // console.log(assetID)
  }
  
  CloseBookingEvent(event){
    if(event.opened != undefined){
      this.IsOpenedBooking = event.opened
      this.assetID = event.assetID      
      
    }
    else {
      this.IsOpenedBooking = false
      this.assetID = 0
    }
    // this.getAssetDetails(this.ReportFilter)
    if(event.IsRefresh){
      this.getAssetDetails(this.ReportFilter)
    } 
    
    // else if(event.assetID != undefined){
    //   this.assetID = event.assetID
    // }
    // else if(event.assetID == undefined){
    //   this.assetID = 0
    // }
  }


  RefreshData() {
    this.ReportFilter = this.sharedDataService.ReportFilterSharedData;
    // this.sharedDataService.ReportFilterBeh$.subscribe(res => {
    //   this.ReportFilter = res;
    this.getAssetDetails(this.ReportFilter);
    // })

  }
  DataLength: any;
  public allData(): ExcelExportData {

    if (this.state.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.assetList, { sort: [{ field: 'userAssetID', dir: 'asc' }] }).data,

      };      
      // console.log(result.data)
      // this.DataLength = result.data.length
      // this.assetListExport = result.data
      return result;
    }
    else {
      this.state1.filter.filters = this.state.filter.filters;

      const result: ExcelExportData = {

        data: process(this.assetList, this.state1).data
      };      
      // console.log(result.data)
      // this.assetListExport = result.data
      // this.DataLength = result.data.length
      return result;
    }

  }

  onTabchange(event) {
    if (event.index == 1) {
      this.initialization();
    }
  }


  // initMap() {

  //   var map = new google.maps.Map(document.getElementById('map'), {
  //     zoom: 3,
  //     center: {lat: -28.024, lng: 140.887}
  //   });

  //   // Create an array of alphabetical characters used to label the markers.
  //   var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  //   // Add some markers to the map.
  //   // Note: The code uses the JavaScript Array.prototype.map() method to
  //   // create an array of markers based on a given "locations" array.
  //   // The map() method here has nothing to do with the Google Maps API.
  //   var markers = this.locations.map(function(location, i) {
  //     return new google.maps.Marker({
  //       position: location,
  //       label: labels[i % labels.length]
  //     });
  //   });

  //   // Add a marker clusterer to manage the markers.
  //   var markerCluster = new MarkerClusterer(map, markers,
  //       {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
  // }




  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.gridData = process(this.assetList, this.state);


    if (this.gridData.data.length > 0) {
      this.stateMap.filter = state.filter;
      this.stateMap.group = state.group;
      this.stateMap.skip = state.skip;
      this.stateMap.sort = state.sort;
      // delete this.stateMap.take;
      var newMapData = process(this.assetList, this.stateMap);
      this.mapData = newMapData.data;
    } else {
      this.mapData = [];
    }
  }

  clearfilter() {
    this.state = this.clear_state;
    this.gridData = process(this.assetList, this.clear_state);
  }

  MenuOnSelect(event) {
    // var baseUrl = window.location.origin + '/#/AddAssets/' + event.dataItem.assetID;
    var baseUrl = window.location.origin + '/AddAssets/' + event.dataItem.assetID;

    if (event.item == "Open") {

      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }



  // EditAsset(id) {

  //   // document.getElementById(id)['href'] = window.location.origin + '/AddAssets/' + id;
  //   window.open('/AddAssets/' + id, '_blank');

  //   // this.router.navigate(['/AddAssets/' + id])
  // }
  //--- Start : Map Code Block---
  //Initializing the Google Map for 1st Use
  initialization() {
    //Getting the Properties of Map
    if (this.mapData.length > 0) {
      var center = new google.maps.LatLng(this.mapData[0].geoLocation.split(",")[0], this.mapData[0].geoLocation.split(",")[1]);

      this.map = new google.maps.Map(document.getElementById('mapView'), {
        zoom: 3,
        center: center,
        mapTypeId: google.maps.MapTypeId.HYBRID
      });
      this.refreshMap();
    }
    else {
      var center = new google.maps.LatLng('25.2744', '133.7751');

      this.map = new google.maps.Map(document.getElementById('mapView'), {
        zoom: 13,
        center: center,
        mapTypeId: google.maps.MapTypeId.HYBRID
      });
    }
  }
  // refresh Map Code
  refreshMap() {
    var bounds = new google.maps.LatLngBounds();
    var markerImage = new google.maps.MarkerImage(this.imageUrl, new google.maps.Size(24, 32));
    var infowindow = new google.maps.InfoWindow();
    this.markerClusters = new Array<MarkerClusterer>();

    //Spider Code
    this.oms = new OverlappingMarkerSpiderfier(this.map,
      {
        markersWontMove: true,
        markersWontHide: false,
        keepSpiderfied: true
      });
    var spider = "images/pointer.png";
    var spidermarker = "images/mark.png";
    var iconWithColor = function (color) {
      return 'http://chart.googleapis.com/chart?chst=d_map_pin_letter_withshadow&chld=C|FF0000|0000FF';
    }
    var shadow = new google.maps.MarkerImage(
      'https://www.google.com/intl/en_ALL/mapfiles/shadow50.png',
      new google.maps.Size(37, 34), // size   - for sprite clipping
      new google.maps.Point(0, 0), // origin - ditto
      new google.maps.Point(10, 34)  // anchor - where to meet map location
    );
    let that = this;

    // var markers =  this.mapData.map((Data, i) => {
    for (var i = 0; i < this.mapData.length; i++) {

      if (this.mapData[i].geoLocation != undefined && this.mapData[i].geoLocation != null && this.mapData[i].geoLocation != "") {

        let latt = this.mapData[i].geoLocation.split(",")[0];
        let longg = this.mapData[i].geoLocation.split(",")[1];

        if (latt != undefined && latt != null && latt != "" && longg != undefined && longg != null && longg != "") {
          var latLng = new google.maps.LatLng(latt, longg)
          var newmarker = new google.maps.Marker({
            position: latLng,
            draggable: true,
            animation: google.maps.Animation.DROP,
            imagePath: 'http://chart.googleapis.com/chart?chst=d_map_pin_letter_withshadow&chld=C|FF0000|0000FF',
            metaData: this.mapData[i],

            map: this.map,
            index: i
          });





          google.maps.event.addListener(newmarker, 'dragstart', (function (clickedmarker, i) {
            return function () {

              var oldLat = this.getPosition().lat().toFixed(5);
              var oldLng = this.getPosition().lng().toFixed(5);
              this.lat = oldLat
              this.long = oldLng

            }
          })(newmarker, i))


          google.maps.event.addListener(newmarker, 'dragend', (function (clickedmarker1, i) {
            return function () {

              if (confirm(this.ErrorMsgData.changeLocation)) {
                let newLat = this.getPosition().lat().toFixed(5);
                let newLng = this.getPosition().lng().toFixed(5);
                that.AssetGeoLocationUpdate.AssetID = clickedmarker1.metaData.assetID
                that.AssetGeoLocationUpdate.GeoLocation = newLat + ',' + newLng;


                that.DataService.UpdateGeoLocationByAsset(that.AssetGeoLocationUpdate).subscribe(res => {

                  if (res != undefined) {

                    that.mapData.forEach(ele => {
                      if (ele.assetID == clickedmarker1.metaData.assetID) {
                        ele.geoLocation = newLat + ',' + newLng
                      }
                    })

                  }
                })


              } else {
                clickedmarker1.setPosition(new google.maps.LatLng(this.lat, this.long))
              }
            }
          })(newmarker, i))


          //listener event when Click on a marker
          google.maps.event.addListener(newmarker, 'click', (function (clickedmarker, i) {

            return function () {
              var AssetID = "", CustomerID = "", customerAssetID;
              if (clickedmarker.metaData.assetID)
                AssetID = (clickedmarker.metaData.assetID == undefined || clickedmarker.metaData.assetID == null || clickedmarker.metaData.assetID == "") ? "NA" : clickedmarker.metaData.assetID;

              customerAssetID = clickedmarker.metaData['customerAssetID'] == null || clickedmarker.metaData['customerAssetID'] == undefined ? '' : clickedmarker.metaData['customerAssetID'];
              let currOrigin = window.location.origin;
              // infowindow.setContent('<p><b>Asset Details</b></p> User Asset ID : <a href="' + currOrigin + '/#/AddAssets/' + clickedmarker.metaData.assetID + '" target="_blank" style="color:#007bff !important">' + clickedmarker.metaData.userAssetID + '</a> <br> Customer Asset ID  : ' + customerAssetID);
              infowindow.setContent('<p><b>Asset Details</b></p> User Asset ID : <a href="' + currOrigin + '/AddAssets/' + clickedmarker.metaData.assetID + '" target="_blank" style="color:#007bff !important">' + clickedmarker.metaData.userAssetID + '</a> <br> Customer Asset ID  : ' + customerAssetID);
              infowindow.open(this.map, clickedmarker);
            }
          })(newmarker, i));
          //this.oms.addMarker(this.map,marker); //for spider.
          bounds.extend(newmarker.getPosition());
          this.map.fitBounds(bounds);
          this.markerClusters.push(newmarker);
          // return newmarker

        }
      }
    };



    var clusterStyles = [
      {
        height: 42,
        width: 42
      }
    ];
    var markerClusterer = new MarkerClusterer(this.map, this.markerClusters, { ac: clusterStyles, gridSize: 50, maxZoom: 17, ignoreHidden: true, imagePath: '../../../assets/m' });

    //Showing Spider
    this.oms.addListener('spiderfy', function (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setIcon(spidermarker);
        markers[i].setShadow(shadow);
      }
    });

    //Hide Spider
    this.oms.addListener('unspiderfy', function (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setIcon(spider);
        markers[i].setShadow(shadow);
      }
    });

    this.oms = new OverlappingMarkerSpiderfier(this.map);
    this.markerClusters = new MarkerClusterer(this.map, this.markerClusters,
      { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });


  }
  moveMarker(map, marker, lat, long) {

    //delayed so you can see it move
    setTimeout(function () {
      //marker.setPosition( new google.maps.LatLng(-37.80706, 144.96464 ) );
      marker.setPosition(new google.maps.LatLng(parseFloat(lat), parseFloat(long)));
      //map.panTo( new google.maps.LatLng(this.lat,this.long) );
      map.panTo(new google.maps.LatLng(parseFloat(lat), parseFloat(long)));

    }, 100);
  };


  labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  labelIndex = 0;

  //Setting location
  setLocation(locationData) {
    //api is not available yet
  }
  //--- End : Map Code Block

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
    this.ReportFilter = new ReportFilter();
    this.sharedDataService.ReportFilterBeh$ = new BehaviorSubject<ReportFilter>(this.ReportFilter);
  }

}
