
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges, DoCheck, AfterViewChecked, NgZone, Input } from '@angular/core';
import { PrimaryDetails, AssetDetails, AssetAdminListingEntity, LocationEntity, AssetTypeFaultListModel, AssetTypeFixListModel, AssetTypeEntity, AssetImageData, SubAssetEntity, SubAssetList, ReplacementCostBugget, UOMData } from '../add-asset/add-asset.model';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService } from '../../Service/CommonUtility.service'
import { Observable, of, from, observable, } from 'rxjs';

import { FormControl, FormGroup, FormsModule, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { Http } from '@angular/http';
import { deepEqual } from 'assert';

import { isValidRange } from '@progress/kendo-angular-dateinputs/dist/es2015/util';
import { itemAt } from '@progress/kendo-angular-grid/dist/es2015/data/data.iterators';
import { Title } from '@angular/platform-browser';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { SnotifyService } from 'ng-snotify';

import { numberSymbols } from '@telerik/kendo-intl';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ImageCompressor, getImageSize } from "compressor-img";
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-sub-assets',
  templateUrl: './sub-assets.component.html',
  styleUrls: ['./sub-assets.component.scss']
})
export class SubAssetsComponent implements OnInit {

  @Output() Subdata: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor(private ng2ImgMax: Ng2ImgMaxService, public http: Http, private DataService: CoreDataService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private router: Router, private activatedRoute: ActivatedRoute
    , private sharedDataService: SharedDataService, private titleService: Title, private notify: SnotifyService
  ) {

  }
  ArraySubAssets: Array<SubAssetEntity> = new Array<SubAssetEntity>();
  TempArraySubAssets: Array<SubAssetEntity> = new Array<SubAssetEntity>();
  subAssetModel: SubAssetEntity = new SubAssetEntity();
  public imageIndexOneSub = 0
  public imagePreviewsSub: any = [];
  public imagePreviewsSubViewer: string[] = [];
  ShowHideSubAssets = false
  ClickedImageSub: boolean = false;
  SubAssetImageID: number;
  LanguageConfig: any;
  ErrorMsgData: any;

  lengthArray: any = [];
  public data: number;
  public dataValid: boolean = true;

  public datawidthBreadth: number;
  public datawidthBreadthValid: boolean = true;
  public datathicknessDepth: number;
  public datathicknessDepthValid: boolean = true;
  isCancelclicked: boolean = false;
  IsCancelClickedSubImage: boolean = false;
  AssetData: Array<AssetTypeEntity> = [];
  AssetBarcodeData: Array<{ assetID: number, barcode: string }> = [];
  public ddlCodeDataDefaultItem: { codeValue: string, codeID: number, weightage: number } = { codeValue: 'Select', codeID: null, weightage: 0 };
  public ddlConditionRatingCd: Array<{ codeValue: string, codeID: number, weightage: number }> = []
  public ddlFilterTypeCd: Array<{ codeValue: string, codeID: number, weightage: number }> = [];
  public ddlMfgCd: Array<{ codeValue: string, codeID: number, weightage: number }> = [];
  IsUserCustomer: string;
  IsCustomer: boolean;
  IsAdmin: string;
  formGroupSub: FormGroup;

  @Input() LoadSubData: Array<any> = new Array<any>();

  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[38];
          this.ErrorMsgData = res[28];
        }
      })
    }


    this.formGroupSub = new FormGroup({
      userAssetID: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)

      ]),
      assetID: new FormControl('', [

      ]),
      subAssetID: new FormControl('', [

      ]),

      assetTypeID: new FormControl('', [
        Validators.required,

      ]),
      conditionRatingCd: new FormControl('', [
        Validators.required,


      ]),
      barcode: new FormControl('', [
        Validators.required,
        Validators.maxLength(51)
      ]),
      filterQty: new FormControl('', [
        Validators.maxLength(51)
      ]),
      filterTypeCd: new FormControl('', [
      ]),
      generalComments: new FormControl('', [
        Validators.maxLength(200)
      ]),
      filterDesc: new FormControl('', [
        Validators.maxLength(111)
      ]),


      areaLocated: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)

      ]),
      make: new FormControl('', [

      ]),
      model: new FormControl('', [

        Validators.maxLength(30)
      ]),
      serialNo: new FormControl('', [

        Validators.maxLength(30)
      ]),
      heightLength: new FormControl('', [

        Validators.pattern(new RegExp("^[\\d]{1,7}(\\.[\\d]{1,5})?$"))


      ]),
      widthBreadth: new FormControl('', [
        //  Validators.required,
        Validators.pattern(new RegExp("^[\\d]{1,7}(\\.[\\d]{1,5})?$"))

      ]),
      thicknessDepth: new FormControl('', [
        Validators.pattern(new RegExp("^[\\d]{1,7}(\\.[\\d]{1,5})?$"))

      ]),
    });
    this.bindCodeData();
    if (this.activatedRoute.snapshot.paramMap.get('id') != null) {

      let Id: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
      this.subAssetModel.assetID = Id;

      this.getSubAssetByID(Id);
    }

    this.IsUserCustomer = this.sharedDataService.IsCustomer();
    this.IsAdmin = this.sharedDataService.IsAdmin();

    this.sharedDataService.SubAssetClone$.subscribe(element => {
      if (element == 'true') {
        this.ArraySubAssets = null
        this.Subdata.emit(this.ArraySubAssets);
        this.sharedDataService.SubAssetClone$.next('false');
      }
    })
  }
  dataValidHeightLength: boolean = true;
  dataValidWidthBreadth: boolean = true;
  dataValidThicknessDepth: boolean = true;

  getSubAssetByID(id) {
    this.DataService.getSubAssetByID(id).subscribe((res) => {

      if (res != undefined && res != null) {


        res.forEach(element => {
          this.subAssetModel = new SubAssetEntity();
          this.imagePreviewsSubViewer = new Array();

          this.subAssetModel = element;
          element.conditionRating = {
            codeValue: element.conditionRating, codeID: element.conditionRatingCd
            , weightage: 0
          };

          element.assetType = { assetTypeID: element.assetTypeID, assetTypeName: element.assetTypeName }
          element.index = this.ArraySubAssets.length;
          this.subAssetModel.makeCd = this.ddlMfgCd;
          this.subAssetModel.makeCd = this.subAssetModel.makeCd.find(ele => {
            if (ele.codeID == parseInt(element.make)) {

              return ele;
            }

          })


          this.subAssetModel.conditionRating = element.conditionRating

          //new fields
          this.subAssetModel.barcode = element.barcode
          this.subAssetModel.filterQty = element.filterQty
          this.subAssetModel.filterTypeCd = element.filterTypeCd


          this.subAssetModel.filterType = this.ddlFilterTypeCd;

          this.subAssetModel.filterType = this.subAssetModel.filterType.find(ele => {
            if (ele.codeID == element.filterTypeCd) {
              return ele;
            }
          })


          this.subAssetModel.generalComments = element.generalComments
          this.subAssetModel.filterDesc = element.filterDesc

          //new fields


          this.subAssetModel.assetType = element.assetType
          this.subAssetModel.index = element.index
          this.subAssetModel.heightLength = element.heightLength == 0 ? null : element.heightLength;
          this.subAssetModel.widthBreadth = element.widthBreadth == 0 ? null : element.widthBreadth;
          this.subAssetModel.thicknessDepth = element.thicknessDepth == 0 ? null : element.thicknessDepth;

          //MOVE SUB ASSET CHECK
          this.subAssetModel.IsChecked = false
          //MOVE SUB ASSET CHECK

          let arr = [];
          let arrPreview = [];
          element.imageData.forEach(ImgData => {
            arr.push({
              src: "data:image/png;base64," + ImgData.imagePath,
              uid: ImgData.imageDataID,
              extension: ImgData.extension,
              IsUpdated: false,
              date: ImgData.creationDate,
              description: ImgData.description,
            });
            arrPreview.push('data:image/png;base64,' + ImgData.imagePath)
            this.subAssetModel.SubAssetImages = arr;
            this.imagePreviewsSubViewer = arrPreview;

          });
          this.ArraySubAssets.push(this.subAssetModel);
        });

      }
    })
  }



  isTextEdit: boolean = true;
  isCancelbtn: boolean = false;
  isSavebtn: boolean = false;
  isEditbtn: boolean = true;
  SubassetImgDesc(value) {
    if (value == 'edit') {
      this.isTextEdit = false;
      this.isCancelbtn = true;
      this.isSavebtn = true;
      this.isEditbtn = false;
      this.positionCursor();

    }
    if (value == 'cancel') {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }

    if (value == 'save') {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }
  }

  positionCursor() {
    document.getElementById("Subdescription").focus();
    document.execCommand('selectAll', false, null);
    document.getSelection().collapseToEnd();

  }


  selectedAssetData: any
  GetAssetByBarcodeDropDownUrl(filterData) {
    // this.activatedRoute.
    let Id: number = isNaN(parseInt(this.activatedRoute.snapshot.paramMap.get('id'))) ? 0 : parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    console.log(Id)
    this.DataService.getAssetByBarcodeDropDownUrl(filterData, Id).subscribe((res) => {
      this.AssetBarcodeData = res
    })
  }
  filterChangeMoveSubAssets(filterData: any): void {
    if (filterData.length >= 5) {
      this.GetAssetByBarcodeDropDownUrl(filterData);
    }
  }
  MoveSubAsset() {
    this.IsMoveSubAssetDialog = true
    this.selectedAssetData = undefined
    this.IsAssetBarcodeSelected = true
  }

  //SELECT & UNSELECT SINGLE SUB ASSETS
  selectSingleSubAsset(subAssetData, event) {

    if (subAssetData.subAssetID != undefined) {
      let checked = event.target.checked

      if (checked == true) {
        subAssetData.IsChecked = true
        this.TempArraySubAssets.push(subAssetData)
        // console.log(this.TempArraySubAssets)
      }
      else if (checked == false) {
        const index = this.TempArraySubAssets.findIndex(item => item.subAssetID === subAssetData.subAssetID);
        if (index >= 0) {
          subAssetData.IsChecked = false
          this.TempArraySubAssets.splice(index, 1);
        }
        // console.log(this.TempArraySubAssets)
      }
      this.IsMoveAssetsVisible = this.TempArraySubAssets.length > 0 ? true : false
      this.IsAllChecked = this.TempArraySubAssets.length == 0 ? false : true
    }
    else {
      subAssetData.IsChecked = false
      this.notify.info("Cannot move sub asset with Barcode:- " + subAssetData.barcode + " .Please save this sub asset first before moving.")
    }

  }



  IsAssetBarcodeSelected: boolean = true
  IsAllChecked: boolean = false

  closeMoveSubAssetDialog(val) {
    if (val == 'true') {
      this.IsAssetBarcodeSelected = (this.selectedAssetData != undefined && this.selectedAssetData != 0) ? true : false
      if (!this.IsAssetBarcodeSelected) {
        this.IsMoveSubAssetDialog = true
        return
      }
      else {
        //Save/update data
        console.log(this.selectedAssetData)
        console.log(this.TempArraySubAssets)
        this.TempArraySubAssets.forEach(ele => {
          ele.IsChecked = false
        })
        this.TempArraySubAssets = this.TempArraySubAssets
        this.TempArraySubAssets.forEach(elem => {

          this.DataService.moveSubAssetByAssetID({ "AssetID": this.selectedAssetData, "SubAssetID": elem.subAssetID }).subscribe(res => {
            if (res != undefined) {
              console.log(res)
              const index = this.ArraySubAssets.findIndex(item => item.subAssetID === elem.subAssetID);
              if (index >= 0) {
                this.ArraySubAssets.splice(index, 1);
              }
            }
          })
        })
        this.TempArraySubAssets = [] //Refresh
        this.IsAllChecked = this.TempArraySubAssets.length == 0 ? false : true
        this.IsMoveSubAssetDialog = false
        this.IsMoveAssetsVisible = this.TempArraySubAssets.length == 0 ? false : true
        this.notify.success("Sub assets moved successfully")
      }
    }
    else {
      this.IsMoveSubAssetDialog = false
    }


  }

  IsMoveSubAssetDialog: boolean = false
  IsMoveAssetsVisible: any

  //SELECT & UNSELECT ALL SUB ASSETS
  selectAllSubAsset(event) {
    // console.log(event)
    let checked = event.target.checked
    // this.IsMoveAssetsVisible = event.target.checked

    if (checked == true) {
      this.checkAllSubAssetList();
    }
    else if (checked == false) {
      this.UnCheckAllSubAssetList();
    }
    this.IsMoveAssetsVisible = this.TempArraySubAssets.length > 0 ? true : false
  }

  checkAllSubAssetList() {
    let item = [];
    this.ArraySubAssets.forEach(element => {
      if (element.subAssetID == undefined) {
        element.IsChecked = false
        this.notify.info("Cannot move sub asset with Barcode:- " + element.barcode + " .Please save this sub asset first before moving.")
      }
      else {
        element.IsChecked = true
        item.push(element)
      }
    })
    // this.ArraySubAssets = item;
    this.TempArraySubAssets = item
    this.IsAllChecked = true
    // console.log(this.TempArraySubAssets)
  }



  UnCheckAllSubAssetList() {
    let item = [];

    this.ArraySubAssets.forEach(element => {
      element.IsChecked = false
      item.push(element)
      // this.TempArraySubAssets.splice(index,1)
    })
    this.TempArraySubAssets = []
    this.IsAllChecked = false
    // console.log(this.TempArraySubAssets)
  }



  // getSubAssets() {

  //   if(this.LoadSubData.length > 0){
  //     this.LoadSubData.forEach(element => {
  //       this.subAssetModel = new SubAssetEntity();
  //       this.imagePreviewsSubViewer = new Array();

  //       this.subAssetModel = element;
  //       element.conditionRating = {
  //         codeValue: element.conditionRating.codeValue, codeID: element.conditionRatingCd
  //         , weightage: 0
  //       };

  //       //TEST CASE FOR TESTING INCORRECT DATA
  //       // element.conditionRating = {
  //       //   codeValue: "", codeID: 0
  //       //   , weightage: 0
  //       // };
  //       //TEST CASE FOR TESTING INCORRECT DATA

  //       element.assetType = { assetTypeID: element.assetTypeID, assetTypeName: element.assetTypeName }
  //       element.index = this.ArraySubAssets.length;
  //       this.subAssetModel.make = parseInt(element.make)
  //       this.subAssetModel.assetType = element.assetType
  //       this.subAssetModel.conditionRating = element.conditionRating

  //       //new fields
  //       this.subAssetModel.barcode = element.barcode
  //       this.subAssetModel.filterQty = element.filterQty
  //       if(element.filterType!= undefined){
  //         element.filterType = {
  //           codeValue: element.filterType.codeValue, codeID: element.filterTypeCd
  //           , weightage: 0
  //         };
  //       }

  //       this.subAssetModel.filterType = element.filterType
  //       this.subAssetModel.generalComments = element.generalComments
  //       this.subAssetModel.filterDesc = element.filterDesc

  //       //new fields

  //       this.subAssetModel.index = element.index
  //       this.subAssetModel.heightLength = element.heightLength == 0 ? null : element.heightLength;
  //       this.subAssetModel.widthBreadth = element.widthBreadth == 0 ? null : element.widthBreadth;
  //       this.subAssetModel.thicknessDepth = element.thicknessDepth == 0 ? null : element.thicknessDepth;


  //       let arr = [];
  //       let arrPreview = [];
  //       element.imageData.forEach(ImgData => {
  //         arr.push({
  //           src: 'data:image/png;base64,' + ImgData.imagePath,
  //           uid: ImgData.imageDataID,
  //           extension: ImgData.extension,
  //           IsUpdated: false
  //         })
  //         arrPreview.push('data:image/png;base64,' + ImgData.imagePath)
  //         this.subAssetModel.SubAssetImages = arr;
  //         this.imagePreviewsSubViewer = arrPreview;

  //       });
  //       this.ArraySubAssets.push(this.subAssetModel);
  //     });
  //   }
  // }



  originalImage: any = null;
  sizeOriganleImage: any = null;
  compressedImage: any = null;
  sizeCompressedImage: any = null;

  public selectEventHandlerSub(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file: any) => {

      let File = file
      if (file.size < 8388608) {
        let reader = new FileReader();

        if (file.size > 300000) {
          reader.onload = () => {


            let imageCompressor = new ImageCompressor({
              onSuccess: response => {
                (this.originalImage = response.original),
                  (this.sizeOriganleImage = getImageSize(response.original)),
                  (this.compressedImage = response.compressed),

                  (this.sizeCompressedImage = getImageSize(response.compressed));

                //GENERATING Unique object ID for image & also for deletion

                if (that.imagePreviewsSubViewer[0] !== this.compressedImage) {

                  let imageSub = {
                    src: this.compressedImage,
                    uid: file.uid,
                    date: Date.now(),
                    extension: file.extension,
                    IsUpdated: true,
                    name: file.name
                  };

                  //GENERATING Unique object ID for image
                  that.subAssetModel.SubAssetImages.unshift(imageSub);
                  that.imagePreviewsSubViewer.unshift(this.compressedImage)
                }
                var k = document.getElementsByClassName('k-upload-files');
                if (k.length > 0) {
                  k[0].remove()
                }
              },
              scale: 30,
              quality: 100,
              holdCompress: false,
              originalImage: reader.result
            });
            imageCompressor.startCompress();

          }
        }
        else {
          reader.onload = (ev) => {

            let imageSub = {
              src: ev.target['result'],
              uid: file.uid,
              date: Date.now(),
              extension: file.extension,
              IsUpdated: true,
              name: file.name
            };

            if (that.subAssetModel.SubAssetImages == undefined) {
              that.subAssetModel.SubAssetImages = [];
            }
            that.subAssetModel.SubAssetImages.unshift(imageSub);
            that.imagePreviewsSubViewer.unshift(ev.target['result'].toString())

            var k = document.getElementsByClassName('k-upload-files');
            if (k.length > 0) {
              k[0].remove()
            }
          }
        }
        reader.readAsDataURL(file.rawFile);
      }
      else {
        this.notify.error(this.ErrorMsgData.exceedFileSize)
        var k = document.getElementsByClassName('k-upload-files');
        if (k.length > 0) {
          k[0].remove()
        }
      }



    });

  }
  validateAssetImages(): boolean {

    let IsValid = false;

    if (this.subAssetModel.SubAssetImages.length <= 10 && this.subAssetModel.SubAssetImages.length >= 2) {
      document.getElementById('ImageDisplaySub').hidden = true;
      IsValid = true;
    }

    else {
      document.getElementById('ImageDisplaySub').hidden = false;
      IsValid = false;
    }


    return IsValid;
  }

  // ValidateBarcode(fieldName) {
  //   if (fieldName.toLowerCase() == 'barcode' && this.subAssetModel.barcode.length >= 1) {
  //     this.DataService.ValidateAssetBarcode(this.subAssetModel.assetID, this.subAssetModel.assetLocationID, this.assetDetailsModel.barcode).subscribe(res => {
  //       if (res != undefined && res != null && res.length > 0) {
  //         this.subAssetModel.barcode = null
  //         this.notify.warning("Barcode already exists, Please enter again.");
  //       }
  //     })
  //   }
  // }

  handleEvent(value) {
    if (value == 'click') {
      this.isTextEdit = true;
      this.isEditbtn = true;
      this.isCancelbtn = false;
      this.isSavebtn = false;
    }
  }

  swapSubAssetImage(data) {
    console.log(data)
    this.subAssetModel.SubAssetImages = data;
  }


  textlengthBoolean: boolean = false
  getSubImagetext(text) {
    if (text.length == 500) {
      this.textlengthBoolean = true;
      setTimeout(() => {
        this.textlengthBoolean = false;
      }, 4000);
    }
  }


  AddSubAsset() {
    if (this.formGroupSub.valid && this.validateAssetImages()) {
      this.toggelSubAsset();
      if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
        let Id: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
        this.subAssetModel.assetID = Id;
      }
      if (Object.keys(this.subAssetModel).length > 0) {
        if (this.subAssetModel.index != undefined && this.subAssetModel.index != null) {

          this.ArraySubAssets.forEach(element => {
            if (element.index == this.subAssetModel.index) {

              if (this.subAssetModel.SubAssetImages != undefined || this.subAssetModel.SubAssetImages != null) {
                element.SubAssetImages = this.subAssetModel.SubAssetImages
              }
              this.subAssetModel = this.formGroupSub.value
              this.subAssetModel.index = element.index

              element.areaLocated = this.subAssetModel.areaLocated;
              element.assetID = this.subAssetModel.assetID;
              element.assetType = this.subAssetModel.assetType;
              element.conditionRating = this.subAssetModel.conditionRating;
              element.heightLength = this.subAssetModel.heightLength;
              element.makeCd = this.subAssetModel.makeCd;
              element.model = this.subAssetModel.model;
              element.serialNo = this.subAssetModel.serialNo;
              element.subAssetID = this.subAssetModel.subAssetID;
              element.thicknessDepth = this.subAssetModel.thicknessDepth;
              element.widthBreadth = this.subAssetModel.widthBreadth;
              element.index = this.subAssetModel.index;
              element.userAssetID = this.subAssetModel.userAssetID;
              element.filterTypeCd = this.subAssetModel.filterTypeCd;
              element.barcode = this.subAssetModel.barcode;
              element.generalComments = this.subAssetModel.generalComments;
              element.filterQty = this.subAssetModel.filterQty;
              element.filterDesc = this.subAssetModel.filterDesc;
              this.subAssetModel.index = this.ArraySubAssets.length;
              this.ddlConditionRatingCd.forEach(ele => {
                if (ele.codeID == this.subAssetModel.conditionRatingCd) {
                  element.conditionRating = ele;
                }
              });
              this.ddlFilterTypeCd.forEach(ele => {
                if (ele.codeID == this.subAssetModel.filterTypeCd) {
                  element.filterType = ele;
                }
              });
              this.AssetData.forEach(ele => {
                if (Number.parseInt(ele.assetTypeID) == this.subAssetModel.assetTypeID) {
                  element.assetType = ele
                }
              });
              this.ddlMfgCd.forEach(ele => {
                if (ele.codeID == this.subAssetModel.make) {
                  element.makeCd = ele
                }
              });

            }


          });

          this.subAssetModel = new SubAssetEntity();


        } else {
          if (this.ArraySubAssets == null) {
            this.ArraySubAssets = new Array<SubAssetEntity>();
            this.subAssetModel.index = this.ArraySubAssets.length;

            this.ddlConditionRatingCd.forEach(ele => {
              if (ele.codeID == this.subAssetModel.conditionRatingCd) {
                this.subAssetModel.conditionRating = ele;
              }
            });
            this.ddlFilterTypeCd.forEach(ele => {
              if (ele.codeID == this.subAssetModel.filterTypeCd) {
                this.subAssetModel.filterType = ele;
              }
            });
            this.AssetData.forEach(ele => {
              if (Number.parseInt(ele.assetTypeID) == this.subAssetModel.assetTypeID) {
                this.subAssetModel.assetType = ele
              }
            });
            this.ddlMfgCd.forEach(ele => {
              if (ele.codeID == this.subAssetModel.make) {
                this.subAssetModel.makeCd = ele
              }
            });


            this.ArraySubAssets.push(this.subAssetModel);
          }
          else {
            this.subAssetModel.index = this.ArraySubAssets.length;
            this.ddlConditionRatingCd.forEach(element => {
              if (element.codeID == this.subAssetModel.conditionRatingCd) {
                this.subAssetModel.conditionRating = element
              }
            });
            this.ddlFilterTypeCd.forEach(ele => {
              if (ele.codeID == this.subAssetModel.filterTypeCd) {
                this.subAssetModel.filterType = ele;
              }
            });
            this.AssetData.forEach(element => {
              if (Number.parseInt(element.assetTypeID) == this.subAssetModel.assetTypeID) {
                this.subAssetModel.assetType = element
              }
            });


            this.ddlMfgCd.forEach(element => {
              if (element.codeID == this.subAssetModel.make) {
                this.subAssetModel.makeCd = element
              }
            });

            this.ArraySubAssets.push(this.subAssetModel);
          }
          this.subAssetModel = new SubAssetEntity();
        }
      }
      this.Subdata.emit(this.ArraySubAssets);
    }
    else {
      Object.keys(this.formGroupSub.controls).forEach(field => { // {1}
        const control = this.formGroupSub.get(field); // {2}

        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });

      this.notify.error(this.ErrorMsgData.incorrect);
      event.preventDefault()

    }

  }



  EditSubModel(element) {


    this.toggelSubAsset();
    this.GetSubAssetTypeByNameDropDownUrl('')
    let Item = new SubAssetEntity();
    this.imagePreviewsSubViewer = new Array()
    Item = element;
    this.subAssetModel = new SubAssetEntity();

    this.subAssetModel.areaLocated = Item.areaLocated;
    this.subAssetModel.assetID = Item.assetID;
    this.subAssetModel.assetTypeID = Item.assetType.assetTypeID;

    this.subAssetModel.conditionRatingCd = Item.conditionRating.codeID;
    this.subAssetModel.heightLength = Item.heightLength;
    this.subAssetModel.makeCd = Item.makeCd;
    this.subAssetModel.make = Item.makeCd == undefined ? null : Item.makeCd.codeID;

    //new fields
    this.subAssetModel.barcode = Item.barcode;
    this.subAssetModel.filterQty = Item.filterQty;
    this.subAssetModel.filterTypeCd = Item.filterType == undefined ? null : Item.filterType.codeID;
    this.subAssetModel.generalComments = Item.generalComments;
    this.subAssetModel.filterDesc = Item.filterDesc;

    //new fields

    this.subAssetModel.model = Item.model;
    this.subAssetModel.serialNo = Item.serialNo;
    this.subAssetModel.subAssetID = Item.subAssetID;
    this.subAssetModel.thicknessDepth = Item.thicknessDepth;
    this.subAssetModel.widthBreadth = Item.widthBreadth;
    this.subAssetModel.index = Item.index;
    this.subAssetModel.userAssetID = Item.userAssetID;
    this.subAssetModel.SubAssetImages = Item.SubAssetImages;


    if (this.subAssetModel.SubAssetImages != undefined && this.subAssetModel.SubAssetImages != null) {
      this.subAssetModel.SubAssetImages.forEach(element => {

        this.imagePreviewsSubViewer.push(element.src)

      });
    }




  }



  changeIndexSub(i, subImage) {
    this.imageIndexOneSub = i
    if (subImage != undefined) {
      this.SubAssetImageID = subImage.uid;
      this.ClickedImageSub = true;
    }
    document.getElementsByTagName('ngx-image-viewer')[0].getElementsByClassName('img-container')[0].getElementsByTagName('img')[0]['style']['height'] = '-webkit-fill-available';
  }

  cancelledClickSubImage(item) {
    if (item != undefined) {
      this.SubAssetImageID = item.uid;
      this.IsCancelClickedSubImage = true;
    }
  }

  subAssetDeleteID: number;
  ItemSubAssetDeleteID: number;
  cancelledClick(subAssetID, id) {
    this.subAssetDeleteID = subAssetID
    this.ItemSubAssetDeleteID = id
    this.isCancelclicked = true;

  }
  closeSubImage(val) {

    if (val != undefined && val == 'true') {
      const index = this.subAssetModel.SubAssetImages.findIndex(item => item.uid === this.SubAssetImageID);

      if (index >= 0) {
        if (typeof this.SubAssetImageID == 'string') {
          this.subAssetModel.SubAssetImages.splice(index, 1);
          this.imagePreviewsSubViewer.splice(index, 1)
          this.IsCancelClickedSubImage = false;
        }
        else if (typeof this.SubAssetImageID == 'number') {
          this.DataService.DeleteSubAssetImage(this.SubAssetImageID).subscribe((res) => {
            if (res != null && res != undefined) {
              this.subAssetModel.SubAssetImages.splice(index, 1);
              this.imagePreviewsSubViewer.splice(index, 1)
              this.IsCancelClickedSubImage = false;
            }
          })
        }
      }

    } else {
      this.IsCancelClickedSubImage = false;
    }
  }
  GetSubAssetTypeByNameDropDownUrl(filterData) {
    this.DataService.getAssetTypeByNameDropDownUrl(filterData).subscribe((res) => {
      this.AssetData = res
    })
  }

  filterChangeSubAssets(filterData: any): void {
    if (filterData.length >= 3) {
      this.GetSubAssetTypeByNameDropDownUrl(filterData);
    }
  }

  bindCodeData() {

    this.DataService.getCodeData().subscribe((res) => {

      if (res != undefined && res != null) {
        res.forEach(element => {
          if (element.codeName.toLowerCase() == 'conditionratingcd') {

            this.ddlConditionRatingCd.push(element);

          }
          else if (element.codeName.toLowerCase() == 'mfgcd') {
            this.ddlMfgCd.push(element);
          }
          else if (element.codeName.toLowerCase() == 'filtertypecd') {
            this.ddlFilterTypeCd.push(element);
          }
        });

      }

    })
  }

  close(val, id, spliceid) {
    if (val != undefined && val == 'true') {
      if (id == undefined) {
        this.ArraySubAssets.splice(spliceid, 1)
        this.isCancelclicked = false;
        this.Subdata.emit(this.ArraySubAssets);
      }
      else {
        this.DataService.DeleteAssetByID(id).subscribe((res) => {
          this.ArraySubAssets.splice(spliceid, 1)
          this.isCancelclicked = false;
          this.Subdata.emit(this.ArraySubAssets);
        })
        //  this.ArraySubAssets.splice(spliceid, 1)
        // this.isCancelclicked = false;
        // this.Subdata.emit(this.ArraySubAssets);
      }

    } else {
      this.isCancelclicked = false;
    }
  }

  public onBlur() {
    this.dataValid = true;
  }
  public onBlurWidthBreadth() {
    this.datawidthBreadthValid = true;
  }
  public onBlurThicknessDepth() {
    this.datathicknessDepthValid = true;
  }


  toggelSubAsset() {


    if (this.ShowHideSubAssets) {
      this.ShowHideSubAssets = false;
    } else {
      this.subAssetModel = new SubAssetEntity();
      this.subAssetModel.model = null
      this.subAssetModel.serialNo = null
      this.subAssetModel.heightLength = null
      this.subAssetModel.widthBreadth = null
      this.subAssetModel.thicknessDepth = null
      this.formGroupSub.reset()

      this.ShowHideSubAssets = true;
    }
  }
  closePreviewImageSub(value) {
    this.ClickedImageSub = value == "false" ? false : true;
    this.handleEvent('click')
  }

  RotateImageForwardSub(srcBase64, uid, Orientation, Rotation, index) {
    var img = new Image();
    let srcOrientation;
    if (Orientation != undefined) {
      srcOrientation = Orientation;
    } else {
      srcOrientation = 1;
    }

    if (Rotation == 'F') {
      //Setting Forward orientation
      if (srcOrientation == 1)
        srcOrientation = 6;
      else if (srcOrientation == 8)
        srcOrientation = 6


    } else if (Rotation == 'B') {
      if (srcOrientation == 1)
        srcOrientation = 8;
      else if (srcOrientation == 6)
        srcOrientation = 8
    }

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);
      this.testSub(canvas.toDataURL(), uid, srcOrientation, index)
      // export base64

      document.getElementById(uid)['src'] = canvas.toDataURL();

    };


    this.subAssetModel.SubAssetImages.forEach(element => {
      if (element.uid === uid) {
        element.Orientation = srcOrientation;
      }
    });
    img.src = srcBase64;

  }

  testSub(data, uid, srcOrientation, index) {

    this.subAssetModel.SubAssetImages.forEach(element => {
      if (element.uid === uid) {
        element.src = data
        element.Orientation = srcOrientation;
        element.IsUpdated = true;
      }
    });
    this.imagePreviewsSubViewer[index] = data;
  }



}
