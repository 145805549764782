import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';

declare var googlemap: any;
@Component({
  selector: 'app-image-upload',
  templateUrl: 'image-upload.component.html',
  styleUrls: ['image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  event: any = {
    target: {
      result: ''
    }
  }
  public events: string[] = [];
  public imagePreviews: FileInfo[] = [];
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png']
  }
  public uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  public uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  public clearEventHandler(e: ClearEvent): void {
    this.log('Clearing the file upload');
    this.imagePreviews = [];
  }

  public completeEventHandler() {
    this.log(`All files processed`);
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.log(`Removing ${e.files[0].name}`);

    const index = this.imagePreviews.findIndex(item => item.uid === e.files[0].uid);

    if (index >= 0) {
      this.imagePreviews.splice(index, 1);
    }
  }

  public selectEventHandler(e: SelectEvent): void {
     const that = this;
    var test = this.event;
    e.files.forEach((file) => {
      that.log(`File selected: ${file.name}`);
 
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
           test = ev;
          const image = {
            src: test.target.result,
            uid: file.uid,
            name: file.name
          };
          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }

  ngOnInit() {


  }

}