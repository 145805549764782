import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { SnotifyService } from "ng-snotify";
import { NgForm, FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedDataService } from '../../Service/CommonUtility.service';
import { CommonFunctionService } from '../../Service/common-function.service';
import { ActivatedRoute } from '@angular/router';
import { AssetGroup } from "../../Common/shared-model";
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-create-asset-group',
  templateUrl: './create-asset-group.component.html',
  styleUrls: ['./create-asset-group.component.scss']
})
export class CreateAssetGroupComponent implements OnInit {
  public defaultItem: { className: string, assetClassID: number } = { className: "Select item...", assetClassID: null };
  assetGroupTitle: string = "Add Asset Group";
  @ViewChild('formData',{static:false}) formGroup: NgForm;
  event: string;
  editVariable = [];
  dropdownIndex: number;
  LanguageInitial: any;
  ErrorMsgData: any;
  temp: number;
  LanguageConfig: any;
  indexValue;
  public griStatus = [];
  public fieldValues = [];
  list: any = {};
  listEditData: AssetGroup = new AssetGroup;
  public listItems = [];
  public myForm: FormGroup = new FormGroup({
    classList: new FormControl()
  });
  // public fieldItems: Array<string> = ["Mandatory", "Optional", "Not Applicable"];
  public fieldItems: Array<any> = [];


  //   [{
  //   displayName: "Mandatory", displayID: '1'
  // },
  // {
  //   displayName: "Optional", displayID: '0'
  // },
  // {
  //   displayName: "Not Applicable", displayID: '-1'
  // }];
  fieldTemplate = [];
  fieldName = [];
  editchange: Array<any>;
  temparray = [];
  formgroupAsset: FormGroup;
  public gridView = [];

  constructor(private titleService: Title,
    private activatedRoute: ActivatedRoute, private notify: SnotifyService, private sharedService: SharedDataService, private router: Router, private shareDataService: SharedDataService, private routeSnapshot: ActivatedRoute, private commonfmunction: CommonFunctionService, private sharedDataService: SharedDataService, private toastrService: ToastrService, private coredataservice: CoreDataService) {


    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);

    this.LanguageInitial = this.sharedDataService.GetLanguageLiteral();

    if (this.LanguageInitial == 'en') {
      this.fieldItems = [{
        displayName: "Mandatory", displayID: '1'
      },
      {
        displayName: "Optional", displayID: '0'
      },
      {
        displayName: "Not Applicable", displayID: '-1'
      }];
    }
    else {
      this.fieldItems = [{
        displayName: "必須", displayID: '1'
      },
      {
        displayName: "オプション", displayID: '0'
      },
      {
        displayName: "適用できません", displayID: '-1'
      }];

    }

  }
  @ViewChild('customerStatus',{static:false}) usrForm: NgForm;

  ngOnInit() {
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[15];
          this.ErrorMsgData = res[28];
        }
      })
    }

    this.formgroupAsset = new FormGroup({
      groupName: new FormControl('', [
        Validators.required,
        //  Validators.minLength(8),
        Validators.maxLength(100)
      ]),
      assetClassID: new FormControl('', [
        Validators.required,
        // Validators.minLength(8),
      ]),
      description: new FormControl('', [
        Validators.maxLength(100),
      ])


      //  active
      // AssetTypeMaster: new FormArray([new FormControl('')])
    });
    this.classList();
    this.loadData();
  }

  classList() {

    this.coredataservice.getAssetClassList().first().subscribe(response => {
      for (let key in response) {
        this.editVariable.push({ assetClassID: response[key]["assetClassID"], className: response[key]["className"] })
      }
    },
      catchError => {
        if (catchError) {
          this.shareDataService.ErrorHandler(catchError);
        }
      });
  }
  valueChange(changeValue: any, dropdownValue) {
    let object = { "assetFieldID": dropdownValue.assetFieldID, "defaultBehaviour": dropdownValue.defaultBehaviour };
    this.fieldTemplate.filter((element, index) => {
      if (element.assetFieldID === dropdownValue.assetFieldID) {
        this.indexValue = index;
      }
    }
    );
    this.event = changeValue;
    if (changeValue.displayName === "Mandatory") {
      object.defaultBehaviour = changeValue.displayID;
    }

    else if (changeValue.displayName === "Optional") {
      object.defaultBehaviour = changeValue.displayID;

    }
    else {
      object.defaultBehaviour = changeValue.displayID;

    }

    this.fieldTemplate.splice(this.indexValue, 1, object)
    this.editchange = this.fieldTemplate;
    this.gridView.map((element, index) => {
      element.Field.map((value) => {
        if (value.assetFieldID === dropdownValue.assetFieldID) {
          value.option = changeValue;
        }
      }
      );
    }
    );

    this.griStatus = this.gridView;
    console.log(this.griStatus);

  }
  dropdownelement(value: number) {
    this.editVariable.map((element, index) => {
      if (element.assetClassID === value) {
        this.dropdownIndex = index;
      }
    });

  }


  loadData() {    //here grid will form and make mandatory field disabled of value is 1 other wise make it non man


    this.coredataservice.getAssetFields().subscribe(response => {
      this.list = response;
      for (let key in this.list) {

        if (this.list[key].web) {
          this.temparray.push(this.list[key])

        }
      }
      this.temparray.map((element) => {
        this.fieldName.push(element.groupName)
      });
      var filter = this.fieldName;
      var uinqueFieldName = filter.filter(function (item, pos) {
        return filter.indexOf(item) == pos;
      });

      for (let key in uinqueFieldName) {
        this.gridView.push({
          "FieldName": uinqueFieldName[key],
          "Field": []
        })
        this.temparray.map((element) => {
          if (element.groupName === uinqueFieldName[key]) {
            let option = this.commonfmunction.iterateArray(element, element.defaultBehaviour);
            this.gridView[key].Field.push({ 'displayName': element.displayName, "defaultBehaviour": element.defaultBehaviour, "Field": element.displayName, "assetFieldID": element.assetFieldID, "option": option, status: 'system' });
            this.fieldTemplate.push({ "assetFieldID": element.assetFieldID, "defaultBehaviour": element.defaultBehaviour });
          }
        });
        this.editchange = this.fieldTemplate;
        this.gridView.filter(ele => {
          if (ele.FieldName != 'Custom') {
            this.griStatus.push(ele)
          }
        })

        //to filter duplicate conditions
        //FieldName
        //var unique = [...new Set(array.map(item => item.age))];
        


        //to filter duplicate conditions

        // this.griStatus = this.gridView;

        this.shareDataService.griddata = this.gridView;
      }
    },
      catchError => {
        if (catchError) {
          this.sharedService.ErrorHandler(catchError);

          // this.toastrService.error("error")


        }
      });
  }
  GroupNameBlur(event) {
    if (event.target.value != undefined && event.target.value != "") {

      this.coredataservice.ValidateAssetGroupName(undefined, event.target.value).subscribe(res => {
        if (res != null && res != undefined && res.length > 0) {
          this.listEditData.groupName = null
          this.notify.error(this.ErrorMsgData.groupalreadyExist);
        }
        else {
          this.listEditData.groupName = this.listEditData.groupName;
        }
      })
    }
  }
  saveAssetGroup() {
    if (this.formgroupAsset.valid) {

      let newFormTemplate = this.editchange.filter(element => element.defaultBehaviour != '-1');
      let temp = {
        AssetGroupID: null,
        GroupName: this.listEditData.groupName,
        Description: this.listEditData.description,
        AssetClassID: this.listEditData.assetClassID,
        FormTemplate: JSON.stringify(newFormTemplate),
        Active: true
      };

      this.coredataservice.createAssets(temp).subscribe(
        (response) => {
          if (response && response != null) {
            let temp = response;
            this.shareDataService.jsoinEditedFormat = temp["FormTemplate"];
            this.notify.success(this.ErrorMsgData.newAssetCreated);

            // this.toastrService.success("New Asset Has been Created ");
            this.router.navigate(["/asset-group"]);
          }
        }, catchError => {
          if (catchError) {
            // this.toastrService.error("error " + catchError.status + " " + catchError.statusText)
            this.sharedService.ErrorHandler(catchError);


          }
        });
    }

    // else if (!this.formGroup.form.controls.groupName.invalid === false && !this.formGroup.form.controls.States.invalid === true) {
    //   this.notify.warning('Please enter groupName !');

    // }
    // else if (!this.formGroup.form.controls.groupName.invalid === true && !this.formGroup.form.controls.States.invalid === false) {
    //   this.notify.warning('Please select class Name !');

    // }

    // else {
    //   this.notify.warning('Please enter all manadatory fields !');

    // }
    else {
      Object.keys(this.formgroupAsset.controls).forEach(field => { // {1}
        const control = this.formgroupAsset.get(field); // {2}
        // let kd = document.getElementsByName(field)[0]
        // if(kd != undefined){
        // }
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });
      this.notify.error(this.ErrorMsgData.mandatoryField);
    }
  }


}
