export class AssetListingEntity {
    public assetID: number;
    public userAssetID: string;
    public barcode: string;
    public locationName: string;
    public assetTypeName: string;
    public statusName: string;
    public updationDate: any;
    public creationDate: any;
    public geoLocation: string;
    public actualAge: string;
  }
  
  export class AssetGeoLocationUpdate {
    public AssetID: number
    public GeoLocation: string
  }