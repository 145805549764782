import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { SharedDataService } from './CommonUtility.service'
import { Component } from '@angular/core';
import { HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { delay } from 'rxjs/operators/delay';
import * as CryptoJS from 'crypto-js';
import { OrgSettingEntity } from '../SystemAdmin/organisation-setting/organisation-setting.model';
import { parse } from 'url';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(private sharedDataService: SharedDataService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler, ): Observable<HttpEvent<any>> {

    if (req.url === 'saveUrl') {
      const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
        type: HttpEventType.UploadProgress,
        loaded: x,
        total: 100
      }).pipe(delay(1000)));

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
      events.push(success);

      return concat(...events);
    }

    if (req.url === 'removeUrl') {
      return of(new HttpResponse({ status: 200 }));
    }
    if (localStorage.getItem('OrgSetting') != null && localStorage.getItem('OrgSetting') != undefined) {
      var UserData = this.sharedDataService.parseJwt(localStorage.getItem('token'));

      this.sharedDataService.GetOrgSettings().then(res => {
        let orgSettings = res;
        if (orgSettings != undefined) {
          var previousIntervalDate = new Date();
          previousIntervalDate.setDate(previousIntervalDate.getDate() - parseInt(orgSettings['PasswordResetInterval'].toString()));
          var LastPasswordReset = new Date(UserData.LastPasswordReset)
          if (LastPasswordReset < previousIntervalDate) {
            this.sharedDataService.ShowChangePass$.next('true');
          }
          else {
            this.sharedDataService.ShowChangePass$.next('false');
          }
        }
      });


    }

    const newRequest = req.clone({

      headers: req.headers.set('Authorization', 'bearer ' + localStorage.getItem('token')),

    });
    return next.handle(newRequest);

  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };
}
