import { RequestPasswordAuthGuardService } from './Service/request-auth-gaurd.service';
import { ResetPasswordAuthGuardService } from './Service/reset-password-gaurd.service';
import { ResponsePasswordResetComponent } from './response-password-reset/response-password-reset.component';
import { UserListComponent } from './SystemAdmin/user-list/user-list.component';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AssetFieldConfigComponent } from "../app/AccountManagement/asset-field-config/asset-field-config.component";
import { AddLocationComponent } from "../app/AccountManagement/add-location/add-location.component";
import { LoginComponent } from "../app/login/login.component";
import { EditAssetGroupComponent } from '../app/AccountManagement/edit-asset-group/edit-asset-group.component';
import { HeaderComponent } from "../app/Common/header/header.component";
import { AddCustomerComponent } from "../app/AccountManagement/add-customer/add-customer.component";
import { AddUserComponent } from "../app/SystemAdmin/add-user/add-user.component";
import { AssetsListingComponent } from "../app/AccountManagement/asset-listing/assets-listing.component";
//import { ConditionAuditsComponent } from "../evaluate-project/Component/condition-audits.component";
import { CompanyManagementComponent } from "../app/SystemAdmin/company-management/company-management.component";
import { CreateCompanyComponent } from "../app/SystemAdmin/create-company/create-company.component";
import { CustomerManagementComponent } from "../app/AccountManagement/customer-management/customer-management.component";
//import { AddNewCustomerComponent } from "../evaluate-project/Component/add-new-customer.component";
import { AssetTypeManagementComponent } from "../app/AccountManagement/asset-type-management/asset-type-management.component";
import { CreateAssetTypeComponent } from "../app/AccountManagement/create-asset-type/create-asset-type.component";
import { LocationManagementComponent } from "../app/AccountManagement/location-management/location-management.component";
import { AssetGroupComponent } from "../app/AccountManagement/asset-group/asset-group.component";
import { CreateAssetGroupComponent } from "../app/AccountManagement/create-asset-group/create-asset-group.component";
import { AuthGuardService } from '../app/Service/auth-guard.service';
//import { UserManagementComponent } from "../evaluate-project/Component/user-management.component";
import { DashboardComponent } from '../app/Dashboard/dashboard.component';
//import { OrganisationSettingComponent } from '../evaluate-project/Component/organisation-setting.component';
import { DataImportComponent } from '../app/SystemAdmin/data-import/data-import.component';
import { DataExportComponent } from '../app/SystemAdmin/data-export/data-export.component';
import { EditCompanyComponent } from "../app/SystemAdmin/edit-company/edit-company.component";
import { AddAssetComponent } from '../app/AccountManagement/add-asset/add-asset.component';
import { EmailConfigurationComponent } from '../app/SystemAdmin/email-configuration/email-configuration.component';
import { EmailConfigurationListingComponent } from '../app/SystemAdmin/email-configuration-listing/email-configuration-listing.component';
import { from } from 'rxjs';
//import { StatusConfigurationComponent } from "../evaluate-project/Component/status-configuration.component";
//import { EditAssetGroupComponent } from "../evaluate-project/Component/edit-asset-group.component";
import { UnitOfMeasurmentgComponent } from '../app/SystemAdmin/unit-of-measurment/unit-of-measurment.component';
import { UnitOfMeasurmentListingComponent } from '../app/SystemAdmin/unit-of-measurment-listing/unit-of-measurment-listing.component';
import { UserEditRouteResolver } from '../app/SystemAdmin/user-list/user-resolver.service'
import { ReportsComponent } from '../app/AccountManagement/Reports/reports.component';
import { GlobalSearchComponent } from '../app/Common/global-search/global-search.component';
import { AssetJobsComponent } from './AccountManagement/asset-jobs/asset-jobs.component';
import { HelpComponent } from './help/help.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
 
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { CodeManagementComponent } from '../app/SystemAdmin/code-management/code-management.component';
import { MyTeamsDashboardComponent } from '../app/my-teams-dashboard/my-teams-dashboard.component'
import { AdminDashboardComponent } from '../app/admin-dashboard/admin-dashboard.component'
import { ResponsePasswordResetMobile } from '../app/response-password-reset/response-password-reset-mobile.component'
import { AssetRefactoringComponent } from '../app/SystemAdmin/asset-refactoring/asset-refactoring.component'
import { Component } from '@angular/core';
import { ChangePasswordComponent } from '../app/Common/change-password/change-password.component';
import { MyAccountComponent } from './Common/my-account/my-account.component';
import { OrganisationSettingComponent } from '../app/SystemAdmin/organisation-setting/organisation-setting.component';
import { FaultfixManagementComponent } from './SystemAdmin/faultfix-management/faultfix-management.component';
import { ContentManagementComponent } from './SystemAdmin/content-management/content-management.component';
import { UserActivityReportComponent } from './SystemAdmin/user-activity-report/user-activity-report.component';
import { BusinessAssetsComponent } from './AccountManagement/business-assets/business-assets.component';
import { ReserveAssetComponent } from './InventoryManagement/reserve-asset/reserve-asset.component';
import { NewReportsComponent } from './AccountManagement/New Report/reports.component';
import { LocationRMListComponent } from './AccountManagement/RM-listing/LocationRMList-management.component';
import { AssetsRMList } from './AccountManagement/RM-listing/AssetsRM-management.component';
import { BusinessDashboardComponent } from './InventoryManagement/business-Dashboard/business-dashboard.component';
import { InventoryReportsComponent } from './InventoryManagement/Inventory Report/reports.component';
import { AddAssetJobComponent } from './AccountManagement/add-asset-jobs/add-asset-job.component';
import { ImageImportComponent } from './SystemAdmin/data-import/image-import.component';



const APP_ROUTES: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Dashboard' }
  },
  {
    path: 'ChangePassword',
    component: ChangePasswordComponent,
    canActivate: [AuthGuardService],
    data: { title: 'ChangePassword' }
  },

  {
    path: 'MyTeamDashboard',
    component: MyTeamsDashboardComponent,
    canActivate: [AuthGuardService],
    data: { title: 'MyTeamDashboard' }
  },
  {
    path: 'AdminDashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuardService],
    data: { title: 'AdminDashboard' }
  }
  ,{
  path: 'InventoryDashboard',
  component: BusinessDashboardComponent,
  canActivate: [AuthGuardService],
  data: { title: 'InventoryDashboard' }
}
,
  {
    path: 'MyAccount',
    component: MyAccountComponent,
    canActivate: [AuthGuardService],
    data: { title: 'My Account' }
  },
  {
    path: 'asset-group',
    component: AssetGroupComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Asset Group Management' }
  },
  {
    path: 'edit-company/:id',
    component: EditCompanyComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'report',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Report Management' }
  },
  {
    path: 'new-report',
    component: NewReportsComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Report Management' }
  },
  {
    path: 'login/:p',
    component: LoginComponent
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'help',    
    component: HelpComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Help Page' }
  },

  {
    path: 'help/:p',    
    component: HelpComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Help Page' }
  },
  {
    path: 'WhatsNew',    
    component: WhatsNewComponent,
  
  },
  
   
  {
    path: 'add-customer',
    component: AddCustomerComponent,
    //   children:[
    //  {
    //   path: 'fieldConfig',
    //   component: AssetFieldConfigComponent
    //  }
    //   ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'AddAssets',
    component: AddAssetComponent,
    pathMatch: 'full',
    data: { title: 'Add Asset' }
  },

  {
    path: 'header',
    component: HeaderComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'assets-listing',
    component: AssetsListingComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Asset Management' }
  },
  {
    path: 'dashboard-assets-listing',
   component: AssetsListingComponent,
  canActivate: [AuthGuardService],
   data: { title: 'Asset Management' ,IsDashboardAsset:true}
   },
  {
    path: 'business-assets-listing',
    component: BusinessAssetsComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Business Asset Management' }
  },
  {
    path: 'reserve-asset/:id',
    component: ReserveAssetComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Reserve Asset' }
  },

  {
    path: 'create-asset-group',
    component: CreateAssetGroupComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Asset Group' }
  },
  {
    path: 'company-management',
    component: CompanyManagementComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Company Management' }

  },
  {
    path: 'create-company',
    component: CreateCompanyComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'customer-management',
    component: CustomerManagementComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Customer Management' , IsCustomerManagement : true,IsBusinessUnit : false}

  },
  {
    path: 'location-RM-management',
    component: LocationRMListComponent,
    canActivate: [AuthGuardService],
  
    data: { title: 'Repair and Maintainance' }
  },
  {
    path: 'assets-RM-Mangement',
    component: AssetsRMList,
    canActivate: [AuthGuardService],
  
    data: { title: 'Repair and Maintainance' }
  },
  {
    path: 'business-unit-management',
    component: CustomerManagementComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Business Unit Management' , IsBusinessManagement : true ,IsBusinessUnit : true}

  },

  {
    path: 'asset-type-management',
    component: AssetTypeManagementComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Asset Type Management' }
  },
  {
    path: 'create-asset-type',
    component: CreateAssetTypeComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Asset Type' }
  },
  {
    path: 'location-management',
    component: LocationManagementComponent,
    data: { title: 'Location Management' ,IsLocationManagement : true ,IsDepartment : false}
  },
  {
    path: 'department-management',
    component: LocationManagementComponent,
    data: { title: 'Department Management' ,IsDepartmentManagement : true, IsDepartment : true}
  },
  {
    path: 'add-location',
    component: AddLocationComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Location' }
  },

  {
    path: 'asset-refactoring',
    component: AssetRefactoringComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Asset Refactoring' }
  },
  {
    path: 'UserManagement',
    component: UserListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'AddUser',
    component: AddUserComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add User' }
  },
  {
    path: 'AddUser/:userID',
    component: AddUserComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Update User' },
    resolve: { userDetail: UserEditRouteResolver }
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Dashboard' }
  },
  {
    path: 'data-import',
    component: DataImportComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Data Import' }

  },
  {
  path: 'image-import',
    component: ImageImportComponent,
    data: { title: 'Image Import' }

  },
  {
    path: 'data-export',
    component: DataExportComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'edit-asset-group/:id', component: EditAssetGroupComponent, canActivate: [AuthGuardService],
    data: { title: 'Update Asset Group' }
  },
  {

    path: 'add-customer',
    component: AddCustomerComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Customer' ,IsAddCustomer : true ,IsBusinessUnit : false}
  },
  {

    path: 'add-business-unit',
    component: AddCustomerComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Business Unit' ,IsAddBusiness : true,IsBusinessUnit : true}
  },
  {

    path: 'add-customer/:id',
    component: AddCustomerComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Update Customer' ,IsUpdateCustomer : true,IsBusinessUnit : false}
  },
  {

    path: 'add-business-unit/:id',
    component: AddCustomerComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Update Business Unit' ,IsUpdateBusiness : true,IsBusinessUnit : true}
  },
  {
    path: 'create-asset-group',
    component: CreateAssetGroupComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'add-location/:id',
    component: AddLocationComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Update Location' ,IsUpdateLocation : true ,IsDepartment : false}
  },
  {
    path: 'add-location',
    component: AddLocationComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Location' ,IsAddLocation : true,IsDepartment : false}
  },
  {
    path: 'add-department',
    component: AddLocationComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Department' ,IsAddDepartment : true,IsDepartment : true }
  },
  {
    path: 'edit-company',
    component: EditCompanyComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'edit-asset-group', component: EditAssetGroupComponent, canActivate: [AuthGuardService],
    data: { title: 'Add Asset Group' }
  },
  {
    path: 'add-department/:id',
    component: AddLocationComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Update Department' ,IsUpdateDepartment : true,IsDepartment : true }
  },


  {
    path: 'AddAssets/:id',
    component: AddAssetComponent,
    pathMatch: 'full',
    data: { title: 'Update Asset' }
  },

  {
    path: 'ViewAssetsJobs/:id',
    component: AssetJobsComponent,
    pathMatch: 'full',
    data: { title: 'Asset Job History' }
  },
  {
    path: 'viewAssetsJobs',
    component: AssetJobsComponent,
    pathMatch: 'full',
    data: { title: 'Asset Jobs' }
  },
  {
    path: 'add-asset-job/:id',
    component: AddAssetJobComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Update Asset Job' ,IsUpdateAssetJob : true}
  },
  {
    path: 'add-asset-job',
    component: AddAssetJobComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Add Asset Job' ,IsAddAssetJob : true}
  },


  {
    path: 'fieldConfig',
    component: AssetFieldConfigComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'email-configuration',
    component: EmailConfigurationComponent,
    pathMatch: 'full',

  },
  {
    path: 'email-configuration/:id',
    component: EmailConfigurationComponent,
    pathMatch: 'full'
  },
  {
    path: 'email-configuration-listing',
    component: EmailConfigurationListingComponent,
    pathMatch: 'full',
    data: { title: 'Email Configuration Management' }
  },
  {
    path: 'unit-of-measurment',
    component: UnitOfMeasurmentgComponent,
    pathMatch: 'full',
    data: { title: 'Add Unit Of Measurement' }
  },
  {
    path: 'organisation-setting',
    component: OrganisationSettingComponent,
    pathMatch: 'full',
    data: { title: 'Organisation Setting' }
  },
  {
    path: 'content-management',
    component: ContentManagementComponent,
    pathMatch: 'full',
    data: { title: 'Content Management' }

  },
  {
    path: 'unit-of-measurment/:id',
    component: UnitOfMeasurmentgComponent,
    pathMatch: 'full',
    data: { title: 'Update Unit Of Measurement' }
  },
  {
    path: 'unit-of-measurment-listing',
    component: UnitOfMeasurmentListingComponent,
    pathMatch: 'full',
    data: { title: 'Unit Of Measurement Management' }
  },
  {
    path: 'global-search',
    component: GlobalSearchComponent,
    pathMatch: 'full',
    data: { title: 'Global Search' }
    // onSameUrlNavigation: 'reload'
  },
  {
    path: 'global-search/:globalSearchText',
    component: GlobalSearchComponent,
    pathMatch: 'full',
    data: { title: 'Global Search' }
  },
  {
    path: 'report',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Report Management' }

  },
  {
    path: 'inventory-Report',
    component: InventoryReportsComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Report Management' }

  },
  {
    path: 'RequestPasswordReset',
    component: RequestPasswordResetComponent
    //canActivate:[RequestPasswordAuthGuardService]
  },
  {
    path: 'ResponsePasswordReset/:p',
    component: ResponsePasswordResetComponent,
    canActivate: [ResetPasswordAuthGuardService],
  },
  {
    path: 'CodeManagement',
    component: CodeManagementComponent,
    pathMatch: 'full',
    data: { title: 'Code Management' }

  },
  {
    path: 'FaultfixManagement',
    component: FaultfixManagementComponent,
    pathMatch: 'full',
    data: { title: 'Fault and Fix Management' }
  },
  {
    path: 'MobileResetPassword',
    component: ResponsePasswordResetMobile,
    pathMatch: 'full'
  },
  {
    path: "userActivity-report",
    component: UserActivityReportComponent,
    pathMatch: 'full',
    data: { title: 'User Activity Report' }
  }

];


export const routing = RouterModule.forRoot(APP_ROUTES);
