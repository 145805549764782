// export class codeManagementListEntity{
//     public code :string;
//     public description : string;
//     public sequence : number;
//}
// save code data modal
export class codeManagementTypeModel{
    public codeTypeID : number;
    public description  : string;
    public isSystem  : boolean;
    public codeName:string;
    public displayName:string;
}
export class codeTypeList{
    public codeID :number;
    public codeTypeID : number;
    public codeValue : string;
    public weightage : number;
    public sequence  : number;
}
export class DefaultNewCodeManagementRow{
    public codeID :number;
   // public codeTypeID : number;
    public codeValue: string="";
    public weightage : number=0;
    public sequence : number=0;
}
export class codeTypeModel{
    public codeTypeID : number;
    public codeName : string;
}
export class codeTypeGridList{
    public codeID :number;
    public codeValue: string;
    public weightage : number;
    public sequence : number;
}