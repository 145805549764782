import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "./Service/authentication.service";
import { environment } from 'src/environments/environment';
// import { CustomCurrency } from "././Common/CustomCurrency.pipe";
import { HttpClient } from '@angular/common/http';
import { SharedDataService } from "./Service/CommonUtility.service";
import { CoreDataService } from "./Service/core-data.service"
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // providers:[CustomCurrency]
})
export class AppComponent implements OnInit {
  isLoggedIn: boolean;
  constructor(private authService: AuthenticationService, private http: HttpClient, private sharedDataService: SharedDataService, private DataService: CoreDataService) {
    Promise.all(
      [
        this.DataService.GetLoginConfig(this.sharedDataService.GetLanguageLiteral(), "login.json"),
        this.DataService.GetFooterConfig(this.sharedDataService.GetLanguageLiteral(), "footer.json"),
        this.DataService.GetGridDataConfig(this.sharedDataService.GetLanguageLiteral(), "grid.json"),
        this.DataService.GetHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "header.json"),
        this.DataService.GetLocationConfig(this.sharedDataService.GetLanguageLiteral(), "location.json"),
        this.DataService.GetAssetListingConfig(this.sharedDataService.GetLanguageLiteral(), "asset_listing.json"),
        this.DataService.GetCustomerManagementConfig(this.sharedDataService.GetLanguageLiteral(), "customerManagementConfig.json"),
        this.DataService.getCustomerConfig(this.sharedDataService.GetLanguageLiteral(), "addCustomer.json"),
        this.DataService.addLocationConfig(this.sharedDataService.GetLanguageLiteral(), "addLocation.json"),
        this.DataService.addAssetConfig(this.sharedDataService.GetLanguageLiteral(), "addAsset.json"),
        this.DataService.getCompanyManagementConfig(this.sharedDataService.GetLanguageLiteral(), "companyManagement.json"),
        this.DataService.getCompanyConfig(this.sharedDataService.GetLanguageLiteral(), "createCompany.json"),
        this.DataService.getUserManagementConfig(this.sharedDataService.GetLanguageLiteral(), "userManagement.json"),
        this.DataService.getUserUpdateConfig(this.sharedDataService.GetLanguageLiteral(), "addUser.json"),
        this.DataService.getAssetGroupConfig(this.sharedDataService.GetLanguageLiteral(), "assetGroup.json"),
        this.DataService.createAssetGroupConfig(this.sharedDataService.GetLanguageLiteral(), "createAssetGroup.json"),
        this.DataService.getAssetTypeManagementConfig(this.sharedDataService.GetLanguageLiteral(), "assetTypeManagement.json"),
        this.DataService.creatAssetTypeConfig(this.sharedDataService.GetLanguageLiteral(), "createAssetType.json"),
        this.DataService.getEmailConfig(this.sharedDataService.GetLanguageLiteral(), "emailConfiguration.json"),
        this.DataService.updateEmailConfig(this.sharedDataService.GetLanguageLiteral(), "emailConfig.json"),
        this.DataService.getAssetRefactoring(this.sharedDataService.GetLanguageLiteral(), "assetrefactoring.json"),
        this.DataService.getOrganisationSettingConfig(this.sharedDataService.GetLanguageLiteral(), "organisationSetting.json"),
        this.DataService.getUnitOfMesurListing(this.sharedDataService.GetLanguageLiteral(), "unitOfMeasListing.json"),
        this.DataService.updateUnitOfMeasurement(this.sharedDataService.GetLanguageLiteral(), "addUnitOfMeasurment.json"),
        this.DataService.getcodeMangementConfig(this.sharedDataService.GetLanguageLiteral(), "codeManagement.json"),
        this.DataService.getFaultFixConfig(this.sharedDataService.GetLanguageLiteral(), "faultfixmanagement.json"),
        this.DataService.getDocumentUploadConfig(this.sharedDataService.GetLanguageLiteral(), "documentUpload.json"),
        this.DataService.getReportConfig(this.sharedDataService.GetLanguageLiteral(), "report.json"),
        this.DataService.getEroorMessageConfig(this.sharedDataService.GetLanguageLiteral(), "ErrorMessagesConfig.json"),
        this.DataService.getFilterConfig(this.sharedDataService.GetLanguageLiteral(), "filter.json"),
        this.DataService.getAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "AssetFields.json"),
        this.DataService.getResetPassword(this.sharedDataService.GetLanguageLiteral(), "resetpassword.json"),
        this.DataService.getDashboardConfig(this.sharedDataService.GetLanguageLiteral(), "dashboard.json"),
        this.DataService.getAdminDashboard(this.sharedDataService.GetLanguageLiteral(), "adminDashboard.json"),
        this.DataService.getTeamDashboard(this.sharedDataService.GetLanguageLiteral(), "teamDashboard.json"),
        this.DataService.getNotificationMessage(this.sharedDataService.GetLanguageLiteral(), "popupMsg.json"),
        this.DataService.getFieldConfig(this.sharedDataService.GetLanguageLiteral(), "assetFieldConfig.json"),
        this.DataService.getChangepasswordConfig(this.sharedDataService.GetLanguageLiteral(), "changepassword.json"),
        this.DataService.getSubAssetConfig(this.sharedDataService.GetLanguageLiteral(), "subAsset.json"),
        this.DataService.getMyAccountConfig(this.sharedDataService.GetLanguageLiteral(), "myAccount.json"),
        this.DataService.getDataImportConfig(this.sharedDataService.GetLanguageLiteral(), "dataImport.json"),
        this.DataService.getAssetFieldGroup(this.sharedDataService.GetLanguageLiteral(), "AssetFieldGroup.json"),
        this.DataService.getAdminConfig(this.sharedDataService.GetLanguageLiteral(), "adminConfig.json"),
        this.DataService.getContentMangementConfig(this.sharedDataService.GetLanguageLiteral(), "contentManagement.json"),
        this.DataService.getUserActivityReportConfig(this.sharedDataService.GetLanguageLiteral(), "activityReport.json"),
        this.DataService.getCustomerFieldConfig(this.sharedDataService.GetLanguageLiteral(), "customerFieldStatusConfig.json"),
        // this.DataService.getOtherFilterConfig(this.sharedDataService.GetLanguageLiteral(), "othersFilter.json"),
        this.DataService.getddlAgeGroup(this.sharedDataService.GetLanguageLiteral(), "ddlAgeGroup.json"),
        this.DataService.getddlRemainingLifeGroup(this.sharedDataService.GetLanguageLiteral(), "ddlRemainingLife.json"),
        this.DataService.getddlReplacementCost(this.sharedDataService.GetLanguageLiteral(), "ddlReplacementCost.json"),
        this.DataService.getddlReplacementyear(this.sharedDataService.GetLanguageLiteral(), "ddlReplacementYear.json"),
        this.DataService.getServiceCall(this.sharedDataService.GetLanguageLiteral(), "otherServiceCall.json"),
        this.DataService.getSortBy(this.sharedDataService.GetLanguageLiteral(), "otherSortBy.json"),
        this.DataService.getOhsComment(this.sharedDataService.GetLanguageLiteral(), "otherOhsComment.json"),
        this.DataService.getSubAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "SubAssetFields.json"),
        this.DataService.getSubAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "GlobalSearch.json"),
        this.DataService.getbusinessunitManagement(this.sharedDataService.GetLanguageLiteral(), "businessunitManagementConfig.json"),
        this.DataService.getaddBusiness(this.sharedDataService.GetLanguageLiteral(), "addBusiness.json"),
        this.DataService.getDepartment(this.sharedDataService.GetLanguageLiteral(), "Department.json"),
        this.DataService.getaddDepartment(this.sharedDataService.GetLanguageLiteral(), "addDepartment.json"),
        this.DataService.getbusinessAssetListing(this.sharedDataService.GetLanguageLiteral(), "BusinessAsset_listing.json"),
        this.DataService.getReserveAsset(this.sharedDataService.GetLanguageLiteral(), "reserve_asset.json"),
        this.DataService.GetBusinessHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "BusinessHeader.json"),
        this.DataService.GetBusinessErrorConfig(this.sharedDataService.GetLanguageLiteral(), "BusinessErrorConfig.json"),
        this.DataService.getddlTotalSpend(this.sharedDataService.GetLanguageLiteral(), "ddlTotalSpend.json"),
        this.DataService.GetAssetJobListingConfig(this.sharedDataService.GetLanguageLiteral(), "asset_job_listing.json"),
        this.DataService.GetRepairAndMaintainanceConfig(this.sharedDataService.GetLanguageLiteral(), "repair_and_maintainance_listing.json"),
        this.DataService.getBusinessDashboard(this.sharedDataService.GetLanguageLiteral(), "business_dashboard.json"),
        this.DataService.getddlIMStatus(this.sharedDataService.GetLanguageLiteral(), "ddlIMStatus.json"),
        this.DataService.getExpenseDashboard(this.sharedDataService.GetLanguageLiteral(), "expenseDashboard.json")
        
        
          
      ]).then(res => {
        this.sharedDataService.languageConfig = res
        this.sharedDataService.languageConfig$.next(this.sharedDataService.languageConfig);
      });

    authService.loggedIn$.subscribe(
      data => {
        this.ngOnInit();
      });
  }

  ngOnInit() {

    // console.log('APP')

    var pathname = window.location.origin;

    console.log(pathname);

    // console.log(environment.environment.apiUrl)

    // var data = null
    // var xhttp = new XMLHttpRequest();
    // xhttp.onreadystatechange = function() {
    // if (this.readyState == 4 && this.status == 200) {
    // // Action to be performed when the document is read;
    // data = JSON.parse(xhttp.responseText);
    // console.log(data);
    // data.apiUrl = 'http://test.evaluatesystems.com.au/api/api/'
    // environment.apiUrl = data.apiUrl;
    // console.log(environment.apiUrl + "2")
    // }
    // };

    // xhttp.open("GET", "/assets/sample.json", true);
    // xhttp.send();



    this.isLoggedIn = this.authService.isAuthenticated();
    if (this.isLoggedIn) {
      this.authService.isLoggedIn();
    }
  }


}