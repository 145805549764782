import { Injectable } from '@angular/core';
import { Response, Http, URLSearchParams, RequestOptions } from '@angular/http';
import { CustomerDetails } from "../AccountManagement/add-customer/add-customer.model";
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, Subject, BehaviorSubject } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { OrgSettingEntity } from '../SystemAdmin/organisation-setting/organisation-setting.model';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class CoreDataService {
  geolocation: string;
  defaultStatusConfigurationCodeValue: Array<any>;
  savegroupDetails: Array<any> = [];
  customerDetails: CustomerDetails = new CustomerDetails();
  customerBehaviorSubject = new BehaviorSubject<any>(this.customerDetails);
  groupDetails = new BehaviorSubject<any>(this.savegroupDetails);
  baseUrl = environment.environment.apiUrl;

  constructor(private http: HttpClient, private simpleHttp: Http) {
    //this.baseUrl = environment.apiUrl;
  }

  private userActivityUrl = this.baseUrl + "User/GetUserActivity";
  private contentManagementUrl = this.baseUrl + "ContentManagement/SaveContent";
  private faultManagementUrl = this.baseUrl + "Fault/SaveFaultGroup";
  private manufacturerModelList = this.baseUrl + "Asset/ManufacturerModelList";
  private faultGroupListing = this.baseUrl + "Fault/FaultGroupList";
  private faultListing = this.baseUrl + "Fault/FaultList";
  private saveFault = this.baseUrl + "Fault/SaveFault";
  private saveFix = this.baseUrl + "Fix/SaveFix";
  private fixListing = this.baseUrl + "Fix/FixList";
  private assetReportFilter =
    this.baseUrl + "Report/SaveAssetReportFilterTemplate";
  private getAssetReportFilterList =
    this.baseUrl + "Report/GetAssetReportFilterTemplate";
  private saveAssetReportUrl =
    this.baseUrl + "Report/SaveAssetReportFieldTemplate";
  private getAssetReportTemplate =
    this.baseUrl + "Report/GetAssetReportFieldTemplate";
  private deleteAssetTemplate =
    this.baseUrl + "Report/AssetReportFieldTemplateDetete";

    private saveCustomReport=this.baseUrl+"Report/SaveCustomReport";
    private getCustomReport=this.baseUrl+"Report/GetCustomReport";
    private deleteCustomReport=this.baseUrl+"Report/DeleteCustomReport";
  private assetClassList = this.baseUrl + "AssetClass/GetAssetClassDDL";
  private getlocationRMUrl = this.baseUrl + "Location/GetLocationRM";
  private savelocationRMUrl = this.baseUrl + "Location/SaveLocationRM";
  private deleteLocationRMUrl = this.baseUrl + "Location/DeleteLocationRM";
  private getCustomerListUrl = this.baseUrl + "Customer";
  private getCustomerByIdUrl = this.baseUrl + "Customer";
  private getStatusConfigurationByCompanyIdUrl =
    this.baseUrl + "Customer/StatusConfigurationByCompany";
  private getcustomerSatusConfigurationUrl =
    this.baseUrl + "Customer/StatusConfiguration";
  private CompanyUrl = this.baseUrl + "Company";
  private customerUrl = this.baseUrl + "Customer";
  private createAssetUrl = this.baseUrl + "AssetGroup";
  private getuserByRole = this.baseUrl + "User/UsersByRole";
  private getUserByIDUrl = this.baseUrl + "User/Id";
  private getUserListUrl = this.baseUrl + "User";
  private getCodeUrl = this.baseUrl + "Code";
  private getAssetFieldUrl = this.baseUrl + "AssetField/AssetFieldDetails";
  private getSubAssetFieldUrl =
    this.baseUrl + "SubAssetField/SubAssetFieldDetails";
  private getAssetClassDropDownListUrl =
    this.baseUrl + "AssetClass/GetAssetClassDDL";
  private getAddUpdateAssetGroupUrl = this.baseUrl + "AssetGroup";
  private assetFieldConfigListUrl =
    this.baseUrl + "AssetGroup/AssetGroupDetails";
  //ADD-User API
  private getLocationByCompanyUrl = this.baseUrl + "User/GetLocationsByCompany";
  private getLocationBySearchUrl = this.baseUrl + "User/GetLocationsByCompany";
  private addUpdateUserUrl = this.baseUrl + "User";
  private getCompanyByNameUrl = this.baseUrl + "Company/CompanyByName";
  private getAssetFieldConfigurationByCompanyUrl =
    this.baseUrl + "Customer/AssetFieldConfigurationByCustomer";
  private AddUpdateAssetFieldConfigurationUrl =
    this.baseUrl + "Customer/AssetFieldConfiguration";
  private AddUpdateAssetUrl = environment.environment.apiUrl + "Asset";

  private GetAssetListingURL =
    environment.environment.apiUrl + "Asset/AssetDetailsList";

  private GetBusinessAssetDetailsListURL =
    environment.environment.apiUrl + "Asset/BusinessAssetDetailsList";

  private AssetFieldConfigurationTemplate =
    this.baseUrl + "AssetGroup/AssetFieldConfigurationTemplate";
  private userLocationDropdownUrl = this.baseUrl + "Location/UserLocation";
  private AssetTypeByNameDropDownUrl =
    this.baseUrl + "AssetType/AssetTypeByName";

  private GetAssetByBarcodeDropDownUrl =
    this.baseUrl + "Asset/GetAssetByBarcode";

  private getLocationMangementListUrl = this.baseUrl + "Location";
  private AddUpdateLocationUrl = this.baseUrl + "Location";
  private getLocationByIDUrl = this.baseUrl + "Location";
  private addUpdateLocationAssetTypeBaselineUrl =
    this.baseUrl + "Location/AssetTypeBaseline";
  private getLocationAssetTypeBaselineUrl =
    this.baseUrl + "Location/GetAssetTypeBaseline";
  private getCustomerbyNameUrl = this.baseUrl + "Customer/CustomerByName";
  private AddUpdateSubAsset = this.baseUrl + "SubAsset";
  private EditSubAssetUrl = this.baseUrl + "SubAsset";
  private getAssetsTypeListUrl = this.baseUrl + "AssetType";
  private AddUpdataAssetTypeUrl = this.baseUrl + "AssetType";
  private getAssetGroupDropdownUrl =
    this.baseUrl + "AssetGroup/GetAssetGroupDDL";
  private editAssetTypeUrl = this.baseUrl + "AssetType";
  private UOMTypeDropDownUrl = this.baseUrl + "UOM/UOMTypeDropdown";
  private getReplacementCostUrl = this.baseUrl + "Asset/ReplacementCostBudget";
  private RepairAndMaintenanceCostBudgetUrl = this.baseUrl + "Asset/RepairAndMaintenanceCostBudget";
  private UploadImageUrl = this.baseUrl + "ImageData";
  private getUOMDataURL = this.baseUrl + "UOM/UOMData";
  private getImageDataLocation = this.baseUrl + "ImageData/GetImageByTypeAndID";
  private getEmailConfigurationListUrl = this.baseUrl + "EmailTemplate";
  private editEmailConfigurationUrl = this.baseUrl + "EmailTemplate";
  private addUpdateEmailConfigurationUrl = this.baseUrl + "EmailTemplate";
  private getUOMListUrl = this.baseUrl + "UOMType";
  private editUOMUrl = this.baseUrl + "UOMType";
  private AddUpdataUOMTypeUrl = this.baseUrl + "UOMType";
  private AddUpdataUOMUrl = this.baseUrl + "UOM";
  private getAssetGlobalSearchUrl = this.baseUrl + "Asset/AssetGlobalSearch";
  private getCustomerGlobalSearchUrl =
    this.baseUrl + "Customer/CustomerGlobalSearch";
  private getLocationGlobalSearchUrl =
    this.baseUrl + "Location/LocationGlobalSearch";
  private SendEmailNotificationUrl =
    this.baseUrl + "EmailNotification/SendNotification";
  private DashboardKPIUrl = this.baseUrl + "Dashboard/DashboardKPI";
  private DashboardRemainingLifeByAgeGroupUrl =
    this.baseUrl + "Dashboard/RemainingLifeByAgeGroup";
  private HighestReplacementCostUrl =
    this.baseUrl + "Dashboard/ReplacementBugetRemainingLifeChart";
  private HighestTotalSpendAssetsUrl=this.baseUrl + "Dashboard/GetHighestTotalSpendAssets";
  private DashboardConditionRatingUrl =
    this.baseUrl + "Dashboard/ConditionRating";
  private DashboardYearBudgetUrl = this.baseUrl + "Dashboard/YearBudget";
  private DashboardRefrigerantBreakdownUrl =
    this.baseUrl + "Dashboard/RefrigerantBreakdown";
  private DashboardAssetGroupKwhUrl = this.baseUrl + "Dashboard/AssetGroupKwh";
  private DashboardAgeVsConditionUrl =
    this.baseUrl + "Dashboard/AgeVSCondition";

  private AssetJob = this.baseUrl + "Asset/GetAssetJobsByAssetID";
  private AssetJobs = this.baseUrl + "AssetJob/AssetJobs";
  private AssetJobsbyID = this.baseUrl + "AssetJob/GetAssetJobsByID";
  private AssetJobDeleteUrl=this.baseUrl+"AssetJob/DeleteAssetJob"
  private AddUpdateAssetJobUrl=this.baseUrl+"AssetJob/AddUpdateAssetJob"
  private CheckAssetExistsURL=this.baseUrl+"AssetJob/CheckAssetExists"
  private AssetJobDetails = this.baseUrl + "Asset/AssetJobDetails";
  private UserAdminDetails = this.baseUrl + "User/UserAdminDetails";

  private getProgressReportUrl = this.baseUrl + "Report/ProjectProgressReportFile";
  private getAssetExportReportUrl = this.baseUrl + "Report/AssetReportExcel";
  private getFaultGroupDetailsURL = this.baseUrl + "Fault/FaultGroupDetails";

  private saveFaultGroupDetails = this.baseUrl + "AssetType/AssetTypeFault";
  private saveFixDetails = this.baseUrl + "AssetType/AssetTypeFix";

  private getFaultDetails = this.baseUrl + "Fault/AssetTypeFaultByID";
  private getFixDetails = this.baseUrl + "Fix/AssetTypeFixByID";

  private assetTypeFaultByID = this.baseUrl + "AssetType/AssetTypeFaultByID";
  private assetTypeFixByID = this.baseUrl + "AssetType/AssetTypeFixByID";
  private getAssetTypeRMUrl = this.baseUrl + "AssetType/GetAssetTypeRM";
  private saveAssetTypeRMUrl = this.baseUrl + "AssetType/SaveAssetTypeRM";
  private deleteAssetTypeRMUrl = this.baseUrl + "AssetType/DeleteAssetTypeRM";
  private DeleteRMPricingCapacityByIDUrl = this.baseUrl + "AssetType/DeleteRMPricingCapacityByID";

  private getFixDetailsURL = this.baseUrl + "Fix/FixDetails";
  private savePortfolioReport = this.baseUrl + "Report/SaveAssetReport";
  private CheckAssetReportCountURL =
    this.baseUrl + "Report/CheckAssetReportCount";
  private scheduledPortfolioReportUrl =
    this.baseUrl + "Report/SaveAssetReportSchedule";

  private requestPasswordResetUrl =
    this.baseUrl + "ResetUser/RequestPasswordReset";
  private validatePasswordResetLinkUrl =
    this.baseUrl + "ResetUser/ValidateLink";
  private updateNewPasswordUrl = this.baseUrl + "ResetUser/UpdatePassword";

  private getCodeManagementDropDownUrl = this.baseUrl + "Code/CodeTypes";
  private getCodeManagementDataByID = this.baseUrl + "code/CodeByTypeID";
  private saveCodeManagementDetails = this.baseUrl + "code/SaveCodeType";
  private saveCodeManagementGride = this.baseUrl + "Code";

  private getReportqueueURL = this.baseUrl + "Report/AssetReportQueue";
  private getScheduleURL = this.baseUrl + "Report/AssetReportScheduled";

  private UpdateGeoLocationByAssetURL =
    this.baseUrl + "Asset/UpdateGeoLocationByAsset";

  private ReviewerActiveProjectsURL =
    this.baseUrl + "Dashboard/ReviewerActiveProjects";
  private ReviewerTechAuditAnalysisURL =
    this.baseUrl + "Dashboard/ReviewerTechAuditAnalysis";
  private ReviewerActiveTechUrl =
    this.baseUrl + "Dashboard/AdminDashboardActiveTech";

  private ReviewerProjectDurationURL =
    this.baseUrl + "Dashboard/ReviewerProjectDuration";

  private AdminDashboardKPIURL = this.baseUrl + "Dashboard/AdminDashboardKPI";
  private AssetRefactorUrl = this.baseUrl + "Asset/RefactorAssets";
  private RandmReportURl = this.baseUrl + "Report/RandMPlanReport";
  private ValidateDataUrl = this.baseUrl + "DataImport/ValidateData";
  private ValidateLocationDataUrl =
    this.baseUrl + "DataImport/ValidateLocationData";
  private saveBulkAssetUrl = this.baseUrl + "DataImport/AssetSave";
  private saveBulkLocationUrl = this.baseUrl + "DataImport/LocationSave";

  private UservalidateUserEmail = this.baseUrl + "User/ValidateUserEmail";

  private AssetvalidateBarcode = this.baseUrl + "Asset/ValidateAssetBarcode";
  private ValidateLocationNameUrl =
    this.baseUrl + "Location/ValidateLocationName";
  private ValidateCompanyNameUrl = this.baseUrl + "Company/ValidateCompanyName";
  private ValidateCustomerNameUrl =
    this.baseUrl + "Customer/ValidateCustomerName";
  private ValidateAssetGroupNameUrl =
    this.baseUrl + "AssetGroup/ValidateAssetGroupName";
  private ValidateAssetTypeNameUrl =
    this.baseUrl + "AssetType/ValidateAssetTypeName";
  private ValidateUOMTypeNameUrl = this.baseUrl + "UOMType/ValidateUOMTypeName";
  private ChangePassword = this.baseUrl + "User/ChangePassword";
  private organisationSettings = this.baseUrl + "OrganisationSettings";
  private organisationSettingSaveUrl =
    this.baseUrl + "OrganisationSettings/UpdateOrgSettings";
  private ValidateExternalLoginUrl =
    this.baseUrl + "User/ValidateExternalLogin";
  private ValidateMicrosoftUrl =
    this.baseUrl + "User/ValidateMicrosoftUser";

  private getReportFields = this.baseUrl + "AssetField/GetReportFields";
  private GetUserContextByUserIDUrl = this.baseUrl + "User/UserContextByUserID";
  private ApplicationLogoutUrl = this.baseUrl + "Application/UserLogOut";
  private OrgsSettings = this.baseUrl + "OrganisationSettings";
  private CommentHistoryGetUrl = this.baseUrl + "Asset/CommentHistory";
  private DocumentsSaveURL = this.baseUrl + "Document";
  private AuditLog = this.baseUrl + "Asset/AuditLog";
  private BookingLog = this.baseUrl + "Booking/AuditLog";

  private MoveSubAssetByAssetID = this.baseUrl + "Asset/MoveSubAssetByAssetID";

  private ScheduledDeleteUrl =
    this.baseUrl + "Report/AssetReportScheduledDetete";

  private AssetFieldLabelDynamic =
    this.baseUrl + "AssetField/AssetFieldDisplayName";
  private AssetReportFilterTemplateDelete =
    this.baseUrl + "Report/AssetReportFilterTemplateDetete";
  public ContentResourceFile = this.baseUrl + "FileShare/ResourceFile";

  private AddUpdateBookingAssetsUrl =
    environment.environment.apiUrl + "Booking";
  private BookingDetailsByAssetIDUrl =
    environment.environment.apiUrl + "Booking/GetBookingDetailsByAssetID";
  private ComplianceManagementbyAssetIDUrl =
    environment.environment.apiUrl + "Booking/GetComplianceManagementbyAssetID";
  private BookingDetailsByBookingIDUrl =
    environment.environment.apiUrl + "Booking/GetBookingDetailByBookingID";

  private DeleteAssetBookingDetailsByBookingIDUrl =
    environment.environment.apiUrl +"Booking/DeleteAssetBookingDetailsByBookingID";
    private getRMUrl = this.baseUrl + "RM/GetLocationsRMBudget";
    private getRMListOnRMItemUrl = this.baseUrl + "RM/GetAssetRMBudget";
    private addUpdateRMBudgetUrl = this.baseUrl + "RM";
    private CheckAssetsRMBudgetExistsURL=this.baseUrl+"RM/CheckAssetsRMBudgetExists"
    private getContentManagementListURL = this.baseUrl + "ContentManagement/GetContentManagement";
    private BusinessDashboardKPIUrl = this.baseUrl + "BusinessDashboard/GetBusinessDashboardKPI";
    private BusinessDashboardAssetGroupCheckoutsUrl=this.baseUrl+"BusinessDashboard/GetAssetGroupByCheckOuts";
    private BusinessDashboardAssetTypeCheckoutsUrl=this.baseUrl+"BusinessDashboard/GetAssetTypeByCheckOuts";
    private BusinessDashboardAssetsCheckoutsUrl=this.baseUrl+"BusinessDashboard/GetAssetsWithMostCheckOuts"
    private BusinessDashboardAssetsOverduesUrl=this.baseUrl+"BusinessDashboard/GetAssetsWithMostOverdues"
    private BusinessDashboardAssetsNonCompliantUrl=this.baseUrl+"BusinessDashboard/GetAssetsNonCompliant"
    private GetNextTestandTagDueReportURl = this.baseUrl + "BusinessReport/GetCurrentMonthNextTestAndTagDueReport";
    private GetNextServiceDueReportURl = this.baseUrl + "BusinessReport/GetCurrentMonthNextServiceDueReport";
    private GetUserByCustomerIDURl=this.baseUrl+"Location/UserByCustomer";
    private GetEquipmentByLocationIdURL=this.baseUrl+"Asset/EquipmentByLocationId";
    private ExpenseDashboardDataURL = this.baseUrl + "Asset/GetExpenseDashboardDetails";

  DeleteAssetBookingDetailsByBookingID(BookingID) {
    return this.http
      .delete(this.DeleteAssetBookingDetailsByBookingIDUrl, {
        params: {
          id: BookingID,
        },
      })
      .catch(this.handleError);
  }

  GetBookingDetailByBookingID(BookingID) {
    return this.http
      .get(this.BookingDetailsByBookingIDUrl, {
        params: {
          BookingID: BookingID,
        },
      })
      .catch(this.handleError);
  }

  GetBookingDetailsByAssetID(AssetID) {
    return this.http
      .get(this.BookingDetailsByAssetIDUrl, {
        params: {
          AssetID: AssetID,
        },
      })
      .catch(this.handleError);
  }
  GetComplianceManagementbyAssetID(AssetID) {
    return this.http
      .get(this.ComplianceManagementbyAssetIDUrl, {
        params: {
          AssetID: AssetID,
        },
      })
      .catch(this.handleError);
  }

  bookAssets(data): Observable<any> {
    return this.http
      .post(this.AddUpdateBookingAssetsUrl, data)
      .catch(this.handleError);
  }

  saveContentManagement(data): Observable<any> {
    return this.http
      .post(this.contentManagementUrl, data)
      .catch(this.handleError);
  }

  moveSubAssetByAssetID(data): Observable<any> {
    return this.http
      .post(this.MoveSubAssetByAssetID, data)
      .catch(this.handleError);
  }

  saveAssetReport(data): Observable<any> {
    return this.http.post(this.assetReportFilter, data).catch(this.handleError);
  }

  getAssetReportFilter(): Observable<any> {
    return this.http.get(this.getAssetReportFilterList).catch(this.handleError);
  }

  DeleteReportTemplate(id): Observable<any> {
    return this.http
      .delete(this.AssetReportFilterTemplateDelete, {
        params: {
          AssetReportFilterTemplateID: id,
        },
      })
      .catch(this.handleError);
  }
  getmanufactureeModelList(AssetTypeID, MfgCd) {
    return this.http
      .get(this.manufacturerModelList, {
        params: {
          AssetTypeID: AssetTypeID,
          MfgCd: MfgCd,
        },
      })
      .catch(this.handleError);
  }

  saveFaultBYGroup(data): Observable<any> {
    return this.http
      .post(this.faultManagementUrl, data)
      .catch(this.handleError);
  }

  getFaultGroupList(): Observable<any> {
    return this.http.get(this.faultGroupListing).catch(this.handleError);
  }

  saveAsFault(data): Observable<any> {
    return this.http.post(this.saveFault, data).catch(this.handleError);
  }

  faultList(): Observable<any> {
    return this.http.get(this.faultListing).catch(this.handleError);
  }

  saveAsFix(data): Observable<any> {
    return this.http.post(this.saveFix, data).catch(this.handleError);
  }

  FixListing(): Observable<any> {
    return this.http.get(this.fixListing).catch(this.handleError);
  }

  GetAssetFieldLabelByLang(LanguageLitral): Observable<any> {
    return this.http
      .get(this.AssetFieldLabelDynamic, {
        params: {
          LanguageLitral: LanguageLitral,
        },
      })
      .catch(this.handleError);
  }

  GetAuditLog(AssetID): Observable<any> {
    return this.http
      .get(this.AuditLog, {
        params: {
          AssetID: AssetID,
        },
      })
      .catch(this.handleError);
  }

  GetBookAuditLog(AssetID): Observable<any> {
    return this.http
      .get(this.BookingLog, {
        params: {
          AssetID: AssetID,
        },
      })
      .catch(this.handleError);
  }

  SaveDocuments(data): Observable<any> {
    return this.http.post(this.DocumentsSaveURL, data).catch(this.handleError);
  }

  GetDocuments(DocType, AssociateID, IsDocRequired): Observable<any> {
    return this.http
      .get(this.DocumentsSaveURL, {
        params: {
          DocType: DocType,
          AssociateID: AssociateID,
          IsDocRequired: IsDocRequired,
        },
      })
      .catch(this.handleError);
  }

  DeleteDocument(id): Observable<any> {
    return this.http
      .delete(this.DocumentsSaveURL + "/" + id)
      .catch(this.handleError);
  }

  CommentHistoryGet(CommentType, AssociateID): Observable<any> {
    return this.http
      .get(this.CommentHistoryGetUrl, {
        params: {
          CommentType: CommentType,
          AssociateID: AssociateID,
        },
      })
      .catch(this.handleError);
  }

  UpdateApplicationLogout(): Observable<any> {
    return this.http.get(this.ApplicationLogoutUrl).catch(this.handleError);
  }

  GetOrgsSettings() {
    return this.http.get<OrgSettingEntity>(this.OrgsSettings).toPromise();
  }

  GetUserContextByUserID(UserID): Observable<any> {
    return this.http
      .get(this.GetUserContextByUserIDUrl, {
        params: {
          UserID: UserID,
        },
      })
      .catch(this.handleError);
  }

  GetReportFields(CustomerID): Observable<any> {
    return this.http
      .get(this.getReportFields, {
        params: {
          CustomerID: CustomerID,
        },
      })
      .catch(this.handleError);
  }

  GetAssetReportFieldTemplate(): Observable<any> {
    return this.http.get(this.getAssetReportTemplate);
  }

  SaveAssetReportFieldTemplate(data): Observable<any> {
    return this.http
      .post(this.saveAssetReportUrl, data)
      .catch(this.handleError);
  }
  OrganSettings(): Observable<any> {
    return this.http.get(this.organisationSettings).catch(this.handleError);
  }
  OrganizationSettings(data): Observable<any> {
    return this.http
      .post(this.organisationSettingSaveUrl, data)
      .catch(this.handleError);
  }

  changePassword(data): Observable<any> {
    data.passEncriptCode = CryptoJS.AES.encrypt(
      data.newPassword,
      "EVALUATE"
    ).toString();
    //console.log(data)
    return this.http.post(this.ChangePassword, data).catch(this.handleError);
  }
  ValidateExternalLogin(ExternalLogintoken): Observable<any> {
    return this.http
      .post(this.ValidateExternalLoginUrl, {
        token: ExternalLogintoken,
      })
      .catch(this.handleError);
  }
  async ValidateMicrosoftUser(ValidateMicrosofttoken): Promise<any> {
    return  await this.http
      .post(this.ValidateMicrosoftUrl, {
        token: ValidateMicrosofttoken,
      }).toPromise()
      .catch(this.handleError);
  }

  ValidateUserEmail(UserID, SearchText): Observable<any> {
    return this.http
      .get(this.UservalidateUserEmail, {
        params: {
          UserID: UserID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }

  ValidateAssetBarcode(AssetID, LocationID, SearchText): Observable<any> {
    return this.http
      .get(this.AssetvalidateBarcode, {
        params: {
          AssetID: AssetID,
          LocationID: LocationID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  ValidateLocationName(LocationID, CustomerID, SearchText): Observable<any> {
    return this.http
      .get(this.ValidateLocationNameUrl, {
        params: {
          LocationID: LocationID,
          CustomerID: CustomerID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  ValidateCompanyName(CompanyID, SearchText): Observable<any> {
    return this.http
      .get(this.ValidateCompanyNameUrl, {
        params: {
          CompanyID: CompanyID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  ValidateCustomerName(CustomerID, CompanyID, SearchText): Observable<any> {
    return this.http
      .get(this.ValidateCustomerNameUrl, {
        params: {
          CustomerID: CustomerID,
          CompanyID: CompanyID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  ValidateAssetGroupName(AssetGroupID, SearchText): Observable<any> {
    return this.http
      .get(this.ValidateAssetGroupNameUrl, {
        params: {
          AssetGroupID: AssetGroupID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  ValidateAssetTypeName(AssetTypeID, SearchText): Observable<any> {
    return this.http
      .get(this.ValidateAssetTypeNameUrl, {
        params: {
          AssetTypeID: AssetTypeID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  ValidateUOMTypeName(UOMTypeID, SearchText): Observable<any> {
    return this.http
      .get(this.ValidateUOMTypeNameUrl, {
        params: {
          UOMTypeID: UOMTypeID,
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  GetRandMPlanReport(CustomerId, locationID,Language): Observable<any> {
    return this.http
      .get(this.RandmReportURl, {
        params: {
          locationID: locationID==""? 0:locationID,
          CustomerId: CustomerId,
          Language:Language
        },
      })
      .catch(this.handleError);
  }
  AssetRefactor(CustomerID, LocationID): Observable<any> {
    return this.http
      .get(this.AssetRefactorUrl, {
        params: {
          CustomerID: CustomerID,
          LocationID: LocationID,
        },
      })
      .catch(this.handleError);
  }
  getUserAdminDetails(Table, AssociatedID): Observable<any> {
    return this.http
      .get(this.UserAdminDetails, {
        params: {
          Table: Table,
          AssociatedID: AssociatedID,
        },
      })
      .catch(this.handleError);
  }

  getAdminDashboardKPI(start, end, CustomerID, LocationID): Observable<any> {
    return this.http
      .get(this.AdminDashboardKPIURL, {
        params: {
          StartDate: start,
          EndDate: end,
          CustomerID: CustomerID,
          LocationID: LocationID,
        },
      })
      .catch(this.handleError);
  }

  getReviewerActiveProjects(
    start,
    end,
    CustomerID,
    LocationID
  ): Observable<any> {
    return this.http
      .get(this.ReviewerActiveProjectsURL, {
        params: {
          StartDate: start,
          EndDate: end,
          CustomerID: CustomerID,
          LocationID: LocationID,
        },
      })
      .catch(this.handleError);
  }

  getReviewerTechAuditAnalysis(
    start,
    end,
    CustomerID,
    LocationID
  ): Observable<any> {
    return this.http
      .get(this.ReviewerTechAuditAnalysisURL, {
        params: {
          StartDate: start,
          EndDate: end,
          CustomerID: CustomerID,
          LocationID: LocationID,
        },
      })
      .catch(this.handleError);
  }
  getReviewerActiveTech(start, end, CustomerID, LocationID): Observable<any> {
    return this.http
      .get(this.ReviewerActiveTechUrl, {
        params: {
          StartDate: start,
          EndDate: end,
          CustomerID: CustomerID,
          LocationID: LocationID,
        },
      })
      .catch(this.handleError);
  }

  getReviewerProjectDuration(
    start,
    end,
    CustomerID,
    LocationID
  ): Observable<any> {
    return this.http
      .get(this.ReviewerProjectDurationURL, {
        params: {
          StartDate: start,
          EndDate: end,
          CustomerID: CustomerID,
          LocationID: LocationID,
        },
      })
      .catch(this.handleError);
  }

  getReportQueueDetails(): Observable<any> {
    return this.http.get(this.getReportqueueURL).catch(this.handleError);
  }
  getScheduledetails(): Observable<any> {
    return this.http.get(this.getScheduleURL).catch(this.handleError);
  }

  DeleteScheduledetails(id): Observable<any> {
    return this.http
      .delete(this.ScheduledDeleteUrl, {
        params: {
          AssetReportScheduledID: id,
        },
      })
      .catch(this.handleError);
  }

  getFaultGroupDetails(): Observable<any> {
    return this.http.get(this.getFaultGroupDetailsURL).catch(this.handleError);
  }

  SaveFaultGroup(data): Observable<any> {
    return this.http
      .post(this.saveFaultGroupDetails, data)
      .catch(this.handleError);
  }

  SaveFix(data): Observable<any> {
    return this.http.post(this.saveFixDetails, data).catch(this.handleError);
  }

  DataImportValidateData(data): Observable<any> {
    return this.http.post(this.ValidateDataUrl, data).catch(this.handleError);
  }
  DataImportValidateLocationData(data): Observable<any> {
    return this.http
      .post(this.ValidateLocationDataUrl, data)
      .catch(this.handleError);
  }
  DataImportSaveAsset(data): Observable<any> {
    return this.http.post(this.saveBulkAssetUrl, data).catch(this.handleError);
  }
  DataImportSaveLocation(data): Observable<any> {
    return this.http
      .post(this.saveBulkLocationUrl, data)
      .catch(this.handleError);
  }

  UpdateGeoLocationByAsset(data): Observable<any> {
    return this.http
      .post(this.UpdateGeoLocationByAssetURL, data)
      .catch(this.handleError);
  }

  GetFaultDetails(AssetTypeID): Observable<any> {
    return this.http
      .get(this.getFaultDetails, {
        params: {
          AssetTypeID: AssetTypeID,
        },
      })
      .catch(this.handleError);
  }

  GetAssetypeFaultByID(AssetTypeID): Observable<any> {
    return this.http
      .get(this.assetTypeFaultByID, {
        params: {
          AssetTypeID: AssetTypeID,
        },
      })
      .catch(this.handleError);
  }

  GetassetTypeFixByID(AssetTypeID): Observable<any> {
    return this.http
      .get(this.assetTypeFixByID, {
        params: {
          AssetTypeID: AssetTypeID,
        },
      })
      .catch(this.handleError);
  }

  GetFixDetails(AssetTypeID): Observable<any> {
    return this.http
      .get(this.getFixDetails, {
        params: {
          AssetTypeID: AssetTypeID,
        },
      })
      .catch(this.handleError);
  }

  GetFixDetailsURL(): Observable<any> {
    return this.http.get(this.getFixDetailsURL).catch(this.handleError);
  }

  getAssetJob(AssetID): Observable<any> {
    return this.http
      .get(this.AssetJob, {
        params: {
          AssetID: AssetID,
        },
      })
      .catch(this.handleError);
  }
  getAssetJobs(): Observable<any> {
    return this.http
      .get(this.AssetJobs)
      .catch(this.handleError);
  }

  getAssetJobDetails(AssetID): Observable<any> {
    return this.http
      .get(this.AssetJobDetails, {
        params: {
          AssetID: AssetID,
        },
      })
      .catch(this.handleError);
  }

  // getDashboardKPIUrl(CustomerID, LocationID, ReportFilter): Observable<any> {
  //   return this.http.get(this.DashboardKPIUrl, { params: { CustomerID: CustomerID, LocationID: LocationID , ReportFilter:ReportFilter} })
  //     .catch(this.handleError);
  // }

  getDashboardKPIUrl(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardKPIUrl, ReportModel)
      .catch(this.handleError);
  }

  // getDashboardRemainingLifeByAgeGroupUrl(report): Observable<any> {
  //   return this.http.get(this.DashboardRemainingLifeByAgeGroupUrl, { params: { CustomerID: CustomerID, LocationID: LocationID } })
  //     .catch(this.handleError);
  // }

  getDashboardRemainingLifeByAgeGroupUrl(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardRemainingLifeByAgeGroupUrl, ReportModel)
      .catch(this.handleError);
  }
  getHighestReplacementCost(ReportModel: any): Observable<any> {
    return this.http
      .post(this.HighestReplacementCostUrl, ReportModel)
      .catch(this.handleError);
  }
  getHighestTotalSpendAssets(ReportModel: any): Observable<any> {
    return this.http
      .post(this.HighestTotalSpendAssetsUrl, ReportModel)
      .catch(this.handleError);
  }

 
  getDashboardConditionRatingUrl(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardConditionRatingUrl, ReportModel)
      .catch(this.handleError);
  }

  getDashboardYearBudgetUrl(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardYearBudgetUrl, ReportModel)
      .catch(this.handleError);
  }
  getDashboardRefrigerantBreakdownUrl(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardRefrigerantBreakdownUrl, ReportModel)
      .catch(this.handleError);
  }
  getDashboardAgeVsCondition(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardAgeVsConditionUrl, ReportModel)
      .catch(this.handleError);
  }
  getDashboardAssetGroupKwh(ReportModel: any): Observable<any> {
    return this.http
      .post(this.DashboardAssetGroupKwhUrl, ReportModel)
      .catch(this.handleError);
  }

  getAssetFieldConfigList(): Observable<any> {
    return this.http.get(this.assetFieldConfigListUrl).catch(this.handleError);
  }

  setGroupDetails(value) {
    this.groupDetails.next(value);
  }
  setcustomer(value) {
    this.customerBehaviorSubject.next(value);
  }
  getCodeData(): Observable<any> {
    return this.http.get(this.getCodeUrl).catch(this.handleError);
  }

  GetUOMDataURL(Id): Observable<any> {
    return this.http
      .get(this.getUOMDataURL, {
        params: {
          UOMTypeID: Id,
        },
      })
      .catch(this.handleError);
  }
  GetLocationImageData(ImageType, AssociateID): Observable<any> {
    return this.http
      .get(this.getImageDataLocation, {
        params: {
          ImageType: ImageType,
          AssociateID: AssociateID,
        },
      })
      .catch(this.handleError);
  }

  DeleteAssetTemplate(id): Observable<any> {
    return this.http
      .delete(this.deleteAssetTemplate, {
        params: {
          AssetReportFieldTemplateID: id,
        },
      })
      .catch(this.handleError);
  }

  DeleteSubAssetImage(id): Observable<any> {
    return this.http
      .delete(this.UploadImageUrl + "/" + id)
      .catch(this.handleError);
  }

  DeleteAssetImage(id): Observable<any> {
    return this.http
      .delete(this.UploadImageUrl + "/" + id)
      .catch(this.handleError);
  }

  AddUpdateCustomer(data): Observable<any> {
    return this.http.post(this.customerUrl, data).catch(this.handleError);
  }
  //upload
  UploadImageAsset(data): Observable<any> {
    return this.http.post(this.UploadImageUrl, data).catch(this.handleError);
  }

  createCompany(data): Observable<any> {
    return this.http.post(this.CompanyUrl, data).catch(this.handleError);
  }

  GetCompanyByID(Id): Observable<any> {
    return this.http.get(this.CompanyUrl + "/" + Id).catch(this.handleError);
  }

  deleteCompany(id) {
    return this.http
      .delete(this.CompanyUrl + "/" + id)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getAssetClassList(): Observable<any> {
    return this.http.get(this.assetClassList).catch(this.handleError);
  }
  getCompanyList(): Observable<any> {
    return this.http.get(this.CompanyUrl).catch(this.handleError);
  }
  addUpdateAssetGroup(data): Observable<any> {
    return this.http
      .post(this.getAddUpdateAssetGroupUrl, data)
      .catch(this.handleError);
  }
  getAssetGroupList(): Observable<any> {
    return this.http
      .get(this.getAddUpdateAssetGroupUrl)
      .catch(this.handleError);
  }

  getCustomerList(): Observable<any> {
    return this.http.get(this.getCustomerListUrl).catch(this.handleError);
  }

  createAssets(payload: any) {
    return this.http
      .post(this.createAssetUrl, payload)
      .map((response) => response)
      .catch(this.handleError);
  }

  getCustomerById(id): Observable<any> {
    return this.http
      .get(this.getCustomerByIdUrl + "/" + id)
      .catch(this.handleError);
  }
  getStatusConfigByCompanyId(customerid): Observable<any> {
    return this.http
      .post(
        this.getStatusConfigurationByCompanyIdUrl + "?CustomerID=" + customerid,
        {
          // params: {
          //   'CustomerID': customerid
          // }
        }
      )
      .catch(this.handleError);
  }

  AdddUpdateCustomerStatusConfig(
    customerStatusData,
    customerID
  ): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    params.set("customerStatusData", customerStatusData);
    params.set("customerID", customerID);
    return this.http
      .post(this.getcustomerSatusConfigurationUrl, {
        customerStatusData: customerStatusData,
        customerID: customerID,
      })
      .catch(this.handleError);
  }
  getUsersByRole(roleNames): Observable<any> {
    return this.http
      .post(this.getuserByRole, roleNames)
      .catch(this.handleError);
  }
  getUserByID(id): Observable<any> {
    return this.http
      .get(this.getUserByIDUrl, {
        params: {
          Id: id,
        },
      })
      .catch(this.handleError);
  }
  getUserList(): Observable<any> {
    return this.http.get(this.getUserListUrl).catch(this.handleError);
  }
  getAssetFields(): Observable<any> {
    return this.http.get(this.getAssetFieldUrl).catch(this.handleError);
  }
  getSubAssetFields(): Observable<any> {
    return this.http.get(this.getSubAssetFieldUrl).catch(this.handleError);
  }

  getAssetFieldsByID(id): Observable<any> {
    return this.http
      .get(this.getAssetFieldUrl + "/" + id)
      .catch(this.handleError);
  }

  getAssetClassDropDownList(): Observable<any> {
    return this.http
      .get(this.getAssetClassDropDownListUrl)
      .catch(this.handleError);
  }

  getLocationsByCompanySearch(companyId, searchText): Observable<any> {
    return this.http
      .get(this.getLocationByCompanyUrl, {
        params: {
          CompanyID: companyId,
          SearchText: searchText,
        },
      })
      .catch(this.handleError);
  }
  searchLocationByCustLocName(searchText: string): Observable<any> {
    return this.http
      .get(this.getLocationBySearchUrl, { params: { SearchText: searchText } })
      .catch(this.handleError);
  }
  addUpdateUser(data): Observable<any> {
    return this.http
      .post(this.addUpdateUserUrl, data, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .catch(this.handleError);
  }

  getCompanyByName(searchString: string): Observable<any> {
    return this.http
      .get(this.getCompanyByNameUrl, { params: { SearchText: searchString } })
      .catch(this.handleError);
  }
  getAssetFieldConfigurationByCompanyId(customerId): Observable<any> {
    return this.http
      .post(
        this.getAssetFieldConfigurationByCompanyUrl +
          "?CustomerID=" +
          customerId,
        null
      )
      .catch(this.handleError);
  }
  getAssetByID(id): Observable<any> {
    return this.http
      .get(this.getAddUpdateAssetGroupUrl, {
        params: {
          id: id,
        },
      })
      .catch(this.handleError);
  }

  addUpdateSubAssets(data): Observable<any> {
    return this.http.post(this.AddUpdateSubAsset, data).catch(this.handleError);
  }

  removeCompanyById(id) {
    return this.http
      .delete(this.getAddUpdateAssetGroupUrl, {
        params: {
          id: id,
        },
      })
      .catch(this.handleError);
  }
  AddUpdateAssetFieldConfiguration(assetFieldData): Observable<any> {
    return this.http
      .post(this.AddUpdateAssetFieldConfigurationUrl, assetFieldData)
      .catch(this.handleError);
  }

  AddUpdateAsset(data): Observable<any> {
    return this.http.post(this.AddUpdateAssetUrl, data).catch(this.handleError);
  }

  GetAssetReport(data): Observable<any> {
    return this.http
      .post(this.GetAssetListingURL, data)
      .catch(this.handleError);
  }
  GetBusinessAssetListing(data): Observable<any> {
    return this.http
      .post(this.GetBusinessAssetDetailsListURL, data)
      .catch(this.handleError);
  }

  GetAssetByID(ID): Observable<any> {
    return this.http
      .get(this.AddUpdateAssetUrl + "/" + ID)
      .catch(this.handleError);
  }

  DeleteAssetByID(id): Observable<any> {
    return this.http
      .delete(this.EditSubAssetUrl, {
        params: {
          id: id,
        },
      })
      .catch(this.handleError);
  }

  getAssetFieldConfigurationTemplate(LocationID, AssetTypeID): Observable<any> {
    return this.http
      .get(this.AssetFieldConfigurationTemplate, {
        params: {
          LocationID: LocationID,
          AssetTypeID: AssetTypeID,
        },
      })
      .catch(this.handleError);
  }

  getUserLocationDropdown(data): Observable<any> {
    return this.http
      .get(this.userLocationDropdownUrl, { params: { SearchText: data } })
      .catch(this.handleError);
  }
  getUserLocationByCustomerDropdown(searchText, CustomerID): Observable<any> {
    return this.http
      .get(this.userLocationDropdownUrl, {
        params: { SearchText: searchText, CustomerID: CustomerID },
      })
      .catch(this.handleError);
  }
  getAssetTypeByNameDropDownUrl(SearchText): Observable<any> {
    return this.http
      .get(this.AssetTypeByNameDropDownUrl, {
        params: {
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }

  getAssetByBarcodeDropDownUrl(SearchText, Id): Observable<any> {
    return this.http
      .get(this.GetAssetByBarcodeDropDownUrl, {
        params: {
          SearchText: SearchText,
          AssetID: Id,
        },
      })
      .catch(this.handleError);
  }

  getLocationManagementList(): Observable<any> {
    return this.http
      .get(this.getLocationMangementListUrl)
      .catch(this.handleError);
  }

  AddUpdateLocation(data): Observable<any> {
    return this.http
      .post(this.AddUpdateLocationUrl, data)
      .catch(this.handleError);
  }

  getLocationByID(Id): Observable<any> {
    return this.http
      .get(this.getLocationByIDUrl + "/" + Id)
      .catch(this.handleError);
  }
  addUpdateLocationAssetTypeBaseline(Data): Observable<any> {
    return this.http
      .post(this.addUpdateLocationAssetTypeBaselineUrl, Data)
      .catch(this.handleError);
  }

  getLocationAssetTypeBaseline(id): Observable<any> {
    return this.http
      .get(this.getLocationAssetTypeBaselineUrl, {
        params: {
          LocationID: id,
        },
      })
      .catch(this.handleError);
  }
  getCustomerByName(SearchText): Observable<any> {
    return this.http
      .get(this.getCustomerbyNameUrl, {
        params: {
          SearchText: SearchText,
        },
      })
      .catch(this.handleError);
  }
  GetAsset(): Observable<any> {
    return this.http.get(this.AddUpdateAssetUrl).catch(this.handleError);
  }
  //common applicable  operations on http response object
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  getSubAssetByID(id): Observable<any> {
    return this.http
      .get(this.EditSubAssetUrl, {
        params: {
          id: id,
        },
      })
      .catch(this.handleError);
  }

  GetAssetGroupDropdown(): Observable<any> {
    return this.http.get(this.getAssetGroupDropdownUrl).catch(this.handleError);
  }

  AddUpdateAssetType(data): Observable<any> {
    return this.http
      .post(this.AddUpdataAssetTypeUrl, data)
      .catch(this.handleError);
  }

  GetAssetTypeList(): Observable<any> {
    return this.http.get(this.getAssetsTypeListUrl).catch(this.handleError);
  }

  GetAssetTypeDetailsByID(id): Observable<any> {
    return this.http
      .get(this.editAssetTypeUrl + "/" + id)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }
  getUOMTypeDropdown(): Observable<any> {
    return this.http.get(this.UOMTypeDropDownUrl).catch(this.handleError);
  }
  GetEmailConfigurationList(): Observable<any> {
    return this.http
      .get(this.getEmailConfigurationListUrl)
      .catch(this.handleError);
  }
  GetEmailConfigurationDetailsByID(id): Observable<any> {
    return this.http
      .get(this.editEmailConfigurationUrl + "/" + id)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }
  AddUpdateEmailConfiguration(data): Observable<any> {
    return this.http
      .post(this.addUpdateEmailConfigurationUrl, data)
      .catch(this.handleError);
  }
  GetUOMList(): Observable<any> {
    return this.http.get(this.getUOMListUrl).catch(this.handleError);
  }
  GetUOMDetailsByID(id): Observable<any> {
    return this.http
      .get(this.editUOMUrl + "/" + id)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }
  AddUpdateUOMType(data): Observable<any> {
    return this.http
      .post(this.AddUpdataUOMTypeUrl, data)
      .catch(this.handleError);
  }
  AddUpdateUOM(data): Observable<any> {
    return this.http.post(this.AddUpdataUOMUrl, data).catch(this.handleError);
  }
  GetAssetGlobalSearchList(searchText): Observable<any> {
    return this.http
      .get(this.getAssetGlobalSearchUrl, {
        params: {
          SearchText: searchText,
        },
      })
      .catch(this.handleError);
  }
  GetCustomerGlobalSearchList(searchText): Observable<any> {
    return this.http
      .get(this.getCustomerGlobalSearchUrl, {
        params: {
          SearchText: searchText,
        },
      })
      .catch(this.handleError);
  }
  GetLocationGlobalSearchList(searchText): Observable<any> {
    return this.http
      .get(this.getLocationGlobalSearchUrl, {
        params: {
          SearchText: searchText,
        },
      })
      .catch(this.handleError);
  }
  getReplacemnetCostBudget(data) {
    return this.http
      .post(this.getReplacementCostUrl, data)
      .catch(this.handleError);
  }
  getRepairAndMaintenanceCostBudget(data):Promise<any> {
    return this.http
      .post(this.RepairAndMaintenanceCostBudgetUrl, data)
      .catch(this.handleError).toPromise();
  }
  // Mail Notification
  SendEmailNotification(data): Observable<any> {
    return this.http
      .post(this.SendEmailNotificationUrl, data)
      .catch(this.handleError);
  }
  GetProgressRrepot(CustomerID, LocationID,format,ReportType,LanguageConfig): Observable<any> {
    return this.http
      .get(this.getProgressReportUrl, {
        params: {
          CustomerID: CustomerID,
          LocationID: LocationID,
          Format:format,
          ReportType:ReportType,
          LanguageConfig:LanguageConfig
        },
      })
      .catch(this.handleError);
  }
  GetAssetExportReportByID(data): Observable<any> {
    return this.http
      .post(this.getAssetExportReportUrl,data)
      .catch(this.handleError);
  }

  SavePortolioReport(data): Observable<any> {
    return this.http
      .post(this.savePortfolioReport, data)
      .catch(this.handleError);
  }

  CheckAssetRecords(data): Observable<any> {
    return this.http
      .post(this.CheckAssetReportCountURL, data)
      .catch(this.handleError);
  }

  SchedulePortolioReport(data): Observable<any> {
    return this.http
      .post(this.scheduledPortfolioReportUrl, data)
      .catch(this.handleError);
  }

  RequestResetPassword(email): Observable<any> {
    return this.simpleHttp
      .post(this.requestPasswordResetUrl, { emailID: email })
      .catch(this.handleError);
  }
  ValidateResetPasswordLink(encodedeLink): Observable<any> {
    return this.simpleHttp
      .post(this.validatePasswordResetLinkUrl, { encodedParam: encodedeLink })
      .catch(this.handleError);
  }
  UpdateNewPassword(encodedeLink, newPassword): Observable<any> {
    return this.simpleHttp
      .post(this.updateNewPasswordUrl, {
        encodedParam: encodedeLink,
        password: newPassword,
      })
      .catch(this.handleError);
  }

  getCodeManagementDropDown(): Observable<any> {
    return this.http
      .get(this.getCodeManagementDropDownUrl)
      .catch(this.handleError);
  }
  GetCodeManagementDetailsByID(CodeTypeID): Observable<any> {
    return this.http
      .get(this.getCodeManagementDataByID, {
        params: {
          CodeTypeID: CodeTypeID,
        },
      })
      .catch(this.handleError);
  }
  SaveCodeManagementData(data): Observable<any> {
    return this.http
      .post(this.saveCodeManagementDetails, data)
      .catch(this.handleError);
  }
  SaveCodeManagementGrid(data): Observable<any> {
    return this.http
      .post(this.saveCodeManagementGride, data)
      .catch(this.handleError);
  }

  //GET PAGE CONFIG BY LANGUAGE
  GetLoginConfig(langLiteral, filename) {
    // this.http.get<OrgSettingEntity>(this.OrgsSettings).toPromise()
    // return this.http.get(+ langLiteral + '/login.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  GetFooterConfig(langLiteral, filename) {
    // this.http.get<OrgSettingEntity>(this.OrgsSettings).toPromise()
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/footer.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  GetGridDataConfig(langLiteral, filename) {
    // this.http.get<OrgSettingEntity>(this.OrgsSettings).toPromise()
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/grid.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  GetHeaderConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/header.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  GetLocationConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/location.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  GetAssetListingConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/asset_listing.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  GetAssetJobListingConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/asset_listing.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  GetRepairAndMaintainanceConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/asset_listing.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  GetCustomerManagementConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/customerManagementConfig.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getCustomerConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/addCustomer.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  addLocationConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/addLocation.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  addAssetConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/addAsset.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getCompanyManagementConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/companyManagement.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getCompanyConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/createCompany.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getUserManagementConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/userManagement.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getUserUpdateConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/addUser.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getAssetGroupConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/assetGroup.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  createAssetGroupConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/createAssetGroup.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getAssetTypeManagementConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/assetTypeManagement.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  creatAssetTypeConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/createAssetType.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getEmailConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/emailConfiguration.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  updateEmailConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/emailConfig.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getAssetRefactoring(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/assetrefactoring.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getOrganisationSettingConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/organisationSetting.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getUnitOfMesurListing(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/unitOfMeasListing.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  updateUnitOfMeasurement(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/addUnitOfMeasurment.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getcodeMangementConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/codeManagement.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getFaultFixConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/faultfixmanagement.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getDocumentUploadConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/documentUpload.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getReportConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/report.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getEroorMessageConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ErrorMessagesConfig.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getFilterConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/filter.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getAssetFieldConfig(langLiteral, filename) {
    // return this.http
    //   .get("/assets/LanguageConfig/" + langLiteral + "/AssetFields.json")
    //   .toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getSubAssetFieldConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getResetPassword(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/resetpassword.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getDashboardConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/dashboard.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getAdminDashboard(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/adminDashboard.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getBusinessDashboard(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/adminDashboard.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getTeamDashboard(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/teamDashboard.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getNotificationMessage(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/popupMsg.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getFieldConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/assetFieldConfig.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getChangepasswordConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/changepassword.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getSubAssetConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/subAsset.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getMyAccountConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/myAccount.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getDataImportConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/dataImport.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getAssetFieldGroup(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFieldGroup.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getAdminConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/adminConfig.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getContentMangementConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/contentManagement.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getUserActivityReportConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/activityReport.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getCustomerFieldConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/customerFieldStatusConfig.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  // getOtherFilterConfig(langLiteral, filename) {
  //   // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/othersFilter.json').toPromise()
  //   return this.http.get(this.ContentResourceFile, {
  //     params: {
  //       "LanguageLiteral": langLiteral,
  //       "FileName": filename
  //     }
  //   }).toPromise();
  // }

  getddlAgeGroup(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlAgeGroup.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getddlRemainingLifeGroup(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlRemainingLife.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getddlReplacementCost(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlReplacementCost.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getddlIMStatus(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlReplacementCost.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getExpenseDashboard(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlReplacementCost.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getddlReplacementyear(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlReplacementYear.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getddlTotalSpend(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/ddlReplacementYear.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getServiceCall(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/otherServiceCall.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getSortBy(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/otherSortBy.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getOhsComment(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/otherOhsComment.json').toPromise()
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getbusinessunitManagement(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getaddBusiness(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getDepartment(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getaddDepartment(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  getbusinessAssetListing(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }
  getReserveAsset(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  GetBusinessHeaderConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  GetBusinessErrorConfig(langLiteral, filename) {
    // return this.http.get('/assets/LanguageConfig/' + langLiteral + '/AssetFields.json').toPromise();
    return this.http
      .get(this.ContentResourceFile, {
        params: {
          LanguageLiteral: langLiteral,
          FileName: filename,
        },
      })
      .toPromise();
  }

  // getServiceCall(langLiteral) {
  //   return this.http.get('/assets/LanguageConfig/' + langLiteral + '/otherServiceCall.json').toPromise()
  // }

  GetUserActivity(StartDate, EndDate) {
    return this.http
      .get(this.userActivityUrl, {
        params: {
          StartDate: StartDate,
          EndDate: EndDate,
        },
      })
      .catch(this.handleError);
  }

  GetLocationRM(locationID): Observable<any> {
    return this.http
      .get(this.getlocationRMUrl, { params: { locationID: locationID } })
      .catch(this.handleError);
  }
  SaveLocationRM(data): Observable<any> {
    return this.http
      .post(this.savelocationRMUrl, data)
      .map((response) => response)
      .catch(this.handleError);
  }
  DeleteLocationRMbyID(LocationRMID): Observable<any> {
    return this.http
      .delete(this.deleteLocationRMUrl, {
        params: {
          locationRMID: LocationRMID,
        },
      })
      .catch(this.handleError);
  }
  GetAssetTypeRM(AssetTypeID): Observable<any> {
    return this.http
      .get(this.getAssetTypeRMUrl, { params: { AssetTypeID: AssetTypeID } })
      .catch(this.handleError);
  }

  DeleteAssetTypeRMbyID(AssetTypeRMID): Observable<any> {
    return this.http
      .delete(this.deleteAssetTypeRMUrl, {
        params: {
          AssetTypeRMID: AssetTypeRMID,
        },
      })
      .catch(this.handleError);
  }
  DeleteRMPricingCapacityByID(RMPricingCapacityID): Observable<any> {
    return this.http
      .delete(this.DeleteRMPricingCapacityByIDUrl, {
        params: {
          RMPricingCapacityID: RMPricingCapacityID,
        },
      })
      .catch(this.handleError);
  }
  GetCustomReport(): Observable<any> {
    return this.http.get(this.getCustomReport);
  }
  DeleteCustomReport(id): Observable<any> {
    return this.http
      .delete(this.deleteCustomReport, {
        params: {
          CustomReportID: id,
        },
      })
      .catch(this.handleError);
  }
  SaveCustomReport(data): Observable<any> {
    return this.http
      .post(this.saveCustomReport, data)
      .catch(this.handleError);
  }
  GetRMList(): Observable<any> {
    return this.http
      .get(this.getRMUrl, )
      .catch(this.handleError);
  }
  GetRMListOnRMItem(LocationID:number): Observable<any> {
    return this.http
      .get(this.getRMListOnRMItemUrl,{
        params: {
        
          LocationID:String(LocationID)
        
        },
   } )
      .catch(this.handleError);
  }
  GetContentManagementList(): Observable<any> {
    return this.http
      .get(this.getContentManagementListURL )
      .catch(this.handleError);
  }
  SaveRMBudget(data): Observable<any> {
    return this.http
      .post(this.addUpdateRMBudgetUrl, data)
      .map((response) => response)
      .catch(this.handleError);
  }
  CheckAssetsRMBudgetExists(assetID){

    return this.http.get(this.CheckAssetsRMBudgetExistsURL,{
      params: {
      
        AssetID:String(assetID)
      
  }}) .catch(this.handleError);

  }
  getBusinessDashboardKPI(CustomerID,LocationID): Observable<any> {
    return this.http
      .get(this.BusinessDashboardKPIUrl,{
        params:{
          CustomerID:CustomerID,
          LocationID:LocationID
        }})
      .catch(this.handleError);
  }

  getDashboardAssetGroupChart(CustomerID,LocationID):Observable<any>{
    return this.http
    .get(this.BusinessDashboardAssetGroupCheckoutsUrl,{
      params:{
        CustomerID:CustomerID,
        LocationID:LocationID
      }})
    .catch(this.handleError);

  }
  
  getDashboardAssetTypeChart(CustomerID,LocationID):Observable<any>{
    return this.http
    .get(this.BusinessDashboardAssetTypeCheckoutsUrl,{
      params:{
        CustomerID:CustomerID,
        LocationID:LocationID
      }})
    .catch(this.handleError);

  }
  getDashboardAssetsCheckout(CustomerID,LocationID):Observable<any>{
    return this.http
    .get(this.BusinessDashboardAssetsCheckoutsUrl,{
      params:{
        CustomerID:CustomerID,
        LocationID:LocationID
      }})
    .catch(this.handleError);

  }
  getDashboardAssetsOverdues(CustomerID,LocationID):Observable<any>{
    return this.http
    .get(this.BusinessDashboardAssetsOverduesUrl,{
      params:{
        CustomerID:CustomerID,
        LocationID:LocationID
      }})
    .catch(this.handleError);

  }
  getDashboardAssetsNonCompliant(CustomerID,LocationID):Observable<any>{
    return this.http
    .get(this.BusinessDashboardAssetsNonCompliantUrl,{
      params:{
        CustomerID:CustomerID,
        LocationID:LocationID
      }})
    .catch(this.handleError);

  }
  GetNextServiceDueReport(CustomerId, locationID,format,Language): Observable<any> {
    return this.http
      .get(this.GetNextServiceDueReportURl, {
        params: {
          locationID: locationID,
          CustomerId: CustomerId,
          Language:Language,
          Format:format
        },
      })
      .catch(this.handleError);
  }
  GetNextTestandTagDueReport(CustomerId, locationID,format,Language): Observable<any> {
    return this.http
      .get(this.GetNextTestandTagDueReportURl, {
        params: {
          locationID: locationID,
          CustomerId: CustomerId,
          Language:Language,
          Format:format
        },
      })
      .catch(this.handleError);
  }
  DeleteAssetJobs(id): Observable<any> {
    return this.http
      .delete(this.AssetJobDeleteUrl, {
        params: {
          AssetJobID: id,
        },
      })
      .catch(this.handleError);
  }
  AddUpdateAssetJob(data): Observable<any> {
    return this.http
      .post(this.AddUpdateAssetJobUrl, data)
      .catch(this.handleError);
  }
  getAssetJobbyID(id): Observable<any> {
    return this.http
      .get(this.AssetJobsbyID, {
        params: {
          AssetJobID: id,
        }})
      .catch(this.handleError);
  }
  CheckAssetExists(EquipmentID,AddressCode,GPCustomerID){

    return this.http.get(this.CheckAssetExistsURL,{
      params: {
      
        EquipmentID:String(EquipmentID),
        AddressCode:String(AddressCode),
        GPCustomerID:String(GPCustomerID)
      
  }}) .catch(this.handleError);

  }

  getUserbyCustomerID(id): Observable<any> {
    return this.http
      .get(this.GetUserByCustomerIDURl, {
        params: {
          CustomerID: id,
        }})
      .catch(this.handleError);
  }

  getEquipmentByLocationId(id): Observable<any> {
    return this.http
      .get(this.GetEquipmentByLocationIdURL, {
        params: {
          AssetLocationID: id,
        }})
      .catch(this.handleError);
  }

  getExpenseDashboardData(id): Observable<any> {
    return this.http
      .get(this.ExpenseDashboardDataURL, {params:{
       AssetID:id
      }})
      .catch(this.handleError);
  }


  private handleError(error: Response | any) {
    //  will use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || "";
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
      if (error.status == 0) {
        window.alert("Network error. Please contact support");
      } else error.status != 200 && error.status != 204;
      {
        window.alert("An Internal error has occured, Please contact support.");
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }

  
}
