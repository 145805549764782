

export class AssetJobListingEntity{
    public assetJobID: number;
    public jobNumber: string;
    public workDescription: string;
    public state: string;
    public technician: string;
    public problemType: string;
    public resolution: string;
    public equipmentID: string;
    public completionDate: Date;
    public callType: string;
    public status: string;
    public addressCode: any;
    public gpCustomerID: string;
    public priority: number;
    public creationDate: Date;
    public purchaseorder: string;
    public requestedBy: string;
    public spend_ExGST: number;
    public barcode: string;
    public customerName:string;

  }
  
  export class AssetJobDetailsListingEntity{
    public userAssetID: string;
    public barcode: string;
    public replacementCostBudget: number;
    public totalSpend: number;
    public remainingLife: number;
    public noOfServiceJobs: number;
  }