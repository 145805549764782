import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { Observable } from 'rxjs/Rx';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { CoreDataService } from './core-data.service'
import { SnotifyService } from 'ng-snotify';
@Injectable()
export class ResetPasswordAuthGuardService implements CanActivate {
  userStatus: boolean;
  validityStatus: boolean;
  constructor(
    private notify: SnotifyService,
    private authService: AuthenticationService,
    private router: Router,
    private coreDataService: CoreDataService,
    private sharedDataService: SharedDataService
  ) {
    // this.userStatus = this.authService.loggedIn;
    this.userStatus = localStorage.getItem('token') == null ? false : true;
  }
  canActivate(snapshot: ActivatedRouteSnapshot, status: RouterStateSnapshot): Promise<any> {
    if (!this.userStatus) {
      //Need to revisit for Activated Route.
      //let encodedURL = this.activatedRoute.snapshot.params['p'];

      let encodedURL = window.location.href;
      encodedURL = encodedURL.split('/p=')[1];

      return this.coreDataService.ValidateResetPasswordLink(encodedURL).first().toPromise().then(
        res => {
          if (res != null && res != undefined) {
            let validResponse=JSON.parse(res._body).validResponse;
            if (validResponse == 0) {
              this.notify.error("Invalid User! Use correct Password Link from Your Inbox.");
              this.validityStatus = false;
              return this.validityStatus;
            }
            else if (validResponse == 1) {
              let encodeLink=JSON.parse(res._body).encodedIdentifire;
              this.sharedDataService.encodedLink$.next(encodeLink);
              console.log("Valid User");
              this.validityStatus = true;
              return this.validityStatus;
            }
            else if (validResponse == 2) {
              this.notify.warning("Link Expired! Send password reset mail again ?", {
                buttons: [
                  {
                    text: 'Okay',
                    action: toster => {
                      this.router.navigateByUrl('/RequestPasswordReset'),
                        this.notify.remove(toster.id)
                    }
                  }
                ]
              });
              this.validityStatus = false;
              return this.validityStatus;
            }
          }
        },
        error => {
          console.log(error);
          this.validityStatus = false; 
          this.notify.error("Invalid Operation! You are being redirected to Login Screen.");
          this.router.navigateByUrl('');
          return this.validityStatus; 
        }
      );
    }
    else {
      //Redirect to Login Page
      this.router.navigateByUrl('');
    }
  }
}
