export class EditCompanyModel {
    Active: number;
    active: boolean;
    address: string;
    comments: string;
    companyID: number;
    createdBy: string;
    creationDate: string;
    email: string;
    evaluateChampion: string;
    geoLocation: string;
    name: string;
    updatedBy: string;
    updationDate: string;
    companyBanner: any;
}