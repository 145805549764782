export class LocationSchema {
    public fieldType: string;
    public displayName: string;
    public dataType: string;
    public length: string;
    public fieldName: string;
}


export class LocationSchemaMeta {
    LocationSchema: Array<LocationSchema> = new Array<LocationSchema>();

    
    public getMetaData() {
        this.LocationSchema.push({ fieldName: "Code", fieldType: "TextField", displayName: "Code", dataType: "String", length: "15" });
        this.LocationSchema.push({ fieldName: "Name", fieldType: "TextField", displayName: "Name", dataType: "String", length: "100" });
        this.LocationSchema.push({ fieldName: "CustomerID", fieldType: "Dropdown", displayName: "Customer", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "AddressLine1", fieldType: "TextField", displayName: "Address Line 1", dataType: "String", length: "150" });
        this.LocationSchema.push({ fieldName: "AddressLine2", fieldType: "TextField", displayName: "Address Line 2", dataType: "String", length: "50" });
        this.LocationSchema.push({ fieldName: "GeoLocation", fieldType: "TextField", displayName: "GeoLocation", dataType: "String", length: "70" });
        this.LocationSchema.push({ fieldName: "Suburb", fieldType: "TextField", displayName: "Suburb", dataType: "String", length: "100" });
        this.LocationSchema.push({ fieldName: "LocationAuditStatusCd", fieldType: "Dropdown", displayName: "Audit Status", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "StateCd", fieldType: "Dropdown", displayName: "State", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "Postcode", fieldType: "TextField", displayName: "Postcode", dataType: "String", length: "11" });
        this.LocationSchema.push({ fieldName: "CountryCd", fieldType: "Dropdown", displayName: "Country", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "AccountManagerID", fieldType: "Dropdown", displayName: "Account Manager", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "Comments", fieldType: "TextField", displayName: "Comments", dataType: "String", length: "500" });
        this.LocationSchema.push({ fieldName: "IsDepartment", fieldType: "TextField", displayName: "Is Department", dataType: "bit", length: "4" });
        this.LocationSchema.push({ fieldName: "DebtorCode", fieldType: "TextField", displayName: "Debtor Code", dataType: "String", length: "15" });
        // this.LocationSchema.push({ fieldName: "LocationID", fieldType: "TextField", displayName: "Location ID", dataType: "Integer", length: "500" });
        return this.LocationSchema;
    }

    public getMetaDataJp() {
        this.LocationSchema.push({ fieldName: "Code", fieldType: "TextField", displayName: "コード", dataType: "String", length: "15" });
        this.LocationSchema.push({ fieldName: "Name", fieldType: "TextField", displayName: "物件名称", dataType: "String", length: "100" });
        this.LocationSchema.push({ fieldName: "CustomerID", fieldType: "Dropdown", displayName: "顧客", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "AddressLine1", fieldType: "TextField", displayName: "住所1", dataType: "String", length: "150" });
        this.LocationSchema.push({ fieldName: "AddressLine2", fieldType: "TextField", displayName: "住所2", dataType: "String", length: "50" });
        this.LocationSchema.push({ fieldName: "GeoLocation", fieldType: "TextField", displayName: "GPS", dataType: "String", length: "70" });
        this.LocationSchema.push({ fieldName: "Suburb", fieldType: "TextField", displayName: "市区町村", dataType: "String", length: "100" });
        this.LocationSchema.push({ fieldName: "LocationAuditStatusCd", fieldType: "Dropdown", displayName: "物件試運転状態", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "StateCd", fieldType: "Dropdown", displayName: "都道府県名", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "Postcode", fieldType: "TextField", displayName: "郵便番号", dataType: "String", length: "11" });
        this.LocationSchema.push({ fieldName: "CountryCd", fieldType: "Dropdown", displayName: "国名", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "AccountManagerID", fieldType: "Dropdown", displayName: "アカウントマネージャー", dataType: "Integer", length: "" });
        this.LocationSchema.push({ fieldName: "Comments", fieldType: "TextField", displayName: "コメント", dataType: "String", length: "500" });
        this.LocationSchema.push({ fieldName: "IsDepartment", fieldType: "TextField", displayName: "部門です", dataType: "bit", length: "4" });
        this.LocationSchema.push({ fieldName: "DebtorCode", fieldType: "TextField", displayName: "債務者コード", dataType: "String", length: "15" });
        // this.LocationSchema.push({ fieldName: "LocationID", fieldType: "TextField", displayName: "Location ID", dataType: "Integer", length: "500" });
        return this.LocationSchema;
    }
}