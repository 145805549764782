import { Component, OnInit, ViewChild } from "@angular/core";
import { CoreDataService } from "../../Service/core-data.service";
import { ToastrService } from "ngx-toastr";
import { SnotifyService } from "ng-snotify";
import { Router } from "@angular/router";
import { NgForm, FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedDataService } from "../../Service/CommonUtility.service";
import { CommonFunctionService } from "../../Service/common-function.service";
import { ActivatedRoute } from "@angular/router";
import { AssetGroup } from "../../Common/shared-model";
import { EditAssetGroupAdminListingEntity } from "./edit-asset-group.model";

import { Title } from "@angular/platform-browser";
import { AdminEntity } from "../../Common/shared-model";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
@Component({
  selector: "app-create-asset-group",
  templateUrl: "./edit-asset-group.component.html",
  styleUrls: ["./edit-asset-group.component.scss"],
})
export class EditAssetGroupComponent implements OnInit {
  public defaultItem: { className: string; assetClassID: number } = {
    className: "Select item...",
    assetClassID: null,
  };
  assetGroupTitle: string = "Create Asset Group";
  @ViewChild("formData",{static:false}) formGroup: NgForm;
  event: string;
  formgroupAsset: FormGroup;
  isEditedField: boolean = false;
  LanguageConfig: any;
  LanguageInitial: any;
  ErrorMsgData: any;
  dropdownIndex: number;
  UserData: any;
  editObject: any = {
    assetGroupID: 0,
  };
  changeValue = [];
  editFieldTemplate = [];
  temp = [];
  indexValue: number;
  public fieldValues = [];
  list = {};
  editVariable = [];
  listEditData: AssetGroup = new AssetGroup();
  AdminEntity: AdminEntity = new AdminEntity();

  public fieldItems: Array<any> = [];

  editchange = [];
  fieldTemplate = [];
  fieldName = [];
  temparray = [];
  public gridStatus = [];
  public gridView = [];
  EditAssetGroupAdminListingEntity: EditAssetGroupAdminListingEntity = new EditAssetGroupAdminListingEntity();
  IsAdmin: string;
  GropuID: number = 0;
  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private notify: SnotifyService,
    private sharedService: SharedDataService,
    private router: Router,
    private shareDataService: SharedDataService,
    private routeSnapshot: ActivatedRoute,
    private commonfmunction: CommonFunctionService,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService,
    private coredataservice: CoreDataService
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
    this.classList();
    this.loadData();
    // this.editObject.assetGroupID = this.routeSnapshot.snapshot.paramMap.get('assetGroupID');
    this.editObject.assetGroupID = this.routeSnapshot.snapshot.paramMap.get(
      "id"
    );

    this.LanguageInitial = this.sharedDataService.GetLanguageLiteral();

    if (this.LanguageInitial == "en") {
      this.fieldItems = [
        {
          displayName: "Mandatory",
          displayID: "1",
        },
        {
          displayName: "Optional",
          displayID: "0",
        },
        {
          displayName: "Not Applicable",
          displayID: "-1",
        },
      ];
    } else {
      this.fieldItems = [
        {
          displayName: "必須",
          displayID: "1",
        },
        {
          displayName: "オプション",
          displayID: "0",
        },
        {
          displayName: "適用できません",
          displayID: "-1",
        },
      ];
    }
  }
  ngOnInit() {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[15];
          this.ErrorMsgData = res[28];
        }
      });
    }

    this.GetAssetFieldsLabel();

    this.formgroupAsset = new FormGroup({
      groupName: new FormControl("", [
        Validators.required,
        //  Validators.minLength(8),
        Validators.maxLength(100),
      ]),
      assetClassID: new FormControl("", [
        Validators.required,
        // Validators.minLength(8),
      ]),
      description: new FormControl("", [Validators.maxLength(100)]),

      //  active
      // AssetTypeMaster: new FormArray([new FormControl('')])
      complianceManagement: new FormControl(""),
    });
   

    //  active
    // AssetTypeMaster: new FormArray([new FormControl('')])


    if (
      this.editObject.assetGroupID != null &&
      this.editObject.assetGroupID != undefined
    ) {
      this.AdminEntity.Table = "AssetGroup";
      this.AdminEntity.AssociatedID = this.editObject.assetGroupID;
    }

    this.IsAdmin = this.sharedDataService.IsAdmin();
    // this.assetGroupTitle = "Update Asset Group";
    //  this.loadEditData(this.editObject.assetGroupID);
  }

  GroupNameBlur(event) {
    if (event.target.value != undefined && event.target.value != "") {
      this.coredataservice
        .ValidateAssetGroupName(
          this.editObject.assetGroupID,
          event.target.value
        )
        .subscribe((res) => {
          if (res != null && res != undefined && res.length > 0) {
            this.listEditData.groupName = null;
            this.notify.error(this.ErrorMsgData.groupalreadyExist);
          } else {
            this.listEditData.groupName = this.listEditData.groupName;
          }
        });
    }
  }
  public onTabchange(e) {
    if (e.index == 2 && this.editObject.assetGroupID != null) {
      this.AdminEntity.Table = "AssetGroup";
      this.AdminEntity.AssociatedID = this.editObject.assetGroupID;
    }
  }

  classList() {
    this.coredataservice
      .getAssetClassList()
      .first()
      .subscribe(
        (response) => {
          for (let key in response) {
            this.editVariable.push({
              assetClassID: response[key]["assetClassID"],
              className: response[key]["className"],
            });
          }
        },
        (catchError) => {
          if (catchError) {
            this.toastrService.error(
              "error" + catchError.status + " " + catchError["message"]
            );
          }
        }
      );
  }
  valueChange(changeValue: any, dropdownValue) {
    this.isEditedField = true;
    let object = {
      assetFieldID: dropdownValue.assetFieldID,
      defaultBehaviour: dropdownValue.defaultBehaviour,
    };
    let index = this.temp.findIndex(
      (element) => element.assetFieldID === dropdownValue["assetFieldID"]
    );

    if (changeValue.displayName === "Mandatory") {
      object.defaultBehaviour = changeValue.displayID;
    } else if (changeValue.displayName === "Optional") {
      object.defaultBehaviour = changeValue.displayID;
    } else {
      object.defaultBehaviour = changeValue.displayID;
    }
    if (object.defaultBehaviour === "0") {
    }

    object["updated"] = true;

    this.temp.splice(index, 1, object);
    this.changeValue = this.temp;

    this.gridView.map((element, index) => {
      element.Field.map((value) => {
        if (value.assetFieldID === dropdownValue.assetFieldID) {
          value.option = changeValue;
        }
      });
    });
    this.gridStatus = this.gridView;
    console.log("Grid Status",this.gridStatus);
  }

  loadEditData(editDataId: string) {
    // this.assetGroupTitle = "Update Asset Group";
   if(editDataId!=null){
    this.coredataservice
      .getAssetByID(editDataId)
      .first()
      .subscribe(
        (response) => {
          this.listEditData = response;
          let filterFieldTemplate = [];
          JSON.parse(this.listEditData["formTemplate"]).map((element) => {
            let temp = this.editchange.find(
              (defaultElement) =>
                defaultElement.assetFieldID === element.assetFieldID
            );
            if (!!temp) {
              filterFieldTemplate.push(element);
            }
          });
          let stringifiedTemplate = JSON.stringify(filterFieldTemplate);
          //here edited data will be loaded with their corresponding formtemlate
          this.temp = JSON.parse(stringifiedTemplate);
          this.editFieldTemplate = JSON.parse(stringifiedTemplate);
          this.gridView.map((element, index) => {
            element.Field.map((value, index) => {
              if (
                !!this.editFieldTemplate.find(
                  (element) => element.assetFieldID === value.assetFieldID
                ) === false
              ) {
                if (value.defaultBehaviour === "1") {
                  let fieldTemplate = {
                    assetFieldID: value.assetFieldID,
                    defaultBehaviour: value.defaultBehaviour,
                  };
                  value["defaultBehaviour"] = value.option.displayID;
                  value["option"] = value.option;
                  this.temp.push(fieldTemplate);
                } else {
                  value["defaultBehaviour"] = this.fieldItems[2].displayID;
                  value["option"] = this.fieldItems[2];
                  let fieldTemplate = {
                    assetFieldID: value.assetFieldID,
                    defaultBehaviour: value.defaultBehaviour,
                  };
                  this.temp.push(fieldTemplate);
                }
              } else {
                this.editFieldTemplate.map((fieldValues) => {
                  if (value.assetFieldID === fieldValues["assetFieldID"]) {
                    if (
                      value.defaultBehaviour != fieldValues.defaultBehaviour
                    ) {
                      value["status"] = "user";
                    }
                    if (fieldValues.defaultBehaviour === "1") {
                      value["defaultBehaviour"] = fieldValues.defaultBehaviour;
                      value["option"] = this.fieldItems[0];
                    } else if (fieldValues.defaultBehaviour === "0") {
                      value["defaultBehaviour"] = fieldValues.defaultBehaviour;
                      value["option"] = this.fieldItems[1];
                    } else {
                      value["defaultBehaviour"] = this.fieldItems[2].displayID;
                      value["option"] = this.fieldItems[2];
                    }
                  }
                });
              }
            });
          });
          // this.gridView.filter((ele) => {
          //   if (ele.FieldName != "Custom") {
          //     this.gridStatus.push(ele);
          //   }
          // });

          this.gridView.forEach((ele) => {
            if (!this.gridStatus.includes(ele) && ele.FieldName != "Custom") {
              this.gridStatus.push(ele);
            }
          });
          console.log("Grid Status",this.gridStatus);
        },
        (catchError) => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);

            // this.toastrService.error("error")
          }
        }
      );
   }
  }
  // dropdownelement(value: number) {
  //   this.editVariable.map((element, index) => {
  //     if (element.assetClassID === value) {
  //       this.dropdownIndex = index;
  //     }
  //   });
  // }

  loadData() {
    this.coredataservice
      .getAssetFields()
      .first()
      .subscribe(
        (response) => {
          this.list = response;
          for (let key in this.list) {
            if (this.list[key].web) {
              this.temparray.push(this.list[key]);
            }
            // this.temparray.push(this.list[key])
          }
          this.temparray.map((element) => {
            this.fieldName.push(element.groupName);
          });
          var filter = this.fieldName;
          var uinqueFieldName = filter.filter(function (item, pos) {
            return filter.indexOf(item) == pos;
          });
          this.loadEditData(this.editObject.assetGroupID);
          for (let key in uinqueFieldName) {
            this.gridView.push({
              FieldName: uinqueFieldName[key],
              Field: [],
            });
            this.temparray.map((element) => {
              if (element.groupName === uinqueFieldName[key]) {
                let option = this.commonfmunction.iterateArray(
                  element,
                  element.defaultBehaviour
                );
                this.gridView[key].Field.push({
                  displayName: element.displayName,
                  defaultBehaviour: element.defaultBehaviour,
                  Field: element.fieldName,
                  assetFieldID: element.assetFieldID,
                  option: option,
                  status: "system",
                });
                this.fieldTemplate.push({
                  assetFieldID: element.assetFieldID,
                  defaultBehaviour: element.defaultBehaviour,
                });
              }
            });
            this.editchange = this.fieldTemplate;

            // this.gridView.filter((ele) => {
            //   if (ele.FieldName != "Custom") {
            //     this.gridStatus.push(ele);
            //   }
            this.gridView.forEach((ele) => {
              if (!this.gridStatus.includes(ele) && ele.FieldName != "Custom") {
                this.gridStatus.push(ele);
              }
          
            });
            console.log("Grid Status",this.gridStatus);
            this.shareDataService.griddata = this.gridView;
            if (
              this.editObject.assetGroupID != 0 &&
              this.editObject.assetGroupID != null
            ) {
            
            }
          }
         
        },
        (catchError) => {
          if (catchError) {
            this.shareDataService.ErrorHandler(catchError);
          }
        }
      );
  }

  // saveAssetGroup() {
  //   if (this.formgroupAsset.valid) {
  //     let formtemplatecopy = [];
  //     formtemplatecopy = this.temp;
  //     let formTemplate: any;
  //     formTemplate = formtemplatecopy.filter(element => element.defaultBehaviour != '-1');
  //     let temp = {
  //       AssetGroupID: this.listEditData["assetGroupID"],
  //       GroupName: this.listEditData.groupName,
  //       Description: this.listEditData.description,
  //       AssetClassID: this.listEditData.assetClassID,
  //       FormTemplate: JSON.stringify(formTemplate),
  //       Active: true
  //     };

  //     this.coredataservice.createAssets(temp).subscribe(
  //       (response) => {
  //         if (response && response != null) {
  //           let temp = response;
  //           this.shareDataService.jsoinEditedFormat = temp["FormTemplate"];

  //           if (this.editObject.assetGroupID != null) {
  //             this.notify.success(this.ErrorMsgData.assetGroupUpdated);

  //           }
  //           if (this.editObject.assetGroupID === null) {
  //             this.notify.success(this.ErrorMsgData.newAssetCreated);

  //           }
  //           this.router.navigate(["/asset-group"]);
  //         }
  //       }, catchError => {
  //         if (catchError) {
  //           this.shareDataService.ErrorHandler(catchError);

  //         }
  //       });
  //   }
  //   else {
  //     Object.keys(this.formgroupAsset.controls).forEach(field => { // {1}
  //       const control = this.formgroupAsset.get(field); // {2}

  //       control.markAsTouched({ onlySelf: true });
  //       control.markAsDirty({ onlySelf: true });       // {3}
  //     });
  //     this.notify.error(this.ErrorMsgData.mandatoryField);
  //   }
  // }

  saveAssetGroup() {
    if (this.formgroupAsset.valid) {
      if (
        this.editObject.assetGroupID != 0 &&
        this.editObject.assetGroupID != undefined
      ) {
        let formtemplatecopy = [];
        formtemplatecopy = this.temp;
        let formTemplate: any;
        formTemplate = formtemplatecopy.filter(
          (element) => element.defaultBehaviour != "-1"
        );
        let temp = {
          AssetGroupID: this.listEditData["assetGroupID"],
          GroupName: this.listEditData.groupName,
          Description: this.listEditData.description,
          AssetClassID: this.listEditData.assetClassID,
          ComplianceManagement: this.listEditData.complianceManagement,
          FormTemplate: JSON.stringify(formTemplate),
          Active: true,
        };

        this.coredataservice.createAssets(temp).subscribe(
          (response) => {
            if (response && response != null) {
              let temp = response;
              this.shareDataService.jsoinEditedFormat = temp["FormTemplate"];
              this.notify.success(this.ErrorMsgData.assetGroupUpdated);

              // this.toastrService.success("New Asset Has been Created ");
              this.router.navigate(["/asset-group"]);
            }
          },
          (catchError) => {
            if (catchError) {
              // this.toastrService.error("error " + catchError.status + " " + catchError.statusText)
              this.sharedService.ErrorHandler(catchError);
            }
          }
        );
      } else {
        let newFormTemplate = this.editchange.filter(
          (element) => element.defaultBehaviour != "-1"
        );
        let temp = {
          AssetGroupID: null,
          GroupName: this.listEditData.groupName,
          Description: this.listEditData.description,
          AssetClassID: this.listEditData.assetClassID,
          complianceManagement:this.listEditData.complianceManagement,
          FormTemplate: JSON.stringify(newFormTemplate),
          Active: true,
        };

        this.coredataservice.createAssets(temp).subscribe(
          (response) => {
            if (response && response != null) {
              let temp = response;
              this.shareDataService.jsoinEditedFormat = temp["FormTemplate"];
              this.notify.success(this.ErrorMsgData.newAssetCreated);

              // this.toastrService.success("New Asset Has been Created ");
              this.router.navigate(["/asset-group"]);
            }
          },
          (catchError) => {
            if (catchError) {
              // this.toastrService.error("error " + catchError.status + " " + catchError.statusText)
              this.sharedService.ErrorHandler(catchError);
            }
          }
        );
      }
    } else {
      Object.keys(this.formgroupAsset.controls).forEach((field) => {
        // {1}
        const control = this.formgroupAsset.get(field); // {2}
        // let kd = document.getElementsByName(field)[0]
        // if(kd != undefined){
        // }
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true }); // {3}
      });
      this.notify.error(this.ErrorMsgData.mandatoryField);
    }
  }

  assetFieldGroup: any;
  GroupFieldNameChange(GroupName: string) {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined && GroupName != "") {
          this.assetFieldGroup = res[41];
        }
      });
    }

    if (GroupName != "" && GroupName != null) {
      let Name = GroupName.replace(/\s+/g, "");
      var Groupname = this.assetFieldGroup.filter((data) => {
        return Name.toString() === data.GroupName.toString();
      });
      return Groupname[0].DisplayName;
    }
  }
  AssetDataLabel: any = [];

  GetAssetFieldsLabel() {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.AssetDataLabel = res[30];
        }
      });
    }
  }
  FieldNameChange(FieldName: string) {
    console.log("AssetDataModel",this.AssetDataLabel)
    var FieldLabel = this.AssetDataLabel.filter((data) => {
      return (
        FieldName.toLowerCase().toString() ===
        data.FieldName.toLowerCase().toString()
      );
    });
    return FieldLabel[0].DisplayName;
  }
}
