///<reference types="@types/googlemaps" />
import { SnotifyService } from 'ng-snotify';

import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService } from '../../Service/CommonUtility.service'
import { Router, ActivatedRoute } from '@angular/router';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { RMEntity } from "./LocationRMList-management.model";

import { Title } from '@angular/platform-browser';

import { FormBuilder, FormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
@Component({
  selector: 'app-RM-management',
  templateUrl: 'LocationRMList-management.component.html',
  styleUrls: ['LocationRMList-management.component.scss']
})
export class LocationRMListComponent implements OnInit {
  RMList: Array<RMEntity> = [];
  public pageSize = 10;
  public skip = 0;
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "customer", dir: "desc" }],
  };
  public filter: CompositeFilterDescriptor;
  private gridData: GridDataResult;
  DateTimeformat: string;
  Dateformat: any;
  GridCurrencyChange: any;
  
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.RMList, sort), this.state);
    this.allData = this.allData.bind(this);
  }
  locationListingTitle: any;
  LanguageConfig: any;
  BusinessLanguageConfig:any;
  GridConfig: any
  IsDepartmentManagement : boolean
  IsLocationManagement : boolean
  constructor(private titleService: Title, private routes: ActivatedRoute,
    private route: Router,
    private activatedRoute: ActivatedRoute, private notify: SnotifyService, private DataService: CoreDataService, private router: Router,  private sharedDataService: SharedDataService,private formBuilder:FormBuilder) 
    {
      let pageTite = this.activatedRoute.snapshot.data['title'];
      this.titleService.setTitle(pageTite);   
  }
  getRMList(): any {
    this.DataService.GetRMList().subscribe((res) => {
      if (res != undefined && res != null) {
        this.RMList = [] 
        this.RMList=res;
        this.gridData = process(this.RMList, this.state);
        this.allData = this.allData.bind(this);
      }
    })
  }

  ngOnInit() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[65];
         
          this.GridConfig = res[2]
        }
      })
      var token = window.localStorage.getItem('token');  
    this.getRMList();
  }
  this.sharedDataService.GetOrgSettings().then((res) => {
    if (res != null) {
      this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
      this.Dateformat = res['DateFormat'];
      this.GridCurrencyChange = res['Currency'];
      console.log(this.GridCurrencyChange)
    }
  })
  }

public Open(Customer,LocationID){
  this.sharedDataService.RMCustomer=Customer;
  this.sharedDataService.RMLocationID=LocationID
  this.route.navigate(["/assets-RM-Mangement"]);
}
  MenuOnSelect(event) {  
  }

  onTabchange(event) {
    if (event.index == 1) { 
    }
  }
  dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.RMList, this.state);
    this.allData = this.allData.bind(this);
  }
 
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  
  }
 public  dblClickEvent(e){
  let rowIndex;
    rowIndex = e.target.parentElement.rowIndex;
    let rowData = this.gridData.data[e.target.parentElement.rowIndex];
    console.log('rowData', rowData);
    this.sharedDataService.RMCustomer=rowData.customer;
  this.sharedDataService.RMLocationID=rowData.locationID
 
 
      this.router.navigate(["/assets-RM-Mangement/",{locationID : rowData.locationID}]);
 }
 
 public allData(): ExcelExportData {
  const result: ExcelExportData = {
    data: process(this.RMList, {
    
      sort: [{ field: "active", dir: "desc" }],
    }).data,
    
  };

  return result;
}

}
