import { Component, ElementRef, NgZone, OnInit, ViewChild, Output, EventEmitter, DoCheck, AfterContentChecked, OnDestroy } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { SnotifyService } from 'ng-snotify';
import { FormGroup, Validators, NgForm, FormControl } from "@angular/forms";
import { Title } from '@angular/platform-browser';

import { SharedDataService } from "../../Service/CommonUtility.service";
import { CoreDataService } from "../../Service/core-data.service";
import { ToastrService } from "ngx-toastr";
declare var google: any;
import { GridComponent } from '@progress/kendo-angular-grid';
import { CommonFunctionService } from "../../Service/common-function.service";
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerDetails } from "./add-customer.model";
import { CompanyList, BusinessManagerModel, CustomerAdminListingEntity } from "../add-customer/add-customer.model";
import { Observable } from 'rxjs/Observable';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Subscription } from "rxjs/Subscription";
import { AdminEntity } from '../../Common/shared-model';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { AbmComponent } from 'angular-baidu-maps';


declare const BMap: any;

interface ColumnSetting {
  width: number;
  field: string;
  title: string;
  format?: string;
  groupID: number;
}
@Component({
  selector: 'app-add-customer ',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit, OnDestroy {


  options: any
  markerPosition: any
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png']
  };
  LanguageConfig: any;
  GridConfig: any
  data: string;
  customerTitle: string;
  SelectedtabIndex: number;
  CustomerAdminListingEntity: CustomerAdminListingEntity = new CustomerAdminListingEntity();
  loading: Subscription;
  IsAdmin: string;
  formTemplate: Array<any> = [];
  defaultSysatemMandatoryFields = [];
  formTemplateDefaultAssetFieldConfig: Array<any> = [];
  saveFieldGroup = [];
  GroupUpdated: boolean = false;
  fields = {
    updatedValue: ''
  }
  fieldName = [];
  ActualGridData = [];
  isStatusConfigEdited: boolean;
  gridDefaultDefaultData: any = [];
  isRowEdited: boolean;
  totalColumLength: number = 0;

  IsConfigAssetField: boolean = false;
  showAssetField: boolean;
  ColumnisSelectAll: boolean = false;
  defaultBehavior: string;
  show: boolean = false;
  loadGridStatusData = [];
  public gridStatusConfiguration = [];
  selectedAssetField: any = {};
  editCompany = [];
  UOM = [];
  standardList = [];
  list = {};
  listDefault = {};
  public columns: ColumnSetting[] = [];
  public columnsDefaultGrid: ColumnSetting[] = [];
  customerid: any;
  countryList = [];
  stateList = [];
  submitted: boolean = false;
  isMarkupvalid: boolean = false;
  mntncList = [];
  public gridstatus = [];
  public checked: boolean;
  public gridView: any[];
  FieldConfigurationList = {};
  FieldConfigurationListDefault = {};
  gridAssetFieldConfiguration = [];
  formGroup: FormGroup;
  AdminEntity: AdminEntity = new AdminEntity();
  private editedRowIndex: number;
  public dataCategory: Array<{ categoryName: string, categoryId: number }> = [
    { categoryName: "Mandatory", categoryId: 1 },
    { categoryName: "Optional", categoryId: 2 },
    { categoryName: "Not Applicable", categoryId: 3 }
  ];
  public gridData: any[];
  public gridresult: any[];
  public gridDataDefault: any[];
  listgroup = [];

  public view: Observable<GridDataResult>;
  filterOptions = [{ key: "ConditionRatingCd", displayName: "Condition Rating" }, { key: "CriticalityCd", displayName: "Criticality Code" }, { key: "AssetStatusCd", displayName: "Asset Status Code" }];
  selected: CustomerDetails = new CustomerDetails();
  public defaultItemCompany: { name: string, companyID: number } = { name: "Select ", companyID: null };
  public defaultItemBusiness: { name: string, userID: number } = { name: "Select ", userID: null };
  public defaultItemStandard: { codeValue: string, codeID: number } = { codeValue: "Select", codeID: null };
  companyList: CompanyList[] = [];
  businessdevlopmentList: BusinessManagerModel[] = [];
  latitude = -25.274399;
  longitude = 133.775131;
  zoom = 4;
  temparray = [];
  addressStatus: boolean;
  defaultSaveField: Array<any> = [];
  IsConfigEdit = false;
  locationChoosen = true;
  IsUserCustomer: string;
  IsAuditorReviewer: boolean;

  IsAddBusiness: boolean;
  IsAddCustomer: boolean;
  IsUpdateBusiness: boolean;
  IsUpdateCustomer: boolean;

  assetfieldCofig: any;
  ErrMessagesData: any;
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  @ViewChild(GridComponent, { static: false })
  public grid: GridComponent;
  @ViewChild('customerStatus', { static: false }) usrForm: NgForm;


  
  private _map: any;
  MapOptions = [{ key: "Google", displayName: "Google" }, { key: "Baidu", displayName: "Baidu" }];
 
  SelectedMap:string ='Google'



  @Output() change = new EventEmitter<boolean>();

  public suggestions: any;
  map: any;
  status: string;
  BaiduAutocomplete: any;
  GoogleAutocomplete:any;


  constructor(
    private notify: SnotifyService,
    private commonfmunction: CommonFunctionService,
    private routes: ActivatedRoute,
    private route: Router,
    private sharedService: SharedDataService,
    private toastrService: ToastrService,
    private coredataservice: CoreDataService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private activatedRoute: ActivatedRoute, private titleService: Title
  ) {
    this.customerid = this.routes.snapshot.paramMap.get('id');
    let pageTite: string;
    if (this.customerid === null) {
      pageTite = this.activatedRoute.snapshot.data['title'];
      this.customerTitle = pageTite;

    }
    else if (this.customerid != null && this.customerid != undefined) {
      pageTite = this.activatedRoute.snapshot.data['title'];
      this.customerTitle = pageTite;
    }

    this.titleService.setTitle(pageTite);




    //BUSINESS UNIT CODE

    if (this.customerid === null) {

      if (this.activatedRoute.snapshot.data['IsAddBusiness']) {
        this.IsAddBusiness = this.activatedRoute.snapshot.data['IsAddBusiness']
      }
      else {
        this.IsAddCustomer = this.activatedRoute.snapshot.data['IsAddCustomer']
      }
    }
    else {
      if (this.activatedRoute.snapshot.data['IsUpdateBusiness']) {
        this.IsUpdateBusiness = this.activatedRoute.snapshot.data['IsUpdateBusiness']
      }
      else {
        this.IsUpdateCustomer = this.activatedRoute.snapshot.data['IsUpdateCustomer']
      }
    }

    //BUSINESS UNIT CODE

  }

  IsUpadated: boolean = false;
  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file: any) => {

      let File = file
      if (file.size < 8388608) {
        const reader = new FileReader();

        reader.onload = (ev) => {
          let image = {
            src: ev.target['result'],
            uid: file.uid,
            extension: file.extension,
            IsUpdated: true
          };
          this.selected.logo = image.src
          this.IsUpadated = image.IsUpdated

          var k = document.getElementsByClassName('k-upload-files');
          if (k.length > 0) {
            k[0].remove()
          }
        }
        reader.readAsDataURL(file.rawFile);
      }
      else {
        this.notify.error(this.ErrMessagesData.exceedFileSize)
        var k = document.getElementsByClassName('k-upload-files');
        if (k.length > 0) {
          k[0].remove()
        }
      }
    });
  }




  public onTabchange(e) {
    this.SelectedtabIndex = e.index;
    if (e.index === 2 && this.sharedService.GridSaveFieldGroup.length > 0) {
      let EditGridData: Array<any> = this.sharedService.GridSaveFieldGroup;
      for (let key in EditGridData) {
        if (!!this.columns.find(element => element.groupID === EditGridData[key].assetGroupID)) {

          let groupName = this.columns.find(element => element.groupID === EditGridData[key].assetGroupID);

          this.formTemplate.map((element) => {

            if ((element.group_name).toLowerCase() === (groupName.title).toLowerCase()) {
              let savedatais: Array<any> = JSON.parse(EditGridData[key]["formTemplate"]);
              let data = [];
              let el = this.formTemplateDefaultAssetFieldConfig.find(eleme => (eleme.group_name).toLowerCase() === (groupName["title"]).toLowerCase());
              //supose if some has chamged inside asset group details section then we have to tell the modified template that t
              //something has changed
              el.form.map(temp => {
                if (!!savedatais.find(data => data.assetFieldID === temp.assetFieldID) === true) {
                  let findElement = savedatais.find(data => data.assetFieldID === temp.assetFieldID)

                  if (temp.defaultBehaviour != findElement.originalBehavior) {

                    findElement.defaultBehaviour = temp.defaultBehaviour;
                    findElement.originalBehavior = temp.defaultBehaviour;
                    data.push(findElement);
                  }
                  else {
                    if (temp.updated === true) {
                      findElement.defaultBehaviour = temp.defaultBehaviour;
                      this.saveAssetGroupDetails(groupName.groupID);
                      data.push(findElement);

                    }
                    else {

                      data.push(findElement);

                    }
                    //if nothing is changed in origibnal form tenplate of given group then directly push that element to the array

                  }

                }
                else if (!!savedatais.find(data => data.assetFieldID === temp.assetFieldID) === false) {
                  let object = { assetFieldID: temp.assetFieldID, defaultBehaviour: temp.defaultBehaviour, originalBehavior: temp.defaultBehaviour };

                  data.push(object);
                }

              });

              element.form = data;
            }
          }
          );
        }
        //here we got the group name by the group id with the help of the name we will find the formtemplate in the groupname
        //
      }
      this.gridData.map((element, index) => {  //here at each index of the data form template will be stored and search the
        //the corresponding data behavior  regarding data
        this.filterForm(element.assetFieldID, index);
      });
      this.gridData.map((element, index, grid) => {  //here we are iterating edited grid data adn
        this.formTemplateDefaultAssetFieldConfig.map((elementForm) => {

          if (!(this.gridDataDefault[index][elementForm.group_name].defaultBehavior === element[elementForm.group_name].defaultBehavior)) {
            grid[index][elementForm.group_name].status = "user";
          }
        });
      }
      );

      this.gridData.filter(ele => {
        if (ele.fieldName != "Customer Budget Price" && ele.fieldName != "Planned Replacement Date") {
          this.ActualGridData.push(ele);
        }
      })
      // this.ActualGridData = this.gridData;
    }


    if (e.index == 3 && this.customerid != null) {
      this.AdminEntity.Table = 'Customer'
      this.AdminEntity.AssociatedID = this.customerid
      // this.coredataservice.getUserAdminDetails('Customer', this.customerid).subscribe(r => {

      //   this.CustomerAdminListingEntity.createdByName = r.createdByName;
      //   this.CustomerAdminListingEntity.updatedByName = r.updatedByName;
      //   this.CustomerAdminListingEntity.creationDate = r.creationDate;
      //   this.CustomerAdminListingEntity.updationDate = r.updationDate;
      // })
    }

  }
  ngOnInit() {


    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = this.customerid != null && this.customerid != undefined ? PageData[langLiteral]['UpdateCustomer'] : PageData[langLiteral]['AddCustomer'];

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[7];
          this.assetfieldCofig = res[30];
          this.ErrMessagesData = res[28];
        }
      })
    }

    this.SelectedMap=this.sharedService.GetMap();

    this.GetAssetFieldsLabel();
    this.formGroup = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      code: new FormControl('', [
        Validators.required,
        Validators.maxLength(15)
      ]),

      addressLine1: new FormControl('', [
        Validators.required,
        Validators.maxLength(150)
      ]),
      addressLine2: new FormControl('', [
        // Validators.required
        // Validators.minLength(8),
        Validators.maxLength(50)
      ]),
      suburb: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      stateCD: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      countryCd: new FormControl('', [
        Validators.required
      ]),
      postcode: new FormControl('', [
        Validators.required,
        Validators.maxLength(11)
      ]),

      companyID: new FormControl('', [
        Validators.required
      ]),

      accountManagerID: new FormControl('', [
        Validators.required]),
      comments: new FormControl('', [
        Validators.maxLength(500)
      ]),
      markUp: new FormControl('', [
        Validators.min(0.75),
        Validators.required,
        Validators.pattern(new RegExp("^[\\d]{1,8}(\\.[\\d]{1,2})?$"))
      ]),
      //  threshold: new FormControl('', [
      //   Validators.min(0),
      //   // // Validators.required,
      //   // !(this.IsAddBusiness || this.IsUpdateBusiness)? Validators.required : Validators.required, 
      //   Validators.pattern(new RegExp("^[\\d]{1,10}(\\.[\\d]{1,2})?$"))

      // ]),
      // codeID: new FormControl('', [
      //   //Validators.required
      // ]),
      active: new FormControl('', [

        Validators.required
      ])

    });


    //NEW CONDITION FOR BUSNINESS FORM GROUP FIELDS
    if (this.IsAddBusiness || this.IsUpdateBusiness) {
      this.formGroup.addControl('threshold', new FormControl('', [
        Validators.min(0),
        Validators.pattern(new RegExp("^[\\d]{1,10}(\\.[\\d]{1,2})?$"))
      ]));
      this.formGroup.addControl('codeID', new FormControl('', [
      ]));
    }
    else {
      this.formGroup.addControl('threshold', new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.pattern(new RegExp("^[\\d]{1,10}(\\.[\\d]{1,2})?$"))
      ]));
      this.formGroup.addControl('codeID', new FormControl('', [
        Validators.required,
      ]));
    }
    //NEW CONDITION FOR BUSNINESS FORM GROUP FIELDS

    if (this.customerid != null && this.customerid != undefined) {

      // this.loadEditData(this.customerid);
      this.AdminEntity.Table = 'Customer'
      this.AdminEntity.AssociatedID = this.customerid
    }

    this.IsAuditorReviewer = this.sharedService.IsAuditorOrReviewer();
    this.IsUserCustomer = this.sharedService.IsCustomer();
    this.IsAdmin = this.sharedService.IsAdmin();
    this.getCompany();
    this.getBusinessDevlopment();
    this.loadCodeData();

    if (this.customerid != null && this.customerid != undefined) {
      this.loadDefaultData();

    }
    this.LoadGroupDetails();

    this.zoom = 4;
   if(this.SelectedMap=='Google'){
    this.mapsAPILoader.load().then(() => {
      this.GoogleAutocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
     this.GoogleAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = this.GoogleAutocomplete.getPlace();
          place.address_components.forEach((element) => {
            element.types.forEach((eleme) => {
              if (eleme === "route") {
                this.selected["route"] = element.long_name;
              }
              if (eleme === "locality") {
                this.selected["locality"] = element.long_name;
              }
              if (eleme === "administrative_area_level_1") {

                // this.selected.state = element.long_name;
              }
              if (eleme === "postal_code") {

                this.selected.postcode = element.long_name;
              }
              if (eleme === "sublocality") {
                this.selected.suburb = element.long_name;
              }
            });
          });
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
        });
      });
    });
    this.setCurrentPosition();
  }
  }
  ngOnDestroy(): void {
    this.coredataservice.defaultStatusConfigurationCodeValue = undefined
  }




  loadCodeData() {
    this.coredataservice.getCodeData().first().subscribe(response => {
      if (response != null && response != undefined) {
        this.mntncList = response;
        this.loadStatusConfiguration();
        this.loadData();
      }

    }, catchError => {
      if (catchError) {
        this.sharedService.ErrorHandler(catchError);

      }
    });
  }
  saveAssetGroupDetails(id) {
    this.coredataservice.getAssetByID(id).first().subscribe(response => {
      let form: Array<any> = JSON.parse(response.formTemplate);
      form.map(element => {
        if (element.updated === true) {
          element.updated = false;
        }
      });

      let object = {
        Active: response.active,
        AssetClassID: response.assetClassID,
        AssetGroupID: response.assetGroupID,
        Description: response.description,
        FormTemplate: JSON.stringify(form),
        GroupName: response.groupName
      }
      this.coredataservice.createAssets(object).subscribe(
        (response) => {
          if (response && response != null) {
          }
        });
    }
    );
  }
  getCompany() {
    this.coredataservice.getCompanyByName('').first()
      .subscribe(response => {
        if (response) {
          this.companyList = response;

        }
      }, catchError => {
        if (catchError) {
          this.toastrService.error("error " + catchError.status + " " + catchError.statusText)
        }
      }
      )
    return this.companyList;
  }
  public SetStatusConfig() {
    this.IsConfigEdit = false;
    this.show = false;

  }

  // tempgrid.filter(ele => {
  //   if (ele.groupName != 'Custom') {
  //     this.gridData.push(ele);
  //   }
  // })
  assetFieldConfiguration() {
    this.coredataservice.getAssetFields().first()
      .subscribe(response => {
        this.FieldConfigurationListDefault = response;
        let tempgrid = [];
        for (let temp in this.FieldConfigurationListDefault) {

          if (this.FieldConfigurationListDefault[temp].web) {
            tempgrid.push({ fieldName: this.FieldConfigurationListDefault[temp]["fieldName"], assetFieldID: this.FieldConfigurationListDefault[temp]["assetFieldID"] })

          }
        }
        this.gridData = tempgrid;
        this.gridData.map((element, index) => {  //here at each index of the data form template will be stored and search the
          //the corresponding data behavior  regarding data
          this.filterForm(element.assetFieldID, index);
        });
        this.gridData.map((element, index, grid) => {  //here we are iterating edited grid data adn
          this.formTemplateDefaultAssetFieldConfig.map((elementForm) => {

            if (!(this.gridDataDefault[index][elementForm.group_name].defaultBehavior === element[elementForm.group_name].defaultBehavior)) {
              grid[index][elementForm.group_name].status = "user";
            }
          });
        }
        );


        this.gridData.filter(ele => {
          if (ele.fieldName != "Customer Budget Price" && ele.fieldName != "Planned Replacement Date") {

            let AssetFieldsTemp: any = []


            ele.fieldName = this.assetfieldCofig.filter((data) => {
              if (data.FieldName === ele.fieldName) {
                return data.DisplayName;
              }

            })

            ele.fieldName = ele.fieldName[0].DisplayName
            // AssetFieldsTemp.push(ele);
            // this.assetfieldCofig.forEach(element => {

            //   if (element.DisplayName.trim().toLowerCase() === ele.fieldName.trim().toLowerCase()) {

            //     ele.fieldName = element.DisplayName

            //   }

            // });

            this.ActualGridData.push(ele);
            // this.ActualGridData = ele;

          }
        })
        //this.ActualGridData.push(ele);
        // var FieldLabel =
        console.log(this.columns)

        // this.ActualGridData = this.gridData;


      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });


  }

  assetFieldConfigurationDefault() {
    this.coredataservice.getAssetFields().first()
      .subscribe(response => {
        this.FieldConfigurationList = response;
        let tempgrid = [];
        for (let temp in this.FieldConfigurationList) {
          if (this.FieldConfigurationList[temp].web) {
            tempgrid.push({ fieldName: this.FieldConfigurationList[temp]["fieldName"], assetFieldID: this.FieldConfigurationList[temp]["assetFieldID"] })
          }
        }
        this.gridDataDefault = tempgrid;
        this.gridDataDefault.map((element, index) => {
          this.filterFormDefault(element.assetFieldID, index);
        });
        this.sharedService.defaultGridData = this.gridDataDefault;

      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });
  }

  loadGridStatus() {    //here grid will form and make mandatory field disabled of value is 1 other wise make it non man
    this.coredataservice.getAssetFields().subscribe(response => {
      this.list = response;
      for (let key in this.list) {

        if (this.list[key].web) {
          this.temparray.push(this.list[key])
        }
      }
      this.temparray.map((element) => {
        this.fieldName.push(element.groupName)
      });
      var filter = this.fieldName;
      var uinqueFieldName = filter.filter(function (item, pos) {
        return filter.indexOf(item) == pos;
      });

      for (let key in uinqueFieldName) {
        this.temparray.map((element) => {
          if (element.groupName === uinqueFieldName[key]) {
            let option = this.commonfmunction.iterateArray(element, element.defaultBehaviour);
            this.gridstatus.push({ 'displayName': element.displayName, "defaultBehaviour": element.defaultBehaviour, "Field": element.displayName, "assetFieldID": element.assetFieldID, "option": option, status: 'system' });
          }
        });

      }
      let data = [];

      this.gridstatus.map(element => {    //it contains no of total assetfield contains in the asset group

        if (element.defaultBehaviour === "1" && element.status === "system") { //only filter all the fields whicb are mandatory and s
          //sytem defined in the asset group default configuration
          data.push(element);
        }

      });
      this.defaultSysatemMandatoryFields = data; //here it will have only system mandatory fields which will use to compare
      //the fields with the edited grid field configuration
      if (this.customerid != null && this.customerid != undefined) {
        this.loadAssetConfiguration();

        // this.assetFieldConfiguration();



      }
      else {
        if (this.customerid === null) {
          this.saveFieldGroup = this.defaultSaveField;
        }
        this.assetFieldConfiguration();
      }
    },
      catchError => {
        if (catchError) {
          this.sharedService.ErrorHandler(catchError);
        }
      });
  }
  getApiData() {
    if(this.SelectedMap=='Google'){
    this.mapsAPILoader.load().then(() => {
      this.GoogleAutocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    this.GoogleAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = this.GoogleAutocomplete.getPlace();
          place.address_components.forEach((element) => {
            element.types.forEach((eleme) => {
              if (eleme === "route") {
              }
              if (eleme === "locality") {
              }
              if (eleme === "administrative_area_level_1") {
                //   this.selected.state = element.long_name;
              }
              if (eleme === "country") {

              }
              if (eleme === "postal_code") {
                this.selected.postcode = element.long_name;

              }
              if (eleme === "sublocality") {
                this.selected.suburb = element.long_name;

              }
            });
          });
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.selected.geoLocation = this.latitude + ',' + this.longitude;
          this.selected.addressLine1 = this.searchElementRef.nativeElement.value;
        });
      });
    });
  }
  else {

    
  }
  }
  LoadGroupDetails() {
    this.coredataservice.getAssetFieldConfigList().first()
      .subscribe(resposne => {
        this.list = resposne;
        let max = 0;
        for (let key in this.list) {
          if (this.list[key].groupName.length > max) {
            max = this.list[key].groupName.length;
          }
          let filterData: Array<any> = JSON.parse(this.list[key].formTemplate);
          filterData.map(element => {
            element["originalBehavior"] = element.defaultBehaviour
          });

          let temp = {
            form: filterData
          };
          temp["group_name"] = this.list[key].groupName;
          temp["status"] = "system";
          this.defaultSaveField.push({ assetGroupID: this.list[key].assetGroupID, formTemplate: JSON.stringify(temp["form"]) });
          this.formTemplate.push(temp);
          //here all the form template with their corresponding goup name will be stored it will help in the formation of grid dat
          //at each column
          this.columns.push({ field: "ProductName" + key, title: this.list[key].groupName, groupID: this.list[key].assetGroupID, width: 0 });
        }

        this.columns.map(columnref => {
          columnref["length"] = max;
        });
        this.loadGridStatus();
      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);

          }
        });
  }             //function to load all the columns data with their corresponding formtemplate





  filterFormDefault(AssetFieldID, gridindex) {
    this.formTemplateDefaultAssetFieldConfig.map((element, index) => {
      let object = element.form.find(elementForm => {
        return elementForm.assetFieldID === AssetFieldID;
      });
      if (!!element.form.find(elementForm => elementForm.assetFieldID === AssetFieldID) === false) {
        this.gridDataDefault[gridindex][element.group_name] = {
          "defaultBehavior": "-1",
          "status": "system"
        };
        this.gridDataDefault[gridindex]["formTemplate"] = this.formTemplate;
      }
      else {

        this.gridDataDefault[gridindex][element.group_name] = {
          "defaultBehavior": object.defaultBehaviour,
          "status": element.status
        };


        this.gridDataDefault[gridindex]["formTemplate"] = this.formTemplateDefaultAssetFieldConfig;

      }
    });

  }

  filterForm(AssetFieldID, gridindex) {
    this.formTemplate.map((element, index) => {
      let object = element.form.find(elementForm => {       //here if the assetfield id of
        return elementForm.assetFieldID === AssetFieldID; //grid index is equal to the formtemplate assetfield id
      });
      if (!!element.form.find(elementForm => elementForm.assetFieldID === AssetFieldID) === false) { //if not present then its mean that is n-a
        this.gridData[gridindex][element.group_name] = {
          "defaultBehavior": "-1",
          "status": "system"
        };
        this.gridData[gridindex]["formTemplate"] = this.formTemplate;    //storing all form template at each index
      }
      else {
        //  if (this.customerid != null && this.customerid!=undefined) {
        if (!!this.defaultSysatemMandatoryFields.find(temp => temp.assetFieldID === object.assetFieldID)) {
          let data = this.defaultSysatemMandatoryFields.find(temp => temp.assetFieldID === object.assetFieldID)
          this.gridData[gridindex][element.group_name] = {
            "defaultBehavior": data.defaultBehaviour,
            "status": "system"
          };
          this.gridData[gridindex]["formTemplate"] = this.formTemplate;

        }

        else if (!!this.defaultSysatemMandatoryFields.find(temp => temp.assetFieldID === object.assetFieldID) === false) {
          if (object.defaultBehaviour === "1") {
            this.gridData[gridindex][element.group_name] = {

              "defaultBehavior": object.defaultBehaviour,
              "status": "system"
            };
          }
          else {
            this.gridData[gridindex][element.group_name] = {
              "defaultBehavior": object.defaultBehaviour,
              "status": "user"
            };
          }
          this.gridData[gridindex]["formTemplate"] = this.formTemplate;
        }
        // }
        else {

          this.gridData[gridindex][element.group_name] = {
            "defaultBehavior": object.defaultBehaviour,
            "status": element.status
          };
          this.gridData[gridindex]["formTemplate"] = this.formTemplate;
        }



      }
    });

  }
  loadStatusConfigrationByid(id) {
    let statusLength: Array<any> = this.coredataservice.defaultStatusConfigurationCodeValue;

    var grid = [];
    this.coredataservice.getStatusConfigByCompanyId(id).first()
      .subscribe(response => {
        if (response["StatusCode"] === 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.notify.error(this.ErrMessagesData.unauthorizedRequest);
        }
        this.selected = response;
        if (this.selected != null) {
          this.show = true;
        }
        else {
          this.show = false;
        }
        if (this.selected != null && statusLength === undefined) {
          for (let key in this.sharedService.griddata) {
            grid = this.filterCodeId(this.sharedService.griddata, parseInt(key), this.selected)
          }
          this.gridStatusConfiguration = [];
          grid.filter(ele => {
            if (ele.FieldName != 'Custom') {
              this.gridStatusConfiguration.push(ele)
            }
          })

        }
        else if (statusLength) {
          for (let key in this.sharedService.griddata) {
            grid = this.filterCodeIdDefaultConfiguration(this.sharedService.griddata, parseInt(key), statusLength)
          }
          this.gridStatusConfiguration = [];
          this.gridStatusConfiguration = grid;
        };
        this.gridStatusConfiguration.map(element => {
          element.Field.map(fieldValue => {
            if (fieldValue.updatedValue === fieldValue.codeValue) {

              if (this.isStatusConfigEdited === true) {
                this.isStatusConfigEdited = true;
              }
              else {
                this.isStatusConfigEdited = false;
              }

            }
            else {
              this.isStatusConfigEdited = true;
            }
          }
          )

        }
        );
        let max = 0;
        this.gridStatusConfiguration.map(el => {
          el.Field.map(inner => {
            if (inner.width > max) {
              max = inner.width;
            }
          })
        });
        this.gridStatusConfiguration.map(el => {
          el.Field.map(inner => {
            inner.width = 30;

          })
        })

      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        }
      );

  }


  loadData() {
    this.selected.active = true;
    this.selected.markUp = 0;
    this.selected.threshold = 0;
    this.selected.customerID = null;
    this.mntncList.map((element) => {
      if (element.codeName === "MaintenanceStdCd") {
        this.standardList.push({ codeValue: element.codeValue, codeID: element.codeID })
      }
      else if (element.codeName === "CountryCd") {
        this.countryList.push({ codeValue: element.codeValue, codeID: parseInt(element.codeID) })
      }
      else if (element.codeName == 'StateCd') {
        this.stateList.push(element)

      }
      else if (element.codeName === "UOMCd") {
        this.UOM.push({ codeValue: element.codeValue, codeID: element.codeID })
      }
    });

  }
  saveStatusConfiguration(customerid) {
    if (this.saveFieldGroup) {
      let AssetFieldData = { customerAssetFields: this.saveFieldGroup, "customerID": customerid };
      this.coredataservice.AddUpdateAssetFieldConfiguration(AssetFieldData).first()
        .subscribe(
          response => {
            if (response != undefined && response != null) {
              //
              // this.route.navigate(["/customer-management"]);
            }
          }, catchError => {
            if (catchError) {
              this.sharedService.ErrorHandler(catchError);

            }
          });
    }


  }

  loadEditData(id) {
    this.coredataservice.getCustomerById(id).first()
      .subscribe(response => {
        this.selected = response;
        if (response.logo != null || response.logo != undefined) {
          this.selected.logo = 'data:image/png;base64,' + response.logo;
        }
        else {
          this.selected.logo = '';
        }

        let geolocation = this.selected.geoLocation;

        let lat = parseFloat(geolocation.split(",")[0])
        let long = parseFloat(geolocation.split(",")[1])
        this.latitude = lat;
        this.longitude = long;
        this.zoom = 5;
        this.searchElementRef.nativeElement.value = this.selected.addressLine1;
        this.onReady(this._map)
      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });
  }


  getBusinessDevlopment() {
    var payload = { Roles: ['customer'] };
    this.coredataservice.getUsersByRole(payload).first()
      .subscribe(response => {
        for (let key in response) {

          this.businessdevlopmentList.push({ userID: parseInt(response[key].userID), name: response[key].name })
        }
      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });
  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  filterObjectValue(array: Array<any>, key: String, actualValue: string) {
    var newObject = { FieldName: actualValue, Field: [] };
    array.map(element => {

      if (element.codeName === key) {
        element["updatedValue"] = element.codeValue;
        element["width"] = element.codeValue.length * 10;
        newObject.Field.push(element);
      }
    });
    return newObject;

  }
  filterCodeId(grid: Array<any>, key: number, response) {

    grid[key].Field.map((elementgrid, index) => {

      response.map((element) => {

        if (element.codeID === elementgrid.codeID) {
          elementgrid["updatedValue"] = element["customerCodeValue"];
          elementgrid["width"] = element["customerCodeValue"].length * 10;
        }
      })

    });
    return grid;
  }
  filterCodeIdDefaultConfiguration(grid: Array<any>, key: number, response) {
    grid[key].Field.map((elementgrid, index) => {

      response.map((element) => {

        if (element.codeID === elementgrid.codeID) {
          elementgrid["updatedValue"] = element["codeValue"];
          elementgrid["width"] = element["codeValue"].length * 10;

        }
      })

    });

    return grid;
  }
  loadStatusConfiguration() {

    let statusLength: Array<any> = this.coredataservice.defaultStatusConfigurationCodeValue;
    let tempStatus = [];
    this.filterOptions.map((element, index) => {
      this.mntncList.sort((a, b) => {
        return a.weightage > b.weightage ? 1 : -1
      })
      tempStatus.push(this.filterObjectValue(this.mntncList, this.filterOptions[index].key, this.filterOptions[index].displayName)
      )
    });
    if (this.customerid != null && this.customerid != undefined && statusLength === undefined) {
      this.loadGridStatusData = tempStatus;
      this.sharedService.griddata = this.loadGridStatusData;
    }
    else if (statusLength) {
      this.loadGridStatusData = tempStatus;
      this.sharedService.griddata = this.loadGridStatusData;
      for (let key in this.sharedService.griddata) {
        tempStatus = this.filterCodeIdDefaultConfiguration(this.sharedService.griddata, parseInt(key), statusLength)
      }
      this.gridAssetFieldConfiguration = [];
      this.gridStatusConfiguration = tempStatus;

    }
    else {
      this.gridStatusConfiguration = tempStatus;
    }
    let max = 0;
    this.gridStatusConfiguration.map(el => {
      el.Field.map(inner => {
        if (inner.width > max) {
          max = inner.width;
        }
      })
    });
    this.gridStatusConfiguration.map(el => {
      el.Field.map(inner => {
        inner.width = max;

      })
    })
    if (this.customerid != null && this.customerid != undefined) {

      this.loadStatusConfigrationByid(this.customerid);
      if (this.gridStatusConfiguration.length > 0) {
        this.show = true;
        this.IsConfigEdit = true;
      }
      if (this.gridAssetFieldConfiguration.length > 0) {
        this.show = true;
        this.IsConfigEdit = true;
      }
      else {
        this.show = false;
        this.IsConfigEdit = false;
      }

    }
    else {
      // this.LoadGroupDetails();
      this.show = false;
      this.IsConfigEdit = false;
    }

  }

  setDefault() {
    let codeData = [];
    this.gridStatusConfiguration.map((element, index) => {
      element.Field.map((element) => {
        codeData.push({ codeID: element.codeID, codeValue: element.updatedValue })
      })
    });
    this.coredataservice.defaultStatusConfigurationCodeValue = codeData;
  }
  saveConfiguration(customerid) {
    let codeData = [];
    this.gridStatusConfiguration.map((element, index) => {
      element.Field.map((element) => {
        codeData.push({ codeID: element.codeID, codeValue: element.updatedValue })
      })
    });
    //
    this.coredataservice.AdddUpdateCustomerStatusConfig(codeData, customerid).first()
      .subscribe(response => {

        if (response["StatusCode"] === 401) {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.notify.error(this.ErrMessagesData.unauthorizedRequest);
          // this.toastrService.error("Unauthorized Requet, Please Login or refresh to continue.");
        }
        //
      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });
  }
  cancelStatusConfig() {
    this.gridStatusConfiguration.map(element => {
      element.Field.map(fieldValue => {
        fieldValue.updatedValue = fieldValue.codeValue
      })
    });

    this.isStatusConfigEdited = false;

  }
  call() {
    this.show = !this.show;
    this.isStatusConfigEdited = !this.isStatusConfigEdited;
    this.IsConfigEdit = false;
  }
  callAssetField() {

    this.showAssetField = true;
    this.IsConfigAssetField = true;
  }

  markerDragEnd(event) {
    
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getGeoLocation(this.latitude, this.longitude);

  }
  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      let request = { latLng: latlng };

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let result = results[0];
          if (result != null) {
            this.searchElementRef.nativeElement.value = result.formatted_address;
            result.address_components.map((element) => {

              element.types.forEach((eleme) => {
                if (eleme === "administrative_area_level_1") {
                  //  this.selected.state = element.long_name;
                }

                if (eleme === "postal_code") {
                  this.selected.postcode = element.long_name;

                }
                if (eleme === "sublocality") {
                  this.selected.suburb = element.long_name;
                }
              });
            });

          }


        }
      });
    }
  }
  customerBlur(event) {
    this.CustomeNameValidate(this.selected.customerID, this.selected.companyID, event.target.value)
  }
  companyValueChange(companyID) {
    this.CustomeNameValidate(this.selected.customerID, companyID, this.selected.name)

  }
  CustomeNameValidate(CustomerID, CompanyID, SearchText) {

    if (CompanyID != undefined && SearchText != "") {
      this.coredataservice.ValidateCustomerName(CustomerID, CompanyID, SearchText).subscribe(res => {

        if (res != null && res != undefined && res.length > 0) {
          this.selected.name = null;
          this.notify.error(this.ErrMessagesData.alreadyExist);
        }

      })

    }
  }
  saveCustomerDetails() {




    this.submitted = true;
    if (this.formGroup.valid) {
      var ValidationValid = true;
      this.gridStatusConfiguration.map((element, index) => {
        element.Field.map((element) => {
          if (typeof element.updatedValue != "undefined" && element.updatedValue == "") {
            ValidationValid = false;
          }
        });
      });
      if (ValidationValid) {

        console.log(this.activatedRoute.snapshot.data['IsBusinessUnit'])

        let geolocation: string = this.latitude.toString() + "," + this.longitude.toString();
        this.selected.addressLine1 = this.searchElementRef.nativeElement.value;
        let customerPayload = {
          CustomerID: Number(this.customerid),
          CompanyID: this.selected.companyID,
          Name: this.selected.name,
          Code: this.selected.code,
          AddressLine1: this.selected.addressLine1,
          AddressLine2: this.selected.addressLine2,
          GeoLocation: geolocation,
          Suburb: this.selected.suburb,
          StateCD: this.selected.stateCD,
          Postcode: this.selected.postcode,
          CountryCd: this.selected.countryCd,
          AccountManagerID: this.selected.accountManagerID,
          MaintenanceStandard: this.selected.maintenanceStandard,
          MarkUp: this.selected.markUp,
          Comments: this.selected.comments,
          Active: this.selected.active,
          threshold: this.selected.threshold,
          Deleted: false,
          IsBusinessUnit: this.activatedRoute.snapshot.data['IsBusinessUnit'],
          Logo: this.IsUpadated == true ? this.convertDataURIToBinary(this.selected.logo) : null
        }
        this.coredataservice.AddUpdateCustomer(customerPayload).first()
          .subscribe(
            response => {
              if (this.customerid != null && this.customerid != undefined) {
                if (typeof response === "number") {
                  this.saveStatusConfiguration(this.customerid);
                  this.saveConfiguration(this.customerid);

                  if (this.activatedRoute.snapshot.data['IsUpdateBusiness'] || this.activatedRoute.snapshot.data['IsAddBusiness']) {
                    this.route.navigate(["/business-unit-management"]);
                    this.notify.success('Business unit updated sucessfully');
                  }
                  else {
                    this.route.navigate(["/customer-management"]);
                    this.notify.success(this.ErrMessagesData.customerUpdated);
                  }
                  //this.route.navigate(["/customer-management"]);
                }


                // this.toastrService.success("customer updated successfully ");
              }
              else {

                if (typeof response === "number") {

                  this.customerid = response;

                  this.saveStatusConfiguration(this.customerid);
                  this.saveConfiguration(this.customerid);

                  if (this.activatedRoute.snapshot.data['IsUpdateBusiness'] || this.activatedRoute.snapshot.data['IsAddBusiness']) {
                    this.route.navigate(["/business-unit-management"]);
                    this.notify.success('Business unit created sucessfully');
                  }
                  else {
                    this.route.navigate(["/customer-management"]);
                    this.notify.success(this.ErrMessagesData.newCustomerCreated);
                  }
                  // this.route.navigate(["/customer-management"]);
                }


              }
              this.searchElementRef.nativeElement.value = '';

            }, catchError => {
              if (catchError) {
                this.sharedService.ErrorHandler(catchError);

              }
            });
      }
      else {
        this.notify.error(this.ErrMessagesData.mandatoryField);
      }
    }

    else {
      Object.keys(this.formGroup.controls).forEach(field => { // {1}
        const control = this.formGroup.get(field); // {2}
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });
      this.notify.error(this.ErrMessagesData.mandatoryField);
    }


  }


  convertDataURIToBinary(dataURI) {

    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
  loadDefaultData() {
    this.coredataservice.getAssetFieldConfigList().first()
      .subscribe(resposne => {
        this.listDefault = resposne;     //here all the grouo details will be loaded with their corresponding formtemplate thes
        //therse fields wiill be needed at the grid formtaton of the asset field configuration list
        for (let key in this.listDefault) {
          if (this.listDefault[key].formTemplate === null) {
            this.listDefault[key].formTemplate = JSON.stringify([]);

          }
          let temp = {
            form: JSON.parse(this.listDefault[key].formTemplate)
          };
          temp["group_name"] = this.listDefault[key].groupName;
          temp["status"] = "system";
          this.formTemplateDefaultAssetFieldConfig.push(temp);
          this.columnsDefaultGrid.push({ field: "ProductName", title: this.listDefault[key].groupName, groupID: this.listDefault[key].assetGroupID, width: 0 });
        }
        this.assetFieldConfigurationDefault();

      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);

          }
        });
  }
  changeCheckbox(data, columns, assetFieldID, event, rowIndex) {

    let formTemplate = data.find(element => (element.group_name).toLowerCase() === columns.toLowerCase());
    let colum = this.columns.find(element => (element.title).toLowerCase() === columns.toLowerCase());
    if (event.srcElement.checked === true) {
      if (!!this.saveFieldGroup.find(element => element.assetGroupID === colum.groupID) === true) {
        let index = this.saveFieldGroup.findIndex(element => element.assetGroupID === colum.groupID);
        formTemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldID) {
            this.defaultBehavior = element.defaultBehaviour;
            element["defaultBehaviour"] = '1';
          }
        })
        this.saveFieldGroup[index].formTemplate = JSON.stringify(formTemplate["form"]);
      }
      else {

        formTemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldID) {

            element["defaultBehaviour"] = '1';
          }
        })
        this.saveFieldGroup.push({ assetGroupID: colum.groupID, formTemplate: JSON.stringify(formTemplate["form"]) });

      }
    }

    else if (event.srcElement.checked === false) {
      if (!!this.saveFieldGroup.find(element => element.assetGroupID === colum.groupID) === true) {
        let index = this.saveFieldGroup.findIndex(element => element.assetGroupID === colum.groupID);
        formTemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldID) {
            this.defaultBehavior = element.defaultBehaviour;
            element["defaultBehaviour"] = '0';
          }
        })
        this.saveFieldGroup[index].formTemplate = JSON.stringify(formTemplate["form"]);
      }
      else {

        formTemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldID) {

            element["defaultBehaviour"] = '0';
          }
        })
        this.saveFieldGroup.push({ assetGroupID: colum.groupID, formTemplate: JSON.stringify(formTemplate["form"]) });

      }
    }
    this.sharedService.GridSaveFieldGroup = this.saveFieldGroup;
  }
  groupNameSelectAllChange(event, data, assetFieldId) {

    if (typeof data === 'number') {
      if (event.srcElement.checked) {
        this.columns.map(element => {
          if (this.gridData[data][element.title].defaultBehavior === "0") {
            let groupTemplate = this.formTemplate.find(groupTemplate => (groupTemplate.group_name).toLowerCase() === (element.title).toLowerCase());

            this.saveSelectAllData(groupTemplate, assetFieldId, event);
            this.gridData[data][element.title].defaultBehavior = "1";
            this.gridData[data][element.title].status = 'user';
          }
        });
      }
      else {
        this.columns.map(element => {
          if (this.gridData[data][element.title].defaultBehavior === "1" && this.gridData[data][element.title].status === "user") {
            this.gridData[data][element.title].defaultBehavior = "0";
            this.gridData[data][element.title].status = 'user';
            let groupTemplate = this.formTemplate.find(groupTemplate => (groupTemplate.group_name).toLowerCase() === (element.title).toLowerCase());
            this.saveSelectAllData(groupTemplate, assetFieldId, event);

          }
        });
      }
    }
    else {
      if (event.srcElement.checked === true) {
        this.gridData.map(element => {
          if (element[data].defaultBehavior === "0") {
            element[data].defaultBehavior = "1";
            element[data].status = "user";
          }

          this.changeCheckbox(this.formTemplate, data, element.assetFieldID, event, 0);
        });
      }
      else {
        this.gridData.map(element => {
          if (element[data].defaultBehavior === "1" && element[data].status === "user") {
            element[data].defaultBehavior = "0";
            element[data].status = "system";
            this.changeCheckbox(this.formTemplate, data, element.assetFieldID, event, 0);
          }

        });

      }
    }

  }


  saveSelectAllData(GroupTemplate, assetFieldId, event) {

    let group = this.columns.find(element => (element.title).toLowerCase() === (GroupTemplate.group_name).toLowerCase());
    let formtemplate = GroupTemplate;
    if (event.srcElement.checked === true) {

      if (!!this.saveFieldGroup.find(element => element.assetGroupID === group.groupID) === true) {
        let index = this.saveFieldGroup.findIndex(element => element.assetGroupID === group.groupID);
        formtemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldId) {
            this.defaultBehavior = element.defaultBehaviour;
            element.defaultBehaviour = "1";
          }
        })
        this.saveFieldGroup[index].formTemplate = JSON.stringify(formtemplate["form"]);
      }
      else {

        formtemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldId) {
            this.defaultBehavior = element.defaultBehaviour;
            element.defaultBehaviour = "1";
          }
        })
        this.saveFieldGroup.push({ assetGroupID: group.groupID, formTemplate: JSON.stringify(formtemplate["form"]) })

      }
    }

    else if (event.srcElement.checked === false) {
      if (!!this.saveFieldGroup.find(element => element.assetGroupID === group.groupID) === true) {
        let index = this.saveFieldGroup.findIndex(element => element.assetGroupID === group.groupID);
        formtemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldId) {
            this.defaultBehavior = element.defaultBehaviour;
            element["defaultBehaviour"] = "0";

          }
        })
        this.saveFieldGroup[index].formTemplate = JSON.stringify(formtemplate["form"]);
      }
      else {

        formtemplate["form"].map((element, index) => {
          if (element["assetFieldID"] === assetFieldId) {
            this.defaultBehavior = element.defaultBehaviour;
            element["defaultBehaviour"] = "0";

          }
        })
        this.saveFieldGroup.push({ assetGroupID: group.groupID, formTemplate: JSON.stringify(formtemplate["form"]) })

      }
    }
  }

  loadAssetConfiguration() {

    this.coredataservice.getAssetFieldConfigurationByCompanyId(this.customerid)
      .subscribe(response => {
        this.selectedAssetField = response;
        if (this.selectedAssetField.length === 0) {
          this.selectedAssetField = this.defaultSaveField;
        }
        for (let key in this.selectedAssetField) {
          if (!!this.columns.find(element => element.groupID === this.selectedAssetField[key].assetGroupID)) {
            let groupName = this.columns.find(element => element.groupID === this.selectedAssetField[key].assetGroupID);

            this.formTemplate.map((element) => {

              if ((element.group_name).toLowerCase() === (groupName.title).toLowerCase()) {
                let savedatais: Array<any> = JSON.parse(this.selectedAssetField[key]["formTemplate"]);
                let data = [];
                let el = this.formTemplateDefaultAssetFieldConfig.find(eleme => (eleme.group_name).toLowerCase() === (groupName["title"]).toLowerCase());
                //supose if some has chamged inside asset group details section then we have to tell the modified template that t
                //something has changed
                el.form.map(temp => {
                  if (!!savedatais.find(data => data.assetFieldID === temp.assetFieldID) === true) {
                    let findElement = savedatais.find(data => data.assetFieldID === temp.assetFieldID)

                    if (temp.defaultBehaviour != findElement.originalBehavior) {
                      this.GroupUpdated = true;
                      findElement.defaultBehaviour = temp.defaultBehaviour;
                      findElement.originalBehavior = temp.defaultBehaviour;
                      data.push(findElement);
                    }

                    else {

                      if (temp.updated === true) {

                        findElement.defaultBehaviour = temp.defaultBehaviour;
                        this.saveAssetGroupDetails(groupName.groupID);
                        data.push(findElement);

                      }
                      else {
                        data.push(findElement);

                      }
                      //if nothing is changed in origibnal form tenplate of given group then directly push that element to the array

                    }

                  }
                  else if (!!savedatais.find(data => data.assetFieldID === temp.assetFieldID) === false) {
                    let object = { assetFieldID: temp.assetFieldID, defaultBehaviour: temp.defaultBehaviour, originalBehavior: temp.defaultBehaviour };

                    data.push(object);
                  }

                });
                this.saveFieldGroup.push({ assetGroupID: this.selectedAssetField[key].assetGroupID, formTemplate: JSON.stringify(data) }); //alsso we are pushing editing data to save fieldgroup so it will be use at the time of comparing new field configuration

                element.form = data;
              }
            }
            );
          }
          //here we got the group name by the group id with the help of the name we will find the formtemplate in the groupname
          //


        }

        if (this.selectedAssetField != null && this.selectedAssetField.length > 0) {
          this.showAssetField = true;
        } else {
          this.showAssetField = false;
        }

        this.assetFieldConfiguration();
        setTimeout(() => {
          this.loadEditData(this.customerid);
        }, 500);

      },
        catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });
  }


  assetFieldGroup: any;
  GroupFieldNameChange(GroupName: string) {
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined && GroupName != '') {
          this.assetFieldGroup = res[41];

        }

      })
    }

    if (GroupName != '' && GroupName != null) {
      let Name = GroupName.replace(/\s+/g, "");
      var Groupname = this.assetFieldGroup.filter((data) => { return Name.toString() === data.GroupName.toString() })
      console.log(GroupName)
      return Groupname[0].DisplayName;
    }


  }


  AssetDataLabel: any = []
  GetAssetFieldsLabel() {
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.AssetDataLabel = res[45];
        }
      })
    }
  }
  FieldNameChange(FieldName: string) {
    let Name = FieldName.replace(/\s+/g, "");
    var FieldLabel = this.AssetDataLabel.filter((data) => { return Name.toLowerCase().toString() === data.FieldName.toLowerCase().toString() })
    return FieldLabel[0].DisplayName;
  }

  onReady(map: any): void {
    if(this.SelectedMap=='Baidu')
    {
    this._map = map;
    this._map.clearOverlays();
    var point = new BMap.Point(this.longitude,this.latitude);
    this._map.centerAndZoom(point, 11);
    this._map.addControl(new BMap.MapTypeControl());
    this._map.enableScrollWheelZoom(true);

    var marker = new BMap.Marker(point);
    marker.enableDragging();
    this._map.addOverlay(marker);
    this._map.enableDragging();
   

    this._map.enableScrollWheelZoom(true);
    const navigationControl = new BMap.NavigationControl({
      anchor: 'BMAP_ANCHOR_BOTTOM_RIGHT',
      type: 'BMAP_NAVIGATION_CONTROL_ZOOM'
    });
    this._map.addControl(navigationControl);

    // First, create a Baidu Maps object with your API key

   
    var inputElementId = 'address';

    var input = document.getElementById(inputElementId);

// Add an event listener to the text box element
//input.addEventListener('focus', (e)=> {
 
    this.BaiduAutocomplete = new BMap.Autocomplete({
      input: inputElementId,
      location: this._map,
    });

    this.BaiduAutocomplete.addEventListener('onconfirm', (event: any) => {
      const keyword =
        event.item.value.city +
        ' ' +
        event.item.value.district +
        ' ' +
        event.item.value.business;
      console.log(event.item.value);
      var local = new BMap.LocalSearch(this._map, {
        renderOptions: { map: this._map, autoViewport: true },
      });
      local.search(keyword); //search for India, and the callback function will be triggered with results

      local.setSearchCompleteCallback((results) => {
        console.log(results);
        var point = results.getPoi(0).point;
        this._map.setCenter(new BMap.Point(point.lat, point.lng));
        var marker = new BMap.Marker(new BMap.Point(point.lat, point.lng));
        this._map.addOverlay(marker);

        this.latitude = point.lat;
        this.longitude = point.lng;
        this.zoom = 12;
        this.selected.geoLocation = this.latitude + ',' + this.longitude;
       this.selected.addressLine1 = this.searchElementRef.nativeElement.value;
        console.log(this.latitude, this.longitude);
      });
    });
  //})

    marker.addEventListener('dragend', (e) => {
      this.latitude = e.point.lat;
      this.longitude = e.point.lng;

      console.log(this.latitude, this.longitude);
    });

    this._map.addEventListener('click', (e) => {
      this._map.clearOverlays();
      marker = new BMap.Marker(e.point);
      marker.enableDragging();
      this._map.addOverlay(marker);
      marker.addEventListener('dragend', (e) => {
        this.latitude = e.point.lat;
        this.longitude = e.point.lng;

        console.log(this.latitude, this.longitude);
      });
    });
    this.searchElementRef.nativeElement.value = this.selected.addressLine1;
  }
}

  searchaddress(event) {
    if(this.SelectedMap=='Baidu'){
    if (event.key === 'Enter') {
      var local = new BMap.LocalSearch(this._map, {
        renderOptions: { map: this._map, autoViewport: true },
      });
      local.search((event.target as HTMLInputElement).value);
      local.setSearchCompleteCallback((results) => {
        console.log(results);
        var point = results.getPoi(0).point;
        this._map.setCenter(new BMap.Point(point.lat, point.lng));
        var marker = new BMap.Marker(new BMap.Point(point.lat, point.lng));
        this._map.addOverlay(marker);

        this.latitude = point.lat;
        this.longitude = point.lng;
        this.selected.geoLocation = this.latitude + ',' + this.longitude;
          this.selected.addressLine1 = this.searchElementRef.nativeElement.value;
        console.log(this.latitude, this.longitude);
        

      });
    }
    this._map.addEventListener("tilesloaded", () => {
      console.log("Map tiles loaded");
      this.searchElementRef.nativeElement.value = this.selected.addressLine1;
    });
  }
}

onValueChangeMap(){
  if(this.SelectedMap=='Google'){
    this.BaiduAutocomplete.hide();
    this.BaiduAutocomplete.dispose();

  }
  else if(this.SelectedMap=='Baidu'){
    google.maps.event.clearInstanceListeners(this.searchElementRef.nativeElement);
    this.searchElementRef.nativeElement.value = this.selected.addressLine1;
  
  }
}
onload(){
  console.log('OnLoad')
}
}
