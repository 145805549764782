import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
  FileRestrictions,
  FileInfo,
  SelectEvent,
  UploadEvent,
} from "@progress/kendo-angular-upload";
import * as XLSX from "xlsx";
import { stringify } from "@angular/compiler/src/util";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { CoreDataService } from "../../Service/core-data.service";
import { SnotifyService } from "ng-snotify";
import {
  MappedConfigModel,
  ValidationErrorResponse,
} from "../../SystemAdmin/data-import/data-import.model";
import { LocationSchemaMeta } from "../../SystemAdmin/data-import/Location-Schema.model";

import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
// import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { SharedDataService } from "../../Service/CommonUtility.service";
import { AssetDetails, LocationDetails } from "./image-import.model";

@Component({
  selector: "app-data-import",
  templateUrl: "data-import.component.html",
  styleUrls: ["data-import.component.scss"],
})
export class DataImportComponent implements OnInit {
  // evaluate:EvaluatePortalUrl=new EvaluatePortalUrl();
  ProcessedRecords: number;
  AllowAutoMap: boolean = false;
  AllowBulkUpdate: boolean = false;
  FileRestrictions: FileRestrictions = {
    maxFileSize: 5242880,
    allowedExtensions: [".xlsx", ".xls"],
  };
  userAction: string = "Data Import";
  HasColumnMissmatch: boolean = false;
  locationSchemaMeta: LocationSchemaMeta = new LocationSchemaMeta();
  ValidationErrorResponse: Array<ValidationErrorResponse> = undefined;
  ShowProcessing: boolean = false;
  TablelistItems: Array<{ text: string; value: number }> = [
    { text: "Asset", value: 1 },
    { text: "Location", value: 2 },
  ];
  Selectedtable: number;
  DefaultTablelistItems: { text: string; value: number } = {
    text: "Select Table",
    value: 0,
  };
  uploadSaveUrl: string;
  previewColumns: Array<string> = [];
  previewColumnsGrid: Array<string> = [];
  dataExcel: any;
  previewDataExcel: any;
  LanguageConfig: any;
  LanguageConfigList: any;
  LanguageInitial: any;
  ErrorDataMsg: any;
  myFiles: any;
  AssetFields: any;
  AssetFieldsConfig: any = [];
  assetfieldCofig: any;
  isCompleted: boolean = false;
  RowsAffected: number = 0;
  arrSelectedColumn: Array<MappedConfigModel> = new Array<MappedConfigModel>();
  MappedConfig: MappedConfigModel = new MappedConfigModel();

  ErrorMessageAsset: string = null;
  ErrorMessageLocation: string = null;
  showImageImport: boolean=false;

  public AssetData:Array<AssetDetails>=[]
  public LocationData:Array<LocationDetails>=[]

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      description: "File upload",
    };
  }
  selectEventHandler(e: SelectEvent) {
    const that = this;
    this.resetData();
    e.files.forEach((file) => {
      const reader = new FileReader();
      let image: any = {};
      reader.onload = (ev) => {
        let arrayBuffer: any = reader.result;

        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        this.dataExcel = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        let headers = this.get_header_row(worksheet);
        if (this.dataExcel != undefined && this.dataExcel.length > 0) {
          this.previewColumns = headers;
          // this.previewColumns = Object.keys(this.dataExcel[0]);
          this.previewDataExcel = this.dataExcel.slice(0, 10);
          this.ProcessedRecords = this.dataExcel.length;
          this.CreateTableFromJSON();
        }
      };
      reader.readAsArrayBuffer(file.rawFile);
    });
  }
  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet["!ref"]);
    var C,
      R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell =
        sheet[
          XLSX.utils.encode_cell({ c: C, r: R })
        ]; /* find the cell in the first row */

      var hdr = "UNKNOWN " + C; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }
  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private notify: SnotifyService,
    private titleService: Title,
    private DataService: CoreDataService
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
    this.userAction = pageTite;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
  }
  TablelistValueChange(event) {
    if (event.value === 1) {
      this.getassetFields();
    } else if (event.value === 2) {
      if (this.sharedDataService.GetLanguageLiteral() == "jp") {
        // this.AssetFields = this.locationSchemaMeta.getMetaDataJp();

        let TempArr = [];
        this.locationSchemaMeta.getMetaDataJp().forEach((e) => {
          e.displayName = this.LanguageConfigList[e.fieldName];
          TempArr.push(e);
        });
        this.AssetFields = TempArr;
      } else {
        //this.AssetFields = this.locationSchemaMeta.getMetaData();

        let TempArr = [];
        this.locationSchemaMeta.getMetaData().forEach((e) => {
          e.displayName = this.LanguageConfigList[e.fieldName];
          TempArr.push(e);
        });
        this.AssetFields = TempArr;
      }
    } else {
      this.notify.error(this.ErrorDataMsg.plsSelectTable);
    }
  }
  CheckBulkUpdate(event) {
    if (event) {
      if (this.sharedDataService.GetLanguageLiteral() == "jp") {
        // this.locationSchemaMeta.LocationSchema.push({ fieldName: "LocationID", fieldType: "TextField", displayName: "物件ID", dataType: "Integer", length: "4" });
        this.AssetFields.push({
          fieldName: "LocationID",
          fieldType: "TextField",
          displayName: "物件ID",
          dataType: "Integer",
          length: "4",
        });
      } else {
        this.AssetFields.push({
          fieldName: "LocationID",
          fieldType: "TextField",
          displayName: "Location ID",
          dataType: "Integer",
          length: "4",
        });
      }
    } else {
      // this.AssetFields = this.locationSchemaMeta.LocationSchema.filter((obj) => { return obj.fieldName != 'LocationID' });
      this.AssetFields = this.AssetFields.filter((obj) => {
        return obj.fieldName != "LocationID";
      });
    }
  }

  getassetFields() {
    this.DataService.getAssetFields().subscribe((res) => {
      if (res != undefined && res != null) {
        //this.AssetFields = res.filter((obj) => { return obj.groupName != 'System' });
        this.AssetFields = res;
        console.log(res);
        this.AssetFields.filter((ele) => {
          //if (ele.fieldName != "Customer Budget Price" && ele.fieldName != "Planned Replacement Date") {
          ele.displayName = this.assetfieldCofig.filter((data) => {
            if (data.FieldName === ele.fieldName) {
              return data.DisplayName;
            }
          });
          ele.displayName = ele.displayName[0].DisplayName;
          this.AssetFieldsConfig.push(ele);
          //}
        });
      }

      this.AssetFields = this.AssetFieldsConfig;
    });
  }

  ngOnInit() {
    //  this.getassetFields();
    //Call Location Schema here
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.LanguageInitial = this.sharedDataService.GetLanguageLiteral();
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[40];
          this.LanguageConfigList = res[4];
          this.ErrorDataMsg = res[28];
          this.assetfieldCofig = res[30];
        }
      });
    }
  }
  AutoMap(previewColumns) {
    if (this.AllowAutoMap) {
      let Item = this.AssetFields.find((obj) => {
        return obj.displayName.toLowerCase() == previewColumns.toLowerCase();
      });
      if (Item != undefined) {
        //  this.SystemselectionChange(Item, previewColumns)
        let IsExist = this.arrSelectedColumn.find((obj) => {
          return obj.ExcelColumn.toLowerCase() == previewColumns.toLowerCase();
        });
        if (IsExist == undefined) {
          this.arrSelectedColumn.push({
            ExcelColumn: previewColumns,
            SystemColumn: Item.fieldName,
          });
        }
        this.SetDisableDropdownItems();
        return Item.fieldName;
      }
    }
  }

  SystemselectionChange(event, data) {
    var Obj = this.arrSelectedColumn.filter((obj) => obj.ExcelColumn === data);
    if (Obj.length > 0) {
      this.arrSelectedColumn.forEach((element) => {
        if (element.ExcelColumn == data) {
          element.SystemColumn = event.fieldName;
        }
      });
    } else {
      this.arrSelectedColumn.push({
        ExcelColumn: data,
        SystemColumn: event.fieldName,
      });
    }
    this.SetDisableDropdownItems();
    this.HasColumnMissmatch = this.checkColumnMissmatch();

    // alert(JSON.stringify(this.arrSelectedColumn))
  }
  SetDisableDropdownItems() {
    this.AssetFields.forEach((element) => {
      let ArrayItem = this.arrSelectedColumn.find(
        (obj) => obj.SystemColumn === element.fieldName
      );
      if (ArrayItem != undefined) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
    });
  }
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    if (itemArgs.dataItem.isDisabled == undefined) {
      itemArgs.dataItem.isDisabled == false;
    } else {
      itemArgs.dataItem.isDisabled == true;
    }
    return itemArgs.dataItem.isDisabled;
  }

  checkColumnMissmatch() {
    let unique = [
      new Set(this.arrSelectedColumn.map((item) => item.SystemColumn)),
    ];
    if (unique != undefined && unique[0].size < this.previewColumns.length) {
      return true;
    } else {
      return false;
    }
  }

  CheckMandatory(): boolean {
    let IsValid = true;
    let MandFields = this.getMandatoryField();
    MandFields.forEach((Outelement) => {
      let Item = this.arrSelectedColumn.find((obj) => {
        return obj.SystemColumn == Outelement;
      });
      if (Item == undefined) {
        IsValid = false;
        return IsValid;
      }
    });

    return IsValid;
  }

  getMandatoryField() {
    if (this.Selectedtable == 1) {
      if (this.AllowBulkUpdate) {
        if (this.sharedDataService.GetLanguageLiteral() == "jp") {
          this.ErrorMessageAsset =
            this.ErrorDataMsg.systemFieldLocation +
            "," +
            this.ErrorDataMsg.assetType +
            "," +
            this.ErrorDataMsg.barcode +
            "," +
            this.ErrorDataMsg.assetId +
            "," +
            this.ErrorDataMsg.systemIdMandatory;
        } else {
          this.ErrorMessageAsset =
            this.ErrorDataMsg.systemFieldLocation +
            "," +
            this.ErrorDataMsg.assetType +
            "," +
            this.ErrorDataMsg.barcode +
            "," +
            this.ErrorDataMsg.assetId +
            "," +
            this.ErrorDataMsg.systemIdMandatory;
        }

        return [
          "AssetLocationID",
          "AssetTypeID",
          "Barcode",
          "UserAssetID",
          "AssetID",
        ];
      } else {
        if (this.sharedDataService.GetLanguageLiteral() == "jp") {
          this.ErrorMessageAsset =
            this.ErrorDataMsg.systemFieldLocation +
            "," +
            this.ErrorDataMsg.assetType +
            "," +
            this.ErrorDataMsg.barcode +
            "," +
            this.ErrorDataMsg.assetId +
            "," +
            this.ErrorDataMsg.systemIdMandatory;
        } else {
          this.ErrorMessageAsset =
            this.ErrorDataMsg.systemFieldLocation +
            "," +
            this.ErrorDataMsg.assetType +
            "," +
            this.ErrorDataMsg.barcode +
            "," +
            this.ErrorDataMsg.assetId +
            "," +
            this.ErrorDataMsg.systemIdMandatory;
        }

        return ["AssetLocationID", "AssetTypeID", "Barcode", "UserAssetID"];
      }
    } else if (this.Selectedtable == 2) {
      if (this.AllowBulkUpdate) {
        if (this.sharedDataService.GetLanguageLiteral() == "jp") {
          this.ErrorMessageLocation =
            this.ErrorDataMsg.systemFieldsLocationName +
            "," +
            this.ErrorDataMsg.accountManagerId +
            "," +
            this.ErrorDataMsg.locationAuditStatus;
        } else {
          this.ErrorMessageLocation =
            this.ErrorDataMsg.systemFieldsLocationName +
            "," +
            this.ErrorDataMsg.accountManagerId +
            "," +
            this.ErrorDataMsg.locationAuditStatus;
        }

        return [
          "Name",
          "AccountManagerID",
          "LocationAuditStatusCd",
          "LocationID",
        ];
      } else {
        if (this.sharedDataService.GetLanguageLiteral() == "jp") {
          this.ErrorMessageLocation =
            this.ErrorDataMsg.systemFieldsLocationName +
            "," +
            this.ErrorDataMsg.accountManagerId +
            "," +
            this.ErrorDataMsg.locationAuditStatus;
        } else {
          this.ErrorMessageLocation =
            this.ErrorDataMsg.systemFieldsLocationName +
            "," +
            this.ErrorDataMsg.accountManagerId +
            "," +
            this.ErrorDataMsg.locationAuditStatus;
        }

        return ["Name", "AccountManagerID", "LocationAuditStatusCd"];
      }
    } else {
      return [];
    }
  }

  onNextClickValidateData() {
    this.ShowProcessing = false;
    if (this.Selectedtable == 1) {
      this.ValidateData();
    } else if (this.Selectedtable == 2) {
      this.ValidateLocationData();
    }
  }
  CreateTableFromJSON() {
    // EXTRACT VALUE FOR HTML HEADER.
    // ('Book ID', 'Book Name', 'Category' and 'Price')
    var col = [];
    for (var i = 0; i < this.previewDataExcel.length; i++) {
      for (var key in this.previewDataExcel[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }

    // CREATE DYNAMIC TABLE.
    var table = document.createElement("table");
    table.className = "table table-bordered";
    // CREATE HTML TABLE HEADER ROW USING THE EXTRACTED HEADERS ABOVE.

    var tr = table.insertRow(-1); // TABLE ROW.
    for (var i = 0; i < col.length; i++) {
      var th = document.createElement("th");
      th.innerHTML = col[i];
      th.style.whiteSpace = "nowrap";
      th.style.overflow = "hidden";
      th.style.textOverflow = "ellipsis";
      th.style.backgroundColor = "#f6f6f6";

      tr.appendChild(th);
    }

    // ADD JSON DATA TO THE TABLE AS ROWS.
    for (var i = 0; i < this.previewDataExcel.length; i++) {
      tr = table.insertRow(-1);
      for (var j = 0; j < col.length; j++) {
        var tabCell = tr.insertCell(-1);
        tabCell.style.whiteSpace = "nowrap";
        tabCell.style.overflow = "hidden";
        tabCell.style.textOverflow = "ellipsis";
        tabCell.innerHTML =
          this.previewDataExcel[i][col[j]] != undefined
            ? this.previewDataExcel[i][col[j]]
            : "";
      }
    }

    // FINALLY ADD THE NEWLY CREATED TABLE WITH JSON DATA TO A CONTAINER.
    var divContainer = document.getElementById("PreviewData");
    divContainer.innerHTML = "";
    divContainer.appendChild(table);
  }
  ValidateData() {
    this.arrSelectedColumn.filter((obj) => {
      if (obj.SystemColumn.toLowerCase() == "statuscd")
        obj.SystemColumn = "AssetStatusCd";
      else if (obj.SystemColumn.toLowerCase() == "ownershipcd")
        obj.SystemColumn = "AssetOwnershipCd";
    });

    this.ValidationErrorResponse = undefined;
    this.DataService.DataImportValidateData({
      data: this.dataExcel,
      MappedConfig: this.arrSelectedColumn,
    }).subscribe((res) => {
      if (res != undefined && res != null) {
        this.ValidationErrorResponse = new Array<ValidationErrorResponse>();
        this.ValidationErrorResponse = res.filter((obj) => {
          return obj.isValid == false;
        });
        if (this.ValidationErrorResponse.length > 0) {
          this.notify.error(this.ErrorDataMsg.incorrectData);
        }
      }
      this.ShowProcessing = true;
    });
  }
  ValidateLocationData() {
    this.ValidationErrorResponse = undefined;
    this.DataService.DataImportValidateLocationData({
      data: this.dataExcel,
      MappedConfig: this.arrSelectedColumn,
      LocationFields: this.AssetFields,
    }).subscribe((res) => {
      if (res != undefined && res != null) {
        this.ValidationErrorResponse = new Array<ValidationErrorResponse>();
        this.ValidationErrorResponse = res.filter((obj) => {
          return obj.isValid == false;
        });
        if (this.ValidationErrorResponse.length > 0) {
          this.notify.error(this.ErrorDataMsg.incorrectData);
        }
      }
      this.ShowProcessing = true;
    });
  }
  IsFinalValidCheck() {
    if (this.ValidationErrorResponse != undefined) {
      if (this.ValidationErrorResponse.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  onPreviewNextClick(event) {
    this.previewColumnsGrid = this.previewColumns;
  }

  //TEST CODE
  onPreviewNextClickCheck(event) {
    if (!this.AllowBulkUpdate) {
      this.previewDataExcel.forEach((ele) => {
        if ("Location ID" in ele) {
          delete ele["Location ID"];
        }
        if ("物件ID" in ele) {
          delete ele["L物件ID"];
        }
      });
      if (this.previewColumns.includes("Location ID")) {
        var index = this.previewColumns.indexOf("Location ID");
        if (index !== -1) this.previewColumns.splice(index, 1);
      }

      if (this.previewColumns.includes("物件ID")) {
        var index = this.previewColumns.indexOf("物件ID");
        if (index !== -1) this.previewColumns.splice(index, 1);
      }
      this.CreateTableFromJSON();
    }
  }
  //TEST CODE

  onComplete() {
    this.ShowProcessing = false;
    if (this.Selectedtable == 1) {
      this.arrSelectedColumn.filter((obj) => {
        if (obj.SystemColumn.toLowerCase() == "statuscd")
          obj.SystemColumn = "AssetStatusCd";
        else if (obj.SystemColumn.toLowerCase() == "ownershipcd")
          obj.SystemColumn = "AssetOwnershipCd";
      });
      this.DataService.DataImportSaveAsset({
        data: this.dataExcel,
        MappedConfig: this.arrSelectedColumn,
      }).subscribe((res) => {
        if (res != undefined && res != null) {
          this.isCompleted = true;
          this.AssetData=res;
          this.RowsAffected = res.length;
          this.notify.success(
            this.ErrorDataMsg.astSaved +' '+ res.length +' '+ this.ErrorDataMsg.rowsAffected
          );
          this.ShowProcessing = true;
          window.scrollTo(0, 0);
        } else {
          this.isCompleted = false;
          this.RowsAffected = 0;
          this.ShowProcessing = true;
        }
      });
    } else if (this.Selectedtable == 2) {
      this.DataService.DataImportSaveLocation({
        data: this.dataExcel,
        MappedConfig: this.arrSelectedColumn,
        LocationFields: this.AssetFields,
      }).subscribe((res) => {
        if (res != undefined && res != null) {
          this.LocationData=res
          this.isCompleted = true;
          this.RowsAffected = res.length;
          this.notify.success(
            this.ErrorDataMsg.locationSaved +' ' +
              res.length +' '+
              this.ErrorDataMsg.rowsAffected
          );
          this.ShowProcessing = true;
          window.scrollTo(0, 0);
        } else {
          this.ShowProcessing = true;
          this.isCompleted = false;
          this.RowsAffected = 0;
        }
      });
    }
  }
  resetData() {
    this.previewColumns = [];
    this.previewColumnsGrid = [];
    this.previewDataExcel = [];
    this.dataExcel = [];
    this.ProcessedRecords = undefined;
    this.CreateTableFromJSON();
    this.arrSelectedColumn = [];
    this.isCompleted = false;
    this.RowsAffected = 0;
    this.ShowProcessing = false;
    this.SetDisableDropdownItems();
  }
  NewImport() {
    this.resetData();
    this.router.navigate(["/data-import"]);
  }
  ImageImport(){
    this.showImageImport=true;
  }
}
