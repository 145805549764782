import { Title } from '@angular/platform-browser';
import { CoreDataService } from './../Service/core-data.service';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-response-password-reset',
  templateUrl: './response-password-reset.component.html',
  styleUrls: ['./response-password-reset.component.scss']
})
export class ResponsePasswordResetComponent implements OnInit {
  UpdatePasswordForm: FormGroup;
  submitted: boolean = false;
  encodedLink: string;
  subs: any;
  deviceInfo = null;
  isMobileDevice:boolean=false;
  constructor(
    private titleService: Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedDataService: SharedDataService,
    private notify: SnotifyService,
    private coreDataService: CoreDataService,
    private spinner: SpinnerVisibilityService,
    private deviceService: DeviceDetectorService
    )
    {
    this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      this.isMobileDevice= this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      console.log(JSON.stringify(this.deviceInfo));
      console.log("Is Mobile : "+isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      console.log("Is Tablet : "+ isTablet);  // returns if the device us a tablet (iPad etc)
      console.log("Is Desktop : "+isDesktopDevice); // returns if the app is running on a Desktop browser.

    this.titleService.setTitle("Update Password - Evaluate Next");
   }

  ngOnInit() {
    this.UpdatePasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.spinner.show();
    this.subs = this.sharedDataService.encodedLink$.subscribe(
      res => {
        this.spinner.hide();
        if (res != null && res != undefined) {
          this.encodedLink = res;
        } else {
          this.notify.warning("Invalid Link!");
        }
      },
      error => {
        this.notify.error("Invalid Link! Error:  " + error);
      }
    );
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  get UpdatePasswordControlValidator() { return this.UpdatePasswordForm.controls; }

  matchConfirmPassword(rePassword) {
    //console.log(rePassword.value);
    if (rePassword.value.length == this.UpdatePasswordForm.controls['newPassword'].value.length) {
      if (rePassword.value === (this.UpdatePasswordForm.controls['newPassword'].value)) {
      }
      this.notify.warning("Password Not Matched!");
    }
    else {
      this.notify.warning("Password Not Matched!");
    }
  }
  updatePassword(updateForm) {
    this.spinner.show();
    this.submitted = true;
    if (this.UpdatePasswordForm.valid) {
      if (updateForm.newPassword === updateForm.confirmpassword) {
        //this.notify.info("Wait...", { timeout: 5000 });

        this.coreDataService.UpdateNewPassword(this.encodedLink, updateForm.newPassword).subscribe(
          res => {
            this.spinner.hide();
            if (res != null && res != undefined) {
              if(res._body==1)
              {
                if (this.isMobileDevice===true) {
                  this.router.navigateByUrl('MobileResetPassword');
                 }
                else {
                  this.notify.confirm("Done!, Password updated. Now login with new password", {
                    timeout: 5000,
                    buttons: [
                      {
                        text: 'Okay',
                        action: toster => {
                          this.router.navigateByUrl('/'),
                            this.notify.remove(toster.id)
                        }
                      }
                    ]
                  });
                }
            }
            else {
              this.notify.error("Unable to update password! Try Again!: " + res.error);
            }
            }
            else {
              this.notify.error("Unable to update password! Try Again!: " + res.error);
            }
          },
          error => {
            this.notify.error("Unable to update password! Try Again!");
            this.router.navigateByUrl('');
          }
        );

      } else {
        this.spinner.hide();
        this.notify.warning("Password Not Matched, Try again!");
      }
    } else {
      this.spinner.hide();
      this.notify.error("Invalid/Missing fields!");
    }
  }
}
