import { Title } from '@angular/platform-browser';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {


  LanguageConfig: any;
  ErrMessagesData: any;
  ResetPasswordForm: FormGroup;
  submitted: boolean = false;
  // emailPattern = "^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$";

  
  constructor(
    private titleService: Title,
    private formBuilder: FormBuilder,
    private router: Router,
    private sharedDataService: SharedDataService,
    private notify: SnotifyService,
    private coreDataService: CoreDataService,
    private spinner: SpinnerVisibilityService,
    private sharedService: SharedDataService
  ) {
    this.titleService.setTitle("Request Password - Evaluate Next");
  }

  ngOnInit() {

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
      // this.ErrMessagesData = ErrMessagesData[langLiteral];
      // this.titleService.setTitle(this.LanguageConfig.PageTitle);

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[31];
          this.ErrMessagesData = res[28];
        }
      })
    }

    this.ResetPasswordForm = this.formBuilder.group({
      //email: ['', [Validators.required, Validators.email]]
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')]]
    });
  }
  get ResetPasswordControlValidator() { return this.ResetPasswordForm.controls; }

  resetPassword(resetForm) {
    this.spinner.show();
    this.submitted = true;
    if (this.ResetPasswordForm.valid) {
      let email = resetForm.email;
      this.coreDataService.RequestResetPassword(email).first().subscribe(
        res => {
          //console.log(res);
          this.spinner.hide();
          if (res._body != null && res._body != undefined) {
            let validMail = JSON.parse(res._body).isValid;
            if (validMail == true) {
              this.notify.success(this.ErrMessagesData.PasswordReset, { timeout: 3000 });
              this.router.navigateByUrl('');
            } else {
              this.notify.error(this.ErrMessagesData.EnteredEmailInValid);
            }
          }
          else {
            this.notify.error(this.ErrMessagesData.EnteredEmailInValid);
          }
        },
        error => {
          this.notify.error(this.ErrMessagesData.ResetContactSupport);
        }
      );
      // this.router.navigate(['/ResponsePasswordReset']);
    }
    else {
      this.notify.error(this.ErrMessagesData.EnterValidEmail);
      //this.sharedDataService.showError("Please enter a valid E-Mail Address!");
      this.submitted = false;
    }
  }

}
