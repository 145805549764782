import { Component, OnInit, ApplicationRef, Injector, LOCALE_ID, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CoreDataService } from '../../Service/core-data.service'
import { SnotifyService } from 'ng-snotify';
import { OrgSettingEntity } from '../../SystemAdmin/organisation-setting/organisation-setting.model';
import { FormControl, FormGroup, FormsModule, FormArray, Validators, FormBuilder, NgForm, FormControlDirective, FormControlName } from "@angular/forms";
import * as CryptoJS from 'crypto-js';
import { SharedDataService } from "src/app/Service/CommonUtility.service";


@Component({
    selector: 'app-organisation-setting',
    templateUrl: '../organisation-setting/organisation-setting.component.html',
    styleUrls: ['../../SystemAdmin/organisation-setting/organisation-setting.component.scss']
})
export class OrganisationSettingComponent implements OnInit {
    userAction: string = "Asset Refactoring";
    OrgSettingsForm: FormGroup;
    LanguageConfig: any;
    GridConfig: any;
    ErrorDataMsg: any;
    orgSettingEntity: OrgSettingEntity = new OrgSettingEntity();
    public ddlCultureInfoDefaultItem: { text: string, value: string } = { text: "Select", value: null };
    public ddlCultureInfo: Array<{ text: string, value: string, symbol: string }> = [
        { text: "en-NZ", value: 'en-NZ', symbol: '$' },
        { text: "en-US", value: 'en-US', symbol: '$' },
        { text: "en-AU", value: 'en-AU', symbol: '$' },
        { text: "ja-JP", value: 'en-JP', symbol: '¥' }
    ];

    public ddlDateFormatDefaultItem: { text: string, value: string } = { text: "Select", value: null };
    public ddlTimeFormatDefaultItem: { text: string, value: string } = { text: "Select", value: null };
    public ddlCurrencyDefaultItem: { text: string, value: string } = { text: "Select", value: null };
    public ddlTimeFormat: Array<{ text: string, value: string }> = [
        { text: "HH:mm", value: 'HH:mm' },
        { text: "HH:mm:ss", value: 'HH:mm:ss' }
    ];
    public ddlCurrency: Array<{ text: string, value: string }> = [
        { text: "NZD", value: 'NZD' },
        { text: "INR", value: 'INR' },
        { text: "AUD", value: 'AUD' },
        { text: 'JPY', value: 'JPY' }
    ];
    public ddlDateFormat: Array<{ text: string, value: string }> = [

        { text: "dd/MM/yyyy", value: 'dd/MM/yyyy' },
        { text: "MM/dd/yyyy", value: 'MM/dd/yyyy' },
        { text: "yyyy/MM/dd", value: 'yyyy/MM/dd' }

    ];

    constructor(private activatedRoute: ActivatedRoute, private sharedDataService: SharedDataService, private notify: SnotifyService, private titleService: Title, private DataService: CoreDataService) {
        let pageTite = this.activatedRoute.snapshot.data['title'];
        this.titleService.setTitle(pageTite);
        this.userAction = pageTite
    }
    ngOnInit() {
        if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
            // let langLiteral = this.sharedDataService.GetLanguageLiteral();
            // this.LanguageConfig = new LanguageConfig();
            // this.LanguageConfig.Labels = PageData[langLiteral];
            // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
            // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
            this.sharedDataService.languageConfig$.subscribe(res => {
                if (res != undefined) {
                    this.LanguageConfig = res[21];
                    this.ErrorDataMsg = res[28];

                }
            })
        }
        this.getOrgSettingData();

        this.OrgSettingsForm = new FormGroup({
            PasswordResetInterval: new FormControl('', [Validators.required,
            Validators.pattern(new RegExp("^\\d+$")),
            Validators.max(999)
            ]),
            CultureInfo: new FormControl(null, [Validators.required]),
            DateFormat: new FormControl(null, [Validators.required]),
            TimeFormat: new FormControl(null, [Validators.required]),
            Currency: new FormControl(null, [Validators.required]),
            TargetTimePerAsset: new FormControl(null, [Validators.required])
        });

    }
    SaveOrgSettings() {
        if (this.OrgSettingsForm.valid) {
            let objParam = this.sharedDataService.convertModelToList(this.orgSettingEntity);
            var UserData = this.sharedDataService.parseJwt(localStorage.getItem('token'));

            this.DataService.OrganizationSettings(objParam).subscribe(res => {
                if (res != undefined && res != null && res > 0) {
                    if (localStorage.getItem('OrgSetting') != undefined && localStorage.getItem('OrgSetting') != null) {
                        var hash = CryptoJS.AES.encrypt(JSON.stringify(this.sharedDataService.convertListToModel(objParam)), UserData.EmailID).toString();
                        localStorage.setItem('OrgSetting', hash);
                        this.notify.success(this.ErrorDataMsg.settingSaved);
                    }
                }
            })
        }
        else {
            this.notify.error(this.ErrorDataMsg.SomeErrorOccured);
        }
    }

    getOrgSettingData() {
        this.DataService.OrganSettings().subscribe(res => {
            if (res != undefined) {
                this.orgSettingEntity = this.sharedDataService.convertListToModel(res);
            }
        })
    }

    setCultureinfo(event) {
        this.orgSettingEntity.CurrencySymbol = event.symbol;
    }

}
