import { SnotifyService } from 'ng-snotify';
import { Component, OnInit } from '@angular/core';
import { unitofMeasurmentList, uomTypeModel, UOMList } from '../unit-of-measurment/unit-of-measurment.model';
import { CoreDataService } from '../../Service/core-data.service';
import { SharedDataService } from '../../Service/CommonUtility.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, RequiredValidator } from '@angular/forms';
import { isValidRange } from '@progress/kendo-angular-dateinputs/dist/es2015/util';
import { AddEvent, EditEvent, GridComponent } from '@progress/kendo-angular-grid';
import { Title } from '@angular/platform-browser';
import { anyChanged } from '@progress/kendo-angular-grid/dist/es2015/utils';


@Component({
  selector: 'app-unit-of-measurment',
  templateUrl: './unit-of-measurment.component.html',
  styleUrls: ['./unit-of-measurment.component.scss']
})
export class UnitOfMeasurmentgComponent implements OnInit {
  rowIndexSelected = undefined;
  public deleteOpened: boolean = false;
  isCancelclicked: boolean = false;
  LanguageConfig: any;
  uomForm: FormGroup;
  unitofMeasurmentList: Array<UOMList> = new Array();
  tempUOMList: UOMList = new UOMList();
  tempData: any;
  uomModel: UOMList = new UOMList();
  editUomModel: UOMList = new UOMList();
  uomTypeModel: Array<any> = [];
  uomdataModel: uomTypeModel = new uomTypeModel();
  ErrorMsgData: any;
  valid: boolean = false;
  uomSubID: number;
  editedRowIndex: number;
  uomSubModel: Array<any> = [];
  IsRowInEdit: boolean = false;
  constructor(private titleService: Title,
    private notify: SnotifyService, private coreDataService: CoreDataService, private sharedDataService: SharedDataService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  userAction: string = "Add Unit Of Measurement"

  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[23];
          this.ErrorMsgData = res[28]
        }
      })
    }

    this.uomForm = this.formBuilder.group({
      unitName: [this.uomModel.unitName, [Validators.required]],
      conversionFactors: [this.uomModel.conversionFactor, [Validators.required]],
      isDefault: [this.uomModel.isDefault],
      description: [this.uomModel.description, [Validators.required]],
    });
    if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
      let UOMTypeID: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
      if (UOMTypeID != null && UOMTypeID != undefined) {
        this.editUOMTypeByID(UOMTypeID);
      }
    }
  }
  saveUOMType() {
    if (this.IsRowInEdit == false) {
      if (this.uomdataModel.typeName != undefined && this.uomdataModel.typeName != '' && this.unitofMeasurmentList.length > 0 && this.validateDefault()) {
        this.coreDataService.AddUpdateUOMType(this.uomdataModel).subscribe((res) => {
          if (res != undefined && res != null) {
            this.coreDataService.AddUpdateUOM({ uomEntity: this.unitofMeasurmentList, uomTypeID: res.uomTypeID }).subscribe((resUOM) => {
              if (resUOM != undefined) {
                this.notify.success(this.ErrorMsgData.unitSavedSuccessfully);
                this.router.navigate(['/unit-of-measurment-listing']);
              }
            })
          }
        })
      } else {
        this.notify.warning(this.ErrorMsgData.unitTypeName);
      }
    }
    else {
      this.notify.warning(this.ErrorMsgData.rowEditMode);
    }
  }
  // cancel PopUp
  cancelledClick() {
    this.isCancelclicked = true;
  }
  Close(value) {
    if (value == true && value != undefined) {
      this.back();
      this.isCancelclicked = false;
    }
    else {
      this.isCancelclicked = false;
    }
  }
  editUOMTypeByID(UOMTypeID) {
    this.coreDataService.GetUOMDetailsByID(UOMTypeID).subscribe((data) => {
      if (data != undefined && data != null) {
        this.unitofMeasurmentList = data.uomEntityList;
        this.uomdataModel = data.uomTypeEntity
      }
    })
  }
  UOMTypeNameBlur(event) {
    if (event.target.value != undefined && event.target.value != "") {
      this.coreDataService.ValidateUOMTypeName(this.uomdataModel.uomTypeID, event.target.value).subscribe(res => {
        if (res != null && res != undefined && res.length > 0) {
          this.uomdataModel.typeName = null;
          this.notify.error(this.ErrorMsgData.typeNameExist);
        }
      })
    }
  }
  addUOMGride() {
    if (this.uomForm.valid && this.validateUOMlist(this.uomForm.value.unitName)) {
      if (this.uomForm.value.isDefault) {
        this.unitofMeasurmentList.forEach(element => {
          element.isDefault = false;
        });
      }
      this.uomModel = new UOMList();
      this.uomModel.uomID = this.uomForm.value.uomID == undefined ? 0 : this.uomForm.value.uomID;
      this.uomModel.unitName = this.uomForm.value.unitName;
      this.uomModel.isDefault = this.uomForm.value.isDefault == null ? false : this.uomForm.value.isDefault;
      this.uomModel.conversionFactor = this.uomForm.value.conversionFactors;
      this.uomModel.description = this.uomForm.value.description;
      this.unitofMeasurmentList.push(this.uomModel);
      this.uomForm.reset();
    }
    else {
      this.notify.error(this.ErrorMsgData.fieldCantEmpty);
    }
  }

  public DeleteOpenPopUp(rowIndex) {
    this.rowIndexSelected = rowIndex;
    this.deleteOpened = true;
  }
  DeleteRow() {
    if (this.rowIndexSelected != undefined) {
      this.unitofMeasurmentList.splice(this.rowIndexSelected, 1);
    }
    this.deleteOpened = false;
  }
  public UOMclosePopUp() {
    this.rowIndexSelected = undefined;
    this.deleteOpened = false;
  }

  validateUOMlist(unitName: string) {
    let IsValid = true;
    this.unitofMeasurmentList.forEach(element => {
      if (element.unitName.toLowerCase() == unitName.toLowerCase()) {
        IsValid = false;
        this.notify.error(this.ErrorMsgData.unitNameExist);
      }

    });
    return IsValid;
  }

  back() {
    this.router.navigate(['/unit-of-measurment-listing']);
  }

  validateDefault(): boolean {
    let IsDefaultTrue = this.unitofMeasurmentList.find(function (Obj) { return Obj.isDefault === true });
    let isvalid = true;
    if (IsDefaultTrue != undefined) {
      isvalid = true;
    } else {
      isvalid = false;
      this.notify.warning(this.ErrorMsgData.unitDefinedDefault);
    }
    return isvalid;
  }
  // Edit Updated ROW

  // Edit Row
  public editHandler() {
    this.IsRowInEdit = true;
  }
  public updateEditeRow(dataItem) {
    this.IsRowInEdit = false;
    if (dataItem.isDefault != null && dataItem.isDefault != undefined && dataItem.isDefault == true) {
      this.unitofMeasurmentList.forEach(element => {
        if (element['uomid'] != dataItem.uomid) {
          let indexData = this.unitofMeasurmentList.indexOf(this.unitofMeasurmentList.find((obj) => {
            return obj['uomid'] == element['uomid']
          }
          ))
          if (indexData > -1) {
            this.unitofMeasurmentList[indexData].isDefault = false;
          }
        }
      });
      let indx = this.unitofMeasurmentList.indexOf(this.unitofMeasurmentList.find((obj) => {
        return obj['uomid'] == dataItem.uomID
      }
      ))
      this.unitofMeasurmentList[indx].isDefault = true;

    }


  }
  public cancelData() {
    this.IsRowInEdit = false;
  }

  //Row Edit Cancel
  public cancelHandler({ sender, rowIndex, dataItem }) {

    this.closeEditor(sender, rowIndex);
    this.editUomModel = Object.assign({}, dataItem);
    this.IsRowInEdit = false;
    this.editedRowIndex = undefined;
    this.editUomModel = undefined;
  }

  //Update Edited Row
  public saveHandler({ sender, rowIndex, dataItem, isNew }) {
    if (dataItem.unitName != null && dataItem.unitName != '' && dataItem.conversionFactor > 0 && dataItem.description != null && dataItem.description != '') {
      this.editUomModel = this.unitofMeasurmentList.find((obj => { return obj.uomID == dataItem.uomID }));
      if (rowIndex > -1) {
        this.unitofMeasurmentList[rowIndex].unitName = dataItem.unitName;
        this.unitofMeasurmentList[rowIndex].isDefault = dataItem.isDefault;
        this.unitofMeasurmentList[rowIndex].description = dataItem.description;
        this.unitofMeasurmentList[rowIndex].conversionFactor = dataItem.conversionFactor;

      }
      sender.closeRow(rowIndex, isNew);
      this.IsRowInEdit = false;
      this.editedRowIndex = undefined;
      this.uomModel = undefined;
    } else {
      this.notify.error(this.ErrorMsgData.fieldsMissing);
    }
  }
  //Row Close/Cancel
  private closeEditor(grid, rowIndex = this.editedRowIndex) {

    grid.closeRow(rowIndex);

  }
}
