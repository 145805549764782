import { SnotifyService } from "ng-snotify";
import {
  Component,
  OnInit,
  AfterViewInit,
  Injectable,
  ElementRef,
  ViewChild,
  AfterContentInit,
  ViewChildren,
  QueryList,
} from "@angular/core";
import {
  AssettypeEntity,
  priceCapacityBased,
  makeModel,
  AssetTypeAdminListingEntity,
  FixListingEntityModel,
  FaultGroupListingEntity,
  FaultGroupListingEntityModel,
  FixListingEntity,
  AssetTypeRMModel,
  PriceCapacityBasedRM,
} from "../create-asset-type/create-asset-type.model";
import { CoreDataService } from "src/app/Service/core-data.service";
import { SharedDataService } from "src/app/Service/CommonUtility.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import {
  process,
  State,
  SortDescriptor,
  orderBy,
  filterBy,
  FilterDescriptor,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import {
  FormControl,
  FormGroup,
  FormsModule,
  FormArray,
  Validators,
  FormBuilder,
  NgForm,
  FormControlDirective,
  FormControlName,
} from "@angular/forms";
import { CodeData } from "src/app/Common/shared-model";
import { Title } from "@angular/platform-browser";
import { AdminEntity } from "../../Common/shared-model";
import { ToastrService } from "ngx-toastr";

const formGroupRM = (dataItem) =>
  new FormGroup({
    assetTypeRMID: new FormControl(dataItem.assetTypeRMID),
    assetTypeID: new FormControl(dataItem.assetTypeID),
    supplyCosts: new FormControl(dataItem.supplyCosts, [Validators.required]),
      installMultiplier: new FormControl(dataItem.installMultiplier, [
        Validators.required,
        Validators.pattern(new RegExp("^[\\d]{1,7}(\\.[\\d]{1,3})?$")),
      ]),
      installCost: new FormControl(dataItem.installCost, [Validators.required]),
      fixedCost: new FormControl(dataItem.fixedCost),
      rmItemCD: new FormControl(dataItem.rmItemCD),
      priceCapacityBasedRM:new FormControl(dataItem.priceCapacityBasedRM),
    description: new FormControl(dataItem.description),
    frequency: new FormControl(dataItem.frequency, [
      Validators.min(0),
      Validators.required,
    ]),

    createdBy: new FormControl(dataItem.createdBy),
    creationDate: new FormControl(dataItem.creationDate),
    updatedBy: new FormControl(dataItem.updatedBy),
    updationDate: new FormControl(dataItem.updationDate),
  });
@Component({
  selector: "app-create-asset-type",
  templateUrl: "create-asset-type.component.html",
  styleUrls: ["create-asset-type.component.scss"],
})
export class CreateAssetTypeComponent implements OnInit, AfterViewInit {
  @ViewChild("assetTypeName",{static:false}) assetTypeName: ElementRef;
  public pageSizeRm=3
  public stateRm: State = {
    skip: 0,
    take: 3,
    sort: [{ field: "assetTypeRMID" }],
  };
  public editedRowIndex: number;
  public isRowOpen: boolean = false;
  public HideShowCapacity=false
  public formGroupRM: FormGroup = formGroupRM(new AssetTypeRMModel());
  AssetTypeRMModel: AssetTypeRMModel = new AssetTypeRMModel();
  public AssetTypeRmList: Array<AssetTypeRMModel> = [];
  
  public filter: CompositeFilterDescriptor = null;

  public gridfilter: any[] = filterBy(this.AssetTypeRmList, this.filter);
  public gridDataRM: GridDataResult = process(
    this.AssetTypeRmList,
    this.stateRm
  );
  public selectableSettings = {
    enabled: true,
    checkboxOnly: false,
    mode: "multiple",
  };
  public mySelection: number[] = [];
  filteredList: Array<AssetTypeRMModel> = [];
  public ItemRMDropdown: Array<{ codeValue: string; codeID: number }> = [];
  GridCurrencyChange: any;
  currencySymbol: any;

  LanguageConfig: any;
  rowIndexTemp = undefined;
  rowIndexTempRM = undefined;
  PricingCapUpdateRowIndex: number = undefined;
  PricingCapUpdateRowIndexRM: number = undefined;
  AssetTypeFormGroup: FormGroup;
  rowIndexMakeMdel = undefined;
  deleteOpenedBtnMakeModel: boolean = false;
  deleteOpendBtnPricing: boolean = false;
  deleteOpendBtnPricingRM: boolean = false;
  isCancelclicked: boolean = false;
  priceingCapacityBasedForm: FormGroup;
  priceingCapacityBasedFormRM: FormGroup;
  CapacityFormControl: FormControl;
  submitted: boolean = false;
  capacityAddButton: boolean = false;
  capacityAddButtonRM: boolean = false;
  makeModelButton: boolean = false;
  addSize: boolean = false;
  addData: boolean = false;
  ErrorMsgData: any;
  ddlSize = Array<CodeData>();
  ddlManufacturer = Array<CodeData>();
  priceCapacityBased: Array<priceCapacityBased> =
    new Array<priceCapacityBased>();
  capacityBased: priceCapacityBased = new priceCapacityBased();
  capacityBasedRM: PriceCapacityBasedRM = new PriceCapacityBasedRM();
  priceCapacityBasedRM:Array<PriceCapacityBasedRM>=Array<PriceCapacityBasedRM>()
  AssetTypeAdminListingEntity: AssetTypeAdminListingEntity =
    new AssetTypeAdminListingEntity();
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
  };
  priceSizeBased: Array<any> = [];
  makeModel: Array<any> = [];
  AdminEntity: AdminEntity = new AdminEntity();
  makeModelData: makeModel = new makeModel();
  private gridData: GridDataResult;
  FrequencyError: boolean = false;
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.priceCapacityBased, sort), this.state);
  }
  assetTypeModel: AssettypeEntity = new AssettypeEntity();
  FaultGroupListingEntityModel: FaultGroupListingEntityModel =
    new FaultGroupListingEntityModel();
  FixListingEntityModel: FixListingEntityModel = new FixListingEntityModel();
  assetsGroupData: Array<any> = [];
  public ddlAssetGroupDefaultItem: { groupName: string; assetGroupID: number } =
    { groupName: "Select", assetGroupID: null };
  public ddlSizeDefaultItem: { codeValue: string; codeID: number } = {
    codeValue: "Select",
    codeID: null,
  };
  public ddlUOMDefaultItem: { typeName: string; uomTypeID: number } = {
    typeName: "Select",
    uomTypeID: null,
  };
  public ddlUomCDItem: { codeValue: string; codeID: number } = {
    codeValue: "Select",
    codeID: null,
  };
  UOMData: Array<any> = [];
  constructor(
    private titleService: Title,
    private notify: SnotifyService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private coreDataService: CoreDataService,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
  }
  IsAdmin: string;
  userAction: string = "Add Asset Type";
  ngOnInit() {
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[17];
          this.ErrorMsgData = res[28];
        }
      });
    }
    this.getSizeDDLData();
    this.IsAdmin = this.sharedDataService.IsAdmin();

    this.AssetTypeFormGroup = new FormGroup({
      assetTypeName: new FormControl("", [
        Validators.required,
        Validators.maxLength(31),
      ]),
      code: new FormControl("", [
        Validators.required,
        Validators.maxLength(10),
      ]),
      assetGroupID: new FormControl("", [Validators.required]),
      expectedLife: new FormControl("", [
        Validators.required,
        Validators.pattern(new RegExp("^[\\d]{1,5}(\\.[\\d]{1,2})?$")),
      ]),
      uom: new FormControl("", [Validators.required]),
      active: new FormControl("", []),
      comments: new FormControl("", [Validators.maxLength(255)]),
      hasSubAssets: new FormControl("", []),
      supplyCosts: new FormControl("", [Validators.required]),
      installMultiplier: new FormControl("", [
        Validators.required,
        Validators.pattern(new RegExp("^[\\d]{1,7}(\\.[\\d]{1,3})?$")),
      ]),
      installCost: new FormControl("", [Validators.required]),
    });
    this.getorgDetails("");

    this.priceingCapacityBasedForm = this.formBuilder.group({
      from: [this.capacityBased.from, [Validators.required]],
      to: [this.capacityBased.to, [Validators.required]],
      cost: [this.capacityBased.cost, [Validators.required]],
      baseRate: [this.capacityBased.baseRate, [Validators.required]],
      size: [this.capacityBased.size],
    });

    this.priceingCapacityBasedFormRM = this.formBuilder.group({
      from: [this.capacityBasedRM.from, [Validators.required]],
      to: [this.capacityBasedRM.to, [Validators.required]],
      cost: [this.capacityBasedRM.cost, [Validators.required]],
      baseRate: [this.capacityBasedRM.baseRate],
      size: [this.capacityBasedRM.size],
    });
    // --------End validation----------

    if (
      this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null &&
      this.activatedRoute.snapshot.paramMap.get("assetTypeID") != undefined
    ) {
      this.AdminEntity.Table = "AssetType";
      this.AdminEntity.AssociatedID =
        this.activatedRoute.snapshot.paramMap.get("assetTypeID");
    }

    this.getAssetGroupData();
    this.getUOMTypeList();
    
  }
  LeftDataArr: Array<FaultGroupListingEntity> = [];
  RightBindDataArr: Array<FaultGroupListingEntity> = [];
  RightBindData: Array<FaultGroupListingEntity> = [];
  LeftDataArrFix: Array<FixListingEntity> = [];
  RightBindDataArrFix: Array<FixListingEntity> = [];
  RightBindDataFix: Array<FixListingEntity> = [];
  public onTabchange(e) {
    if (
      e.index == 5 &&
      this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null
    ) {
      this.AdminEntity.Table = "AssetType";
      this.AdminEntity.AssociatedID =
        this.activatedRoute.snapshot.paramMap.get("assetTypeID");
    } else if (
      e.index == 3 &&
      this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null
    ) {
    } else if (
      e.index == 4 &&
      this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null
    ) {
    }
  }
  // -----CODE FOR FAULT ---//
  selectedFaultCodes: Array<any> = [];

  selectedFixCodes: Array<any> = [];
  IsDisable() {
    if (
      this.PricingCapUpdateRowIndex == 0 ||
      this.priceCapacityBased.length == 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  addSelected() {
    var totalLoc = 0;

    this.selectedFaultCodes = new Array<FaultGroupListingEntity>();
    //Get Total Locations
    let Data;
    for (
      var i = 0;
      i < document.getElementById("linkFault").childNodes.length;
      i++
    ) {
      totalLoc +=
        document.getElementById("linkFault").childNodes[i].childNodes.length;
    }
    // Traverse to get all Selected locations and Push in an Array(selectedLocations)
    for (i = 0; i < totalLoc; i++) {
      if (document.getElementById("linkFault")[i].selected == true) {
        if (
          this.LeftDataArr.find(
            (obj) =>
              obj.faultID == document.getElementById("linkFault")[i].value
          )
        ) {
          this.selectedFaultCodes.push(
            this.LeftDataArr.find(
              (obj) =>
                obj.faultID == document.getElementById("linkFault")[i].value
            )
          );
        }
      }
    }
    if (
      this.selectedFaultCodes != undefined &&
      this.selectedFaultCodes.length > 0
    ) {
      //If Selected Locations already having Locations in Right SELECT BOX then add only those which are new to right selectbox
      var locToRemoveFromLeftWhenAddInRight: Array<FaultGroupListingEntity> =
        [];
      if (this.LeftDataArr.length > 0 && this.LeftDataArr != undefined) {
        for (var loc = 0; loc < this.selectedFaultCodes.length; loc++) {
          const rdIndex: number = this.LeftDataArr.indexOf(
            this.LeftDataArr.find(
              (obj) => obj.faultID == this.selectedFaultCodes[loc].faultID
            )
          );
          if (rdIndex == -1) {
            this.LeftDataArr.push(this.selectedFaultCodes[loc]); //adding new locations only
            locToRemoveFromLeftWhenAddInRight.push(
              this.selectedFaultCodes[loc]
            );
          }
        }
        document.getElementById("selectedlinkFault").innerHTML = ""; //clean Right selectbox before render/bind locations
        this.selectedFaultCodes = this.selectedFaultCodes.filter(
          (v, i) => this.selectedFaultCodes.indexOf(v) === i
        );
        this.selectedFaultCodes.forEach((ele) => {
          this.RightBindData.push(ele);
        });
        this.RightBindDataArr = this.RightBindData;
        this.commonSelectedAdd(this.RightBindDataArr);
        this.removeSelectedLinkLocations(this.RightBindDataArr);
      }
      //If No Selected Locations in Right SELECT BOX Add all the selected Locations in Right Select Box
      else {
        this.RightBindDataArr = this.RightBindDataArr.slice(0); //copy selected location from 0 index
        this.commonSelectedAdd(this.RightBindDataArr);
        this.removeSelectedLinkLocations(this.RightBindDataArr);
      }
    }
  }
  removeSelected() {
    var totalLoc = 0;
    for (
      var i = 0;
      i < document.getElementById("selectedlinkFault").childNodes.length;
      i++
    ) {
      totalLoc +=
        document.getElementById("selectedlinkFault").childNodes[i].childNodes
          .length;
    }
    var arr: Array<FaultGroupListingEntity> = [];
    let currGroup;
    for (i = 0; i < totalLoc; i++) {
      if (document.getElementById("selectedlinkFault")[i].selected == true) {
        if (
          this.RightBindData.find(
            (obj) =>
              obj.faultID ==
              document.getElementById("selectedlinkFault")[i].value
          )
        ) {
          this.LeftDataArr.push(
            this.RightBindData.find(
              (obj) =>
                obj.faultID ==
                document.getElementById("selectedlinkFault")[i].value
            )
          );
        }
      }
    }
    if (totalLoc > 0) {
      for (i = 0; i < totalLoc; i++) {
        if (document.getElementById("selectedlinkFault")[i].selected == true) {
          const delIndex: number = this.RightBindData.indexOf(
            this.RightBindData.find(
              (obj) =>
                obj.faultID ==
                document.getElementById("selectedlinkFault")[i].value
            )
          );
          if (delIndex != -1) {
            this.RightBindData.splice(delIndex, 1);
          }
        }
      }
    }

    document.getElementById("selectedlinkFault").innerHTML = "";
    document.getElementById("linkFault").innerHTML = "";
    this.commonSelectedAdd(this.RightBindData);
    this.BindData(this.LeftDataArr);
  }
  scurrentFaultGroup: number = 0;
  slastFaultGroup: number = 0;
  commonSelectedAddUpper(FaultGroupList: Array<FaultGroupListingEntity>): any {
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById("linkFault"); //selected option Select(child)
    var selectedFaultLeft: Array<FaultGroupListingEntity> = [];
    //Sorting array by Customer for better result.
    selectedFaultLeft = FaultGroupList.sort(
      this.sharedDataService.predicate(
        { name: "faultGroupID", reverse: true },
        "groupName",
        "faultName"
      )
    );
    if (selectedFaultLeft != undefined && selectedFaultLeft.length > 0) {
      for (let i = 0; i < selectedFaultLeft.length; i++) {
        this.scurrentFaultGroup = selectedFaultLeft[i].faultGroupID;
        //FOR FIRST GROUP
        if (this.slastFaultGroup == 0 && this.slastFaultGroup != undefined) {
          this.scurrentFaultGroup = this.slastFaultGroup =
            selectedFaultLeft[i].faultGroupID;
          soGroup = document.createElement("optgroup");
          soGroup.label = selectedFaultLeft[i].groupName;
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentFaultGroup === this.slastFaultGroup) {
          var soOption = document.createElement("option");
          soOption.value = selectedFaultLeft[i].faultID.toString();
          soOption.innerHTML = selectedFaultLeft[i].faultName;
          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
        } else {
          soGroup = document.createElement("optgroup");
          soGroup.label = selectedFaultLeft[i].groupName;
          var soOption = document.createElement("option");
          soOption.value = selectedFaultLeft[i].faultID.toString();
          soOption.innerHTML = selectedFaultLeft[i].faultName;
          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
          this.slastFaultGroup = this.scurrentFaultGroup;
        }
      }
      this.slastFaultGroup = 0;
      this.scurrentFaultGroup = 0;
    } else if (selectedFaultLeft.length == 0) {
      soSelect.innerHTML = "";
    }
  }
  commonSelectedAdd(FaultGroupList: Array<FaultGroupListingEntity>): any {
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById("selectedlinkFault"); //selected option Select(child)
    var selectedFaultLeft: Array<FaultGroupListingEntity> = [];
    selectedFaultLeft = FaultGroupList.sort(
      this.sharedDataService.predicate(
        { name: "faultGroupID", reverse: true },
        "groupName",
        "faultName"
      )
    );
    if (selectedFaultLeft != undefined && selectedFaultLeft.length > 0) {
      for (let i = 0; i < selectedFaultLeft.length; i++) {
        this.scurrentFaultGroup = selectedFaultLeft[i].faultGroupID;
        //FOR FIRST GROUP
        if (this.slastFaultGroup == 0 && this.slastFaultGroup != undefined) {
          this.scurrentFaultGroup = this.slastFaultGroup =
            selectedFaultLeft[i].faultGroupID;
          soGroup = document.createElement("optgroup");
          soGroup.label = selectedFaultLeft[i].groupName;
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentFaultGroup === this.slastFaultGroup) {
          var soOption = document.createElement("option");
          soOption.value = selectedFaultLeft[i].faultID.toString();
          soOption.innerHTML = selectedFaultLeft[i].faultName;
          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
        } else {
          soGroup = document.createElement("optgroup");
          soGroup.label = selectedFaultLeft[i].groupName;
          var soOption = document.createElement("option");
          soOption.value = selectedFaultLeft[i].faultID.toString();
          soOption.innerHTML = selectedFaultLeft[i].faultName;
          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
          this.slastFaultGroup = this.scurrentFaultGroup;
        }
      }
      this.slastFaultGroup = 0;
      this.scurrentFaultGroup = 0;
    } else if (selectedFaultLeft.length == 0) {
      soSelect.innerHTML = "";
    }
  }
  //BIND ALL Fault Group LIST IN LEFT SELECT BOX AFTER GETTING FROm API
  BindData(FaultGroupList: Array<FaultGroupListingEntity>): any {
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById("linkFault"); //selected option Select(child)
    var selectedFaultLeft: Array<FaultGroupListingEntity> = [];
    //Sorting array by Customer for better result.
    selectedFaultLeft = FaultGroupList.sort(
      this.sharedDataService.predicate(
        { name: "faultGroupID", reverse: true },
        "groupName",
        "faultName"
      )
    );
    if (selectedFaultLeft != undefined && selectedFaultLeft.length > 0) {
      for (let i = 0; i < selectedFaultLeft.length; i++) {
        this.scurrentFaultGroup = selectedFaultLeft[i].faultGroupID;
        //FOR FIRST GROUP
        if (this.slastFaultGroup == 0 && this.slastFaultGroup != undefined) {
          this.scurrentFaultGroup = this.slastFaultGroup =
            selectedFaultLeft[i].faultGroupID;
          soGroup = document.createElement("optgroup");
          soGroup.label = selectedFaultLeft[i].groupName;
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentFaultGroup === this.slastFaultGroup) {
          var soOption = document.createElement("option");
          soOption.value = selectedFaultLeft[i].faultID.toString();
          soOption.innerHTML = selectedFaultLeft[i].faultName;
          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
        } else {
          soGroup = document.createElement("optgroup");
          soGroup.label = selectedFaultLeft[i].groupName;
          var soOption = document.createElement("option");
          soOption.value = selectedFaultLeft[i].faultID.toString();
          soOption.innerHTML = selectedFaultLeft[i].faultName;
          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
          this.slastFaultGroup = this.scurrentFaultGroup;
        }
      }
      this.slastFaultGroup = 0;
      this.scurrentFaultGroup = 0;
    } else if (selectedFaultLeft.length == 0) {
      soSelect.innerHTML = "";
    }
    //---Location Block --End
  }
  removeSelectedLinkLocations(locToRemove: Array<FaultGroupListingEntity>) {
    if (locToRemove.length > 0) {
      for (var i = 0; i < locToRemove.length; i++) {
        const delIndex: number = this.LeftDataArr.indexOf(
          this.LeftDataArr.find((obj) => obj.faultID == locToRemove[i].faultID)
        );
        if (delIndex != -1) {
          this.LeftDataArr.splice(delIndex, 1);
        }
      }
    }
    document.getElementById("linkFault").innerHTML = "";
    this.BindData(this.LeftDataArr);
  }
  // -----CODE FOR FAULT ---//
  addSelectedFix() {
    var totalLoc = 0;
    //var selectedFaultCodes: Array<FaultGroupListingEntity> = [];
    //Get Total Locations
    this.selectedFixCodes = new Array<FaultGroupListingEntity>();
    for (
      var i = 0;
      i < document.getElementById("linkFix").childNodes.length;
      i++
    ) {
      totalLoc +=
        document.getElementById("linkFix").childNodes[i].childNodes.length;
    }
    // Traverse to get all Selected locations and Push in an Array(selectedLocations)
    for (i = 0; i < totalLoc; i++) {
      if (document.getElementById("linkFix")[i].selected == true) {
        if (
          this.LeftDataArrFix.find(
            (obj) => obj.fixID == document.getElementById("linkFix")[i].value
          )
        ) {
          this.selectedFixCodes.push(
            this.LeftDataArrFix.find(
              (obj) => obj.fixID == document.getElementById("linkFix")[i].value
            )
          );
        }
      }
    }
    if (
      this.selectedFixCodes != undefined &&
      this.selectedFixCodes.length > 0
    ) {
      //If Selected Locations already having Locations in Right SELECT BOX then add only those which are new to right selectbox
      var locToRemoveFromLeftWhenAddInRight: Array<FixListingEntity> = [];
      if (this.LeftDataArrFix.length > 0 && this.LeftDataArrFix != undefined) {
        for (var loc = 0; loc < this.selectedFixCodes.length; loc++) {
          const rdIndex: number = this.LeftDataArrFix.indexOf(
            this.LeftDataArrFix.find(
              (obj) => obj.fixID == this.selectedFixCodes[loc].fixID
            )
          );
          if (rdIndex == -1) {
            this.LeftDataArrFix.push(this.selectedFixCodes[loc]); //adding new locations only
            locToRemoveFromLeftWhenAddInRight.push(this.selectedFixCodes[loc]);
          }
        }
        document.getElementById("selectedlinkFix").innerHTML = ""; //clean Right selectbox before render/bind locations
        this.selectedFixCodes = this.selectedFixCodes.filter(
          (v, i) => this.selectedFixCodes.indexOf(v) === i
        );
        this.selectedFixCodes.forEach((ele) => {
          this.RightBindDataFix.push(ele);
        });
        this.RightBindDataArrFix = this.RightBindDataFix;
        this.commonSelectedAddFix(this.RightBindDataArrFix);
        this.removeSelectedLinkLocationsFix(this.RightBindDataArrFix);
      }
      //If No Selected Locations in Right SELECT BOX Add all the selected Locations in Right Select Box
      else {
        this.RightBindDataArrFix = this.RightBindDataArrFix.slice(0); //copy selected location from 0 index
        this.commonSelectedAddFix(this.RightBindDataArrFix);
        this.removeSelectedLinkLocationsFix(this.RightBindDataArrFix);
      }
    }
  }
  removeSelectedFix() {
    var totalLoc = 0;

    for (
      var i = 0;
      i < document.getElementById("selectedlinkFix").childNodes.length;
      i++
    ) {
      totalLoc +=
        document.getElementById("selectedlinkFix").childNodes[i].childNodes
          .length;
    }
    var arr: Array<FaultGroupListingEntity> = [];
    let currGroup;
    for (i = 0; i < totalLoc; i++) {
      if (document.getElementById("selectedlinkFix")[i].selected == true) {
        if (
          this.RightBindDataFix.find(
            (obj) =>
              obj.fixID == document.getElementById("selectedlinkFix")[i].value
          )
        ) {
          this.LeftDataArrFix.push(
            this.RightBindDataFix.find(
              (obj) =>
                obj.fixID == document.getElementById("selectedlinkFix")[i].value
            )
          );
        }
      }
    }
    if (totalLoc > 0) {
      for (i = 0; i < totalLoc; i++) {
        if (document.getElementById("selectedlinkFix")[i].selected == true) {
          const delIndex: number = this.RightBindDataFix.indexOf(
            this.RightBindDataFix.find(
              (obj) =>
                obj.fixID == document.getElementById("selectedlinkFix")[i].value
            )
          );
          if (delIndex != -1) {
            this.RightBindDataFix.splice(delIndex, 1);
          }
        }
      }
    }

    document.getElementById("selectedlinkFix").innerHTML = "";
    document.getElementById("linkFix").innerHTML = "";
    this.commonSelectedAddFix(this.RightBindDataFix);
    this.BindDataFix(this.LeftDataArrFix);
  }
  scurrentFixGroup: number = 0;
  slastFixGroup: number = 0;
  commonSelectedAddUpperFix(FaultGroupList: Array<FixListingEntity>): any {
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById("linkFix"); //selected option Select(child)
    var selectedFixLeft: Array<FixListingEntity> = [];
    //Sorting array by Customer for better result.
    selectedFixLeft = FaultGroupList.sort(
      this.sharedDataService.predicate(
        { name: "fixID", reverse: false },
        "fixName",
        ""
      )
    );
    if (selectedFixLeft != undefined && selectedFixLeft.length > 0) {
      for (let i = 0; i < selectedFixLeft.length; i++) {
        this.scurrentFixGroup = selectedFixLeft[i].fixID;
        //FOR FIRST GROUP
        if (this.slastFixGroup == 0 && this.slastFixGroup != undefined) {
          this.scurrentFixGroup = this.slastFixGroup = selectedFixLeft[i].fixID;
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentFixGroup === this.slastFixGroup) {
          var soOption = document.createElement("option");
          soOption.value = selectedFixLeft[i].fixID.toString();
          soOption.innerHTML = selectedFixLeft[i].fixName;
          soSelect.appendChild(soOption);
        } else {
          var soOption = document.createElement("option");
          soOption.value = selectedFixLeft[i].fixID.toString();
          soOption.innerHTML = selectedFixLeft[i].fixName;
          soSelect.appendChild(soOption);
          this.slastFixGroup = this.scurrentFixGroup;
        }
      }
      this.slastFixGroup = 0;
      this.scurrentFixGroup = 0;
    } else if (selectedFixLeft.length == 0) {
      soSelect.innerHTML = "";
    }
  }
  commonSelectedAddFix(FaultGroupList: Array<FixListingEntity>): any {
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById("selectedlinkFix"); //selected option Select(child)
    var selectedFixLeft: Array<FixListingEntity> = [];
    //Sorting array by Customer for better result.
    selectedFixLeft = FaultGroupList.sort(
      this.sharedDataService.predicate(
        { name: "fixID", reverse: false },
        "fixName",
        ""
      )
    );
    if (selectedFixLeft != undefined && selectedFixLeft.length > 0) {
      for (let i = 0; i < selectedFixLeft.length; i++) {
        this.scurrentFixGroup = selectedFixLeft[i].fixID;
        //FOR FIRST GROUP
        if (this.slastFixGroup == 0 && this.slastFixGroup != undefined) {
          this.scurrentFixGroup = this.slastFixGroup = selectedFixLeft[i].fixID;
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentFixGroup === this.slastFixGroup) {
          var soOption = document.createElement("option");
          soOption.value = selectedFixLeft[i].fixID.toString();
          soOption.innerHTML = selectedFixLeft[i].fixName;
          // soGroup.appendChild(soOption);
          soSelect.appendChild(soOption);
        } else {
          var soOption = document.createElement("option");
          soOption.value = selectedFixLeft[i].fixID.toString();
          soOption.innerHTML = selectedFixLeft[i].fixName;
          soSelect.appendChild(soOption);
          this.slastFixGroup = this.scurrentFixGroup;
        }
      }
      this.slastFixGroup = 0;
      this.scurrentFixGroup = 0;
    } else if (selectedFixLeft.length == 0) {
      soSelect.innerHTML = "";
    }
  }
  //BIND ALL Fault Group LIST IN LEFT SELECT BOX AFTER GETTING FROm API
  BindDataFix(FaultGroupList: Array<FixListingEntity>): any {
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById("linkFix"); //selected option Select(child)
    var selectedFixLeft: Array<FixListingEntity> = [];
    //Sorting array by Customer for better result.
    selectedFixLeft = FaultGroupList.sort(
      this.sharedDataService.predicate(
        { name: "fixID", reverse: false },
        "fixName",
        ""
      )
    );
    if (selectedFixLeft != undefined && selectedFixLeft.length > 0) {
      for (let i = 0; i < selectedFixLeft.length; i++) {
        this.scurrentFixGroup = selectedFixLeft[i].fixID;
        //FOR FIRST GROUP
        if (this.slastFixGroup == 0 && this.slastFixGroup != undefined) {
          this.scurrentFixGroup = this.slastFixGroup = selectedFixLeft[i].fixID;
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentFixGroup === this.slastFixGroup) {
          var soOption = document.createElement("option");
          soOption.value = selectedFixLeft[i].fixID.toString();
          soOption.innerHTML = selectedFixLeft[i].fixName;
          soSelect.appendChild(soOption);
        } else {
          var soOption = document.createElement("option");
          soOption.value = selectedFixLeft[i].fixID.toString();
          soOption.innerHTML = selectedFixLeft[i].fixName;
          soSelect.appendChild(soOption);
          this.slastFixGroup = this.scurrentFixGroup;
        }
      }
      this.slastFixGroup = 0;
      this.scurrentFixGroup = 0;
    } else if (selectedFixLeft.length == 0) {
      soSelect.innerHTML = "";
    }
    //---Location Block --End
  }
  removeSelectedLinkLocationsFix(locToRemove: Array<FixListingEntity>) {
    if (locToRemove.length > 0) {
      for (var i = 0; i < locToRemove.length; i++) {
        const delIndex: number = this.LeftDataArrFix.indexOf(
          this.LeftDataArrFix.find((obj) => obj.fixID == locToRemove[i].fixID)
        );
        if (delIndex != -1) {
          this.LeftDataArrFix.splice(delIndex, 1);
        }
      }
    }
    document.getElementById("linkFix").innerHTML = "";
    this.BindDataFix(this.LeftDataArrFix);
  }
  // -----CODE FOR FIX ---//
  ngAfterViewInit() {
    this.calculateFixCost();
    var k = document.getElementById("selectedlinkFault");
    if (k != null) {
    }
    this.initFaultDetails();
    this.initFixDetails();
    if (this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null) {
      this.assetTypeModel.assetTypeID = parseInt(
        this.activatedRoute.snapshot.paramMap.get("assetTypeID")
      );
    }
  }
  initFaultDetails() {
    this.RightBindData = new Array();
    this.coreDataService.getFaultGroupDetails().subscribe((res) => {
      document.getElementById("selectedlinkFault").innerHTML = "";
      document.getElementById("linkFault").innerHTML = "";
      this.LeftDataArr = res;
      if (this.assetTypeModel.assetTypeID == null) {
        this.BindData(this.LeftDataArr);
      }
      if (
        this.assetTypeModel.assetTypeID != null &&
        this.assetTypeModel.assetTypeID != undefined
      ) {
        this.coreDataService
          .GetFaultDetails(this.assetTypeModel.assetTypeID)
          .subscribe((resp) => {
            let arr = 0;

            resp.forEach((element) => {
              this.RightBindData.push(
                this.LeftDataArr.find((obj) => obj.faultID == element)
              );
              arr = this.LeftDataArr.findIndex((obj) => obj.faultID == element);
              if (arr > -1) {
                this.LeftDataArr.splice(arr, 1);
              }
            });
            this.BindData(this.LeftDataArr);
            this.commonSelectedAdd(this.RightBindData);
          });
      }
    });
  }

  initFixDetails() {
    this.coreDataService.GetFixDetailsURL().subscribe((res) => {
      this.RightBindDataFix = new Array();

      document.getElementById("selectedlinkFix").innerHTML = "";
      document.getElementById("linkFix").innerHTML = "";

      this.LeftDataArrFix = res;
      if (this.assetTypeModel.assetTypeID == null) {
        this.BindDataFix(this.LeftDataArrFix);
      }

      if (
        this.assetTypeModel.assetTypeID != null &&
        this.assetTypeModel.assetTypeID != undefined
      ) {
        this.coreDataService
          .GetFixDetails(this.assetTypeModel.assetTypeID)
          .subscribe((resp) => {
            let arr = 0;
            resp.forEach((element) => {
              this.RightBindDataFix.push(
                this.LeftDataArrFix.find((obj) => obj.fixID == element)
              );
              arr = this.LeftDataArrFix.findIndex(
                (obj) => obj.fixID == element
              );
              if (arr > -1) {
                this.LeftDataArrFix.splice(arr, 1);
              }
            });

            this.BindDataFix(this.LeftDataArrFix);
            // this.BindData(this.RightBindData)
            this.commonSelectedAddFix(this.RightBindDataFix);
          });
      }
    });
  }
  //Validating the Pricing form
  // convenience getter for easy access to form fields
  manufacturerData: any;
  getAssetTypeDetails(AssetTypeID) {
    this.coreDataService.GetAssetTypeDetailsByID(AssetTypeID).subscribe(
      (data) => {
        if (data != null && data != undefined) {
          //Getting AssetTypeEntity to Form
          this.assetTypeModel = data.assetTypeEntity;
          this.manufacturerData = data.manufacturerModel;
          //filling data in form
          this.selectedUOM(this.assetTypeModel.uom);
          data.manufacturerModel.forEach((element) => {
            var mgC = {};
            var uom = { codeValue: element.unitName, codeID: element.uomCd };
            // uom={codeValue: element., codeID:number}
            mgC = {
              codeValue: element.manufacturerName,
              codeID: element.mfgCd,
            };
            this.makeModel.push({
              mfgCd: mgC,
              model: element.model,
              manufacturerModelID: element.manufacturerModelID,
              uOMCd: uom,
              capacity: element.capacity,
              electricalInputKW: element.electricalInputKW,
            });
          });
          data.pricingCapacity.forEach((element) => {
            var selectedSize = new CodeData();
            selectedSize = this.ddlSize.find(function (obj) {
              return obj.codeID === element.sizeCd;
            });
            let objcapacityBased = new priceCapacityBased();
            objcapacityBased.pricingCapacityID = element.pricingCapacityID;
            objcapacityBased.from = element.from;
            objcapacityBased.to = element.to;
            objcapacityBased.cost = element.cost;
            objcapacityBased.baseRate = element.baseRate;
            if (element.sizeCd != undefined && element.sizeCd > 0) {
              objcapacityBased.size = {
                codeID: element.sizeCd,
                codeValue: selectedSize.codeValue,
              };
            } else {
              objcapacityBased.size = null;
            }
            this.priceCapacityBased.push(objcapacityBased);
          });
          this.calculateFixCost();
          if (
            this.priceCapacityBased != undefined &&
            this.priceCapacityBased.length > 0
          ) {
            const baseRateCtr = this.priceingCapacityBasedForm.get("baseRate");
            baseRateCtr.clearValidators();
            baseRateCtr.updateValueAndValidity();
          }
        }
      },
      (error) => {}
    );
  }
  getAssetGroupData() {
    this.coreDataService.GetAssetGroupDropdown().subscribe((res) => {
      if (res != undefined && res != null) {
        this.assetsGroupData = res;
      }
    });
  }
  //------------------------ TODO: Save data Asset Type-----------

  saveAssetType() {
    if (this.isRowOpen == true) {
      this.notify.error(this.ErrorMsgData.openRowError);
      return;
    }

    this.submitted = true;

    if (this.AssetTypeFormGroup.valid) {
      if (this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null) {
        this.assetTypeModel.assetTypeID = parseInt(
          this.activatedRoute.snapshot.paramMap.get("assetTypeID")
        );
      } else {
        this.assetTypeModel.assetTypeID = 0;
      }

      let makeModelArray = [];
      this.makeModel.forEach((element) => {
        makeModelArray.push({
          model: element.model,
          mfgCd: element.mfgCd.codeID,
          manufacturerModelID: element.manufacturerModelID,
          uOMCd: element.uOMCd.codeID,
          capacity: element.capacity,
          electricalInputKW: element.electricalInputKW,
        });
      });
      let capacityArray = [];
      this.priceCapacityBased.forEach((element) => {
        capacityArray.push({
          pricingCapacityID: element.pricingCapacityID,
          from: element.from,
          to: element.to,
          cost: element.cost,
          baseRate: element.baseRate,
          SizeCd:
            element.size != null
              ? element.size.codeID != null
                ? element.size.codeID
                : 0
              : 0,
        });
      });
      this.AssetTypeRmList.forEach((row) => {
        row.assetTypeID = Number(
          this.activatedRoute.snapshot.paramMap.get("assetTypeID")
        );
        this.ItemRMDropdown.forEach((Prow) => {
          if (row.rmItemCD == Prow.codeValue) {
            row.rmItemCD = Prow.codeID + "";
          }
        });
       row.priceCapacityBasedRM.forEach(Prow=>{
          Prow.sizeCD=(Prow.size!=null?Prow.size.codeID:0)
       })
      });
      this.assetTypeModel.manufacturerModel = makeModelArray;
      this.assetTypeModel.pricingCapacity = capacityArray;
      this.assetTypeModel.AssetTypeRMModel = this.AssetTypeRmList;
      this.coreDataService
        .AddUpdateAssetType(this.assetTypeModel)
        .subscribe((res) => {
          if (res != undefined && res != null) {
            if (res > 0) {
              this.FaultGroupListingEntityModel =
                new FaultGroupListingEntityModel();
              this.FixListingEntityModel = new FixListingEntityModel();

              this.RightBindData.forEach((ele) => {
                this.FaultGroupListingEntityModel.FaultID.push(ele.faultID);
              });
              this.RightBindDataFix.forEach((ele) => {
                this.FixListingEntityModel.FixID.push(ele.fixID);
              });

              this.FaultGroupListingEntityModel.AssetTypeID =
                this.assetTypeModel.assetTypeID;
              this.FixListingEntityModel.AssetTypeID =
                this.assetTypeModel.assetTypeID;

              this.coreDataService
                .SaveFix(this.FixListingEntityModel)
                .subscribe((resp) => {});

              this.coreDataService
                .SaveFaultGroup(this.FaultGroupListingEntityModel)
                .subscribe((resp) => {
                  if (resp > 0) {
                  }
                });

              this.notify.success(this.ErrorMsgData.assetTypeSaved);
              this.router.navigate(["/asset-type-management"]);
            }
          }
        });
    } else {
      Object.keys(this.AssetTypeFormGroup.controls).forEach((field) => {
        // {1}
        const control = this.AssetTypeFormGroup.get(field); // {2}
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true }); // {3}
      });
      this.notify.error(this.ErrorMsgData.incorrect);
    }
  }

  //---------------------end TODO: -----------------------------------------

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.priceCapacityBased, this.state);
  }
  addGrideCapacityBased() {
    // this.rangeValidator();
    this.capacityAddButton = true;
    if (this.priceingCapacityBasedForm.valid) {
      if (this.priceCapacityBased.length > 0) {
        let rangeIsValid = this.checkValidationToForm();

        this.capacityBased = new priceCapacityBased();

        let sizeExist = this.priceCapacityBasedValidater();
        if (!sizeExist && rangeIsValid) {
          this.capacityBased.from = this.priceingCapacityBasedForm.value.from;
          this.capacityBased.to = this.priceingCapacityBasedForm.value.to;
          this.capacityBased.cost = this.priceingCapacityBasedForm.value.cost;
          this.capacityBased.baseRate =
            this.priceingCapacityBasedForm.value.baseRate;
          this.capacityBased.size = this.priceingCapacityBasedForm.value.size;
          this.priceCapacityBased.push(this.capacityBased);
          this.calculateCapacityPrice();
          this.priceingCapacityBasedForm.reset();
        } else {
          this.notify.warning(this.ErrorMsgData.sizeUsedCapacity);
        }
      } else {
        this.capacityBased.from = this.priceingCapacityBasedForm.value.from;
        this.capacityBased.to = this.priceingCapacityBasedForm.value.to;
        this.capacityBased.cost = this.priceingCapacityBasedForm.value.cost;
        this.capacityBased.baseRate =
          this.priceingCapacityBasedForm.value.baseRate;
        this.capacityBased.size = this.priceingCapacityBasedForm.value.size;
        this.priceCapacityBased.push(this.capacityBased);
        const baseRateCtr = this.priceingCapacityBasedForm.get("baseRate");
        baseRateCtr.clearValidators();
        baseRateCtr.updateValueAndValidity();
        this.calculateCapacityPrice();
        this.priceingCapacityBasedForm.reset();
      }
    } else {
      this.notify.error(this.ErrorMsgData.emptyField);
    }
  }

  //-----------Calculate capacity price---------

  calculateCapacityPrice() {
    this.priceCapacityBased.sort(function (obj1, obj2) {
      return obj1.from - obj2.from;
    });
    for (let i = 0; i < this.priceCapacityBased.length; i++) {
      let baseRate;
      let previousUpperCapacity;

      if (i > 0) {
        let unitRate = this.priceCapacityBased[i - 1].cost;
        let previousLowerCapacity = this.priceCapacityBased[i - 1].from;
        previousUpperCapacity = this.priceCapacityBased[i - 1].to;
        baseRate = this.priceCapacityBased[i - 1].baseRate;
        this.priceCapacityBased[i].baseRate =
          baseRate + unitRate * (previousUpperCapacity - previousLowerCapacity);
      } else {
        baseRate = this.capacityBased.baseRate;
        previousUpperCapacity = 0;
      }
    }
  }

  deleteGrideCapacityBased(rowIndex) {
    if (rowIndex != undefined) {
      this.priceCapacityBased.splice(rowIndex, 1);
    }
  }
  public CapacityBasedopenPopUp(rowIndex) {
    this.rowIndexTemp = rowIndex;
    this.deleteOpendBtnPricing = true;
  }
  DeleteYesPopUp() {
    this.deleteGrideCapacityBased(this.rowIndexTemp);
    this.deleteOpendBtnPricing = false;
  }
  public CapacityBasedclosePopUp() {
    this.rowIndexTemp = undefined;
    this.deleteOpendBtnPricing = false;
  }

  // ---------TODO: make Model start---------------

  selectUOm(data) {
    this.makeModelData.uOMCd = data;
  }

  addMakeModelGrideData() {
    if (this.manufacturerData == undefined) {
      this.createMakeModelData();
    } else if (this.manufacturerData != undefined) {
      const found = this.manufacturerData.find(
        (element) => element.model == this.makeModelData.model
      );
      if (found == undefined) {
        this.createMakeModelData();
      } else if (
        found.model == this.makeModelData.model &&
        found.model != undefined
      ) {
        this.notify.success(this.ErrorMsgData.modelExistAlready);
      }
    }
  }

  createMakeModelData() {
    if (Object.keys(this.makeModelData).length > 0) {
      this.addData = true;
      this.makeModel.push(this.makeModelData);
      this.makeModelData = new makeModel();
    } else {
      this.notify.error(this.ErrorMsgData.emptyField);
    }
  }
  deleteMakeModelGrideData(rowIndex) {
    if (rowIndex != undefined) {
      this.makeModel.splice(rowIndex, 1);
    }
  }
  public makeModelOpenPopUp(rowIndex) {
    this.rowIndexMakeMdel = rowIndex;
    this.deleteOpenedBtnMakeModel = true;
  }
  DeleteYesMakeModelPopUp() {
    this.deleteMakeModelGrideData(this.rowIndexMakeMdel);
    this.deleteOpenedBtnMakeModel = false;
  }
  public makeModelclosePopUp() {
    this.rowIndexMakeMdel = undefined;
    this.deleteOpenedBtnMakeModel = false;
  }

  // ----------make Model end--------------------

  //-------------TODO: dropDown sizeBased(pricing tab) Manufacturer(make&Model tab)-----------------
  getSizeDDLData() {
    this.coreDataService.getCodeData().subscribe(
      (res) => {
        if (res != undefined && res != null) {
          res.forEach((element) => {
            if (element.codeName.toString() == "AssetSizeCd") {
              this.ddlSize.push(element);
            } else if (element.codeName.toString() == "MfgCd") {
              this.ddlManufacturer.push(element);
            } else if (element.codeName.toString() == "ItemsRMCd") {
              this.ItemRMDropdown.push(element);
            }
          });
          if (
            this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null
          ) {
            let AssetTypeID: number = parseInt(
              this.activatedRoute.snapshot.paramMap.get("assetTypeID")
            );
            if (AssetTypeID != null && AssetTypeID != undefined) {
              this.getAssetTypeDetails(AssetTypeID);
              this.AssetTypeRM();
            }
          }
        }
      },
      (error) => {
        this.notify.error(this.ErrorMsgData.errorOccured);
      }
    );
  }
  //---------------------end TODO---------------------------------------------------------

  //-------------------TODO: primary details unit of measurment dropdown-----------------

  getUOMTypeList() {
    this.coreDataService.getUOMTypeDropdown().subscribe((res) => {
      if (res != undefined && res != null) {
        this.UOMData = res;
      }
    });
  }

  ddlUOMCd: any = [];
  selectedUOM(Uomid) {
    this.coreDataService.GetUOMDataURL(Uomid).subscribe((res) => {
      res.forEach((element) => {
        this.ddlUOMCd.push({
          codeID: element.uomid,
          codeValue: element.unitName,
        });
      });
    });
  }
  //------------------end TODO----------------------------

  //---------------------FixCast calculation-------------

  calculateFixCost() {
    let supplyCost =
      this.assetTypeModel.supplyCosts != undefined
        ? this.assetTypeModel.supplyCosts
        : 0;
    let installMultiplier =
      this.assetTypeModel.installMultiplier != undefined
        ? this.assetTypeModel.installMultiplier
        : 0;
    let installCost = installMultiplier * supplyCost;
    this.assetTypeModel.installCost = installCost;
    this.assetTypeModel.fixedCost = installCost + supplyCost;
  }
  //MS

  priceCapacityBasedValidater(): boolean {
    var TempPricingCapacity: any = JSON.parse(
      JSON.stringify(this.priceCapacityBased)
    );
    if (
      this.PricingCapUpdateRowIndex != undefined &&
      this.PricingCapUpdateRowIndex != undefined
    ) {
      TempPricingCapacity.splice(this.PricingCapUpdateRowIndex, 1);
    }
    let selectedSize =
      this.priceingCapacityBasedForm.value.size == null
        ? -1
        : this.priceingCapacityBasedForm.value.size.codeID == null
        ? -1
        : this.priceingCapacityBasedForm.value.size.codeID;
    if (selectedSize > 0) {
      var res = TempPricingCapacity.find(function (Obj) {
        if (Obj.size != undefined && Obj.size != null)
          return Obj.size.codeID == selectedSize;
      });
      if (res != null && res != undefined && res.size != null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkValidationToForm(): boolean {
    var TempPricingCapacity: any = JSON.parse(
      JSON.stringify(this.priceCapacityBased)
    );
    let IsValid = true;
    let from = this.priceingCapacityBasedForm.value.from;
    let to = this.priceingCapacityBasedForm.value.to;
    if (
      this.PricingCapUpdateRowIndex != undefined &&
      this.PricingCapUpdateRowIndex != undefined
    ) {
      TempPricingCapacity.splice(this.PricingCapUpdateRowIndex, 1);
    }
    TempPricingCapacity.forEach((element) => {
      if (
        (from >= element.from && from <= element.to) ||
        (to >= element.from && to <= element.to) ||
        (from <= element.from && to >= element.to)
      ) {
        IsValid = false;
        this.notify.warning("Range ovelap");
      }
    });
    return IsValid;
  }
  AssetTypeNameBlur(event) {
    if (event.target.value != undefined && event.target.value != "") {
      this.coreDataService
        .ValidateAssetTypeName(
          this.assetTypeModel.assetTypeID,
          event.target.value
        )
        .subscribe((res) => {
          if (res != null && res != undefined && res.length > 0) {
            this.assetTypeModel.assetTypeName = null;
            this.assetTypeModel.assetTypeName = null;
            this.notify.error(this.ErrorMsgData.assetTypeExist);
          }
        });
    }
  }

  rangeValidator() {
    let from, to;
    from = this.priceingCapacityBasedForm.value.from;
    to = this.priceingCapacityBasedForm.value.to;
    if ((from > to || from == to) && to != null && to != undefined) {
      this.priceingCapacityBasedForm.controls["to"].setValue("");

      this.notify.warning(this.ErrorMsgData.valueInform);
    }
  }
  back() {
    this.router.navigate(["/asset-type-management"]);
  }

  cancelledClick() {
    this.isCancelclicked = true;
  }
  public close() {
    this.isCancelclicked = false;
  }

  updatePricing(DataItem, rowIndex) {
    this.priceingCapacityBasedForm.controls["to"].setValue(DataItem.to);
    this.priceingCapacityBasedForm.controls["cost"].setValue(DataItem.cost);
    this.priceingCapacityBasedForm.controls["baseRate"].setValue(
      DataItem.baseRate
    );
    this.priceingCapacityBasedForm.controls["size"].setValue(DataItem.size);
    this.priceingCapacityBasedForm.controls["from"].setValue(DataItem.from);
    this.PricingCapUpdateRowIndex = rowIndex;
    this.capacityAddButton = false;
  }
  UpdatePricingCapacity() {
    if (
      this.PricingCapUpdateRowIndex != undefined &&
      this.PricingCapUpdateRowIndex != null
    ) {
      if (this.priceingCapacityBasedForm.valid) {
        if (this.priceCapacityBased.length > 0) {
          let rangeIsValid = this.checkValidationToForm();

          this.capacityBased = new priceCapacityBased();

          let sizeExist = this.priceCapacityBasedValidater();
          if (!sizeExist && rangeIsValid) {
            for (var i = 0; i < this.priceCapacityBased.length; i++) {
              if (i == this.PricingCapUpdateRowIndex) {
                this.priceCapacityBased[i].from =
                  this.priceingCapacityBasedForm.value.from;
                this.priceCapacityBased[i].to =
                  this.priceingCapacityBasedForm.value.to;
                this.priceCapacityBased[i].cost =
                  this.priceingCapacityBasedForm.value.cost;
                if (this.PricingCapUpdateRowIndex == 0) {
                  this.priceCapacityBased[i].baseRate =
                    this.priceingCapacityBasedForm.value.baseRate;
                }
                this.priceCapacityBased[i].size =
                  this.priceingCapacityBasedForm.value.size;
              }
            }
            this.PricingCapUpdateRowIndex = undefined;
            this.priceingCapacityBasedForm.reset();
            this.calculateCapacityPrice();
          } else {
            this.notify.warning(this.ErrorMsgData.sizeUsedCapacity);
          }
        } else {
          for (var i = 0; i < this.priceCapacityBased.length; i++) {
            if (i == this.PricingCapUpdateRowIndex) {
              this.priceCapacityBased[i].from =
                this.priceingCapacityBasedForm.value.from;
              this.priceCapacityBased[i].to =
                this.priceingCapacityBasedForm.value.to;
              this.priceCapacityBased[i].cost =
                this.priceingCapacityBasedForm.value.cost;
              if (this.PricingCapUpdateRowIndex == 0) {
                this.priceCapacityBased[i].baseRate =
                  this.priceingCapacityBasedForm.value.baseRate;
              }
              this.priceCapacityBased[i].size =
                this.priceingCapacityBasedForm.value.size;
            }
          }
          this.PricingCapUpdateRowIndex = undefined;
          this.priceingCapacityBasedForm.reset();
          this.calculateCapacityPrice();
          const baseRateCtr = this.priceingCapacityBasedForm.get("baseRate");
          baseRateCtr.clearValidators();
          baseRateCtr.updateValueAndValidity();
        }
      } else {
        this.notify.error(this.ErrorMsgData.emptyField);
      }
    }
  }
  AssetTypeRM() {
    let NewAssetTypeRmList=new AssetTypeRMModel();
    if (this.activatedRoute.snapshot.paramMap.get("assetTypeID") != null) {
      this.coreDataService
        .GetAssetTypeRM(
          this.activatedRoute.snapshot.paramMap.get("assetTypeID")
        )

        .subscribe(
          (response) => {
            response.forEach((row) => {
              NewAssetTypeRmList=new AssetTypeRMModel();
              NewAssetTypeRmList.assetTypeRMID=row.assetTypeRMID
              NewAssetTypeRmList.assetTypeID=row.assetTypeID
              NewAssetTypeRmList.description=row.description
              NewAssetTypeRmList.fixedCost=row.fixedCost
              NewAssetTypeRmList.frequency=row.frequency
              NewAssetTypeRmList.installCost=row.installCost
              NewAssetTypeRmList.installMultiplier=row.installMultiplier
              NewAssetTypeRmList.rmItemCD=row.rmItemCD
              NewAssetTypeRmList.supplyCosts=row.supplyCosts
             
            
              row.rMPricingCapacityDetailsModel .forEach((element) => {
                var selectedSize = new CodeData();
                selectedSize = this.ddlSize.find(function (obj) {
                  return obj.codeID === element.sizeCd;
                });
                let objcapacityBased = new PriceCapacityBasedRM();
                objcapacityBased.rMPricingCapacityID  = element.rmPricingCapacityID                ;
                objcapacityBased.assetTypeRMID  = element.assetTypeRMID                ;
                objcapacityBased.from = element.from;
                objcapacityBased.to = element.to;
                objcapacityBased.cost = element.cost;
                objcapacityBased.baseRate = element.baseRate;
                if (element.sizeCd != undefined && element.sizeCd > 0) {
                  objcapacityBased.size = {
                    codeID: element.sizeCd,
                    codeValue: selectedSize.codeValue,
                  };
                } else {
                  objcapacityBased.size = null;
                }
              console.log(objcapacityBased)
                NewAssetTypeRmList.priceCapacityBasedRM.push(objcapacityBased);
              });
             
              this.AssetTypeRmList.push(NewAssetTypeRmList);
            });
            this.gridDataRM = process(
              this.AssetTypeRmList.reverse(),
              this.stateRm
            );

            // this.FilterGridByCharacter("All")
          },
          (catchError) => {
            if (catchError) {
              this.toastrService.error(
                "error" + catchError.status + " " + catchError["message"]
              );
            }
          }
        );
    }
  }

  public dataStateChangeRM(state: DataStateChangeEvent): void {
    this.stateRm = state;
    this.gridDataRM = process(this.AssetTypeRmList, this.stateRm);
  }
 
  onSelect($event) {
this.HideShowCapacity=true
this.priceCapacityBasedRM=$event.dataItem.priceCapacityBasedRM

  }
  addHandler({ sender }) {
    this.isRowOpen = true;
    sender.addRow(this.formGroupRM);
  }
  editHandler({ sender, rowIndex, dataItem }) {
    this.isRowOpen = true;
    this.assetTypeModel.supplyCostsRM=dataItem.supplyCosts
    this.assetTypeModel.installMultiplierRM=dataItem.installMultiplier
    this.assetTypeModel.installCostRM=dataItem.installCost
    this.assetTypeModel.fixedCostRM=dataItem.fixedCost
    this.ItemRMDropdown.forEach((Prow) => {
      if (dataItem.rmItemCD === Prow.codeValue) {
        dataItem.rmItemCD = Prow.codeID;
      }
    });

    this.formGroupRM = formGroupRM(dataItem);
    sender.editRow(rowIndex, this.formGroupRM);
  }
  cancelHandler({ sender, rowIndex }) {
    this.AssetTypeRmList.forEach((row) => {
      this.ItemRMDropdown.forEach((Prow) => {
        if (Number(row.rmItemCD) == Prow.codeID) {
          row.rmItemCD = Prow.codeValue;
        }
      });

      this.isRowOpen = false;
    });
    this.assetTypeModel.supplyCostsRM=0;
    this.assetTypeModel.installMultiplierRM=0;
    this.assetTypeModel.installCostRM=0;
    this.assetTypeModel.fixedCostRM=0
    this.gridData = process(this.AssetTypeRmList, this.state);
    this.closeEditor(sender, rowIndex);
  }
  saveHandler({ sender, rowIndex, dataItem, formGroup, isNew }) {
    if (!/^(0|[1-9]\d*)$/.test(formGroup.value.frequency)) {
      this.notify.error(this.ErrorMsgData.negativeDecimalValueError);
      return;
    }
    var num: Number = 0;
    formGroup.value.assetTypeID =
      this.activatedRoute.snapshot.paramMap.get("assetTypeID");

    this.AssetTypeRMModel.assetTypeID = formGroup.value.assetTypeID;
    this.AssetTypeRMModel.assetTypeRMID = formGroup.value.assetTypeRMID;
    this.AssetTypeRMModel.supplyCosts = formGroup.value.supplyCosts;
    this.AssetTypeRMModel.installMultiplier = formGroup.value.installMultiplier;
    this.AssetTypeRMModel.installCost = formGroup.value.installCost;
    this.AssetTypeRMModel.fixedCost = formGroup.value.fixedCost;
    this.AssetTypeRMModel.rmItemCD = formGroup.value.rmItemCD;
    this.AssetTypeRMModel.frequency = formGroup.value.frequency;
   this.AssetTypeRMModel.priceCapacityBasedRM=formGroup.value.priceCapacityBasedRM;
    this.AssetTypeRMModel.updatedBy = formGroup.value.updatedBy;
    this.AssetTypeRMModel.updationDate = formGroup.value.updationDate;

    this.AssetTypeRMModel.description = formGroup.value.description;
    this.AssetTypeRMModel.creationDate = formGroup.value.creationDate;
    this.AssetTypeRMModel.createdBy = formGroup.value.createdBy;
  

    this.ItemRMDropdown.forEach((Prow) => {
      if (Number(this.AssetTypeRMModel.rmItemCD) === Prow.codeID) {
        this.AssetTypeRMModel.rmItemCD = Prow.codeValue;
      }
    });

    if (formGroup.status == "VALID") {
      //this.saveLocationRM(formGroup.value)

      sender.closeRow(rowIndex);

      if (isNew) {
        this.AssetTypeRmList.push(this.AssetTypeRMModel);
        this.gridDataRM = process(this.AssetTypeRmList.reverse(), this.stateRm);
      } else {
        this.AssetTypeRmList = this.AssetTypeRmList.map((t, index) =>
          index !== rowIndex ? t : this.AssetTypeRMModel
        );
        this.gridDataRM = process(this.AssetTypeRmList, this.stateRm);
      }

      this.AssetTypeRMModel = new AssetTypeRMModel();
      this.formGroupRM = formGroupRM(new AssetTypeRMModel());

      this.isRowOpen = false;
    } else this.notify.error(this.ErrorMsgData.rmError);
    //this.notify.error("error")
    this.assetTypeModel.supplyCostsRM=0;
    this.assetTypeModel.installMultiplierRM=0;
    this.assetTypeModel.installCostRM=0;
    this.assetTypeModel.fixedCostRM=0
  }
  RemoveHandler(dataItem) {
    if (dataItem.dataItem.assetTypeRMID != null) {
      this.DeleteAssetTypeRM(dataItem.dataItem.assetTypeRMID);
    }

    this.AssetTypeRmList.forEach((element, index) => {
      if (index == dataItem.rowIndex) this.AssetTypeRmList.splice(index, 1);
    });

    this.gridDataRM = process(this.AssetTypeRmList, this.stateRm);
    this.formGroupRM = formGroupRM(new AssetTypeRMModel());
    this.isRowOpen = false;
  }

  closeEditor(grid, rowIndex) {
    grid.closeRow(rowIndex);
    this.isRowOpen = false;
    this.formGroupRM = formGroupRM(new AssetTypeRMModel());
  }

  DeleteAssetTypeRM(id) {
    this.coreDataService.DeleteAssetTypeRMbyID(id).subscribe(
      (res) => {
        this.notify.success(this.ErrorMsgData.deletedSuccessfully);
      },
      (catchError) => {
        this.notify.warning(this.ErrorMsgData.SomethingWentWrong);
      }
    );
  }
  getorgDetails(data) {
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        
        this.GridCurrencyChange = res.Currency;
        this.currencySymbol = res.CurrencySymbol;
      }
    });
  }

  // priceCapacityBasedRM(){

  // }

  addGrideCapacityBasedRM() {
    // this.rangeValidator();
    this.capacityAddButtonRM = true;
    this.capacityBasedRM = new PriceCapacityBasedRM();
    if (this.priceingCapacityBasedFormRM.valid) {
      if (this.priceCapacityBasedRM.length > 0) {
        let rangeIsValid = this.checkValidationToFormRM();

       

        let sizeExist = this.priceCapacityBasedValidaterRM();
        if (!sizeExist && rangeIsValid) {
          this.capacityBasedRM.from = this.priceingCapacityBasedFormRM.value.from;
          this.capacityBasedRM.to = this.priceingCapacityBasedFormRM.value.to;
          this.capacityBasedRM.cost = this.priceingCapacityBasedFormRM.value.cost;
          this.capacityBasedRM.baseRate =
            this.priceingCapacityBasedFormRM.value.baseRate;
          this.capacityBasedRM.size = this.priceingCapacityBasedFormRM.value.size;
          this.priceCapacityBasedRM.push(this.capacityBasedRM);
         
          this.calculateCapacityPriceRM();
          this.priceingCapacityBasedFormRM.reset();
        } else {
          this.notify.warning(this.ErrorMsgData.sizeUsedCapacity);
        }
      } else {
        this.capacityBasedRM.from = this.priceingCapacityBasedFormRM.value.from;
        this.capacityBasedRM.to = this.priceingCapacityBasedFormRM.value.to;
        this.capacityBasedRM.cost = this.priceingCapacityBasedFormRM.value.cost;
        this.capacityBasedRM.baseRate =
          this.priceingCapacityBasedFormRM.value.baseRate;
        this.capacityBasedRM.size = this.priceingCapacityBasedFormRM.value.size;
        this.priceCapacityBasedRM.push(this.capacityBasedRM);
       
        const baseRateCtr = this.priceingCapacityBasedFormRM.get("baseRate");
        baseRateCtr.clearValidators();
        baseRateCtr.updateValueAndValidity();
        this.calculateCapacityPriceRM();
        this.priceingCapacityBasedFormRM.reset();
      }
    } else {
      this.notify.error(this.ErrorMsgData.emptyField);
    }
    console.log(this.AssetTypeRmList)
  }
  UpdatePricingCapacityRM() {
       this.capacityBasedRM = new PriceCapacityBasedRM();
    if (
      this.PricingCapUpdateRowIndexRM != undefined &&
      this.PricingCapUpdateRowIndexRM != null
    ) {
      if (this.priceingCapacityBasedFormRM.valid) {
        if (this.priceCapacityBasedRM.length > 0) {
          let rangeIsValid = this.checkValidationToFormRM();

       

          let sizeExist = this.priceCapacityBasedValidaterRM();
          if (!sizeExist && rangeIsValid) {
            for (var i = 0; i < this.priceCapacityBasedRM.length; i++) {
              if (i == this.PricingCapUpdateRowIndexRM) {
                this.priceCapacityBasedRM[i].from =
                  this.priceingCapacityBasedFormRM.value.from;
                this.priceCapacityBasedRM[i].to =
                  this.priceingCapacityBasedFormRM.value.to;
                this.priceCapacityBasedRM[i].cost =
                  this.priceingCapacityBasedFormRM.value.cost;
                if (this.PricingCapUpdateRowIndexRM== 0) {
                  this.priceCapacityBasedRM[i].baseRate =
                    this.priceingCapacityBasedFormRM.value.baseRate;
                }
                this.priceCapacityBasedRM[i].size =
                  this.priceingCapacityBasedFormRM.value.size;
              }
            }
            this.PricingCapUpdateRowIndexRM = undefined;
            this.priceingCapacityBasedFormRM.reset();
            this.calculateCapacityPriceRM();
          } else {
            this.notify.warning(this.ErrorMsgData.sizeUsedCapacity);
          }
        } else {
          for (var i = 0; i < this.priceCapacityBasedRM.length; i++) {
            if (i == this.PricingCapUpdateRowIndexRM) {
              this.priceCapacityBasedRM[i].from =
                this.priceingCapacityBasedFormRM.value.from;
              this.priceCapacityBasedRM[i].to =
                this.priceingCapacityBasedFormRM.value.to;
              this.priceCapacityBasedRM[i].cost =
                this.priceingCapacityBasedFormRM.value.cost;
              if (this.PricingCapUpdateRowIndexRM == 0) {
                this.priceCapacityBasedRM[i].baseRate =
                  this.priceingCapacityBasedFormRM.value.baseRate;
              }
              this.priceCapacityBasedRM[i].size =
                this.priceingCapacityBasedFormRM.value.size;
            }
          }
          this.PricingCapUpdateRowIndexRM = undefined;
          this.priceingCapacityBasedFormRM.reset();
          this.calculateCapacityPriceRM();
          const baseRateCtr = this.priceingCapacityBasedFormRM.get("baseRate");
          baseRateCtr.clearValidators();
          baseRateCtr.updateValueAndValidity();
        }
      } else {
        this.notify.error(this.ErrorMsgData.emptyField);
      }
    }
  }
  calculateFixCostRM() {
    let supplyCost =
      this.assetTypeModel.supplyCostsRM != undefined
        ? this.assetTypeModel.supplyCostsRM
        : 0;
    let installMultiplier =
      this.assetTypeModel.installMultiplierRM != undefined
        ? this.assetTypeModel.installMultiplierRM
        : 0;
    let installCost = installMultiplier * supplyCost;
    this.assetTypeModel.installCostRM = installCost;
    this.assetTypeModel.fixedCostRM = installCost + supplyCost;
  }
  updatePricingRM(DataItem, rowIndex) {
    this.priceingCapacityBasedFormRM.controls["to"].setValue(DataItem.to);
    this.priceingCapacityBasedFormRM.controls["cost"].setValue(DataItem.cost);
    this.priceingCapacityBasedFormRM.controls["baseRate"].setValue(
      DataItem.baseRate
    );
    this.priceingCapacityBasedFormRM.controls["size"].setValue(DataItem.size);
    this.priceingCapacityBasedFormRM.controls["from"].setValue(DataItem.from);
    this.PricingCapUpdateRowIndexRM = rowIndex;
    this.capacityAddButtonRM = false;
  }
  priceCapacityBasedValidaterRM(): boolean {
    var TempPricingCapacity: any = JSON.parse(
      JSON.stringify(this.priceCapacityBasedRM)
    );
    if (
      this.PricingCapUpdateRowIndexRM != undefined &&
      this.PricingCapUpdateRowIndexRM != undefined
    ) {
      TempPricingCapacity.splice(this.PricingCapUpdateRowIndexRM, 1);
    }
    let selectedSize =
      this.priceingCapacityBasedFormRM.value.size == null
        ? -1
        : this.priceingCapacityBasedFormRM.value.size.codeID == null
        ? -1
        : this.priceingCapacityBasedFormRM.value.size.codeID;
    if (selectedSize > 0) {
      var res = TempPricingCapacity.find(function (Obj) {
        if (Obj.size != undefined && Obj.size != null)
          return Obj.size.codeID == selectedSize;
      });
      if (res != null && res != undefined && res.size != null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  calculateCapacityPriceRM() {
    this.priceCapacityBasedRM.sort(function (obj1, obj2) {
      return obj1.from - obj2.from;
    });
    for (let i = 0; i < this.priceCapacityBasedRM.length; i++) {
      let baseRate;
      let previousUpperCapacity;

      if (i > 0) {
        let unitRate = this.priceCapacityBasedRM[i - 1].cost;
        let previousLowerCapacity = this.priceCapacityBasedRM[i - 1].from;
        previousUpperCapacity = this.priceCapacityBasedRM[i - 1].to;
        baseRate = this.priceCapacityBasedRM[i - 1].baseRate;
        this.priceCapacityBasedRM[i].baseRate =
          baseRate + unitRate * (previousUpperCapacity - previousLowerCapacity);
      } else {
        baseRate = this.capacityBasedRM.baseRate;
        previousUpperCapacity = 0;
      }
    }
  }
  checkValidationToFormRM(): boolean {
    var TempPricingCapacity: any = JSON.parse(
      JSON.stringify(this.priceCapacityBasedRM)
    );
    let IsValid = true;
    let from = this.priceingCapacityBasedFormRM.value.from;
    let to = this.priceingCapacityBasedFormRM.value.to;
    if (
      this.PricingCapUpdateRowIndexRM != undefined &&
      this.PricingCapUpdateRowIndexRM != undefined
    ) {
      TempPricingCapacity.splice(this.PricingCapUpdateRowIndexRM, 1);
    }
    TempPricingCapacity.forEach((element) => {
      if (
        (from >= element.from && from <= element.to) ||
        (to >= element.from && to <= element.to) ||
        (from <= element.from && to >= element.to)
      ) {
        IsValid = false;
        this.notify.warning("Range ovelap");
      }
    });
    return IsValid;
  }
  IsDisableRM() {
    if (
      this.PricingCapUpdateRowIndexRM == 0 ||
      this.priceCapacityBasedRM.length == 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  rangeValidatorRM() {
    let from, to;
    from = this.priceingCapacityBasedFormRM.value.from;
    to = this.priceingCapacityBasedFormRM.value.to;
    if ((from > to || from == to) && to != null && to != undefined) {
      this.priceingCapacityBasedFormRM.controls["to"].setValue("");

      this.notify.warning(this.ErrorMsgData.valueInform);
    }
  }
  public CapacityBasedopenPopUpRM(dataItem,rowIndex) {
    this.rowIndexTempRM = rowIndex;
    this.deleteOpendBtnPricingRM = true;
  }
  public CapacityBasedclosePopUpRM() {
    this.rowIndexTempRM = undefined;
    this.deleteOpendBtnPricingRM = false;
  }
  DeleteYesPopUpRM() {
    this.deleteGrideCapacityBasedRM(this.rowIndexTempRM);
    this.deleteOpendBtnPricingRM = false;
  }
  deleteGrideCapacityBasedRM(rowIndex) {
    if (rowIndex != undefined) {
     
      this.priceCapacityBasedRM.forEach((row,index)=>{
        if(index==rowIndex  && row.rMPricingCapacityID!=undefined){
          this.coreDataService.DeleteRMPricingCapacityByID(row.rMPricingCapacityID).subscribe(
            (res) => {
              this.notify.success(this.ErrorMsgData.deletedSuccessfully);
            },
            (catchError) => {
              this.notify.warning(this.ErrorMsgData.SomethingWentWrong);
            }
          );
         
        }
      })
      this.priceCapacityBasedRM.splice(rowIndex, 1);
    }
  }
  
}
