import { Component, OnInit, ɵConsole, ChangeDetectorRef } from '@angular/core';
import { codeTypeModel, codeTypeList, codeManagementTypeModel, codeTypeGridList, DefaultNewCodeManagementRow } from '../code-management/code-management.model'
import { CoreDataService } from '../../Service/core-data.service';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { SnotifyService } from 'ng-snotify';
import {
  process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor,
  CompositeFilterDescriptor
} from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-code-management',
  templateUrl: './code-management.component.html',
  styleUrls: ['./code-management.component.scss']
})
export class CodeManagementComponent implements OnInit {
  public page: 1
  skip = 0
  codeTypeListing: Array<codeTypeList>
  public pageSize = 70;
  public codeState: State = {
    skip: 0,
    take: 10
  };
  count: number;
  public gridData: GridDataResult;
  // public sortChange(sort: SortDescriptor[]): void {
  //   this.gridData = process(orderBy(this.codeTypeData, sort), this.codeState);
  // }
  public itemToRemove: any;
  public dataSource = [{
    page: 1
  }]
  // public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public editedRowIndex: number;
  LanguageConfig: any;
  IsRowInEdit: boolean = false;
  HideDeleteInEdit: boolean = true;
  IsSystem: boolean = false;
  rowIndexTemp = null;
  rowIndexSelected = undefined;
  select: boolean = false;
  selected: Array<any> = [];
  public deleteOpened: boolean = false;
  public isCancelclicked: boolean = false;
  GridConfig: any;
  ErrorDataMsg: any;
  //gridData: Array<any> = [];
  currentPage = 1;
  CMDData: Array<codeManagementTypeModel> = new Array<codeManagementTypeModel>();
  codeTypeData: Array<codeTypeList> = new Array();

  //codeTypetList:Array<codeTypeGridList> = new Array();
  codeTypeModel: codeManagementTypeModel = new codeManagementTypeModel();
  DefaultNewCodeManagementRow: DefaultNewCodeManagementRow = new DefaultNewCodeManagementRow()
  // public editedProduct: codeManagementList;
  // codeDataModel: codeManagementList = new codeManagementList();
  public ddlCodeManagement: { codeTypeID: number, codeName: string, description: string, isSystem: boolean, displayName: string } = { codeTypeID: undefined, codeName: "select", description: undefined, isSystem: false, displayName: "Select" }

  public selectedCodeData: { codeId: number, codeValue: string };
  public editedCodeData: Array<any>;
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.codeTypeData, this.filter);
  //public gridData: GridDataResult = process(this.codeTypeData, this.codeState);
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.codeTypeData, sort), this.codeState);
  }

  constructor(private coreDataService: CoreDataService, private sharedDataService: SharedDataService,
    private notify: SnotifyService, private ref: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private titleService: Title) {
    // this.removeConfirmation = this.removeConfirmation.bind(this);

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[24];
          this.ErrorDataMsg = res[28];
          this.GridConfig = res[2];
        }
      })
    }

    this.getCodeTypeDropdown();

  }
  ngAfterViewInit() {
    // Retrieve the object from storage
    const retrievedState = localStorage.getItem('kendoGridState');
    if (retrievedState) {
      console.log(JSON.parse(retrievedState))
      setTimeout(() => this.codeState = JSON.parse(retrievedState));
    }
  }

  //Code Data Droodown : Get Data
  getCodeTypeDropdown() {
    this.coreDataService.getCodeManagementDropDown().subscribe((res) => {
      if (res != undefined && res != null) {
        this.CMDData = res;
      }
    })
  }
  changeCodeType(value) {
    if (value != undefined && value != null && value.codeTypeID != undefined && value.codeTypeID != null) {
      //console.log(value)
      //let codeTypeID = value.codeTypeID;
      this.codeTypeModel.description = value.description;
      this.codeTypeModel.isSystem = value.isSystem;
      // this.ddlCodeManagement.codeName =value.selected;
      this.coreDataService.GetCodeManagementDetailsByID(value.codeTypeID).first()
        .subscribe((data) => {
          if (data != undefined && data != null) {
            this.codeTypeData = JSON.parse(JSON.stringify(data));
            this.count = this.codeTypeData.length;
            this.codeState.skip = 0;
            this.codeState.take = 10;
            this.gridData = JSON.parse(JSON.stringify(process(this.codeTypeData, this.codeState)))

          }
        });
      error => {
        this.sharedDataService.showError(this.ErrorDataMsg.noDataFound);
      };
    }
    else {
      this.reset();
      // this.sharedDataService.showError("Invalid Code Type Selected");
    }
  }

  //HANDLERS FOR IN LINE EDITING
  addHandler({ sender }) {
    // console.log(sender['add'])
    sender.addRow(new DefaultNewCodeManagementRow());
  }
  editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.DefaultNewCodeManagementRow);
  }
  saveHandler({ sender, rowIndex, dataItem, isNew }) {


    if (rowIndex == -1) {
      let found = this.codeTypeData.some(el => el.codeValue.toLowerCase() === dataItem.codeValue.toLowerCase());
      if (found) {
        this.notify.error(this.ErrorDataMsg.codeNameExist);
        return
      }
    }
    else {
      let found = this.codeTypeData.some(el => el.codeID !== dataItem.codeID && el.codeValue.toLowerCase() === dataItem.codeValue.toLowerCase());
      if (found) {
        this.notify.error(this.ErrorDataMsg.codeNameExist);
        return
      }
    }

    if (isNew) {
      this.codeTypeData.push({ codeID: undefined, codeTypeID: this.codeTypeModel.codeTypeID, codeValue: dataItem.codeValue, sequence: dataItem.sequence, weightage: dataItem.weightage })
    }
    else {
      this.codeTypeData[rowIndex] = dataItem
    }




    sender.closeRow(rowIndex);
    this.refreshGrid();
  }


  removeHandler($event) {
    // console.log($event)
    this.refreshGrid();
  }
  cancelHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender, rowIndex);
    this.refreshGrid();
  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.DefaultNewCodeManagementRow = undefined;
  }

  //HANDLERS FOR IN LINE EDITING


  // save code management data:Post call
  saveCodeManagedData() {
    if (this.codeTypeModel != undefined && this.codeTypeModel != null && this.codeTypeModel.codeTypeID != undefined && this.codeTypeModel.codeTypeID != null) {

      if (this.IsRowInEdit == false) {
        this.coreDataService.SaveCodeManagementGrid({ 'CodeData': this.codeTypeData, 'codeTypeID': this.codeTypeModel.codeTypeID }).subscribe((data) => {
          if (data != undefined) {
            this.reset();
            this.notify.success(this.ErrorDataMsg.codeSavedSuccess);
          }
        })
      }

      else {
        this.notify.warning(this.ErrorDataMsg.rowEditMode);
      }

    } else {
      this.notify.warning(this.ErrorDataMsg.selectCode);
    }
  }
  //Edited cancel row
  public cancelRow() {
    this.IsRowInEdit = false;
  }
  // updated row
  public upDatedRow() {
    this.IsRowInEdit = false;
  }
  // edit row
  public editRow() {
    this.IsRowInEdit = true;
    this.HideDeleteInEdit = false;
  }

  public DeleteOpenPopUp(rowIndex) {
    this.rowIndexSelected = rowIndex;
    this.deleteOpened = true;
  }
  codeTypeDeleteRow() {
    if (this.rowIndexSelected != undefined) {
      let CurrDeleteIndex = this.codeTypeData.findIndex(item => item.codeID === this.rowIndexSelected.codeID);
      if (CurrDeleteIndex != -1) {
        this.codeTypeData.splice(CurrDeleteIndex, 1)
        if (this.codeState.filter != undefined) {
          this.codeState.filter.filters = [];
        }
        this.refreshGrid();
      }

    }

    this.deleteOpened = false;

  }
  public codeTypeclosePopUp() {
    this.rowIndexSelected = undefined;
    this.deleteOpened = false;

  }
  public refreshGrid() {
    // update the data collection reference
    // in this case the data is the same, so there is no visible change
    this.gridData = JSON.parse(JSON.stringify(process(this.codeTypeData, this.codeState)))

  }
  reset() {
    this.codeTypeModel = this.ddlCodeManagement;
    this.codeTypeData = new Array<codeTypeList>();

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.codeState = state;
    this.gridData = JSON.parse(JSON.stringify(process(this.codeTypeData, this.codeState)))
  }


}
