
export class commonFilterEntity {
    public customerID?: number;
    public assetTypeID?: string;
    public conditionRating: string;
    public status: string;
    public criticality: string;
    public assetOwnership: string;
    public filtersFitted: string;
    public refrigerant: string;
    public assetGroup: string;
    public ageGroup: string
    public remainingLife: string;
    public sortBy: string;
    public OHSComments?: number;
    public serviceCalls: string
}
export class commonFilterListEntity {
    public serviceCallID: string;
    public CFMAgeGroupID: string;
    public remaningLifeID: string;
    public replacementCostID: string;
    public replacementYearModelID: string;
    public shortByID: string;
    public OHSCommentID: string;
}

export class reportFilterTemplate {
    public FieldTemplate: any;
    public FilterTemplateName: string;
}
export class CustomReport{
    public  customReportID:number;
    public userID:number 
    public  reportName:string
    public  reportType:string

    public  fieldTemplate:any
    public  filterTemplate:any



}
