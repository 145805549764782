import { SnotifyService } from 'ng-snotify';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { unitofMeasurmentListingEntity } from '../unit-of-measurment-listing/unit-of-measurment-listing.model'
import { CoreDataService } from '../../Service/core-data.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";

import { SharedDataService } from '../../Service/CommonUtility.service';
const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-unit-of-measurment-listing',
  templateUrl: './unit-of-measurment-listing.component.html',
  styleUrls: ['./unit-of-measurment-listing.component.scss']
})
export class UnitOfMeasurmentListingComponent implements OnInit {
  public dialogOpened = false;
  uomTypeID: number
  LanguageConfig: any;
  UserData: any;
  GridConfig: any
  uomList: Array<unitofMeasurmentListingEntity> = [];
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
  };
  public filter: CompositeFilterDescriptor;
  public gridfilter: Array<unitofMeasurmentListingEntity> = filterBy(this.uomList, this.filter);
  public gridData: GridDataResult;
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.uomList, sort), this.state);
  }
  constructor(private titleService: Title, private sharedService: SharedDataService,
    private activatedRoute: ActivatedRoute, private notify: SnotifyService, private coreDataService: CoreDataService, private router: Router) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {
    // if(this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null){
    //   let langLiteral = this.sharedService.GetLanguageLiteral();
    //   this.LanguageConfig = new LanguageConfig();
    //   this.LanguageConfig.Labels = PageData[langLiteral];
    //   this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
    //   this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
    // }
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[22];
          this.GridConfig = res[2];

        }
      })
      var token = window.localStorage.getItem('token');
      this.UserData = this.sharedService.parseJwt(token);
    }
    this.getUOMList();
  }

  getUOMList() {

    this.coreDataService.GetUOMList().subscribe((res) => {
      if (res != undefined && res != null) {
        this.uomList = res;

        this.gridData = process(res, this.state);
      }
    })
  }
  MenuOnSelect(event) {
    // var baseUrl = window.location.origin + '/#/unit-of-measurment' + event.dataItem.uomTypeID;
    var baseUrl = window.location.origin + '/unit-of-measurment' + event.dataItem.uomTypeID;

    if (event.item == "Open") {
      // this.getQuoteDetail(event.dataItem.QuoteID);
      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }
  editUOMType(value) {
    if (value != null && value != undefined) {
      this.uomTypeID = value.uomTypeID;
      this.router.navigate(["/unit-of-measurment/" + this.uomTypeID])
    }
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.uomList, this.state);
  }
}
