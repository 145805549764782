import { SnotifyService } from 'ng-snotify';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { projectProgressReport, CommonFilterEntity, PortfolioReportEntity, AssetReportFieldTemplateModel, QueueReportEntity, SchedulerReportEntity, ScheduleList } from '../New Report/reports.model';
import { CoreDataService } from '../../Service/core-data.service';
import { aggregateBy } from '@progress/kendo-data-query';
import { CommonFilterComponent } from '../../Common/CommonFilter/commonFilter.component';
import { SharedDataService, ReportFilter } from '../../Service/CommonUtility.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { from, BehaviorSubject } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelService } from '../../Service/excel.service';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { JsonPipe } from '@angular/common';

import { CustomReport, reportFilterTemplate } from 'src/app/Common/CommonFilter/commonFilter.model';
import { ReportOptionsComponent } from 'src/app/Common/report-options/report-options.component';




@Component({
  selector: 'admin-dashboard',
  templateUrl: 'reports.component.html',

  styleUrls: ['reports.component.scss'],


})
export class NewReportsComponent implements OnInit {


  dialogOpened = false;
  template_id: any;
  opened: boolean = false;
  show = false;
  GridConfig: any;
  commonFilterEntity: CommonFilterEntity = new CommonFilterEntity();
  SchedulerReportEntity: SchedulerReportEntity = new SchedulerReportEntity();

  AssetReportFieldTemplate: AssetReportFieldTemplateModel = new AssetReportFieldTemplateModel();

  public aggregates: any[] = [{ field: 'baselineValue', aggregate: 'sum' }, { field: 'actualValue', aggregate: 'sum' }, { field: 'subAssetBaselineValue', aggregate: 'sum' }, { field: 'subAssetActualvalue', aggregate: 'sum' }];
  GDActual: number;
  GDTender: number;
  GDActualTender: number;

  progressRepootModel: Array<projectProgressReport> = new Array<projectProgressReport>();
  QueueReportEntity: Array<QueueReportEntity> = new Array<QueueReportEntity>();
  ScheduleList: Array<ScheduleList> = new Array<ScheduleList>();

  public total: any = aggregateBy(this.progressRepootModel, this.aggregates);
  EXCEL_TYPE = 'application/vnd.ms-excel;charset=utf-8';
  EXCEL_EXTENSION = '.xlsx'
  public group: any[] = [{
    field: 'locationName',
    aggregates: this.aggregates
  }];
  customReport: CustomReport = new CustomReport();
  customReportList: Array<CustomReport> = [];

  public openedFieldChooser: boolean = false;
  public listItems: Array<string> = ["PDF", "DOC"];
  PortfoliocheckMode: string = "PDF";
  ErrorMsgData: any;
  Customerdata: any;
  LocationData: any;
  templateData: any;
  subtemplateData: any;
  sub: any;
  data: any;
  assetFieldsList: any[];
  assetFieldsListTemp: any[];
  AssetFields: any[];
  public FilteredLocationDropdown: Array<{ name: string; locationID: number }> =
    [];

  subassetFieldsList: any[];
  subassetFieldsListTemp: any[];
  SubAssetFields: any[];
  public CustomerDropdown: Array<{
    customerName: string;
    customerID: number;
    isBusinessUnit: boolean;
  }> = [];
  public LocationDropdown: Array<{ name: string; locationID: number }> = [];

  portfolioReportEntity: PortfolioReportEntity = new PortfolioReportEntity();
  EnableFieldButton: boolean = true;
  IsCustomerChange: boolean = false;
  IsLocationChange: boolean = false;
  IsAppliedField: boolean = false;
  IsInactiveLocationSelected:boolean=false;
  ReportType: string;
  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public state1: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  IsOpenScheduler = false;
  public Schedulerange = { start: new Date(), end: null };
  Duration: string;
  

  public WeeklyListItems: Array<{ text: string, value: number }> = [
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 }
  ];
  public MonthlyListItems: Array<{ text: string, value: number }> = [
    { text: "Janurary", value: 1 },
    { text: "Feburary", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ];

  public WeekListItems: Array<{ text: string, value: number }> = [
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thurday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
    { text: "Sunday", value: 7 }
  ];

  public DaysListItems: Array<{ text: string, value: any }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
    { text: "13", value: 13 },
    { text: "14", value: 14 },
    { text: "15", value: 15 },
    { text: "16", value: 16 },
    { text: "17", value: 17 },
    { text: "18", value: 18 },
    { text: "19", value: 19 },
    { text: "20", value: 20 },
    { text: "21", value: 21 },
    { text: "22", value: 22 },
    { text: "23", value: 23 },
    { text: "24", value: 24 },
    { text: "25", value: 25 },
    { text: "26", value: 26 },
    { text: "27", value: 27 },
    { text: "28", value: 28 },
    { text: "29", value: 29 },
    { text: "30", value: 30 },
    { text: "31", value: 31 },
    { text: "Last", value: 't' },
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: false,
    enableCheckAll: false,
    maxHeight: 180

  };
  
  
  public ReportTree1: any[] = [
    {
      reportType: "PR",
      text: "portfolioReport",

      items: [
        { text: "System Report", items: [] },
        { text: "Custom Report", items: [] }
      ]
    },
    {
      reportType: "AR",
      text: "assetExport",
      items: [
        { text: "System Report", items: [] },
        { text: "Custom Report", items: [] }
      ]

    },
    { reportType: "PPR", text: "projectProgress" },
    { reportType: "RR", text: "ReplacementReport" },
    { reportType: "MRP", text: "MaintenanceandRenovationPlanReport" },



  ];
  public expandedKeys: any[] = [];

  public min: Date = new Date();
  public max: Date = new Date();
  ScheduleDuration: string;
  templateTitle: any;
  templateName: any;
  SubtemplateName: any;
  AllowAssetGroup: boolean = false
  AllowAssetClass: boolean = false
  AllowCustomerCode: boolean = false
  AllowLocationCode: boolean = false
  AllowCustomer: boolean = false
  LanguageConfig: any
  LanguageConfig1: any
  LanguageInitial: any
  assetfieldCofig: any;
  subassetfieldCofig: any;
  subscription: any;
  //public CommonFilterComponent: CommonFilterComponent;
  @ViewChild(CommonFilterComponent,{static:false}) CommonFilterComponent: CommonFilterComponent
  @ViewChild(ReportOptionsComponent,{static:false}) ReportOptionsComponent: ReportOptionsComponent;

  reportFilter: ReportFilter = new ReportFilter();

  public gridData: GridDataResult;
  public gridScheduleData: GridDataResult;
  LocationIDModel: any;
  CustomerIDModel: any;
  SelectedReport: string;
  ReportParameter: boolean = true;
  MoreFilter: boolean = true;
  ChooseColumn: boolean = true;
  Schedule: boolean = true;
  Format: boolean = true;
  ReportTypeButton: string = "Generate Report";
  display: boolean = false;
  customReportButton: boolean = true;
  templateFilterDetails: any;
  LanguageConfigoption: string;
  ReportOption: boolean = true;
  isExpanded: boolean = true;
  IsImageOptionsDisabled: boolean = false;
  isGenerateReportEnabled: boolean=true;
  isExpandedtree: boolean;
  success: boolean=false;
  fieldTemplate: any;
  DateTimeformat: string;
  Dateformat: any;
  GridCurrencyChange: any;



  constructor(private titleService: Title, private spinner: SpinnerVisibilityService, private activatedRoute: ActivatedRoute, private notify: SnotifyService, private coreDataService: CoreDataService, private sharedData: SharedDataService, private excelService: ExcelService) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);

  }
  ngOnInit() {
    this.getCustomReport();

    this.getCustomerbyName("");
    this.subscription = this.sharedData.getMessage().subscribe(message => {
      if (message.title == 'GenerateReport') {
        this.reportFilter = this.sharedData.ReportFilterSharedData;
        this.reportFilter.assetGroup = this.spit(this.reportFilter.assetGroup).toString();
        console.log(this.reportFilter.assetGroup)
        this.reportFilter =
          this.commonFilterEntity.customerID = this.reportFilter.customerID;
        this.commonFilterEntity.locationID = this.reportFilter.locationID;
        this.EnableFieldButton = false;
        this.openedFieldChooser = false;

      }
      else {


      }
    }

    )
    this.sharedData.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
        this.Dateformat = res['DateFormat'];
        this.GridCurrencyChange = res['Currency'];
        console.log(this.GridCurrencyChange)
      }
    })

    this.LanguageInitial = this.sharedData.GetLanguageLiteral()

    if (this.sharedData.GetLanguageLiteral() != '' && this.sharedData.GetLanguageLiteral() != null) {

      this.sharedData.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[27];
          this.LanguageConfig1 = res[29];
          this.assetfieldCofig = res[30];
          this.subassetfieldCofig = res[53];
          this.ErrorMsgData = res[28];
          this.GridConfig = res[2];
          this.LanguageConfigoption = res[5];
          if(this.LanguageConfig!=undefined){
           this.ReportTypeButton=this.LanguageConfig.GenerateReport
        this.ReportTree1.forEach(row=>{
        if(row.reportType=="PR"){
          row.text=this.LanguageConfig.portfolioReport
          row.items[0].text=this.LanguageConfig.SystemReport
          row.items[1].text=this.LanguageConfig.CustomReport
        }
        else if(row.reportType=="AR"){
          row.text=this.LanguageConfig.assetExport
          row.items[0].text=this.LanguageConfig.SystemReport
          row.items[1].text=this.LanguageConfig.CustomReport
        }
        else if(row.reportType=="PPR"){
          row.text=this.LanguageConfig.projectProgress
        }
        else if(row.reportType=="MRP"){
          row.text=this.LanguageConfig.MaintenanceandRenovationPlanReport
        }
        else if(row.reportType=="RR"){
          row.text=this.LanguageConfig.ReplacementReport
        }
      })  
      }
    }
        })
    }
    this.SchedulerReportEntity.day_Recur = 1;
    var MaxDate = new Date();
    var year = MaxDate.getFullYear();
    var month = MaxDate.getMonth();
    var day = MaxDate.getDate();
    this.max = new Date(year + 1, month, day)

    //this.sub = this.sharedData.ReportFilterBeh$.subscribe((object: ReportFilter) => {
      this.sharedData
      .getMessage()
      .subscribe((message) => {
      let obj: any = this.sharedData.ReportFilterSharedData;

      let cust = this.sharedData.ReportFilterSharedData['customerID']
      let loc = this.sharedData.ReportFilterSharedData['locationID']

      let objReport: ReportFilter = new ReportFilter()
      //let objReportObj: ReportFilter = object

      // NEW CODE
      if (JSON.stringify(obj) === JSON.stringify(objReport)) {
       
        this.commonFilterEntity.customerID = cust
        this.commonFilterEntity.locationID = loc
        return
      }
      // NEW CODE


      if (obj != null && obj != undefined) {
        if (obj.customerID != undefined && obj.customerID != 0) {
          this.commonFilterEntity.customerID = obj.customerID;


         // this.getFieldList()

          if (obj.locationID !== null && obj.locationID === undefined) {
            this.IsLocationChange = true;
          }
          else if (obj.locationID === null) {
            this.IsLocationChange = false;
          }

          // this.commonFilterEntity.locationID = obj.locationID.toString();
          this.commonFilterEntity.locationID = obj.locationID;
          this.commonFilterEntity.state = obj.state;
          this.commonFilterEntity.conditionRating = obj.conditionRating;
          this.commonFilterEntity.status = obj.status;
          this.commonFilterEntity.assetGroup = obj.assetGroup;
          this.commonFilterEntity.criticality = obj.criticality;
          this.commonFilterEntity.assetOwnership = obj.assetOwnership;
          this.commonFilterEntity.ageGroup = obj.ageGroup;
          this.commonFilterEntity.filtersFitted = obj.filtersFitted;
          this.commonFilterEntity.replacementYear = obj.replacementYear;
          this.commonFilterEntity.replacementCost = obj.replacementCost;
          this.commonFilterEntity.remainingLife = obj.remainingLife;
          this.commonFilterEntity.refrigerant = obj.refrigerant;
          this.commonFilterEntity.assetTypeID = obj.assetTypeID;
          this.commonFilterEntity.OHSComments = obj.OHSComments;
          this.commonFilterEntity.serviceCalls = obj.serviceCalls;
          this.commonFilterEntity.sortBy = obj.sortBy;
          this.commonFilterEntity.installStartDate = obj.installStartDate
          this.commonFilterEntity.installEndDate = obj.installEndDate
          this.commonFilterEntity.capacityStart = obj.capacityStart
          this.commonFilterEntity.capacityEnd = obj.capacityEnd
          this.commonFilterEntity.startDate = obj.startDate
          this.commonFilterEntity.endDate = obj.endDate
          this.commonFilterEntity.totalSpend=obj.totalSpend

          if (this.commonFilterEntity.customerID != undefined) {
            this.EnableFieldButton = false
          }
          else {
            this.EnableFieldButton = true
          }
        }
        else {

          if (obj.customerID == undefined) {
            this.commonFilterEntity.customerID = undefined;
          }
          else if (obj.customerID == 0) {
            this.commonFilterEntity.customerID = 0;
          }
          if (obj.locationID == undefined) {
            this.commonFilterEntity.locationID = undefined;
          }
          else if (obj.locationID == "") {
            this.commonFilterEntity.locationID = null;
          }
          this.EnableFieldButton = true
        }
      }

      //--SUBASSET FIELD API
      this.GetSubAssetFields()
      //--SUBASSET FIELD API
    })

    this.GetReportQueue();



  }

  spit(data) {
    var answer = data.split(',').filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });
    return answer
  }


  //-SUB ASSETS FIELDS CODE
  GetSubAssetFields() {

    this.coreDataService.getSubAssetFields().subscribe(res => {

      this.SubAssetFields = res;
      let SubAssetFieldsTemp = [];
      this.SubAssetFields.forEach(e => {
        // SubAssetFieldsTemp.push({ "subAssetFieldID": e.subAssetFieldID, "defaultBehaviour": e.defaultBehaviour, "subAssetDisplayName": e.subAssetDisplayName, "subAssetFieldName": e.subAssetFieldName })
        this.subassetfieldCofig.forEach(element => {
          if (element.FieldName === e.subAssetFieldName) {
            SubAssetFieldsTemp.push({ "subAssetFieldID": e.subAssetFieldID, "defaultBehaviour": e.defaultBehaviour, "subAssetDisplayName": element.DisplayName, "subAssetFieldName": e.subAssetFieldName })
          }
        })
      })
      //console.log(SubAssetFieldsTemp)
      this.SubAssetFields = SubAssetFieldsTemp;
      this.SubAssetFields.sort(function (a, b) {
        return a.subAssetDisplayName.localeCompare(b.subAssetDisplayName,'en', {numeric: true});
      })
    })
  }
  //-SUB ASSETS FIELDS CODE

  onExcelExport(e: any): void {
    const rows = e.workbook.sheets[0].rows;

    // align multi header
    console.log(rows)
    // rows[0].cells[2].backgound = '#ffffff';

    // set alternating row color
    let altIdx = 0;
    rows.forEach((row) => {
      if (row.type === 'group-footer' || row.type === 'footer') {
        console.log(row)

        row.cells.forEach((cell) => {
          cell.textAlign = 'right';
          cell.background = '#a5a5a5'
        });

      }
      if (row.type === 'group-header') {
        row.cells.forEach((cell) => {
          cell.background = '#ffffff';
        });
      }
      if (row.type === 'header') {
        row.cells.forEach((cell) => {
          cell.background = '#a5a5a5';
          cell.color = '#333'
        });
      }
    });
  }


  public closeFieldChooser(status) {
    this.openedFieldChooser = false;
  }


  selectAssetField(event) {
    let checked = event.target.checked
    if (checked == true) {
      this.checkAllAssetFieldList();
    }
    else if (checked == false) {
      this.UnCheckAllAssetFieldList();
    }
  }

  selectSubAssetField(event) {
    let checked = event.target.checked
    if (checked == true) {
      this.checkAllSubAssetFieldList();
    }
    else if (checked == false) {
      this.UnCheckAllSubAssetFieldList();
    }
  }

  checkAllAssetFieldList() {
    let item = [];
    this.AssetFields.forEach(element => {
      item.push(element.assetFieldID)
    })
    this.assetFieldsListTemp = item;
    this.AllowAssetGroup = true;
    this.AllowAssetClass = true;
    this.AllowCustomerCode=true;
    this.AllowLocationCode=true;
    this.AllowCustomer=true;
  }

  checkAllSubAssetFieldList() {
    let item = [];
    this.SubAssetFields.forEach(element => {
      item.push(element.subAssetFieldID)
    })
    this.subassetFieldsListTemp = item;
  }

  checkAssetFieldList(assetFieldsList) {
    let item = [];
    assetFieldsList.forEach(element => {
      if (element.assetFieldID == undefined) {
        item.push(element)
      }
      else {
        item.push(element.assetFieldID)
      }

    })
    this.assetFieldsListTemp = item;
  }

  checkSubAssetFieldList(subassetFieldsList) {
    let item = [];
    subassetFieldsList.forEach(element => {
      if (element.subAssetFieldID == undefined) {
        item.push(element)
      }
      else {
        item.push(element.subAssetFieldID)
      }

    })
    this.subassetFieldsListTemp = item;
  }

  UnCheckAllAssetFieldList() {
    this.AllowAssetGroup = false;
    this.AllowAssetClass = false;
    this.AllowCustomerCode=false;
    this.AllowLocationCode=false;
    this.AllowCustomer=false;
    this.assetFieldsListTemp = [];
    this.MandatoryFields(undefined);
  }

  UnCheckAllSubAssetFieldList() {
    this.subassetFieldsListTemp = [];
    this.MandatorySubFields(undefined);
  }



  //fields save as Template
  saveAsTemplate() {
    this.openedFieldChooser = false;
    this.show = false;
    let asssetsFieldsforTemplate: any = [];
    let subasssetsFieldsforTemplate: any = [];

    if (this.assetFieldsListTemp.length > 0 && this.assetFieldsListTemp != null) {
      this.assetFieldsListTemp.forEach(data => {
        var result: any = this.AssetFields.filter((fields => {
          return fields.assetFieldID === data;
        }))
        asssetsFieldsforTemplate.push(result[0])

      })
    }
    if (this.subassetFieldsListTemp.length > 0 && this.subassetFieldsListTemp != null) {
      this.subassetFieldsListTemp.forEach(data => {
        var result: any = this.SubAssetFields.filter((fields => {
          return fields.subAssetFieldID === data;
        }))
        subasssetsFieldsforTemplate.push(result[0])

      })
    }
    let fieldTemplate = []
    fieldTemplate = asssetsFieldsforTemplate.concat(subasssetsFieldsforTemplate)

    this.AssetReportFieldTemplate.FieldTemplate = fieldTemplate;
    //this.AssetReportFieldTemplate.TemplateName = this.templateTitle;
    //this.coreDataService.SaveAssetReportFieldTemplate(this.AssetReportFieldTemplate)
    // .subscribe(data => {
    //})
    //this.templateTitle = '';
    this.getAssetFieldReportTemplate();

  }


  saveAsSubTemplate() {
    // this.openedFieldChooser = false;
    // this.show = false;
    // let subasssetsFieldsforTemplate: any = [];
    // if (this.subassetFieldsListTemp.length > 0 && this.subassetFieldsListTemp != null) {
    //   this.subassetFieldsListTemp.forEach(data => {
    //     var result: any = this.SubAssetFields.filter((fields => {
    //       return fields.subAssetFieldID === data;
    //     }))
    //     subasssetsFieldsforTemplate.push(result[0])

    //   })
    //   this.AssetReportFieldTemplate.FieldTemplate = subasssetsFieldsforTemplate;
    //   this.AssetReportFieldTemplate.TemplateName = this.templateTitle;
    //   this.coreDataService.SaveAssetReportFieldTemplate(this.AssetReportFieldTemplate)
    //     .subscribe(data => {
    //     })
    //   this.templateTitle = '';
    //   this.getAssetFieldReportTemplate();
    // }
  }


  MandatoryFields(DefaultFields) {
    let tempArr = []
    this.AssetFields.forEach(ele => {
      if (ele.fieldName.toLowerCase() == 'userassetid' || ele.fieldName.toLowerCase() == 'barcode' || ele.fieldName.toLowerCase() == 'assetlocationid' || ele.fieldName.toLowerCase() == 'statuscd' || ele.fieldName.toLowerCase() == 'assettypeid') {
        tempArr.push(ele)
      }
    })
    let arr = []
    tempArr.forEach(elem => {
      arr.push(elem.assetFieldID)
    })
    if (DefaultFields == undefined) {
      this.assetFieldsListTemp = arr
    }
    else {
      this.assetFieldsListTemp = this.assetFieldsListTemp.concat(arr);
    }


  }

  MandatorySubFields(DefaultFields) {
    let tempArr = []
    this.SubAssetFields.forEach(ele => {
      if (ele.subAssetFieldName.toLowerCase() == 'sasubassetid' || ele.subAssetFieldName.toLowerCase() == 'sauserassetid' || ele.subAssetFieldName.toLowerCase() == 'sabarcode') {
        tempArr.push(ele)
      }
    })
    let arr = []
    tempArr.forEach(elem => {
      arr.push(elem.subAssetFieldID)
    })
    if (DefaultFields == undefined) {
      this.subassetFieldsListTemp = arr
    }
    else {
      this.subassetFieldsListTemp = this.subassetFieldsListTemp.concat(arr);
    }


  }

  DefaultAssetFieldList() {
    let arr = []
    let defaultAssetFieldList = ["ActualAge", "AgeGroup", "AirnetAddress", "SpecialComments", "AppliedFieldCodeSetting"]
    let tempArr = []
    defaultAssetFieldList.forEach(el => {

      this.assetFieldsList.forEach(ele => {
        if (ele.fieldName.toLowerCase() === el.toLowerCase()) {
          tempArr.push(ele)
        }
      })
    })
    tempArr.forEach(elem => {
      arr.push(elem.assetFieldID)
    })
    this.assetFieldsListTemp = arr
    this.MandatoryFields(this.assetFieldsListTemp)
  }


  public openFieldChooser() {

    if (this.CustomerIDModel != undefined && this.CustomerIDModel != 0) {

      //this.openedFieldChooser = true;


      // console.log(this.AssetFields)
      // if (this.assetfieldCofig.forEach(res => {
      //   res.fieldName.toString()
      // }) == this.AssetFields.forEach(ele => {
      //   ele.fieldName.toString()
      // })
      // ) {
      //   this.AssetFields = this.assetfieldCofig;
      //   this.AssetFields.sort(function (a, b) {
      //     return a.displayName.localeCompare(b.displayName);
      //   })
      // }

      this.AssetFields = this.AssetFields;

      this.AssetFields.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName,'en', {numeric: true});
      })


      this.SubAssetFields = this.SubAssetFields;

      this.SubAssetFields.sort(function (a, b) {
        return a.subAssetDisplayName.localeCompare(b.subAssetDisplayName,'en', {numeric: true});
      })

      let CustomerChange = null

      // this.sharedData.ShowDefaultFields$.subscribe(res => {
      //   CustomerChange = res
      // })
      if (this.IsCustomerChange && !this.IsLocationChange) {
        
        this.assetFieldsListTemp = this.AssetFields
        this.assetFieldsList = this.AssetFields
        //this.IsCustomerChange = false;
        //this.IsLocationChange = true;
        this.checkAllAssetFieldList();
        this.checkAllSubAssetFieldList();
      }
      else {

        this.assetFieldsListTemp = (this.assetFieldsListTemp === undefined || this.assetFieldsListTemp.length == 0) ? this.AssetFields : this.assetFieldsListTemp;

        this.checkAssetFieldList(this.assetFieldsListTemp)

        this.subassetFieldsListTemp = (this.subassetFieldsListTemp === undefined || this.subassetFieldsListTemp.length == 0) ? this.SubAssetFields : this.subassetFieldsListTemp;

        this.checkSubAssetFieldList(this.subassetFieldsListTemp)

        // this.AllowAssetGroup = true;
        // this.AllowAssetClass = true;
      }
    }


  }

  getAssetFieldReportTemplate() {

    this.coreDataService.GetAssetReportFieldTemplate()
      .subscribe(data => {
        this.templateData = []
        this.subtemplateData = []

        data.forEach(element => {

          this.templateData.push(element);
          // if(element.fieldTemplateType =='AST'){
          //   this.templateData.push(element);
          // }
          // else if(element.fieldTemplateType =='SAST')
          // {
          //   this.subtemplateData.push(element);
          // }

          // console.log(JSON.parse(element.fieldTemplate))
          // let ObjEle = JSON.parse(element.fieldTemplate)

          // let AssetArr = []
          // let SubAssetArr = []
          // for(var fieldJson of JSON.parse(element.fieldTemplate)){
          //   if('assetFieldID' in fieldJson){
          //     AssetArr.push(fieldJson);
          //   }
          //   else if('subAssetFieldID' in fieldJson)
          //   {
          //     SubAssetArr.push(fieldJson);
          //   }
          // }




        });
      })
  }

  onTabchange(event) {
    if (event.index == 1) {
      this.GetReportQueue();
    }
    else if (event.index == 2) {
      this.GetScheduleList();
    }
  }
    GenerateRandMReport() {
    var locationID =this.commonFilterEntity.locationID;
    var CustomerID = this.commonFilterEntity.customerID;
    // if (locationID == undefined || locationID == null) {
    //   this.notify.warning(this.ErrorMsgData.selectLocation);
    //   return false;
    // } 
     if (CustomerID == undefined || CustomerID == null) {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
      return false;
    } 
   
    else {
      this.coreDataService
        .GetRandMPlanReport(CustomerID, locationID, this.LanguageInitial)
        .subscribe((res) => {
          if (res != undefined && res != null) {
            this.saveAsExcelFile(this._base64ToArrayBuffer(res), "RandMReport");
            //this.CommonFilterComponent.resetModels();
          } else {
            this.notify.warning(this.ErrorMsgData.noDataAvailable);
          }
        });
    }
  }

  onTabchangeFields(event) {

    if (event.index == 1) {
      // this.GetReportQueue();
    }
    else if (event.index == 2) {
      // this.GetScheduleList();
      // this.GetSubAssetFields();
    }
  }

  DeleteSchedule(id) {
    this.coreDataService.DeleteScheduledetails(id).subscribe((res) => {
      if (res != undefined) {

        this.ScheduleList.forEach((ele, index) => {
         
          if (ele.assetReportScheduledID == id) {
            this.ScheduleList.splice(index, 1)
          }
        })

        this.gridScheduleData = process(this.ScheduleList, this.state1);
        this.notify.success(this.ErrorMsgData.recordDelete);
        // this.GetScheduleList();

      }
    });
  }

  protected dataStateChange(state: DataStateChangeEvent): void {

    this.state = state;
    this.gridData = process(this.QueueReportEntity, this.state);
  }

  protected dataStateChangeSchedule(state: DataStateChangeEvent): void {

    this.state1 = state;
    this.gridScheduleData = process(this.ScheduleList, this.state1);
  }

  public PPRclose(status) {
   this.CustomerIDModel=null
   this.LocationIDModel=null
    this.opened = false;
  }

  public PPDopen() {
    this.opened = true;
  }

  public TemplateClose(status) {
    this.display = false;
    // this.ResetFieldSelector(false,false)
  }

  public TemplateOpen() {
    this.display = true;
  }

  AssetFieldsCustomerChange() {
    let AssetFieldData = [];
    if (true) {


      this.AssetFields.forEach(e => {
        if (typeof e == 'number') {
          AssetFieldData.push(e);
        }
        else if (typeof e == 'object') {
          AssetFieldData.push(e.assetFieldID);
        }
      })
    }
    return AssetFieldData;
  }

  SubAssetFieldsCustomerChange() {
    let SubAssetFieldData = [];
    if (true) {


      this.SubAssetFields.forEach(e => {
        if (typeof e == 'number') {
          SubAssetFieldData.push(e);
        }
        else if (typeof e == 'object') {
          SubAssetFieldData.push(e.subAssetFieldID);
        }
      })
    }
    return SubAssetFieldData;
  }

  GenerateExcelExport(event) {
    this.sendMessage();

    this.assetFieldsList = this.assetFieldsListTemp == undefined || this.assetFieldsListTemp.length == 0 ? this.AssetFieldsCustomerChange() : this.assetFieldsListTemp;
    // let assetFieldList = this.assetFieldsList.toString();
    let FieldList = [];
    this.assetFieldsList.forEach(e => {
      if (typeof e == 'number') {
        FieldList.push(e);

        console.log('numberFieldList' + FieldList)
      }
      else if (typeof e == 'object') {
        FieldList.push(e.assetFieldID);
      }
    })


    this.subassetFieldsList = this.subassetFieldsListTemp == undefined || this.subassetFieldsListTemp.length == 0 ? this.SubAssetFieldsCustomerChange() : this.subassetFieldsListTemp;
    // let assetFieldList = this.assetFieldsList.toString();
    let SubFieldList = [];
    this.subassetFieldsList.forEach(e => {
      if (typeof e == 'number') {
        SubFieldList.push(e);
      }
      else if (typeof e == 'object') {
        SubFieldList.push(e.subAssetFieldID);
      }
    })

    if (this.commonFilterEntity != null && this.commonFilterEntity != undefined) {

      if (this.commonFilterEntity.customerID != undefined && this.commonFilterEntity.customerID != 0) {
        this.commonFilterEntity.locationID=this.LocationIDModel;
        //NEW FIELDS
        this.AllowAssetClass;
        this.AllowAssetGroup;
        this.LanguageInitial;
        //NEW FIELDS
        this.getAssetExportData(this.commonFilterEntity, FieldList, SubFieldList, this.AllowAssetClass, this.AllowAssetGroup, this.LanguageInitial)

      } else {
        this.notify.warning(this.ErrorMsgData.selectCustomer);

      }
    } else {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
    }
  }


  sendMessage(): void {
    // send message to subscribers via observable subject
    this.sharedData.sendMessage('GenerateReport');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });

    console.log('excelData' + data);
    FileSaver.saveAs(data, fileName + new Date().getTime() + this.EXCEL_EXTENSION);
  }

  public saveFile(URL, fileName, format, ElementID) {


    document.getElementById(ElementID).innerText = this.LanguageConfig["PleaseWait"]
    var oReq = new XMLHttpRequest();
    // The Endpoint of your server
    // var URLToPDF = "https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf";
    //  var URLToPDF = '/assets/TEST.docx';
    var URLToPDF = URL;
    // Configure XMLHttpRequest
    oReq.open("GET", URLToPDF, true);
    // oReq.setRequestHeader('Access-Control-Allow-Origin','*')
    // Important to use the blob response type
    oReq.responseType = "blob";

    // When the file request finishes
    // Is up to you, the configuration for error events etc.

    oReq.onload = () => {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser

      var file = new Blob([oReq.response], {
        type: 'application' + '/' + format
      });

      // Generate file download directly in the browser !
      // FileSaver.saveAs(file, fileName);


      FileSaver.saveAs(file, fileName);
      document.getElementById(ElementID).innerText = this.LanguageConfig["download"]
    };

    oReq.send();

  }


  getAssetExportData(data, AssetFields, SubAssetFields, IsAssetClassSelected, IsAssetGroupSelected, language) {
    // console.log('Asset field List'+data.assetGroup)
    data.assetGroup = this.spit(data.assetGroup).toString();
    data.ageGroup = this.spit(data.ageGroup).toString();
    data.conditionRating = this.spit(data.conditionRating).toString();
    data.status = this.spit(data.status).toString();
    data.criticality = this.spit(data.criticality).toString();
    data.assetOwnership = this.spit(data.assetOwnership).toString();
    data.filtersFitted = this.spit(data.filtersFitted).toString();
    data.refrigerant = this.spit(data.refrigerant).toString();
    data.assetTypeID = this.spit(data.assetTypeID).toString();
    // this.coreDataService.GetAssetExportReportByID(data, AssetFields, SubAssetFields, IsAssetClassSelected, IsAssetGroupSelected, language).subscribe((res) => {
    //   if (res != undefined && res != null) {
    //     this.saveAsExcelFile(this._base64ToArrayBuffer(res), "AssetReport");
    //     this.CommonFilterComponent.resetModels();
    //     this.CustomerIDModel=null
    //     this.LocationIDModel=null
    //   } else {
    //     this.notify.warning(this.ErrorMsgData.noDataAvailable);
    //   }
    // })
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  getProgressRepotData(CustomerID, LocationID,format,ReportType,LanguageConfig) {
    this.coreDataService.GetProgressRrepot(CustomerID, LocationID,format,ReportType,LanguageConfig) .subscribe((res) => {
      if (res != undefined && res != null) {
        this.saveAsFile(this._base64ToArrayBuffer(res.fileData),res.fileName,res.extention)
        this.CommonFilterComponent.resetModels();
      } else {
        this.notify.warning(this.ErrorMsgData.noDataAvailable);
      }
    });

   
  }


  getAccountManagerNameByLocation(locationName: any) {
    let accountManager: any = null

    // this.progressRepootModel.find((ele) => { return ele.locationName.toUpperCase() === locationName.toUpperCase() })

    // console.log(locationName)
    // console.log(this.progressRepootModel[0].accountManager)

    this.progressRepootModel.forEach(ele => {
      if (ele.locationName.toUpperCase() === locationName.toUpperCase()) {
        accountManager = ele.accountManager
      }
    })
    // accountManager = this.progressRepootModel[0].accountManager

    console.log(accountManager)
    return accountManager
  }

  getAuditStatusNameByLocation(locationName: any) {
    let auditStatus: any = null

    // this.progressRepootModel.find((ele) => { return ele.locationName.toUpperCase() === locationName.toUpperCase() })
    // // console.log(locationName)
    // // console.log(this.progressRepootModel[0].accountManager)
    // auditStatus = this.progressRepootModel[0].auditStatus
    // return auditStatus

    this.progressRepootModel.forEach(ele => {
      if (ele.locationName.toUpperCase() === locationName.toUpperCase()) {
        auditStatus = ele.auditStatus
      }
    })

    console.log(auditStatus)
    return auditStatus
  }


  GetReportQueue() {
    this.coreDataService.getReportQueueDetails().subscribe(res => {

      if (res != undefined) {
        this.QueueReportEntity = res
        this.QueueReportEntity.forEach(ele => {

          ele.fileName = ele.reportName
          ele.reportName = environment.environment.reportBasePath + ele.reportName
          ele.creationDate=new Date(ele.creationDate);
        })

        this.gridData = process(this.QueueReportEntity, this.state);
      }
    })

  }

  GetScheduleList() {
    this.coreDataService.getScheduledetails().subscribe(res => {

      if (res != undefined) {
        this.ScheduleList = res
        this.ScheduleList.forEach(ele => {
          ele.nextDate=new Date(ele.nextDate)

          this.ReportTree1.forEach(row => {
            if (row.reportType == ele.reportType) {
              ele.reportType = row.text
              
            }
          })
        })
        this.gridScheduleData = process(this.ScheduleList, this.state1);
      }
    })
  }


  IsReportOptionsEnabled: boolean = false
  IsScheduled: boolean = false
  GeneratePortfolioReport(IsScheduled, ReportType) {
    this.sendMessage();
    this.IsScheduled = IsScheduled
    this.ReportType = ReportType;
    this.commonFilterEntity.customerID = this.CustomerIDModel
    this.commonFilterEntity.locationID = this.LocationIDModel
    if (this.commonFilterEntity.customerID == undefined || this.commonFilterEntity.customerID == 0) {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
      return false;
    }
    // if ((this.commonFilterEntity.locationID == undefined || this.commonFilterEntity.locationID == null) && this.ReportType != 'RR') {
    //   this.notify.warning(this.ErrorMsgData.selectLocation);
    //   return false;
    // }
    if (this.commonFilterEntity != null && this.commonFilterEntity != undefined) {
      if (this.commonFilterEntity.customerID != undefined) {
        //Setting the model
        this.portfolioReportEntity = new PortfolioReportEntity();
        if (this.PortfoliocheckMode.toLowerCase() == "pdf") {
          this.portfolioReportEntity.format = "PDF";
        } else {
          this.portfolioReportEntity.format = "DOC";
        }
        if (this.ReportType == "AR") {
          this.portfolioReportEntity.format = "XLS"
        }
        this.portfolioReportEntity.status = "Queued";
        this.portfolioReportEntity.language = this.LanguageInitial;
        //NEW FIELDS
        this.portfolioReportEntity.IsAssetClassSelected = this.AllowAssetClass
        this.portfolioReportEntity.IsAssetGroupSelected = this.AllowAssetGroup
        this.portfolioReportEntity.IsCustomerCodeSelected=this.AllowCustomerCode
        this.portfolioReportEntity.IsLocationCodeSelected=this.AllowLocationCode
        this.portfolioReportEntity.IsCustomerSelected=this.AllowCustomer
        this.portfolioReportEntity.reportType = ReportType;

        //NEW FIELDS
        this.portfolioReportEntity.customerID = this.commonFilterEntity.customerID;
        // this.portfolioReportEntity.locationID = this.commonFilterEntity.locationID.toString();
        this.portfolioReportEntity.locationID = this.commonFilterEntity.locationID;
        this.portfolioReportEntity.state = this.commonFilterEntity.state;
        this.portfolioReportEntity.conditionRating = this.commonFilterEntity.conditionRating;
        this.portfolioReportEntity.assetStatus = this.commonFilterEntity.status;
        this.portfolioReportEntity.assetCategory = this.commonFilterEntity.assetGroup;
        this.portfolioReportEntity.criticality = this.commonFilterEntity.criticality;
        this.portfolioReportEntity.ownership = this.commonFilterEntity.assetOwnership;
        this.portfolioReportEntity.ageGroup = this.commonFilterEntity.ageGroup;
        this.portfolioReportEntity.filtersFitted = this.commonFilterEntity.filtersFitted;
        this.portfolioReportEntity.replacementCost = this.commonFilterEntity.replacementCost;
        this.portfolioReportEntity.remainingLife = this.commonFilterEntity.remainingLife;
        this.portfolioReportEntity.refrigerant = this.commonFilterEntity.refrigerant;
        this.portfolioReportEntity.replacementYear = this.commonFilterEntity.replacementYear;
        this.portfolioReportEntity.assetType = this.commonFilterEntity.assetTypeID;
        this.portfolioReportEntity.ohsComments = this.commonFilterEntity.OHSComments;
        this.portfolioReportEntity.assetSortBy = this.commonFilterEntity.sortBy;
        this.portfolioReportEntity.jobCount = this.commonFilterEntity.serviceCalls;
        this.portfolioReportEntity.totalSpend=this.commonFilterEntity.totalSpend;
        this.portfolioReportEntity.IsInactiveLocationSelected=this.IsInactiveLocationSelected
        this.assetFieldsList = this.assetFieldsListTemp == undefined || this.assetFieldsListTemp.length == 0 ? this.AssetFieldsCustomerChange() : this.assetFieldsListTemp;



        let FieldList = [];
        this.assetFieldsList.forEach(e => {
          if (typeof e == 'number') {
            FieldList.push(e);
          }
          else if (typeof e == 'object') {
            FieldList.push(e.assetFieldID);
          }
        })

        this.portfolioReportEntity.assetFields = FieldList.toString();

        //CODE FOR SUB ASSET FIELDS
        this.subassetFieldsList = this.subassetFieldsListTemp == undefined || this.subassetFieldsListTemp.length == 0 ? this.SubAssetFieldsCustomerChange() : this.subassetFieldsListTemp;
        let SubFieldList = [];
        this.subassetFieldsList.forEach(e => {
          if (typeof e == 'number') {
            SubFieldList.push(e);
          }
          else if (typeof e == 'object') {
            SubFieldList.push(e.subAssetFieldID);
          }
        })

        this.portfolioReportEntity.subAssetFields = SubFieldList.toString();
        //CODE FOR SUB ASSET FIELDS

        if (this.commonFilterEntity != undefined && this.commonFilterEntity.capacityStart != null && this.commonFilterEntity.capacityStart != undefined) {
          this.portfolioReportEntity.capacity = this.commonFilterEntity.capacityStart + ',' + this.commonFilterEntity.capacityEnd;
        } else {
          this.portfolioReportEntity.capacity = undefined;
        }

        if (this.commonFilterEntity != undefined && this.commonFilterEntity.startDate != null && this.commonFilterEntity.startDate != undefined) {
          this.portfolioReportEntity.creationDateRange = this.commonFilterEntity.startDate + ',' + this.commonFilterEntity.endDate;
        } else {
          this.portfolioReportEntity.creationDateRange = undefined;
        }

        if (this.commonFilterEntity != undefined && this.commonFilterEntity.installStartDate != null && this.commonFilterEntity.installStartDate != undefined) {
          this.portfolioReportEntity.installDateRange = this.commonFilterEntity.installStartDate + ',' + this.commonFilterEntity.installEndDate;
        } else {
          this.portfolioReportEntity.installDateRange = undefined;
        }


        //NEW CHANGES NEEDS TO BE ADDED
        //DAILOG POP-UP GeneratePortfolioReport
        //this.IsReportOptionsEnabled = true



        this.ReportOptionsComponent.close('yes', this.portfolioReportEntity)

        if (this.IsScheduled) {
          this.IsOpenScheduler = false
        }


        //NEW CHANGES NEEDS TO BE ADDED



        //ASSET COUNT

        // this.coreDataService.CheckAssetRecords(this.portfolioReportEntity).subscribe(res => {

        //   if (res != undefined && res.aCount > 0) {
        //     if (res.aCount > 50000) {
        //       this.notify.error(this.ErrorMsgData.generatelargeReport)
        //       return false;
        //     }
        //     if (!IsScheduled) {
        //       this.coreDataService.SavePortolioReport(this.portfolioReportEntity).subscribe((response) => {
        //         if (response != undefined && response != null) {
        //           this.notify.success(this.ErrorMsgData.reportSchedule);

        //           this.ResetFieldSelector(true, false);
        //           this.IsCustomerChange = false;

        //           this.CommonFilterComponent.resetModels();
        //         } else {
        //           this.notify.warning(this.ErrorMsgData.noDataAvailable);
        //         }
        //       })
        //     } else {
        //       if (this.Schedulerange.start == undefined || this.Schedulerange.start == null) {
        //         this.notify.warning(this.ErrorMsgData.selectStartDate);
        //         return false;
        //       }
        //       if (this.Schedulerange.end == undefined || this.Schedulerange.end == null) {
        //         this.notify.warning(this.ErrorMsgData.selectEnddate);
        //         return false;
        //       }
        //       let TempObj = this.SchedulerReportEntity;
        //       this.SchedulerReportEntity = this.portfolioReportEntity

        //       //DAILY
        //       if (this.ScheduleDuration == 'DAILY') {
        //         if (TempObj.day_Recur == undefined || TempObj.day_Recur == 0) {
        //           this.notify.warning(this.ErrorMsgData.selectRecuuringDay);

        //           return false;
        //         }
        //         this.SchedulerReportEntity.duration = this.ScheduleDuration;
        //         this.SchedulerReportEntity.day_Recur = TempObj.day_Recur;
        //         // this.SchedulerReportEntity = TempObj
        //       }
        //       //DAILY

        //       //WEEKLY
        //       let tmpWeekDays = [];
        //       if (this.ScheduleDuration == 'WEEKLY') {

        //         if (TempObj.week_Recur == undefined || TempObj.week_Recur == 0) {
        //           this.SchedulerReportEntity.week_Days = TempObj.week_Days;
        //           this.notify.warning(this.ErrorMsgData.selectWeek);
        //           return false;
        //         }
        //         if (TempObj.week_Days == undefined || TempObj.week_Days.length == 0) {
        //           this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;
        //           this.notify.warning(this.ErrorMsgData.selectOneDay);

        //           return false;
        //         }
        //         else {
        //           this.SchedulerReportEntity.duration = this.ScheduleDuration;
        //           if (TempObj.week_Days != undefined && TempObj.week_Days != "") {
        //             TempObj.week_Days.forEach(element => {
        //               tmpWeekDays.push(element.value);
        //             });
        //           }
        //           else {
        //             TempObj.week_Days = null
        //           }
        //         }
        //         this.SchedulerReportEntity.duration = this.ScheduleDuration;
        //         this.SchedulerReportEntity.week_Days = TempObj.week_Days;
        //         this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;
        //       }
        //       //WEEKLY


        //       //MONTHLY
        //       let tmpMonthDays = [];
        //       let tmpMonthDaysRecr = [];
        //       if (this.ScheduleDuration == 'MONTHLY') {

        //         if (TempObj.month_Recur == undefined || TempObj.month_Recur.length == 0) {
        //           this.SchedulerReportEntity.month_Days = TempObj.month_Days;
        //           this.notify.warning(this.ErrorMsgData.selectOneMonth);
        //           return false;
        //         }
        //         if (TempObj.month_Days == undefined || TempObj.month_Days.length == 0) {
        //           this.SchedulerReportEntity.month_Recur = TempObj.month_Recur;
        //           this.notify.warning(this.ErrorMsgData.selectOneMonthDay);
        //           return false;
        //         }

        //         else {
        //           if (TempObj.month_Days != undefined && TempObj.month_Days != "") {
        //             TempObj.month_Days.forEach(element => {
        //               tmpMonthDays.push(element.value);
        //             });
        //           }
        //           else {
        //             TempObj.month_Days = null
        //           }


        //           if (TempObj.month_Recur != undefined && TempObj.month_Recur != "") {
        //             TempObj.month_Recur.forEach(element => {
        //               tmpMonthDaysRecr.push(element.value);
        //             });
        //           }
        //           else {
        //             TempObj.month_Recur = null
        //           }
        //         }
        //         this.SchedulerReportEntity.duration = this.ScheduleDuration;
        //         this.SchedulerReportEntity.month_Days = TempObj.month_Days;
        //         this.SchedulerReportEntity.month_Recur = TempObj.month_Recur;

        //       }
        //       //MONTHLY

        //       this.SchedulerReportEntity.month_Days = tmpMonthDays.toString();
        //       this.SchedulerReportEntity.month_Recur = tmpMonthDaysRecr.toString();

        //       this.SchedulerReportEntity.week_Days = tmpWeekDays.toString();
        //       this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;

        //       this.SchedulerReportEntity.duration = this.ScheduleDuration;
        //       this.SchedulerReportEntity.startDate = this.Schedulerange.start
        //       this.SchedulerReportEntity.endDate = this.Schedulerange.end
        //       this.coreDataService.SchedulePortolioReport(this.SchedulerReportEntity).subscribe((response) => {
        //         if (response != undefined && response != null) {
        //           this.IsOpenScheduler = false;
        //           this.notify.success(this.ErrorMsgData.reportSchedule);

        //           //FIELDS RESET
        //           this.ResetSchedulerModel();
        //           //FIELDS RESET

        //           this.ResetFieldSelector(true, false);
        //           this.IsCustomerChange = false;
        //           //this.SchedulerReportEntity = new SchedulerReportEntity();
        //           this.CommonFilterComponent.resetModels();
        //         } else {
        //           this.notify.warning(this.ErrorMsgData.noDataAvailable);
        //         }
        //       })

        //     }
        //   }


        // })


        //ASSET COUNT


        //Sending the data to API
      } else {
        this.notify.warning(this.ErrorMsgData.selectCustomer);


      }
    } else {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
    }
  }

  portfolioReportEntityEvent(event) {
    console.log(event)
    console.log(this.ReportType)
    this.portfolioReportEntity.reportType = this.ReportType;
    this.portfolioReportEntity = event
    this.IsReportOptionsEnabled = false

    if ((this.ReportType != 'PPR' && this.ReportType != 'MRP') || this.ReportType == undefined) {
      this.coreDataService.CheckAssetRecords(this.portfolioReportEntity).subscribe(res => {

        if ((res != undefined && res.aCount > 0) || this.ReportType == "AR") {
          if (res.aCount > 50000) {
            this.notify.error(this.ErrorMsgData.generatelargeReport)
            return false;
          }
          if (!this.IsScheduled) {
            if(true)
            this.coreDataService.SavePortolioReport(this.portfolioReportEntity).subscribe((response) => {
              if (response != undefined && response != null) {
                this.notify.success(this.ErrorMsgData.reportGenerate);
                // this.resetAfterReportGenerate()

               // this.ResetFieldSelector(true, false);
                this.IsCustomerChange = false;
                this.IsInactiveLocationSelected=false

                //this.CommonFilterComponent.resetModels();
              } else {
                this.notify.warning(this.ErrorMsgData.noDataAvailable);
              }
            })
            else{
              this.coreDataService.GetAssetExportReportByID(this.portfolioReportEntity).subscribe((res) => {
                if (res != undefined && res != null) {
                  this.saveAsExcelFile(this._base64ToArrayBuffer(res), "AssetReport");
                 // this.CommonFilterComponent.resetModels();
                 // this.CustomerIDModel=null
                 // this.LocationIDModel=null
                } else {
                  this.notify.warning(this.ErrorMsgData.noDataAvailable);
                }
              })
            }
          } else {
            if (this.Schedulerange.start == undefined || this.Schedulerange.start == null) {
              this.notify.warning(this.ErrorMsgData.selectStartDate);
              return false;
            }
            if (this.Schedulerange.end == undefined || this.Schedulerange.end == null) {
              this.notify.warning(this.ErrorMsgData.selectEnddate);
              return false;
            }
            let TempObj = this.SchedulerReportEntity;
            this.SchedulerReportEntity = this.portfolioReportEntity

            //DAILY
            this.SchedulerReportEntity.scheduleName = TempObj.scheduleName
            if (this.ScheduleDuration == 'DAILY') {
              if (TempObj.day_Recur == undefined || TempObj.day_Recur == 0) {
                this.notify.warning(this.ErrorMsgData.selectRecuuringDay);

                return false;
              }
              this.SchedulerReportEntity.duration = this.ScheduleDuration;
              this.SchedulerReportEntity.day_Recur = TempObj.day_Recur;
              // this.SchedulerReportEntity = TempObj
            }
            //DAILY

            //WEEKLY
            let tmpWeekDays = [];
            if (this.ScheduleDuration == 'WEEKLY') {

              if (TempObj.week_Recur == undefined || TempObj.week_Recur == 0) {
                this.SchedulerReportEntity.week_Days = TempObj.week_Days;
                this.notify.warning(this.ErrorMsgData.selectWeek);
                return false;
              }
              if (TempObj.week_Days == undefined || TempObj.week_Days.length == 0) {
                this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;
                this.notify.warning(this.ErrorMsgData.selectOneDay);

                return false;
              }
              else {
                this.SchedulerReportEntity.duration = this.ScheduleDuration;
                if (TempObj.week_Days != undefined && TempObj.week_Days != "") {
                  TempObj.week_Days.forEach(element => {
                    tmpWeekDays.push(element.value);
                  });
                }
                else {
                  TempObj.week_Days = null
                }
              }
              this.SchedulerReportEntity.duration = this.ScheduleDuration;
              this.SchedulerReportEntity.week_Days = TempObj.week_Days;
              this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;
            }
            //WEEKLY


            //MONTHLY
            let tmpMonthDays = [];
            let tmpMonthDaysRecr = [];
            if (this.ScheduleDuration == 'MONTHLY') {

              if (TempObj.month_Recur == undefined || TempObj.month_Recur.length == 0) {
                this.SchedulerReportEntity.month_Days = TempObj.month_Days;
                this.notify.warning(this.ErrorMsgData.selectOneMonth);
                return false;
              }
              if (TempObj.month_Days == undefined || TempObj.month_Days.length == 0) {
                this.SchedulerReportEntity.month_Recur = TempObj.month_Recur;
                this.notify.warning(this.ErrorMsgData.selectOneMonthDay);
                return false;
              }

              else {
                if (TempObj.month_Days != undefined && TempObj.month_Days != "") {
                  TempObj.month_Days.forEach(element => {
                    tmpMonthDays.push(element.value);
                  });
                }
                else {
                  TempObj.month_Days = null
                }


                if (TempObj.month_Recur != undefined && TempObj.month_Recur != "") {
                  TempObj.month_Recur.forEach(element => {
                    tmpMonthDaysRecr.push(element.value);
                  });
                }
                else {
                  TempObj.month_Recur = null
                }
              }
              this.SchedulerReportEntity.duration = this.ScheduleDuration;
              this.SchedulerReportEntity.month_Days = TempObj.month_Days;
              this.SchedulerReportEntity.month_Recur = TempObj.month_Recur;

            }
            //MONTHLY

            this.SchedulerReportEntity.month_Days = tmpMonthDays.toString();
            this.SchedulerReportEntity.month_Recur = tmpMonthDaysRecr.toString();

            this.SchedulerReportEntity.week_Days = tmpWeekDays.toString();
            this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;

            this.SchedulerReportEntity.duration = this.ScheduleDuration;
            this.SchedulerReportEntity.startDate = this.Schedulerange.start
            this.SchedulerReportEntity.endDate = this.Schedulerange.end

            this.coreDataService.SchedulePortolioReport(this.SchedulerReportEntity).subscribe((response) => {
              if (response != undefined && response != null) {
                this.IsOpenScheduler = false;
                this.notify.success(this.ErrorMsgData.reportSchedule);
               //  this.resetAfterReportGenerate()
                this.success=true

                //FIELDS RESET
              //  this.ResetSchedulerModel();
                //FIELDS RESET

               // this.ResetFieldSelector(true, false);
                this.IsCustomerChange = false;
                this.IsInactiveLocationSelected=false
                //this.SchedulerReportEntity = new SchedulerReportEntity();
              //  this.CommonFilterComponent.resetModels();
              } else {
                this.notify.warning(this.ErrorMsgData.noDataAvailable);
              }
            })

          }
        }


      })
    }
    else if (this.ReportType == 'PPR' || this.ReportType == "MRP") {
      // this.ScheduleProjectProgressReport();
      console.log(this.SchedulerReportEntity)
      this.coreDataService.SchedulePortolioReport(this.SchedulerReportEntity).subscribe((response) => {
        if (response != undefined && response != null) {
          this.IsOpenScheduler = false;
          this.notify.success(this.ErrorMsgData.reportSchedule);
           this.resetAfterReportGenerate()
          this.success=true;

          //FIELDS RESET
          this.ResetSchedulerModel();
          //FIELDS RESET

          this.ResetFieldSelector(true, false);
          this.IsCustomerChange = false;
          this.IsInactiveLocationSelected=false
          //this.SchedulerReportEntity = new SchedulerReportEntity();
          this.CommonFilterComponent.resetModels();
        } else {
          this.notify.warning(this.ErrorMsgData.noDataAvailable);
        }
      })
    }

  }


  ResetField() {
    this.UnCheckAllAssetFieldList();
    this.UnCheckAllSubAssetFieldList();
  }

  ResetFieldSelector(Clear: boolean, IsCancel: boolean) {

    if (Clear && !IsCancel) {
      this.assetFieldsList = [];
      this.assetFieldsListTemp = [];
      this.subassetFieldsList = [];
      this.subassetFieldsListTemp = [];

      this.AllowAssetGroup = false;
      this.AllowAssetClass = false;
      this.AllowCustomerCode=false;
      this.AllowLocationCode=false;

    }
    else if (!Clear && IsCancel) {
      this.assetFieldsList = this.assetFieldsList;
      this.assetFieldsListTemp = this.assetFieldsList;

      this.subassetFieldsList = this.subassetFieldsList;
      this.subassetFieldsListTemp = this.subassetFieldsList;

      this.AllowAssetGroup = true;
      this.AllowAssetClass = true;
      this.AllowCustomerCode=true;
      this.AllowLocationCode=true;
      this.AllowCustomer=true;

    }
    else {
      if (this.assetFieldsListTemp.length > 0) {
        this.assetFieldsList = this.assetFieldsListTemp;
      }
      else {
        this.notify.error(this.ErrorMsgData.selectAtLeastOneAsset)
        return false;
      }

      if (this.subassetFieldsListTemp.length > 0) {
        this.subassetFieldsList = this.subassetFieldsListTemp;
      }
      else {
        this.notify.error(this.ErrorMsgData.selectAtLeastOneAsset)
        return false;
      }
    }
    this.IsAppliedField = true;
    this.openedFieldChooser = false;

  }

  PtojectProgressData(ReportType) {
    this.sendMessage();
    this.commonFilterEntity.customerID = this.CustomerIDModel
    this.commonFilterEntity.locationID = this.LocationIDModel
    this.commonFilterEntity.locationID=this.commonFilterEntity.locationID==null?0:this.commonFilterEntity.locationID;
    this.commonFilterEntity.locationID=this.commonFilterEntity.locationID==undefined?0:this.commonFilterEntity.locationID;
    this.portfolioReportEntity.reportType = ReportType;
    if (this.commonFilterEntity != null && this.commonFilterEntity != undefined) {
      if (this.commonFilterEntity.customerID != undefined && this.commonFilterEntity.customerID != 0) {
        this.progressRepootModel = [];
        this.getProgressRepotData(this.commonFilterEntity.customerID, this.commonFilterEntity.locationID,this.PortfoliocheckMode,ReportType,this.LanguageInitial);
      } else {
        this.notify.warning(this.ErrorMsgData.selectCustomer);
      }
    } else {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
    }
  }

  DurationSelect(value) {

    this.ScheduleDuration = value

    //FIELDS RESET
    this.ResetSchedulerModel();
    //FIELDS RESET
    if (value == 'WEEKLY') {
      this.SchedulerReportEntity.week_Recur = 1;
      this.SchedulerReportEntity.day_Recur = 0;
    }
    else if (value == 'DAILY') {
      this.SchedulerReportEntity.week_Recur = 0;
      this.SchedulerReportEntity.day_Recur = 1;
    }

  }
  ScheduleReport() {

    if (this.ReportType == 'PR' || this.ReportType == 'RR' || this.ReportType == "AR") {
      this.GeneratePortfolioReport(true, this.ReportType)
    }

    else if (this.ReportType == 'PPR' || this.ReportType == 'MRP') {
      this.ScheduleProjectProgressReport(this.ReportType);
    }

  }


  ScheduleProjectProgressReport(ReportType) {


    if (this.commonFilterEntity != null && this.commonFilterEntity != undefined) {
      if (this.commonFilterEntity.customerID != undefined) {
        //Setting the model
        this.portfolioReportEntity = new PortfolioReportEntity();

        this.portfolioReportEntity.format = "XLS";
        this.portfolioReportEntity.language = this.LanguageInitial;
        this.portfolioReportEntity.status = "Queued";

        this.portfolioReportEntity.customerID = this.commonFilterEntity.customerID;
        this.portfolioReportEntity.locationID = this.commonFilterEntity.locationID;
        this.portfolioReportEntity.reportType = ReportType;
        if (this.Schedulerange.start == undefined || this.Schedulerange.start == null) {
          this.notify.warning(this.ErrorMsgData.selectStartDate);
          return false;
        }
        if (this.Schedulerange.end == undefined || this.Schedulerange.end == null) {
          this.notify.warning(this.ErrorMsgData.selectEnddate);
          return false;
        }
        let TempObj = this.SchedulerReportEntity;
        this.SchedulerReportEntity = this.portfolioReportEntity
        this.SchedulerReportEntity.scheduleName = TempObj.scheduleName
        //DAILY
        if (this.ScheduleDuration == 'DAILY') {
          if (TempObj.day_Recur == undefined || TempObj.day_Recur == 0) {
            this.notify.warning(this.ErrorMsgData.selectRecuuringDay);

            return false;
          }
          this.SchedulerReportEntity.duration = this.ScheduleDuration;
          this.SchedulerReportEntity.day_Recur = TempObj.day_Recur;
          // this.SchedulerReportEntity = TempObj
        }
        //DAILY

        //WEEKLY
        let tmpWeekDays = [];
        if (this.ScheduleDuration == 'WEEKLY') {

          if (TempObj.week_Recur == undefined || TempObj.week_Recur == 0) {
            this.SchedulerReportEntity.week_Days = TempObj.week_Days;
            this.notify.warning(this.ErrorMsgData.selectWeek);
            return false;
          }
          if (TempObj.week_Days == undefined || TempObj.week_Days.length == 0) {
            this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;
            this.notify.warning(this.ErrorMsgData.selectOneDay);

            return false;
          }
          else {
            this.SchedulerReportEntity.duration = this.ScheduleDuration;
            if (TempObj.week_Days != undefined && TempObj.week_Days != "") {
              TempObj.week_Days.forEach(element => {
                tmpWeekDays.push(element.value);
              });
            }
            else {
              TempObj.week_Days = null
            }
          }
          this.SchedulerReportEntity.duration = this.ScheduleDuration;
          this.SchedulerReportEntity.week_Days = TempObj.week_Days;
          this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;
        }
        //WEEKLY

        //MONTHLY
        let tmpMonthDays = [];
        let tmpMonthDaysRecr = [];
        if (this.ScheduleDuration == 'MONTHLY') {

          if (TempObj.month_Recur == undefined || TempObj.month_Recur.length == 0) {
            this.SchedulerReportEntity.month_Days = TempObj.month_Days;
            this.notify.warning(this.ErrorMsgData.selectOneMonth);
            return false;
          }
          if (TempObj.month_Days == undefined || TempObj.month_Days.length == 0) {
            this.SchedulerReportEntity.month_Recur = TempObj.month_Recur;
            this.notify.warning(this.ErrorMsgData.selectOneMonthDay);
            return false;
          }

          else {
            if (TempObj.month_Days != undefined && TempObj.month_Days != "") {
              TempObj.month_Days.forEach(element => {
                tmpMonthDays.push(element.value);
              });
            }
            else {
              TempObj.month_Days = null
            }


            if (TempObj.month_Recur != undefined && TempObj.month_Recur != "") {
              TempObj.month_Recur.forEach(element => {
                tmpMonthDaysRecr.push(element.value);
              });
            }
            else {
              TempObj.month_Recur = null
            }
          }
          this.SchedulerReportEntity.duration = this.ScheduleDuration;
          this.SchedulerReportEntity.month_Days = TempObj.month_Days;
          this.SchedulerReportEntity.month_Recur = TempObj.month_Recur;

        }
        //MONTHLY


        this.SchedulerReportEntity.month_Days = tmpMonthDays.toString();
        this.SchedulerReportEntity.month_Recur = tmpMonthDaysRecr.toString();

        this.SchedulerReportEntity.week_Days = tmpWeekDays.toString();
        this.SchedulerReportEntity.week_Recur = TempObj.week_Recur;

        this.SchedulerReportEntity.duration = this.ScheduleDuration;
        this.SchedulerReportEntity.startDate = this.Schedulerange.start
        this.SchedulerReportEntity.endDate = this.Schedulerange.end


        //NEW CHANGES NEEDS TO BE ADDED
        //DAILOG POP-UP GeneratePortfolioReport
        this.IsOpenScheduler = false

        // this.IsReportOptionsEnabled = true
        this.ReportOptionsComponent.close('yes', this.portfolioReportEntity)


        return

        //NEW CHANGES NEEDS TO BE ADDED


        // this.coreDataService.SchedulePortolioReport(this.SchedulerReportEntity).subscribe((response) => {
        //   if (response != undefined && response != null) {
        //     this.IsOpenScheduler = false;
        //     this.notify.success(this.ErrorMsgData.reportSchedule);

        //     //FIELDS RESET
        //     this.ResetSchedulerModel();
        //     //FIELDS RESET

        //     this.ResetFieldSelector(true, false);
        //     this.IsCustomerChange = false;
        //     //this.SchedulerReportEntity = new SchedulerReportEntity();
        //     this.CommonFilterComponent.resetModels();
        //   } else {
        //     this.notify.warning(this.ErrorMsgData.noDataAvailable);
        //   }
        // })
      }
      //Sending the data to API
    } else {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
    }

  }


  async SelectedTemplate(value) {
    // this.AssetFields = event.fieldTemplate;
    // this.AssetFields = JSON.parse(dataItem.fieldTemplate)
    await this.getFieldList();
    await this.GetSubAssetFields();


  }
  checkUncheckFields(value) {
    let itemAsset = [];
    let itemSubAsset = [];
    var arr = JSON.parse(value)
    arr.forEach(element => {
      if ('assetFieldID' in element) {
        itemAsset.push(element.assetFieldID)
      }
      else if ('subAssetFieldID' in element) {
        itemSubAsset.push(element.subAssetFieldID)
      }
    })


    this.assetFieldsListTemp = itemAsset;
    console.log("-->", this.assetFieldsListTemp)
    this.subassetFieldsListTemp = itemSubAsset.length == 0 ? [2, 3] : itemSubAsset;
    this.AllowAssetGroup = true;
    this.AllowAssetClass = true;
    this.AllowCustomerCode=true;
    this.AllowLocationCode=true
    this.AllowCustomer=true

    // this.templateName = dataItem
  }

  SelectedSubTemplate(dataItem) {
    // this.AssetFields = event.fieldTemplate;
    // this.AssetFields = JSON.parse(dataItem.fieldTemplate)
    // let item = [];
    // let SelectedFieldArray = JSON.parse(dataItem.fieldTemplate)
    // SelectedFieldArray.forEach(element => {
    //   item.push(element.subAssetFieldID)
    // })
    // this.subassetFieldsListTemp = item;

    // this.SubtemplateName = dataItem

  }

  ResetSchedulerModel() {
    //FIELDS RESET
    this.Schedulerange.start = new Date()
    this.Schedulerange.end = null
    this.SchedulerReportEntity = new SchedulerReportEntity()
    //FIELDS RESET
  }



  CloseScheduler() {
    this.IsOpenScheduler = false;
    this.ResetSchedulerModel();
  }

  deleteTemplateDialog(id) {
    this.dialogOpened = true;
    this.template_id = id;
  }

  deleteTemplate() {
    this.coreDataService.DeleteAssetTemplate(this.template_id)
      .subscribe(res => {
        if (res != undefined) {
          this.templateData.forEach((ele, index) => {
            if (ele.assetReportFieldConfigID == this.template_id) {
              this.templateData.splice(index, 1);
            }
          })
          this.subtemplateData.forEach((ele, index) => {
            if (ele.assetReportFieldConfigID == this.template_id) {
              this.subtemplateData.splice(index, 1);
            }
          })
          this.notify.success(this.ErrorMsgData.recordDelete);
          this.dialogOpened = false;
        }
      })
  }
  close() {
    this.dialogOpened = false;
  }

  OpenScheduler(ReportType) {
    this.commonFilterEntity.customerID = this.CustomerIDModel
    this.commonFilterEntity.locationID = this.LocationIDModel
    this.ReportType = ReportType;
    this.portfolioReportEntity.reportType = ReportType;
    this.sendMessage();
    if (this.commonFilterEntity.customerID == undefined || this.commonFilterEntity.customerID == null || this.commonFilterEntity.customerID == 0) {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
      return false;
    
   
   
    }
    this.ScheduleDuration = 'DAILY';
    this.IsOpenScheduler = true;
  }

  ngOnDestroy(): void {
    
    this.sharedData.ReportFilterBeh$ = new BehaviorSubject<ReportFilter>(this.commonFilterEntity);
    this.reportFilter = new ReportFilter();
   
  }
  async handleSelection($event) {
    this.fieldTemplate=null
    this.isGenerateReportEnabled=true;
    this.CustomerIDModel = null
    this.LocationIDModel = null
    this.IsImageOptionsDisabled = false
    this.CommonFilterComponent.resetModels()
    // this.SelectedCustomer(this.CustomerIDModel )

    //await  this.SelectedCustomer(undefined)
    //this.SelectedReport = $event.dataItem.text
    if ($event.index.charAt(0) == "0") {
      this.SelectedReport = "Portfolio Report"
      this.ReportTypeButton = this.LanguageConfig.GenerateReport
      if ($event.index.charAt(4)!=''&& ($event.index.charAt(2) == "0" || $event.index.charAt(2) == "1")) {
        this.CommonFilterComponent.templateFilterDetails = $event.dataItem.filterTemplate
        this.CommonFilterComponent.SelectedTemplate($event.dataItem.filterTemplate)

        let SelectedFieldArray = JSON.parse($event.dataItem.filterTemplate);
        this.CustomerIDModel = SelectedFieldArray.customerID
        this.LocationIDModel = SelectedFieldArray.locationID
        this.fieldTemplate=$event.dataItem.fieldTemplate;
        if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined   || this.CustomerIDModel==""))
        {
        await this.SelectedTemplate($event.dataItem.fieldTemplate)
        await this.checkUncheckFields($event.dataItem.fieldTemplate);
        }
        

      }
    }
    else if ($event.index.charAt(0) == "1") {
      this.IsImageOptionsDisabled = true
      this.SelectedReport = "Asset Report"
      this.ReportTypeButton =  this.LanguageConfig.GenerateReport
      if ($event.index.charAt(4)!=''&& ($event.index.charAt(2) == "0" || $event.index.charAt(2) == "1")) {
        this.CommonFilterComponent.templateFilterDetails = $event.dataItem.filterTemplate
        this.CommonFilterComponent.SelectedTemplate($event.dataItem.filterTemplate)

        let SelectedFieldArray = JSON.parse($event.dataItem.filterTemplate);

        this.CustomerIDModel = SelectedFieldArray.customerID
        this.LocationIDModel = SelectedFieldArray.locationID
        this.fieldTemplate=$event.dataItem.fieldTemplate;

        // this.SelectedCustomer("")
        if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  ||this.CustomerIDModel==""))
        {
       
        await this.SelectedTemplate($event.dataItem.fieldTemplate)
        await this.checkUncheckFields($event.dataItem.fieldTemplate);
        }
     

      }
    }
    else if ($event.index.charAt(0) == "2") {
      this.IsImageOptionsDisabled = true
      this.SelectedReport = "Project Progress Report"
      this.ReportTypeButton = this.LanguageConfig.GenerateReport
    }
    else if ($event.index.charAt(0) == "3") {
      this.IsImageOptionsDisabled = true
      this.SelectedReport = "Asset Due Replacement Report"
      this.ReportTypeButton = this.LanguageConfig.GenerateReport
    }
    else if ($event.index.charAt(0) == "4") {
      this.SelectedReport = "Maintenance and Renovation Plan"
      this.ReportTypeButton = this.LanguageConfig.download
    }
    this.resetPanel();

  }

  onPanelChange(event) {

  }
  private saveAsFile(buffer: any, fileName: string, extension: string): void {
    var format= extension.split('.')[1];
    const data: Blob = new Blob([buffer], {
      type: "application/" + format + ";"
    });
    FileSaver.saveAs(data, fileName+extension);
  }
  CustomerfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
      this.sharedData.ShowDefaultFields$.next("true");
    } else if (filterData.length == 0) {
      // 23/06/21 Changes for filter data length.
      this.getCustomerbyName("");
    }
  }
  async SelectedCustomer(val) {
    if (val == undefined) {
      // 23/06/21 Changes for SelectedCustomer values
      this.CustomerDropdown = [];
      this.sharedData.ReportFilterSharedData["customerID"] = null;
      this.sharedData.ReportFilterSharedData["locationID"] = null;
      this.LocationIDModel = undefined;
      this.CustomerIDModel = undefined


      this.CommonFilterComponent.CustomerIDModel = this.CustomerIDModel
      
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.CommonFilterComponent.ApplyFilter();

    } else if (val != undefined) {
      this.LocationIDModel = null;
      // this.LocationDropdown = [];
      this.FilteredLocationDropdown = [];
      this.getLocationbyName(this.CustomerIDModel);
      this.CommonFilterComponent.CustomerIDModel = this.CustomerIDModel
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.CommonFilterComponent.ApplyFilter();
      this.resetPanel();


    }
    await this.getFieldList();
    await this.GetSubAssetFields();

  }
  SelectedLocation(val) {
    if (val == undefined) {
      this.sharedData.ReportFilterSharedData["locationID"] = null;
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.resetPanel()
    }
    if (val != undefined) {
      this.sharedData.ReportFilterSharedData["locationID"] = val;
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.CommonFilterComponent.ApplyFilter();
      this.resetPanel()
    }
    //this.openFieldChooser();
  }


  locationFilterChange(value) {
    this.FilteredLocationDropdown = [];
    if (value.length >= 3) {
      this.FilteredLocationDropdown = this.LocationDropdown.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      this.FilteredLocationDropdown = this.LocationDropdown;
    }
  }

  getCustomerbyName(searchtext) {
    // console.log(this.sharedData.parseJwt(localStorage.getItem('token')))
    let userData = this.sharedData.parseJwt(localStorage.getItem("token"));
    console.log(userData);
    this.coreDataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {
        if (userData.InventoryManagement == "False") {
          this.CustomerDropdown = res;
          this.CustomerDropdown = this.CustomerDropdown.filter((e) => {
            return e.isBusinessUnit == false;
          });
          this.getLocationbyName(this.CustomerIDModel);
        } else {
          this.CustomerDropdown = res;
          this.getLocationbyName(this.CustomerIDModel);
        }
      }
    });
  }

  getLocationbyName(customerID) {
    this.coreDataService
      .getUserLocationByCustomerDropdown("", customerID)
      .subscribe((res) => {
        if (res != undefined && res != null) {
          this.LocationDropdown = res;
          this.FilteredLocationDropdown = res;
        }
      });
  }

  async generateReport(type) {
    this.CommonFilterComponent.ApplyFilter()
    this.ResetFieldSelector(false, false)


    {
      if (this.SelectedReport == "Portfolio Report") {
        if (type == "generate") {
          this.GeneratePortfolioReport(false, 'PR')
        }
        else if (type == "schedule")
          this.OpenScheduler('PR')
      }
      else if (this.SelectedReport == "Asset Report") {
        if (type == "generate") {
          this.GeneratePortfolioReport(false, 'AR')
        }
        else if (type == "schedule")
          this.OpenScheduler('AR')
      }
      else if (this.SelectedReport == "Project Progress Report") {
        if (type == "generate")
          this.PtojectProgressData('PPR')
        else if (type == "schedule")
          this.OpenScheduler('PPR')
      }
      else if (this.SelectedReport == "Asset Due Replacement Report") {
        if (type == "generate")
          this.GeneratePortfolioReport(false, 'RR')
        else if (type == "schedule")
          this.OpenScheduler('RR')
      }
      else if (this.SelectedReport == "Maintenance and Renovation Plan") {
        if (type == "generate")
          this.GenerateRandMReport()
        else if (type == "schedule")
          this.OpenScheduler('MRP')
      }
      this.isExpanded = false
    }
   
   


  }

  resetPanel() {
    this.ReportParameter = true
    this.MoreFilter = true
    this.ChooseColumn = true
    this.Schedule = true
    this.Format = true
    this.ReportOption = true
    this.customReportButton = true
    this.IsInactiveLocationSelected=false

    if (this.SelectedReport == "Portfolio Report") {
      this.ReportParameter = false
      this.Format = false
      this.MoreFilter = false
      this.customReportButton = false
      if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  ||this.CustomerIDModel=="")){
        this.isGenerateReportEnabled=false
        this.ChooseColumn = false
        this.Schedule = false

        this.ReportOption = false

      }
    }
    else if (this.SelectedReport == "Asset Report") {
      this.ReportParameter = false
      this.MoreFilter = false
      this.Format = true
      this.customReportButton = false
      if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  ||this.CustomerIDModel=="")){
        this.isGenerateReportEnabled=false
        this.ChooseColumn = false
        this.Schedule = false

        this.ReportOption = false

      }
    }
    else if (this.SelectedReport == "Project Progress Report") {
      this.ReportParameter = false
      this.MoreFilter = true
      this.customReportButton = true
      this.Format = false
      if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  ||this.CustomerIDModel=="")){
        this.isGenerateReportEnabled=false
        this.ChooseColumn = true
        this.Schedule = false

        this.ReportOption = false

      }
    }
    else if (this.SelectedReport == "Asset Due Replacement Report") {
      this.ReportParameter = false
      this.MoreFilter = true
      this.Format = true
      this.customReportButton = true
      if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  ||this.CustomerIDModel=="")){
        this.isGenerateReportEnabled=false
        this.ChooseColumn = true
        this.Schedule = false

        this.ReportOption = false

      }
    }
    else if (this.SelectedReport == "Maintenance and Renovation Plan") {
      this.ReportParameter = false
      this.MoreFilter = true
      this.Format = true
      this.customReportButton = true
      if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  ||this.CustomerIDModel=="")){
       this.isGenerateReportEnabled=false
        this.ChooseColumn = true
        this.Schedule = true

        this.ReportOption = true

      }
    }
  }
  saveFilterTemplate() {
    this.CommonFilterComponent.ApplyFilter()
    this.CommonFilterComponent.reportFilter.customerID =
      this.CustomerIDModel == null ? "" : this.CustomerIDModel;
    this.CommonFilterComponent.reportFilter.locationID =
      this.LocationIDModel == null ? "" : this.LocationIDModel;
      if(!(this.CustomerIDModel==null || this.CustomerIDModel==undefined  || this.CustomerIDModel==""))
      {
    this.saveAsTemplate()
    
    this.customReport.fieldTemplate = JSON.stringify(this.AssetReportFieldTemplate.FieldTemplate);
  }
    this.customReport.filterTemplate = JSON.stringify(this.CommonFilterComponent.reportFilter);
    this.customReport.reportName = this.templateTitle;
    this.customReport.reportType = this.SelectedReport;
    this.coreDataService
      .SaveCustomReport(this.customReport)
      .subscribe(async (res) => {
        if (res == 1) {
          this.notify.success(this.ErrorMsgData.CustomReportSave);
          this.customReport.userID = -1;
      //this.customReportList.push(this.customReport)
      //this.setTreevalues(this.customReportList)
          await this.getCustomReport();
         // this.addSelectionToTree(this.customReport)
          await this.CommonFilterComponent.resetModels();

          //await this.getReportFilterTemplate();
        }
        else {
          this.notify.error(this.ErrorMsgData.SomethingWentWrong)
        }
      });
    this.display = false;
    this.templateTitle = null
  }
  saveCustomReport() {

    this.saveFilterTemplate()

  }
  getCustomReport() {

    this.coreDataService.GetCustomReport().subscribe(async (res) => {

      await this.setTreevalues(res);
      //this.customReportList=res
    });
  }
  async setTreevalues(res) {
    this.customReportList=[]
    this.ReportTree1[0].items[0].items=[]
    this.ReportTree1[0].items[1].items=[]
    this.ReportTree1[1].items[0].items=[]
    this.ReportTree1[1].items[1].items=[]
    this.customReportList = res;
    this.customReportList.forEach(async row => {
      await this.addSelectionToTree(row)
    })
  }

 async addSelectionToTree(row) {


    if (row.reportType == "Portfolio Report") {
      if ((row.userID == null || row.userID == undefined || row.userID == 0)) {
        this.ReportTree1[0].items[0].items.push(row)

      }
      else {
        this.ReportTree1[0].items[1].items.push(row)
      }
    }
    else if (row.reportType == "Asset Report") {

      if ((row.userID == null || row.userID == undefined || row.userID == 0)) {
        this.ReportTree1[1].items[0].items.push(row)
      }
      else {
        {
          this.ReportTree1[1].items[1].items.push(row)
        }
      }
    }
   
  }

  async resetAfterReportGenerate() {
    this.ReportParameter = true
    this.MoreFilter = true
    this.ChooseColumn = true
    this.Schedule = true
    this.Format = true
    this.ReportOption = true
    this.customReportButton = true
    this.isGenerateReportEnabled=true


  }

  async getFieldList() {
    if (this.CustomerIDModel != undefined && this.CustomerIDModel != null) {

      this.coreDataService.GetReportFields(this.CustomerIDModel).subscribe(async res => {

        this.AllowAssetGroup = true;
        this.AllowAssetClass = true;
        this.AllowCustomerCode=true;
        this.AllowLocationCode=true
        this.AllowCustomer=true
        this.AssetFields = res;
        let AssetFieldsTemp = [];
        this.AssetFields.forEach(e => {
          this.assetfieldCofig.forEach(element => {

            if (element.FieldName === e.fieldName) {

              AssetFieldsTemp.push({ "assetFieldID": e.assetFieldID, "defaultBehaviour": e.defaultBehaviour, "displayName": element.DisplayName, "fieldName": e.fieldName })
            }


          });

        })
       
         console.log(this.fieldTemplate)
        if(this.fieldTemplate!=null){
        
        //  await this.SelectedTemplate(this.fieldTemplate)
          await this.checkUncheckFields(this.fieldTemplate);
        //  this.fieldTemplate=null
        }
         else{
        
          this.AssetFields = AssetFieldsTemp;
 await this.openFieldChooser();
        this.IsCustomerChange = true;
         }
         
         this.AssetFields.sort(function (a, b) {
          return a.displayName.localeCompare(b.displayName,'en', {numeric: true});
        })
        //this.AssetFields = AssetFieldsTemp;
        //await this.openFieldChooser();
        //this.IsCustomerChange = true;
       
      })

      //--SUBASSET FIELD API
      // this.GetSubAssetFields()
      //--SUBASSET FIELD API


    }
  }
  
  onclick(row){
    
    this.coreDataService.DeleteCustomReport(row.customReportID)
      .subscribe(res => {
      
        if (row.reportType == "Portfolio Report") {
        
          var temp=[]
            this.ReportTree1[0].items[1].items.forEach(ele => {
              if(ele.customReportID!=row.customReportID)
              {
                temp.push(ele)
              }
            });
            this.ReportTree1[0].items[1].items=temp
         
        }
        else if (row.reportType == "Asset Report") {
    
          var temp=[]
              this.ReportTree1[1].items[1].items.forEach(ele => {
                if(ele.customReportID!=row.customReportID)
                {
                 temp.push(ele)
                }
              });
              this.ReportTree1[1].items[1].items=temp
         
              
        }
        
          this.notify.success(this.ErrorMsgData.CustomReportDelete);
         
      })
  }
}
