import { SnotifyService } from 'ng-snotify';
import { Component, OnInit } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { Router } from '@angular/router';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { SharedDataService } from '../../Service/CommonUtility.service';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-asset-type-management',
  templateUrl: 'asset-type-management.component.html',
  styleUrls: ['asset-type-management.component.scss']
})

export class AssetTypeManagementComponent implements OnInit {

  public Selectedvalue:any;
  public ddlDefaultassetType:{text:string,value:number}={text:'Select',value:null};
  sampleData=[];
  assetsTypeList: Array<any> = [];

  public ddlItems:Array<{text:string,value:number}>
  

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
  };
  LanguageConfig: any;
  UserData: any;
  GridConfig: any
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.assetsTypeList, this.filter);
  public gridData: GridDataResult;
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.assetsTypeList, sort), this.state);
  }

  ngOnInit() {
    // if(this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null){
    //   let langLiteral = this.sharedService.GetLanguageLiteral();
    //   this.LanguageConfig = new LanguageConfig();
    //   this.LanguageConfig.Labels = PageData[langLiteral];
    //   this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
    //   this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
    // }
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.GridConfig = GridData[langLiteral];
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[16];
          this.GridConfig = res[2];
          if(this.LanguageConfig!=undefined){
            this.ddlDefaultassetType={text:this.LanguageConfig.Select,value:null};
            this.ddlItems=[
              {text:this.LanguageConfig.All,value:0},
              {text:this.LanguageConfig.BusinessGroups,value:1},
              {text:this.LanguageConfig.NonBusinessGroups,value:2}]
            }

        }
      })
      var token = window.localStorage.getItem('token');
      this.UserData = this.sharedService.parseJwt(token);
    }
    this.getAssetsTypeList();
  }
  constructor(private titleService: Title, private sharedService: SharedDataService,
    private activatedRoute: ActivatedRoute, private notify: SnotifyService, private coreDataService: CoreDataService, private router: Router) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }
  getAssetsTypeList() {
    this.coreDataService.GetAssetTypeList().subscribe((res) => {
      if (res != undefined && res != null) {
        this.assetsTypeList = res;
        this.sampleData=this.assetsTypeList;
        this.allData = this.allData.bind(this);
        this.gridData = process(this.sampleData, this.state);
      }
    })

  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.sampleData, { sort: [{ field: 'assetTypeName', dir: 'asc' }] }).data,

    };

    return result;
  }

  MenuOnSelect(event) {
    // var baseUrl = window.location.origin + '/#/create-asset-type;assetTypeID=' + event.dataItem.assetTypeID;
    var baseUrl = window.location.origin + '/create-asset-type;assetTypeID=' + event.dataItem.assetTypeID;

    if (event.item == "Open") {

      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }

  editAssetType(value) {
    if (value != null && value != undefined) {
      this.router.navigate(["/create-asset-type", { assetTypeID: value.assetTypeID }])
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.sampleData, this.state);
  }

// This code is used to retrieve data from the dropdown.

Onchanges(){
  console.log(this.Selectedvalue);
  let dataexists= this.ddlItems.find(ele=>{return ele.value==this.Selectedvalue });
   console.log(dataexists);
   console.log(JSON.stringify(this.assetsTypeList));
    this.sampleData=this.assetsTypeList.filter((ele)=>{
       if(dataexists.value==0) {
         console.log('ALL');
         return ele;
       }
       else if(ele.isBusiness==true && dataexists.value!=2){
        console.log('BUSINESS');
            return ele;      
       }
       else if(ele.isBusiness==false && dataexists.value!=1){
        console.log('Non BUSINESS');
            return ele;      
       }

    });
    console.log(JSON.stringify(this.sampleData));
    this.state= {
      skip: 0,
      take: 20,
      sort: [{ field: "active", dir: "desc" }],
    };
    this.gridData=process(this.sampleData, this.state);
   
}

}
