import { SnotifyService } from 'ng-snotify';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { GroupDescriptor, DataResult, process } from '@progress/kendo-data-query';

import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { SortDescriptor } from '@progress/kendo-data-query';
import { CommonFunctionService } from '../../Service/common-function.service';
import { CoreDataService } from "../../Service/core-data.service";



@Component({
  selector: 'app-status-configuration',
  templateUrl: 'status-configuration.component.html',
  styleUrls: ['status-configuration.component.scss']


})
export class StatusConfigurationComponent implements OnInit {
   fieldConfigrationObject = [0];
  objectLiterals = [];
  configurationid: number;
  filterOptions = ["ConditionRating", "CriticalityCd", "StatusCd"];
  codeList: any;
  constructor(private notify:SnotifyService,private ActivatedRoute: Router, private commonFunction: CommonFunctionService, private coreDataService: CoreDataService) { }
  public gridView = [];
  public ngOnInit(): void {

    // this.loadStatusConfiguration();
  }
  // loadStatusConfiguration() {
  //   this.codeList = JSON.parse(localStorage.getItem('codeList'));
  //   this.filterOptions.map((element, index) => {
  //     this.gridView.push(this.commonFunction.filterObjectValue(this.codeList, this.filterOptions[index].)
  //     )
  //   });
  // }
  onKey(event, id, keyEvent) {
    this.configurationid = id["codeID"];
    if (this.fieldConfigrationObject.indexOf(this.configurationid) === -1) {
      this.fieldConfigrationObject.push(this.configurationid)
      this.objectLiterals.push({ id: this.configurationid, updatedValue: event.value })
    }
    if (this.fieldConfigrationObject.indexOf(this.configurationid) != -1) {
      this.fieldConfigrationObject.splice(this.fieldConfigrationObject.indexOf(this.configurationid), 1, this.configurationid)
      this.objectLiterals.splice(this.fieldConfigrationObject.indexOf(this.configurationid), 1, { id: this.configurationid, updatedValue: event.value })
    }
    if (event.value === "") {
      this.objectLiterals.splice(this.fieldConfigrationObject.indexOf(this.configurationid), 1)
    }
  }
  saveConfiguration() {
   }
  focusOutFunction() {
   }

}
