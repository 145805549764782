import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { State, process, SortDescriptor, orderBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { UserActivityModel } from './user-activity-report.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-user-activity-report',
  templateUrl: './user-activity-report.component.html',
  styleUrls: ['./user-activity-report.component.css']
})
export class UserActivityReportComponent implements OnInit {

  UserActivityModel: UserActivityModel = new UserActivityModel()
  emailTemplateID: number;
  userActivityLog: any = [];

  private state1: State = {
    skip: 0,
    sort: [{ field: 'UserName', dir: 'asc' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  LanguageConfig: any;
  GridConfig: any
  public filter: CompositeFilterDescriptor;
  public gridfilter;
  public gridData: GridDataResult;
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.userActivityLog, sort), this.state);
  }
  constructor(private titleService: Title, private notify: SnotifyService,
    private activatedRoute: ActivatedRoute, private coreDataService: CoreDataService, private sharedDataService: SharedDataService, private router: Router) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }
  public range = { start: null, end: null };
  public ConvertedRange = { start: null, end: null };

  DateTimeformat: any;
  Dateformat: any;
  ErrorMsgData: any;
  ngOnInit() {
    var StartDate = new Date();
    StartDate.setDate(StartDate.getDate() - 1);
    var EndDate = new Date();
    EndDate.setDate(EndDate.getDate() - 1);
    this.range.start = StartDate
    this.range.end = EndDate

    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
        this.Dateformat = res['DateFormat'];
      }
    })

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[44];
          this.GridConfig = res[2];
          this.ErrorMsgData = res[28];
        }
      })
    }


    this.RefreshData();

  }


  GetUserActivity(StartDate, EndDate) {

    this.coreDataService.GetUserActivity(StartDate, EndDate).subscribe((res) => {
      if (res != undefined && res != null) {
        this.userActivityLog = res;
        // this.userActivityLog.forEach(ele => {

        //   // ele['creationDate'] = new Date(ele['creationDate'])
        //   // ele['updationDate'] = ele['updationDate']
        //   ele['lastLoginDate'] = ele['lastLoginDate'] == '0001-01-01T00:00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['lastLoginDate'])
        //   ele['logOutDate'] = ele['logOutDate'] == '0001-01-01T00:00:00+00:00' ? new Date("0001-01-01T00:00:00+00:00") : new Date(ele['logOutDate'])
        //   // ele['lastLoginDate'] = new Date(ele['lastLoginDate']).getFullYear();
        //   // ele['logOutDate'] = new Date(ele['logOutDate']).getFullYear();

        // })
        this.userActivityLog = this.userActivityLog;
        this.gridData = process(this.userActivityLog, this.state);
        this.allData = this.allData.bind(this);
      }
    })
  }

  RefreshData() {
    this.ConvertedRange.start = this.range.start.getFullYear() + '-' + (this.range.start.getMonth() + 1) + '-' + this.range.start.getDate()
    this.ConvertedRange.end = this.range.end.getFullYear() + '-' + (this.range.end.getMonth() + 1) + '-' + this.range.end.getDate()
    this.GetUserActivity(this.ConvertedRange.start, this.ConvertedRange.end)

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.userActivityLog, this.state);
  }

  DownloadAssetCount: number;


  public onExcelExport(e: any): void {

    if (this.DataLength > this.DownloadAssetCount) {

      e.prevented = true;
      this.notify.warning(this.ErrorMsgData.manyRecords);
    }
  }

  DataLength: any;

  public allData(): ExcelExportData {

    if (this.state.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.userActivityLog, { sort: [{ field: 'UserName', dir: 'asc' }] }).data,

      };
      return result;
    }
    else {
      this.state1.filter.filters = this.state.filter.filters;

      const result: ExcelExportData = {

        data: process(this.userActivityLog, this.state1).data
      };
      this.DataLength = result.data.length
      return result;
    }

  }


}
