import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";

import { ViewChild, ElementRef, HostListener } from "@angular/core";

import { ChartComponent, ValueAxis } from "@progress/kendo-angular-charts";
import { saveAs } from "@progress/kendo-file-saver";

import { CoreDataService } from "../../Service/core-data.service";

import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { PlotBand } from "@progress/kendo-angular-charts";
import {
  SharedDataService,
  ReportFilter,
} from "src/app/Service/CommonUtility.service";

import * as FileSaver from "file-saver";

import {

  State,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";

import { IntlService } from "@progress/kendo-angular-intl";
import { CustomCurrency } from "../../Common/CustomCurrency.pipe";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import htmlToImage from "html-to-image";
import { Subscription } from "rxjs";
import { 
  BusinessDashboardKPIListingEntity ,
  AssetGroupCheckoutListingEntity,
  AssetTypeCheckoutListingEntity,
  AssetWithMostCheckOutListingEntity,
  AssetWithMostOverduesListingEntity,
  AssetWithMostNonCompliantListingEntity,
} from "./business-dashboard.model";

@Component({
  selector: "business-dashboard",
  templateUrl: "business-dashboard.component.html",
  styleUrls: ["business-dashboard.component.scss"],
  providers: [CustomCurrency],
})
export class BusinessDashboardComponent implements OnInit, OnDestroy {
  reportFilter: ReportFilter = new ReportFilter();
  BudgetChartLabel: string;
  public FilteredLocationDropdown: Array<{ name: string; locationID: number }> =
    [];
  public LocationDropdown: Array<{ name: string; locationID: number }> = [];
  public CustomerDropdown: Array<{
    customerName: string;
    customerID: number;
    isBusinessUnit: boolean;
  }> = [];
  myActiveProjectsData: any[] = [];
  techAuditData: any[] = [];
  projectDuration: any[] = [];
  CustomerID: any=null;
  LocationID: any=null;
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  @ViewChild("AssetGroupChart",{static:false}) AssetGroupChart: ChartComponent;
  @ViewChild("AssetTypeChart",{static:false}) AssetTypeChart: ChartComponent;
  

  isShowBacktoTop: boolean;
  public opened = false;
  displayError: any;
  sub: Subscription;
  //report Models

 
  currencySymbol: any;
  
  message: any;
  Dateformat: any;

  @HostListener("window:scroll")
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

   

    
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  private state1: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  private state2: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public filter: CompositeFilterDescriptor;
  //public gridfilter: any[] = filterBy(this.myActiveProjectsData, this.filter);
  LanguageConfig: any;
  GridConfig: any;
  TotalAssetCountRefeg: number;
  disabledKpICall: boolean = false;

  constructor(
    private http: HttpClient,
    private currencyPipe: CustomCurrency,
    public intl: IntlService,
    private DataService: CoreDataService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private router: Router
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
  }

  async ngOnInit() {
    this.getCustomerbyName("");
    // this.getLocationbyName("");
    this.CustomerID =
      this.sharedDataService.ReportFilterSharedData["customerID"];
    if (this.CustomerID != null || this.CustomerID != undefined) {
      this.getLocationbyName(this.CustomerID);
    }
    this.LocationID =
      this.sharedDataService.ReportFilterSharedData["locationID"]==undefined?null:Number(this.sharedDataService.ReportFilterSharedData["locationID"])
    this.updateAllKpIS();
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[66];
        }
      });
    }
    

    var token = window.localStorage.getItem("token");
    if (token != null && token != undefined && token != "") {
      this.UserData = this.sharedDataService.parseJwt(token);
    }
    this.sharedDataService.GetOrgSettings().then((res) => {
     
      this.currencySymbol = res.CurrencySymbol;
      this.Dateformat = res['DateFormat'];
    
    });
    
   
 
    this.bindCodeData();
   
 
  }

  
   

  

  RefreshData() {
    if (this.CustomerID != undefined) {
      this.reportFilter.customerID = this.CustomerID;
      if (this.LocationID == null) {
        this.updateAllKpIS();
      } else {
      
        this.reportFilter.locationID = this.LocationID;
     
        this.updateAllKpIS();
      }
    } else {
      this.displayError = this.LanguageConfig.SelectCustomer;
    }
  }

  async updateAllKpIS() {
    await this.getDashboardKPI(this.CustomerID,this.LocationID);
    await this.getDashboardAssetGroupChart(this.CustomerID,this.LocationID);
    await this.getDashboardAssetTypeChart(this.CustomerID,this.LocationID);
    await this.getDashboardAssetsCheckout(this.CustomerID,this.LocationID);
    await this.getDashboardAssetsOverdues(this.CustomerID,this.LocationID);
    await this.getDashboardAssetsNonCompliant(this.CustomerID,this.LocationID)
    
  }

  public labelContent(e: any): string {
    return `${e.category} (${e.dataItem.refrigerantQty})`;
  }
  public exportChart(type): void {
    var node;
    if (type == "AssetGroupMostCheckout") {
      this.AssetGroupChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "AssetTypeMostCheckOut") {
      this.AssetTypeChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    
    } else if (type == "AssetMostCheckouts") {
       node = document.getElementById("AssetMostCheckouts");
    } else if (type == "AssetOverdue") {
       node = document.getElementById("AssetOverdue");
    }  else if (type == "assetsNonCompliant") {
         node = document.getElementById("assetsNonCompliant");
    }

      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          FileSaver.saveAs(dataUrl, type + ".png");
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    
  }


  DashboardKPIListing: BusinessDashboardKPIListingEntity =
    new BusinessDashboardKPIListingEntity();

  AssetGroupCheckoutListingEntity: AssetGroupCheckoutListingEntity =
    new AssetGroupCheckoutListingEntity();
    AssetTypeCheckoutListingEntity: AssetTypeCheckoutListingEntity =
    new AssetTypeCheckoutListingEntity();
    AssetWithMostCheckOutListingEntity:Array<AssetWithMostCheckOutListingEntity>=[]
    
    AssetWithMostOverduesListingEntity:Array<AssetWithMostOverduesListingEntity>=[]
    
    AssetWithMostNonCompliantListingEntity:Array<AssetWithMostNonCompliantListingEntity>=[]

 

  public ddlCodeData: Array<any> = new Array<any>();
  public AssetGroupData: Array<any> = new Array<any>();
  public AssetTypeData: Array<any> = new Array<any>();
  UserData: any;
  Math: any;
 
  bindCodeData() {
    this.DataService.getCodeData().subscribe((res) => {
      if (res != undefined && res != null) {
        this.ddlCodeData.push(res);
      }
    });
  }

  getLocationbyName(customerID) {
    this.DataService.getUserLocationByCustomerDropdown(
      "",
      customerID
    ).subscribe((res) => {
      if (res != undefined && res != null) {
        this.LocationDropdown = res;
        this.FilteredLocationDropdown = this.LocationDropdown;
      }
    });
  }
  CustomerfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
    } else if (filterData.length == 0) {
      // 23/06/21 Changes for filter data length.
      this.getCustomerbyName("");
      this.CustomerID = null;
      this.LocationID = null;
      this.sharedDataService.ReportFilterSharedData["customerID"] = null;
      this.sharedDataService.ReportFilterSharedData["locationID"] = null;
    }
  }
  SelectedCustomer(val) {
    console.log(val);
    if (val == undefined) {
      this.CustomerDropdown = [];
      this.getCustomerbyName("");
      this.sharedDataService.ReportFilterSharedData["customerID"] = null;
      this.sharedDataService.ReportFilterSharedData["locationID"] = null;
      this.updateAllKpIS()
      // this.getLocationbyName('');
    } else if (val != undefined) {
      this.sharedDataService.ReportFilterSharedData["customerID"] = val;
      this.LocationID = null;
      this.LocationDropdown = [];
      this.FilteredLocationDropdown = [];
      this.updateAllKpIS()
      this.getLocationbyName(this.CustomerID);
    }
    //this.getCustomerbyName("");
  }
  SelectedLocation(val) {
    if (val == undefined) {
      this.sharedDataService.ReportFilterSharedData["locationID"] = null;
      this.updateAllKpIS()
    }
    if (val != undefined) {
      this.sharedDataService.ReportFilterSharedData["locationID"] = val;
      this.updateAllKpIS()
    }
  }
  locationFilterChange(value) {
    this.FilteredLocationDropdown = [];
    if (value.length >= 3) {
      this.FilteredLocationDropdown = this.LocationDropdown.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      // this.getLocationbyName('')
      this.FilteredLocationDropdown = this.LocationDropdown;
    }
  }
  getCustomerbyName(searchtext) {
    console.log(searchtext);
    let userData = this.sharedDataService.parseJwt(
      localStorage.getItem("token")
    );
    this.DataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {
        if (userData.InventoryManagement == "False") {
          this.CustomerDropdown = res;
          this.CustomerDropdown = this.CustomerDropdown.filter((e) => {
            return e.isBusinessUnit == false;
          });
        } else {
          this.CustomerDropdown = res;
        }
      }
    });
  }

  getDashboardAssetGroupChart(CustomerID,LocationID) {
    this.DataService.getDashboardAssetGroupChart(CustomerID!=undefined?CustomerID:0,LocationID!=undefined?LocationID:0).subscribe(
      (res) => {
        this.AssetGroupData = res;
        let assetGroupName = [];
        let count = [];

        res.forEach((element) => {
          assetGroupName.push(element.assetGroupName);
          count.push(element.count);
        });
        this.AssetGroupCheckoutListingEntity.assetGroupName =
        assetGroupName;
        this.AssetGroupCheckoutListingEntity.count = count;
      }
    );
  }
  getDashboardAssetTypeChart(CustomerID,LocationID) {
    this.DataService.getDashboardAssetTypeChart(CustomerID!=undefined?CustomerID:0,LocationID!=undefined?LocationID:0).subscribe(
      (res) => {
        this.AssetTypeData = res;
        let assetTypeName = [];
        let count = [];

        res.forEach((element) => {
          assetTypeName.push(element.assetTypeName);
          count.push(element.count);
        });
        this.AssetTypeCheckoutListingEntity.assetTypeName =
        assetTypeName;
        this.AssetTypeCheckoutListingEntity.count = count;
      }
    );
  }
  getDashboardAssetsCheckout(CustomerID,LocationID) {
    this.DataService.getDashboardAssetsCheckout(CustomerID!=undefined?CustomerID:0,LocationID!=undefined?LocationID:0).subscribe(
      (res: any = []) => {
       this.AssetWithMostCheckOutListingEntity=res
      }
    );
  }
  getDashboardAssetsOverdues(CustomerID,LocationID) {
    this.DataService.getDashboardAssetsOverdues(CustomerID!=undefined?CustomerID:0,LocationID!=undefined?LocationID:0).subscribe(
      (res: any = []) => {
       this.AssetWithMostOverduesListingEntity=res
      }
    );
  }
  getDashboardAssetsNonCompliant(CustomerID,LocationID) {
    this.DataService.getDashboardAssetsNonCompliant(CustomerID!=undefined?CustomerID:0,LocationID!=undefined?LocationID:0).subscribe(
      (res: any = []) => {
       this.AssetWithMostNonCompliantListingEntity=res
      }
    );
  }
 
  public pointColor(point): string {
    if (point.index == 0) return "red";
    if (point.index == 1) return "yellow";
    if (point.index == 2) return "red";
    if (point.index == 3) return "red";
    if (point.index == 4) return "red";
    if (point.index == 5) return "red";
    else return "green";
  }
  
  
  getDashboardKPI(CustomerID,LocationID) {
  
    this.DataService.getBusinessDashboardKPI(CustomerID!=undefined?CustomerID:0,LocationID!=undefined?LocationID:0).subscribe((res) => {
     res=res[0]
      this.DashboardKPIListing.assetsAvailable = res.assetsAvailable;
      this.DashboardKPIListing.assetsAvaliableButBooked = res.assetsAvailableButBooked;
      this.DashboardKPIListing.assetsReturnOverdue = res.assetsReturnOverdue
      
      this.DashboardKPIListing.assetsNonCompliant = res.assetsNonCompliant;
      this.DashboardKPIListing.assetsCheckedInPreviousDay = res.assetsCheckedInPreviousDay
     
    });
  }


  public open() {
    this.opened = true;
  }

  public close(status) {
    this.opened = false;
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
    this.reportFilter = new ReportFilter();
  }

  ApplyFilter() {
    this.sharedDataService.ReportFilterSharedData = new ReportFilter();
    this.sharedDataService.ReportFilterSharedData.iMStatus=this.reportFilter.iMStatus
    this.sharedDataService.ReportFilterSharedData.checkInStartDate=this.reportFilter.checkInStartDate
    this.sharedDataService.ReportFilterSharedData.checkInEndDate=this.reportFilter.checkInEndDate
   this.sharedDataService.ReportFilterSharedData.AssetID=this.reportFilter.AssetID
   // this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);


    this.router.navigate(["/business-assets-listing"]);
  }

  AssetsAvailable(){
    this.reportFilter.iMStatus='Available'
  this.ApplyFilter()
  }
  AssetsAvailableButBooked(){
    this.reportFilter.iMStatus='Available-check bookings'
    this.ApplyFilter()
  }
  AssetsNonCompliant(){
    this.reportFilter.iMStatus='Non Compliant'
    this.ApplyFilter()
  }
  AssetsReturnOverdue(){
    this.reportFilter.iMStatus='Return overdue'
    this.ApplyFilter()
  }
 
  AssetsCheckedInPreviousDay(){
    var PreviousDate = new Date();
    PreviousDate.setDate(PreviousDate.getDate() - 1);
    this.reportFilter.checkInStartDate = PreviousDate.getFullYear() + '-' + (PreviousDate.getMonth() + 1) + '-' + PreviousDate.getDate()
    this.reportFilter.checkInEndDate = PreviousDate.getFullYear() + '-' + (PreviousDate.getMonth() + 1) + '-' + PreviousDate.getDate()
    this.ApplyFilter()
  }

  AssetWithMostNonCompliantClick(dataItem){
    this.reportFilter.AssetID = dataItem.assetID.toString();
    this.ApplyFilter()
  }
  AssetWithMostOverduesClick(dataItem){
    this.reportFilter.AssetID = dataItem.assetID.toString();
    this.ApplyFilter()
  }
  AssetWithMostCheckOutClick(dataItem){
    this.reportFilter.AssetID = dataItem.assetID.toString();
    this.ApplyFilter()
  }
}
