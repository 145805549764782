import { Component, OnInit } from '@angular/core';
import { FaultModelClass, FixClass, GroupModel } from './faultfix.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { State, process, SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SnotifyService } from 'ng-snotify';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faultfix-management',
  templateUrl: './faultfix-management.component.html',
  styleUrls: ['./faultfix-management.component.css']
})
export class FaultfixManagementComponent implements OnInit {
  faultGroupName: any
  faultmanagement: FaultModelClass = new FaultModelClass();
  fixClass: FixClass = new FixClass();
  createGroupName: GroupModel = new GroupModel()
  toggleField: boolean = true;
  fault_group_id: number;
  selectedGroup: any;
  LanguageConfig: any;
  faultsData: any = [];
  FaultGroupListing: any = [];
  GridConfig: any;
  fixListingArray: any = [];
  public filter: CompositeFilterDescriptor;
  faultfixForm: FormGroup;
  fixForm: FormGroup;
  private gridData: GridDataResult;
  private gridFixData: GridDataResult;
  ErrorDataMsg: any;
  faultList: Array<any> = [];
  fixList: Array<any> = [];
  private state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
  };
  public gridfilter: any[] = filterBy(this.faultList, this.filter);
  public fixGridData: any[] = filterBy(this.fixList, this.filter);
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.faultList, sort), this.state);
    // this.gridFixData = process(orderBy(this.fixList, sort), this.state);
  }

  public sortFixChange(sort: SortDescriptor[]): void {
    this.gridFixData = process(orderBy(this.fixList, sort), this.state);
  }
  constructor(
    private fb: FormBuilder, private coreDataService: CoreDataService, private notify: SnotifyService, private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute, private titleService: Title
  ) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {


    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[25];
          this.ErrorDataMsg = res[28];
          this.GridConfig = res[2];

        }
      })
    }
    this.getFaultListing();
    this.fixListing();
    this.getGroupListing();


    this.faultfixForm = this.fb.group({
      faultGroupID: [''],
      groupName: [''],
      faultName: ['']
    })

    this.fixForm = this.fb.group({
      fixName: ['']
    })
  }


  addGroup() {
    this.toggleField = !this.toggleField;
  }

  TemplateClose() {
    this.toggleField = !this.toggleField;
  }
  onValueChange(value) {
    this.fault_group_id = value;
  }

  onTabchange(event) {
    if (event.title == "Fault") {
      this.getFaultListing();
      this.getGroupListing();
    }
    else if (event.title == "Fix") {
      this.fixListing();
    }
  }

  saveFault(formData) {
    this.faultmanagement = this.faultmanagement;
    if (this.faultmanagement !== undefined && this.faultmanagement.faultID == null || this.faultmanagement.faultGroupID != formData.faultGroupID) {
      this.faultmanagement = formData;
      this.faultmanagement.faultID = 0;
      this.coreDataService.saveAsFault(this.faultmanagement)
        .subscribe(data => {
          if (data != undefined) {
            this.notify.success(this.ErrorDataMsg.faultSaved);
          }
          this.getFaultListing();
        })
      // this.getFaultListing();
    }
    else if (this.faultmanagement !== undefined && this.faultmanagement.faultID > 0 || this.faultmanagement.faultGroupID == formData.faultGroupID) {
      this.faultmanagement.faultID = this.faultmanagement.faultID;
      this.faultmanagement.faultName = this.faultfixForm.value.faultName;
      this.faultmanagement.groupName = "";
      this.coreDataService.saveAsFault(this.faultmanagement)
        .subscribe(data => {
          if (data != undefined) {
            this.notify.success(this.ErrorDataMsg.faultUpdated);
          }
        })
      this.getFaultListing();
    }
    else {
      this.notify.warning(this.ErrorDataMsg.selectfault);
      this.getFaultListing();
      this.faultmanagement = new FaultModelClass();
      this.faultfixForm.reset();
    }
  }

  EditFault(data) {
    window.scrollTo(0, 0);
    this.faultmanagement = data;
    if (data.faultID != null && data.faultID != undefined) {
      //  this.editModel = data;
      this.faultfixForm.controls["faultGroupID"].setValue(this.faultmanagement.faultGroupID);
      this.faultfixForm.controls["faultName"].setValue(this.faultmanagement.faultName);
    }
  }

  getFaultListing() {
    this.coreDataService.faultList()
      .subscribe(res => {
        // const result = Array.from(res.reduce((m, t) => m.set(t.faultGroupID, t), new Map()).values());
        this.faultList = res;
        this.gridData = process(res, this.state);
      })
  }

  saveNewFaultGroup(data) {
    this.faultmanagement.faultGroupID = 0;
    this.faultmanagement.groupName = data.groupName;
    this.coreDataService.saveFaultBYGroup(this.faultmanagement)
      .subscribe(res => {
        this.fault_group_id = res;
        this.getGroupListing();
      })
    this.faultfixForm.reset();
  }

  getGroupListing() {
    this.coreDataService.getFaultGroupList()
      .subscribe(data => {
        this.FaultGroupListing = data;
        for (let index = 0; index < this.FaultGroupListing.length; index++) {
          const element = this.FaultGroupListing[0].faultGroupID;
          console.log(element)
          this.faultfixForm.controls["faultGroupID"].setValue(element);
        }

      })

  }
  //save fix
  saveFix() {
    if (this.fixClass.fixID > 0) {
      this.fixClass.fixName = this.fixForm.value.fixName;
      this.fixClass.fixID = this.fixClass.fixID;
      this.coreDataService.saveAsFix(this.fixClass)
        .subscribe(res => {
          this.fixListing();
        })
    }
    else if (this.fixClass.fixID == null) {
      this.fixClass.fixName = this.fixForm.value.fixName;
      this.fixClass.fixID = 0;
      this.coreDataService.saveAsFix(this.fixClass)
        .subscribe(res => {
        })
      this.fixListing();
    }
    this.fixClass = new FixClass();
    this.fixListing();
    this.fixForm.reset();
  }

  fixListing() {
    this.coreDataService.FixListing()
      .subscribe(res => {
        this.fixList = res;
        this.gridFixData = process(res, this.state);
      })
  }

  editFix(data) {
    this.fixClass = data;
    this.fixForm.controls['fixName'].setValue(this.fixClass.fixName)
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.faultList, this.state);
  }
  protected dataStateFixChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridFixData = process(this.fixList, this.state);
  }
}
