import { Injectable } from '@angular/core';
// import {Http, Response, RequestOptions, Headers} from "@angular/http"import
import { Http, Response } from "@angular/http";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject } from 'rxjs/Rx';
import { Router } from "@angular/router";
import 'rxjs/Rx';
// import { JwtHelper } from 'angular2-jwt';
// import { AuthHttp, tokenNotExpired } from 'angular2-jwt';
import { environment } from "../../environments/environment";
import { SharedDataService } from "./CommonUtility.service";
import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable()
export class AuthenticationService {
  public loggedIn: boolean = false;

  public UserInfo: any;
  userInfo$ = new BehaviorSubject<any>(this.UserInfo);
  headers = new HttpHeaders();
  Token: any;
  baseUrl = environment.environment.authUrl;
  apiUrl = environment.environment.apiUrl;
  globals: any;

  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  constructor(private http: HttpClient,private deviceService: DeviceDetectorService, private router: Router,
    private sharedDataService: SharedDataService) {
    if (this.authenticated) {
      this.setLoggedIn(true);
    }
  }
  

  getLogin(object: any) {
    var data = "grant_type=password&username=" + object.email + "&password=" + object.password;
    let TimeZone;
    if(new Date().toString().substr(new Date().toString().indexOf('GMT')).split('+').length > 1){
      let GMT =  new Date().toString().substr(new Date().toString().indexOf('GMT')).split('+')['0'];
      let hour = new Date().toString().substr(new Date().toString().indexOf('GMT')).split('+')['1'].split(' ')[0].substring(0,2)
      let Minute =  new Date().toString().substr(new Date().toString().indexOf('GMT')).split('+')['1'].split(' ')[0].substring(2,4)
      TimeZone = GMT +'+'+ hour+ ':'+Minute 
    }
    else if(new Date().toString().substr(new Date().toString().indexOf('GMT')).split('-').length > 1){
      let GMT =  new Date().toString().substr(new Date().toString().indexOf('GMT')).split('-')['0'];
      let hour = new Date().toString().substr(new Date().toString().indexOf('GMT')).split('-')['1'].split(' ')[0].substring(0,2)
      let Minute =  new Date().toString().substr(new Date().toString().indexOf('GMT')).split('-')['1'].split(' ')[0].substring(2,4)
      TimeZone = GMT +'-'+ hour+ ':'+Minute 
    }
     
    
    //DeviceInfo
   
    //  var DeviceInfo = JSON.stringify({'SessionID':'','UserID ':0,'AgentType' : 'web','AgentName' : this.deviceService.getDeviceInfo().browser ,'Version': this.deviceService.getDeviceInfo().browser_version,'ApplicationVersion':environment.environment.version,'TimeZone':TimeZone})
    var DeviceInfo = JSON.stringify({'SessionID':'','UserID ':0,'AgentType' : 'web','AgentName' : this.deviceService.getDeviceInfo().browser ,'Version': this.deviceService.getDeviceInfo().browser_version,'ApplicationVersion':environment.environment.version,'TimeZone':TimeZone})
     let requestHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
              .append('DeviceInfo', DeviceInfo);
    
    //here we have extracted the data from the payload object
    return this.http.post(this.baseUrl + 'token', data, {
      headers: requestHeaders      
    })
      .map((response: HttpResponse<any>) => response).catch(err => {   throw err});
  }
  getExternalLogin(email, password, ExternalLogin) {
    var data = "grant_type=password&username=" + email + "&password=" + password + "&ExternalLogin=" + ExternalLogin;

    //here we have extracted the data from the payload object
    return this.http.post(this.baseUrl + 'token', data, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    })
      .map((response: HttpResponse<any>) => response).catch(this.handleError);
  }

  _setSession(authResult) {
    // Save session data and update login status subject
    localStorage.setItem('token', authResult.access_token);
    this.setLoggedIn(true);
  }

  // jwtHelper: JwtHelper = new JwtHelper();
  ExpiryTime: number = 0;
  useJwtHelper() {
    // var token = localStorage.getItem('token');
    // let tokenVal = this.jwtHelper.decodeToken(token);
    // let isTokenExpired = this.jwtHelper.isTokenExpired(token);

    // let tokenExpirationDate: any = this.jwtHelper.getTokenExpirationDate(token);
    // let currentDateTime: any = new Date();
    // this.ExpiryTime = tokenExpirationDate - currentDateTime;
  }
  setLoggedIn(value) {
    if (value != null && value != undefined && !value) {
      return;
    }
    // this.useJwtHelper();
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
    // this.router.navigate(['dashboard']);
  }


  isAuthenticated() {

    this.globals = localStorage.getItem('token');
    return !!this.globals;
  }

  isLoggedIn() {
    return this.loggedIn$.asObservable();
  }

  getUserData() {
    return this.globals || {};
  }

  private handleSuccess(res) {
    return res.json();
  }

  private handleError(error: Response | any) {
    //  will use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      if (error.status == 0) {
        window.alert("Network error. Please contact support");
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }


    return Observable.throw(errMsg);
  }
  get authenticated() {
    // return tokenNotExpired('token');
    
    return localStorage.getItem('token') == null ? false : true;
  }
}


