import { Component, OnInit, Input } from '@angular/core';

import { CoreDataService } from '../../Service/core-data.service';
import { AdminListingEntity } from './admin-tab.model';
import { AdminEntity } from '../../Common/shared-model';
import { SharedDataService } from "../../Service/CommonUtility.service";

import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-admin-tab',
  templateUrl: './admin-tab.component.html',
  styleUrls: ['./admin-tab.component.scss']
})
export class AdminTabComponent implements OnInit {

  constructor(private DataService: CoreDataService, private sharedDataService: SharedDataService) { }

  AdminListingEntity: AdminListingEntity = new AdminListingEntity();
  DateTimeformat: any;
  LanguageConfig: any;
  ErrorMsgData: any

  @Input('AdminEntity') AdminEntity: AdminEntity;

  ngOnInit() {


    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[42];
          this.ErrorMsgData = res[28];
        }
      })
    }

    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
      }
    })

    if (this.AdminEntity.Table != undefined && this.AdminEntity.AssociatedID != undefined) {
      this.DataService.getUserAdminDetails(this.AdminEntity.Table, this.AdminEntity.AssociatedID).subscribe(r => {

        this.AdminListingEntity.createdByName = r.createdByName;
        this.AdminListingEntity.updatedByName = r.updatedByName;
        this.AdminListingEntity.creationDate = r.creationDate;
        this.AdminListingEntity.updationDate = r.updationDate;
        // this.AdminListingEntity.creationDate = r.creationDate =='0001-01-01T00:00:00+00:00' ? '' :r.creationDate;
        // this.AdminListingEntity.updationDate = r.updationDate =='0001-01-01T00:00:00+00:00' ? '' :r.updationDate;
        // this.AdminListingEntity.creationDate = r.creationDate =='0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') :new Date(r.creationDate);
        // this.AdminListingEntity.updationDate = r.updationDate =='0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(r.updationDate);
      })
    }

  }
}
