import { SnotifyService } from 'ng-snotify';
import { Component, OnInit, ViewChild } from '@angular/core';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CoreDataService } from '../../Service/core-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { assetGlobalSearchEntity, customerGlobalSearchEntity, locationGlobalSearchEntity } from '../global-search/global-search.model';
import { from } from 'rxjs';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
//import {TabStripComponent} from '@angular/'
const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

  assetFGlobalSearchList: Array<assetGlobalSearchEntity>;
  customerGlobalSearchList: Array<customerGlobalSearchEntity>;
  locationGlobalSearchList: Array<locationGlobalSearchEntity>;
  public pageSize = 70;
  private customerState: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "status", dir: "desc" }],
  };
  private assetState: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "status", dir: "desc" }],
  };
  private locationstate: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "status", dir: "desc" }],
  };
  customerCount: number;
  assetCount: number;
  locationCount: number;
  searchText: string;
  subs: any;
  show: boolean = false;
  UserData: any;
  ErrMessagesData: any;
  // DashboardRouting: any;
  LanguageConfig: any;
  GridConfig: any
  public selectedTab: number = 0;
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.assetFGlobalSearchList, this.filter);
  public gridfilter1: any[] = filterBy(this.customerGlobalSearchList, this.filter);
  public gridfilter2: any[] = filterBy(this.locationGlobalSearchList, this.filter);

  private LocGridData: GridDataResult;
  private customerGridData: GridDataResult;
  private assetGridData: GridDataResult;
  public sortChange(sort: SortDescriptor[]): void {
    this.assetGridData = process(orderBy(this.assetFGlobalSearchList, sort), this.assetState);

    this.customerGridData = process(orderBy(this.customerGlobalSearchList, sort), this.customerState);
    this.LocGridData = process(orderBy(this.locationGlobalSearchList, sort), this.locationstate);
  }

  constructor(private titleService: Title,
    private notify: SnotifyService, private coreDataService: CoreDataService, private router: Router, private sharedService: SharedDataService,
    private activatedRoute: ActivatedRoute) {

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {

    this.subs = this.sharedService.globalSearchText$.subscribe(
      (obj) => {
        if (obj != undefined && obj != null) {
          this.searchText = obj;
          if(this.searchText !='' && this.searchText!=null && this.searchText!=undefined){
            this.getAssetFGlobalSearchList(this.searchText);
            this.getCustomerGlobalSearchList(this.searchText);
            this.getLocationGlobalSearchList(this.searchText);
          }        
        }
      }
    );

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[54];
          this.ErrMessagesData = res[28];
          this.GridConfig = res[2];
        }
      })
    }

    var token = window.localStorage.getItem('token');

    this.UserData = this.sharedService.parseJwt(token);
    console.log(this.UserData.RoleType)
    if (this.UserData.RoleType == 'CUSTOMER' || this.UserData.RoleType == 'REVIEWER' || this.UserData.RoleType == 'AUDITOR') {
      this.show = true;
    }
    else {
      this.show = false;
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  getAssetFGlobalSearchList(searchText) {
    let userData = this.sharedService.parseJwt(localStorage.getItem('token'))
    this.coreDataService.GetAssetGlobalSearchList(searchText).subscribe((res) => {
      if (res != undefined && res != null) {

        if (userData.InventoryManagement == 'False') {
          this.assetFGlobalSearchList = res;
          this.assetFGlobalSearchList = this.assetFGlobalSearchList.filter(e => { return e.isDepartment == false })
          this.assetCount = this.assetFGlobalSearchList.length;
          this.assetGridData = process(this.assetFGlobalSearchList, this.assetState);
        }
        else
        {
          this.assetFGlobalSearchList = res;
          this.assetCount = this.assetFGlobalSearchList.length;
          this.assetGridData = process(this.assetFGlobalSearchList, this.assetState);
        }

      }
    })
  }
  getCustomerGlobalSearchList(searchText) {

    let userData = this.sharedService.parseJwt(localStorage.getItem('token'))

    this.coreDataService.GetCustomerGlobalSearchList(searchText).subscribe((res) => {
      if (res != undefined && res != null) {
        if (userData.InventoryManagement == 'False') {
          this.customerGlobalSearchList = res;
          this.customerGlobalSearchList = this.customerGlobalSearchList.filter(e => { return e.isBusinessUnit == false })
          this.customerCount = this.customerGlobalSearchList.length;
          this.customerGridData = process(this.customerGlobalSearchList, this.customerState);
        }
        else {
          this.customerGlobalSearchList = res;
          this.customerCount = this.customerGlobalSearchList.length;
          this.customerGridData = process(this.customerGlobalSearchList, this.customerState);
        }

      }
    })
  }
  getLocationGlobalSearchList(searchText) {

    let userData = this.sharedService.parseJwt(localStorage.getItem('token'))

    this.coreDataService.GetLocationGlobalSearchList(searchText).subscribe((res) => {
      if (res != undefined && res != null) {

        if (userData.InventoryManagement == 'False') {
          this.locationGlobalSearchList = res;
          this.locationGlobalSearchList = this.locationGlobalSearchList.filter(e => { return e.isDepartment == false })
          this.locationCount = this.locationGlobalSearchList.length;
          this.LocGridData = process(this.locationGlobalSearchList, this.locationstate);
        }
        else {
          this.locationGlobalSearchList = res;
          this.locationCount = this.locationGlobalSearchList.length;
          this.LocGridData = process(this.locationGlobalSearchList, this.locationstate);
        }

      }
    })
  }
  protected customerStateChange(state: DataStateChangeEvent): void {
    this.customerState = state;
    this.customerGridData = process(this.customerGlobalSearchList, this.customerState);
  }
  protected assetStateChange(state: DataStateChangeEvent): void {
    this.assetState = state;
    this.assetGridData = process(this.assetFGlobalSearchList, this.assetState);

  }
  protected locationStateChange(state: DataStateChangeEvent): void {
    this.locationstate = state;
    this.LocGridData = process(this.locationGlobalSearchList, this.locationstate);
  }


  editDetails(event) {
    // let currOrigin = window.location.origin + '/#/add-customer/' + event.customerID;
    let currOrigin = window.location.origin + '/add-customer/' + event.customerID;
    window.open(currOrigin);
  }
  EditAsset(id) {
    // let currOrigin = window.location.origin + '/#/AddAssets/' + id;
    let currOrigin = window.location.origin + '/AddAssets/' + id;
    window.open(currOrigin);
  }
  EditLocation(LocationID) {

    // let currOrigin = window.location.origin + '/#/add-location/' + LocationID;
    let currOrigin = window.location.origin + '/add-location/' + LocationID;
    window.open(currOrigin);
  }

}
