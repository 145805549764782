

export class ExpenseDashboard{
public  assetID:string;
public  installDate:Date
public  replacementCost:number
public plannedReplacementYear:Date
public rmBudgetCost:number
public rmExpense:number
public totalSpend:number
public userAssetID:string
public conditionRating:string
public calculatedReplacementYear:number
public replacementYear:number
public plannedRepacement:number


}


export interface InetData {
    name: string;
    data: {
      category: string;
      value: number;
      color: string;
      label:string;
    }[];
  }

 