import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.css']
})
export class WhatsNewComponent implements OnInit {

  constructor() { }
 
  Link : any
  ngOnInit() {

 this.Link = window.location.origin + '/app'
    //alert(this.Link)
  }

  
  
}
