import { Component, OnInit, Input } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { SnotifyService } from 'ng-snotify';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { UserContextList } from '../active-sessions/active-sessions.model';
import { from } from 'rxjs';
@Component({
  selector: 'app-active-sessions',
  templateUrl: './active-sessions.component.html',
  styleUrls: ['./active-sessions.component.css']
})
export class ActiveSessionsComponent implements OnInit {
  UserContextList: Array<UserContextList> = new Array<UserContextList>();
  DateFormat: string;




  // public expandMode: number = PanelBarExpandMode.Full;
  // public kendoPanelBarExpandMode: any = PanelBarExpandMode;
  // private height = 320;
  // public onChange(event: any): void {
  //   this.expandMode = parseInt(event.target.value, 10);
  // }


  constructor(private DataService: CoreDataService, private sharedService: SharedDataService, private notify: SnotifyService) { }
  @Input('UserID') UserID: number;
  
  ngOnInit() {
    // this.DateFormat = this.sharedService.dateTimeFormat();
    this.sharedService.GetOrgSettings().then(res =>{
      if(res != undefined){
        this.DateFormat = res['DateFormat'] + ' ' + res['TimeFormat']
      }
    })

    var token = window.localStorage.getItem('token');
    let UserData = this.sharedService.parseJwt(token);
    if (UserData != undefined) {
      this.DataService.GetUserContextByUserID(UserData.UserID).subscribe(res => {
        if (res != undefined && res != null) {
          this.UserContextList = res;
        } else {
          this.notify.error("Something went wrong");
        }




      })
    }
  }
}