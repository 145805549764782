export class BusinessDashboardKPIListingEntity{
  public assetsAvailable	: number;
  public assetsAvaliableButBooked: number;
  public assetsReturnOverdue: number;
  public assetsNonCompliant: number;
  public assetsCheckedInPreviousDay: number;
}
  

  export class RemainingLifeListingEntity{
    public ageGroup: Array<string> = new Array<string>() ;
    public remainingLife: Array<number> = new Array<number>();
    public actualAge: Array<number> = new Array<number>();
  }
 
  export class AssetGroupCheckoutListingEntity{
    public assetGroupName: Array<string> = new Array<string>() ;
    public count: Array<number> = new Array<number>();
  }
  export class AssetTypeCheckoutListingEntity{
    public assetTypeName: Array<string> = new Array<string>() ;
    public count: Array<number> = new Array<number>();
  
  }
  export class AssetWithMostCheckOutListingEntity{
    public assetID: number;
    public userAssetID: string;
    public barcode: string;
    public departmentName: string;
    public assetType: string;
    public areaLocated: string;
    public noOfCheckouts: number; 
  }
  export class AssetWithMostOverduesListingEntity{
    public assetID: number;
    public userAssetID: string;
    public barcode: string;
    public departmentName: string;
    public assetType: string;
    public areaLocated: string;
    public noOfOverdues: number; 
  }
  export class AssetWithMostNonCompliantListingEntity{
    public assetID: number;
    public userAssetID: string;
    public barcode: string;
    public departmentName: string;
    public assetType: string;
    public areaLocated: string;
    public nextService: Date; 
  }
  export class BudgetListingEntity{
    public budgetYear: Array<string> = new Array<string>() ;
    public budget: Array<number> = new Array<number>();

  }
  export class AssetGroupKwhListingEntity{
    public budgetYear: Array<string> = new Array<string>() ;
    public budget: Array<number> = new Array<number>();

  }
  export class AgeVsConditionEntity{
    public conditionRatingName:string;
    public code:string;
    public ageGroup: string;
    public conditionRatingCd: number;
    public count:number;
  }
  export class HighestReplacementCost{
    public locationID :number
    public customerID: number
    public assetTypeID : number
    public locationName : string
    public assetTypeName: string
    public userAssetID  : string
    public capacity:number
    public unitName  : string
    public replacementYear : string
    public replacementBudget :number    
    public conditionRating : string
    public totalSpend:number
  }
  
  export class HighestTotalSpendAssets{
    public locationID :number
    public customerID: number
    public assetTypeID : number
    public locationName : string
    public assetTypeName: string
    public userAssetID  : string
    public capacity:number
    public unitName  : string
    public replacementYear : string
    public replacementBudget :number    
    public conditionRating : string
    public totalSpend:number
  }
  export class RefrigerantListingEntity{
    public Data: any[];
   
  }
