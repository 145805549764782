import { Component, OnInit } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { Router } from "@angular/router";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { CoreDataService } from '../../Service/core-data.service';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from '../../Service/CommonUtility.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-asset-group',
  templateUrl: './asset-group.component.html',
  styleUrls: ['./asset-group.component.scss']
})

export class AssetGroupComponent implements OnInit {
  list: any = [];
  UserData: any;
  sampledata = [];
  public selectedValue:any;
  public ddlassetgrouptype:{text:string,value:number}

  public ddlItems:Array<{text:string,value:number}>

  public state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  LanguageConfig: any;
  GridConfig: any
  gridData: GridDataResult;
  constructor(private titleService: Title,
    private activatedRoute: ActivatedRoute, private sharedService: SharedDataService, private router: Router, private toastrService: ToastrService, private coredataservice: CoreDataService) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }
  async ngOnInit() {
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[14];
          this.GridConfig = res[2]
          if(this.LanguageConfig!=undefined){
          this.ddlassetgrouptype={text:this.LanguageConfig.Select,value:null};
          this.ddlItems=[
            {text:this.LanguageConfig.All,value:0},
            {text:this.LanguageConfig.BusinessGroups,value:1},
            {text:this.LanguageConfig.NonBusinessGroups,value:2}]
          }
        }
      })
    
      var token = window.localStorage.getItem('token');
      this.UserData = this.sharedService.parseJwt(token);

    }
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.GridConfig = GridData[langLiteral];
    }
    this.loadData();
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.sampledata, { sort: [{ field: 'assetGroupID', dir: 'asc' }] }).data,

    };

    return result;
  }
  public dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.sampledata, this.state);

  }


  MenuOnSelect(event) {
    // var baseUrl = window.location.origin + '/#/edit-asset-group/' + event.dataItem.assetGroupID;
    var baseUrl = window.location.origin + '/edit-asset-group/' + event.dataItem.assetGroupID;

    if (event.item == "Open") {
      // this.getQuoteDetail(event.dataItem.QuoteID);
      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }


  editDetails(event) {
    this.router.navigate(['/edit-asset-group/' + event.assetGroupID])

  }
  removeDetail(event) {


    var index = this.sampledata.indexOf(event);
  }
  loadData() {

    this.coredataservice.getAssetGroupList().first().subscribe(res => {
      this.list = res;
      this.sampledata = this.list;
      this.allData = this.allData.bind(this);
      this.gridData = process(this.sampledata, this.state);

    },
      catchError => {
        if (catchError) {
          this.sharedService.ErrorHandler(catchError);

        }
      });

  }

 // This code is used to retrieve data from the dropdown.
 selected(){

  let dataexists= this.ddlItems.find((eledata:any)=>{return eledata.value==this.selectedValue});

this.sampledata = this.list.filter(ele=> 
 {
   if (dataexists.value== 0) {
     
     return ele;
   }
   else if(ele.isBusiness == true && dataexists.value !=2){      
     return ele;
   }
   else if(ele.isBusiness == false && dataexists.value !=1) {
           return ele;
   }   
 }
 );

 console.log(JSON.stringify(this.sampledata));

 this.state= {
   skip: 0,
   take: 20,
   sort: [{ field: "active", dir: "desc" }],
 };
this.gridData = process(this.sampledata, this.state);
  
}


}
