export class ContenManagementModel {
    public fileData: any;
    public fileName: string;
    public languageLiteral: string;
}
export class ContentManagementLogList{

    public  language :string;
    public  fileName:string;
    public creationDate:Date
    public updationDate:Date 
    public createdByName:string; 
    public updatedByName:string;
}