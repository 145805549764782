import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CodeData } from './../../Common/shared-model';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterViewChecked } from "@angular/core";
import { UserEntity, LinkLocation, CompanyEntity, SendEmailEntity, UserAdminListingEntity, AssetGroupDDLModel, ManagerEntity } from './add-user.model';
import { CoreDataService } from "src/app/Service/core-data.service";
import { SharedDataService } from "src/app/Service/CommonUtility.service";
import { Title } from '@angular/platform-browser';
import 'rxjs/add/operator/toPromise';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { AdminEntity } from '../../Common/shared-model';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-add-user',
  templateUrl: 'add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {
  //User MetaDataf

  public listItems: Array<{ text: string, value: number }> = [
    { text: 'Small', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Large', value: 3 }
];
  userAction: string = "Add User";
  userData: UserEntity = new UserEntity();
  userDatatemp: UserEntity = new UserEntity();
 
  
  welcomeEmailData: SendEmailEntity = new SendEmailEntity();
  roleData = Array<CodeData>(); //from shared.
  stateData = Array<CodeData>(); 
  codeData = Array<CodeData>();
  companyData = Array<CompanyEntity>();
  Companymultiboxdata:any=[];
   StateMultiboxdata:any=[];
  Array: any = [];

  managerData = Array<ManagerEntity>();
  LanguageConfig: any;
  GridConfig: any

  AssetAuditing: any = false;

  UserAdminListingEntity: UserAdminListingEntity = new UserAdminListingEntity();
  //Location MetaData
  locations = Array<LinkLocation>();
  private locationList: Array<LinkLocation> = [];
  dbselectedLocations: Array<LinkLocation> = [];
  dbselectedLocationList: Array<LinkLocation> = [];
  rightDataArr: Array<LinkLocation> = [];
  selectedUser: number;
  Userinfo: any = {}
  userRole: any;
  userID: number;
  lastCust: number = 0;
  currentCust: number = 0;
  slastCust: number = 0;
  scurrentCust: number = 0;
  searchFlag: boolean = false;
  saveLocationsIDs: Array<any> = [];
  validEmail: boolean = true;
  private locSearchString: string = "";
  selectedCompanyID: number;
  isCancelclicked: boolean = false;
  enableCompanyChangeLocationClear: boolean = false;
  subscriber: any;
  allowSendMail: boolean = false;
  allowAssetLink: boolean = false;
  showAssetGroupPopUp: boolean = false;
  passwordForMail: any;
  ErrorMsgData: any;
  isUserExist:boolean = false;
  @ViewChild('userForm',{static:true}) usrForm: NgForm;

  assetGroupData: Array<AssetGroupDDLModel> = [];
  assetGroupForm: FormGroup;
  IsAdmin: string;
  assetSaved: any = [];
  AdminEntity: AdminEntity = new AdminEntity();
  chkAll = new FormControl();
  public defaultRole: { codeValue: string, codeID: number } = { codeValue: "Select Role", codeID: null };
  public defaultManager: { name: string, userID: number } = { name: "Select Manager", userID: null };
  public defaultCompany: { companyID: number, name: string } = { companyID: null, name: "Select Company" };
  multiboxdata: any;
  constructor(private formBuilder: FormBuilder, private coreDataService: CoreDataService, private sharedDataService: SharedDataService, private titleService: Title, private activatedRoute: ActivatedRoute,
    private router: Router, private notify: SnotifyService) {
    this.getAssetGroupList();
    
    // this.userData.emailID =  null
    // this.userData.password =  null
  }


  allowAssetGroup: boolean = false;

  IsEmailErrorVisible : boolean = false;




  ngOnInit() {
    console.log("adding user checking...");
    this.IsEmailErrorVisible = false
    // this.usrForm.setAttribute
    //  this.userForm.setAttribute( "autocomplete", "off" ); 
    //document.addEventListener('onautocomplete', (e)=> {
      // e.target.hasAttribute('autocompleted'); // true or false
      // e.preventDefault(); // prevent autocomplete
      // do you stuff...
      // this.userData.emailID =  null
      // this.userData.password =  null
      // if(this.userDatatemp == undefined){
      //   this.IsEmailErrorVisible =  true
      //   if(this.userData.emailID != null && this.userData.password != null){
      //     this.userData.emailID =  null
      //     this.userData.password =  null
      //   }
      //   // console.log(this.userData)
      //   // console.log(this.userDatatemp)
      //   // console.log(e)
      // }
      
    //})
    // console.log(JSON.stringify(this.userDatatemp) + 'Temp')
    // console.log(JSON.stringify(this.userData.emailID) + 'Before')
    

    if(this.userData.userID <= 0 || this.userData.userID == null || this.userData.userID == undefined ){
    this.userData.password = ''
    this.userData.emailID = ''
    this.usrForm.reset();
    }

    // console.log(JSON.stringify(this.userData) + 'After')

    this.IsAdmin = this.sharedDataService.IsAdmin();
    //Dynamic Set Title
    let pageTite = this.activatedRoute.snapshot.data['title'];
    // this.titleService.setTitle(pageTite);
    this.userAction = pageTite;
    //End -- Set Title

    //Route Resolver Start
    this.subscriber = this.activatedRoute.data.subscribe(
      (data: { userDetail: any }) => {
        if (data != undefined && Object.keys(data).length > 0) {
          //--new changes
          //--new changes

          this.AdminEntity.Table = 'User'
          this.AdminEntity.AssociatedID  = data.userDetail.userEntity.userID

          if (data.userDetail != undefined && data.userDetail != null) {
            this.isUserExist = true;
             if (this.sharedDataService.SharedUserActive) {
              this.userDatatemp = data.userDetail.userEntity;
              this.userDatatemp.password = '';
              this.userDatatemp.companyID=data.userDetail.userEntity.companyID;
              

              
            //   // let splitid=data.userDetail.userEntity.companyID.split(',');
            //   //        splitid.forEach(ele=>{
            //   //   this.Companymultiboxdata.push(ele);
            //   // }) 

              data.userDetail.userEntity.companyID.split(',').forEach(ele => {
                this.Companymultiboxdata.push(Number.parseInt(ele))
              });
              this.userDatatemp.companyID= this.Companymultiboxdata;
              console.log(this.userDatatemp.companyID);

              this.userDatatemp.stateCD=data.userDetail.userEntity.stateCD;
             if(this.userDatatemp.stateCD!=undefined){
                data.userDetail.userEntity.stateCD.split(',').forEach(ele => {
                  this.StateMultiboxdata.push(Number.parseInt(ele))
                });
                this.userDatatemp.stateCD= this.StateMultiboxdata;
              }   
            
       
                

              //--new changes
              this.assetSaved = data.userDetail.assetGroupID;
              //--new changes

              //if (this.sharedDataService.user.active)
              //Set ****** for any user either active or not when editing
               this.userDatatemp.password = this.sharedDataService.password;
              //  this.userData.password = '******'
             
              if (data.userDetail.userEntity.passEncriptCode != null) {
                // this.userDatatemp.password = window.atob(data.userDetail.userEntity.passEncriptCode);
                // var ciphertext = CryptoJS.AES.encrypt('123456', 'EVALUATE').toString();

                this.userDatatemp.password = "";

                //this.userDatatemp.password = CryptoJS.AES.decrypt(data.userDetail.userEntity.passEncriptCode,'EVALUATE').toString(CryptoJS.enc.Utf8);
              }
              else {
                this.userDatatemp.password = "";
              }



            // }
            // this.passwordForMail = this.userDatatemp.password;

            if (this.userDatatemp.allowAssetDownload) {
              this.allowAssetLink = true;

            }
            //NEW CHANGES FOR BUSINESS USER
            this.AssetAuditing = this.userDatatemp.allowAssetDownload || this.userDatatemp.showJobHistory ? true : false

            //NEW CHANGES FOR BUSINESS USER


          }
        }
        else{
         this.isUserExist = false;
        }
        }
      });

    //Route Resolver End
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // let Id: number = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
      // this.LanguageConfig.PageTitle = Id != null && Id != undefined  ? PageData[langLiteral]['UpdateUser'] : PageData[langLiteral]['AddUser'];
      // this.titleService.setTitle(this.LanguageConfig.PageTitle);

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[13];
          this.ErrorMsgData = res[28]
        }
      })

      var token = window.localStorage.getItem('token');

      this.userRole = this.sharedDataService.parseJwt(token);
    }
    this.rightDataArr = [];

    this.getManagerData();
    this.getRoleList();
    this.getCompanyDDL();

  }

  NoAccessActiveUserHandler() {
    if (!this.userDatatemp.active) {
      this.AssetAuditing = false
      this.userDatatemp.showJobHistory = false
      this.userDatatemp.allowAssetDownload = false
      this.userDatatemp.inventoryManagement = false
      this.userDatatemp.allowReportAccess = false
      // this.userDatatemp.multipleConcurrentSession =  false
    }
  }
  NoAccessAssetAuditHandler(){
    if (!this.AssetAuditing) {
      this.userDatatemp.showJobHistory = false
      this.userDatatemp.allowAssetDownload = false
      //NEED TO CHECK IF THIS IS NEEDED
      this.userDatatemp.inventoryManagement = false
      this.userDatatemp.allowReportAccess = false

      // this.userDatatemp.multipleConcurrentSession =  false
    }
  }

  ngAfterViewInit(): void {
    if (this.sharedDataService.locationData != undefined && this.sharedDataService.locationData != null) {
      this.rightDataArr = this.sharedDataService.locationData;
      this.commonSelectedAdd(this.rightDataArr);
    }
  }


  locTab:boolean = true; 
  public onTabchange(e) {

    if(e.index == 0){
     
    }
    else{
      
    }

    if (e.index == 2 && this.userData.userID != null) {

      this.AdminEntity.Table = 'User'
      this.AdminEntity.AssociatedID = this.userData.userID
      // this.userData.password = this.userData.password
      
      // this.coreDataService.getUserAdminDetails('User', this.userData.userID).subscribe(r => {

      //   this.UserAdminListingEntity.createdByName = r.createdByName;
      //   this.UserAdminListingEntity.updatedByName = r.updatedByName;
      //   this.UserAdminListingEntity.creationDate = r.creationDate;
      //   this.UserAdminListingEntity.updationDate = r.updationDate;
      // },
      //   error => { this.notify.error("Unable to get user details, contact support!"); })
    }
    // console.log(this.userData)
    // console.log(this.userDatatemp)
     
      // setTimeout(() =>
      // { 

      // if(this.userData.userID != undefined && this.userData.userID > 0){
      //   this.userData.password = null
      //   // this.userData.password = '******'
      // }  
      // else{
      //   let obj  =  this.userData.password
      //   this.userData.password = null
      //   this.userData.password = obj
      // }
      
      // console.log(this.userData)
    
      // }, 1000);

  }


  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
  //---User Form Block Start--

  //Get Manager Data
  getManagerData() {
    var payload = { Roles: ['reviewer'] };
    this.coreDataService.getUsersByRole(payload).first()
      .subscribe((data) => {
        if (data != undefined && data != null) {
          data.forEach(element => {
            this.managerData.push({ "userID": parseInt(element.userID), "name": element.name });
          });
          this.userData = this.userDatatemp;
        }
      },
        error => {
          this.sharedDataService.showError(this.ErrorMsgData.noMangerFound);
        });
  }

  //--Get Role Drop-Down
  getRoleList() {
    this.coreDataService.getCodeData().first()
      .subscribe((data) => {
        if (data != null && data != undefined) {
          this.codeData = data;
          this.stateData=this.codeData.filter(function (res) { return res.codeName.toLowerCase() == 'statecd' });
          if (this.userRole.RoleType == 'ADMIN') {

            this.roleData = this.codeData.filter(function (res) { return res.codeName.toLowerCase() == 'roletypecd' && res.codeValue != 'SYS ADMIN' });

          }
          else {
            this.roleData = this.codeData.filter(function (res) { return res.codeName.toLowerCase() == 'roletypecd' });
          }
          //return this.resCodeData;
        }
      },
        error => this.notify.error(this.ErrorMsgData.contactSupportError + error));
  }
  //--Get Company DropDown
  getCompanyDDL() {
    this.coreDataService.getCompanyByName('').first()
      .subscribe((data) => {
        if (data != null && data != undefined) {
          console.log(data);
          console.log(this.userData.companyID);
          this.companyData = data;
        }
      },
        error => this.notify.error(this.ErrorMsgData.contactSupportError + error));
  }
  //Get Asset Group List
  getAssetGroupList() {

    this.coreDataService.GetAssetGroupDropdown().subscribe((data) => {
      if (data.length > 0 && data != undefined && data != null) {
        this.assetGroupData = data;

        //For Asset Group
        //if (typeof (this.assetSaved) != "undefined" && this.assetSaved.length > 0) {
        if (this.assetGroupData.length > 0) {
          const controls = this.assetGroupData.map(c => new FormControl(false));
          controls.forEach(element => {
            element.setValue(false);
          });
          this.assetGroupForm = this.formBuilder.group({
            assetGroupData: new FormArray(controls)
          });
        }
        // }
        else if (this.userData.allowAssetDownload) {
          const controls = this.assetGroupData.map(c => new FormControl(false));
          controls.forEach(element => {
            element.setValue(false);
          });
          this.assetGroupForm = this.formBuilder.group({
            assetGroupData: new FormArray(controls)
          });
        }

      } else { }
    },
      error => {
        this.notify.error(this.ErrorMsgData.notFetchAssetGroup);
      });
  }
  CheckAllAssetGroups() {
    let ckeckAll = this.chkAll.value;
    if (ckeckAll) {
      if (this.assetGroupData.length > 0) {
        for (let i = 0; i < this.assetGroupData.length; i++) {
          document.getElementById('AG' + i)['checked'] = true;
        }
        const controls = this.assetGroupData.map(c => new FormControl(false));
        controls.forEach(element => {
          element.setValue(true);
        });
        this.assetGroupForm = this.formBuilder.group({
          assetGroupData: new FormArray(controls)
        });
      }
    }
    else {
      if (this.assetGroupData.length > 0) {
        for (let i = 0; i < this.assetGroupData.length; i++) {
          document.getElementById('AG' + i)['checked'] = false;
        }
        const controls = this.assetGroupData.map(c => new FormControl(false));
        this.assetGroupForm = this.formBuilder.group({
          assetGroupData: new FormArray(controls)
        });
      }
    }
  }
  //Canclec PopUp
  cancelledClick() {
    this.isCancelclicked = true;


  }
  close(val) {
    if (val != undefined && val == 'true') {
      this.router.navigate(["UserManagement"]);
      this.isCancelclicked = false;
    } else {
      this.isCancelclicked = false;
    }
  }
  closeComp(val) {
    if (val != undefined && val == 'true') {
      this.userData.locationsID = [];
      this.rightDataArr = [];
      document.getElementById('linkLocation').innerHTML = "";
      document.getElementById('selectedlinkLocation').innerHTML = "";
      this.enableCompanyChangeLocationClear = false;
    } else {
      this.enableCompanyChangeLocationClear = false;
    }
  }
  //Validate Email
  checkEmail(event) {
    if (event != undefined && event != "" && event != null) {

      this.userData.emailID = event.replace(/[\s]/g, '');
      this.validEmail = this.sharedDataService.checkEmailValidity(this.userData.emailID);
      if (this.validEmail == false) {
        this.notify.info(this.ErrorMsgData.InvalidEmail);
      }

      
       
      //--API CALL FOR CHECKING THE EXISTENCE OF EMAIL IN DB--//
      this.coreDataService.ValidateUserEmail(this.userData.userID, event).subscribe(res => {
        if (res != null && res != undefined && res.length > 0) {

          if((this.userData.emailID == undefined && this.userData.password == undefined) || (this.userData.emailID == '' && this.userData.password == '' )){
            console.log(this.userData)
            return
          }
          else{

            if(this.userData.userID == undefined || this.userData.userID <=0){
              this.userData.emailID = null   
              this.IsEmailErrorVisible = true   
                
            }
            
            // console.log('else')
            // console.log(this.userData)
            if(this.userData.emailID == null && this.userData.password != '' && (this.userData.userID == undefined || this.userData.userID <=0)){
              this.userData.emailID =  null
              this.userData.password =  null
              this.IsEmailErrorVisible = true
              console.log('if')
              console.log(this.userData)
              this.notify.error(this.ErrorMsgData.emailAlreadyExisit);
              return
            }
            else{
              this.userData.emailID =  null
              this.notify.error(this.ErrorMsgData.emailAlreadyExisit);
            }
            
          }          
        }
        else {
          this.userData.emailID = this.userData.emailID;

        }
      })
      //--API CALL FOR CHECKING THE EXISTENCE OF EMAIL IN DB--//
    }
  }
  //On Company Change
  onCompanyChange(val) {
   
    if (this.rightDataArr != undefined && this.rightDataArr.length > 0) {
      this.enableCompanyChangeLocationClear = true;
    } else { return; }
  }
  // --Add Update User
  saveUser() {
    if (this.userData.userID == undefined) {
      this.assetGroupData.map(ele =>{
        console.log(ele.assetGroupID)
        this.userData.assetGroupID.push(ele.assetGroupID)
      })
      this.userData.assetGroupID = this.userData.assetGroupID
    }
    
    if(this.userData.assetGroupID == undefined){
      this.userData.assetGroupID = []
      this.assetGroupData.map(ele =>{
        // console.log(ele.assetGroupID)
        this.userData.assetGroupID.push(ele.assetGroupID)
      })
      this.userData.assetGroupID = this.userData.assetGroupID
    }
   if (this.assetSaved <= 0 && this.userData.assetGroupID.length <= 0) {
      this.notify.error(this.ErrorMsgData.ErrorAssetGroup);
    }    
    else {
      if (this.userData != undefined) {
        if (this.rightDataArr.length > 0 && this.rightDataArr != undefined) {
          for (var loc = 0; loc < this.rightDataArr.length; loc++) {
            this.saveLocationsIDs.push(this.rightDataArr[loc].locationID);
          }
          this.userData.locationsID = this.saveLocationsIDs;
        }
        else {
          this.userData.locationsID = [];
        }
        //ADD & UPDATE
        if (this.selectedUser != null && this.selectedUser != undefined) {
          this.userData.userID = this.selectedUser;
          this.saveLocationsIDs = [];
        }

        // if (this.userData.password == this.sharedDataService.password) {
        //   this.userData.password = "";
        // }

        if (this.usrForm.form.valid && this.validEmail == true && this.isUserExist) {

          //CONDITION TO BIND ASSETGROUPID DURING CREATION PHASE
          if (this.userData.userID == undefined) {
            this.onChangeAllowAssetDownload(true)
          }
          //CONDITION TO BIND ASSETGROUPID DURING CREATION PHASE


          //--new changes
          if (this.userData.assetGroupID == undefined && this.userData.assetGroupID == null) {
            this.userData.assetGroupID = this.assetSaved;
          }
          //--new changes
          this.passwordForMail = this.userData.password;

          // if (this.userData.userID != null && this.userData.userID > 0) {
          //   if (this.userData.password.toString() === '******') {
          //     this.userData.password = null;
          //   }
          //   else {
          //     this.userData.passEncriptCode = CryptoJS.AES.encrypt(this.userData.password, 'EVALUATE').toString();
          //   }
          // }

          if (this.userData.passEncriptCode != null) {
            this.userData.passEncriptCode = CryptoJS.AES.encrypt(this.userData.password, 'EVALUATE').toString();
          }

          if (this.userData.companyID != null){
            this.userData.companyID=this.userData.companyID.toString();
          }
          if(this.userData.stateCD == undefined||this.userData.stateCD==""){
            this.userData.stateCD=null;
          }
          if (this.userData.stateCD != null){
            this.userData.stateCD=this.userData.stateCD.toString();
          }

          this.coreDataService.addUpdateUser(this.userData).first()
            .subscribe((data) => {
              if (data != null && data != undefined) {
                this.sharedDataService.user = new UserEntity();
                this.router.navigate(["UserManagement"]);
                if (this.sharedDataService.SharedUserActive) {
                  this.notify.success(this.ErrorMsgData.userUpdated);
                  this.sharedDataService.SharedUserActive = false;
                }
                else {
                  this.notify.success(this.ErrorMsgData.userCreated);
                  this.sharedDataService.SharedUserActive = false;
                }
                //Welcome email notification when creating new user
                if (this.allowSendMail) {
                  this.welcomeEmailData.Data = {
                    "FullName": this.userData.firstName + " " + this.userData.lastName,
                    "EmailID": this.userData.emailID,
                    //"Password": this.userData.password
                    "Password": this.passwordForMail
                  };
                  this.welcomeEmailData.EmailType = "Welcome";
                  this.welcomeEmailData.EmailTo = this.userData.emailID;
                  this.coreDataService.SendEmailNotification(this.welcomeEmailData).first()
                    .subscribe((res) => {
                      if (res != undefined && res != null) {
                        this.notify.success(this.ErrorMsgData.emailSentSuccessfully);
                      }
                    },
                      error => {
                        this.notify.warning(this.ErrorMsgData.emailNotSend);
                      });
                }
              }
            },
              error => {
                this.notify.error(this.ErrorMsgData.userNotSaved);
              });
        }
        else {
          if (this.validEmail == false) {
            this.notify.error(this.ErrorMsgData.incorrectEmail);
          } else
            this.notify.error(this.ErrorMsgData.fieldMissing);
        }
        this.saveLocationsIDs = [];
      }
    }
    // this.userData.password = null;
  }
  //---User Form Block End---

  // ----Location Block --Start
  onLocationFilterChange() {
    if (this.userData.companyID == undefined || this.userData.companyID == null) {
      this.notify.warning(this.ErrorMsgData.selectCompany);
    } else {
      if (this.locSearchString != "" && this.locSearchString != undefined) {
        if (this.locSearchString.length >= 3) {
          this.searchLocations(this.locSearchString);
        } else {
          this.notify.warning(this.ErrorMsgData.searchText);
        }
      }
      else {
        this.notify.warning(this.ErrorMsgData.nothingToSearch);
      }
    }
  }
  //GET ALL LOCATIONS FOR SEARCH
  searchLocations(SearchString?: any) {
    if (SearchString != undefined && this.userData.companyID != undefined && this.userData.companyID != null) {
      document.getElementById('linkLocation').innerHTML = "";
      let tempcompanyID = this.userData.companyID.toString()
      this.coreDataService.getLocationsByCompanySearch(tempcompanyID, this.locSearchString).first()
        .subscribe((coreData) => {
          if (coreData != undefined && coreData != null && coreData.length > 0) {
            this.locations = coreData;
            this.locationList = this.locations;
            this.bindLinkLocation();
          } else {
            this.notify.warning(this.ErrorMsgData.locationNotFound);
          }
        },
          error => {
            this.sharedDataService.ErrorHandler(this.ErrorMsgData.internalError);
          }
        );
    } else {
      this.notify.warning(this.ErrorMsgData.noCompanySelected)
    }
  }

  //BIND ALL LOCATION LIST IN LEFT SELECT BOX AFTER GETTING FROm API
  bindLinkLocation() {
    var oGroup;
    this.lastCust = 0;
    this.currentCust = 0;
    var oSelect = document.getElementById('linkLocation');
      oSelect.innerHTML = "";
   
    if (this.locationList != undefined && this.locationList.length > 0) {
      for (let i = 0; i < this.locationList.length; i++) {
        this.currentCust = this.locationList[i].customerID;
        //First Time only
        if (this.lastCust == 0 && this.lastCust != undefined) {
          this.lastCust = this.currentCust = this.locationList[i].customerID;
          oGroup = document.createElement('optgroup');
          oGroup.label = this.locationList[i].customerName.toUpperCase();
        }
        //When match then add in same group
        if (this.currentCust === this.lastCust) {
        
          var oOption = document.createElement('option');
          oOption.value = (this.locationList[i].locationID).toString();
          oOption.innerHTML = this.locationList[i].locationName.toUpperCase();

          oGroup.appendChild(oOption);
          oSelect.appendChild(oGroup);
        }
        else {
          oGroup = document.createElement('optgroup');
          oGroup.label = this.locationList[i].customerName.toUpperCase();
          //Ms 07-02-19 changes : Start
          var oOption = document.createElement('option');
          oOption.value = (this.locationList[i].locationID).toString();
          oOption.innerHTML = this.locationList[i].locationName.toUpperCase();
          oGroup.appendChild(oOption);
          oSelect.appendChild(oGroup);
          //Ms 07-02-19 changes : End
          this.lastCust = this.currentCust;
        }
      }
    }
   
  }

  isEnable:boolean = false;
  usrPassword(pass){
    console.log(pass)
    if(pass.valid && pass.model.length>4){
    this.isEnable = true;
    this.isUserExist = true
    }
    else{
      this.isEnable = false;
      this.isUserExist = false
    }

  }


  //ADD SELECTED LOCATIONS TO RIGHT DDL
  addSelectedLocations() {
    var totalLoc = 0;
    var selectedLocations: Array<LinkLocation> = [];
    //Get Total Locations
    for (var i = 0; i < document.getElementById("linkLocation").childNodes.length; i++) {
      totalLoc += document.getElementById("linkLocation").childNodes[i].childNodes.length;
    }
    // Traverse to get all Selected locations and Push in an Array(selectedLocations)
    for (i = 0; i < totalLoc; i++) {
      if (document.getElementById("linkLocation")[i].selected == true) {
        if (this.locationList.find((obj) => obj.locationID == document.getElementById("linkLocation")[i].value)) {
          selectedLocations.push(this.locationList.find((obj) => obj.locationID == document.getElementById("linkLocation")[i].value));
        }
      }
    }
    if (selectedLocations != undefined && selectedLocations.length > 0) {
      //If Selected Locations already having Locations in Right SELECT BOX then add only those which are new to right selectbox
      var locToRemoveFromLeftWhenAddInRight: Array<LinkLocation> = []
      if (this.rightDataArr.length > 0 && this.rightDataArr != undefined) {
        for (var loc = 0; loc < selectedLocations.length; loc++) {
          const rdIndex: number = this.rightDataArr.indexOf(this.rightDataArr.find((obj) => obj.locationID == selectedLocations[loc].locationID));
          if (rdIndex == -1) {
            this.rightDataArr.push(selectedLocations[loc]);  //adding new locations only
            locToRemoveFromLeftWhenAddInRight.push(selectedLocations[loc]);
          }
        }
        document.getElementById('selectedlinkLocation').innerHTML = ""; //clean Right selectbox before render/bind locations
        this.commonSelectedAdd(this.rightDataArr);
        this.removeSelectedLinkLocations(locToRemoveFromLeftWhenAddInRight);
      }
      //If No Selected Locations in Right SELECT BOX Add all the selected Locations in Right Select Box
      else {
        this.rightDataArr = selectedLocations.slice(0);  //copy selected location from 0 index
        this.commonSelectedAdd(this.rightDataArr);
        this.removeSelectedLinkLocations(this.rightDataArr);
      }
    } else {
      this.notify.warning(this.ErrorMsgData.LocationWarning);
    }

  }

  //Remove From Right
  removeSelectedLocations() {
    var totalLoc = 0;
    for (var i = 0; i < document.getElementById("selectedlinkLocation").childNodes.length; i++) {
      totalLoc += document.getElementById("selectedlinkLocation").childNodes[i].childNodes.length;
    }
    if (totalLoc > 0) {
      for (i = 0; i < totalLoc; i++) {
        if (document.getElementById("selectedlinkLocation")[i].selected == true) {
          const delIndex: number = this.rightDataArr.indexOf(this.rightDataArr.find((obj) => obj.locationID == document.getElementById("selectedlinkLocation")[i].value));
          if (delIndex != -1) {
            this.addlocationtoleft(this.rightDataArr.find((obj) => obj.locationID == document.getElementById("selectedlinkLocation")[i].value))
            this.rightDataArr.splice(delIndex, 1);
          }
        
        }
      }
    }
    document.getElementById('selectedlinkLocation').innerHTML = "";
    this.commonSelectedAdd(this.rightDataArr);
  }
  addlocationtoleft(location){
    this.locationList.push(location)
    this.bindLinkLocation()

  }
  //Remove From Left
  removeSelectedLinkLocations(locToRemove: Array<LinkLocation>) {
    if (locToRemove.length > 0) {
      for (var i = 0; i < locToRemove.length; i++) {
        const delIndex: number = this.locationList.indexOf(this.locationList.find((obj) => obj.locationID == locToRemove[i].locationID));
        if (delIndex != -1) {
          this.locationList.splice(delIndex, 1);
        }
      }
    }

    document.getElementById('linkLocation').innerHTML = "";
    this.bindLinkLocation();
  }
  //COMMON FOR ADD
  commonSelectedAdd(selectedLocationsList: Array<LinkLocation>): any {
    this.slastCust = 0;
    this.scurrentCust = 0;
    var soGroup; //selected option group (parent)
    var soSelect = document.getElementById('selectedlinkLocation');  //selected option Select(child)
    var selectedLocForRight: Array<LinkLocation> = [];
    //Sorting array by Customer for better result.
    selectedLocForRight = selectedLocationsList.sort(this.sharedDataService.predicate({ name: 'customerID', reverse: true }, 'customerName', 'locationName'));
    if (selectedLocForRight != undefined && selectedLocForRight.length > 0) {
      for (let i = 0; i < selectedLocForRight.length; i++) {

        this.scurrentCust = selectedLocForRight[i].customerID;
        //FOR FIRST GROUP
        if (this.slastCust == 0 && this.slastCust != undefined) {
          this.scurrentCust = this.slastCust = selectedLocForRight[i].customerID;
          soGroup = document.createElement('optgroup');
          soGroup.label = selectedLocForRight[i].customerName.toUpperCase();
        }
        //TO ADD OPTIONS IN SAME GROUP
        if (this.scurrentCust === this.slastCust) {
          var soOption = document.createElement('option');
          soOption.value = (selectedLocForRight[i].locationID).toString();
          soOption.innerHTML = selectedLocForRight[i].locationName.toUpperCase();

          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);
        }
        else {
          soGroup = document.createElement('optgroup');
          soGroup.label = selectedLocForRight[i].customerName.toUpperCase();

          var soOption = document.createElement('option');
          soOption.value = (selectedLocForRight[i].locationID).toString();
          soOption.innerHTML = selectedLocForRight[i].locationName.toUpperCase();

          soGroup.appendChild(soOption);
          soSelect.appendChild(soGroup);

          this.slastCust = this.scurrentCust;
        }
      }
   
    }
    else if (selectedLocForRight.length == 0) {
      soSelect.innerHTML = "";
    }

    //---Location Block --End
  }
  //Send Mail : When Send Mail Switch is Active
  activeSendMail(event) {
    if (event.toString() == "true") {
      this.allowSendMail = true;
    }
    else
      this.allowSendMail = false;
  }
  onChangeAllowAssetDownload(event) {
    if (event.toString() == "true") {
      this.allowAssetLink = true;
      // Create a new array with a form control for each AssetGroup
      //controls[0].setValue(true); // Set the first checkbox to true (checked)
      const controls = this.assetGroupData.map(c => new FormControl(false));
      controls.forEach(element => {
        element.setValue(true);
      });
      this.assetGroupForm = this.formBuilder.group({
        assetGroupData: new FormArray(controls)
      });
      this.chkAll.setValue(true);
      const selectedOrderIds = this.assetGroupForm.value.assetGroupData
        .map((v, i) => v ? this.assetGroupData[i].assetGroupID : null)
        .filter(v => v !== null);
      //console.log(selectedOrderIds);
      this.userData.assetGroupID = selectedOrderIds;
    }
    else {
      this.allowAssetLink = false;
      // Create a new array with a form control for each AssetGroup
      const controls = this.assetGroupData.map(c => new FormControl(false));
      //controls[0].setValue(true); // Set the first checkbox to true (checked)
      controls.forEach(element => {
        element.setValue(false);
      });
      this.assetGroupForm = this.formBuilder.group({
        assetGroupData: new FormArray(controls)
      });
      this.userData.assetGroupID = [];
    }
  }
  
  onAllowAssetDownloadLinkClick(event) {

    if (event) {
      this.allowAssetGroup = true;
      if (typeof (this.assetSaved) != "undefined") {
        if (this.assetSaved.length != this.assetGroupData.length) {
          this.chkAll.setValue(false);
        }
      }
      this.showAssetGroupPopUp = true;
      if (this.assetSaved.length > 0) {
        for (let i = 0; i < this.assetSaved.length; i++) {
          var index = this.assetGroupData.indexOf(this.assetGroupData.find((obj) => { return obj.assetGroupID == this.assetSaved[i] }))
          if (index > -1) {
            this.assetGroupForm.controls.assetGroupData['controls'][index].value = true;
          }
        }
      }
      if (this.assetSaved.length == 0) {
        for (let i = 0; i < this.assetGroupData.length; i++) {
          var index = this.assetGroupData.indexOf(this.assetGroupData[i])
          if (index > -1) {
            this.assetGroupForm.controls.assetGroupData['controls'][index].value = true;
          }
        }
      }
    }
  }
  onCloseAssetGroupPopUp(val) {
    if (val != undefined && val == 'true') {
      this.showAssetGroupPopUp = false;
    } else {
      this.showAssetGroupPopUp = true;
    }
    this.allowAssetGroup = false;
  }
  saveAssetGroup() {
    const selectedOrderIds = this.assetGroupForm.value.assetGroupData
      .map((v, i) => v ? this.assetGroupData[i].assetGroupID : null)
      .filter(v => v !== null);
    this.userData.assetGroupID = selectedOrderIds;
    this.showAssetGroupPopUp = false;
  }
}
