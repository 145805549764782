import { Component, Input, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import "moment/locale/en-au";
import { SignaturePad } from "angular2-signaturepad";
import { CoreDataService } from "../../Service/core-data.service";
import { SharedDataService } from "../../Service/CommonUtility.service";
import { BookingEntity } from "./reserve-asset.model";
import {
  FormControl,
  FormGroup,
  FormArray,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { SnotifyService } from "ng-snotify";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  GridDataResult,
  DataStateChangeEvent,
  SelectAllCheckboxState,
} from "@progress/kendo-angular-grid";
import {
  process,
  State,
  SortDescriptor,
  orderBy,
  filterBy,
  FilterDescriptor,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { clear } from "console";
@Component({
  selector: "app-reserve-asset",
  templateUrl: "./reserve-asset.component.html",
  styleUrls: ["./reserve-asset.component.scss"],
})
export class ReserveAssetComponent implements OnInit {
  // @Input() opened: boolean;
  assetID: number;
  // @Input() bookingID : number
  bookingStatus: string;
  bookingEntityModel: BookingEntity = new BookingEntity();
  reservedByList = [];
  UserData: any;
  UserDataList: any = [];
  BookingStatus: any[] = [];
  date: Date = null;
  settings = {
    bigBanner: true,
    timePicker: true,
    format: "dd-MM-yyyy HH:mm",
    defaultOpen: false,
  };
  settings1 = {
    bigBanner: true,
    timePicker: false,
    format: "dd-MM-yyyy",
    defaultOpen: false,
  };
  public format = "dd-MM-yyyy";
  // public value: Date = new Date(2019, 5, 1, 22);
  // public format = 'MM/dd/yyyy HH:mm';
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 300,
    canvasHeight: 200,
  };
  public state: State = {
    skip: 0,
    take: 5,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public Data: any[] = [];
  public gridData: GridDataResult;
  DateTimeformat: any;
  Dateformat: any;
  IsRefreshNeeded: boolean = false;
  BusinessLanguageConfig: any;
  BusinessErrMessages: any;
  IsComplianceManagement: boolean = false;

  public SelectionDropdown: Array<{
    display: string;
    text: string;
    disabled: boolean;
  }>;

  SelectedSelection: string = "Reservation";
  CheckoutDateGlobal: Date;

  checkoutWithReservation: boolean = false;
  isReserveDetails: boolean = false;
  isCheckoutDetails: boolean = false;
  isCheckedIn: boolean = false;
  isCheckOutGlobal:boolean=false;
  checkoutPermission = true;
  newBookBtnClick = 0;
  IsDirectCheckout: boolean = false;
  IsEditBtnClick = false;
  IsPermanentGlobal:boolean=false;
  IsPermanentCheckoutGlobal:boolean=false;


  constructor(
    private coredataservice: CoreDataService,
    private titleService: Title,
    private sharedDataService: SharedDataService,
    private notify: SnotifyService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {
    this.date = null;

    this.UserData = this.sharedDataService.parseJwt(
      window.localStorage.getItem("token")
    );

    //console.log(document.getElementsByClassName('ng-tns-c13-0 ng-trigger ng-trigger-toggle ng-star-inserted'))

    var payload = { Roles: ["customer"] };
    this.coredataservice
      .getUsersByRole(payload)
      .first()
      .subscribe((response) => {
        for (let key in response) {
          this.reservedByList.push({
            userID: parseInt(response[key].userID),
            name: response[key].name,
          });
        }
        console.log(this.reservedByList);
        this.UserDataList = this.reservedByList.filter((ele) => {
          return ele.userID == this.UserData.UserID;
        });
        this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList;
        this.bookingEntityModel.checkedOutBySelectedEmail =
          this.UserData.EmailID;
        this.bookingEntityModel.checkedOutBy = this.UserData.UserID;
      });

    this.getCodeData();
    this.assetID = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
    // console.log(this.assetID)
    this.getBookingDetailsByAssetID(this.assetID);
    this.GetComplianceManagementbyAssetID(this.assetID);
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res["DateFormat"] + " " + res["TimeFormat"];
        this.Dateformat = res["DateFormat"];

        //CASE FOR DATE TIME FORMAT SET ON DATE/TIME FIELDS
        this.settings.format = this.DateTimeformat;
        this.format = this.Dateformat;
        //CASE FOR DATE TIME FORMAT SET ON DATE/TIME FIELDS
      }
    });
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          console.log(res);
          this.BusinessLanguageConfig = res[60];
          this.BusinessErrMessages = res[62];

          if (this.BusinessLanguageConfig != undefined) {
            this.SelectionDropdown = [
              {
                display: this.BusinessLanguageConfig.Reservation,
                text: "Reservation",
                disabled: false,
              },
              {
                display: this.BusinessLanguageConfig.Checkout,
                text: "Checkout",
                disabled: false,
              },
              {
                display: this.BusinessLanguageConfig["Check In"],
                text: "Check In",
                disabled: false,
              },
            ];
          }
        }
      });
    }
  }



  newBooking(val:boolean) {
    console.log("new booking");
    this.ResetDataFields();
    this.newBookBtnClick =1;
    this.checkoutPermission = true;
    this.bookingEntityModel.isPermanent = false;
    this.IsDirectCheckout = val;
    if(val){
      this.isCheckoutDetails = true
      this.isReserveDetails = false;
      this.checkoutWithReservation = true;
    }else{
      this.isCheckoutDetails = true;
      this.isReserveDetails = true;
    }
    
  }

  checkOutDetailsSelected() {
    if (this.checkoutWithReservation) {
      this.bookingEntityModel.checkOutNotes =
        this.bookingEntityModel.reservationNotes;
    }
    console.log(this.bookingEntityModel.checkOutDate);
    console.log(this.bookingEntityModel.returnByDate);
  }

  getCodeData() {
    this.coredataservice.getCodeData().subscribe((res) => {
      if (res != undefined && res != null) {
        res.forEach((element) => {
          if (element.codeName.toLowerCase() == "bookingstatuscd") {
            this.BookingStatus.push(element);
          }
        });
        console.log(this.BookingStatus);
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.Data, this.state);
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    // this.signaturePad.fromDataURL('')
  }
  onTabchange(event) {}
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
    //if(this.bookingEntityModel.signature == null || this.bookingEntityModel.signature == ''){
    this.bookingEntityModel.signature = this.signaturePad.toDataURL();
    //}
    // else{
    //   this.signaturePad.clear()
    // }
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // if(this.bookingEntityModel.signature == null || this.bookingEntityModel.signature == ''){
    //   this.bookingEntityModel.signature = null
    // }
    console.log("begin drawing");
  }
  valueChange(value: any): void {
    console.log(value);
    console.log(this.bookingEntityModel.reservedBy);
    this.ReservedByErrorEnable =
      this.bookingEntityModel.reservedBy == null ? true : false;
    console.log(this.ReservedByErrorEnable);
    // this.bookingEntityModel.receivedBy = this.bookingEntityModel.reservedBy;
    if (this.checkoutWithReservation) {
      this.bookingEntityModel.receivedBy = this.bookingEntityModel.reservedBy;
    }
    console.log("check received:", this.bookingEntityModel.receivedBy);
  }
  receivedBYvalueChange(value: any) {
    this.bookingEntityModel.receivedBy = value;
  }

  selectionChange(value: any): void {}

  public source: Array<{ name: string; userID: number }> = this.reservedByList;

  filterChange(filter) {
    // this.log('filterChange', filter);
    // this.data = this.source.filter((s) => s.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    if (filter.length >= 3) {
      this.reservedByList = this.source.filter(
        (s) => s.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      );
      console.log(this.reservedByList);
    } else {
      // this.reservedByList = [];
      this.reservedByList = this.source;
      console.log(this.reservedByList);
    }
  }

  IsPermanentVisibility: boolean = false;
  getBookingDetailsByAssetID(AssetID) {
    if (AssetID != undefined || AssetID > 0) {
      this.coredataservice
        .GetBookingDetailsByAssetID(AssetID)
        .subscribe((data: any) => {
          console.log(data);
          this.gridData = null;
          this.Data = null;
          this.Data = data;
          this.gridData = process(data, this.state);
          //CHECK FOR BOOKING STATUS
          this.bookingStatus =
            this.gridData.data.find((ele) => {
              return ele.statusName.toLowerCase() == "checked out";
            }) != undefined
              ? "Checked Out"
              : "Available";
          this.IsPermanentVisibility =
            this.gridData.data.find((ele) => {
              return ele.isPermanent == true&&ele.statusName == "Checked In";
            }) != undefined
              ? true
              : false;
          console.log(this.IsPermanentVisibility);
          this.bookingStatusName =
            this.gridData.data.find((ele) => {
              return ele.statusName.toLowerCase() == "checked out";
            }) != undefined
              ? "Checked Out"
              : "Available";
          var CheckoutRow = this.gridData.data.find((ele) => {
            return (
              ele.statusName.toLowerCase() == "checked out" && ele.checkOutDate
            );
          });
          if (CheckoutRow != null) {
            this.CheckoutDateGlobal = new Date(CheckoutRow.checkOutDate);
          } else {
            this.SelectionDropdown[2].disabled = true;           
          }
          this.IsPermanentGlobal =
            this.gridData.data.find((ele) => {
              return ele.isPermanent == true && ele.statusName.toLowerCase() != "checked in";
            }) != undefined
              ? true
              : false;
          this. IsPermanentCheckoutGlobal=
              this.gridData.data.find((ele) => {
                return ele.isPermanentCheckout == true && ele.statusName.toLowerCase() != "checked in";
              }) != undefined
                ? true
                : false;
          
          if(this.bookingStatus=="Checked Out"){
            this.isCheckOutGlobal=true;
          }
        });
    }
  }
  GetComplianceManagementbyAssetID(AssetID) {
    var nowDate = new Date();

    if (AssetID != undefined || AssetID > 0) {
      this.coredataservice
        .GetComplianceManagementbyAssetID(AssetID)
        .subscribe((ele: any) => {
          if (
            ele["complianceManagement"] == true &&
            ((ele["nextService"] != null &&
              ele["nextService"] != "0001-01-01T00:00:00") ||
              (ele["nextTestandTag"] != null &&
                ele["nextTestandTag"] != "0001-01-01T00:00:00")) &&
            ((ele["nextService"] == "0001-01-01T00:00:00"
              ? false
              : new Date(ele["nextService"]) < nowDate) ||
              (ele["nextTestandTag"] == "0001-01-01T00:00:00"
                ? false
                : new Date(ele["nextTestandTag"]) < nowDate))
          ) {
            this.IsComplianceManagement = true;
          }
        });
    }
  }

  bookingStatusName: any;
  bookingStatusVisibility: any;
  EditBookingDetails(bookingID) {
    console.log(this.gridData);
    this.ResetDataFields();
    this.IsEditBtnClick = true;
    this.newBookBtnClick = 2;
    let data: any = this.gridData.data.find((ele) => {
      return ele.bookingID == bookingID;
    });
    if (data != undefined) {
      if (data.statusName == "Reserved") {
        this.checkoutPermission = false;
        this.isReserveDetails = true;
        this.isCheckoutDetails = false;
        this.checkoutWithReservation = false;
      } else if (data.statusName == "Checked Out") {
        this.checkoutPermission = true;
        this.isCheckoutDetails = true;
        // this.checkoutWithReservation = true;
        this.isReserveDetails = true;
        // this.checkoutWithReservation = false;
      }
      this.bookingEntityModel = new BookingEntity();
      this.signaturePad.clear();
      this.bookingStatusName = null;
      this.bookingEntityModel.assetID = data.assetID;
      this.bookingEntityModel.bookingID = data.bookingID;
      this.bookingEntityModel.endDateTime = data.endDateTime;
      this.bookingEntityModel.startDateTime = data.startDateTime;
      this.bookingEntityModel.reservationNotes = data.reservationNotes;
      this.bookingEntityModel.status = data.status;
      this.bookingEntityModel.reservedBy = data.reservedBy;
      this.bookingEntityModel.receivedBy = data.receivedBy;
      // console.log(new Date(data.checkOutDate).getFullYear().toString())

      // if(this.IsPermanentVisibility=true){
      //   this.bookingEntityModel.receivedBy=this.bookingEntityModel.reservedBy
      // }

      this.bookingEntityModel.checkOutDate =
        new Date(data.checkOutDate).getFullYear().toString() == "1"
          ? null
          : new Date(data.checkOutDate);
      this.bookingEntityModel.checkOutNotes = data.checkOutNotes;
      this.bookingEntityModel.checkedOutBy = this.UserData.UserID;
      this.bookingEntityModel.returnByDate =
        new Date(data.returnByDate).getFullYear().toString() == "1"
          ? null
          : new Date(data.returnByDate);
      this.bookingEntityModel.signature = data.signature;

      this.bookingEntityModel.isPermanent = data.isPermanent;
      this.bookingEntityModel.isPermanentCheckout = data.isPermanentCheckout;
      this.bookingEntityModel.checkInBy = data.checkInBy;
      this.bookingEntityModel.checkInDate = data.checkInDate;
      this.bookingEntityModel.checkInNotes = data.checkInNotes;

      this.signaturePad.fromDataURL(this.bookingEntityModel.signature);

      this.UserDataList = this.reservedByList.filter((ele) => {
        return ele.userID == this.UserData.UserID;
      });
      this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList;
      this.bookingEntityModel.checkedOutBySelectedEmail = this.UserData.EmailID;

      if (this.bookingEntityModel.isPermanent) {
        this.bookingEntityModel.startDateTime = new Date(); // OR NUll
        this.bookingEntityModel.endDateTime = new Date(); // OR NUll
      }
      if (this.bookingEntityModel.isPermanentCheckout) {
        this.bookingEntityModel.checkOutDate = new Date(); // OR NUll
        this.bookingEntityModel.returnByDate = new Date(); // OR NUll
      }

      //CHECK FOR BOOKING STATUS
      this.bookingStatus =
        this.gridData.data.find((ele) => {
          return ele.statusName.toLowerCase() == "checked out";
        }) != undefined
          ? "Checked Out"
          : "Available";
      this.bookingStatusName =
        this.gridData.data.find((ele) => {
          return (
            ele.statusName.toLowerCase() == "checked out" &&
            ele.bookingID == data.bookingID
          );
        }) != undefined
          ? "Checked Out"
          : "Available";

      this.bookingStatusVisibility = this.BookingStatus.filter((ele) => {
        return ele.codeID == this.bookingEntityModel.status;
      })[0]["code"];
      //CHECK FOR BOOKING STATUS
    }
  }
  CheckBookingDetails(bookingID) {
    console.log(this.gridData);
    this.ResetDataFields();
    console.log(
      this.gridData.data.find((ele) => {
        return ele.bookingID == bookingID;
      })
    );
    this.newBookBtnClick = 2;
    let data: any = this.gridData.data.find((ele) => {
      return ele.bookingID == bookingID;
    });
    if (data != undefined) {
      if (data.statusName == "Reserved") {
        this.isCheckoutDetails = true;
        this.isReserveDetails = false;
        this.isCheckedIn = false;
      } else if (data.statusName == "Checked Out") {
        this.isCheckoutDetails = false;
        this.isReserveDetails = false;
        this.isCheckedIn = true;
        data.checkInDate = new Date();
        data.checkInBy = this.UserData.UserID;
      }
      this.bookingEntityModel = new BookingEntity();
      this.signaturePad.clear();
      this.bookingStatusName = null;
      this.bookingEntityModel.assetID = data.assetID;
      this.bookingEntityModel.bookingID = data.bookingID;
      this.bookingEntityModel.endDateTime = data.endDateTime;
      this.bookingEntityModel.startDateTime = data.startDateTime;
      this.bookingEntityModel.reservationNotes = data.reservationNotes;
      this.bookingEntityModel.status = data.status;
      this.bookingEntityModel.reservedBy = data.reservedBy;
      this.bookingEntityModel.receivedBy = data.receivedBy;
      // // console.log(new Date(data.checkOutDate).getFullYear().toString())

      // // if(this.IsPermanentVisibility=true){
      // //   this.bookingEntityModel.receivedBy=this.bookingEntityModel.reservedBy
      // // }

      this.bookingEntityModel.checkOutDate =
        new Date(data.checkOutDate).getFullYear().toString() == "1"
          ? null
          : new Date(data.checkOutDate);
      this.bookingEntityModel.checkOutNotes = data.checkOutNotes;
      this.bookingEntityModel.checkedOutBy = this.UserData.UserID;
      this.bookingEntityModel.returnByDate =
        new Date(data.returnByDate).getFullYear().toString() == "1"
          ? null
          : new Date(data.returnByDate);
      this.bookingEntityModel.signature = data.signature;

      this.bookingEntityModel.isPermanent = data.isPermanent;
      this.bookingEntityModel.isPermanentCheckout = data.isPermanentCheckout;
      this.bookingEntityModel.checkInDate = data.checkInDate;
      this.bookingEntityModel.checkInBy = data.checkInBy;
      this.bookingEntityModel.checkInNotes = data.checkInNotes;

      this.signaturePad.fromDataURL(this.bookingEntityModel.signature);

      this.UserDataList = this.reservedByList.filter((ele) => {
        return ele.userID == this.UserData.UserID;
      });
      this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList;
      this.bookingEntityModel.checkedOutBySelectedEmail = this.UserData.EmailID;

      if (this.bookingEntityModel.isPermanent) {
        this.bookingEntityModel.startDateTime = new Date(); // OR NUll
        this.bookingEntityModel.endDateTime = new Date(); // OR NUll
      }
      if (this.bookingEntityModel.isPermanentCheckout) {
        this.bookingEntityModel.checkOutDate = new Date(); // OR NUll
        this.bookingEntityModel.returnByDate = new Date(); // OR NUll
      }

      // //CHECK FOR BOOKING STATUS
      this.bookingStatus =
        this.gridData.data.find((ele) => {
          return ele.statusName.toLowerCase() == "checked out";
        }) != undefined
          ? "Checked Out"
          : "Available";
      this.bookingStatusName =
        this.gridData.data.find((ele) => {
          return (
            ele.statusName.toLowerCase() == "checked out" &&
            ele.bookingID == data.bookingID
          );
        }) != undefined
          ? "Checked Out"
          : "Available";

      this.bookingStatusVisibility = this.BookingStatus.filter((ele) => {
        return ele.codeID == this.bookingEntityModel.status;
      })[0]["code"];
      // CHECK FOR BOOKING STATUS
    }
  }

  ResetModel() {
    this.UserData = this.sharedDataService.parseJwt(
      window.localStorage.getItem("token")
    );
    this.bookingEntityModel = new BookingEntity();
    this.UserDataList = this.reservedByList.filter((ele) => {
      return ele.userID == this.UserData.UserID;
    });
    this.bookingEntityModel.checkedOutBySelectedList = this.UserDataList;
    this.bookingEntityModel.checkedOutBySelectedEmail = this.UserData.EmailID;
    this.bookingEntityModel.checkedOutBy = this.UserData.UserID;
    this.bookingStatus =
      this.gridData.data.find((ele) => {
        return ele.statusName.toLowerCase() == "checked out";
      }) != undefined
        ? "Checked Out"
        : "Available";
    this.bookingStatusName = this.bookingStatus;
    this.IsRefreshNeeded = false;
    this.bookingStatusName = "Available";
    this.bookingStatusVisibility = "Available";
    // this.IsPermanentVisibility = false
    this.IsPermanentVisibility =
      this.gridData.data.find((ele) => {
        return ele.isPermanent == true && ele.statusName!="Checked In";
      }) != undefined
        ? true
        : false;
    this.signaturePad.clear();
    console.log("BookingStatus", this.bookingStatus);
  }

  ResetDataFields() {
    this.IsEditBtnClick = false;
    this.signaturePad.clear();
    this.ResetModel();
    this.isReserveDetails=false;
    this.isCheckoutDetails=false;
    this.isCheckedIn = false;
  }

  preventStartDateTime(event) {
    if (this.checkoutWithReservation) {
      this.bookingEntityModel.checkOutDate =
        this.bookingEntityModel.startDateTime;
    }
    document
      .getElementById("k-panelbar-0-item-default-0")
      .getElementsByTagName("div")[0].style.overflow = "visible";
    //console.log(document.getElementById('k-panelbar-0-item-default-0').getElementsByTagName('div')[0])
    if (this.bookingEntityModel.bookingID > 0) {
      //CHECK FOR UPDATED BOOKING STATUS
      //this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == this.bookingEntityModel.bookingID }) == undefined ? 'Available' : 'Checked Out'
      this.bookingStatusName =
        this.gridData.data.find((ele) => {
          return (
            ele.statusName.toLowerCase() == "checked out" &&
            ele.bookingID == this.bookingEntityModel.bookingID
          );
        }) != undefined
          ? "Checked Out"
          : "Available";
      //CHECK FOR UPDATED BOOKING STATUS

      if (
      
        this.bookingEntityModel.isPermanent
      ) {
        document
          .getElementsByClassName("wc-date-popover banner-true")[0]
          .setAttribute("hidden", "true");
      } else {
        if (
          document
            .getElementsByClassName("wc-date-popover banner-true")[0]
            .getAttribute("hidden")
        ) {
          document
            .getElementsByClassName("wc-date-popover banner-true")[0]
            .removeAttribute("hidden");
        }
      }
    } else if (
      this.IsPermanentVisibility ||
      this.bookingEntityModel.isPermanent
    ) {
      document
        .getElementsByClassName("wc-date-popover banner-true")[0]
        .setAttribute("hidden", "true");
    } else if (
      !this.IsPermanentVisibility ||
      !this.bookingEntityModel.isPermanent
    ) {
      if (
        document
          .getElementsByClassName("wc-date-popover banner-true")[0]
          .getAttribute("hidden")
      ) {
        document
          .getElementsByClassName("wc-date-popover banner-true")[0]
          .removeAttribute("hidden");
      }
    }
    console.log(this.bookingEntityModel.checkOutDate);
  }

  preventEndDateTime(event) {
    if (this.checkoutWithReservation) {
      this.bookingEntityModel.returnByDate =
        this.bookingEntityModel.endDateTime;
    }
    if (this.bookingEntityModel.bookingID > 0) {
      //CHECK FOR UPDATED BOOKING STATUS
      this.bookingStatusName =
        this.gridData.data.find((ele) => {
          return (
            ele.statusName.toLowerCase() == "checked out" &&
            ele.bookingID == this.bookingEntityModel.bookingID
          );
        }) == undefined
          ? "Available"
          : "Checked Out";
      //CHECK FOR UPDATED BOOKING STATUS
      if (
       
        this.bookingEntityModel.isPermanent
      ) {
        document
          .getElementsByClassName("wc-date-popover banner-true")[1]
          .setAttribute("hidden", "true");
      } else {
        if (
          document
            .getElementsByClassName("wc-date-popover banner-true")[1]
            .getAttribute("hidden")
        ) {
          document
            .getElementsByClassName("wc-date-popover banner-true")[1]
            .removeAttribute("hidden");
        }
      }
    } else if (
      this.IsPermanentVisibility ||
      this.bookingEntityModel.isPermanent
    ) {
      document
        .getElementsByClassName("wc-date-popover banner-true")[1]
        .setAttribute("hidden", "true");
    } else if (
      !this.IsPermanentVisibility ||
      !this.bookingEntityModel.isPermanent
    ) {
      if (
        document
          .getElementsByClassName("wc-date-popover banner-true")[1]
          .getAttribute("hidden")
      ) {
        document
          .getElementsByClassName("wc-date-popover banner-true")[1]
          .removeAttribute("hidden");
      }
    }
    console.log(this.bookingEntityModel.checkOutDate);
    console.log(this.bookingEntityModel.returnByDate);
  }

  TempBookingID: number = 0;
  DeleteBooking(bookingID) {
    this.IsOpenDialog = true;
    this.TempBookingID = bookingID;
  }
  IsOpenDialog: boolean = false;
  closeDeleteAssetBookingDialog(status) {
    // this.IsOpenDialog = true;

    if (status == "true") {
      console.log(this.TempBookingID);
      if (this.TempBookingID > 0) {
        this.coredataservice
          .DeleteAssetBookingDetailsByBookingID(this.TempBookingID)
          .subscribe((res) => {
            const index = this.gridData.data.findIndex(
              (item) => item.bookingID === this.TempBookingID
            );
            if (index >= 0) {
              this.gridData.data.splice(index, 1);
              this.gridData = process(this.gridData.data, this.state);
              this.ResetModel();

              //CHECK FOR UPDATED BOOKING STATUS
              this.bookingStatus =
                this.gridData.data.find((ele) => {
                  return ele.statusName.toLowerCase() == "checked out";
                }) != undefined
                  ? "Checked Out"
                  : "Available";
              //this.bookingStatus = this.gridData.data.find((ele) => { return ele.statusName.toLowerCase() == 'checked out' && ele.bookingID == this.TempBookingID }) == undefined ? 'Available' : 'Checked Out'
              //CHECK FOR UPDATED BOOKING STATUS
              this.IsRefreshNeeded = true;
            }
          });
      }
    }
    // else if(status =='false'){

    // }
    this.IsOpenDialog = false;
  }

  ReservedByErrorEnable: boolean = false;
  ReservedStartTime: boolean = false;
  ReservedEndTime: boolean = false;

  compareDate(d1,d2){
    let dy1 = new Date(d1).getFullYear();
    let dm1 = new Date(d1).getMonth()+1;
    let dd1 = new Date(d1).getDate();
    let dd2 = new Date(d2).getDate();
    let dm2 = new Date(d2).getMonth()+1;
    let dy2 = new Date(d2).getFullYear();
    if(dy1>dy2){
      return 1;
    }else if(dy2>dy1){
      return 2;
    }else{
      if(dm1>dm2){
        return 1;
      }else if(dm2>dm1){
        return 2;
      }else{
        if(dd1>dd2){
          return 1;
        }else if(dd2>dd1){
          return 2;
        }else{
          return 0;
        }
      }
    }

  }

  BookAsset(bookingEntityModel: BookingEntity) {
    console.log('ck', bookingEntityModel)
    //Add validation

    if(this.IsDirectCheckout==true){
      this.bookingEntityModel.reservedBy=this.bookingEntityModel.receivedBy
      this.bookingEntityModel.startDateTime=this.bookingEntityModel.checkOutDate
      this.bookingEntityModel.endDateTime=this.bookingEntityModel.returnByDate
      this.bookingEntityModel.reservationNotes=this.bookingEntityModel.checkOutNotes
     this.bookingEntityModel.isPermanent=this.bookingEntityModel.isPermanentCheckout
    }

    console.log(this.IsValid(bookingEntityModel));
    if (this.IsValid(bookingEntityModel)) {
      if (bookingEntityModel.checkOutDate != null && bookingEntityModel.checkOutDate != undefined && bookingEntityModel.receivedBy !=null &&  bookingEntityModel.receivedBy !=undefined && bookingEntityModel.receivedBy !=0) {
        bookingEntityModel.status = this.BookingStatus.filter((ele) => { return ele.code.toLowerCase() == 'checked out' })[0]['codeID']
      }
      else {
        bookingEntityModel.status = this.BookingStatus.filter((ele) => { return ele.code.toLowerCase() == 'reserved' })[0]['codeID']
      }
      //(bookingEntityModel.checkOutDate == null || bookingEntityModel.checkOutDate == undefined) &&
      if (typeof (bookingEntityModel.checkInDate) != "string" && (bookingEntityModel.checkInDate != null && bookingEntityModel.checkInDate != undefined && bookingEntityModel.checkInBy != null && bookingEntityModel.checkInBy != undefined)) {
        bookingEntityModel.status = this.BookingStatus.filter((ele) => { return ele.code.toLowerCase() == 'checked in' })[0]['codeID']
      }
      
      
      let bookingStatus: any = this.BookingStatus.filter((ele) => { return ele.codeID == bookingEntityModel.status })[0]['code']
      
    

      bookingEntityModel.assetID = this.assetID
      let startDate: any
      let endDate: any
      let checkOutDate:any
      let returnByDate:any
      startDate = moment(bookingEntityModel.startDateTime).format();
      endDate = moment(bookingEntityModel.endDateTime).format();
      checkOutDate=moment(bookingEntityModel.checkOutDate).format();
      returnByDate =moment(bookingEntityModel.returnByDate).format();
      bookingEntityModel.startDateTime = startDate.substr(0, startDate.lastIndexOf("+"))
      bookingEntityModel.endDateTime = endDate.substr(0, endDate.lastIndexOf("+"))
      bookingEntityModel.checkOutDate = checkOutDate.substr(0, checkOutDate.lastIndexOf("+"))
      bookingEntityModel.returnByDate = returnByDate.substr(0, returnByDate.lastIndexOf("+"))


      bookingEntityModel.bookingID = this.bookingEntityModel.bookingID == 0 ? 0 : this.bookingEntityModel.bookingID;

      console.log(bookingEntityModel.startDateTime)
      console.log(bookingEntityModel.endDateTime)
      console.log(bookingEntityModel.checkOutDate)
      console.log(bookingEntityModel.returnByDate)

      

      //&& this.bookingEntityModel.bookingID == undefined
      if (this.bookingEntityModel.isPermanent) {
        this.bookingEntityModel.startDateTime = new Date() 
        this.bookingEntityModel.endDateTime = new Date() 
      }

      if (this.bookingEntityModel.isPermanentCheckout) {
        this.bookingEntityModel.checkOutDate = new Date() 
        this.bookingEntityModel.returnByDate = new Date() 
      }

      if (this.gridData != undefined && this.gridData.data.length > 0 && (this.bookingEntityModel.bookingID == undefined || this.bookingEntityModel.bookingID == 0) && !this.bookingEntityModel.isPermanent) {
        let PreviousEndDatetimeValue = this.gridData.data[this.gridData.data.length - 1]['endDateTime']
        let PreviousStatus=this.gridData.data[this.gridData.data.length - 1]['statusName']
        if (new Date(this.bookingEntityModel.startDateTime).getDate() > new Date(PreviousEndDatetimeValue).getDate() && new Date(this.bookingEntityModel.endDateTime).getDate() > new Date(PreviousEndDatetimeValue).getDate()) {
          console.log(PreviousEndDatetimeValue)
          console.log(this.bookingEntityModel)
        }
        else {
          //Please select the future date to reserve the asset
          let dateEndTimefilter = [];
          let dateStartTimeFilter = [];
          this.Data.forEach(date => {
            dateEndTimefilter.push((date.endDateTime.slice(0, 10)));
            dateStartTimeFilter.push((date.startDateTime.slice(0, 10)));

            console.log(dateEndTimefilter)
          });

     
          // if ((dateStartTimeFilter.find(x => x <  moment(this.bookingEntityModel.startDateTime).toISOString().slice(0, 10)) && dateEndTimefilter.find(x => x >  moment(this.bookingEntityModel.startDateTime).toISOString().slice(0, 10))) &&  PreviousStatus!='Checked In') {
          //   // this.notify.error("This dates are already being reserved, Please select another date");
          //   this.notify.error(this.BusinessErrMessages.ReservedDate);
          //   return
          // }

          if ( moment(this.bookingEntityModel.endDateTime).toISOString().slice(0, 10) <  moment(this.bookingEntityModel.startDateTime).toISOString().slice(0, 10)) {
            //this.notify.error("Please select a valid date range");
            this.notify.error(this.BusinessErrMessages.ValidRange);
            return
          }
          if ( moment(this.bookingEntityModel.returnByDate).toISOString().slice(0, 10) <  moment(this.bookingEntityModel.checkOutDate).toISOString().slice(0, 10)) {
            //this.notify.error("Please select a valid date range");
            this.notify.error(this.BusinessErrMessages.ValidRange);
            return
          }



        
        }
      } else {
        if(this.gridData.data.length>0 ){
        let PreviousEndDatetimeValue = this.gridData.data[this.gridData.data.length - 1]['endDateTime']
        let PreviousStatus=this.gridData.data[this.gridData.data.length - 1]['statusName']
        // if ((new Date(this.bookingEntityModel.startDateTime).getDate() <= new Date(PreviousEndDatetimeValue).getDate() &&(this.bookingEntityModel.bookingID == undefined || this.bookingEntityModel.bookingID == 0)) && PreviousStatus!='Checked In') {
        //   this.notify.error(this.BusinessErrMessages.ReservedDate);
        //   return
        // }else if ((new Date(this.bookingEntityModel.endDateTime).getDate() <= new Date(PreviousEndDatetimeValue).getDate() && (this.bookingEntityModel.bookingID == undefined || this.bookingEntityModel.bookingID == 0) ) && PreviousStatus!='Checked In') {
        //   this.notify.error(this.BusinessErrMessages.ReservedDate);
        //   return
        // }

        

       


        
      }
      }

     

      if(this.isCheckOutGlobal && this.bookingEntityModel.receivedBy !=null &&this.bookingEntityModel.checkInBy==null && !this.IsEditBtnClick ){
         this.notify.error(this.BusinessErrMessages.AlreadyCheckout)
         return
          }

          if(this.gridData!=undefined&&this.gridData.data.length>0&&!this.bookingEntityModel.isPermanent  &&this.bookingEntityModel.checkInBy==null) {
            let dateArr=[];
           
            this.Data.forEach((date) => {
              if(date.statusName!="Checked In" && date.bookingID!=bookingEntityModel.bookingID){
                dateArr.push([date.startDateTime, date.endDateTime]);
              }
            });
            let alreadyReserved =  dateArr.find((x)=> this.compareDate(this.bookingEntityModel.startDateTime,x[0])==1&&this.compareDate(this.bookingEntityModel.startDateTime,x[1])==2 );
               
              if(alreadyReserved){
                //this.notify.error("Please select the future start & end date to reserve/check out the asset");
                this.notify.error(this.BusinessErrMessages.DateSelection);
                return;
              }
             alreadyReserved =  dateArr.find((x)=> this.compareDate(this.bookingEntityModel.endDateTime,x[0])==1&&this.compareDate(this.bookingEntityModel.endDateTime,x[1])==2 );
              if(alreadyReserved){
                //this.notify.error("Please select the future start & end date to reserve/check out the asset");
                this.notify.error(this.BusinessErrMessages.DateSelection);
                return;
              }
              alreadyReserved =  dateArr.find((x)=> this.compareDate(this.bookingEntityModel.endDateTime,x[0])==0||this.compareDate(this.bookingEntityModel.endDateTime,x[1])==0 );
              if(alreadyReserved){
                //this.notify.error("Please select the future start & end date to reserve/check out the asset");
                this.notify.error(this.BusinessErrMessages.DateSelection);
                return;
              }
              alreadyReserved =  dateArr.find((x)=> this.compareDate(this.bookingEntityModel.startDateTime,x[0])==0||this.compareDate(this.bookingEntityModel.startDateTime,x[1])==0 );
              if(alreadyReserved){
                //this.notify.error("Please select the future start & end date to reserve/check out the asset");
                this.notify.error(this.BusinessErrMessages.DateSelection);
                return;
              }
              alreadyReserved =  dateArr.find((x)=> this.compareDate(this.bookingEntityModel.startDateTime,x[0])==2&&this.compareDate(this.bookingEntityModel.endDateTime,x[1])==1 );
              if(alreadyReserved){
                //this.notify.error("Please select the future start & end date to reserve/check out the asset");
                this.notify.error(this.BusinessErrMessages.DateSelection);
                return;
              }
            }

      

      console.log(bookingEntityModel)
      if (this.IsComplianceManagement ==true) {
        this.notify.error(this.BusinessErrMessages.complianceManagementerror);
        return
        
      }
      
      if ( moment(this.bookingEntityModel.endDateTime).toISOString().slice(0, 10) <  moment(this.bookingEntityModel.startDateTime).toISOString().slice(0, 10)) {
        //this.notify.error("Please select a valid date range");
        this.notify.error(this.BusinessErrMessages.ValidRange);
        return
      }
      if ( moment(this.bookingEntityModel.returnByDate).toISOString().slice(0, 10) <  moment(this.bookingEntityModel.checkOutDate).toISOString().slice(0, 10)) {
        //this.notify.error("Please select a valid date range");
        this.notify.error(this.BusinessErrMessages.ValidRange);
        return
      }

      else {
        this.coredataservice.bookAssets(bookingEntityModel).subscribe(res => {
          // console.log(res)
          this.bookingEntityModel = new BookingEntity()
          if (this.signaturePad != undefined) {
            this.signaturePad.clear();
          }
          //this.signaturePad.clear();
          // this.notify.success("Asset booked successfully");
          this.notify.success(this.BusinessErrMessages.AssetBooking);
          this.router.navigate(['/business-assets-listing']);
        })
      }

      // }
      // else {
      //   this.notify.error("Please select a valid date");
      // }
    }
    else {
      //this.notify.error("Please fill all the mandatory fields");
      this.notify.error(this.BusinessErrMessages.MandatoryField);
    }

    //console.log(bookingEntityModel)

  }

  selectIsPermanent() {
    this.bookingEntityModel.isPermanent = !this.bookingEntityModel.isPermanent;
    // this.bookingEntityModel.isPermanentCheckout = this.bookingEntityModel.isPermanent
    this.bookingEntityModel.startDateTime = new Date();
    this.bookingEntityModel.endDateTime = new Date();
  }

  selectIsPermanentCheckout() {
    this.bookingEntityModel.isPermanentCheckout =
      !this.bookingEntityModel.isPermanentCheckout;
    this.bookingEntityModel.checkOutDate = new Date();
    this.bookingEntityModel.returnByDate = new Date();
  }

  IsValid(bookingEntityModel: BookingEntity): boolean {
    if (
      bookingEntityModel.startDateTime != undefined &&
      bookingEntityModel.endDateTime != undefined &&
      bookingEntityModel.reservedBy != undefined &&
      bookingEntityModel.reservedBy != null
    ) {
      return true;
    } else {
      return false;
    }
  }

  OnSelectionChange(event) {
    if (this.SelectedSelection == "Check In") {
      var CheckoutRow = this.gridData.data.find((ele) => {
        return ele.statusName.toLowerCase() == "checked out";
      });
      if (CheckoutRow != null) {
        this.EditBookingDetails(CheckoutRow.bookingID);
      }
    }
  }

  itemDisabled(itemArgs) {
    return itemArgs.dataItem.disabled;
  }
}
