import { Component, OnInit } from '@angular/core';
import { ChangePasswordModel } from './change-password.model';
import { from } from 'rxjs';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";
import { CoreDataService } from '../../Service/core-data.service'
import { SnotifyService } from 'ng-snotify';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';

import { Router } from '@angular/router';
import { AuthenticationService } from '../../Service/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private DataService: CoreDataService, private notify: SnotifyService, private sharedService: SharedDataService, private router: Router, private authservice: AuthenticationService,
  ) { }

  ChangePasswordModel: ChangePasswordModel = new ChangePasswordModel();
  formGroup: FormGroup;
  match: boolean = true;
  LanguageConfig: any;
  ErrorMsgData: any;
  ngOnInit() {

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[38];
          this.ErrorMsgData = res[28]
        }
      })
    }

    this.formGroup = new FormGroup({
      oldPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
    });
  }

  ConfirmPass() {
    if (this.formGroup.value['newPassword'] == this.formGroup.value['confirmPassword']) {
      this.match = true;
      this.ChangePasswordModel.oldPassword = this.sharedService.password;
      document.getElementById('confirmPassword').style.borderColor = "#00a65a";
      return true
    }
    else {
      this.match = false;
      document.getElementById('confirmPassword').style.borderColor = "#dc3545";
      return false
    }
  }

  Reset() {
    this.formGroup.reset()
  }
  changePassword() {
    if (this.formGroup.valid && this.ConfirmPass()) {
      console.log("pass",this.sharedService.password);
      this.DataService.changePassword(this.ChangePasswordModel).subscribe(res => {
        if (res != undefined && res.message == "Success") {


          this.authservice.loggedIn$.next(null);
          var UserData = this.sharedService.parseJwt(localStorage.getItem('token'));
          var obj = { email: UserData.EmailID, password: this.ChangePasswordModel.confirmPassword };

          this.authservice.getLogin(obj).subscribe((data): any => {
            if (data != undefined) {
              this.Reset()
              localStorage.setItem('token', data['access_token'])
              this.sharedService.ShowChangePass$.next('false');
              this.notify.success("Password changed successfully")
            }
          })

        }
        else if (res != undefined && res.message == "InvalidOldPassword") {
          this.notify.error("Please enter correct old password")
        }
      })
    }
    else {
      Object.keys(this.formGroup.controls).forEach(field => { // {1}
        const control = this.formGroup.get(field); // {2}
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });
      this.ConfirmPass()
      this.notify.error("Some error occured")
    }
  }


}
