import { Component, OnInit, Inject, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { Observable, from } from 'rxjs';

import { FormGroup, FormControl, Validators } from '@angular/forms';


import { State, process } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DocumentsUploadEntity } from '../documents-upload/documents-upload.model';
import { FileInfo } from '@progress/kendo-angular-upload';
import { SnotifyService } from 'ng-snotify';
import { CoreDataService } from '../../Service/core-data.service';
import * as FileSaver from 'file-saver';
import { environment } from "../../../environments/environment";
import { SharedDataService } from 'src/app/Service/CommonUtility.service';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.css']
})
export class DocumentsUploadComponent implements OnInit, AfterViewInit {

  @Output() DocumentsData: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() DocType: string;
  @Input() ID: string;
  LanguageConfig: any;
  IsUserCustomer: string;

  constructor(private notify: SnotifyService, private DataService: CoreDataService, private sharedDataService: SharedDataService) { }
  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  DocumentsUploadEntity: DocumentsUploadEntity = new DocumentsUploadEntity();
  DocumentsUploadEntityList: Array<DocumentsUploadEntity> = new Array<DocumentsUploadEntity>();
  public editedRowIndex: number;
  myFiles: Array<FileInfo> = [];
  fileName: string
  IsDocRequired: boolean = true;
  extension: string
  public formGroup: FormGroup;
  maxLimitDoc: Number;
  ErrorDataMsg: any;
  maxDocLimitReach: boolean = false
  documentFormat: Array<string> = ['.pdf', '.docx', '.doc', '.xls', '.xlsx'];
  ngOnInit() {

    this.getDocumentsData();

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[26];
          this.ErrorDataMsg = res[28];
        }
      })
    }

    this.IsUserCustomer = this.sharedDataService.IsCustomer();

  }

  getDocumentsData() {
    this.DocumentsUploadEntity = new DocumentsUploadEntity();
    let arrTemp = []
    this.DataService.GetDocuments(this.DocType, this.ID, true).subscribe(ele => {
      if (ele != undefined && ele != null) {

        ele.forEach(e => {
          e.fileName = e.fileName + e.extension
          e.IsUpdated = false;
        })
        arrTemp = ele
        this.DocumentsUploadEntityList = arrTemp
        this.gridData = process(this.DocumentsUploadEntityList, this.state);
        this.maxfieldLimit()

      }
    })

  }
  ngAfterViewInit() {

  }
  selectEventHandler(e: any) {
    
    e.files.forEach((file) => {
      if((this.checkSpecialCharacter(file.name.substr(0, file.name.lastIndexOf('.'))))){
        this.notify.error('File Name with special Character Not Allowed')
        return
      }

      if (file.size < 10485760) {
        if (this.documentFormat.includes(file.extension)) {
          const reader = new FileReader();
          reader.onload = (ev) => {
            this.DocumentsUploadEntity.fileName = file.name;
            this.DocumentsUploadEntity.extension = file.extension
            this.DocumentsUploadEntity.documentData = this.convertDataURIToBinary(ev.target['result'])
            this.DocumentsUploadEntity.IsUpdated = true
          }
          reader.readAsDataURL(file.rawFile);
        }
        else {
          this.notify.error(this.ErrorDataMsg.onlyFormat + this.documentFormat.toString())
          return
        }
      }
      else {
        this.notify.error(this.ErrorDataMsg.uploadSizeLimit)
        return
      }




    });
  }

  convertDataURIToBinary(dataURI) {

    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  addHandler({ sender }) {
    this.closeEditor(false, sender);
    sender.addRow(new DocumentsUploadEntity());
    this.DocumentsUploadEntity = new DocumentsUploadEntity();

  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(true, sender, rowIndex);
  }
  editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(false, sender);

    this.editedRowIndex = rowIndex;
    this.DocumentsUploadEntity = Object.assign({}, dataItem);

    sender.editRow(rowIndex);
  }

  maxfieldLimit() {
    if (this.DocumentsUploadEntityList.length >= 3) {
      this.maxDocLimitReach = true
    }
    else if (this.DocumentsUploadEntityList.length <= 3) {
      this.maxDocLimitReach = false
    }
  }
 
  checkSpecialCharacter(str){
    let specialCharacters = /[$%*\\;:.<>/]/;
    let emojis=/\p{Extended_Pictographic}/u

    if(specialCharacters.test(str) || emojis.test(str)){
     return true
    }
    else
    return false

    

  }

  saveHandler({ sender, rowIndex, dataItem, isNew }) {

    
    if (isNew) {
      dataItem.fileName = this.DocumentsUploadEntity.fileName
      dataItem.extension = this.DocumentsUploadEntity.extension
      dataItem.documentData = this.DocumentsUploadEntity.documentData
      dataItem.IsUpdated = this.DocumentsUploadEntity.IsUpdated
      if (dataItem.fileName == undefined || dataItem.documentData == undefined) {
        this.notify.error(this.ErrorDataMsg.pleaseUploadDocument)
        return
      }
     
      this.DocumentsUploadEntityList.push(dataItem)
    }
    else {
    
      dataItem.IsUpdated = (dataItem.title != this.DocumentsUploadEntity.title) || (dataItem.description != this.DocumentsUploadEntity.description) ? true : false;
      dataItem.fileName = this.DocumentsUploadEntity.fileName
      dataItem.extension = this.DocumentsUploadEntity.extension
      dataItem.documentData = this.DocumentsUploadEntity.documentData
      this.DocumentsUploadEntityList[rowIndex] = dataItem
    }

    this.maxfieldLimit()
    this.gridData = process(this.DocumentsUploadEntityList, this.state);
    sender.closeRow(rowIndex);

    this.DocumentsData.emit(this.DocumentsUploadEntityList);

    this.editedRowIndex = undefined;
    this.DocumentsUploadEntity = undefined;
  }

  closeEditor(status, grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);

    if (status) {
      this.DocumentsUploadEntityList[rowIndex] = this.DocumentsUploadEntity
    }
    this.gridData = process(this.DocumentsUploadEntityList, this.state);
    this.editedRowIndex = undefined;
    this.DocumentsUploadEntity = undefined;
  }

  removeHandler({ dataItem, rowIndex }) {
    if (dataItem.documentID != undefined && dataItem.documentID > 0) {
      this.DataService.DeleteDocument(dataItem.documentID).subscribe(res => {
        this.DocumentsUploadEntityList.splice(rowIndex, 1);
        this.gridData = process(this.DocumentsUploadEntityList, this.state);
        this.maxfieldLimit()
      })
    }
    else {
      this.DocumentsUploadEntityList.splice(rowIndex, 1);
      this.maxfieldLimit()
    }
    this.gridData = process(this.DocumentsUploadEntityList, this.state);
  }


  DownloadFile(dataItem) {
    this.saveAsFile(this._base64ToArrayBuffer(dataItem.docPath), dataItem.fileName, dataItem.extension.split('.')[1]);
    //  FileSaver.saveAs(environment.environment.attachmentPath+dataItem.docPath, dataItem.fileName);
  }
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx'

  private saveAsFile(buffer: any, fileName: string, extension: string): void {
    const data: Blob = new Blob([buffer], {
      type: "application/" + extension + ";charset=UTF-8"
    });
    FileSaver.saveAs(data, fileName);
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.gridData = process(this.DocumentsUploadEntityList, this.state);
  }
}
