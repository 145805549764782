import { FormControl, FormGroup, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";

export class AssetValidationComponent {


    ValidateMeathod() {
        var formGroup = new FormGroup({
          assetID: new FormControl("", [Validators.maxLength(10)]),
          barcode: new FormControl("", [
            Validators.maxLength(100),
            Validators.required,
          ]),
          customerAssetID: new FormControl("", [Validators.maxLength(100)]),
          installDate: new FormControl("", [Validators.maxLength(100)]),
          mfgCd: new FormControl("", [Validators.maxLength(100)]),
          modelNbr: new FormControl("", [Validators.maxLength(100)]),
          roomNo: new FormControl("", [Validators.maxLength(100)]),
          buildingNo: new FormControl("", [Validators.maxLength(100)]),
          calculatedReplacementYear: new FormControl("", [
            Validators.maxLength(100),
          ]),

          serialNo: new FormControl("", [Validators.maxLength(100)]),
          userAssetID: new FormControl("", [Validators.maxLength(100)]),
          assetLocationID: new FormControl("", [Validators.maxLength(100)]),
          ownershipCd: new FormControl("", [Validators.maxLength(100)]),
          conditionRatingCd: new FormControl("", [Validators.maxLength(100)]),
          statusCd: new FormControl("", [Validators.maxLength(100)]),
          assetTypeID: new FormControl("", [Validators.maxLength(100)]),
          indoorOutdoorCd: new FormControl("", [Validators.maxLength(100)]),
          geoLocation: new FormControl("", [
            Validators.maxLength(70),
            Validators.required,
          ]),
          primaryValidation: new FormControl("", []),
          areaServed: new FormControl("", [Validators.maxLength(100)]),
          areaLocated: new FormControl("", [Validators.maxLength(100)]),
          levelLocated: new FormControl("", [Validators.maxLength(100)]),
          fireModeFnCd: new FormControl("", [Validators.maxLength(100)]),
          essentialServiceCd: new FormControl("", [Validators.maxLength(100)]),
          internalComments: new FormControl("", [Validators.maxLength(100)]),
          oHSComments: new FormControl("", [Validators.maxLength(100)]),
          generalComments: new FormControl("", [Validators.maxLength(100)]),

          criticalityCd: new FormControl("", [Validators.maxLength(100)]),
          replacementCostBudget: new FormControl("", [
            Validators.maxLength(100),
          ]),
          expectedLife: new FormControl("", [Validators.maxLength(100)]),
          remainingLife: new FormControl("", [Validators.maxLength(100)]),
          actualAge: new FormControl("", [Validators.maxLength(100)]),
          efficiencyAge: new FormControl("", [Validators.maxLength(100)]),
          equivalentAge: new FormControl("", [Validators.maxLength(100)]),
          maintenanceStdCd: new FormControl("", [Validators.maxLength(100)]),
          assetRunTimeCd: new FormControl("", [Validators.maxLength(100)]),
          dampersFittedCd: new FormControl("", [Validators.maxLength(100)]),
          filterDesc: new FormControl("", [Validators.maxLength(100)]),
          fanBeltPartNbr: new FormControl("", [Validators.maxLength(100)]),
          fanBeltQty: new FormControl("", [Validators.maxLength(100)]),
          refrigerantCd: new FormControl("", [Validators.maxLength(100)]),
          refrigerantQty: new FormControl("", [Validators.maxLength(100)]),
          nFactor: new FormControl("", [Validators.maxLength(100)]),
          ageGroup: new FormControl("", [Validators.maxLength(100)]),
          lastAnnual: new FormControl("", [Validators.maxLength(100)]),
          lastService: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear1: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear2: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear3: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear4: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear5: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear1: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear2: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear3: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear4: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear5: new FormControl("", [Validators.maxLength(100)]),
          customerBudgetPrice: new FormControl("", [Validators.maxLength(100)]),

          auditCompleted: new FormControl("", [Validators.maxLength(100)]),
          capacity: new FormControl("", [Validators.maxLength(100)]),
          quantity: new FormControl("", [Validators.maxLength(100)]),
          // firstAuditduration: new FormControl('', [
          //     Validators.maxLength(100)
          // ]),
          // lastAuditduration: new FormControl('', [
          //     Validators.maxLength(100)
          // ]),
          capExBudgetYear6: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear7: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear8: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear9: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear10: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear11: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear12: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear13: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear14: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear15: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear16: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear17: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear18: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear19: new FormControl("", [Validators.maxLength(100)]),
          capExBudgetYear20: new FormControl("", [Validators.maxLength(100)]),
          dLPEndDate: new FormControl("", [Validators.maxLength(100)]),
          maintenanceCompletedCd: new FormControl("", [
            Validators.maxLength(100),
          ]),
          faultCodes: new FormControl("", [Validators.maxLength(100)]),
          fixCodes: new FormControl("", [Validators.maxLength(100)]),
          priorityCd: new FormControl("", [Validators.maxLength(100)]),
          permits: new FormControl("", [Validators.maxLength(100)]),
          environmentCd: new FormControl("", [Validators.maxLength(100)]),
          replacementYear: new FormControl("", [Validators.maxLength(100)]),
          pumpDuty: new FormControl("", [Validators.maxLength(100)]),
          hYDRStorz: new FormControl("", [Validators.maxLength(100)]),
          layFlatHoses: new FormControl("", [Validators.maxLength(100)]),
          maxPeople: new FormControl("", [Validators.maxLength(100)]),
          customerCommentary: new FormControl("", [Validators.maxLength(100)]),
          d3NetAddress: new FormControl("", [Validators.maxLength(100)]),
          airnetAddress: new FormControl("", [Validators.maxLength(100)]),
          appliedFieldCodeSetting: new FormControl("", [
            Validators.maxLength(100),
          ]),
          thicknessDepth: new FormControl("", [Validators.maxLength(100)]),
          widthBreadth: new FormControl("", [Validators.maxLength(100)]),
          heightLength: new FormControl("", [
            Validators.maxLength(100),
            // Validators.pattern("^[0-9]{1," + (12 - 2) +"}(?:\.[0-9]{1," + 2 + "})?$")
          ]),
          reccWithdrawalDate: new FormControl("", [Validators.maxLength(100)]),
          ductworkandInsulation: new FormControl("", [
            Validators.maxLength(100),
          ]),
          specialComments: new FormControl("", [Validators.maxLength(100)]),
          economyDampersCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          electricalWiringCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          inactiveLeafWidth: new FormControl("", [Validators.maxLength(100)]),
          refrigPipeworkCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          maintenanceFreqCd: new FormControl("", [Validators.maxLength(100)]),
          decommissionedAssetID: new FormControl("", [
            Validators.maxLength(100),
          ]),
          noOfPoles: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear6: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear7: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear8: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear9: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear10: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear11: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear12: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear13: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear14: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear15: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear16: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear17: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear18: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear19: new FormControl("", [Validators.maxLength(100)]),
          rMBudgetYear20: new FormControl("", [Validators.maxLength(100)]),
          auditConditionRating: new FormControl("", [
            Validators.maxLength(100),
          ]),
          electricalInputKW: new FormControl("", [Validators.maxLength(100)]),
          coolingCoilCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          assetSizeCd: new FormControl("", [Validators.maxLength(100)]),
          valvesandActuatorsCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          indoorCoilCondition: new FormControl("", [Validators.maxLength(100)]),
          filterTypeCd: new FormControl("", [Validators.maxLength(100)]),
          outdoorCoilCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          systemID: new FormControl("", [Validators.maxLength(100)]),
          fireDoorFRLRatingCd: new FormControl("", [Validators.maxLength(100)]),
          heatingCoilCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          supplyAirFanMotorCondition: new FormControl("", [
            Validators.maxLength(100),
          ]),
          filterQty: new FormControl("", [Validators.maxLength(100)]),
          recommendedActionCd: new FormControl("", [Validators.maxLength(100)]),
          uOMCd: new FormControl("", [Validators.maxLength(100)]),
          totalSpend: new FormControl("", [Validators.maxLength(100)]),
          jobCount: new FormControl("", [Validators.maxLength(100)]),
          // assetCaptureDate: new FormControl('', [
          //     Validators.maxLength(100)
          // ]),
          batteries: new FormControl("", [Validators.maxLength(100)]),

          purchaseDate: new FormControl("", [Validators.maxLength(100)]),
          registrationExpiryDate: new FormControl("", [
            Validators.maxLength(100),
          ]),
          nextService: new FormControl("", [Validators.maxLength(100)]),
          nextTestandTag: new FormControl("", [Validators.maxLength(100)]),
          lastTestandTag: new FormControl("", [Validators.maxLength(100)]),
          fuelType: new FormControl("", [Validators.maxLength(100)]),
          version: new FormControl("", [Validators.maxLength(100)]),
          registrationNumber: new FormControl("", [Validators.maxLength(100)]),
          purchaseOrderReference:new FormControl("",[Validators.maxLength(100)]),
        });

        return formGroup
    }


}