export class AssetsRMListEntity{
  public rmBudetID:string
  public barcode:string
  public userAssetID:string
   public assetID :number
    public assetLocationID :number
   public  rmItem :string
   public assetClass:string
   public assetStatus:string
  public  rmBudgetYear1 :number
  public  rmBudgetYear2 :number
  public  rmBudgetYear3 :number
  public  rmBudgetYear4 :number
  public  rmBudgetYear5 :number
  public  rmBudgetYear6 :number
  public  rmBudgetYear7 :number
  public  rmBudgetYear8 :number
  public  rmBudgetYear9 :number
  public  rmBudgetYear10:number
  public  rmBudgetYear11:number
  public  rmBudgetYear12:number
  public  rmBudgetYear13:number
  public  rmBudgetYear14:number
  public  rmBudgetYear15:number
  public  rmBudgetYear16:number
  public  rmBudgetYear17:number
  public  rmBudgetYear18:number
  public  rmBudgetYear19:number
  public  rmBudgetYear20:number
  public rmBudgetYear1Comment:string
  public rmBudgetYear2Comment:string
  public rmBudgetYear3Comment:string
  public rmBudgetYear4Comment:string
  public rmBudgetYear5Comment:string
  public rmBudgetYear6Comment:string
  public rmBudgetYear7Comment:string
  public rmBudgetYear8Comment:string
  public rmBudgetYear9Comment:string
  public rmBudgetYear10Comment:string
  public rmBudgetYear11Comment:string
  public rmBudgetYear12Comment:string
  public rmBudgetYear13Comment:string
  public rmBudgetYear14Comment:string
  public rmBudgetYear15Comment:string
  public rmBudgetYear16Comment:string
  public rmBudgetYear17Comment:string
  public rmBudgetYear18Comment:string
  public rmBudgetYear19Comment:string
  public rmBudgetYear20Comment:string
  public locationName:string
 

 
  
}
export class AssetsRMModel{
public locationID:number;
public addUpdateRMBudgetModel:Array<AssetsRMListEntity>;
}
