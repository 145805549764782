
import { RequestPasswordAuthGuardService } from './Service/request-auth-gaurd.service';
import { ResetPasswordAuthGuardService } from './Service/reset-password-gaurd.service';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { DataExportComponent } from "../app/SystemAdmin/data-export/data-export.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from '../app/Common/header/header.component';
import { AppComponent } from './app.component';
// import { AuthHttp } from 'angular2-jwt';
import { CommonFunctionService } from "../app/Service/common-function.service";
import { DashboardComponent } from "../app/Dashboard/dashboard.component";
import { LabelModule } from '@progress/kendo-angular-label';
import { NgxDnDModule } from '@swimlane/ngx-dnd';


import { DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { AssetFieldConfigComponent } from "../app/AccountManagement/asset-field-config/asset-field-config.component";
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { UploadModule } from '@progress/kendo-angular-upload';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { EmailConfigurationComponent } from "../app/SystemAdmin/email-configuration/email-configuration.component";
import { EmailConfigurationListingComponent } from "../app/SystemAdmin/email-configuration-listing/email-configuration-listing.component";
import { HttpModule } from '@angular/http';
import { AssetGroupComponent } from "../app/AccountManagement/asset-group/asset-group.component";
import { LoginComponent } from '../app/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { LocationManagementComponent } from "../app/AccountManagement/location-management/location-management.component";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EditService } from "../app/Service/edit.service";

import { EditCompanyComponent } from "../app/SystemAdmin/edit-company/edit-company.component";
import { ModalModule } from 'ngx-bootstrap/modal';
import { AddAssetComponent } from '../app/AccountManagement/add-asset/add-asset.component';
import { AttachmentComponent } from "../app/Common/attachment/attachment.component";
import { AuthHttpInterceptor } from "../app/Service/http-interceptor.service";
import { AssetsListingComponent } from '../app/AccountManagement/asset-listing/assets-listing.component';
import { CompanyManagementComponent } from '../app/SystemAdmin/company-management/company-management.component';
import { CreateCompanyComponent } from '../app/SystemAdmin/create-company/create-company.component';
import { CustomerManagementComponent } from '../app/AccountManagement/customer-management/customer-management.component';
import { AddCustomerComponent } from '../app/AccountManagement/add-customer/add-customer.component';
import { AssetTypeManagementComponent } from '../app/AccountManagement/asset-type-management/asset-type-management.component';
import { CreateAssetTypeComponent } from '../app/AccountManagement/create-asset-type/create-asset-type.component';
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { ChartModule } from "@progress/kendo-angular-charts";
import { StatusConfigurationComponent } from "../app/AccountManagement/status-configuration/status-configuration.component";
import { CreateAssetGroupComponent } from "../app/AccountManagement/create-asset-group/create-asset-group.component";
import { OrganisationSettingComponent } from "../app/SystemAdmin/organisation-setting/organisation-setting.component";
import { AddLocationComponent } from "../app/AccountManagement/add-location/add-location.component";
import { routing } from './app-routing.module';
import { CoreDataService } from "../app/Service/core-data.service";
import { FooterComponent } from "../app/Common/footer/footer.component";
import { AuthGuardService } from '../app/Service/auth-guard.service';
import { AuthenticationService } from '../app/Service/authentication.service';
import { SharedDataService } from './Service/CommonUtility.service';
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from 'ngx-toastr';
import { EditAssetGroupComponent } from "../app/AccountManagement/edit-asset-group/edit-asset-group.component";
import { AddUserComponent } from "../app/SystemAdmin/add-user/add-user.component";
import { ImageUploadComponent } from "../app/Common/image-upload/image-upload.component";
import { UserListComponent } from './SystemAdmin/user-list/user-list.component';
import { UnitOfMeasurmentgComponent } from '../app/SystemAdmin/unit-of-measurment/unit-of-measurment.component';
import { UnitOfMeasurmentListingComponent } from '../app/SystemAdmin/unit-of-measurment-listing/unit-of-measurment-listing.component'
import { UserEditRouteResolver } from '../app/SystemAdmin/user-list/user-resolver.service';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { ImageViewerModule } from "ngx-image-viewer";
import { ReportsComponent } from "../app/AccountManagement/Reports/reports.component";
import { CommonFilterComponent } from "../app/Common/CommonFilter/commonFilter.component";
import { PopupModule } from "@progress/kendo-angular-popup";
import { GlobalSearchComponent } from "../app/Common/global-search/global-search.component";
import { from } from 'rxjs';
import 'hammerjs';
import { AssetJobsComponent } from './AccountManagement/asset-jobs/asset-jobs.component';

import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
 import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { GridContextMenuComponent } from '../app/Common/grid-context-menu.component';

import { UppercaseInputDirective } from '../app/Common/Directives/Directives'
import { ExcelService } from '../app/Service/excel.service';
import { ExcelServiceExport } from '../app/Service/ExcelExport.service';

import { HelpComponent } from './help/help.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ResponsePasswordResetComponent } from './response-password-reset/response-password-reset.component';
import { CodeManagementComponent } from '../app/SystemAdmin/code-management/code-management.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { MyTeamsDashboardComponent } from './my-teams-dashboard/my-teams-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RangeSliderModule } from 'ngx-rangeslider-component';
import { ResponsePasswordResetMobile } from '../app/response-password-reset/response-password-reset-mobile.component';
import { AssetRefactoringComponent } from './SystemAdmin/asset-refactoring/asset-refactoring.component'
import { SubAssetsComponent } from './AccountManagement/sub-assets/sub-assets.component'
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { WizardComponent } from './Common/FormWizard/wizard.component';
import { WizardStepComponent } from './Common/FormWizard/wizard-step.component';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppInitService } from './app.init.service';
import { ChangePasswordComponent } from '../app/Common/change-password/change-password.component';
import { MyAccountComponent } from '../app/Common/my-account/my-account.component';
import { SyschangepasswordComponent } from '../app/Common/syschangepassword/syschangepassword.component'
// import { AppRoutingModule } from '../../../app/app/app-routing.module';
// import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ChecklistModule } from 'angular-checklist';

import { dateFormatPipe } from '../app/Common/dateFormatPipe.pipe';
import { validDateCheckPipe } from '../app/Common/validDateCheckPipe.pipe';

// import { MultiSelectModule } from 'angular5-multiselectcheckbox'
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import { IntlModule, load } from '@progress/kendo-angular-intl';
import { LocaleHelper } from './locale.helper';
import { AdminTabComponent } from '../app/Common/admin-tab/admin-tab.component';
import { ActiveSessionsComponent } from '../app/Common/active-sessions/active-sessions.component';
import { CustomCurrency } from '../app/Common/CustomCurrency.pipe';
import { CommentHistoryComponent } from '../app/Common/comment-history/comment-history.component';
import { DocumentsUploadComponent } from '../app/Common/documents-upload/documents-upload.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';



import { CookieService } from 'ngx-cookie-service';
import { DashboardFilterComponent } from './Common/dashboard-filter/dashboard-filter.component';
import { FaultfixManagementComponent } from './SystemAdmin/faultfix-management/faultfix-management.component';
import { ContentManagementComponent } from './SystemAdmin/content-management/content-management.component';
import { UserActivityReportComponent } from './SystemAdmin/user-activity-report/user-activity-report.component';
import { DataImportComponent } from './SystemAdmin/data-import/data-import.component';
import { ReportOptionsComponent } from './Common/report-options/report-options.component';
import { BusinessAssetsComponent } from './AccountManagement/business-assets/business-assets.component';
import { ReserveAssetComponent } from './InventoryManagement/reserve-asset/reserve-asset.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { BookingAssetComponent } from './InventoryManagement/booking-asset/booking-asset.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { NewReportsComponent } from './AccountManagement/New Report/reports.component';
import { LocationRMListComponent } from './AccountManagement/RM-listing/LocationRMList-management.component';
import { AssetsRMList } from './AccountManagement/RM-listing/AssetsRM-management.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { BusinessDashboardComponent } from './InventoryManagement/business-Dashboard/business-dashboard.component';
import { InventoryReportsComponent } from './InventoryManagement/Inventory Report/reports.component';
import { AddAssetJobComponent } from './AccountManagement/add-asset-jobs/add-asset-job.component';
import { AbmModule } from 'angular-baidu-maps';
import { ExpenseDashboardComponent } from './AccountManagement/expense-dashboard/expense-dashboard.component';
import { ImageImportComponent } from './SystemAdmin/data-import/image-import.component';
import { AuthService } from './Service/auth.service';
import {  EditorModule } from '@progress/kendo-angular-editor';






export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

load(
  // supplemental data
  require(`cldr-data/supplemental/likelySubtags.json`),
  require(`cldr-data/supplemental/weekData.json`),
  require(`cldr-data/supplemental/currencyData.json`),
  // require("cldr-data/supplemental/dateFields.json"),

  // locale data
  require(`cldr-data/main/en-GB/numbers.json`),
  require(`cldr-data/main/en-GB/currencies.json`),
  require(`cldr-data/main/en-GB/ca-gregorian.json`),
  require(`cldr-data/main/en-GB/timeZoneNames.json`),
  require("cldr-data/main/en-GB/dateFields.json"),

  require(`cldr-data/main/en-AU/numbers.json`),
  require(`cldr-data/main/en-AU/currencies.json`),
  require(`cldr-data/main/en-AU/ca-gregorian.json`),
  require(`cldr-data/main/en-AU/timeZoneNames.json`),
  require("cldr-data/main/en-AU/dateFields.json"),




);

@NgModule({
  declarations: [
    CustomCurrency,
    dateFormatPipe,
    validDateCheckPipe,
    SubAssetsComponent,
    WizardStepComponent,
    UppercaseInputDirective,
    EditAssetGroupComponent,
    GridContextMenuComponent,
    ReportsComponent,
    NewReportsComponent,
    AddCustomerComponent,
    DashboardComponent,
    LocationRMListComponent,
  AssetsRMList ,

  
    AddUserComponent,
    DataImportComponent,
    EditCompanyComponent,
    DataExportComponent,
    ImageUploadComponent,
    CreateAssetGroupComponent,
    AttachmentComponent,
    AppComponent,
    AssetFieldConfigComponent,
    AddAssetComponent,
    AssetGroupComponent,
    StatusConfigurationComponent,
    HeaderComponent,
    AddLocationComponent,
    LoginComponent,
    FooterComponent,
    AssetsListingComponent,
    CompanyManagementComponent,
    CreateCompanyComponent,
    CustomerManagementComponent,
    AssetTypeManagementComponent,
    CreateAssetTypeComponent,
    LocationManagementComponent,
    UserListComponent,
    OrganisationSettingComponent,
    EmailConfigurationComponent,
    EmailConfigurationListingComponent,
    UnitOfMeasurmentgComponent,
    UnitOfMeasurmentListingComponent,
    CommonFilterComponent,
  
     GlobalSearchComponent,
    ReportsComponent,
    NewReportsComponent,
    AssetJobsComponent,
    HelpComponent,
    RequestPasswordResetComponent,
    ResponsePasswordResetComponent,
    CodeManagementComponent,
    MyTeamsDashboardComponent,
    AdminDashboardComponent,
    ResponsePasswordResetMobile,
    AssetRefactoringComponent,
    WizardComponent,
    ChangePasswordComponent,
    MyAccountComponent,
    SyschangepasswordComponent,
    AdminTabComponent,
    ActiveSessionsComponent,
    CommentHistoryComponent,
    DocumentsUploadComponent,
    DashboardFilterComponent,
    FaultfixManagementComponent,
    ContentManagementComponent,
    UserActivityReportComponent,
    ReportOptionsComponent,
    BusinessAssetsComponent,
    ReserveAssetComponent,
    BookingAssetComponent,
    WhatsNewComponent,
    BusinessDashboardComponent,
    InventoryReportsComponent,
    AddAssetJobComponent,
    ExpenseDashboardComponent,
    ImageImportComponent
    
    

  ],
  imports: [
    ScrollToModule.forRoot(),
    NgxUpperCaseDirectiveModule,
    ScrollViewModule,
    RangeSliderModule,
    Ng2ImgMaxModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    ExcelModule,
    TreeViewModule,
    ChecklistModule,
    BrowserModule, HttpClientModule,
    ExcelExportModule,
    PDFExportModule,
    IntlModule,
    NgxDnDModule,
    NgBootstrapFormValidationModule.forRoot(),
    ToastrModule.forRoot(),
    AngularDateTimePickerModule,
    SignaturePadModule,


    ImageViewerModule.forRoot(
      {
        btnClass: 'default', // The CSS class(es) that will apply to the buttons
        zoomFactor: 0.1, // The amount that the scale will be increased by
        containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
        wheelZoom: true, // If true, the mouse wheel can be used to zoom in
        allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
        allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
        btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
          zoomIn: 'fa fa-plus',
          zoomOut: 'fa fa-minus',
          rotateClockwise: 'fa fa-repeat',
          rotateCounterClockwise: 'fa fa-undo',
          next: 'fa fa-arrow-right',
          prev: 'fa fa-arrow-left',
          fullscreen: 'fa fa-arrows-alt',
        },
        btnShow: {
          zoomIn: true,
          zoomOut: true,
          rotateClockwise: false,
          rotateCounterClockwise: false,
          next: true,
          prev: true
        }
      }
    ),
    DialogsModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    InputsModule,
    SwitchModule,
    PopupModule,
    DialogModule,
    ChartModule,
    HttpModule,
    routing,
    DateInputsModule,
    ButtonsModule,
    DropDownsModule,
    LabelModule,
    DropDownListModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDTsPSc5YUSI6YI0gI0g5Z3ouF7-aQv260',
      libraries: ["places", "routes", "city"]
    }),
    AbmModule.forRoot({
      apiKey: '7TVNFGrsxQ4EDsqfQ3b5CxufBGX5gHwW' 
     
    }),
    HttpClientModule,
    FormsModule,
    GridModule,
    BsDropdownModule.forRoot(),
    //TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    UploadModule,
    RippleModule,
    NotificationModule,
    PopupModule,
    PDFModule,
    SnotifyModule,
    DeviceDetectorModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    TooltipModule,
    EditorModule
  ],
  providers: [CoreDataService, CookieService, CommonFunctionService, ExcelService, ExcelServiceExport, AuthGuardService, SharedDataService, EditService,
    AuthHttpInterceptor,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthenticationService,AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }, Title,
    UserEditRouteResolver,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    // { provide: LOCALE_ID, useValue: "en-GB" },
    // { provide: LOCALE_ID, useFactory: () => LocaleHelper.getCurrentLocale() },

    ResetPasswordAuthGuardService,
    RequestPasswordAuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
