export class AssetDetails{
    assetID:number
    barcode:string
    customerID:number
    customerName:string
    locationID:number
    locationName:string
    assetTypeID:number
    assetTypeName:string
   

}

export class LocationDetails{
    locationID:number
    locationName:string 
    customerName:string
    code:string
}

export class AssetImageData {
    public imageDataID: number;
    public imageType: string;
    public associatedID: number;
    public description: string;
    public imageData: Array<number>;
    public uid?: string;
    public extension: string;
    public sequence: number = null;
    public CreationDate: Date;
  }