import {
  Component,
  OnInit,
  ElementRef,
  NgZone,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { SnotifyService } from "ng-snotify";
import { EditCompanyModel } from "./edit-company.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoreDataService } from "../../Service/core-data.service";
import { MapsAPILoader } from "@agm/core";
import { CustomerAdminListingEntity } from "../../AccountManagement/add-customer/add-customer.model";
declare var google: any;
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { SharedDataService } from "../../Service/CommonUtility.service";
import { catchError } from "rxjs/operators";
import { AdminEntity } from "../../Common/shared-model";

import {
  FileRestrictions,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
} from "@progress/kendo-angular-upload";

@Component({
  selector: "app-create-company",
  templateUrl: "./edit-company.component.html",
  styleUrls: ["./edit-company.component.scss"],
})
export class EditCompanyComponent implements OnInit {
  temp: string;
  documentFormat: Array<string> = [".jpg", ".png"];

  CustomerAdminListingEntity: CustomerAdminListingEntity = new CustomerAdminListingEntity();
  selected: EditCompanyModel = new EditCompanyModel();
  list: any;
  id: string;
  LanguageConfig: any;
  ErrorMsgData: any;
  GridConfig: any;
  @ViewChild("form",{static:false}) formvariable: NgForm;
  IsUserCustomer: string;
  latitude = -25.274399;
  longitude = 133.775131;
  zoom = 4;
  locationChoosen = true;
  formGroup: FormGroup;
  IsAdmin: string;
  private editedRowIndex: number;
  AdminEntity: AdminEntity = new AdminEntity();
  @ViewChild("search",{static:false})
  public searchElementRef: ElementRef;
  constructor(
    private notify: SnotifyService,
    private sharedService: SharedDataService,
    private router: Router,

    private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private coredataservice: CoreDataService
  ) {}
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }
  ngOnInit() {
    if (
      this.sharedService.GetLanguageLiteral() != "" &&
      this.sharedService.GetLanguageLiteral() != null
    ) {
      this.sharedService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[11];
          this.ErrorMsgData = res[28];
        }
      });
    }
    this.formGroup = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        //  Validators.minLength(8),
        Validators.maxLength(100),
      ]),
      Address: new FormControl("", [
        Validators.required,
        // Validators.minLength(8),
        Validators.maxLength(150),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        ),
        // Validators.minLength(8),
      ]),
      evaluate_champion: new FormControl("", [
        // Validators.minLength(8),
        Validators.maxLength(50),
      ]),
      comments: new FormControl("", [
        // Validators.required
        // Validators.minLength(8),
        Validators.maxLength(500),
      ]),

      //  active
      // AssetTypeMaster: new FormArray([new FormControl('')])
    });
    this.setCurrentPosition();
      //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          var place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    this.id = this.route.snapshot.paramMap.get("id");
    this.IsUserCustomer = this.sharedService.IsCustomer();

    if (this.id != null && this.id != undefined) {
      this.AdminEntity.Table = "Company";
      this.AdminEntity.AssociatedID = this.id;
    }
    this.IsAdmin = this.sharedService.IsAdmin();

    if (this.id != null && this.id != undefined) {
      this.loadData(this.id);
    }

    this.mapsLoader(this.searchElementRef.nativeElement);
  }
  getApiData() {}
  public onTabchange(e) {
    if (e.index == 1 && this.id != null) {
      this.AdminEntity.Table = "Company";
      this.AdminEntity.AssociatedID = this.id;
    }
  }
  mapsLoader(value) {
    if (value === "autoSearch") {
      value = this.searchElementRef.nativeElement;
    }

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(value);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          var place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  markerDragEnd(event) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getGeoLocation(this.latitude, this.longitude);
  }
  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      let request = { latLng: latlng };

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let result = results[0];
          if (result != null) {
            this.searchElementRef.nativeElement.value =
              result.formatted_address;
            this.selected.address = result.formatted_address;
          }
        }
      });
    }
  }
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  loadData(id: any) {
    this.coredataservice
      .GetCompanyByID(id)
      .first()
      .subscribe(
        (response) => {
          this.selected = response;
          if (
            response.companyBanner != null ||
            response.companyBanner != undefined
          ) {
            this.selected.companyBanner =
              "data:image/png;base64," + response.companyBanner;
          }
          // this.selected.companyBanner = response.companyBanner;
          // this.searchElementRef.nativeElement.value = this.selected.address;
          let geolocation = this.selected["geoLocation"];
          let lat = parseFloat(geolocation.split(",")[0]);
          let long = parseFloat(geolocation.split(",")[1]);
          this.latitude = lat;
          this.longitude = long;
          this.zoom = 5;
        },
        (error) => {
          if (error) {
            this.sharedService.ErrorHandler(catchError);

            // this.toastrService.error("error !")
          }
        }
      );
  }
  CompanyNameBlur(event) {
    if (event.target.value != "" && event.target.value != "") {
      this.coredataservice
        .ValidateCompanyName(this.id, event.target.value)
        .subscribe((res) => {
          if (res != null && res != undefined && res.length > 0) {
            this.selected.name = null;
            this.notify.error(this.ErrorMsgData.companyExist);
          }
        });
    }
  }
  // editCompanyDetails() {
  //     if (this.formGroup.valid) {
  //         this.selected.address = this.searchElementRef.nativeElement.value;
  //         this.selected.companyBanner = this.IsUpadated == true ? this.convertDataURIToBinary(this.selected.companyBanner) : null
  //         this.selected.Active = 1;
  //         this.coredataservice.createCompany(this.selected).first().subscribe(response => {
  //             this.selected = response;
  //             this.searchElementRef.nativeElement.value = this.selected.address;
  //             // this.toastrService.success("Company updated successfully")
  //             this.notify.success(this.ErrorMsgData.companyUpdated);

  //             this.setCurrentPosition();
  //             this.mapsLoader(this.searchElementRef.nativeElement);

  //             this.router.navigate(["/company-management"]);
  //         },
  //             error => {
  //                 if (error) {
  //                     this.sharedService.ErrorHandler(catchError);

  //                 }
  //             });
  //     }

  //     else {
  //         Object.keys(this.formGroup.controls).forEach(field => { // {1}
  //             const control = this.formGroup.get(field); // {2}
  //             // let kd = document.getElementsByName(field)[0]
  //             // if(kd != undefined){
  //             //   console.log(kd)
  //             // }
  //             control.markAsTouched({ onlySelf: true });
  //             control.markAsDirty({ onlySelf: true });       // {3}
  //         });
  //         this.notify.error(this.ErrorMsgData.mandatoryField);
  //     }

  // }

  editCompanyDetails() {
    if (this.formGroup.valid) {
      if (this.id != null && this.id != undefined) {
        this.selected.address = this.searchElementRef.nativeElement.value;
        this.selected.companyBanner =
          this.IsUpadated == true
            ? this.convertDataURIToBinary(this.selected.companyBanner)
            : null;
        this.selected.Active = 1;
        this.coredataservice
          .createCompany(this.selected)
          .first()
          .subscribe(
            (response) => {
              this.selected = response;
             // console.log(this.selected.address);
              this.searchElementRef.nativeElement.value = this.selected.address;
              // this.toastrService.success("Company updated successfully")
              this.notify.success(this.ErrorMsgData.companyUpdated);

              this.setCurrentPosition();
              this.mapsLoader(this.searchElementRef.nativeElement);

              this.router.navigate(["/company-management"]);
            },
            (error) => {
              if (error) {
                this.sharedService.ErrorHandler(catchError);
              }
            }
          );
      } else {
        let geolocation: string =
          this.latitude.toString() + "," + this.longitude.toString();
        this.formGroup.value.Address = this.searchElementRef.nativeElement.value;
        let filterData = {
          CompanyID: null,
          Address: this.formGroup.value.Address,
          Name: this.formGroup.value.name,
          GeoLocation: geolocation,
          Active: 1,
          Email: this.formGroup.value.email,
          EvaluateChampion: this.formGroup.value.evaluate_champion,
          comments: this.formGroup.value.comments,
          companyBanner:
            this.IsUpadated == true
              ? this.convertDataURIToBinary(this.banner)
              : null,
        };

        this.coredataservice
          .createCompany(filterData)
          .first()
          .subscribe(
            (response) => {
              if (response && response != null) {
                // this.toastrService.success("Company has been created");
                this.notify.success(this.ErrorMsgData.companyCreated);
                this.router.navigate(["/company-management"]);
              }
            },
            (catchError) => {
              if (catchError) {
                this.sharedService.ErrorHandler(catchError);
              }
            }
          );
      }
    } else {
      Object.keys(this.formGroup.controls).forEach((field) => {
        // {1}
        const control = this.formGroup.get(field); // {2}
        // let kd = document.getElementsByName(field)[0]
        // if(kd != undefined){
        //   console.log(kd)
        // }
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true }); // {3}
      });
      this.notify.error(this.ErrorMsgData.mandatoryField);
    }
  }


  
  convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  IsUpadated: boolean = false;
  banner: any;
  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file: any) => {
      let File = file;
      if (file.size < 8388608) {
        if (this.documentFormat.includes(file.extension)) {
          const reader = new FileReader();

          reader.onload = (ev) => {
            let image = {
              src: ev.target["result"],
              uid: file.uid,
              extension: file.extension,
              IsUpdated: true,
            };
            this.selected.companyBanner = image.src;
            // this.banner = image.src;
            this.IsUpadated = image.IsUpdated;

            var k = document.getElementsByClassName("k-upload-files");
            if (k.length > 0) {
              k[0].remove();
            }
          };
          reader.readAsDataURL(file.rawFile);
        } else {
          this.notify.error(
            this.ErrorMsgData.onlyFormat + this.documentFormat.toString()
          );
          return;
        }
      } else {
        this.notify.error(this.ErrorMsgData.exceedFileSize);
        var k = document.getElementsByClassName("k-upload-files");
        if (k.length > 0) {
          k[0].remove();
        }
      }
    });
  }
}
