import { SharedDataService } from '../../Service/CommonUtility.service';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserEntity } from './../add-user/add-user.model';


@Injectable()

export class UserEditRouteResolver implements Resolve<UserEntity>{
  constructor(private coreDataService: CoreDataService, private sharedDataService: SharedDataService) { }
  resolve(route: ActivatedRouteSnapshot): Promise<UserEntity> {
    let userID = +route.params['userID'];
    return this.coreDataService.getUserByID(userID).first().toPromise().then(user => {
      if (user != undefined && user != null) {
        this.sharedDataService.user = user.userEntity;
        this.sharedDataService.SharedUserActive = true;
        this.sharedDataService.locationData = user.customerLocation;
        this.sharedDataService.password = "******";
        return user;
      }
      else {
        this.sharedDataService.ErrorHandler(user);
      }
    }
    );
  }
}
