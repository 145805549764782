export class emailConfiguration{
  public emailTemplateID : number;
  public type : string;
  public subject : string;
  public body : string;
  public dataFields : string
  }
  export class EmailAdminListingEntity{
    public createdByName: string;
    public updatedByName: string;
    public creationDate: Date;
    public updationDate: Date;
  }
  // export class DataFieldsEntity{
  //   public AssetID : number;
  //   public EquipmentID : number;
  //   public Location : number;
  // }
  