import { Component, OnInit } from '@angular/core';
import { myActiveProjectsModel } from '../Dashboard/MyactiveProjects'
import { techAuditAnalysisModel } from '../Dashboard/TechAuditAnalysis'
import { projectDurationModel } from '../Dashboard/ProjectDuration'

import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

import { ReviewerActiveProject, ReviewerTechAuditAnalysis, ReviewerProjectDuration } from '../my-teams-dashboard/my-teams-dashboard.model'
import { CoreDataService } from '../Service/core-data.service'
import { RowArgs } from '@progress/kendo-angular-grid';
import { SharedDataService, ReportFilter } from 'src/app/Service/CommonUtility.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-teams-dashboard',
  templateUrl: './my-teams-dashboard.component.html',
  styleUrls: ['./my-teams-dashboard.component.scss']
})
export class MyTeamsDashboardComponent implements OnInit {

  constructor(private titleService: Title, private DataService: CoreDataService, private sharedDataService: SharedDataService, private router: Router) { }

  myActiveProjectsData: any[] = [];
  techAuditData: any[] = [];
  projectDuration: any[] = [];
  reportFilter: ReportFilter = new ReportFilter();
  private DefaultState: State = {
    skip: 0,
    sort: [{ field: 'userAssetID', dir: 'asc' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };


  private state1: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private state2: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private gridDataActiveProjects: GridDataResult;
  private gridDataTechAudit: GridDataResult;
  private gridDataProjectDuration: GridDataResult;

  ReviewerActiveProject: Array<ReviewerActiveProject> = new Array<ReviewerActiveProject>()
  ReviewerTechAuditAnalysis: Array<ReviewerTechAuditAnalysis> = new Array<ReviewerTechAuditAnalysis>()
  ReviewerProjectDuration: Array<ReviewerProjectDuration> = new Array<ReviewerProjectDuration>()
  LanguageConfig: any;
  GridConfig: any

  ngOnInit() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
      // this.titleService.setTitle(this.LanguageConfig.PageTitle);
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[34];
          this.GridConfig = res[2];
        }
      })
    }
    // if(this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null){
    //   let langLiteral = this.sharedDataService.GetLanguageLiteral();
    //   this.GridConfig = GridData[langLiteral];
    // }


    this.state.filter.filters = [{ field: 'locationAuditStatus', operator: 'contains', value: 'INCOMPLETE' }];
    this.state2.filter.filters = [{ field: 'locationAuditStatus', operator: 'contains', value: 'INCOMPLETE' }];
    this.state1.filter.filters = [{ field: 'locationAuditStatus', operator: 'contains', value: 'INCOMPLETE' }];
    this.reviewerActiveProject(undefined, undefined)
  }

  public onTabSelect(e) {
    if (e.index == 1) {
      this.reviewerTechAuditAnalysis(undefined, undefined)
    }
    else if (e.index == 2) {
      this.reviewerProjectDuration(undefined, undefined)
    }
  }


  public reviewerProjectDurationAllData(): ExcelExportData {
    let DataLength: any;
    if (this.state2.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.projectDuration, { sort: [{ field: 'customerName', dir: 'asc' }] }).data,

      };
      DataLength = result.data.length
      return result;
    }
    else {
      this.DefaultState.filter.filters = this.state2.filter.filters;

      const result: ExcelExportData = {

        data: process(this.projectDuration, this.DefaultState).data
      };
      DataLength = result.data.length
      return result;
    }
  }
  reviewerProjectDuration(customerID, locationID) {
    this.DataService.getReviewerProjectDuration(null, null, customerID, locationID).subscribe(res => {
      this.ReviewerProjectDuration = res
      this.ReviewerProjectDuration.forEach(ele => {
        ele['firstCaptureDate'] = new Date(ele['firstCaptureDate'])
        ele['lastCaptureDate'] = new Date(ele['lastCaptureDate'])
      })
      this.ReviewerProjectDuration = this.ReviewerProjectDuration
      this.projectDuration = this.ReviewerProjectDuration


      this.gridDataProjectDuration = process(this.projectDuration, this.state2);
      this.reviewerProjectDurationAllData = this.reviewerProjectDurationAllData.bind(this);

    })

  }
  public reviewerTechAuditAnalysisAllData(): ExcelExportData {
    let DataLength: any;
    if (this.state1.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.techAuditData, { sort: [{ field: 'customerName', dir: 'asc' }] }).data,
      };
      DataLength = result.data.length
      return result;
    }
    else {
      this.DefaultState.filter.filters = this.state1.filter.filters;
      const result: ExcelExportData = {
        data: process(this.techAuditData, this.DefaultState).data
      };
      DataLength = result.data.length
      return result;
    }
  }
  reviewerTechAuditAnalysis(customerID, locationID) {
    this.DataService.getReviewerTechAuditAnalysis(null, null, customerID, locationID).subscribe(res => {

      this.ReviewerTechAuditAnalysis = res
      this.techAuditData = this.ReviewerTechAuditAnalysis

      this.gridDataTechAudit = process(this.techAuditData, this.state1);
      this.reviewerTechAuditAnalysisAllData = this.reviewerTechAuditAnalysisAllData.bind(this);

    })
  }


  Selected(event) {

    this.ApplyFilter(event.selectedRows[0].dataItem.customerID, event.selectedRows[0].dataItem.locationID)
  }

  public reviewerActiveProjectAllData(): ExcelExportData {
    let DataLength: any;
    if (this.state.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.myActiveProjectsData, { sort: [{ field: 'customerName', dir: 'asc' }] }).data,
      };
      DataLength = result.data.length
      return result;
    }
    else {
      this.DefaultState.filter.filters = this.state.filter.filters;
      const result: ExcelExportData = {
        data: process(this.myActiveProjectsData, this.DefaultState).data
      };
      DataLength = result.data.length
      return result;
    }
  }
  reviewerActiveProject(customerID, locationID) {
    this.DataService.getReviewerActiveProjects(null, null, customerID, locationID).subscribe(res => {
      this.ReviewerActiveProject = res;
      this.myActiveProjectsData = this.ReviewerActiveProject

      this.gridDataActiveProjects = process(this.myActiveProjectsData, this.state);
      this.reviewerActiveProjectAllData = this.reviewerActiveProjectAllData.bind(this);

    })
  }


  ApplyFilter(CustomerID, LocationID) {

    this.reportFilter.customerID = CustomerID;
    this.reportFilter.locationID = LocationID;

    this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);
    this.router.navigate(["/assets-listing"]);
  }


  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridDataActiveProjects = process(this.myActiveProjectsData, this.state);
  }

  protected dataStateChangeAudit(state: DataStateChangeEvent): void {
    this.state1 = state;
    this.gridDataTechAudit = process(this.techAuditData, this.state1);
  }
  protected dataStateChangeProject(state: DataStateChangeEvent): void {
    this.state2 = state;
    this.gridDataProjectDuration = process(this.projectDuration, this.state2);
  }

}
