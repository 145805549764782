export class CustomerDetails {
  public customerID: number;
  public companyID: number;
  public name: string;
  public code: string;
  public address: string;
  public addressLine1: string;
  public addressLine2: string;
  public geoLocation: string;
  public suburb: string;
  public stateCD: string;
  public postcode: string;
  public countryCd: number;
  public accountManagerID: number;
  public maintenanceStandard: number;
  public markUp: number;
  public threshold: number;
  public comments: string;
  public active: boolean;
  public logo:any ;

}
export class groupDetails {
  assetGroupID: number;
  formTemplate: string;
  groupName: string;
}
export class CompanyList {
  companyID: number;
  name: string;

}
export class Subscriptionvalue {
  isStoped: boolean;
}
export class BusinessManagerModel {
  userID: number;
  name: string;
}

export class CustomerAdminListingEntity {
  public createdByName: string;
  public updatedByName: string;
  public creationDate: Date;
  public updationDate: Date;
}