import { Component, OnInit, ElementRef, NgZone, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";
import { CoreDataService } from '../../Service/core-data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { SharedDataService } from '../../Service/CommonUtility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AssetJobListingEntity } from '../asset-jobs/asset-jobs.model';

@Component({
  selector: 'app-add-new-asset-job',
  templateUrl: 'add-asset-job.component.html',
  styleUrls: ['add-asset-job.component.scss']
})
export class AddAssetJobComponent implements OnInit {
  CodeData: any;
  AssetJobID:number;
  public LocationID:number;
  public Barcode:string;
  public gpCustomerID:string;
  public equipmentID:string;
  public FilteredLocationDropdown: Array<{ name: string, locationID: number,debtorCode:string,code:string }> = [];
  public LocationDropdown: Array<{ name: string, locationID: number,debtorCode:string,code:string }> = [];
  public FilteredBarcodeDropdown: Array<{ assetID: string, barcode: string }> = [];
  public BarcodeDropdown: Array<{ assetID: string, barcode: string }> = [];
  AssetJobListingEntityObject: AssetJobListingEntity = new AssetJobListingEntity();
  LanguageConfig: any;
  ErrMessagesData: any;
  GridConfig: any;
  public formGroup: FormGroup = new FormGroup({
    assetJobID: new FormControl(),
    jobNumber: new FormControl(),
    workDescription: new FormControl(),
    state: new FormControl(),
    technician: new FormControl(),
    problemType: new FormControl(),
    resolution: new FormControl(),
    equipmentID: new FormControl(),
    completionDate: new FormControl(),
    callType: new FormControl(),
    status: new FormControl(),
    addressCode: new FormControl(null),
    gpCustomerID: new FormControl(),
    priority: new FormControl(0, [Validators.min(0), Validators.max(100)]),
    creationDate: new FormControl(),
    purchaseorder: new FormControl(),
    requestedBy: new FormControl(),
    spend_ExGST: new FormControl(),
    barcode: new FormControl(),
    customerName: new FormControl(),
  });
  constructor(
    private notify: SnotifyService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private DataService: CoreDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private sharedDataService: SharedDataService,
    private toastrService: ToastrService
  ) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
    this.AssetJobID = parseInt(this.activatedRoute.snapshot.paramMap.get('id')==null?'0':this.activatedRoute.snapshot.paramMap.get('id')); 
  }

  ngOnInit() { 
    this.formGroup= new FormGroup({
      assetJobID: new FormControl(0),
      jobNumber: new FormControl(),
      workDescription: new FormControl(),
      state: new FormControl(),
      technician: new FormControl(),
      problemType: new FormControl(),
      resolution: new FormControl(),
      equipmentID: new FormControl(),
      completionDate: new FormControl(),  
      callType: new FormControl(),
      status: new FormControl(),
      addressCode: new FormControl(null),
      gpCustomerID: new FormControl(),
      priority: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      creationDate: new FormControl(new Date()),
      purchaseorder: new FormControl(),
      requestedBy: new FormControl(),
      spend_ExGST: new FormControl(),
      barcode: new FormControl(),
      customerName: new FormControl(),
    });
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
    }
    this.sharedDataService.languageConfig$.subscribe(res => {
      if (res != undefined) {
        this.LanguageConfig = res[64];
        this.GridConfig = res[2];
        this.ErrMessagesData = res[28];
        
      }
    })
   this.getLocationbyName();

  }

getAssetJobByID(){
  if(this.AssetJobID!=0){
  this.DataService.getAssetJobbyID(this.AssetJobID).subscribe(res => {
    this.AssetJobListingEntityObject = res[0]
    
      this.LocationDropdown.forEach(row=>{
      if(row.code ==this.AssetJobListingEntityObject.addressCode && row.debtorCode ==this.AssetJobListingEntityObject.gpCustomerID){
        this.AssetJobListingEntityObject.addressCode=row.locationID;
        this.LocationID=row.locationID;
      }
     })
    
    this.getEquipmentByLocationId();

    this.AssetJobListingEntityObject.completionDate=  new Date(this.AssetJobListingEntityObject.completionDate)
    this.formGroup.patchValue(this.AssetJobListingEntityObject)
    });
  }
}
onSubmit(){
  this.AssetJobListingEntityObject=this.formGroup.value

  this.LocationDropdown.forEach(row=>{
    if(row.locationID ==this.AssetJobListingEntityObject.addressCode && row.debtorCode ==this.AssetJobListingEntityObject.gpCustomerID){
      this.AssetJobListingEntityObject.addressCode=row.code;
    }
   })

  this.DataService.CheckAssetExists(this.AssetJobListingEntityObject.barcode,this.AssetJobListingEntityObject.addressCode,this.AssetJobListingEntityObject.gpCustomerID).subscribe( (response) => {     
    if (response == 1 ) {
   this.DataService.AddUpdateAssetJob(this.AssetJobListingEntityObject).subscribe(res=>{ 
    this.router.navigate(['viewAssetsJobs']);
       this.notify.success(this.ErrMessagesData.SavedSuccesfully);
     },
     (catchError) => {
       this.notify.warning(this.ErrMessagesData.SomethingWentWrong);   
   })
  }
  else{
    this.notify.error("Asset with this Address Code,GP Customer ID,Barcode does not exists")
  }
})
 }
 
 onTabchange($event){
  
 }

 locationFilterChange(value) {
  this.FilteredLocationDropdown = [];
  if (value.length >= 3) {
    this.FilteredLocationDropdown = this.LocationDropdown.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  } else {
    this.FilteredLocationDropdown = this.LocationDropdown;
  }
}
getLocationbyName() {
  this.DataService.getUserLocationByCustomerDropdown('', '').subscribe((res) => {
    if (res != undefined && res != null) {
      this.LocationDropdown = res;
      this.FilteredLocationDropdown = this.LocationDropdown
      this.getAssetJobByID();
    }
  });
}

getEquipmentByLocationId() {
  this.BarcodeDropdown=[]
  this.FilteredBarcodeDropdown=[]
  this.Barcode=''
 
  this.DataService.getEquipmentByLocationId(this.LocationID).subscribe((res) => {
    if (res != undefined && res != null) {
      this.BarcodeDropdown = res;
      this.FilteredBarcodeDropdown = this.BarcodeDropdown
    }
  });
}
barcodeFilterChange(value) {
  this.FilteredBarcodeDropdown = [];
  if (value.length >= 3) {
    this.FilteredBarcodeDropdown = this.BarcodeDropdown.filter((s) => s.barcode.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  } else {
    this.FilteredBarcodeDropdown = this.BarcodeDropdown;
  }
}

LocationValueChange(locationID){

  var location=this.LocationDropdown.find((obj) => obj.locationID ==this.LocationID)
  
  this.gpCustomerID=location['debtorCode']
  this.getEquipmentByLocationId();
}

BarcodeValueChange(val){
  if(this.equipmentID=='' || this.equipmentID== null){
  this.equipmentID=val;
  }
}

}