import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { CoreDataService } from '../../Service/core-data.service';
import { ToastrService } from 'ngx-toastr';
import { filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { process, State } from '@progress/kendo-data-query';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { SharedDataService } from "../../Service/CommonUtility.service";
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
// import { PageData } from '../../Languages/customer-management/customer-managementConfig';

import { ExcelExportData } from '@progress/kendo-angular-excel-export';
const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};
@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss']
})
export class CustomerManagementComponent implements OnInit {
  public checked: boolean = false;
  id: number;
  editItemData;
  sampledata = [];
  public view: boolean;
  list: any = [];
  temp: any = {};
  public filter: CompositeFilterDescriptor;
  userId: number;
  value: string;
  public gridfilter: any[] = filterBy(this.list, this.filter);
  LanguageConfig: any;
  GridConfig: any
  gridData: GridDataResult;
  key;
  filteredQuotelist: any = [];
  public state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  IsUserCustomer: string;
  IsAuditor: boolean;
  IsSysAdmin: boolean;
  public opened: boolean = true;
  IsBusinessManagement : boolean
  IsCustomerManagement : boolean

  public close(status) {
    this.opened = false;
  }
  public open() {
    this.opened = true;
  }
  constructor(private activatedRoute: ActivatedRoute, private sharedService: SharedDataService, private titleService: Title, private coredataservice: CoreDataService, private toastrService: ToastrService,
    private route: ActivatedRoute, private router: Router) {

    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);

    if(this.activatedRoute.snapshot.data['IsBusinessManagement'])
    {
      this.IsBusinessManagement = this.activatedRoute.snapshot.data['IsBusinessManagement']
    }
    else{
      this.IsCustomerManagement = this.activatedRoute.snapshot.data['IsCustomerManagement']
    }
    


  }

  isRowSelected(e: any): void {
    this.temp = e;
    // this.router.navigate(["/quote-detail"]);
  }

  ngOnInit() {

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
      // if(this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null){
      //   let langLiteral = this.sharedService.GetLanguageLiteral();
      //   this.GridConfig = GridData[langLiteral];
      // }

      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.GridConfig = res[2];
          this.LanguageConfig = res[6];
        }
      })
    }

    this.value = this.route.snapshot.paramMap.get('name');
    if (this.value != undefined) {
      this.state.filter.filters = [{ field: 'companyName', operator: 'contains', value: this.value.toString() }];

      //  this.dataStateChange(this.state);
    }

    this.IsUserCustomer = this.sharedService.IsCustomer();
    this.IsAuditor = this.sharedService.IsAuditorOrReviewer()

    this.IsSysAdmin = this.sharedService.IsSystemAdmin();

    this.loadData();
  }
  MenuOnSelect(event) {

    if(this.IsBusinessManagement){
      // var baseUrl = window.location.origin + '/#/add-business-unit/' + event.dataItem.customerID;
      var baseUrl = window.location.origin + '/add-business-unit/' + event.dataItem.customerID;
    }
    else
    {
      // var baseUrl = window.location.origin + '/#/add-customer/' + event.dataItem.customerID;
      var baseUrl = window.location.origin + '/add-customer/' + event.dataItem.customerID;
    }
    if (event.item == "Open") {
      // this.getQuoteDetail(event.dataItem.QuoteID);
      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }
  editDetails(event) {

    this.id = event.customerID;
    if(this.IsBusinessManagement){
      this.router.navigate(["/add-business-unit/" + this.id]);
    }
    else{
      this.router.navigate(["/add-customer/" + this.id]);
    }
  }

  loadData() {

    console.log(this.activatedRoute.snapshot.data['IsBusinessUnit'])
    
    this.coredataservice.getCustomerList().first().subscribe(response => {

      if(this.activatedRoute.snapshot.data['IsBusinessUnit'])
      {
        this.list = response;
        this.list = this.list.filter(e=>{return e.isBusinessUnit == true})
      }
      else{
        this.list = response;
        this.list = this.list.filter(e=>{return e.isBusinessUnit == false})
      }
      
      this.gridData = process(this.list, this.state);
      this.allData = this.allData.bind(this);

    },
      catchError => {
        if (catchError) {
          this.sharedService.ErrorHandler(catchError);
        }
      });

  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, { sort: [{ field: 'name', dir: 'asc' }] }).data,

    };

    return result;
  }

  public dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.list, this.state);

  }

  public switchChange(checked: boolean): void {
    const root = this.filter || { logic: "and", filters: [] };
    const [filter] = flatten(root).filter(x => x.field === "active");
    if (!filter) {
      root.filters.push({
        field: "active",
        operator: "eq",
        value: checked
      });
    }
    else {
      filter.value = checked;
    }
    this.checked = checked;
    this.filterChange(root);
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: "active", dir: "desc" }]
    };
  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: "Active", dir: "desc" }]

    };
    this.gridfilter = filterBy(this.list, filter);
    this.gridData = process(this.gridfilter, this.state);
  }
  GetFormattedDate(todayTime) {
    var dt = new Date(todayTime);
    var month = dt.getMonth();
    var day = dt.getDate();
    var year = dt.getFullYear();
    return moment(new Date(year, month, day)).toDate();

  }
}
