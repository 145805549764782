import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { SharedDataService } from '../../app/Service/CommonUtility.service';
import { OrgSettingEntity } from '../SystemAdmin/organisation-setting/organisation-setting.model';
declare var window: any;

@Pipe({
    name: 'CustomCurrency',
})
export class CustomCurrency implements PipeTransform {
    OrgSettings: OrgSettingEntity;

    constructor(private SharedDataService: SharedDataService) {
        this.SharedDataService.GetOrgSettings().then((res) => {
            this.OrgSettings = res;
        });
    }

    transform(
        value: number,
        currencyCode: string = this.OrgSettings === undefined ? '' : this.OrgSettings.Currency,
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '2.2-2',
        locale: string = this.OrgSettings === undefined ? "en" : (this.OrgSettings.CultureInfo == 'ja-JP' ? 'en-JP' : this.OrgSettings.CultureInfo),
    ): string | null {
        return formatCurrency(
            value,
            locale,
            getCurrencySymbol(currencyCode, 'wide'),
            currencyCode,
            digitsInfo,
        );
    }
}