export class MappedConfigModel {
    public ExcelColumn :string;
    public SystemColumn :string;
  }
  export class ValidationErrorResponse{
    public isValid :boolean;
    public row :boolean;
    public column  :boolean;
    public type :boolean;
    public value :boolean;

  }