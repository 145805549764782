export class DashboardKPIListingEntity{
    public inUseAsset: number;
    public totalAsset: number;
    public total5YearBudget: number;
    public lessYear1Asset: number;
    public totalR22Refrigerant: number;
    public totalExceptR22Refrigerant: number; 
    public totalRefrigerantQty: number;
    public totalR22RefrigerantPer: number;
    public totalExceptR22RefrigerantPer: number; 
    public refigerantName: string;
  }
  

  export class RemainingLifeListingEntity{
    public ageGroup: Array<string> = new Array<string>() ;
    public remainingLife: Array<number> = new Array<number>();
    public actualAge: Array<number> = new Array<number>();
  }
 
  export class ConditionRatingListingEntity{
    public conditionRatingName: Array<string> = new Array<string>() ;
    public count: Array<number> = new Array<number>();
  }
  export class AssetGroupKwhListingEntity{
    public groupName:string;
    public assetGroupID: number;
    public electricalInputKW: number;
  }
  export class BudgetListingEntity{
    public budgetYear: Array<string> = new Array<string>() ;
    public budget: Array<number> = new Array<number>();

  }
  export class AgeVsConditionEntity{
    public conditionRatingName:string;
    public ageGroup: string;
    public conditionRatingCd: number;
    public count:number;
  }
  export class RefrigerantListingEntity{
    public Data: any[];
   
  }
  export class ReviewerActiveProject
  {
        // customerID : number
        // locationID : number
        // locationName : number
        // baseLineQuantity : number
        // count : number
         customerID : number
         locationID : number
         locationName : string
         customerName : string
         baseLineQuantity :  number
         count : number
         auditDuration : number
         percentage :number
         status : string
         locationAuditStatus : string
  }
  
  export class AdminDashboardKPI {
    capacityZero : number
    installDateYesterday : number
    repYr1AndNewAsset : number
    replacementCostBudgetZero : number
    activeTech: number
    assetsAdded : number
    poorConditionAgeLessThnFive : number
    exceConditionRemLifeLessThnOne : number
    decommissionedAssets:number
    assetsUpdated:number
  }
  export class ReviewerTechAuditAnalysis {
        locationID : number
        userName : string
        locationName : string
        assetsCaptured : number
        totalAuditDuration : any
        avgAuditDuration : number
        locationAuditStatus : string
  }
  export class ReviewerActiveTech {
    locationName : string
    fullName: string
}
  export class ReviewerProjectDuration { 
     
       locationID : number
       customerID : number
       customerName : string
       locationName : string
       firstCaptureDate : any
       lastCaptureDate : any
       duration : number
       locationAuditStatus : string
  }
  