import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { ChangePasswordModel } from '../change-password/change-password.model';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder, NgForm } from "@angular/forms";
import { SnotifyService } from 'ng-snotify';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { AuthenticationService } from '../../Service/authentication.service';
import { CoreDataService } from '../../Service/core-data.service'

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  UserData: any;
  ChangePasswordModel: ChangePasswordModel = new ChangePasswordModel();
  formGroup: FormGroup;
  match: boolean = true;
  DateTimeformat: any
  ErrMessagesData: any;
  LanguageConfig: any;
  GridConfig: any
  ErrorMsgData: any;

  constructor(private DataService: CoreDataService, private activatedRoute: ActivatedRoute, private titleService: Title, private notify: SnotifyService, private sharedService: SharedDataService, private router: Router, private authservice: AuthenticationService,
  ) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {

    // if(this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null){
    //   let langLiteral = this.sharedService.GetLanguageLiteral();
    //   this.LanguageConfig = new LanguageConfig();
    //   this.LanguageConfig.Labels = PageData[langLiteral];
    //   this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
    //   this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
    //   this.ErrMessagesData = ErrMessagesData[langLiteral];
    // }
    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[39];
          this.ErrorMsgData = res[28]

        }
      })
    }
    this.formGroup = new FormGroup({
      oldPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30)
      ]),
    });

    // this.DateTimeformat = this.sharedService.dateTimeFormat();
    this.sharedService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
      }
    })


    this.BindUserData();



  }
  ConfirmPass() {
    if (this.formGroup.value['newPassword'] == this.formGroup.value['confirmPassword']) {
      this.match = true;
      document.getElementById('confirmPassword').style.borderColor = "#00a65a";
      return true
    }
    else {
      this.match = false;
      document.getElementById('confirmPassword').style.borderColor = "#dc3545";
      return false
    }
  }

  Reset() {
    this.formGroup.reset()
  }
  changePassword() {
    if (this.formGroup.valid && this.ConfirmPass()) {
      console.log("pass,my acc", this.sharedService.password);
      this.DataService.changePassword(this.ChangePasswordModel).subscribe(res => {
        if (res != undefined && res.message == "Success") {


          this.authservice.loggedIn$.next(null);
          var UserData = this.sharedService.parseJwt(localStorage.getItem('token'));
          var obj = { email: UserData.EmailID, password: this.ChangePasswordModel.confirmPassword };

          this.authservice.getLogin(obj).subscribe((data): any => {
            if (data != undefined) {
              this.Reset()
              localStorage.setItem('token', data['access_token'])
              this.sharedService.ShowChangePass$.next('false');
              this.notify.success("Password changed successfully")
              this.BindUserData();
            }
          })

        }
        else if (res != undefined && res.message == "InvalidOldPassword") {
          this.notify.error("Please enter correct old password")
        }
      })
    }
    else {
      Object.keys(this.formGroup.controls).forEach(field => { // {1}
        const control = this.formGroup.get(field); // {2}
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });
      this.ConfirmPass()
      this.notify.error("Some error occured")
    }
  }
  BindUserData() {
    var token = window.localStorage.getItem('token');
    if (token != null && token != undefined && token != '') {
      this.UserData = this.sharedService.parseJwt(token);
      // console.log(this.UserData)
    }
  }

}