export class DashboardKPIListingEntity{
    public inUseAsset: number;
    public totalAsset: number;
    public total5YearBudget: number;
    public lessYear1Asset: number;
    public totalR22Refrigerant: number;
    public totalExceptR22Refrigerant: number; 
    public totalRefrigerantQty: number;
    public totalR22RefrigerantPer: number;
    public totalExceptR22RefrigerantPer: number; 
    public refigerantName: string;
    public ActualAge1Year:number;
  }
  

  export class RemainingLifeListingEntity{
    public ageGroup: Array<string> = new Array<string>() ;
    public remainingLife: Array<number> = new Array<number>();
    public actualAge: Array<number> = new Array<number>();
  }
 
  export class ConditionRatingListingEntity{
    public conditionRatingName: Array<string> = new Array<string>() ;
    public count: Array<number> = new Array<number>();
  }
  export class AssetGroupKwhListingEntity{
    public groupName:string;
    public assetGroupID: number;
    public electricalInputKW: number;
  }
  export class BudgetListingEntity{
    public budgetYear: Array<string> = new Array<string>() ;
    public budget: Array<number> = new Array<number>();

  }
  export class AgeVsConditionEntity{
    public conditionRatingName:string;
    public code:string;
    public ageGroup: string;
    public conditionRatingCd: number;
    public count:number;
  }
  export class HighestReplacementCost{
    public locationID :number
    public customerID: number
    public assetTypeID : number
    public locationName : string
    public assetTypeName: string
    public userAssetID  : string
    public capacity:number
    public unitName  : string
    public replacementYear : string
    public replacementBudget :number    
    public conditionRating : string
    public totalSpend:number
  }
  
  export class HighestTotalSpendAssets{
    public locationID :number
    public customerID: number
    public assetTypeID : number
    public locationName : string
    public assetTypeName: string
    public userAssetID  : string
    public capacity:number
    public unitName  : string
    public replacementYear : string
    public replacementBudget :number    
    public conditionRating : string
    public totalSpend:number
  }
  export class RefrigerantListingEntity{
    public Data: any[];
   
  }
