import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'validDateCheckPipe',
})
export class validDateCheckPipe implements PipeTransform {
    
    transform(val: string) {
        if(val != undefined){
            if (new Date(val).getFullYear() == 1) {
                return ''
            }
            else {
                return val
            }
        }
    }
}