export class AssettypeEntity
{
public assetTypeID: number;
public assetTypeName: string;
public code: string;
public assetGroupID: number;
public expectedLife: number;
public image: string;
public supplyCosts: number;
public installMultiplier: number;
public installCost: number;
public active: boolean;
public hasSubAssets:boolean;
public comments:string;
public uom: number;
public fixedCost:number;
public pricingCapacity:Array<priceCapacityBased>=[];
public manufacturerModel:Array<makeModel>;
public  AssetTypeRMModel:Array<AssetTypeRMModel>;
public supplyCostsRM: number;
public installMultiplierRM: number;
public installCostRM: number;
public fixedCostRM:number;
public baseRate:number;
public baseRateRM:number;

}

export class AssetTypeAdminListingEntity{
    public createdByName: string;
    public updatedByName: string;
    public creationDate: Date;
    public updationDate: Date;
}

export class FaultGroupListingEntity{
    public faultGroupID: number;
    public faultID: number;
    public faultName: string;
    public groupName: string;
}

export class FaultGroupListingEntityModel{
    public  AssetTypeID :number
    public  FaultID : Array<number> = new Array<number>();
}

export class FixListingEntityModel{
    public  AssetTypeID :number
    public  FixID : Array<number> = new Array<number>();
}

export class FixListingEntity{

    public fixID: number;
    public fixName: string;

}

export class priceCapacityBased{
public from : number;
public to : number;
public cost : number;
public baseRate:number;
public pricingCapacityID?: number
public pricingSizeID?: number
public size : any;
}

export class makeModel{
public mfgCd : number;
public model : string;
public manufacturerModelID:number;
public uOMCd:number;
public electricalInputKW:number;
public capacity:number;
}
export class AssetTypeRMModel{
    public  assetTypeRMID: number=0;
    public  assetTypeID : number;
    public  baseRate: number;
    public  multiplier: number;
    public  baseCapacity: number;
    public  rmItemCD : any;
    public  description:string;
    public  frequency : number;
    public  createdBy : number;
    public  creationDate:Date;
    public  updatedBy : number;
    public  updationDate:Date;
    public cost:number;
    public supplyCosts :number;
    public installMultiplier :number
   public installCost:number;
   public fixedCost :number;
   public priceCapacityBasedRM:Array<PriceCapacityBasedRM> = new Array<PriceCapacityBasedRM>();
    }

    export class PriceCapacityBasedRM {
        public rMPricingCapacityID:number
        public assetTypeRMID:number
        public from : number;
        public to : number;
        public cost : number;
        public baseRate:number;      
        public pricingSizeID?: number
        public size : any;
        public sizeCD:number
        }