export class BookingEntity {
    
   public bookingID? : number = 0;
   public assetID? : number
   public status? : number = 0
   public reservedBy : number
   public receivedBy : number
   public startDateTime : any=Date.now() ;
   public endDateTime : any=Date.now() ;
   public reservationNotes : string
   public checkedOutBy : number
   public checkedOutBySelectedList : any
   // public checkedOutBySelectedName : any
   public checkedOutBySelectedEmail : any
   public checkOutDate : any=Date.now();
   public returnByDate : any=Date.now();
   public checkOutNotes : string
   public signature : string

   //NEW FIELDS
   public checkInBy : number
   public checkInNotes : string
   public checkInDate : any
   public isPermanent : boolean = false
   public isPermanentCheckout:boolean =false

}