import { SnotifyService } from 'ng-snotify';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { projectProgressReport, CommonFilterEntity, PortfolioReportEntity, AssetReportFieldTemplateModel, QueueReportEntity, SchedulerReportEntity, ScheduleList } from '../Inventory Report/reports.model';
import { CoreDataService } from '../../Service/core-data.service';
import { aggregateBy } from '@progress/kendo-data-query';
import { CommonFilterComponent } from '../../Common/CommonFilter/commonFilter.component';
import { SharedDataService, ReportFilter } from '../../Service/CommonUtility.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { from, BehaviorSubject } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelService } from '../../Service/excel.service';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { JsonPipe } from '@angular/common';

import { CustomReport, reportFilterTemplate } from 'src/app/Common/CommonFilter/commonFilter.model';
import { ReportOptionsComponent } from 'src/app/Common/report-options/report-options.component';




@Component({
  selector: 'New-Report',
  templateUrl: 'reports.component.html',

  styleUrls: ['reports.component.scss'],


})
export class InventoryReportsComponent implements OnInit {


  dialogOpened = false;
  template_id: any;
  opened: boolean = false;
  show = false;
  GridConfig: any;
  commonFilterEntity: CommonFilterEntity = new CommonFilterEntity();
  SchedulerReportEntity: SchedulerReportEntity = new SchedulerReportEntity();

  AssetReportFieldTemplate: AssetReportFieldTemplateModel = new AssetReportFieldTemplateModel();

  public aggregates: any[] = [{ field: 'baselineValue', aggregate: 'sum' }, { field: 'actualValue', aggregate: 'sum' }, { field: 'subAssetBaselineValue', aggregate: 'sum' }, { field: 'subAssetActualvalue', aggregate: 'sum' }];
  GDActual: number;
  GDTender: number;
  GDActualTender: number;

  progressRepootModel: Array<projectProgressReport> = new Array<projectProgressReport>();
  QueueReportEntity: Array<QueueReportEntity> = new Array<QueueReportEntity>();
  ScheduleList: Array<ScheduleList> = new Array<ScheduleList>();

  public total: any = aggregateBy(this.progressRepootModel, this.aggregates);
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx'
  public group: any[] = [{
    field: 'locationName',
    aggregates: this.aggregates
  }];
  customReport: CustomReport = new CustomReport();
  customReportList: Array<CustomReport> = [];

  public openedFieldChooser: boolean = false;
  public listItems: Array<string> = ["PDF", "DOC","XLS"];
  PortfoliocheckMode: string = "PDF";
  ErrorMsgData: any;
  Customerdata: any;
  LocationData: any;
  templateData: any;
  subtemplateData: any;
  sub: any;
  data: any;
  assetFieldsList: any[];
  assetFieldsListTemp: any[];
  AssetFields: any[];
  public FilteredLocationDropdown: Array<{ name: string; locationID: number }> =
    [];

  subassetFieldsList: any[];
  subassetFieldsListTemp: any[];
  SubAssetFields: any[];
  public CustomerDropdown: Array<{
    customerName: string;
    customerID: number;
    isBusinessUnit: boolean;
  }> = [];
  public LocationDropdown: Array<{ name: string; locationID: number }> = [];

  portfolioReportEntity: PortfolioReportEntity = new PortfolioReportEntity();
  EnableFieldButton: boolean = true;
  IsCustomerChange: boolean = false;
  IsLocationChange: boolean = false;
  IsAppliedField: boolean = false;
  ReportType: string;
  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public state1: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  IsOpenScheduler = false;
  public Schedulerange = { start: new Date(), end: null };
  Duration: string;
  

  public WeeklyListItems: Array<{ text: string, value: number }> = [
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 }
  ];
  public MonthlyListItems: Array<{ text: string, value: number }> = [
    { text: "Janurary", value: 1 },
    { text: "Feburary", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ];

  public WeekListItems: Array<{ text: string, value: number }> = [
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thurday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
    { text: "Sunday", value: 7 }
  ];

  public DaysListItems: Array<{ text: string, value: any }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
    { text: "13", value: 13 },
    { text: "14", value: 14 },
    { text: "15", value: 15 },
    { text: "16", value: 16 },
    { text: "17", value: 17 },
    { text: "18", value: 18 },
    { text: "19", value: 19 },
    { text: "20", value: 20 },
    { text: "21", value: 21 },
    { text: "22", value: 22 },
    { text: "23", value: 23 },
    { text: "24", value: 24 },
    { text: "25", value: 25 },
    { text: "26", value: 26 },
    { text: "27", value: 27 },
    { text: "28", value: 28 },
    { text: "29", value: 29 },
    { text: "30", value: 30 },
    { text: "31", value: 31 },
    { text: "Last", value: 't' },
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: false,
    enableCheckAll: false,
    maxHeight: 180

  };
  
  
  public ReportTree1: any[] = [
   
    { reportType: "NTDR", text: "Next Test and Tag Due Report" },
    { reportType: "NSDR", text: "Next Service Due Report" },
    { reportType: "PPR", text: "Project Progress Report" },



  ];
  public expandedKeys: any[] = [];

  public min: Date = new Date();
  public max: Date = new Date();
  ScheduleDuration: string;
  templateTitle: any;
  templateName: any;
  SubtemplateName: any;
  AllowAssetGroup: boolean = false
  AllowAssetClass: boolean = false
  LanguageConfig: any
  LanguageConfig1: any
  LanguageInitial: any
  assetfieldCofig: any;
  subassetfieldCofig: any;
  subscription: any;
  //public CommonFilterComponent: CommonFilterComponent;
  @ViewChild(CommonFilterComponent,{static:false}) CommonFilterComponent: CommonFilterComponent
  @ViewChild(ReportOptionsComponent,{static:false}) ReportOptionsComponent: ReportOptionsComponent;

  reportFilter: ReportFilter = new ReportFilter();

  public gridData: GridDataResult;
  public gridScheduleData: GridDataResult;
  LocationIDModel: any;
  CustomerIDModel: any;
  SelectedReport: string;
  ReportParameter: boolean = true;
  MoreFilter: boolean = true;
  ChooseColumn: boolean = true;
  Schedule: boolean = true;
  Format: boolean = true;
  ReportTypeButton: string = "Download";
  display: boolean = false;
  customReportButton: boolean = true;
  templateFilterDetails: any;
  LanguageConfigoption: string;
  ReportOption: boolean = true;
  isExpanded: boolean = true;
  IsImageOptionsDisabled: boolean = false;
  isGenerateReportEnabled: boolean=true;
  isExpandedtree: boolean;
  success: boolean=false;
  fieldTemplate: any;



  constructor(private titleService: Title, private spinner: SpinnerVisibilityService, private activatedRoute: ActivatedRoute, private notify: SnotifyService, private coreDataService: CoreDataService, private sharedData: SharedDataService, private excelService: ExcelService) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);

  }
  ngOnInit() {
    

    this.getCustomerbyName("");
    this.subscription = this.sharedData.getMessage().subscribe(message => {
    
    }

    )

    this.LanguageInitial = this.sharedData.GetLanguageLiteral()

    if (this.sharedData.GetLanguageLiteral() != '' && this.sharedData.GetLanguageLiteral() != null) {

      this.sharedData.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[27];
          this.LanguageConfig1 = res[29];
          this.assetfieldCofig = res[30];
          this.subassetfieldCofig = res[53];
          this.ErrorMsgData = res[28];
          this.GridConfig = res[2];
          this.LanguageConfigoption = res[5];
          if(this.LanguageConfig!=undefined){
            if(this.LanguageConfig!=undefined){
           
           this.ReportTree1.forEach(row=>{
           if(row.reportType=="NTDR"){
            row.text=this.LanguageConfig.NextTestandTagDueReport
           }
           else if(row.reportType=="NSDR"){
             row.text=this.LanguageConfig.NextServiceDueReport
          
           }
           else if(row.reportType=="PPR"){
             row.text=this.LanguageConfig.projectProgress
           }
          
         })  
         }
        
      }
    }
        })
    }
    
      this.sharedData
      .getMessage()
      .subscribe((message) => {
      let obj: any = this.sharedData.ReportFilterSharedData;

      let cust = this.sharedData.ReportFilterSharedData['customerID']
      let loc = this.sharedData.ReportFilterSharedData['locationID']

    })

   



  }

  spit(data) {
    var answer = data.split(',').filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });
    return answer
  }



  onExcelExport(e: any): void {
    const rows = e.workbook.sheets[0].rows;

    // align multi header
    console.log(rows)
    // rows[0].cells[2].backgound = '#ffffff';

    // set alternating row color
    let altIdx = 0;
    rows.forEach((row) => {
      if (row.type === 'group-footer' || row.type === 'footer') {
        console.log(row)

        row.cells.forEach((cell) => {
          cell.textAlign = 'right';
          cell.background = '#a5a5a5'
        });

      }
      if (row.type === 'group-header') {
        row.cells.forEach((cell) => {
          cell.background = '#ffffff';
        });
      }
      if (row.type === 'header') {
        row.cells.forEach((cell) => {
          cell.background = '#a5a5a5';
          cell.color = '#333'
        });
      }
    });
  }


  onTabchangeFields(event) {

    if (event.index == 1) {
      // this.GetReportQueue();
    }
    else if (event.index == 2) {
      // this.GetScheduleList();
      // this.GetSubAssetFields();
    }
  }

  

  protected dataStateChange(state: DataStateChangeEvent): void {

    this.state = state;
    this.gridData = process(this.QueueReportEntity, this.state);
  }

  protected dataStateChangeSchedule(state: DataStateChangeEvent): void {

    this.state1 = state;
    this.gridScheduleData = process(this.ScheduleList, this.state1);
  }

  public PPRclose(status) {
   this.CustomerIDModel=null
   this.LocationIDModel=null
    this.opened = false;
  }

  public PPDopen() {
    this.opened = true;
  }

  public TemplateClose(status) {
    this.display = false;
    
  }

  public TemplateOpen() {
    this.display = true;
  }

  
  

 

  
  
  getProgressRepotData(CustomerID, LocationID,format,ReportType,LanguageConfig) {
    this.coreDataService.GetProgressRrepot(CustomerID, LocationID,format,ReportType,LanguageConfig) .subscribe((res) => {
      if (res != undefined && res != null) {
        this.saveAsFile(this._base64ToArrayBuffer(res.fileData),res.fileName,res.extention)
        this.CommonFilterComponent.resetModels();
      } else {
        this.notify.warning(this.ErrorMsgData.noDataAvailable);
      }
    });

   
  }


  getAccountManagerNameByLocation(locationName: any) {
    let accountManager: any = null

    // this.progressRepootModel.find((ele) => { return ele.locationName.toUpperCase() === locationName.toUpperCase() })

    // console.log(locationName)
    // console.log(this.progressRepootModel[0].accountManager)

    this.progressRepootModel.forEach(ele => {
      if (ele.locationName.toUpperCase() === locationName.toUpperCase()) {
        accountManager = ele.accountManager
      }
    })
    // accountManager = this.progressRepootModel[0].accountManager

    console.log(accountManager)
    return accountManager
  }

  getAuditStatusNameByLocation(locationName: any) {
    let auditStatus: any = null

    // this.progressRepootModel.find((ele) => { return ele.locationName.toUpperCase() === locationName.toUpperCase() })
    // // console.log(locationName)
    // // console.log(this.progressRepootModel[0].accountManager)
    // auditStatus = this.progressRepootModel[0].auditStatus
    // return auditStatus

    this.progressRepootModel.forEach(ele => {
      if (ele.locationName.toUpperCase() === locationName.toUpperCase()) {
        auditStatus = ele.auditStatus
      }
    })

    console.log(auditStatus)
    return auditStatus
  }


  

  





  sendMessage(): void {
    // send message to subscribers via observable subject
    this.sharedData.sendMessage('GenerateReport');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });

    console.log('excelData' + data);
    FileSaver.saveAs(data, fileName + new Date().getTime() + this.EXCEL_EXTENSION);
  }

  private saveAsFile(buffer: any, fileName: string, extension: string): void {
    var format= extension.split('.')[1];
    const data: Blob = new Blob([buffer], {
      type: "application/" + format + ";"
    });
    FileSaver.saveAs(data, fileName+extension);
  }
  

  PtojectProgressData(ReportType) {
    this.sendMessage();
    this.commonFilterEntity.customerID = this.CustomerIDModel
    this.commonFilterEntity.locationID = this.LocationIDModel
    this.commonFilterEntity.locationID= this.commonFilterEntity.locationID==null?0:this.commonFilterEntity.locationID;
    this.commonFilterEntity.locationID= this.commonFilterEntity.locationID==undefined?0:this.commonFilterEntity.locationID;
    this.portfolioReportEntity.reportType = ReportType;
    if (this.commonFilterEntity != null && this.commonFilterEntity != undefined) {
      if (this.commonFilterEntity.customerID != undefined && this.commonFilterEntity.customerID != 0) {
        this.progressRepootModel = [];
        this.getProgressRepotData(this.commonFilterEntity.customerID, this.commonFilterEntity.locationID,this.PortfoliocheckMode,ReportType,this.LanguageInitial);
      } else {
        this.notify.warning(this.ErrorMsgData.selectCustomer);
      }
    } else {
      this.notify.warning(this.ErrorMsgData.selectCustomer);
    }
  }
  NextTestAndTagDueReport(){
    this.coreDataService
    .GetNextTestandTagDueReport(this.CustomerIDModel!=null?this.CustomerIDModel:0, this.LocationIDModel!=null?this.LocationIDModel:0,this.PortfoliocheckMode ,this.LanguageInitial)
    .subscribe((res) => {
      if (res != undefined && res != null) {
      this.saveAsFile(this._base64ToArrayBuffer(res.fileData),res.fileName,res.extention)
      } else {
        this.notify.warning(this.ErrorMsgData.noDataAvailable);
      }
    });
  }
  NextServiceDueReport(){
    this.coreDataService
    .GetNextServiceDueReport(this.CustomerIDModel!=null?this.CustomerIDModel:0, this.LocationIDModel!=null?this.LocationIDModel:0, this.PortfoliocheckMode,this.LanguageInitial)
    .subscribe((res) => {
      if (res != undefined && res != null) {
        this.saveAsFile(this._base64ToArrayBuffer(res.fileData),res.fileName,res.extention)
        this.CommonFilterComponent.resetModels();
      } else {
        this.notify.warning(this.ErrorMsgData.noDataAvailable);
      }
    });
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  ngOnDestroy(): void {
    this.commonFilterEntity = new ReportFilter();
    this.sharedData.ReportFilterBeh$ = new BehaviorSubject<ReportFilter>(this.commonFilterEntity);
    this.reportFilter = new ReportFilter();
   
  }
  async handleSelection($event) {
    this.fieldTemplate=null
    this.isGenerateReportEnabled=true;
    this.CustomerIDModel = null
    this.LocationIDModel = null
    this.IsImageOptionsDisabled = false
    this.CommonFilterComponent.resetModels()
    
    if ($event.index.charAt(0) == "0") {
      this.SelectedReport = "Next Test and Tag Due Report"
      this.ReportTypeButton = this.LanguageConfig.download
     
    }
    else if ($event.index.charAt(0) == "1") {
      this.IsImageOptionsDisabled = true
      this.SelectedReport = "Next Service Due Report"
      this.ReportTypeButton =  this.LanguageConfig.download
     
    }
    else if ($event.index.charAt(0) == "2") {
      this.SelectedReport = "Project Progress Report"
      this.ReportTypeButton = this.LanguageConfig.download
    }
   
    this.resetPanel();

  }

  onPanelChange(event) {

  }
  CustomerfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
      this.sharedData.ShowDefaultFields$.next("true");
    } else if (filterData.length == 0) {
      // 23/06/21 Changes for filter data length.
      this.getCustomerbyName("");
    }
  }
  async SelectedCustomer(val) {
    if (val == undefined) {
      // 23/06/21 Changes for SelectedCustomer values
      this.CustomerDropdown = [];
      this.sharedData.ReportFilterSharedData["customerID"] = null;
      this.sharedData.ReportFilterSharedData["locationID"] = null;
      this.LocationIDModel = undefined;
      this.CustomerIDModel = undefined


      this.CommonFilterComponent.CustomerIDModel = this.CustomerIDModel
      
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.CommonFilterComponent.ApplyFilter();

    } else if (val != undefined) {
      this.LocationIDModel = null;
      // this.LocationDropdown = [];
      this.FilteredLocationDropdown = [];
      this.getLocationbyName(this.CustomerIDModel);
      this.CommonFilterComponent.CustomerIDModel = this.CustomerIDModel
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.CommonFilterComponent.ApplyFilter();
      this.resetPanel();


    }
   

  }
  SelectedLocation(val) {
    if (val == undefined) {
      this.sharedData.ReportFilterSharedData["locationID"] = null;
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.resetPanel()
    }
    if (val != undefined) {
      this.sharedData.ReportFilterSharedData["locationID"] = val;
      this.CommonFilterComponent.LocationIDModel = this.LocationIDModel
      this.CommonFilterComponent.ApplyFilter();
      this.resetPanel()
    }
    //this.openFieldChooser();
  }


  locationFilterChange(value) {
    this.FilteredLocationDropdown = [];
    if (value.length >= 3) {
      this.FilteredLocationDropdown = this.LocationDropdown.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      this.FilteredLocationDropdown = this.LocationDropdown;
    }
  }

  getCustomerbyName(searchtext) {
    // console.log(this.sharedData.parseJwt(localStorage.getItem('token')))
    let userData = this.sharedData.parseJwt(localStorage.getItem("token"));
    console.log(userData);
    this.coreDataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {
       
          this.CustomerDropdown = res;
          this.CustomerDropdown = this.CustomerDropdown.filter((e) => {
            return e.isBusinessUnit == true;
          });
          this.getLocationbyName(this.CustomerIDModel);
       
      }
    });
  }

  getLocationbyName(customerID) {
    this.coreDataService
      .getUserLocationByCustomerDropdown("", customerID)
      .subscribe((res) => {
        if (res != undefined && res != null) {
          this.LocationDropdown = res;
          this.FilteredLocationDropdown = res;
        }
      });
  }

  async generateReport(type) {
    
   


    {
      if (this.SelectedReport == "Next Test and Tag Due Report") {
       this.NextTestAndTagDueReport()
      }
      else if (this.SelectedReport == "Next Service Due Report") {
       this.NextServiceDueReport()
      }
      else if (this.SelectedReport == "Project Progress Report") {
        this.PtojectProgressData('PPR')
      }
     
      this.isExpanded = false
    }
   
   


  }

  resetPanel() {
    this.ReportParameter = true
    this.MoreFilter = true
    this.ChooseColumn = true
    this.Schedule = true
    this.Format = true
    this.ReportOption = true
    this.customReportButton = true

    if (this.SelectedReport == "Next Test and Tag Due Report") {
      this.ReportParameter = false
      this.Format = false
      this.isGenerateReportEnabled=false
    
    }
    else if (this.SelectedReport == "Next Service Due Report") {
      this.ReportParameter = false
      this.Format = false
      this.isGenerateReportEnabled=false
     

      
    }
    else if (this.SelectedReport == "Project Progress Report") {
      this.ReportParameter = false
      this.Format = false
      this.isGenerateReportEnabled=false


      }
    }
    
  }
 
  

 
  

  
 

