import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ViewChild, ElementRef, HostListener } from "@angular/core";

import { ChartComponent, ValueAxis } from "@progress/kendo-angular-charts";
import { saveAs } from "@progress/kendo-file-saver";
import { exportImage, geometry } from "@progress/kendo-drawing";
import { CoreDataService } from "../Service/core-data.service";
import {
  DashboardKPIListingEntity,
  HighestReplacementCost,
  AgeVsConditionEntity,
  AssetGroupKwhListingEntity,
  RemainingLifeListingEntity,
  ConditionRatingListingEntity,
  BudgetListingEntity,
  RefrigerantListingEntity,
  HighestTotalSpendAssets,
} from "../Dashboard/dashboard.model";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { PlotBand } from "@progress/kendo-angular-charts";
import {
  SharedDataService,
  ReportFilter,
} from "src/app/Service/CommonUtility.service";
import { myActiveProjectsModel } from "../Dashboard/MyactiveProjects";
import { techAuditAnalysisModel } from "../Dashboard/TechAuditAnalysis";
import { projectDurationModel } from "../Dashboard/ProjectDuration";
import * as FileSaver from "file-saver";

import {
  process,
  State,
  SortDescriptor,
  orderBy,
  filterBy,
  FilterDescriptor,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import { IntlService } from "@progress/kendo-angular-intl";
import { CustomCurrency } from "../Common/CustomCurrency.pipe";
import { share } from "rxjs-compat/operator/share";

import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import htmlToImage from "html-to-image";

import { ValueAxisLabels } from "@progress/kendo-angular-charts";
import { Subscription } from "rxjs";

@Component({
  selector: "admin-dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss"],
  providers: [CustomCurrency],
})
export class DashboardComponent implements OnInit, OnDestroy {
  reportFilter: ReportFilter = new ReportFilter();
  BudgetChartLabel: string;
  public FilteredLocationDropdown: Array<{ name: string; locationID: number }> =
    [];
  public LocationDropdown: Array<{ name: string; locationID: number }> = [];
  public CustomerDropdown: Array<{
    customerName: string;
    customerID: number;
    isBusinessUnit: boolean;
  }> = [];
  myActiveProjectsData: any[] = [];
  techAuditData: any[] = [];
  projectDuration: any[] = [];
  CustomerID: any;
  LocationID: any;
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  @ViewChild("AgeVsRemainingLifeChart",{static:false}) AgeVsRemainingLifeChart: ChartComponent;
  @ViewChild("ConditionRatingChart",{static:false}) ConditionRatingChart: ChartComponent;
  @ViewChild("AgeVsConditionChart",{static:false}) AgeVsConditionChart: ChartComponent;
  @ViewChild("BudgetGraphChart",{static:false}) BudgetGraphChart: ChartComponent;
  @ViewChild("RefrigerantBreakdownChart",{static:false})
  RefrigerantBreakdownChart: ChartComponent;
  @ViewChild("AssetGroupKwhChart",{static:false}) AssetGroupKwhChart: ChartComponent;

  isShowBacktoTop: boolean;
  public opened = false;
  displayError: any;
  sub: Subscription;
  //report Models

  StatusModel: any[] = new Array<any>();
  CriticalityModel: any[] = new Array<any>();
  OwnershipModel: any[] = new Array<any>();
  AgeGroupModel: any[] = new Array<any>();
  FilterTypeModel: any[] = new Array<any>();
  ReplacementCostModel: any[] = new Array<any>();
  ConditionRatingModel: any[] = new Array<any>();
  AssetGroupModel: any[] = new Array<any>();
  ReplacementYearModel: any[] = new Array<any>();
  RemaningLifeModel: any[] = new Array<any>();
  RefrigerantModel: any[] = new Array<any>();
  AssetTypeModel: any[] = new Array<any>();
  ServiceCallModel: any[] = new Array<any>();
  SortByModel: number = 1;
  ohsCommentModel: number = 2;
  currencySymbol: any;
  IsRefrigerentWigetEnable: boolean = false;
  IsTotalKWHWidgetEnable: boolean = false;
  message: any;

  @HostListener("window:scroll")
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    var element = document.getElementById("FilterHeader");
    var elementSub = document.getElementById("filterSubHeader");

    if (scrollPosition >= 100) {
      this.isShowBacktoTop = true;
      element.classList.add("one-edge-shadow");
      element.style.background = "#000000";
      elementSub.style.color = "#fff";
    } else {
      this.isShowBacktoTop = false;
      element.classList.remove("one-edge-shadow");
      element.style.background = "#ffffff";
      elementSub.style.color = "#000000";
    }
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  private state1: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  private state2: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "CustomerName", dir: "desc" }],
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public filter: CompositeFilterDescriptor;
  //public gridfilter: any[] = filterBy(this.myActiveProjectsData, this.filter);
  LanguageConfig: any;
  GridConfig: any;
  TotalAssetCountRefeg: number;
  disabledKpICall: boolean = false;

  constructor(
    private http: HttpClient,
    private currencyPipe: CustomCurrency,
    public intl: IntlService,
    private DataService: CoreDataService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private router: Router
  ) {
    let pageTite = this.activatedRoute.snapshot.data["title"];
    this.titleService.setTitle(pageTite);
  }

  async ngOnInit() {
    this.getCustomerbyName("");
    // this.getLocationbyName("");
    this.CustomerID =
      this.sharedDataService.ReportFilterSharedData["customerID"];
    if (this.CustomerID != null || this.CustomerID != undefined) {
      this.getLocationbyName(this.CustomerID);
    }
    this.LocationID =
    this.sharedDataService.ReportFilterSharedData["locationID"]==undefined?null:Number(this.sharedDataService.ReportFilterSharedData["locationID"])
    this.getDashboardKPI(this.sharedDataService.ReportFilterSharedData);
    this.updateAllKpIS(this.sharedDataService.ReportFilterSharedData)
    if (
      this.sharedDataService.GetLanguageLiteral() != "" &&
      this.sharedDataService.GetLanguageLiteral() != null
    ) {
      this.sharedDataService.languageConfig$.subscribe((res) => {
        if (res != undefined) {
          this.LanguageConfig = res[32];
        }
      });
    }
    // if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
    //   let langLiteral = this.sharedDataService.GetLanguageLiteral();
    //   this.GridConfig = GridData[langLiteral];
    // }

    var token = window.localStorage.getItem("token");
    if (token != null && token != undefined && token != "") {
      this.UserData = this.sharedDataService.parseJwt(token);
    }
    this.sharedDataService.GetOrgSettings().then((res) => {
      this.GridCurrencyChange = res.Currency;
      this.currencySymbol = res.CurrencySymbol;
      this.functionReturningString(res.Currency);
    });
    // if (this.sharedDataService.AssetGroups.length == 0) {
    //   this.DataService.GetAssetGroupDropdown().subscribe((result) => {
    //     if (result != undefined && result != null) {
    //       result.forEach((res) => {
    //         if (
    //           res.groupName == "CHILLERS" ||
    //           res.groupName == "REFRIGERATED-OTHER" ||
    //           res.groupName == "SPLITSYSTEMS" ||
    //           res.groupName == "REFRIGERATION-HVAC" ||
    //           res.groupName == "AA-REFRIGERATION-FUTURE"
    //         ) {
    //           this.IsRefrigerentWigetEnable = true;
    //         }
    //         if (
    //           res.groupName == "BOILERS" ||
    //           res.groupName == "CHILLERS" ||
    //           res.groupName == "CONTROLS" ||
    //           res.groupName == "PUMPS" ||
    //           res.groupName == "NONREFRIGERATED" ||
    //           res.groupName == "REFRIGERATED-OTHER" ||
    //           res.groupName == "SWITCHBOARD/COMP" ||
    //           res.groupName == "VENTILATION" ||
    //           res.groupName == "LIFTS" ||
    //           res.groupName == "SPLITSYSTEMS" ||
    //           res.groupName == "ELECTRICAL" ||
    //           res.groupName == "HYDRAULIC" ||
    //           res.groupName == "FIREELECTRICAL" ||
    //           res.groupName == "STEAM" ||
    //           res.groupName == "REVERSE OSMOSIS" ||
    //           res.groupName == "REFRIGERATION-HVAC" ||
    //           res.groupName == "AA-REFRIGERATION-FUTURE" ||
    //           res.groupName == "VIRTUAL ASSETS"
    //         ) {
    //           this.IsTotalKWHWidgetEnable = true;
    //         }
    //       });
    //     }
    //   });
    // }

    if (this.sharedDataService.AssetGroups.length > 0) {
      this.sharedDataService.AssetGroups.forEach((res) => {
        if (
          res.groupName == "CHILLERS" ||
          res.groupName == "REFRIGERATED-OTHER" ||
          res.groupName == "SPLITSYSTEMS" ||
          res.groupName == "REFRIGERATION-HVAC" ||
          res.groupName == "AA-REFRIGERATION-FUTURE"
        ) {
          this.IsRefrigerentWigetEnable = true;
        }
        if (
          res.groupName == "BOILERS" ||
          res.groupName == "CHILLERS" ||
          res.groupName == "CONTROLS" ||
          res.groupName == "PUMPS" ||
          res.groupName == "NONREFRIGERATED" ||
          res.groupName == "REFRIGERATED-OTHER" ||
          res.groupName == "SWITCHBOARD/COMP" ||
          res.groupName == "VENTILATION" ||
          res.groupName == "LIFTS" ||
          res.groupName == "SPLITSYSTEMS" ||
          res.groupName == "ELECTRICAL" ||
          res.groupName == "HYDRAULIC" ||
          res.groupName == "FIREELECTRICAL" ||
          res.groupName == "STEAM" ||
          res.groupName == "REVERSE OSMOSIS" ||
          res.groupName == "REFRIGERATION-HVAC" ||
          res.groupName == "AA-REFRIGERATION-FUTURE" ||
          res.groupName == "VIRTUAL ASSETS"
        ) {
          this.IsTotalKWHWidgetEnable = true;
        }
      });
    }

    this.Math = Math;
    this.IsInUseAssets = true;
    this.IsBudgetYear = false;
    this.IsR22 = false;
    this.bindCodeData();
   
      this.sub = this.sharedDataService.ReportFilterBeh$.subscribe(
        async (obj) => {
          this.CustomerID=obj.customerID;
          this.LocationID=obj.locationID
          console.log(obj);
          if (
            obj != undefined &&
            obj != null &&
            this.disabledKpICall == false
          ) {
            this.reportFilter = obj;
           
            console.log(this.reportFilter);
            if (this.sharedDataService.AssetGroups.length == 0) {
              this.DataService.GetAssetGroupDropdown().subscribe((result) => {
                if (result != undefined && result != null) {
                  console.log("REES", result);
                  result.forEach((res) => {
                    if (
                      res.groupName == "CHILLERS" ||
                      res.groupName == "REFRIGERATED-OTHER" ||
                      res.groupName == "SPLITSYSTEMS" ||
                      res.groupName == "REFRIGERATION-HVAC" ||
                      res.groupName == "AA-REFRIGERATION-FUTURE"
                    ) {
                      this.IsRefrigerentWigetEnable = true;
                    }
                    if (
                      res.groupName == "BOILERS" ||
                      res.groupName == "CHILLERS" ||
                      res.groupName == "CONTROLS" ||
                      res.groupName == "PUMPS" ||
                      res.groupName == "NONREFRIGERATED" ||
                      res.groupName == "REFRIGERATED-OTHER" ||
                      res.groupName == "SWITCHBOARD/COMP" ||
                      res.groupName == "VENTILATION" ||
                      res.groupName == "LIFTS" ||
                      res.groupName == "SPLITSYSTEMS" ||
                      res.groupName == "ELECTRICAL" ||
                      res.groupName == "HYDRAULIC" ||
                      res.groupName == "FIREELECTRICAL" ||
                      res.groupName == "STEAM" ||
                      res.groupName == "REVERSE OSMOSIS" ||
                      res.groupName == "REFRIGERATION-HVAC" ||
                      res.groupName == "AA-REFRIGERATION-FUTURE" ||
                      res.groupName == "VIRTUAL ASSETS"
                    ) {
                      this.IsTotalKWHWidgetEnable = true;
                    }
                  });
                  if (this.IsRefrigerentWigetEnable == true) {
                    this.getDashboardRefrigerantBreakdown(this.reportFilter);
                  }
                  if (this.IsTotalKWHWidgetEnable == true) {
                    this.getDashboardAssetGroupKwh(this.reportFilter);
                  }
                }
              });
            }
            await this.getDashboardKPI(this.reportFilter);
            await this.getDashboardConditionRating(this.reportFilter);
            if (this.IsRefrigerentWigetEnable == true) {
              await this.getDashboardRefrigerantBreakdown(this.reportFilter);
            }
            await this.getDashboardRemainingLifeByAgeGroup(this.reportFilter);
            await this.getDashboardYearBudget(this.reportFilter);
            if (this.IsTotalKWHWidgetEnable == true) {
              await this.getDashboardAssetGroupKwh(this.reportFilter);
            }
            await this.getDashboardAgeVsCondition(this.reportFilter);
            await this.getHighestReplacementCost(this.reportFilter);
            await this.getHighestTotalSpendAssets(this.reportFilter)
          } else if (
            this.activatedRoute.snapshot.data["title"] == "Dashboard" &&
            obj == undefined &&
            obj == null
          ) {
            if (this.sharedDataService.AssetGroups.length == 0) {
              this.DataService.GetAssetGroupDropdown().subscribe((result) => {
                if (result != undefined && result != null) {
                  console.log("RES1", result);
                  result.forEach((res) => {
                    if (
                      res.groupName == "CHILLERS" ||
                      res.groupName == "REFRIGERATED-OTHER" ||
                      res.groupName == "SPLITSYSTEMS" ||
                      res.groupName == "REFRIGERATION-HVAC" ||
                      res.groupName == "AA-REFRIGERATION-FUTURE"
                    ) {
                      this.IsRefrigerentWigetEnable = true;
                    }
                    if (
                      res.groupName == "BOILERS" ||
                      res.groupName == "CHILLERS" ||
                      res.groupName == "CONTROLS" ||
                      res.groupName == "PUMPS" ||
                      res.groupName == "NONREFRIGERATED" ||
                      res.groupName == "REFRIGERATED-OTHER" ||
                      res.groupName == "SWITCHBOARD/COMP" ||
                      res.groupName == "VENTILATION" ||
                      res.groupName == "LIFTS" ||
                      res.groupName == "SPLITSYSTEMS" ||
                      res.groupName == "ELECTRICAL" ||
                      res.groupName == "HYDRAULIC" ||
                      res.groupName == "FIREELECTRICAL" ||
                      res.groupName == "STEAM" ||
                      res.groupName == "REVERSE OSMOSIS" ||
                      res.groupName == "REFRIGERATION-HVAC" ||
                      res.groupName == "AA-REFRIGERATION-FUTURE" ||
                      res.groupName == "VIRTUAL ASSETS"
                    ) {
                      this.IsTotalKWHWidgetEnable = true;
                    }
                  });
                  if (this.IsRefrigerentWigetEnable == true) {
                    this.getDashboardRefrigerantBreakdown(this.reportFilter);
                  }
                  if (this.IsTotalKWHWidgetEnable == true) {
                    this.getDashboardAssetGroupKwh(this.reportFilter);
                  }
                }
              
              });
            }

            await this.getDashboardKPI(new ReportFilter());
            await this.getDashboardConditionRating(new ReportFilter());
            if (this.IsRefrigerentWigetEnable == true) {
              await this.getDashboardRefrigerantBreakdown(this.reportFilter);
            }
            await this.getDashboardRemainingLifeByAgeGroup(new ReportFilter());
            await this.getDashboardYearBudget(new ReportFilter());
            if (this.IsTotalKWHWidgetEnable == true) {
              await this.getDashboardAssetGroupKwh(this.reportFilter);
            }
            await this.getDashboardAgeVsCondition(new ReportFilter());
            await this.getHighestReplacementCost(new ReportFilter());
           await this.getHighestTotalSpendAssets(new ReportFilter());
          }
        }
      );
    
    // else {
    // await this.getDashboardKPI(new ReportFilter());
    // await this.getDashboardConditionRating(new ReportFilter());
    // await this.getDashboardRefrigerantBreakdown(new ReportFilter());
    // await this.getDashboardRemainingLifeByAgeGroup(new ReportFilter());
    // await this.getDashboardYearBudget(new ReportFilter());
    // await this.getDashboardAssetGroupKwh(new ReportFilter());
    // await this.getDashboardAgeVsCondition(new ReportFilter());
    // await this.getHighestReplacementCost(new ReportFilter());
    //   }
    // })
  }

  public labelContentt = (e: any) => {
    // let data: any = this.functionReturningString(this.BudgetListingEntity.budget)
    // function FormatLongNumber(value) {
    if (this.budgetChartData == 0) {
      return this.currencySymbol + e.value + 0;
    } else {
      // for testing

      //value = Math.floor(Math.random()*1001);

      // hundreds
      if (this.budgetChartData == 0 <= 999) {
        return this.currencySymbol + e.value;
      }
      // thousands
      else if (this.budgetChartData >= 1000 && this.budgetChartData <= 999999) {
        return this.currencySymbol + e.value + this.LanguageConfig["Thousand"];
      }
      // millions
      else if (
        this.budgetChartData >= 1000000 &&
        this.budgetChartData <= 999999999
      ) {
        return this.currencySymbol + e.value + this.LanguageConfig["Million"];
      }
      // billions
      else if (
        this.budgetChartData >= 1000000000 &&
        this.budgetChartData <= 999999999999
      ) {
        return this.currencySymbol + e.value + this.LanguageConfig["Billion"];
      } else return e.value;
    }
  };

  // const label = this.customLabels.find(label => label.value === e.value);
  // console.log(data)
  // return '$' + e.value + 'M';

  RefreshData() {
    if (this.CustomerID != undefined) {
      this.reportFilter.customerID = this.CustomerID;
      if (this.LocationID == null) {
        this.updateAllKpIS(this.reportFilter);
      } else {
        // this.reportFilter.locationID = this.LocationID.toString();
        this.reportFilter.locationID = this.LocationID;
        // this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);
        this.updateAllKpIS(this.reportFilter);
      }
    } else {
      this.displayError = this.LanguageConfig.SelectCustomer;
    }
  }

  async updateAllKpIS(reportFilter) {
    await this.getDashboardKPI(reportFilter);
    await this.getDashboardConditionRating(reportFilter);
    await this.getDashboardRefrigerantBreakdown(reportFilter);
    await this.getDashboardRemainingLifeByAgeGroup(reportFilter);
    await this.getDashboardYearBudget(reportFilter);
    await this.getDashboardAssetGroupKwh(reportFilter);
    await this.getDashboardAgeVsCondition(reportFilter);
    await this.getHighestReplacementCost(reportFilter);
   await this.getHighestTotalSpendAssets(reportFilter)
  }

  public labelContent(e: any): string {
    return `${e.category} (${e.dataItem.refrigerantQty})`;
  }
  public exportChart(type): void {
    if (type == "AgeVsRemainingLife") {
      this.AgeVsRemainingLifeChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "ConditionRatingChart") {
      this.ConditionRatingChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "AgeVsConditionChart") {
      this.AgeVsConditionChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "BudgetGraphChart") {
      this.BudgetGraphChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "RefrigerantBreakdownChart") {
      this.RefrigerantBreakdownChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "AssetGroupKwhChart") {
      this.AssetGroupKwhChart.exportImage().then((dataURI) => {
        saveAs(dataURI, type + ".png");
      });
    } else if (type == "ReplacementCostTable") {
      var node = document.getElementById("ReplacementCostTable");
    }  else if (type == "TotalSpendTable") {
        var node = document.getElementById("TotalSpendTable");


      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          FileSaver.saveAs(dataUrl, type + ".png");
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }
  }
  labelContentAssetGroup(e: any): string {
    return `${e.category} (${e.dataItem.electricalInputKW})`;
  }
  functionReturningString(value: any) {
    if (value == 0) {
      return 0;
    } else {
      // for testing
      //value = Math.floor(Math.random()*1001);

      // hundreds
      if (value <= 999) {
        return Number.parseFloat(value.toString()).toFixed(1);
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (
          Number.parseFloat((value / 1000).toString())
            .toFixed(1)
            .toString() + this.LanguageConfig["Thousand"]
        );
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (
          Number.parseFloat((value / 1000000).toString())
            .toFixed(1)
            .toString() + this.LanguageConfig["Million"]
        );
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (
          Number.parseFloat((value / 1000000000).toString())
            .toFixed(1)
            .toString() + this.LanguageConfig["Billion"]
        );
      } else return value;
    }
  }

  DashboardKPIListing: DashboardKPIListingEntity =
    new DashboardKPIListingEntity();
  RemainingLifeListingEntity: RemainingLifeListingEntity =
    new RemainingLifeListingEntity();
  ConditionRatingListingEntity: ConditionRatingListingEntity =
    new ConditionRatingListingEntity();
  BudgetListingEntity: BudgetListingEntity = new BudgetListingEntity();
  RMBudgetListingEntity: BudgetListingEntity = new BudgetListingEntity();
  LocationBudgetListingEntity:BudgetListingEntity=new BudgetListingEntity();
  RefrigerantListingEntity: RefrigerantListingEntity =
    new RefrigerantListingEntity();
  AssetGroupKwhListingEntity: AssetGroupKwhListingEntity =
    new AssetGroupKwhListingEntity();
  AgeVsConditionEntity: Array<AgeVsConditionEntity> =
    new Array<AgeVsConditionEntity>();
  HighestReplacementCostEntity: Array<HighestReplacementCost> =
    new Array<HighestReplacementCost>();
    HighestTotalSpendAssets: Array<HighestTotalSpendAssets> =
    new Array<HighestTotalSpendAssets>();
  AgeVsConditionCategories: Array<string> = new Array<string>();
  AgeVsConditionSeries1: Array<number> = new Array<number>();
  AgeVsConditionSeries2: Array<number> = new Array<number>();
  AgeVsConditionSeries3: Array<number> = new Array<number>();
  AgeVsConditionSeries4: Array<number> = new Array<number>();
  AgeVsConditionSeries5: Array<number> = new Array<number>();
  AgeVsConditionSeries6: Array<number> = new Array<number>();
  AgeVsConditionSeries7: Array<number> = new Array<number>();

  AgeVsConditionSeries1Name: string;
  AgeVsConditionSeries2Name: string;
  AgeVsConditionSeries3Name: string;
  AgeVsConditionSeries4Name: string;
  AgeVsConditionSeries5Name: string;
  AgeVsConditionSeries6Name: string;
  AgeVsConditionSeries7Name: string;
  IsInUseAssets: boolean;
  IsBudgetYear: boolean;
  IsR22: boolean;

  public ddlCodeData: Array<any> = new Array<any>();
  public ConditionRatingData: Array<any> = new Array<any>();
  UserData: any;
  Math: any;
  BudgetYearLabelFormat(e: any) {
    return e.value;
  }

  GridCurrencyChange: string;

  bindCodeData() {
    this.DataService.getCodeData().subscribe((res) => {
      if (res != undefined && res != null) {
        this.ddlCodeData.push(res);
      }
    });
  }

  getLocationbyName(customerID) {
    this.DataService.getUserLocationByCustomerDropdown(
      "",
      customerID
    ).subscribe((res) => {
      if (res != undefined && res != null) {
        this.LocationDropdown = res;
        this.FilteredLocationDropdown = this.LocationDropdown;
      }
    });
  }
  CustomerfilterChange(filterData: any): void {
    if (filterData.length >= 3) {
      this.getCustomerbyName(filterData);
    } else if (filterData.length == 0) {
      // 23/06/21 Changes for filter data length.
      this.getCustomerbyName("");
      this.CustomerID = null;
      this.LocationID = null;
      this.sharedDataService.ReportFilterSharedData["customerID"] = null;
      this.sharedDataService.ReportFilterSharedData["locationID"] = null;
    }
  }
  SelectedCustomer(val) {
    console.log(val);
    if (val == undefined) {
      this.CustomerDropdown = [];
      this.getCustomerbyName("");
      this.sharedDataService.ReportFilterSharedData["customerID"] = null;
      this.sharedDataService.ReportFilterSharedData["locationID"] = null;
      this.getDashboardKPI(this.sharedDataService.ReportFilterSharedData);
      // this.getLocationbyName('');
    } else if (val != undefined) {
      this.sharedDataService.ReportFilterSharedData["customerID"] = val;
      this.LocationID = null;
      this.LocationDropdown = [];
      this.FilteredLocationDropdown = [];
      // this.getDashboardKPI(this.sharedDataService.ReportFilterSharedData);
      this.getLocationbyName(this.CustomerID);
    }
    //this.getCustomerbyName("");
  }
  SelectedLocation(val) {
    if (val == undefined) {
      this.sharedDataService.ReportFilterSharedData["customerID"] = null;
      this.sharedDataService.ReportFilterSharedData["locationID"] = null;
      this.reportFilter.locationID=this.LocationID;
      this.reportFilter.customerID=this.CustomerID
      this.updateAllKpIS(this.reportFilter);
     
    }
    if (val != undefined) {
      this.sharedDataService.ReportFilterSharedData["locationID"] = val;
      this.getDashboardKPI(this.sharedDataService.ReportFilterSharedData);
      this.reportFilter.locationID=this.LocationID;
      this.reportFilter.customerID=this.CustomerID
      this.updateAllKpIS(this.reportFilter);
    }
  }
  locationFilterChange(value) {
    this.FilteredLocationDropdown = [];
    if (value.length >= 3) {
      this.FilteredLocationDropdown = this.LocationDropdown.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      // this.getLocationbyName('')
      this.FilteredLocationDropdown = this.LocationDropdown;
    }
  }
  getCustomerbyName(searchtext) {
    console.log(searchtext);
    let userData = this.sharedDataService.parseJwt(
      localStorage.getItem("token")
    );
    this.DataService.getCustomerByName(searchtext).subscribe((res) => {
      if (res != undefined && res != null) {
        if (userData.InventoryManagement == "False") {
          this.CustomerDropdown = res;
          this.CustomerDropdown = this.CustomerDropdown.filter((e) => {
            return e.isBusinessUnit == false;
          });
        } else {
          this.CustomerDropdown = res;
        }
      }
    });
  }
  getDashboardRemainingLifeByAgeGroup(reportFilter) {
    this.DataService.getDashboardRemainingLifeByAgeGroupUrl(
      reportFilter
    ).subscribe((res) => {
      let ageGroup = [];
      let remainingLife = [];
      let actualAge = [];
      res.forEach((element) => {
        if (element.ageGroup != undefined && element.ageGroup != null) {
          ageGroup.push(element.ageGroup);
          remainingLife.push(element.remainingLife);
          actualAge.push(element.actualAge);
        }
      });
      this.RemainingLifeListingEntity.ageGroup = ageGroup;
      this.RemainingLifeListingEntity.remainingLife = remainingLife;
      this.RemainingLifeListingEntity.actualAge = actualAge;
    });
  }
  getHighestReplacementCost(reportFilter) {
    this.DataService.getHighestReplacementCost(reportFilter).subscribe(
      (res) => {
        this.HighestReplacementCostEntity = res;
      }
    );
  }
  getHighestTotalSpendAssets(reportFilter) {
    this.DataService.getHighestTotalSpendAssets(reportFilter).subscribe(
      (res) => {
        this.HighestTotalSpendAssets = res;
      }
    );
  }
  getDashboardConditionRating(reportFilter) {
    this.DataService.getDashboardConditionRatingUrl(reportFilter).subscribe(
      (res) => {
        this.ConditionRatingData = res;
        let conditionRatingName = [];
        let count = [];

        res.forEach((element) => {
          conditionRatingName.push(element.conditionRatingName);
          count.push(element.count);
        });
        this.ConditionRatingListingEntity.conditionRatingName =
          conditionRatingName;
        this.ConditionRatingListingEntity.count = count;
      }
    );
  }
  getDashboardRefrigerantBreakdown(reportFilter) {
    this.DataService.getDashboardRefrigerantBreakdownUrl(
      reportFilter
    ).subscribe((res) => {
      this.RefrigerantListingEntity.Data = res;
    });
  }

  getDashboardAssetGroupKwh(reportFilter) {
    this.DataService.getDashboardAssetGroupKwh(reportFilter).subscribe(
      (res) => {
        this.AssetGroupKwhListingEntity = res;
      }
    );
  }
  public pointColor(point): string {
    if (point.index == 0) return "red";
    if (point.index == 1) return "yellow";
    if (point.index == 2) return "red";
    if (point.index == 3) return "red";
    if (point.index == 4) return "red";
    if (point.index == 5) return "red";
    else return "green";
  }
  getDashboardAgeVsCondition(reportFilter) {
    this.DataService.getDashboardAgeVsCondition(reportFilter).subscribe(
      (res) => {
        this.AgeVsConditionEntity = res;
        this.AgeVsConditionCategories = [
          "<1",
          "1-5",
          "6-10",
          "11-15",
          "16-20",
          "21-25",
          "25+",
        ];
        this.AgeVsConditionSeries1 = [];
        this.AgeVsConditionSeries2 = [];
        this.AgeVsConditionSeries3 = [];
        this.AgeVsConditionSeries4 = [];
        this.AgeVsConditionSeries5 = [];
        this.AgeVsConditionSeries6 = [];
        this.AgeVsConditionSeries7 = [];

        this.AgeVsConditionSeries1Name = "";
        this.AgeVsConditionSeries2Name = "";
        this.AgeVsConditionSeries3Name = "";
        this.AgeVsConditionSeries4Name = "";
        this.AgeVsConditionSeries5Name = "";
        this.AgeVsConditionSeries6Name = "";
        this.AgeVsConditionSeries7Name = "";

        this.AgeVsConditionCategories.forEach((element) => {
          let value = this.AgeVsConditionEntity.filter((obj) => {
            return obj.ageGroup == element;
          });
          if (value.length > 0) {
            // if (value[0] != undefined && this.AgeVsConditionSeries1Name == undefined) { this.AgeVsConditionSeries1Name = value[0].conditionRatingName }
            // if (value[1] != undefined && this.AgeVsConditionSeries2Name == undefined) { this.AgeVsConditionSeries2Name = value[1].conditionRatingName }
            // if (value[2] != undefined && this.AgeVsConditionSeries3Name == undefined) { this.AgeVsConditionSeries3Name = value[2].conditionRatingName }
            // if (value[3] != undefined && this.AgeVsConditionSeries4Name == undefined) { this.AgeVsConditionSeries4Name = value[3].conditionRatingName }
            // if (value[4] != undefined && this.AgeVsConditionSeries5Name == undefined) { this.AgeVsConditionSeries5Name = value[4].conditionRatingName }
            this.AgeVsConditionSeries1Name =
              value.find((obj) => {
                return (
                  obj.code == "NEEDS TO BE REPLACED IN 12MTHS"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code ==
                      "NEEDS TO BE REPLACED IN 12MTHS"
                    );
                  }).conditionRatingName
                : this.AgeVsConditionSeries1Name;
            this.AgeVsConditionSeries2Name =
              value.find((obj) => {
                return (
                  obj.code == "NEEDS COMPONENT UPGRADE/REPAIR"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code ==
                      "NEEDS COMPONENT UPGRADE/REPAIR"
                    );
                  }).conditionRatingName
                : this.AgeVsConditionSeries2Name;
            this.AgeVsConditionSeries3Name =
              value.find((obj) => {
                return (
                  obj.code == "NO IMMEDIATE WORK REQUIRED.OK"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code == "NO IMMEDIATE WORK REQUIRED.OK"
                    );
                  }).conditionRatingName
                : this.AgeVsConditionSeries3Name;
            this.AgeVsConditionSeries4Name =
              value.find((obj) => {
                return (
                  obj.code == "NOT BRAND NEW. WORKS VERY WELL"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code ==
                      "NOT BRAND NEW. WORKS VERY WELL"
                    );
                  }).conditionRatingName
                : this.AgeVsConditionSeries4Name;
            this.AgeVsConditionSeries5Name =
              value.find((obj) => {
                return obj.code == "NEW OR AS GOOD A NEW UNIT";
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code == "NEW OR AS GOOD A NEW UNIT"
                    );
                  }).conditionRatingName
                : this.AgeVsConditionSeries5Name;
                this.AgeVsConditionSeries6Name =
                value.find((obj) => {
                  return obj.code == "PHASED OUT/DIRECT REPLACEMENT NOT AVAILABLE";
                }) != undefined
                  ? value.find((obj) => {
                      return (
                        obj.code == "PHASED OUT/DIRECT REPLACEMENT NOT AVAILABLE"
                      );
                    }).conditionRatingName
                  : this.AgeVsConditionSeries6Name;
                  this.AgeVsConditionSeries7Name =
                  value.find((obj) => {
                    return obj.code == "NO LONGER MANUFACTURED OR SUPPORTED";
                  }) != undefined
                    ? value.find((obj) => {
                        return (
                          obj.code == "NO LONGER MANUFACTURED OR SUPPORTED"
                        );
                      }).conditionRatingName
                    : this.AgeVsConditionSeries7Name;

            this.AgeVsConditionSeries1.push(
              value.find((obj) => {
                return (
                  obj.code == "NEEDS TO BE REPLACED IN 12MTHS"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code ==
                      "NEEDS TO BE REPLACED IN 12MTHS"
                    );
                  }).count
                : 0
            );
            this.AgeVsConditionSeries2.push(
              value.find((obj) => {
                return (
                  obj.code == "NEEDS COMPONENT UPGRADE/REPAIR"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code ==
                      "NEEDS COMPONENT UPGRADE/REPAIR"
                    );
                  }).count
                : 0
            );
            this.AgeVsConditionSeries3.push(
              value.find((obj) => {
                return (
                  obj.code == "NO IMMEDIATE WORK REQUIRED.OK"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code == "NO IMMEDIATE WORK REQUIRED.OK"
                    );
                  }).count
                : 0
            );
            this.AgeVsConditionSeries4.push(
              value.find((obj) => {
                return (
                  obj.code == "NOT BRAND NEW. WORKS VERY WELL"
                );
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code ==
                      "NOT BRAND NEW. WORKS VERY WELL"
                    );
                  }).count
                : 0
            );
            this.AgeVsConditionSeries5.push(
              value.find((obj) => {
                return obj.code == "NEW OR AS GOOD A NEW UNIT";
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code == "NEW OR AS GOOD A NEW UNIT"
                    );
                  }).count
                : 0
            );
            this.AgeVsConditionSeries6.push(
              value.find((obj) => {
                return obj.code == "PHASED OUT/DIRECT REPLACEMENT NOT AVAILABLE";
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code == "PHASED OUT/DIRECT REPLACEMENT NOT AVAILABLE"
                    );
                  }).count
                : 0
            );
            this.AgeVsConditionSeries7.push(
              value.find((obj) => {
                return obj.code == "NO LONGER MANUFACTURED OR SUPPORTED";
              }) != undefined
                ? value.find((obj) => {
                    return (
                      obj.code == "NO LONGER MANUFACTURED OR SUPPORTED"
                    );
                  }).count
                : 0
            );

            // this.AgeVsConditionSeries1Name = value.find((obj) => { return obj.conditionRatingName == '1年以内に交換要' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '1年以内に交換要' }).conditionRatingName : this.AgeVsConditionSeries1Name
            // this.AgeVsConditionSeries2Name = value.find((obj) => { return obj.conditionRatingName == '部品の更新または修理要' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '部品の更新または修理要' }).conditionRatingName : this.AgeVsConditionSeries2Name
            // this.AgeVsConditionSeries3Name = value.find((obj) => { return obj.conditionRatingName == '特に問題なし' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '特に問題なし' }).conditionRatingName : this.AgeVsConditionSeries3Name
            // this.AgeVsConditionSeries4Name = value.find((obj) => { return obj.conditionRatingName == '新品ではないが動作良好' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '新品ではないが動作良好' }).conditionRatingName : this.AgeVsConditionSeries4Name
            // this.AgeVsConditionSeries5Name = value.find((obj) => { return obj.conditionRatingName == '新品または新品同様' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '新品または新品同様' }).conditionRatingName : this.AgeVsConditionSeries5Name

            // this.AgeVsConditionSeries1.push(value.find((obj) => { return obj.conditionRatingName == '1年以内に交換要' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '1年以内に交換要' }).count : 0)
            // this.AgeVsConditionSeries2.push(value.find((obj) => { return obj.conditionRatingName == '部品の更新または修理要' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '部品の更新または修理要' }).count : 0)
            // this.AgeVsConditionSeries3.push(value.find((obj) => { return obj.conditionRatingName == '特に問題なし' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '特に問題なし' }).count : 0)
            // this.AgeVsConditionSeries4.push(value.find((obj) => { return obj.conditionRatingName == '新品ではないが動作良好' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '新品ではないが動作良好' }).count : 0)
            // this.AgeVsConditionSeries5.push(value.find((obj) => { return obj.conditionRatingName == '新品または新品同様' }) != undefined ? value.find((obj) => { return obj.conditionRatingName == '新品または新品同様' }).count : 0)

            // if (value[0] != undefined) { this.AgeVsConditionSeries1.push(value[0].count); } else { this.AgeVsConditionSeries1.push(0); }
            // if (value[1] != undefined) { this.AgeVsConditionSeries2.push(value[1].count); } else { this.AgeVsConditionSeries2.push(0); }
            // if (value[2] != undefined) { this.AgeVsConditionSeries3.push(value[2].count); } else { this.AgeVsConditionSeries3.push(0); }
            // if (value[3] != undefined) { this.AgeVsConditionSeries4.push(value[3].count); } else { this.AgeVsConditionSeries4.push(0); }
            // if (value[4] != undefined) { this.AgeVsConditionSeries5.push(value[4].count); } else { this.AgeVsConditionSeries5.push(0); }
          } else {
            this.AgeVsConditionSeries1.push(0);
            this.AgeVsConditionSeries2.push(0);
            this.AgeVsConditionSeries3.push(0);
            this.AgeVsConditionSeries4.push(0);
            this.AgeVsConditionSeries5.push(0);
            this.AgeVsConditionSeries6.push(0);
            this.AgeVsConditionSeries7.push(0);
          }
        });
      }
    );
  }

  budgetChartData: any;
  RMbudgetChartData: any;
  getDashboardYearBudget(reportFilter) {
    this.DataService.getDashboardYearBudgetUrl(reportFilter).subscribe(
      (res) => {
        let budgetYear = [];
        let RMbudgetYear = [];
        let budget = [];
        let RMbudget = [];
        let LocationRMBudget=[]
        let lowest = Number.POSITIVE_INFINITY;
        let highest = Number.NEGATIVE_INFINITY;
        let tmp;
        console.log(res);
        console.log(res.capexYearBudget);
        res.capexYearBudget.forEach((element) => {
          tmp = element.budget;
          this.budgetChartData = tmp;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;

          // element.budgetYear = element.budgetYear == "Year11" ? "Year10+" : element.budgetYear
          element.budgetYear = this.LanguageConfig[element.budgetYear];

          budgetYear.push(element.budgetYear);
          budget.push(element.budget);
        });
        res.rmYearBudget.forEach((element) => {
          tmp = element.budget;
          this.RMbudgetChartData = tmp;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;

          // element.budgetYear = element.budgetYear == "Year11" ? "Year10+" : element.budgetYear
          element.budgetYear = this.LanguageConfig[element.budgetYear];

          // budgetYear.push(element.budgetYear);
          RMbudget.push(element.rmBudget);
        });
        res.locationRMYearBudget.forEach((element) => {
          tmp = element.locationRMBudget  ;
          this.budgetChartData = tmp;
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;

          // element.budgetYear = element.budgetYear == "Year11" ? "Year10+" : element.budgetYear
          element.budgetYear = this.LanguageConfig[element.budgetYear];

         // budgetYear.push(element.budgetYear);
          LocationRMBudget.push(element.locationRMBudget);
        });
        let factor = 1;

        if (highest <= 999) {
          factor = 1;
        }
        // thousands
        else if (highest >= 1000 && highest <= 999999) {
          factor = 1000;
          this.BudgetChartLabel =
            this.LanguageConfig["BudgetChartLabelInthousands"];
        }
        // millions
        else if (highest >= 1000000 && highest <= 999999999) {
          factor = 1000000;
          this.BudgetChartLabel =
            this.LanguageConfig["BudgetChartLabelInmillions"];
        }
        // billions
        else if (highest >= 1000000000 && highest <= 999999999999) {
          factor = 1000000000;
          this.BudgetChartLabel =
            this.LanguageConfig["BudgetChartLabelInbillions"];
        }
        let FormatedBudget: Array<number> = new Array<number>();
        let RMFormatedBudget: Array<number> = new Array<number>();
        let LocationRMFormatedBudget: Array<number> = new Array<number>();
        budget.forEach((element) => {
          FormatedBudget.push(element / factor);
        });
        RMbudget.forEach((ele) => {
          RMFormatedBudget.push(ele / factor);
        });
        LocationRMBudget.forEach((ele) => {
          LocationRMFormatedBudget.push(ele / factor);
        });
        FormatedBudget.push();
        this.BudgetListingEntity.budgetYear = budgetYear;
        this.RMBudgetListingEntity.budgetYear = budgetYear;
        this.LocationBudgetListingEntity.budgetYear=budgetYear
        this.RMBudgetListingEntity.budget = RMFormatedBudget;
        this.BudgetListingEntity.budget = FormatedBudget;
        this.LocationBudgetListingEntity.budget=LocationRMFormatedBudget
        console.log("BB:", this.BudgetListingEntity);
        console.log("RM:", this.RMBudgetListingEntity);
        console.log('LBM', this.LocationBudgetListingEntity);
      }
    );
  }

  getDashboardKPI(reportFilter) {
    this.DataService.getDashboardKPIUrl(reportFilter).subscribe((res) => {
      this.DashboardKPIListing.inUseAsset = res.inUseAsset;
      this.DashboardKPIListing.lessYear1Asset = res.lessYear1Asset;
      this.DashboardKPIListing.total5YearBudget = this.functionReturningString(
        res.total5YearBudget
      );
      this.DashboardKPIListing.totalAsset = res.totalAsset;
      this.DashboardKPIListing.totalExceptR22Refrigerant = parseInt(
        Math.round(res.totalExceptR22Refrigerant).toFixed(0)
      );
      this.DashboardKPIListing.ActualAge1Year = res.actualAge1Year;

      this.DashboardKPIListing.totalR22Refrigerant = parseInt(
        Math.round(res.totalR22Refrigerant).toFixed(0)
      );

      this.DashboardKPIListing.totalRefrigerantQty = res.totalRefrigerantQty;
      this.DashboardKPIListing.refigerantName = res.refigerantName;
      this.DashboardKPIListing.totalR22RefrigerantPer = parseFloat(
        Math.round(
          (res.totalR22Refrigerant /
            this.DashboardKPIListing.totalRefrigerantQty) *
            100
        ).toFixed(2)
      );
      this.DashboardKPIListing.totalExceptR22RefrigerantPer = parseFloat(
        Math.round(
          (res.totalExceptR22Refrigerant /
            this.DashboardKPIListing.totalRefrigerantQty) *
            100
        ).toFixed(2)
      );
      if (document.getElementById("progressIDExceptR22") != null) {
        document.getElementById("progressIDExceptR22").style.width =
          this.DashboardKPIListing.totalExceptR22RefrigerantPer + "%";
      }
    });
  }

  //reset Filter Model

  public InstallDaterange = { start: null, end: null };
  public Daterange = { start: null, end: null };
  CapMin = 0;
  CapStep = 10;
  CapMax = 999;
  CapRangeCheckBox;
  State: string;
  CapRange: any = new Array<any>();

  onConditionRatingSelect(e): void {
    let Value = this.ConditionRatingData.filter((obj) => {
      return obj.conditionRatingName.toLowerCase() == e.category.toLowerCase();
    });
    if (Value != undefined && Value.length > 0)
      this.reportFilter.conditionRating = Value[0].codeID.toString();
    // this.sharedDataService.ReportFilterSharedData =  this.reportFilter
    // this.router.navigate(["/assets-listing"]);
    this.disabledKpICall = true;
    this.ApplyFilter();
  }

  InUseAsset(AssetCount) {
    this.IsInUseAssets = true;
    this.disabledKpICall = true;
    this.ApplyFilter();
  }
  TotalR22() {
    this.IsR22 = true;
    this.disabledKpICall = true;
    this.ApplyFilter();
  }
  BudgetYear() {
    this.IsInUseAssets = true;
    this.IsBudgetYear = true;
    this.disabledKpICall = true;
    this.ApplyFilter();
  }

  TotalAssets() {
    this.IsInUseAssets = false;
    this.disabledKpICall = true;
    this.ApplyFilter();
    // this.router.navigate(["/assets-listing"]);
  }

  AssetsLessThanOne(val) {
    this.reportFilter.remainingLife = val;
    this.disabledKpICall = true;
    this.ApplyFilter();
  }
  ActualAgeLessThanOne(val) {
    this.reportFilter.ageGroup = val;
    this.disabledKpICall = true;
    this.ApplyFilter();
  }

  public onAgeVsRemainingLifeSelect(e): void {
    if (e.category != undefined && e.category != "") {
      if (e.series.name.toLowerCase() == "remaining life") {
        this.reportFilter.remainingLife = e.category;
      } else {
        this.reportFilter.ageGroup = e.category;
      }
      this.disabledKpICall = true;
      this.ApplyFilter();
    }
  }
  onBudgetGraphSelect(e): void {
     var val= Object.keys(this.LanguageConfig).find(key => this.LanguageConfig[key] === e.category);
     e.category=val
    if (e.category != undefined && e.category != "") {
      this.reportFilter.replacementYear = e.category;
      this.disabledKpICall = true;
      this.ApplyFilter();
    }
  }
  onRefrigerantBreakdownSelect(e): void {
    if (e.category != undefined && e.category != "") {
      this.reportFilter.refrigerant = e.dataItem.codeID.toString();
      this.disabledKpICall = true;
      this.ApplyFilter();
    }
  }
  onAssetGroupKwhSelect(e): void {
    if (e.category != undefined && e.category != "") {
      this.reportFilter.assetGroup = e.dataItem.assetGroupID.toString();
      this.disabledKpICall = true;
      this.ApplyFilter();
    }
  }
  OnHighestReplacementCostClick(dataItem): void {
    this.LocationID = dataItem.locationID;
    this.reportFilter.assetTypeID = dataItem.assetTypeID.toString();
    this.disabledKpICall = true;
    this.ApplyFilter();
  }

  OnHighestTotalSpendClick(dataItem): void {
    this.LocationID = dataItem.locationID;
    this.reportFilter.assetTypeID = dataItem.assetTypeID.toString();
    this.disabledKpICall = true;
    this.ApplyFilter();
  }

  onAgeVsConditionSelect(e): void {
    let _ageGroup = e.category;
    let _conditionRatingName = e.series.name;

    let value = this.AgeVsConditionEntity.filter((obj) => {
      return (
        obj.conditionRatingName.toLowerCase() ==
          _conditionRatingName.toLowerCase() && obj.ageGroup == _ageGroup
      );
    });
    if (value != undefined && value.length > 0) {
      this.reportFilter.ageGroup = _ageGroup;
      this.reportFilter.conditionRating = value[0].conditionRatingCd.toString();
      this.disabledKpICall = true;
      this.ApplyFilter();
    }
  }

  ApplyFilter() {
    this.sharedDataService.ReportFilterSharedData = new ReportFilter();

    this.reportFilter.customerID = this.CustomerID;
    // this.reportFilter.locationID = this.LocationID == undefined ? "" : this.LocationID.toString();
    this.reportFilter.locationID = this.LocationID;

    if (this.IsInUseAssets) {
      this.ddlCodeData[0].forEach((ele) => {
        if (
          ele.codeName.toLowerCase() == "assetstatuscd" &&
          ele.codeValue.toLowerCase() == "in use"
        ) {
          this.reportFilter.status = ele.codeID.toString();
        }
      });
    }

    if (this.IsBudgetYear) {
      this.reportFilter.replacementYear = "Year1,Year2,Year3,Year4,Year5";
    }

    if (this.IsR22) {
      let aRRTemp = [];
      this.ddlCodeData[0].forEach((ele) => {
        if (
          ele.codeName.toLowerCase() == "refrigerantcd" &&
          ele.codeValue.toLowerCase() == "r22"
        ) {
          this.reportFilter.refrigerant = ele.codeID.toString();
          this.sharedDataService.ReportFilterSharedData = this.reportFilter;
        }
        // && ele.codeValue.toLowerCase() != 'decommisioned' DECOMMISSIONED
        if (
          ele.codeName.toLowerCase() == "assetstatuscd" &&
          ele.codeValue != "DECOMMISSIONED"
        ) {
          // this.reportFilter.status = ele.codeID.toString()
          aRRTemp.push(ele.codeID);
        }
      });

      console.log(aRRTemp);
      this.reportFilter.status = aRRTemp.toString();
      // aRRTemp.filter(ele=>{
      //   if(ele.codeValue != 'DECOMMISSIONED'){
      //     return ele.codeID
      //   }
      // })
    }
    // this.sharedDataService.ReportFilterSharedData = this.reportFilter
    this.sharedDataService.ReportFilterSharedData.customerID =
      this.reportFilter.customerID;
    this.sharedDataService.ReportFilterSharedData.locationID =
      this.reportFilter.locationID;
    this.sharedDataService.ReportFilterSharedData.assetGroup =
      this.reportFilter.assetGroup;
    this.sharedDataService.ReportFilterSharedData.status =
      this.reportFilter.status;

    this.sharedDataService.ReportFilterSharedData.state =
      this.reportFilter.state;
    this.sharedDataService.ReportFilterSharedData.capacityEnd =
      this.reportFilter.capacityEnd;
    this.sharedDataService.ReportFilterSharedData.capacityStart =
      this.reportFilter.capacityStart;
    this.sharedDataService.ReportFilterSharedData.installStartDate =
      this.reportFilter.installStartDate;
    this.sharedDataService.ReportFilterSharedData.installEndDate =
      this.reportFilter.installEndDate;
    this.sharedDataService.ReportFilterSharedData.startDate =
      this.reportFilter.startDate;
    this.sharedDataService.ReportFilterSharedData.endDate =
      this.reportFilter.endDate;
    this.sharedDataService.ReportFilterSharedData.conditionRating =
      this.reportFilter.conditionRating;
    this.sharedDataService.ReportFilterSharedData.criticality =
      this.reportFilter.criticality;
    this.sharedDataService.ReportFilterSharedData.assetOwnership =
      this.reportFilter.assetOwnership;
    this.sharedDataService.ReportFilterSharedData.ageGroup =
      this.reportFilter.ageGroup;
    this.sharedDataService.ReportFilterSharedData.filtersFitted =
      this.reportFilter.filtersFitted;
    this.sharedDataService.ReportFilterSharedData.replacementYear =
      this.reportFilter.replacementYear;
    this.sharedDataService.ReportFilterSharedData.replacementCost =
      this.reportFilter.replacementCost;
    this.sharedDataService.ReportFilterSharedData.remainingLife =
      this.reportFilter.remainingLife;
    this.sharedDataService.ReportFilterSharedData.refrigerant =
      this.reportFilter.refrigerant;
    this.sharedDataService.ReportFilterSharedData.assetTypeID =
      this.reportFilter.assetTypeID;
    this.sharedDataService.ReportFilterSharedData.OHSComments =
      this.reportFilter.OHSComments;
    this.sharedDataService.ReportFilterSharedData.sortBy =
      this.reportFilter.sortBy;
      this.sharedDataService.ReportFilterSharedData.totalSpend =
      this.reportFilter.totalSpend;

    this.sharedDataService.ReportFilterBeh$.next(this.reportFilter);


    this.router.navigate(["/assets-listing"]);
    //window.open(window.location.origin+"/assets-listing","_blank");
  }

  public open() {
    this.opened = true;
  }

  public close(status) {
    this.opened = false;
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
    this.reportFilter = new ReportFilter();
  }
}
