import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { CoreDataService } from '../../Service/core-data.service'
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { EmailConfigurationListingEntity } from './email-configuration-listing.model';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { SharedDataService, ReportFilter } from '../../Service/CommonUtility.service'

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-email-configuration-listing',
  templateUrl: './email-configuration-listing.component.html',
  styleUrls: ['./email-configuration-listing.component.scss']
})
export class EmailConfigurationListingComponent implements OnInit {
  emailTemplateID: number;
  emailConfigurationList: Array<EmailConfigurationListingEntity> = [];
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
  };
  LanguageConfig: any;
  GridConfig: any
  public filter: CompositeFilterDescriptor;
  public gridfilter: Array<EmailConfigurationListingEntity> = filterBy(this.emailConfigurationList, this.filter);
  public gridData: GridDataResult;
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.emailConfigurationList, sort), this.state);
  }
  constructor(private titleService: Title,
    private activatedRoute: ActivatedRoute, private coreDataService: CoreDataService, private sharedDataService: SharedDataService, private router: Router) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  DateTimeformat: any;
  Dateformat: any;
  ngOnInit() {

    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      // let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.GridConfig = GridData[langLiteral];
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[18];
          this.GridConfig = res[2];
        }
      })
    }
    this.getEmailConfigurationList();
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res['DateFormat'] + ' ' + res['TimeFormat']
        this.Dateformat = res['DateFormat'];
      }
    })
  }


  getEmailConfigurationList() {
    this.coreDataService.GetEmailConfigurationList().subscribe((res) => {
      if (res != undefined && res != null) {
        this.emailConfigurationList = res;

        this.emailConfigurationList.forEach(ele => {

          ele['updationDate'] = ele['updationDate'] == '0001-01-01T00:00:00+00:00' ? new Date("0001-01-01T00:00:00+00:00") : new Date(ele['updationDate'])
          ele['createDate'] = ele['createDate'] == '0001-01-01T00:00:00+00:00' ? new Date('0001-01-01T00:00:00+00:00') : new Date(ele['createDate'])
          ele['updationDateYear'] = new Date(ele['updationDate']).getFullYear();
          ele['createDateYear'] = new Date(ele['createDate']).getFullYear();
        })

        this.emailConfigurationList = this.emailConfigurationList;

        this.gridData = process(this.emailConfigurationList, this.state);
      }
    })
  }

  editemailConfig(value) {
    if (value != null && value != undefined) {
      this.emailTemplateID = value.emailTemplateID
      this.router.navigate(["/email-configuration/" + this.emailTemplateID])
    }
  }
  MenuOnSelect(event) {
    // var baseUrl = window.location.origin + '/#/email-configuration/' + event.dataItem.emailTemplateID;
    var baseUrl = window.location.origin + '/email-configuration/' + event.dataItem.emailTemplateID;

    if (event.item == "Open") {
      // this.getQuoteDetail(event.dataItem.QuoteID);
      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.emailConfigurationList, this.state);
  }
}
