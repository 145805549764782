import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms'
import { SharedDataService } from "src/app/Service/CommonUtility.service";

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']

})
export class FooterComponent implements OnInit {
  constructor(private sharedDataService: SharedDataService) {
  }
  LanguageConfig: any;
  GridConfig: any
  ngOnInit() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      let langLiteral = this.sharedDataService.GetLanguageLiteral();
      // this.LanguageConfig = new LanguageConfig();
      // this.LanguageConfig.Labels = PageData[langLiteral];
      // this.LanguageConfig.PageHeading = PageData[langLiteral]['PageHeading'];
      // this.LanguageConfig.PageTitle = PageData[langLiteral]['PageTitle'];
      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[1]
        }
      })
    }
  }
}