import { Component, OnInit } from '@angular/core';
const is = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);
import { of } from 'rxjs';
import { SelectableSettings } from '@progress/kendo-angular-treeview';
import { SharedDataService } from 'src/app/Service/CommonUtility.service';
import { CoreDataService } from 'src/app/Service/core-data.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ContenManagementModel, ContentManagementLogList } from './contentManagement.model';
import { SnotifyService } from 'ng-snotify';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GridDataResult, DataStateChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {

  selectedLanguageValue: string;
  menuData: any;
  ErrorDataMsg: any;
  contentTypeModel: ContenManagementModel = new ContenManagementModel();
  private state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public arrLanguage: Array<{ text: string, value: string }> = [
    { text: "English", value: 'en' },
    { text: "日本語", value: 'jp' }
  ];
  public checkedKeys: any[] = [];
  public expandedKeys: any[] = ['0', '1'];
  public selectedKeys: any[] = [];

  public checkedKeys1: any[] = ['0_2'];
  public expandedKeys1: any[] = ['0', '1'];
  public selectedKeys1: any[] = ['0_1'];

  public checked: boolean = false;

  public selection: SelectableSettings = { mode: 'multiple' };
  public gridData: any;
  public UpdatedBy:string;
  public UpdationDate:Date;
  public ContentManagementLog:Array<ContentManagementLogList>=[]
  // public selectedKeys: any[] = ['0_2'];
  LanguageConfig: any
  ConfigData: any
  LanguageInitial: any;
  GridConfig: any
  DateTimeformat: string;
  constructor(private titleService: Title, private sharedDataService: SharedDataService, private notify: SnotifyService, private http: HttpClient, private DataService: CoreDataService, private cookieService: CookieService, private activatedRoute: ActivatedRoute) {
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);
  }

  ngOnInit() {
    this.GetContentManagementLogList();
    if((this.cookieService.get('EvalContentFileIndexSelected') != null && this.cookieService.get('EvalContentFileIndexSelected') !=undefined )){
      this.selectedKeys = [this.cookieService.get('EvalContentFileIndexSelected')]
      this.checkedKeys = this.selectedKeys
      
    }
    if((this.cookieService.get('EvalContentFileSelected') !=null && this.cookieService.get('EvalContentFileSelected') !=undefined)){
      this.handleSelection(this.cookieService.get('EvalContentFileSelected'))
    }
    // if((this.cookieService.get('EvalContentFileIndexSelected') !=null)){
    //   this.selectedKeys = [this.cookieService.get('EvalContentFileIndexSelected')]
    // }
    this.sharedDataService.GetOrgSettings().then((res) => {
      if (res != null) {
        this.DateTimeformat = res["DateFormat"] + " " + res["TimeFormat"];
      }
    });
    

    this.LanguageInitial = this.sharedDataService.GetLanguageLiteral();
    this.sharedDataService.languageConfig$.subscribe(res => {
      if (res != undefined) {
        this.LanguageConfig = res[43];
        this.GridConfig = res[2];
        this.ErrorDataMsg = res[28];
      }
    })
  }
  
  public data: any[] = [{
    text: 'Menu',
    items: [
      {
        text: 'Web',

        items: [
          {
            text: 'Header', file: 'header.json'
          },
          {
            text: 'Dashboard',
            items: [
              { text: 'Dashboard', file: 'dashboard.json' },
              { text: 'Admin Dashboard', file: 'adminDashboard.json' },
              { text: 'My Teams Dashboard', file: 'teamDashboard.json' }

            ]

          },
          {
            text: 'Account Management',
            items: [
              {
                text: 'Customer', file: 'customerManagementConfig.json',
                items: [
                  { text: 'Add Customer', file: 'addCustomer.json' }
                ]
              },
              {
                text: 'Location', file: 'location.json',
                items: [

                  {
                    text: 'Add Location', file: 'addLocation.json'
                  }
                ]
              },
              {
                text: 'Repair and Maintainance', file: 'repair_and_maintainance_listing.json',
                items: [

                  {
                    text: 'Repair and Maintainance', file: 'repair_and_maintainance_listing.json'
                  }
                ]
              },
              {
                text: 'Asset Jobs', file: 'asset_job_listing.json',
                items: [

                  {
                    text: 'Asset Jobs', file: 'asset_job_listing.json'
                  }
                ]
              },
              {
                text: 'Asset', file: 'asset_listing.json',
                items: [
                  // {
                  //   text: 'Dashboard', file: 'expenseDashboard.json'
                  // },
                  {
                    text: 'Dashboard', file: 'expenseDashboard.json'
                  },
                  {
                    text: 'Add Asset', file: 'addAsset.json'
                  },
                  {
                    text: 'subAsset', file: 'subAsset.json'
                  }
                ]
              },
              {
                text: 'Report', file: 'report.json',
                items: [
                  {
                    text: 'Filter', file: 'filter.json'
                  },
                  {
                    text: 'AgeGroup', file: 'ddlAgeGroup.json'
                  },
                  {
                    text: 'Remaining life', file: 'ddlRemainingLife.json'
                  },
                  {
                    text: 'Replacement Cost', file: 'ddlReplacementCost.json'
                  },
                  {
                    text: 'Inventory Management Status', file: 'ddlIMStatus.json'
                  },
                  {
                    text: 'Replacement Year', file: 'ddlReplacementYear.json'
                  },
                  {
                    text: 'Other OHS Comment', file: 'otherOhsComment.json'
                  },
                  {
                    text: 'Other Service Call', file: 'otherServiceCall.json'
                  },
                  {
                    text: 'Other SortBy', file: 'otherSortBy.json'
                  },
                  {
                    text: 'Total Spend', file: 'ddlTotalSpend.json'
                  }
                ]
              }
            ]
          },
          {
            text: 'System Administration',
            items: [
              {
                text: 'Company Management', file: 'companyManagement.json',
                items: [
                  { text: 'Add Company', file: 'createCompany.json' }
                ]

              },
              {
                text: 'User Management', file: 'userManagement.json',
                items: [
                  { text: 'Add User', file: 'adduser.json' }
                ]
              },
              {
                text: 'Masters',
                items: [
                  {
                    text: 'Asset Group', file: 'assetGroup.json',
                    items: [
                      { text: 'Add Asset Group', file: 'createAssetGroup.json' }
                    ]
                  },
                  {
                    text: 'Asset Type', file: 'assetTypeManagement.json',
                    items: [
                      { text: 'Add Asset Type', file: 'createAssetType.json' }
                    ]

                  },
                  {
                    text: 'Unit Of Measurement', file: 'unitOfMeasListing.json',
                    items: [
                      { text: 'Add Unit Of Measurement', file: 'addUnitOfMeasurment.json' }
                    ]


                  },
                  { text: 'Code Management', file: 'codeManagement.json' },
                  { text: 'Fault & Fix Management', file: 'faultfixmanagement.json' }
                ]

              },
              { text: 'Email Configuration', file: 'emailConfiguration.json' },
              { text: 'Asset Refactoring', file: 'assetrefactoring.json' },
              { text: 'Organisation Settings', file: 'organisationSetting.json' },
              { text: 'Data Import', file: 'dataImport.json' },
              { text: 'Global Search', file: 'GlobalSearch.json' }
              
            ]
          },
          {
            text: 'Inventory Management',
            items: [
              {
                text: 'Business Unit', file: 'businessunitManagementConfig.json',
                items: [
                  { text: 'Add Business', file: 'addBusiness.json' }
                ]
                 
              },
              {
                text: 'Department', file: 'Department.json',
                items: [
                  { text: 'Add Department', file: 'addDepartment.json' }
                ]
                
              },
              {
                text: 'Business Assets', file: 'BusinessAsset_listing.json',
                items: [
                  { text: 'Reserve Asset', file: 'reserve_asset.json' }
                ]
                           
              },
              {
                text: 'Inventory Dashboard', file: 'business_dashboard.json',
                items: [
                  { text: 'Inventory Dashboard', file: 'business_dashboard.json' }
                ]
                           
              },
               
                ]
              }


        ]
      },

      {
        text: 'Mobile',
        items: [
          { text: 'Login Page', file: "login_mobile.json" },
          { text: 'Dashboard', file: "dashboard_mobile.json" },
          { text: 'App Usage Screen', file: "appUsage_mobile.json" },
          {
            text: 'Assets',
            items: [
              { text: 'Asset listing', file: "assetList_mobile.json" },
              { text: 'Add Asset', file: "PrimaryDetailsScreen_mobile.json" }
            ]

          },
          {
            text: 'Other',
            items: [
              { text: 'Attachments', file: "attachments_mobile.json" },
              { text: 'Sub-Assets', file: "addSubAsset_mobile.json" },
              { text: 'Reports', file: "reportScreen_mobile.json" },
              { text: 'Change Passsword', file: "changePasssword_mobile.json" },
              { text: 'Progress Report', file: "baseline_mobile.json" }

              // { text: 'Progress report' }
            ]
          },
          {
            text: 'Settings',
            items: [
              { text: 'Setting Page', file: "settings_mobile.json" },
              { text: 'Report an Issue', file: "otherTexts_mobile.json" }
            ]
          }
        ]
      },

      {
        text: 'Common',
        items: [
          { text: 'Asset Field', file: "AssetFields.json" },
          { text: 'Sub Asset Field', file: "SubAssetFields.json" },
          { text: 'Asset Field Group', file: "AssetFieldGroup.json" }
          
        ]
      },
      {
        text: 'Miscellaneous',
        items: [
          {
            text: 'Error & Notification', file: "ErrorMessagesConfig.json"

          }
        ]
      }
    ]
  }];



  public hasChildren = (item: any) => item.items && item.items.length > 0;
  public fetchChildren = (item: any) => of(item.items);

  /* custom selection implementation below */


  public isItemSelected = (_: any, index: string) => this.selectedKeys.indexOf(index) > -1;


  public TempArr = [];
  public formGroup: FormGroup;
  private editedRowIndex: number;

  handleChecking($event){
    console.log($event.item)
    this.handleSelection($event.item)
  }

  handleSelection(event) {
   this.state.skip=0

    this.contentTypeModel.fileName = event.dataItem == undefined ? event : event.dataItem.file
    this.UpdatedBy=''
    this.UpdationDate=null
   this.ContentManagementLog.forEach(row=>{
    if(row.fileName== this.contentTypeModel.fileName && row.language==this.sharedDataService.GetLanguageLiteral())
    {
      this.UpdatedBy=row.updatedByName
      this.UpdationDate=row.updationDate
    }

   })
    
     this.checkedKeys = []
     this.cookieService.set('EvalContentFileSelected',event.dataItem == undefined ? event : event.dataItem.file,365)
     this.cookieService.set('EvalContentFileIndexSelected',event.index == undefined ? this.selectedKeys.toString() : event.index,365)
    //  this.checkedKeys = event.index == undefined ? this.selectedKeys : [event.index]

      this.selectedKeys = [this.cookieService.get('EvalContentFileIndexSelected')]
      this.checkedKeys = this.selectedKeys
      // this.selectedKeys = ['0_2_0']

      this.GetConfigData(this.sharedDataService.GetLanguageLiteral(), event.dataItem == undefined ? event : event.dataItem.file).then(res => {


        if (this.contentTypeModel.fileName != 'AssetFields.json' && this.contentTypeModel.fileName != 'SubAssetFields.json' && this.contentTypeModel.fileName != 'AssetFieldGroup.json' && this.contentTypeModel.fileName != 'ddlAgeGroup.json' && this.contentTypeModel.fileName != 'ddlRemainingLife.json' && this.contentTypeModel.fileName != 'ddlReplacementCost.json' && this.contentTypeModel.fileName != 'ddlIMStatus.json' && this.contentTypeModel.fileName != 'ddlReplacementYear.json' && this.contentTypeModel.fileName != 'otherOhsComment.json' && this.contentTypeModel.fileName != 'otherServiceCall.json' && this.contentTypeModel.fileName != 'otherSortBy.json') {

          this.TempArr = [];
          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;



          Object.entries(res).forEach((ele) => {
            this.TempArr.push({ "FieldName": ele[0], "DisplayName": ele[1] })
          })
          this.gridData = process(this.TempArr, this.state);
        }
        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFields.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['FieldName'], "DisplayName": ele[1]['DisplayName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('SubAssetFields.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['FieldName'], "DisplayName": ele[1]['DisplayName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlAgeGroup.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['ageGroupID'], "DisplayName": ele[1]['ageGroupName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlRemainingLife.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['remaningLifeID'], "DisplayName": ele[1]['remaningLifeValue'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlReplacementCost.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['replacementCostID'], "DisplayName": ele[1]['replacementCostValue'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlIMStatus.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['iMStatusID'], "DisplayName": ele[1]['iMStatusValue'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlReplacementYear.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['replacementYearID'], "DisplayName": ele[1]['replacementYearName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }
        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('otherOhsComment.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['ohsCommentID'], "DisplayName": ele[1]['ohsCommentName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }

        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('otherServiceCall.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['serviceCallID'], "DisplayName": ele[1]['serviceCallName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }


        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('otherSortBy.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['sortByID'], "DisplayName": ele[1]['sortByName'] })

          })

          this.gridData = process(this.TempArr, this.state);
        }


        else if (new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFieldGroup.json').valueOf()) {
          this.TempArr = [];

          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;


          Object.entries(res).forEach((ele) => {

            this.TempArr.push({ "FieldName": ele[1]['GroupName'], "DisplayName": ele[1]['DisplayName'] })

          })

          this.gridData = process(this.TempArr, this.state);

        }
        else {
          this.TempArr = [];
          this.TempArr.push(res)
          this.ConfigData = JSON.stringify(res);
          this.contentTypeModel.fileData = this.ConfigData;

          this.gridData = process(this.TempArr[0], this.state);

        }

      })
  }


  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({

      'DisplayName': new FormControl('', Validators.required)
    });

    sender.addRow(this.formGroup);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.formGroup = new FormGroup({

      'DisplayName': new FormControl(dataItem.DisplayName, Validators.required),

    });

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew ,dataItem}) {

    if (rowIndex != undefined && rowIndex != null && this.contentTypeModel.fileName != 'AssetFields.json' && this.contentTypeModel.fileName != 'SubAssetFields.json' && this.contentTypeModel.fileName != 'AssetFieldGroup.json' && this.contentTypeModel.fileName != 'ddlAgeGroup.json' && this.contentTypeModel.fileName != 'ddlRemainingLife.json' && this.contentTypeModel.fileName != 'ddlReplacementCost.json' && this.contentTypeModel.fileName != 'ddlIMStatus.json'  && this.contentTypeModel.fileName != 'ddlReplacementYear.json' && this.contentTypeModel.fileName != 'otherOhsComment.json' && this.contentTypeModel.fileName != 'otherServiceCall.json' && this.contentTypeModel.fileName != 'otherSortBy.json') {
    //  this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
    this.saveValue(dataItem.FieldName,formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFields.json').valueOf()) {
      // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
      this.saveValue(dataItem.FieldName,formGroup.value['DisplayName'])
     }
     else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('SubAssetFields.json').valueOf()) {
      // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
      this.saveValue(dataItem.FieldName,formGroup.value['DisplayName'])
     }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFieldGroup.json').valueOf()) {
     // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
     this.saveValue(dataItem.FieldName,formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('ddlAgeGroup.json').valueOf()) {
      //this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
      this.saveValue(dataItem.FieldName,formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('ddlRemainingLife.json').valueOf()) {
     // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
     this.saveValue(dataItem.FieldName,formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('ddlReplacementCost.json').valueOf()) {
     // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
     this.saveValue(dataItem.FieldName, formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('ddlIMStaus.json').valueOf()) {
      // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
      this.saveValue(dataItem.FieldName, formGroup.value['DisplayName'])
     }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('ddlReplacementYear.json').valueOf()) {
    //  this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
    this.saveValue(dataItem.FieldName, formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('otherOhsComment.json').valueOf()) {
    //  this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
    this.saveValue(dataItem.FieldName, formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('otherServiceCall.json').valueOf()) {
     // this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
     this.saveValue(dataItem.FieldName, formGroup.value['DisplayName'])
    }
    else if (rowIndex != undefined && rowIndex != null && new String(this.contentTypeModel.fileName).valueOf() === new String('otherSortBy.json').valueOf()) {
    //  this.TempArr[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
    this.saveValue(dataItem.FieldName, formGroup.value['DisplayName'])
    }
    else {
      this.TempArr.forEach(e => {
       // e[rowIndex]['DisplayName'] = formGroup.value['DisplayName']
       if(e.FieldName==dataItem.FieldName){ e.DisplayName= formGroup.value['DisplayName']}
      })
    }

    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }



  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    if (this.contentTypeModel.fileName != 'AssetFields.json' && this.contentTypeModel.fileName != 'SubAssetFields.json' && this.contentTypeModel.fileName != 'AssetFieldGroup.json') {
      this.gridData = process(this.TempArr, this.state);
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFields.json').valueOf()) {
      this.gridData = process(this.TempArr, this.state);
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('SubAssetFields.json').valueOf()) {
      this.gridData = process(this.TempArr, this.state);
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFieldGroup.json').valueOf()) {
      this.gridData = process(this.TempArr, this.state);
    }
    else {
      this.gridData = process(this.TempArr[0], this.state);
    }
  }

  public hasChildren1 = (item: any) => item.items && item.items.length > 0;
  public fetchChildren1 = (item: any) => of(item.items);

  SelectedLanguage() {
    this.ChangeLanguage();
    this.LanguageInitial = this.sharedDataService.GetLanguageLiteral()

    this.selectedLanguageValue = this.LanguageInitial;


  }

  ChangeLanguage() {
    if (this.cookieService.check('EvalLangPref') != null && this.cookieService.check('EvalLangPref')) {
      this.cookieService.set('EvalLangPref', this.selectedLanguageValue)
    }
    else {
      this.cookieService.set('EvalLangPref', environment.environment.language)
    }
    this.ChangeLanguageData();
  }

  ChangeLanguageData() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {
      this.DataService.GetHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "header.json").then(res => {
        this.menuData = res;
      })
    }
  }


  GetConfigData(langLiteral, Filename) {

    return this.http.get(this.DataService.ContentResourceFile, {
      params: {
        "LanguageLiteral": langLiteral,
        "FileName": Filename
      }
    }).toPromise();

    //api call;
  }

  saveContentData() {
    var result = {};

    if (this.contentTypeModel.fileName != 'AssetFields.json' && this.contentTypeModel.fileName != 'SubAssetFields.json' && this.contentTypeModel.fileName != 'AssetFieldGroup.json' && this.contentTypeModel.fileName != 'ddlAgeGroup.json' && this.contentTypeModel.fileName != 'ddlRemainingLife.json' && this.contentTypeModel.fileName != 'ddlReplacementCost.json' && this.contentTypeModel.fileName != 'ddlIMStatus.json'  && this.contentTypeModel.fileName != 'ddlReplacementYear.json' && this.contentTypeModel.fileName != 'otherOhsComment.json' && this.contentTypeModel.fileName != 'otherServiceCall.json' && this.contentTypeModel.fileName != 'otherSortBy.json') {
      for (var i = 0; i < this.TempArr.length; i++) {
        result[this.TempArr[i].FieldName] = this.TempArr[i].DisplayName;
      }
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFields.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "FieldName": ele[1]['FieldName'], "DisplayName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('SubAssetFields.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "FieldName": ele[1]['FieldName'], "DisplayName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('AssetFieldGroup.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "GroupName": ele[1]['FieldName'], "DisplayName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('otherSortBy.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "sortByID": ele[1]['FieldName'], "sortByName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('otherServiceCall.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "serviceCallID": ele[1]['FieldName'], "serviceCallName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('otherOhsComment.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "ohsCommentID": ele[1]['FieldName'], "ohsCommentName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlReplacementYear.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "replacementYearID": ele[1]['FieldName'], "replacementYearName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlReplacementCost.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "replacementCostID": ele[1]['FieldName'], "replacementCostValue": ele[1]['DisplayName'] })

      })
      result = arr;
    }

    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlIMStatus.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "iMStatusID": ele[1]['FieldName'], "iMStatusValue": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlRemainingLife.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "remaningLifeID": ele[1]['FieldName'], "remaningLifeValue": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else if (new String(this.contentTypeModel.fileName).valueOf() === new String('ddlAgeGroup.json').valueOf()) {

      let arr = [];
      Object.entries(this.TempArr).forEach((ele) => {

        arr.push({ "ageGroupID": ele[1]['FieldName'], "ageGroupName": ele[1]['DisplayName'] })

      })
      result = arr;
    }
    else {
      result = this.TempArr[0];
    }



    if (result != null && result != undefined) {
      this.contentTypeModel.fileData = JSON.stringify(result);
    }
    else {
      this.contentTypeModel.fileData = JSON.parse(JSON.stringify(this.contentTypeModel.fileData));
    }

    this.contentTypeModel.languageLiteral = this.LanguageInitial;
    this.DataService.saveContentManagement(this.contentTypeModel).subscribe(res => {
      if (res == 200) {
        setTimeout(() => {
          this.getConfigFile();
        }, 1000);

        this.notify.success(this.ErrorDataMsg.configSaved);

      }
      else if (res == 400) {
        this.notify.error(this.ErrorDataMsg.SomeErrorOccured);
      }
      else {
        this.notify.error(this.ErrorDataMsg.SomeErrorOccured);

      }
    })
  }
  
  getConfigFile() {
    Promise.all(
      [
        this.DataService.GetLoginConfig(this.sharedDataService.GetLanguageLiteral(), "login.json"),
        this.DataService.GetFooterConfig(this.sharedDataService.GetLanguageLiteral(), "footer.json"),
        this.DataService.GetGridDataConfig(this.sharedDataService.GetLanguageLiteral(), "grid.json"),
        this.DataService.GetHeaderConfig(this.sharedDataService.GetLanguageLiteral(), "header.json"),
        this.DataService.GetLocationConfig(this.sharedDataService.GetLanguageLiteral(), "location.json"),
        this.DataService.GetAssetListingConfig(this.sharedDataService.GetLanguageLiteral(), "asset_listing.json"),
        this.DataService.GetCustomerManagementConfig(this.sharedDataService.GetLanguageLiteral(), "customerManagementConfig.json"),
        this.DataService.getCustomerConfig(this.sharedDataService.GetLanguageLiteral(), "addCustomer.json"),
        this.DataService.addLocationConfig(this.sharedDataService.GetLanguageLiteral(), "addLocation.json"),
        this.DataService.addAssetConfig(this.sharedDataService.GetLanguageLiteral(), "addAsset.json"),
        this.DataService.getCompanyManagementConfig(this.sharedDataService.GetLanguageLiteral(), "companyManagement.json"),
        this.DataService.getCompanyConfig(this.sharedDataService.GetLanguageLiteral(), "createCompany.json"),
        this.DataService.getUserManagementConfig(this.sharedDataService.GetLanguageLiteral(), "userManagement.json"),
        this.DataService.getUserUpdateConfig(this.sharedDataService.GetLanguageLiteral(), "addUser.json"),
        this.DataService.getAssetGroupConfig(this.sharedDataService.GetLanguageLiteral(), "assetGroup.json"),
        this.DataService.createAssetGroupConfig(this.sharedDataService.GetLanguageLiteral(), "createAssetGroup.json"),
        this.DataService.getAssetTypeManagementConfig(this.sharedDataService.GetLanguageLiteral(), "assetTypeManagement.json"),
        this.DataService.creatAssetTypeConfig(this.sharedDataService.GetLanguageLiteral(), "createAssetType.json"),
        this.DataService.getEmailConfig(this.sharedDataService.GetLanguageLiteral(), "emailConfiguration.json"),
        this.DataService.updateEmailConfig(this.sharedDataService.GetLanguageLiteral(), "emailConfig.json"),
        this.DataService.getAssetRefactoring(this.sharedDataService.GetLanguageLiteral(), "assetrefactoring.json"),
        this.DataService.getOrganisationSettingConfig(this.sharedDataService.GetLanguageLiteral(), "organisationSetting.json"),
        this.DataService.getUnitOfMesurListing(this.sharedDataService.GetLanguageLiteral(), "unitOfMeasListing.json"),
        this.DataService.updateUnitOfMeasurement(this.sharedDataService.GetLanguageLiteral(), "addUnitOfMeasurment.json"),
        this.DataService.getcodeMangementConfig(this.sharedDataService.GetLanguageLiteral(), "codeManagement.json"),
        this.DataService.getFaultFixConfig(this.sharedDataService.GetLanguageLiteral(), "faultfixmanagement.json"),
        this.DataService.getDocumentUploadConfig(this.sharedDataService.GetLanguageLiteral(), "documentUpload.json"),
        this.DataService.getReportConfig(this.sharedDataService.GetLanguageLiteral(), "report.json"),
        this.DataService.getEroorMessageConfig(this.sharedDataService.GetLanguageLiteral(), "ErrorMessagesConfig.json"),
        this.DataService.getFilterConfig(this.sharedDataService.GetLanguageLiteral(), "filter.json"),
        this.DataService.getAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "AssetFields.json"),
        this.DataService.getResetPassword(this.sharedDataService.GetLanguageLiteral(), "resetpassword.json"),
        this.DataService.getDashboardConfig(this.sharedDataService.GetLanguageLiteral(), "dashboard.json"),
        this.DataService.getAdminDashboard(this.sharedDataService.GetLanguageLiteral(), "adminDashboard.json"),
        this.DataService.getTeamDashboard(this.sharedDataService.GetLanguageLiteral(), "teamDashboard.json"),
        this.DataService.getNotificationMessage(this.sharedDataService.GetLanguageLiteral(), "popupMsg.json"),
        this.DataService.getFieldConfig(this.sharedDataService.GetLanguageLiteral(), "assetFieldConfig.json"),
        this.DataService.getChangepasswordConfig(this.sharedDataService.GetLanguageLiteral(), "changepassword.json"),
        this.DataService.getSubAssetConfig(this.sharedDataService.GetLanguageLiteral(), "subAsset.json"),
        this.DataService.getMyAccountConfig(this.sharedDataService.GetLanguageLiteral(), "myAccount.json"),
        this.DataService.getDataImportConfig(this.sharedDataService.GetLanguageLiteral(), "dataImport.json"),
        this.DataService.getAssetFieldGroup(this.sharedDataService.GetLanguageLiteral(), "AssetFieldGroup.json"),
        this.DataService.getAdminConfig(this.sharedDataService.GetLanguageLiteral(), "adminConfig.json"),
        this.DataService.getContentMangementConfig(this.sharedDataService.GetLanguageLiteral(), "contentManagement.json"),
        this.DataService.getUserActivityReportConfig(this.sharedDataService.GetLanguageLiteral(), "activityReport.json"),
        this.DataService.getCustomerFieldConfig(this.sharedDataService.GetLanguageLiteral(), "customerFieldStatusConfig.json"),
        this.DataService.getddlAgeGroup(this.sharedDataService.GetLanguageLiteral(), "ddlAgeGroup.json"),
        this.DataService.getddlRemainingLifeGroup(this.sharedDataService.GetLanguageLiteral(), "ddlRemainingLife.json"),
        this.DataService.getddlReplacementCost(this.sharedDataService.GetLanguageLiteral(), "ddlReplacementCost.json"),
        this.DataService.getddlReplacementyear(this.sharedDataService.GetLanguageLiteral(), "ddlReplacementYear.json"),
        this.DataService.getServiceCall(this.sharedDataService.GetLanguageLiteral(), "otherServiceCall.json"),
        this.DataService.getSortBy(this.sharedDataService.GetLanguageLiteral(), "otherSortBy.json"),
        this.DataService.getOhsComment(this.sharedDataService.GetLanguageLiteral(), "otherOhsComment.json"),
        this.DataService.getSubAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "SubAssetFields.json"),
        this.DataService.getSubAssetFieldConfig(this.sharedDataService.GetLanguageLiteral(), "GlobalSearch.json"),
        this.DataService.getddlTotalSpend(this.sharedDataService.GetLanguageLiteral(), "ddlTotalSpend.json"),
        this.DataService.GetAssetJobListingConfig(this.sharedDataService.GetLanguageLiteral(), "asset_job_listing.json"),
        this.DataService.GetRepairAndMaintainanceConfig(this.sharedDataService.GetLanguageLiteral(), "repair_and_maintainance_listing.json"),
        this.DataService.getBusinessDashboard(this.sharedDataService.GetLanguageLiteral(), "business_dashboard.json"),
        this.DataService.getddlReplacementCost(this.sharedDataService.GetLanguageLiteral(), "ddlIMStatus.json"),
     //   this.DataService.getExpenseDashboard(this.sharedDataService.GetLanguageLiteral(), "expenseDashboard.json")
        
      ]).then(res => {
        this.sharedDataService.languageConfig = res
        this.sharedDataService.languageConfig$.next(this.sharedDataService.languageConfig);
      });
      this.GetContentManagementLogList()
  }
  GetContentManagementLogList(){
  this.DataService.GetContentManagementList().subscribe(res=>{

   this.ContentManagementLog=res
  })
}
  saveValue(key,value){
    this.TempArr.forEach(e => {
      if(e.FieldName==key){ e.DisplayName=value}
    })
  }
}
