export class projectProgressReport {
    public assetTypeName: string;
    public locationName: string;
    public accountManager: string;
    public auditStatus: string;    
    public baselineValue: number;
    public actualValue: number;
    public TenderActual: number;
    public subAssetBaselineValue:number;
    public subAssetActualvalue:number;
}

export class CommonFilterEntity {
    public customerID: number;
    public assetTypeID: string;
    public locationID?: number;
    public conditionRating: string;
    public status: string;
    public criticality: string;
    public assetOwnership: string;
    public filtersFitted: string;
    public refrigerant: string;
    public assetGroup: string;
    public ageGroup: string
    public remainingLife: string;
    public sortBy: string;
    public OHSComments?: number;
    public serviceCalls: string;
    public replacementYear: string;
    public replacementCost: string;
    public totalSpend:string;
    public state?: string;
    public startDate?: any;
    public endDate?: any;
    public capacityStart?: any;
    public capacityEnd?: any;
    public installStartDate?: any;
    public installEndDate?: any;
    public IsInactiveLocationSelected:boolean=false;
}

export class PortfolioReportEntity {
    public customerID: number;
    public locationID?: number;
    public conditionRating: string;
    public assetStatus: string;
    public sate: string;
    public assetCategory: string;
    public criticality: string;
    public ownership: string;
    public assetType: string;
    public refrigerant: string;
    public ageGroup: string;
    public remainingLife: string;
    public replacementCost: string;
    public filtersFitted: string;
    public replacementYear: string;
    public reportName: string;
    public status: string;
    public state?: string
    public format: string;
    public ohsComments: number;
    public jobCount: string;
    public assetSortBy: string;
    public favoriteName: string;
    public creationDateRange: string;
    public installDateRange: string;
    public capacity: string;
    public assetFields: string;
    public assetID: string;
    //NEW FIELDS
    public IsAssetGroupSelected: boolean;
    public IsAssetClassSelected: boolean;
    public IsCustomerCodeSelected: boolean;
    public IsLocationCodeSelected: boolean;
    public IsCustomerSelected:boolean;
    public language: any;
    public subAssetFields: string;
    //NEW FIELDS
    public startDate: any;
    public endDate: any;
    public duration: any;
    public week_Recur: any;
    public week_Days: any;
    public month_Recur: any;
    public month_Days: any;
    public day_Recur: any;

    //NEW ADDITONAL FIELDS
    public isIncludeAllPhotos: boolean = false;
    public emailRecipients: string;
    //NEW ADDITONAL FIELDS

    //New field
    public reportType: string;
   public  scheduleName :string;
   public totalSpend:string;
   public IsInactiveLocationSelected:boolean=false;
    //New field

}

export class QueueReportEntity {
    public customerName: string;
    public locationName: number;
    public creationDate: any;
    public format: string;
    public status: string;
    public reportName: string;
    public fileName: string;
}

export class ScheduleList {
    public assetReportScheduledID: string;

    public customerName: string;
    public locationName: string;
    public creationDate: any;
    public format: string;
    public startDate: any;
    public endDate: any;
    public duration: string;
    public nextDate: any;
    public scheduleDescription: string;
    public scheduleName: string;
    public reportType:string;
}



export class SchedulerReportEntity {

    public customerID: number;
    public locationID?: number;
    public conditionRating: string;
    public assetStatus: string;
    public sate: string;
    public assetCategory: string;
    public criticality: string;
    public ownership: string;
    public assetType: string;
    public refrigerant: string;
    public ageGroup: string;
    public remainingLife: string;
    public replacementCost: string;
    public filtersFitted: string;
    public replacementYear: string;
    public reportName: string;
    public status: string;
    public state?: string
    public format: string;
    public ohsComments: number;
    public jobCount: string;
    public assetSortBy: string;
    public favoriteName: string;
    public creationDateRange: string;
    public installDateRange: string;
    public capacity: string;
    public assetFields: string;
    public subAssetFields: string;
    public assetID: string;

    public startDate: any;
    public endDate: any;
    public duration: any;

    public day_Recur: any;
    public week_Recur: any;
    public week_Days: any;
    public month_Recur: any;
    public month_Days: any;
    public language: any;

    //NEW ADDITONAL FIELDS
    public isIncludeAllPhotos: boolean = false;
    public emailRecipients: string;
    //NEW ADDITONAL FIELDS
    public reportType: string;
    public  scheduleName :string;
}

export class AssetReportFieldTemplateModel {
    public FieldTemplate: any;
    public TemplateName: string;
    public isDefaultTemplate: boolean;
}