import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export const ExcelConfig = {

    headerStyle: {
      fill: {fgColor: {rgb: "D3D3D3"}},
      font: {name: 'Arial', sz:10, bold: true},
      alignment: { wrapText: true, vertical: 'bottom', horizontal: 'center' }
    },
  
    dateStyle: {
      font: {name: 'Arial', sz:10},
      alignment: { wrapText: false, vertical: 'bottom', horizontal: 'center' }
    },
  
    currencyStyle: {
      font: {name: 'Arial', sz:10},
      alignment: { wrapText: false, vertical: 'bottom', horizontal: 'right' }
    },
  
    textStyle: {
      font: {name: 'Arial', sz:10},
      alignment: { wrapText: true, vertical: 'bottom', horizontal: 'right' }
    },
  
    generalStyle: {
      font: {name: 'Arial', sz:10},
      alignment: { wrapText: true, vertical: 'bottom', horizontal: 'left' }
    },
  
    dateFmt: 'dd/mm/yyyy',
    currencyFmt: '$#,##0.00;[Red]($#,##0.00)'
  };
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

    constructor() { }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        
        worksheet[0].s= ExcelConfig.headerStyle;
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    Workbook(worksheet ) {
        return 

        
    }
}