import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormatPipe',
})
export class dateFormatPipe implements PipeTransform {
    timeZone: string;
    transform(value: string) {
       if(value !== undefined && value !==""){
        let newDateUpdate = new Date(value) 

        return newDateUpdate;
       }
    }
}