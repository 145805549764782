import { SnotifyService } from 'ng-snotify';
import { SharedDataService, ReportFilter } from 'src/app/Service/CommonUtility.service';
import { Component, OnInit, OnDestroy, AfterContentInit, AfterViewInit ,Input} from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { DashboardKPIListingEntity } from 'src/app/Dashboard/dashboard.model';
import { reportFilterTemplate } from '../CommonFilter/commonFilter.model';

@Component({
    selector: 'app-dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.css']
})
export class DashboardFilterComponent implements OnInit, OnDestroy {



    @Input('CustomerID') CustomerID : any;
    @Input('LocationID') LocationID : any;

    dialogOpened: boolean = false;
    public InstallDaterange = { start: null, end: null };
    public Daterange = { start: null, end: null };
    CapMin = 0;
    CapStep = 10;
    CapMax = 999;
    CapRangeCheckBox;
    templateFilterDetails: any = [];
    templateFilterId: any;
    State: string;
    CapRange: any = new Array<any>();
    displayError: any;
    LanguageConfig: any;
    ErrorMsgData: any;

    //oninput change you need to do
    rangeChanged(event: any) {
        //    this.CapRange=[...this.CapRange];
    }

    reportFilter: ReportFilter = new ReportFilter();

    //Output Models
    LocationIDModel: any;
    CustomerIDModel: any;

    DashboardKPIListing: DashboardKPIListingEntity = new DashboardKPIListingEntity();
    reportFilterTemplate: reportFilterTemplate = new reportFilterTemplate();

    StatusModel: any[] = new Array<any>();
    CriticalityModel: any[] = new Array<any>();
    OwnershipModel: any[] = new Array<any>();
    AgeGroupModel: any[] = new Array<any>();
    FilterTypeModel: any[] = new Array<any>();
    ReplacementCostModel: any[] = new Array<any>();
    ConditionRatingModel: any[] = new Array<any>();
    AssetGroupModel: any[] = new Array<any>();
    ReplacementYearModel: any[] = new Array<any>();
    RemaningLifeModel: any[] = new Array<any>();
    RefrigerantModel: any[] = new Array<any>();
    AssetTypeModel: any[] = new Array<any>();
    ServiceCallModel: any[] = new Array<any>();
    SortByModel: number = 1;
    ohsCommentModel: number = 2;
    TotalSpendModel: any[] = new Array<any>();
    //Age group
    ddlAgeGroup = [
        { ageGroupID: '<1', ageGroupName: '<1' },
        { ageGroupID: '1-5', ageGroupName: '1-5' },
        { ageGroupID: '6-10', ageGroupName: '6-10' },
        { ageGroupID: '11-15', ageGroupName: '11-15' },
        { ageGroupID: '16-20', ageGroupName: '16-20' },
        { ageGroupID: '21-25', ageGroupName: '21-25' },
        { ageGroupID: '25+', ageGroupName: '25+' }];


    // ServiceCalls
    public ddlServiceCall = [
        { serviceCallID: '0', serviceCallName: '0' },
        { serviceCallID: '1-2', serviceCallName: '1-2' },
        { serviceCallID: '3-5', serviceCallName: '3-5' },
        { serviceCallID: '6+', serviceCallName: '6+' }];
    // Remaining life
    ddlRemaningLife = [
        { remaningLifeID: '<1', remaningLifeValue: '<1' },
        { remaningLifeID: '1-5', remaningLifeValue: '1-5' },
        { remaningLifeID: '6-10', remaningLifeValue: '6-10' },
        { remaningLifeID: '11-15', remaningLifeValue: '11-15' },
        { remaningLifeID: '16-20', remaningLifeValue: '16-20' },
        { remaningLifeID: '21-25', remaningLifeValue: '21-25' },
        { remaningLifeID: '25+', remaningLifeValue: '25+' }];



    // ReplacementCost
    public ddlReplacementCost = [
        { replacementCostID: '0', replacementCostValue: 'Equal to Zero' },

        { replacementCostID: '<5000', replacementCostValue: 'Under 5k' },
        { replacementCostID: '5000-10000', replacementCostValue: '>5k to <10k' },
        { replacementCostID: '10000-20000', replacementCostValue: '>10k to <20k' },
        { replacementCostID: '20000-30000', replacementCostValue: '>20k to <30k' },
        { replacementCostID: '30000-50000', replacementCostValue: '>30k to <50k' },
        { replacementCostID: '>50000', replacementCostValue: '>50k' }];


    //Replacement Year
    public ddlReplacementYear: Array<{ replacementYearName: string, replacementYearID: string }> = [
        { replacementYearName: "year 1", replacementYearID: 'year 1' },
        { replacementYearName: "year 2", replacementYearID: 'year 2' },
        { replacementYearName: "year 3", replacementYearID: 'year 3' },
        { replacementYearName: "year 4", replacementYearID: 'year 4' },
        { replacementYearName: "year 5", replacementYearID: 'year 5' },
        { replacementYearName: "year 6", replacementYearID: 'year 6' },
        { replacementYearName: "year 7", replacementYearID: 'year 7' },
        { replacementYearName: "year 8", replacementYearID: 'year 8' },
        { replacementYearName: "year 9", replacementYearID: 'year 9' },
        { replacementYearName: "year 10", replacementYearID: 'year 10' },
        { replacementYearName: "year 10+", replacementYearID: 'year 10+' }
    ];
    // short By
    public ddlSortBy = [{ sortByID: 1, sortByName: 'Default' },
    { sortByID: 2, sortByName: 'Asset Type' },
    { sortByID: 3, sortByName: 'Area Served' },
    { sortByID: 4, sortByName: 'Area Located' }];
    // OHS comments
    public ddlOHSComment = [{ ohsCommentID: 2, ohsCommentName: 'All' },
    { ohsCommentID: 0, ohsCommentName: 'No' },
    { ohsCommentID: 1, ohsCommentName: 'Yes' }];

    // Total Spend
     
      public ddlTotolSpend = [
        { totalSpendID: '0', totalSpendValue: 'Equal to Zero' },
        { totalSpendID: '<2000', totalSpendValue: 'Under 2k' },
        { totalSpendID: '2000-5000', totalSpendValue: '>2k to <5k' },
        { totalSpendID: '5000-10000', totalSpendValue: '>5k to <10k' },
        { totalSpendID: '10000-20000', totalSpendValue: '>10k to <20k' },      
        { totalSpendID: '>20000', totalSpendValue: '>20k' }];


    public ddlConditionRating: Array<{ codeValue: string, codeID: number }> = [];
    public ddlStatus: Array<{ codeValue: string, codeID: number }> = [];
    public ddlIndoorOutdoor: Array<{ codeValue: string, codeID: number }> = [];
    public ddlDampersFitted: Array<{ codeValue: string, codeID: number }> = [];
    public ddlFilterType: Array<{ codeValue: string, codeID: number }> = [];
    public ddlFireDoorFRLRating: Array<{ codeValue: string, codeID: number }> = [];
    public ddlMfg: Array<{ codeValue: string, codeID: number }> = [];
    public ddlAssetSize: Array<{ codeValue: string, codeID: number }> = [];
    public ddlCriticality: Array<{ codeValue: string, codeID: number }> = [];
    public ddlOwnership: Array<{ codeValue: string, codeID: number }> = [];
    public ddlRefrigerant: Array<{ codeValue: string, codeID: number }> = [];
    public ddlEssentialService: Array<{ codeValue: string, codeID: number }> = [];
    public ddlAssetGroupName: Array<{ groupName: string, assetGroupID: number }> = [];
    public ddlAssetType: Array<{ assetTypeName: string, assetTypeID: number }>
    public CustomerDropdown: Array<{ customerName: string, customerID: number }> = [];
    public LocationDropdown: Array<{ name: string, locationID: any }> = [];
    public FilteredLocationDropdown: Array<{ name: string, locationID: any }> = [];

    Dateformat: any;
    Counter : any;

    ngOnInit() {


        if (this.sharedData.GetLanguageLiteral() != '' && this.sharedData.GetLanguageLiteral() != null) {

            this.sharedData.languageConfig$.subscribe(res => {
                if (res != undefined) {
                    this.LanguageConfig = res[29];
                    this.ErrorMsgData = res[28]
                }
            })
        }
        this.CapRange.push(0);
        this.CapRange.push(999);
        this.getCustomerbyName('');
        this.getLocationbyName('');
        this.getCodeData();
        this.getAssetGroupData();
        this.getAssetTypeData();
        this.getReportFilterTemplate();
        this.sharedData.GetOrgSettings().then((res) => {
            if (res != null) {
                this.Dateformat = res['DateFormat'];
            }
        })

        
        this.sharedData.ReportFilterBeh$.subscribe(ob => {

            if (ob != undefined) {

                if (ob.conditionRating != "" && ob.conditionRating != undefined && ob.conditionRating != "NaN") {
                    this.ConditionRatingModel.push(parseInt(ob.conditionRating));
                }
                if (ob.customerID != undefined && ob.customerID != NaN) {
                    this.CustomerID = ob.customerID
                    this.Counter = 0
                    this.Counter++ 

                }
                if (ob.locationID != undefined) {
                    this.LocationID = ob.locationID
                }
                if (ob.status != "" && ob.status != undefined && ob.status != "NaN") {
                    this.StatusModel.push(parseInt(ob.status))
                }
                if (ob.assetGroup != "" && ob.assetGroup != undefined && ob.assetGroup != "NaN") {
                    this.AssetGroupModel.push(ob.assetGroup)
                }
                if (ob.criticality != "" && ob.criticality != undefined && ob.criticality != "NaN") {
                    this.CriticalityModel.push(parseInt(ob.criticality))
                }
                if (ob.assetOwnership != "" && ob.assetOwnership != undefined && ob.assetOwnership != "NaN") {
                    this.OwnershipModel.push(parseInt(ob.assetOwnership))
                }
                if (ob.ageGroup != "" && ob.ageGroup != undefined && ob.ageGroup != "NaN") {
                    this.AgeGroupModel.push(ob.ageGroup)
                }
                if (ob.filtersFitted != "" && ob.filtersFitted != undefined && ob.filtersFitted != "NaN") {
                    this.FilterTypeModel.push(parseInt(ob.filtersFitted))
                }
                if (ob.replacementYear != "" && ob.replacementYear != undefined && ob.replacementYear != "NaN") {
                    // this.ReplacementYearModel = [];
                    this.ReplacementYearModel.push(ob.replacementYear)
                }
                if (ob.replacementCost != "" && ob.replacementCost != undefined && ob.replacementCost != "NaN") {
                    this.ReplacementCostModel.push(ob.replacementCost)
                }
                if (ob.totalSpend != "" && ob.totalSpend != undefined && ob.totalSpend != "NaN") {
                    this.TotalSpendModel.push(ob.totalSpend)
                }
                if (ob.remainingLife != "" && ob.remainingLife != undefined && ob.remainingLife != "NaN") {
                    this.RemaningLifeModel.push(ob.remainingLife)
                }
                if (ob.refrigerant != "" && ob.refrigerant != undefined && ob.refrigerant != "NaN") {
                    this.RefrigerantModel.push(parseInt(ob.refrigerant))
                }
                if (ob.assetTypeID != "" && ob.assetTypeID != undefined && ob.assetTypeID != "NaN") {
                    this.AssetTypeModel.push(parseInt(ob.assetTypeID))
                }
                if (ob.OHSComments != undefined && ob.OHSComments != NaN) {
                    this.ohsCommentModel = ob.OHSComments
                }
                if (ob.serviceCalls != "" && ob.serviceCalls != undefined && ob.serviceCalls != "NaN") {
                    this.ServiceCallModel.push(parseInt(ob.serviceCalls))
                }
                if (ob.capacityStart != undefined) {
                    this.CapRange = [];
                    if (ob.capacityStart.toString() != "" && ob.capacityStart.toString() != "") {

                        this.CapRange.push(ob.capacityStart);
                        this.CapRange.push(ob.capacityEnd);
                    }
                    else {
                        this.CapRange.push(0);
                        this.CapRange.push(999);
                    }
                }

                if (ob.capacityStart == undefined && ob.capacityEnd == undefined) {
                    this.CapRangeCheckBox = false;
                }
                else if (ob.capacityStart != undefined && ob.capacityEnd != undefined) {
                    this.CapRangeCheckBox = true;

                }

                if (ob.installStartDate != "" && ob.installStartDate != undefined) {
                    this.InstallDaterange.start = new Date(ob.installStartDate)
                    this.InstallDaterange.end = new Date(ob.installEndDate)
                }
                if (ob.startDate != "" && ob.startDate != undefined) {
                    this.Daterange.start = new Date(ob.startDate)
                }
                if (ob.endDate != "" && ob.endDate != undefined) {
                    this.Daterange.end = new Date(ob.endDate)
                }

            }

        })

    }


    functionReturningString(value: any) {
        if (value == 0) {
            return 0;
        }
        else {
            // for testing
            //value = Math.floor(Math.random()*1001);

            // hundreds
            if (value <= 999) {
                return Number.parseFloat(value.toString()).toFixed(1);
            }
            // thousands
            else if (value >= 1000 && value <= 999999) {
                return Number.parseFloat((value / 1000).toString()).toFixed(1).toString() + 'K';
            }
            // millions
            else if (value >= 1000000 && value <= 999999999) {
                return Number.parseFloat((value / 1000000).toString()).toFixed(1).toString() + 'M';
            }
            // billions
            else if (value >= 1000000000 && value <= 999999999999) {
                return Number.parseFloat((value / 1000000000).toString()).toFixed(1).toString() + 'B';
            }
            else
                return value;
        }

    }
    // CustomerfilterChange(filterData: any): void {
    //     if (filterData.length >= 3) {
    //         this.getCustomerbyName(filterData);
    //         this.sharedData.ShowDefaultFields$.next('true')
    //     }
    // }


    // SelectedCustomer(val) {
    //     this.LocationIDModel = null;
    //     this.LocationDropdown = [];
    //     this.FilteredLocationDropdown = [];
    //     this.getLocationbyName(this.CustomerIDModel);

    // }
    // locationFilterChange(value) {
    //     this.FilteredLocationDropdown = [];
    //     if (value.length >= 3) {
    //         this.FilteredLocationDropdown = this.LocationDropdown.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    //     } else {
    //         this.FilteredLocationDropdown = this.LocationDropdown;
    //     }
    // }

    constructor(private notify: SnotifyService, private coreDataService: CoreDataService, private sharedData: SharedDataService) { }

    public show: boolean = false;
    public onToggle(): void {
        this.show = !this.show;
    }

    getCodeData() {
        this.coreDataService.getCodeData().subscribe((res) => {
            if (res != undefined && res != null) {
                res.forEach(element => {
                    if (element.codeName.toLowerCase() == 'conditionratingcd') {
                        this.ddlConditionRating.push(element);
                    }

                    else if (element.codeName.toLowerCase() == 'mfgcd') {
                        this.ddlMfg.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'assetstatuscd') {
                        this.ddlStatus.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'filtertypecd') {
                        this.ddlFilterType.push(element);
                    }

                    else if (element.codeName.toLowerCase() == 'firedoorfrlratingcd') {
                        this.ddlFireDoorFRLRating.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'assetsizecd') {
                        this.ddlAssetSize.push(element)
                    }
                    else if (element.codeName.toLowerCase() == 'criticalitycd') {
                        this.ddlCriticality.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'assetownershipcd') {
                        this.ddlOwnership.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'replacementyear') {
                        this.ddlReplacementYear.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'refrigerantcd') {
                        this.ddlRefrigerant.push(element);
                    }
                    else if (element.codeName.toLowerCase() == 'essentialservicecd') {
                        this.ddlEssentialService.push(element);
                    }
                })
            }
        })
    }

    getAssetGroupData() {
        this.coreDataService.GetAssetGroupDropdown().subscribe((res) => {
            if (res != undefined && res != null) {
                res.forEach(element => {
                    this.ddlAssetGroupName.push(element);
                })
            }
        })
    }
    getAssetTypeData() {
        this.coreDataService.getAssetTypeByNameDropDownUrl('').subscribe((res) => {
            if (res != undefined && res != null) {
                this.ddlAssetType = res;
            }
        })
    }
    getCustomerbyName(searchtext) {
        this.coreDataService.getCustomerByName(searchtext).subscribe((res) => {
            if (res != undefined && res != null) {
                this.CustomerDropdown = res;

            }
        })
    }
    getLocationbyName(customerID) {
        this.coreDataService.getUserLocationByCustomerDropdown('', customerID).subscribe((res) => {
            if (res != undefined && res != null) {

                this.LocationDropdown = res;
                this.FilteredLocationDropdown = this.LocationDropdown
            }

        })
    }



    ApplyFilter() {

        console.log(this.LocationID)
        console.log(this.CustomerID)
        this.reportFilter.capacityStart = this.CapRange[0] != undefined && this.CapRangeCheckBox == true ? this.CapRange[0] : undefined
        this.reportFilter.capacityEnd = this.CapRange[1] != undefined && this.CapRangeCheckBox == true ? this.CapRange[1] : undefined
        this.reportFilter.state = (this.State == null || this.State === undefined || this.State === "") ? undefined : this.State;


        if (this.InstallDaterange != null && this.InstallDaterange != undefined && this.InstallDaterange.start != null && this.InstallDaterange.end != null) {
            this.reportFilter.installStartDate = this.InstallDaterange.start.getFullYear() + '-' + (this.InstallDaterange.start.getMonth() + 1) + '-' + this.InstallDaterange.start.getDate()
            this.reportFilter.installEndDate = this.InstallDaterange.end.getFullYear() + '-' + (this.InstallDaterange.end.getMonth() + 1) + '-' + this.InstallDaterange.end.getDate()
        }
        else {
            this.reportFilter.installStartDate = undefined;
            this.reportFilter.installEndDate = undefined;
        }
        if (this.Daterange != null && this.Daterange != undefined && this.Daterange.start != null && this.Daterange.end != null) {
            this.reportFilter.startDate = this.Daterange.start.getFullYear() + '-' + (this.Daterange.start.getMonth() + 1) + '-' + this.Daterange.start.getDate()
            this.reportFilter.endDate = this.Daterange.end.getFullYear() + '-' + (this.Daterange.end.getMonth() + 1) + '-' + this.Daterange.end.getDate()
        }
        else {
            this.reportFilter.startDate = undefined;
            this.reportFilter.endDate = undefined;
        }


        this.reportFilter.conditionRating = this.ConditionRatingModel.toString() == "NaN" ? "" : this.ConditionRatingModel.toString();;

        this.reportFilter.customerID = this.CustomerID;
        this.reportFilter.locationID = this.LocationID;
        this.reportFilter.status = this.StatusModel.toString() == "NaN" ? "" : this.StatusModel.toString();
        this.reportFilter.assetGroup = this.AssetGroupModel.toString() == "NaN" ? "" : this.AssetGroupModel.toString();
        this.reportFilter.criticality = this.CriticalityModel.toString() == "NaN" ? "" : this.CriticalityModel.toString();
        this.reportFilter.assetOwnership = this.OwnershipModel.toString() == "NaN" ? "" : this.OwnershipModel.toString();
        this.reportFilter.ageGroup = this.AgeGroupModel.toString() == "NaN" ? "" : this.AgeGroupModel.toString();
        this.reportFilter.filtersFitted = this.FilterTypeModel.toString() == "NaN" ? "" : this.FilterTypeModel.toString();
        this.reportFilter.replacementYear = this.ReplacementYearModel.toString() == "NaN" ? "" : this.ReplacementYearModel.toString();
        this.reportFilter.replacementCost = this.ReplacementCostModel.toString() == "NaN" ? "" : this.ReplacementCostModel.toString();
        this.reportFilter.totalSpend = this.TotalSpendModel.toString() == "NaN" ? "" : this.TotalSpendModel.toString();
        this.reportFilter.remainingLife = this.RemaningLifeModel.toString() == "NaN" ? "" : this.RemaningLifeModel.toString();
        this.reportFilter.refrigerant = this.RefrigerantModel.toString() == "NaN" ? "" : this.RefrigerantModel.toString();

        this.reportFilter.assetTypeID = this.AssetTypeModel.toString() == "NaN" ? "" : this.AssetTypeModel.toString();
        this.reportFilter.OHSComments = this.ohsCommentModel;
        this.reportFilter.serviceCalls = this.ServiceCallModel.toString() == "NaN" ? "" : this.ServiceCallModel.toString();
        this.reportFilter.sortBy = this.SortByModel.toString() == "NaN" ? "" : this.SortByModel.toString();
        //  this.getDashboardKPI(this.reportFilter)
        this.sharedData.ReportFilterBeh$.next(this.reportFilter);
        this.show = false;
    }

   

    resetModels() {
        this.StatusModel = new Array<any>();
        this.CriticalityModel = new Array<any>();
        this.OwnershipModel = new Array<any>();
        this.AgeGroupModel = new Array<any>();
        this.FilterTypeModel = new Array<any>();
        this.ReplacementCostModel = new Array<any>();
        this.TotalSpendModel = new Array<any>();
        this.ConditionRatingModel = new Array<any>();
        this.AssetGroupModel = new Array<any>();
        this.ReplacementYearModel = new Array<any>();
        this.RemaningLifeModel = new Array<any>();
        this.RefrigerantModel = new Array<any>();
        this.AssetTypeModel = new Array<any>();
        this.ServiceCallModel = new Array<any>();
        this.InstallDaterange.start = null;
        this.InstallDaterange.end = null;
        this.Daterange.start = null;
        this.Daterange.end = null;
        this.CapRange = [0, 999];
        this.CapRangeCheckBox = false
        this.State = null
        this.SortByModel = 1;
        this.ohsCommentModel = 2;
        this.reportFilter.capacityStart = undefined
        this.reportFilter.capacityEnd = undefined
        this.reportFilter.customerID = null;
        this.reportFilter.locationID = null;
        this.CustomerIDModel = "";
        this.LocationIDModel = "";
        this.reportFilter = new ReportFilter()
        this.sharedData.ReportFilterBeh$.next(this.reportFilter);

    }
    ConditionRatingSelectionChange(val) {
        var IsExist = this.ConditionRatingModel.find((obj) => { return obj == val.dataItem.codeID; })
        if (IsExist != undefined) {
            let ind = this.ConditionRatingModel.indexOf(IsExist);
            this.ConditionRatingModel.splice(ind, 1);
        } else {
            this.ConditionRatingModel.push(val.dataItem.codeID);
        }

    }
    StatusModelSelectionChange(val) {
        var IsExist = this.StatusModel.find((obj) => { return obj == val.dataItem.codeID; })
        if (IsExist != undefined) {
            let ind = this.StatusModel.indexOf(IsExist);
            this.StatusModel.splice(ind, 1);
        } else {
            this.StatusModel.push(val.dataItem.codeID);
        }

    }
    CriticalityModelSelectionChange(val) {
        var IsExist = this.CriticalityModel.find((obj) => { return obj == val.dataItem.codeID; })
        if (IsExist != undefined) {
            let ind = this.CriticalityModel.indexOf(IsExist);
            this.CriticalityModel.splice(ind, 1);
        } else {
            this.CriticalityModel.push(val.dataItem.codeID);
        }

    }
    OwnershipModelSelectionChange(val) {
        var IsExist = this.OwnershipModel.find((obj) => { return obj == val.dataItem.codeID; })
        if (IsExist != undefined) {
            let ind = this.OwnershipModel.indexOf(IsExist);
            this.OwnershipModel.splice(ind, 1);
        } else {
            this.OwnershipModel.push(val.dataItem.codeID);
        }

    }
    FilterTypeModelSelectionChange(val) {
        var IsExist = this.FilterTypeModel.find((obj) => { return obj == val.dataItem.codeID; })
        if (IsExist != undefined) {
            let ind = this.FilterTypeModel.indexOf(IsExist);
            this.FilterTypeModel.splice(ind, 1);
        } else {
            this.FilterTypeModel.push(val.dataItem.codeID);
        }

    }
    ReplacementCostModelSelectionChange(val) {
        var IsExist = this.ReplacementCostModel.find((obj) => { return obj == val.dataItem.replacementCostID; })
        if (IsExist != undefined) {
            let ind = this.ReplacementCostModel.indexOf(IsExist);
            this.ReplacementCostModel.splice(ind, 1);
        } else {
            this.ReplacementCostModel.push(val.dataItem.replacementCostID);
        }

    }
    TotalSpendModelSelectionChange(val) {
        var IsExist = this.TotalSpendModel.find((obj) => { return obj == val.dataItem.totalSpendID; })
        if (IsExist != undefined) {
            let ind = this.TotalSpendModel.indexOf(IsExist);
            this.TotalSpendModel.splice(ind, 1);
        } else {
            this.TotalSpendModel.push(val.dataItem.totalSpendID);
        }

    }
    AgeGroupModelSelectionChange(val) {
        var IsExist = this.AgeGroupModel.find((obj) => { return obj == val.dataItem.ageGroupID; })
        if (IsExist != undefined) {
            let ind = this.AgeGroupModel.indexOf(IsExist);
            this.AgeGroupModel.splice(ind, 1);
        } else {
            this.AgeGroupModel.push(val.dataItem.ageGroupID);
        }

    }
    RemaningLifeModelSelectionChange(val) {
        var IsExist = this.RemaningLifeModel.find((obj) => { return obj == val.dataItem.remaningLifeID; })
        if (IsExist != undefined) {
            let ind = this.RemaningLifeModel.indexOf(IsExist);
            this.RemaningLifeModel.splice(ind, 1);
        } else {
            this.RemaningLifeModel.push(val.dataItem.remaningLifeID);
        }

    }
    ServiceCallModelSelectionChange(val) {
        var IsExist = this.ServiceCallModel.find((obj) => { return obj == val.dataItem.serviceCallID; })
        if (IsExist != undefined) {
            let ind = this.ServiceCallModel.indexOf(IsExist);
            this.ServiceCallModel.splice(ind, 1);
        } else {
            this.ServiceCallModel.push(val.dataItem.serviceCallID);
        }
    }

    display: boolean = false;
    TemplateOpen() {
        this.display = true;
    }

    public TemplateClose(status) {
        this.display = false;
    }
    SelectedTemplate(dataItem) {
        let SelectedFieldArray = JSON.parse(dataItem.fieldTemplate);
        this.reportFilter.customerID = SelectedFieldArray.customerID;
        this.reportFilter.locationID = SelectedFieldArray.locationID;

        if (SelectedFieldArray.conditionRating != undefined && SelectedFieldArray.conditionRating != "") {
            this.ConditionRatingModel = [];
            SelectedFieldArray.conditionRating.split(',').forEach(element => {
                this.ConditionRatingModel.push(Number.parseInt(element));
            });
        }

        if (SelectedFieldArray.customerID != undefined && SelectedFieldArray.customerID != NaN) {
            this.CustomerIDModel = null;
            this.CustomerIDModel = SelectedFieldArray.customerID

        }
        if (SelectedFieldArray.locationID != undefined && SelectedFieldArray.locationID != NaN) {
            this.LocationIDModel = null;
            this.LocationIDModel = SelectedFieldArray.locationID
        }
        if (SelectedFieldArray.status != "" && SelectedFieldArray.status != undefined && SelectedFieldArray.status != "NaN") {
            this.StatusModel = [];
            SelectedFieldArray.status.split(',').forEach(ele => {
                this.StatusModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.assetGroup != "" && SelectedFieldArray.assetGroup != undefined && SelectedFieldArray.assetGroup != "NaN") {
            this.AssetGroupModel = [];
            SelectedFieldArray.assetGroup.split(',').forEach(ele => {
                this.AssetGroupModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.criticality != "" && SelectedFieldArray.criticality != undefined && SelectedFieldArray.criticality != "NaN") {
            this.CriticalityModel = [];
            SelectedFieldArray.criticality.split(',').forEach(ele => {
                this.CriticalityModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.assetOwnership != "" && SelectedFieldArray.assetOwnership != undefined && SelectedFieldArray.assetOwnership != "NaN") {
            this.OwnershipModel = [];
            SelectedFieldArray.assetOwnership.split(',').forEach(ele => {
                this.OwnershipModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.ageGroup != "" && SelectedFieldArray.ageGroup != undefined && SelectedFieldArray.ageGroup != "NaN") {
            this.AgeGroupModel = [];
            SelectedFieldArray.ageGroup.split(',').forEach(ele => {
                this.AgeGroupModel.push(ele)
            })
        }
        if (SelectedFieldArray.filtersFitted != "" && SelectedFieldArray.filtersFitted != undefined && SelectedFieldArray.filtersFitted != "NaN") {
            this.FilterTypeModel = [];
            SelectedFieldArray.filtersFitted.split(',').forEach(ele => {
                this.FilterTypeModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.replacementYear != "" && SelectedFieldArray.replacementYear != undefined && SelectedFieldArray.replacementYear != "NaN") {
            this.ReplacementYearModel = [];
            SelectedFieldArray.replacementYear.split(',').forEach(ele => {
                this.ReplacementYearModel.push(ele)
            })
        }
        if (SelectedFieldArray.replacementCost != "" && SelectedFieldArray.replacementCost != undefined && SelectedFieldArray.replacementCost != "NaN") {
            this.ReplacementCostModel = [];
            SelectedFieldArray.replacementCost.split(',').forEach(ele => {
                this.ReplacementCostModel.push(ele)
            })
        }
        if (SelectedFieldArray.totalSpend != "" && SelectedFieldArray.totalSpend != undefined && SelectedFieldArray.totalSpend != "NaN") {
            this.TotalSpendModel = [];
            SelectedFieldArray.replacementCost.split(',').forEach(ele => {
                this.TotalSpendModel.push(ele)
            })
        }
        if (SelectedFieldArray.remainingLife != "" && SelectedFieldArray.remainingLife != undefined && SelectedFieldArray.remainingLife != "NaN") {
            this.RemaningLifeModel = [];
            SelectedFieldArray.remainingLife.split(',').forEach(ele => {
                this.RemaningLifeModel.push(ele)
            })
        }
        if (SelectedFieldArray.refrigerant != "" && SelectedFieldArray.refrigerant != undefined && SelectedFieldArray.refrigerant != "NaN") {
            this.RefrigerantModel = [];
            SelectedFieldArray.refrigerant.split(',').forEach(ele => {
                this.RefrigerantModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.assetTypeID != "" && SelectedFieldArray.assetTypeID != undefined && SelectedFieldArray.assetTypeID != "NaN") {
            this.AssetTypeModel = [];
            SelectedFieldArray.assetTypeID.split(',').forEach(ele => {
                this.AssetTypeModel.push(Number.parseInt(ele))
            })
        }
        if (SelectedFieldArray.OHSComments != undefined && SelectedFieldArray.OHSComments != NaN) {
            this.ohsCommentModel = null;
            this.ohsCommentModel = SelectedFieldArray.OHSComments

        }
        if (SelectedFieldArray.serviceCalls != "" && SelectedFieldArray.serviceCalls != undefined && SelectedFieldArray.serviceCalls != "NaN") {
            this.ServiceCallModel = [];
            SelectedFieldArray.serviceCalls.split(',').forEach(ele => {
                this.ServiceCallModel.push(ele)
            })
        }
        if (SelectedFieldArray.sortBy != undefined && SelectedFieldArray.sortBy != NaN) {
            this.SortByModel = null;
            this.SortByModel = Number.parseInt(SelectedFieldArray.sortBy)
        }
        if (SelectedFieldArray.capacityStart != undefined) {
            this.CapRange = [];
            if (SelectedFieldArray.capacityStart.toString() != "" && SelectedFieldArray.capacityStart.toString() != "") {

                this.CapRange.push(SelectedFieldArray.capacityStart);
                this.CapRange.push(SelectedFieldArray.capacityEnd);
            }
            else {
                this.CapRange.push(0);
                this.CapRange.push(999);
            }
        }

        if (SelectedFieldArray.capacityStart == undefined && SelectedFieldArray.capacityEnd == undefined) {
            this.CapRangeCheckBox = false;
        }
        else if (SelectedFieldArray.capacityStart != undefined && SelectedFieldArray.capacityEnd != undefined) {
            this.CapRangeCheckBox = true;

        }

        if (SelectedFieldArray.installStartDate != "" && SelectedFieldArray.installStartDate != undefined) {
            this.InstallDaterange.start = new Date(SelectedFieldArray.installStartDate)
            this.InstallDaterange.end = new Date(SelectedFieldArray.installEndDate)
        }
        if (SelectedFieldArray.startDate != "" && SelectedFieldArray.startDate != undefined) {
            this.Daterange.start = new Date(SelectedFieldArray.startDate)
        }
        if (SelectedFieldArray.endDate != "" && SelectedFieldArray.endDate != undefined) {
            this.Daterange.end = new Date(SelectedFieldArray.endDate)
        }
    }

    getReportFilterTemplate() {
        this.coreDataService.getAssetReportFilter().subscribe(res => {
            this.templateFilterDetails = res;
        })
    }

    deleteDialog(id) {
        this.dialogOpened = true;
        this.templateFilterId = id;
    }
    deleteTemplate() {
        this.coreDataService.DeleteReportTemplate(this.templateFilterId)
            .subscribe(res => {
                if (res != undefined) {
                    this.templateFilterDetails.forEach((ele, index) => {
                        if (ele.assetReportFilterConfigID == this.templateFilterId) {
                            this.templateFilterDetails.splice(index, 1);
                        }
                    })
                    this.notify.success(this.ErrorMsgData.recordDelete);
                    this.dialogOpened = false;
                    this.show = false;
                }
            })
        this.resetModels();
    }

    templateTitle: any;

    saveFilterTemplate() {
        this.reportFilter.customerID = this.CustomerIDModel == null ? "" : this.CustomerIDModel;
        this.reportFilter.locationID = this.LocationIDModel == null ? "" : this.LocationIDModel;
        this.reportFilter.startDate = this.Daterange.start;
        this.reportFilter.endDate = this.Daterange.end;
        this.reportFilter.conditionRating = this.ConditionRatingModel.toString();
        this.reportFilter.status = this.StatusModel.toString();
        this.reportFilter.criticality = this.CriticalityModel.toString();
        this.reportFilter.assetOwnership = this.OwnershipModel.toString();
        this.reportFilter.filtersFitted = this.FilterTypeModel.toString();
        this.reportFilter.refrigerant = this.RefrigerantModel.toString();
        this.reportFilter.capacityStart = this.CapRange[0];
        this.reportFilter.capacityEnd = this.CapRange[1];
        this.reportFilter.assetGroup = this.AssetGroupModel.toString();
        this.reportFilter.assetTypeID = this.AssetTypeModel.toString();
        this.reportFilter.replacementCost = this.ReplacementCostModel.toString();
        this.reportFilter.ageGroup = this.AgeGroupModel.toString();
        this.reportFilter.remainingLife = this.RemaningLifeModel.toString();
        this.reportFilter.replacementYear = this.ReplacementYearModel.toString();
        this.reportFilter.installStartDate = this.InstallDaterange.start;
        this.reportFilter.installEndDate = this.InstallDaterange.end;
        this.reportFilter.OHSComments = this.ohsCommentModel;
        this.reportFilter.serviceCalls = this.ServiceCallModel.toString();
        this.reportFilter.sortBy = this.SortByModel.toString();
        this.reportFilterTemplate.FieldTemplate = JSON.stringify(this.reportFilter);
        this.reportFilterTemplate.FilterTemplateName = this.templateTitle;
        this.coreDataService.saveAssetReport(this.reportFilterTemplate).subscribe(async (res) => {
            if (res == 1) {
                await this.resetModels();
                await this.getReportFilterTemplate();
            }

        })
        this.show = false;

    }

    close() {
        this.dialogOpened = false;
    }
    ngOnDestroy() {
        //this.resetModels()
    }

}
