
import { Component, OnInit, ElementRef, NgZone, ViewChild, DoCheck } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoreDataService } from "../../Service/core-data.service";
import { MapsAPILoader } from '@agm/core';
import { Router } from "@angular/router";
import { SnotifyService } from "ng-snotify";
declare var google: any;
import { ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';

import { SharedDataService } from "../../Service/CommonUtility.service";

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit {
  documentFormat: Array<string> = ['.jpg', '.png'];

  temp: string;
  @ViewChild('form',{static:false}) formvariable: NgForm;
  LanguageConfig: any;
  GridConfig: any
  latitude = -25.274399;
  longitude = 133.775131;
  zoom = 4;
  locationChoosen = true;
  formGroup: FormGroup;
  private editedRowIndex: number;
  ErrorMsgData: any;

  @ViewChild("search",{static:false})
  public searchElementRef: ElementRef;
  constructor(private notify: SnotifyService, private sharedService: SharedDataService, private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private coredataservice: CoreDataService
  ) { }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // public removeHandler({ dataItem }) {
  //   this.editiconservice.remove(dataItem);
  // }

  public cancelHandler({ sender, rowIndex }) {


    this.closeEditor(sender, rowIndex);
  }
  markerDragEnd(event) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getGeoLocation(this.latitude, this.longitude);

  }




  CompanyNameBlur(event) {

    if (event.target.value != "" && event.target.value != "") {
      this.coredataservice.ValidateCompanyName(undefined, event.target.value).subscribe(res => {

        if (res != null && res != undefined && res.length > 0) {
          this.formGroup.controls['Name'].setValue(null);
          this.notify.error(this.ErrorMsgData.companyExist);
        }

      })

    }
  }
  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      let request = { latLng: latlng };

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let result = results[0];
          if (result != null) {
            this.searchElementRef.nativeElement.value = result.formatted_address;
            this.formGroup.value.Address = this.searchElementRef.nativeElement.value;
          }
        }
      });
    }
  }
  ngOnInit() {

    if (this.sharedService.GetLanguageLiteral() != '' && this.sharedService.GetLanguageLiteral() != null) {
      this.sharedService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[11];
          this.ErrorMsgData = res[28]
        }
      })
    }
    this.formGroup = new FormGroup({
      Name: new FormControl('', [
        Validators.required,
        //  Validators.minLength(8),
        Validators.maxLength(100)
      ]),
      Address: new FormControl('', [
        Validators.required,
        // Validators.minLength(8),
        Validators.maxLength(150)
      ]),
      email: new FormControl('', [
        Validators.maxLength(100),
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
        // Validators.minLength(8),
      ]),
      evaluate_champion: new FormControl('', [
        // Validators.minLength(8),
        Validators.maxLength(50)
      ]),
      comments: new FormControl('', [
        // Validators.required
        // Validators.minLength(8),
        Validators.maxLength(500)
      ]),
      CompanyBanner: new FormControl('', [

      ])

      //  active
      // AssetTypeMaster: new FormArray([new FormControl('')])
    });
    this.temp = this.route.snapshot.params["name"];
    this.setCurrentPosition();
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          var place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  getApiData() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          var place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  submitCompanyDetail() {
    this.formGroup.value.Address = this.searchElementRef.nativeElement.value;
    if (this.formGroup.valid) {
      let geolocation: string = this.latitude.toString() + "," + this.longitude.toString()
      let filterData = {
        "CompanyID": null,
        "Address": this.formGroup.value.Address,
        "Name": this.formGroup.value.Name,
        "GeoLocation": geolocation,
        "Active": 1,
        "Email": this.formGroup.value.email,
        "EvaluateChampion": this.formGroup.value.evaluate_champion,
        "comments": this.formGroup.value.comments,
        "companyBanner": this.IsUpadated == true ? this.convertDataURIToBinary(this.banner) : null
      };

      this.coredataservice.createCompany(filterData).first().subscribe(
        (response) => {
          if (response && response != null) {

            // this.toastrService.success("Company has been created");
            this.notify.success(this.ErrorMsgData.companyCreated);
            this.router.navigate(["/company-management"]);
          }
        }, catchError => {
          if (catchError) {
            this.sharedService.ErrorHandler(catchError);
          }
        });
    }
    else {
      Object.keys(this.formGroup.controls).forEach(field => { // {1}
        const control = this.formGroup.get(field); // {2}

        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });       // {3}
      });
      this.notify.error(this.ErrorMsgData.mandatoryField);
    }

  }


  convertDataURIToBinary(dataURI) {

    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Array<number>(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
  IsUpadated: boolean = false;
  banner: any;



  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file: any) => {

      let File = file
      if (file.size < 8388608) {
        if (this.documentFormat.includes(file.extension)) {
          const reader = new FileReader();

          reader.onload = (ev) => {
            let image = {
              src: ev.target['result'],
              uid: file.uid,
              extension: file.extension,
              IsUpdated: true
            };
            this.formGroup.value.CompanyBanner = image.src;
            this.banner = image.src;
            this.IsUpadated = image.IsUpdated

            var k = document.getElementsByClassName('k-upload-files');
            if (k.length > 0) {
              k[0].remove()
            }
          }
          reader.readAsDataURL(file.rawFile);
        }
        else {
          this.notify.error(this.ErrorMsgData.onlyFormat + this.documentFormat.toString())
          return
        }
      }
      else {
        this.notify.error(this.ErrorMsgData.exceedFileSize)
        var k = document.getElementsByClassName('k-upload-files');
        if (k.length > 0) {
          k[0].remove()
        }
      }
    });
  }

}
