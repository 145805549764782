///<reference types="@types/googlemaps" />
import { SnotifyService } from 'ng-snotify';
import * as MarkerClusterer from "@google/markerclusterer";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreDataService } from '../../Service/core-data.service'
import { SharedDataService } from '../../Service/CommonUtility.service'
import { Router, ActivatedRoute } from '@angular/router';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { LocationEntity } from "./location-management.model";
import { google } from '@google/maps';
import { Title } from '@angular/platform-browser';

import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { environment } from 'src/environments/environment';

declare var google: any;
const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};
@Component({
  selector: 'app-location-management',
  templateUrl: 'location-management.component.html',
  styleUrls: ['location-management.component.scss']
})
export class LocationManagementComponent implements OnInit {
  locationList: Array<any> = [];
  private state: State = {
    skip: 0,
    take: 20,
    sort: [{ field: "active", dir: "desc" }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private state1: State = {
    skip: 0,
    sort: [{ field: 'locationName', dir: 'asc' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  private stateMap: State = {
    skip: 0,
    sort: [{ field: "active", dir: "desc" }],
  };
  public filter: CompositeFilterDescriptor;
  public gridfilter: any[] = filterBy(this.locationList, this.filter);
  private gridData: GridDataResult;
  public sortChange(sort: SortDescriptor[]): void {
    this.gridData = process(orderBy(this.locationList, sort), this.state);
  }
  locationListingTitle: any;
  //--Start : Map MetaData
  map: google.maps.Map;
  value: string;
  markerClusters: Array<MarkerClusterer>;
  mapData: Array<LocationEntity> = [];   //The locations Lat-Long to Draw on Map
  imageUrl = 'https://chart.apis.google.com/chart?cht=mm&chs=24x32&chco=FFFFFF,008CFF,000000&ext=.png';
  oms: OverlappingMarkerSpiderfier;
  private gridDataForMap: GridDataResult;
  //--End : Map MetaData
  IsSysAdmin: boolean;
  UserData: any;
  IsUserCustomer: string
  baseUrl = environment.environment.apiUrl;
  LanguageConfig: any;
  BusinessLanguageConfig:any;

  GridConfig: any
  userAction: string = "Add Location";
  IsDepartmentManagement : boolean
  IsLocationManagement : boolean
  constructor(private titleService: Title,
    private activatedRoute: ActivatedRoute, private notify: SnotifyService, private DataService: CoreDataService, private router: Router, private route: ActivatedRoute, private sharedDataService: SharedDataService) {
    this.locationListingTitle = '<i class="fa fa-th-list" aria-hidden="true">Location Listing</i>';
    let pageTite = this.activatedRoute.snapshot.data['title'];
    this.titleService.setTitle(pageTite);

    if(this.activatedRoute.snapshot.data['IsDepartmentManagement'])
    {
      this.IsDepartmentManagement = this.activatedRoute.snapshot.data['IsDepartmentManagement']
    }
    else{
      this.IsLocationManagement = this.activatedRoute.snapshot.data['IsLocationManagement']
    }
    
  }
  getLocationList(): any {

    
    console.log(this.activatedRoute.snapshot.data['IsDepartment'])


    this.DataService.getLocationManagementList().subscribe((res) => {
      if (res != undefined && res != null) {

        this.locationList = []
        
        if(this.activatedRoute.snapshot.data['IsDepartment'])
        {
          this.locationList = res;
          this.locationList = this.locationList.filter(e=>{return e.isDepartment == true})
        }
        else{
          this.locationList = res;
          this.locationList = this.locationList.filter(e=>{return e.isDepartment == false})
        }

        // this.locationList = this.locationList
        // this.locationList = [... new Set(this.locationList.map(data =>{return data} ))]

        this.mapData = this.locationList;
        this.gridData = process(this.locationList, this.state);
        this.stateMap.filter = this.state.filter;
      this.stateMap.group = this.state.group;
      this.stateMap.skip = this.state.skip;
      this.stateMap.sort = this.state.sort;     
      this.gridDataForMap = process(this.locationList, this.stateMap);      
      this.mapData = this.gridDataForMap.data;
        this.allData = this.allData.bind(this);
      }
    })
  }

  ngOnInit() {
    if (this.sharedDataService.GetLanguageLiteral() != '' && this.sharedDataService.GetLanguageLiteral() != null) {

      this.sharedDataService.languageConfig$.subscribe(res => {
        if (res != undefined) {
          this.LanguageConfig = res[4];
          this.BusinessLanguageConfig=res[58];
          this.GridConfig = res[2]
        }
      })

      var token = window.localStorage.getItem('token');

      this.UserData = this.sharedDataService.parseJwt(token);
    }
    this.value = this.route.snapshot.paramMap.get('name');
    if (this.value != undefined) {
      this.state.filter.filters = [{ field: 'customerName', operator: 'contains', value: this.value.toString() }];
    }

    this.IsSysAdmin = this.sharedDataService.IsSystemAdmin();
    this.IsUserCustomer = this.sharedDataService.IsCustomer();
    this.getLocationList();
  }


  public allData(): ExcelExportData {

    if (this.state.filter.filters.length == 0) {
      const result: ExcelExportData = {
        data: process(this.locationList, { sort: [{ field: 'locationName', dir: 'asc' }] }).data,

      };
      return result;
    }
    else {
      this.state1.filter.filters = this.state.filter.filters;

      const result: ExcelExportData = {

        data: process(this.locationList, this.state1).data
      };
      return result;
    }

  }


  ViewAssets(event) {
    this.router.navigate(["/assets-listing", { name: event.locationName }]);

  }


  MenuOnSelect(event) {


    if(this.activatedRoute.snapshot.data['IsDepartmentManagement']){
      // var baseUrl = window.location.origin + '/#/add-department/' + event.dataItem.locationID;
      var baseUrl = window.location.origin + '/add-department/' + event.dataItem.locationID;
    }
    else
    {
      // var baseUrl = window.location.origin + '/#/add-location/' + event.dataItem.locationID;
      var baseUrl = window.location.origin + '/add-location/' + event.dataItem.locationID;
    }
    

    if (event.item == "Open") {

      window.location.href = baseUrl;
    }
    else if (event.item == "Open in new tab") {
      window.open(baseUrl);
    }
    else if (event.item == "Open in new window") {
      window.open(baseUrl, '_blank', 'location=yes,height=600,width=1200,scrollbars=yes,status=yes');
    } else {

    }
  }

  onTabchange(event) {
    if (event.index == 1) {
      this.initialization();
    }
  }
  protected dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.locationList, this.state);
    if (this.gridData.data.length > 0) {
      this.stateMap.filter = state.filter;
      this.stateMap.group = state.group;
      this.stateMap.skip = state.skip;
      this.stateMap.sort = state.sort;
      this.gridDataForMap = process(this.locationList, this.stateMap);
      this.mapData = this.gridDataForMap.data;
    } else {
      this.mapData = [];
    }

  }
  EditLocation(LocationID) {

    if(this.activatedRoute.snapshot.data['IsDepartmentManagement']){
      // document.getElementById(LocationID)['href'] = window.location.origin + '/#/add-department/' + LocationID;
      document.getElementById(LocationID)['href'] = window.location.origin + '/add-department/' + LocationID;
    }
    else
    {
      // document.getElementById(LocationID)['href'] = window.location.origin + '/#/add-location/' + LocationID;
      document.getElementById(LocationID)['href'] = window.location.origin + '/add-location/' + LocationID;
    }
    
  }

  //--- Start : Map Code Block---
  //Initializing the Google Map for 1st Use
  initialization() {
    //Getting the Properties of Map
    //var center = new google.maps.LatLng(this.mapData[0].geoLocation.split(",")[0], this.mapData[0].geoLocation.split(",")[1]);
    if (this.baseUrl.includes("eudemonic")) {
      //India
      var center = new google.maps.LatLng('20.593684', '78.96288000000004');
    } else {
      //Australia
      var center = new google.maps.LatLng('-25.274398', '133.77513599999997');
    }

    this.map = new google.maps.Map(document.getElementById('mapView'), {
      zoom: 4,
      center: center,
      mapTypeId: google.maps.MapTypeId.HYBRID
    });
    this.refreshMap();
  }
  // refresh Map Code
  refreshMap() {
    var markerImage = new google.maps.MarkerImage(this.imageUrl, new google.maps.Size(24, 32));
    var infowindow = new google.maps.InfoWindow();
    this.markerClusters = new Array<MarkerClusterer>();

    //Spider Code
    var oms = new OverlappingMarkerSpiderfier(this.map, { markersWontMove: true, markersWontHide: false, keepSpiderfied: true });

    var spider = "../../../assets/marker.svg";
    var spidermarker = "../../../assets/marker-plus.svg";
    var spiderfiedColor = 'ffee22';
    var iconWithColor = function (color) {
      return 'http://chart.googleapis.com/chart?chst=d_map_pin_letter_withshadow&chld=C|FF0000|0000FF';
    }
    var shadow = new google.maps.MarkerImage(
      'https://www.google.com/intl/en_ALL/mapfiles/shadow50.png',
      new google.maps.Size(37, 34), // size   - for sprite clipping
      new google.maps.Point(0, 0), // origin - ditto
      new google.maps.Point(10, 34)  // anchor - where to meet map location
    );

    for (var i = 0; i < this.mapData.length; i++) {
      if (this.mapData[i].geoLocation != undefined && this.mapData[i].geoLocation != null && this.mapData[i].geoLocation != "") {
        let latt = this.mapData[i].geoLocation.split(",")[0];
        let longg = this.mapData[i].geoLocation.split(",")[1];
        if (latt != undefined && latt != null && latt != "" && longg != undefined && longg != null && longg != "") {
          var latLng = new google.maps.LatLng(latt, longg)
          var marker = new google.maps.Marker({
            position: latLng,
            draggable: true,
            animation: google.maps.Animation.DROP,
            imagePath: 'http://chart.googleapis.com/chart?chst=d_map_pin_letter_withshadow&chld=C|FF0000|0000FF',
            metaData: this.mapData[i]
          });
          //listener event on Drag Start   //api not ready yet


          //listener event when Click on a marker
          google.maps.event.addListener(marker, 'click', (function (clickedmarker, i) {
            return function () {
              var LocationCode = "", LocationName = "";
              if (clickedmarker.metaData.locationID)
                LocationCode = (clickedmarker.metaData.locationCode == undefined || clickedmarker.metaData.locationCode == null || clickedmarker.metaData.locationCode == "") ? "NA" : clickedmarker.metaData.locationCode;
              LocationName = clickedmarker.metaData.locationName;
              let currOrigin = window.location.origin;
              //'/add-location/' + LocationID
              // infowindow.setContent('<p><b>Location Details</b></p> Location Code :<a href="' + currOrigin + '/#/add-location/' + clickedmarker.metaData.locationID + '" target="_blank" style="color:#007bff !important">' + LocationCode + '</a> <br> LocationName :' + LocationName);
              infowindow.setContent('<p><b>Location Details</b></p> Location Code :<a href="' + currOrigin + '/add-location/' + clickedmarker.metaData.locationID + '" target="_blank" style="color:#007bff !important">' + LocationCode + '</a> <br> LocationName :' + LocationName);
              infowindow.open(this.map, clickedmarker);
            }
          })(marker, i));
          //oms.addMarker(marker,function(){})
          this.markerClusters.push(marker);
        }
      }
    }
    var clusterStyles = [
      {
        height: 42,
        width: 42
      }
    ];
    var markerClusterer = new MarkerClusterer(this.map, this.markerClusters, { ac: clusterStyles, gridSize: 50, maxZoom: 17, ignoreHidden: true, imagePath: '../../../assets/m' });
    //Showing Spider
    this.oms.addListener('spiderfy', function (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setIcon(spidermarker);
        markers[i].setShadow(shadow);
      }
    });

    //Hide Spider
    this.oms.addListener('unspiderfy', function (spidermarkers) {
      for (var i = 0; i < spidermarkers.length; i++) {
        spidermarkers[i].setIcon(spider);
        spidermarkers[i].setShadow(shadow);
      }
    });
    //this.oms = new OverlappingMarkerSpiderfier(this.map);
    this.markerClusters = new MarkerClusterer(this.map, this.markerClusters,
      { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
  }
  //Setting location
  setLocation(locationData) {
  }
  //--- End : Map Code Block
}
