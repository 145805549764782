import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
// import { EvaluatePortalUrl } from "../Services/shared/shared";

@Component({
  selector: 'app-data-export',
  templateUrl: 'data-export.component.html',
  styleUrls: ['data-export.component.scss']


})
export class DataExportComponent implements OnInit {
  public userName: string;
  public avtar: number;
  // evaluate:EvaluatePortalUrl=new EvaluatePortalUrl();
  constructor(private router: Router, private fb: FormBuilder) {
  }
  ngOnInit() {


  }
  save(value: any, valid: boolean) {
    if (valid) {
     }
  }


}






